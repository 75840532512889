import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import Axios from 'axios';
import SimpleInputField from '../../FieldComponents/InputFields/InputField.jsx';

const { success, error, warning } = require('../../_helper/SweetAlerts/alerts.jsx');
const { onChange } = require('../../_helper/index.jsx');

export default class ResetEmailRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const { email } = this.state;
    Axios.get('/user/password-reset-request', { params: { email } })
      .then((user) => {
        const { message } = user.data;
        success({
          title: 'Success!',
          text: message,
          button: 'Okay',
        });
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 400) {
          return error({
            title: 'Error!',
            text: 'Email is invalid or empty. Please try again.',
            button: 'Okay.',
          });
        }
        error({ title: 'Error!', text: 'Server Error', button: 'Okay' });
      });
  }

  render() {
    const { email } = this.state;
    return (
      <MDBContainer className="margin-top-adjustment">
        <MDBRow center>
          <MDBCol xs="12" lg="8">
            <MDBRow center>
              <MDBCol className="text-center">
                <p className="contact-us-text">Password Reset</p>
              </MDBCol>
            </MDBRow>
            <div className="row page-divider mb-5" />
            <form className="tab-content animated fadeIn" onSubmit={this.onSubmit}>
              <MDBRow center>
                <MDBCol sm="12" md="8">
                  <SimpleInputField
                    label="Email"
                    name="email"
                    value={email}
                    onChange={(e) => onChange(this, e)}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow center className="text-left">
                <MDBCol sm="8" className="my-3">
                  <MDBBtn type="submit" color="amber">
                    Send Password Reset Email.
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
