import React from 'react';
import Modal from 'react-responsive-modal';
import $ from 'jquery';
import { Link } from 'react-router-dom';

const gifs = [
  [
    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Landing+Page/slowcat.gif',
    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Landing+Page/fastcat.gif',
  ],
  [
    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Landing+Page/slowdog-min.gif',
    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Landing+Page/fastdog-min.gif',
  ],
];

const selectedGifs = gifs[Math.floor(Math.random() * gifs.length)];

export default class PopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      gif: selectedGifs[0],
    };
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentWillMount() {
    const readCookie = (name) => {
      const nameEQ = name + '=';
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    };

    if (!readCookie('hide')) {
      this.setState({
        open: true,
      });
    } else {
      this.setState({
        open: false,
      });
    }
  }

  componentDidMount() {
    if (this.state.open) {
      const that = this;
      (() => {
        $(document).mousemove((e) => {
          const mX = e.pageX;
          const mY = e.pageY;
          const from = { x: mX, y: mY };
          const $n = $('#pop-up-sub-button');
          const off = $n.offset(),
            nx1 = off.left,
            ny1 = off.top,
            nx2 = nx1 + $n.outerWidth(),
            ny2 = ny1 + $n.outerHeight(),
            maxX1 = Math.max(mX, nx1),
            minX2 = Math.min(mX, nx2),
            maxY1 = Math.max(mY, ny1),
            minY2 = Math.min(mY, ny2),
            intersectX = minX2 >= maxX1,
            intersectY = minY2 >= maxY1,
            to = {
              x: intersectX ? mX : nx2 < mX ? nx2 : nx1,
              y: intersectY ? mY : ny2 < mY ? ny2 : ny1,
            };
          let distX = to.x - from.x,
            distY = to.y - from.y,
            hypot = Math.sqrt(distX * distX + distY * distY);
          if (hypot === 0) {
            that.setState({
              gif: selectedGifs[1],
            });
          } else if (hypot > 0) {
            that.setState({
              gif: selectedGifs[0],
            });
          }
          $('html').click(() => {
            $(document).off('mousemove');
          });
        });
      })();
    }
  }

  onCloseModal(e) {
    const createCookie = (name, value, days) => {
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        var expires = '; expires=' + date.toGMTString();
      } else var expires = '';
      document.cookie = name + '=' + value + expires + '; path=/';
    };

    if (e) {
      createCookie('hide', true, 1);
      this.setState({
        open: false,
      });
    }
    $('.customOverlay, .customModal')
      .removeClass('fade-in')
      .addClass('fade-out');
  }

  render() {
    return (
      <div>
        <Modal
          center
          open={this.state.open}
          onClose={this.onCloseModal}
          showCloseIcon={false}
          classNames={{
            overlay: 'customOverlay fade-in',
            modal: 'customModal scale-in-center',
          }}>
          <div className="container">
            <div className="row">
              <div className="col-6 p-0">
                <img className="popup-dog" src={this.state.gif} />
              </div>
              <div className="col-6 p-0">
                <div className="text-right popup-close">
                  <span className="popup-icon" onClick={this.onCloseModal}>
                    <img
                      src={
                        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Landing+Page/multiply.png'
                      }
                    />
                  </span>
                </div>
                <div className="join mb-3 text-center">JOIN LOTUS TODAY!</div>
                <img
                  className="img-fluid"
                  src={
                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Landing+Page/freepopup.png'
                  }
                />
                <div className="text-center signup-text">
                  <i>For new members only</i>
                </div>
                <div className="text-center mt-3">
                  <Link to="/subscribe" href="/subscribe">
                    <button
                      type="submit"
                      className="popup-submit popup-text mt-2"
                      id="pop-up-sub-button"
                      onClick={this.signUp}>
                      Subscribe
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
