module.exports = {
  infoText: [
    {
      alt: 'USDA Inspected & passed meats',
      src:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/usds-inspected-meats.png',
    },
    {
      alt: 'Good source of calcium',
      src:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/source-of-calcium.png',
    },
    {
      alt: 'Natural source of Thiamine',
      src:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/source-of-thiamine.png',
    },
  ],

  whatsInsideInfo: [
    {
      icon:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/bone-icon.png',
      title: 'No Ground Bone',
      text:
        'Our raw recipes do not contain any ground bone. This helps texture and palatability. It also helps maintain proper calcium and phosphorous levels.',
    },
    {
      icon:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/meat-icon.png',
      title: ' No added hormones or antibiotics',
      text: `We only use meats and poultry that are vegterian fed. 
        We use USDA INSPECTED and Passed and New Zealand Ministery Inspected and Passed meats and poultry. 
        No hormones added. No antibiotics added.`,
    },
    {
      icon:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/vegatable-icon.png',
      title: 'No vegetables or fruits',
      text:
        'Our raw recipes for dogs do not contain any vegatables or fruits. This helps texture and palatability.',
    },
    {
      icon:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/chicken-leg.png',
      title: 'Limited ingredient diet ',
      text:
        'Our raw recipes for dogs do not contain any vegatables or fruits. This helps texture and palatability.',
    },
  ],

  benefitsInfo: [
    {
      icon:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/thumbnail-1.jpg',

      title: 'Naturally clean teeth',
      text:
        'Many dry foods leave a starchy coating on teeth, which promotes plague. Feeding a raw diet can lead to healthier teeth and gum.',
    },
    {
      icon:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/thumbnail-2.jpg',

      title: 'Healthy skin and coat',
      text:
        'Many dogs are allergic to grains, which can lead to pathologic skin conditions. Also feeding raw is a great way to naturally keep your dog hydrated',
    },
    {
      icon:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/thumbnail-3.jpg',

      title: 'Natural weight control',
      text: `Dogs are healthiest on a high protein, low carbohydrate diet. Also feeding raw is a great way to naturally keep your dog hydrated.
      Feeding raw closely mirrors what dogs would naturally eat in the wild. Dog's digestive systems are designed to ingest raw meat and 
      healthiest eating what nature intended.`,
    },
  ],
};
