import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import StarRatingComponent from 'react-star-rating-component';
import axios from 'axios';
import ScrollAnimation from 'react-animate-on-scroll';

class Rawfood extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: {
        chicken: {
          total: 0,
          stars: 0,
          average: 0,
        },
        pork: {
          total: 0,
          stars: 0,
          average: 0,
        },
        turkey: {
          total: 0,
          stars: 0,
          average: 0,
        },
        beef: {
          total: 0,
          stars: 0,
          average: 0,
        },
        lamb: {
          total: 0,
          stars: 0,
          average: 0,
        },
        venison: {
          total: 0,
          stars: 0,
          average: 0,
        },
      },
    };
    this.showChicken = this.showChicken.bind(this);
    this.hideChicken = this.hideChicken.bind(this);
    this.showPork = this.showPork.bind(this);
    this.hidePork = this.hidePork.bind(this);
    this.showbeef = this.showbeef.bind(this);
    this.hidebeef = this.hidebeef.bind(this);
    this.showTurkey = this.showTurkey.bind(this);
    this.hideTurkey = this.hideTurkey.bind(this);
    this.showlamb = this.showlamb.bind(this);
    this.hidelamb = this.hidelamb.bind(this);
    this.showvenison = this.showvenison.bind(this);
    this.hidevenison = this.hidevenison.bind(this);
  }

  componentDidMount() {
    axios.post('/getallreviews').then((res) => {
      const reviews = {
        chicken: {
          total: 0,
          stars: 0,
          average: 0,
        },
        pork: {
          total: 0,
          stars: 0,
          average: 0,
        },
        turkey: {
          total: 0,
          stars: 0,
          average: 0,
        },
        beef: {
          total: 0,
          stars: 0,
          average: 0,
        },
        lamb: {
          total: 0,
          stars: 0,
          average: 0,
        },
        venison: {
          total: 0,
          stars: 0,
          average: 0,
        },
      };
      /* So this component doesnt even fucking work correctly... are you shitting me ? */
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].product_id === 41) {
          reviews.chicken.total++;
          reviews.chicken.stars += res.data[i].stars;
          reviews.chicken.average = reviews.chicken.stars / reviews.chicken.total;
        }

        if (res.data[i].product_id === 42) {
          reviews.pork.total++;
          reviews.pork.stars += res.data[i].stars;
          reviews.pork.average = reviews.pork.stars / reviews.pork.total;
        }

        if (res.data[i].product_id === 43) {
          reviews.turkey.total++;
          reviews.turkey.stars += res.data[i].stars;
          reviews.turkey.average = reviews.turkey.stars / reviews.turkey.total;
        }

        if (res.data[i].product_id === 44) {
          reviews.beef.total++;
          reviews.beef.stars += res.data[i].stars;
          reviews.beef.average = reviews.beef.stars / reviews.beef.total;
        }

        if (res.data[i].product_id === 46) {
          reviews.lamb.total++;
          reviews.lamb.stars += res.data[i].stars;
          reviews.lamb.average = reviews.lamb.stars / reviews.lamb.total;
        }

        if (res.data[i].product_id === 47) {
          reviews.venison.total++;
          reviews.venison.stars += res.data[i].stars;
          reviews.venison.average = reviews.venison.stars / reviews.venison.total;
        }
      }

      this.setState({
        reviews: reviews,
      });
    });
  }

  showChicken(e) {
    $('#dog-chicken-loaf-name, #dog-chicken-loaf-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hideChicken(e) {
    $('#dog-chicken-loaf-name, #dog-chicken-loaf-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  showPork(e) {
    $('#dog-pork-loaf-name, #dog-pork-loaf-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hidePork(e) {
    $('#dog-pork-loaf-name, #dog-pork-loaf-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  showTurkey(e) {
    $('#dog-turkey-loaf-name, #dog-turkey-loaf-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hideTurkey(e) {
    $('#dog-turkey-loaf-name, #dog-turkey-loaf-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  showbeef(e) {
    $('#dog-beef-loaf-name, #dog-beef-loaf-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hidebeef(e) {
    $('#dog-beef-loaf-name, #dog-beef-loaf-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  showlamb(e) {
    $('#dog-lamb-loaf-name, #dog-lamb-loaf-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hidelamb(e) {
    $('#dog-lamb-loaf-name, #dog-lamb-loaf-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  showvenison(e) {
    $('#dog-venison-loaf-name, #dog-venison-loaf-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hidevenison(e) {
    $('#dog-venison-loaf-name, #dog-venison-loaf-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  render() {
    return (
      <div>
        <img
          className="banner img-fluid w-100"
          src={'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/raw-banner.png'}
        />
        <div className="container regular-bites-container">
          <div className="row">
            <div className="col cat-raw-title-text cat-raw-text">RAW FOOD FOR CATS</div>
          </div>
          <div className="row">
            <div className="col dog-food-text description">
              Coastal breezes, lush central valley farmlands, rolling green hills packed with
              vineyards...what better place to think local? Thatʼs exactly what we do every time we
              make a batch of one of our Lotus raw recipes for cats here in our own Southern
              California craft raw-ery. These are real cuts of meats and poultry, like you would
              find in your own kitchen, ground and filled locally in small batches by our own staff
              (yes, people we know and like). And our cuts of meat and poultry are of course
              hormone, antibiotic free and vegetarian fed. It just seems natural to us, but itʼs
              kind of a big deal, because so many other pet foods don’t use this same quality and
              high standard for their meat and poultry. So every time you feed your pet Lotus, you
              can pat yourself on the back for feeding the very best ingredients and doing your part
              to support the local economy.
            </div>
          </div>
          <ScrollAnimation animateOnce={true} animateIn="slideInUp">
            <div className="row dog-food-row-1">
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/cat/raw-food/Chicken-60">
                  <img
                    className="img-fluid cat-raw-hover"
                    src={
                      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Chicken/24/chicken+320.png'
                    }
                  />

                  <div
                    className="cat-raw-chicken d-flex justify-content-center flex-column"
                    onMouseEnter={this.showChicken}
                    onMouseLeave={this.hideChicken}>
                    <div
                      className="recipe-name cat-raw-font-size dk-font"
                      id="dog-chicken-loaf-name">
                      CHICKEN RECIPE
                    </div>
                    <div
                      className="regular-bites-sizes dk-font text-lowercase raw-margin-top-8"
                      id="dog-chicken-loaf-sizes">
                      3.5 oz | 16 oz | 24 oz
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.chicken.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.chicken.total}</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/cat/raw-food/Pork-58">
                  <img
                    className="img-fluid cat-raw-hover"
                    src={
                      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/pork/24oz/pork+320.png'
                    }
                  />

                  <div
                    className="cat-raw-pork d-flex justify-content-center flex-column"
                    onMouseEnter={this.showPork}
                    onMouseLeave={this.hidePork}>
                    <div className="recipe-name cat-raw-font-size dk-font" id="dog-pork-loaf-name">
                      PORK RECIPE
                    </div>
                    <div
                      className="regular-bites-sizes dk-font text-lowercase raw-margin-top-8"
                      id="dog-pork-loaf-sizes">
                      3.5 oz | 16 oz | 24 oz
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.pork.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.pork.total}</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/cat/raw-food/Turkey-57">
                  <img
                    className="img-fluid cat-raw-hover"
                    src={
                      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/turkey/24oz/turkey+320.png'
                    }
                  />

                  <div
                    className="cat-raw-turkey d-flex justify-content-center flex-column"
                    onMouseEnter={this.showTurkey}
                    onMouseLeave={this.hideTurkey}>
                    <div
                      className="recipe-name cat-raw-font-size dk-font"
                      id="dog-turkey-loaf-name">
                      TURKEY RECIPE
                    </div>
                    <div
                      className="regular-bites-sizes dk-font text-lowercase raw-margin-top-8"
                      id="dog-turkey-loaf-sizes">
                      3.5 oz | 16 oz | 24 oz
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.turkey.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.turkey.total}</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/cat/raw-food/Beef-59">
                  <img
                    className="img-fluid cat-raw-hover"
                    src={
                      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Beef/24oz/beef+320.png'
                    }
                  />

                  <div
                    className="cat-raw-beef d-flex justify-content-center flex-column"
                    onMouseEnter={this.showbeef}
                    onMouseLeave={this.hidebeef}>
                    <div
                      className="recipe-name cat-raw-font-size dk-font slide-bottom-rec"
                      id="dog-beef-loaf-name">
                      beef RECIPE
                    </div>
                    <div
                      className="regular-bites-sizes dk-font text-lowercase raw-margin-top-8"
                      id="dog-beef-loaf-sizes">
                      3.5 oz | 16 oz | 24 oz
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.beef.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.beef.total}</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/cat/raw-food/Venison-55">
                  <img
                    className="img-fluid cat-raw-hover"
                    src={
                      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/venison/24oz/venison+320.png'
                    }
                  />

                  <div
                    className="cat-raw-venison d-flex justify-content-center flex-column"
                    onMouseEnter={this.showvenison}
                    onMouseLeave={this.hidevenison}>
                    <div
                      className="recipe-name cat-raw-font-size dk-font text-white"
                      id="dog-venison-loaf-name">
                      venison RECIPE
                    </div>
                    <div
                      className="regular-bites-sizes dk-font text-lowercase raw-margin-top-8 text-white"
                      id="dog-venison-loaf-sizes">
                      3.5 oz | 16 oz | 24 oz
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={5}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">1</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/cat/raw-food/Lamb-54">
                  <img
                    className="img-fluid cat-raw-hover"
                    src={
                      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/lamb/24oz/lamb+320.png'
                    }
                  />

                  <div
                    className="cat-raw-lamb d-flex justify-content-center flex-column"
                    onMouseEnter={this.showlamb}
                    onMouseLeave={this.hidelamb}>
                    <div
                      className="recipe-name cat-raw-font-size dk-font text-white"
                      id="dog-lamb-loaf-name">
                      lamb RECIPE
                    </div>
                    <div
                      className="regular-bites-sizes dk-font text-lowercase raw-margin-top-8 text-white"
                      id="dog-lamb-loaf-sizes">
                      3.5 oz | 16 oz | 24 oz
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.lamb.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.lamb.total}</div>
                </div>
              </div>
              <div className="col-12 col-md-6 my-sm-5 my-4">
                <div className="row brochure--screen-alignment align-items-center ">
                  <div className="col-8 col-md-6">
                    <a
                      style={{ marginLeft: '20px' }}
                      data-tooltip-position="top"
                      data-tooltip="May take up to 10 seconds to load."
                      className="no-underline"
                      href="https://www.yumpu.com/en/document/read/62896306/virtual2"
                      target="_blank">
                      <img
                        className="img-fluid cat-raw-hover brochure--larger-img"
                        style={{ marginTop: '25px' }}
                        src={
                          'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/brochure/brochure.png'
                        }
                        alt="Lotus cat raw food brochure. Links to another website to view PDF Brochure."
                      />
                    </a>
                  </div>
                  <div className="col-4 brochure__arrow--adjustment">
                    <img
                      className="img-fluid cat-raw-hover brochure--larger-img"
                      style={{ marginTop: '25px' }}
                      src={
                        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/brochure/view-our-brochure.png'
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    );
  }
}

export default Rawfood;
