import React, { Component } from 'react';
import Axios from 'axios';

import SimpleOverview from './OverviewType/SimpleOverview.jsx';
import IsLoadingChild from '../../IsLoading/IsLoadingChild.jsx';

const { capitalizedFirstLetters } = require('../../_helper/index.jsx');
const { success, error, warning } = require('../../_helper/SweetAlerts/alerts.jsx');
const { modifyResProduct, changeIngredientType } = require('./ProductOverviewHelper.jsx');

/*
  ToDo:
  To render different products, Or should I just have one component?
  Need a logic to write and ensure that the params are correct
  TODO for later;
  Include error handling

*/

export default class ProductOverviewClass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      banner: '',
      description: '',
      products: '',
      foodType: '',
      petType: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    //"/overview/:petType/:foodType"
    const { foodType, petType } = this.props.match.params;
    const updateTypeQuery = foodType.replace(/-/g, ' ').toLowerCase();
    const updatePetTypeQuery = petType.replace(/-/g, ' ').toLowerCase();
    this.getProduct(
      capitalizedFirstLetters(updateTypeQuery),
      capitalizedFirstLetters(updatePetTypeQuery),
    );
    this.mapOverviewState(foodType);
  }

  /* set product to map onto page */
  setProducts = async (product) => {
    const modifiedProduct = await modifyResProduct(product);
    this.setState({
      products: modifiedProduct,
    });
  };

  /* set other information for render */
  mapOverviewState = (foodType) => {
    const { title, banner, description } = changeIngredientType(foodType);
    this.setState({
      title,
      banner,
      description,
    });
  };

  getProduct = (foodType, petType) => {
    const query = { foodType, petType };
    //{ foodType: "Regular Bites", petType: "dog" };
    Axios.get('/client/products/get-like-products', { params: { type: 'name', value: query } })
      .then((product) => {
        const { data } = product;
        if (data.length < 1) {
          return error({
            title: 'Error',
            text: 'Server Error. Cannot get Info.',
            button: 'Close.',
          });
        }
        if (petType === 'Cat' && foodType === 'Juicy') {
          const adjustedData = data.filter((product) => product.name.includes('Cat'));
          return this.setProducts(adjustedData);
        }
        if (petType === 'Dog' && foodType === 'Just Juicy') {
          const adjustedData = data.filter((product) => product.name.includes('Dog'));
          return this.setProducts(adjustedData);
        }
        this.setProducts(data);
      })
      .catch((err) => console.error(err));
  };

  waitToRenderChild = () => {
    const { products } = this.state;
    if (products.length < 1) {
      return <IsLoadingChild />;
    }
    const { foodType, petType } = this.props.match.params;
    return <SimpleOverview state={this.state} petType={petType} foodType={foodType} />;
  };

  render() {
    return this.waitToRenderChild();
  }
}
