import React from 'react';
import swal from 'sweetalert2';
import { Route } from 'react-router-dom';
import { MDBNav, MDBNavItem, MDBNavLink, MDBRow, MDBCol } from 'mdbreact';
import NoMatch from '../../NoMatch/NoMatch.jsx';
import SalesMainComponents from './SalesMainComponents.jsx';

export default function AdminMain(props) {
  const { history } = props;
  const adminLink = '/sales/main/';
  const adminPages = ['stores', 'retailers'];

  if (!localStorage.admin) {
    swal
      .fire({
        title: 'Incorrect Permission!',
        text: 'You do not have permission to view this page.',
        icon: 'error',
        confirmButtonText: 'Take me back to homepage!',
      })
      .then(() => history.push('/'), 3000);
    return <NoMatch />;
  }
  return (
    <div className="container contact-us-body">
      <MDBNav className="nav-tabs mt-5 mb-5 justify-content-center">
        {adminPages.map((page, i) => (
          <MDBNavLink
            className="d-inline-block admin-page__link"
            to={`${adminLink}${page}`}
            key={i}>
            <MDBNavItem>
              <p className="admin-page__text text-capitalize futura-font">{page}</p>
            </MDBNavItem>
          </MDBNavLink>
        ))}
      </MDBNav>
      <MDBCol className="px-0">
        <Route path="/sales/main/:page" component={SalesMainComponents} />
      </MDBCol>
    </div>
  );
}
