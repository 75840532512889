import React, { useState, useEffect } from 'react';
import { MDBInputGroup, MDBBtn, MDBCol, MDBInput } from 'mdbreact';
import subscribeToNewsLetter from '@Requests/subscribeToNewsLetter';
import initialState from './state';

const { success, error, warning } = require('@Comps/_helper/SweetAlerts/alerts');

/**
 * Newsletter subscription input
 * acceptAll enable user to accept term and newsletter
 * @param {Boolean} props.acceptAll
 * @param {String} props.email
 * @param {Boolean} props.enableBtn
 */
export default function NewsLetterSub(props) {
  const { acceptAll, email, enableBtn } = props || {};
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (acceptAll) {
      setState({ email, terms: true, newsletter: true });
    }
  }, []);

  return (
    <>
      {/*<MDBInputGroup
        onChange={({ target }) => setState({ ...state, zip: target.value })}
        outline
        className="my-2"
        hint="Zip code"
      />*/}

      <MDBInputGroup
        onChange={({ target }) => setState({ ...state, firstName: target.value })}
        outline
        className="my-2"
        hint="First Name"
        style={{paddingRight: 15}}
      />
    
      <MDBInputGroup
        style={{paddingRight: 15}}
        hint="Email address"
        onChange={({ target }) => setState({ ...state, email: target.value })}
        append={
          enableBtn ? (
            <MDBBtn
              color="dark"
              onClick={() => subscribeToNewsLetter({ email: state.email, firstName:state.firstName})}
              className="newsletterBtn m-0 px-3 py-2 z-depth-0">
              Submit
            </MDBBtn>
          ) : null
        }
      />
    </>
  );
}
