import React from 'react';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';

export default function NewsItem(props) {
  return (
    <div>
      <div className="container contact-us-body">
        <div className="row">
          <div className="col contact-us-text">NEWSLETTER</div>
        </div>
        <div className="row page-divider mb-5" />
        <div className="row">
          <div className="col-12 mb-5" style={{ fontSize: '24px' }}>
            <Link to="/newsletter" href="/newsletter">
              Return
            </Link>
          </div>
          <div className="col-12" style={{ position: 'relative' }}>
            <img
              className="img-fluid"
              src={`https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Newsletter/${props.location.pathname.replace(
                '/newsletter/',
                '',
              )}.jpg`}
            />
            {props.location.pathname.replace('/newsletter/', '') === 'may19' ? (
              <div style={{ position: 'relative', top: '-66.5%', left: '49.6%', width: '48%' }}>
                <YouTube className="newsletter__youtube-vid" videoId="oW882koaoaY" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
