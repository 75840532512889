import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import swal from 'sweetalert2';

class QuizRedeem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      points: 0,
    };
    this.selectPrize = this.selectPrize.bind(this);
  }

  componentDidMount() {
    // window.scrollTo(0, 0);

    // if (!localStorage.email) {
    //   swal.fire({
    //     title: 'You must be logged in to view this page!',
    //     icon: 'error',
    //     showConfirmButton: false,
    //     timer: 1500
    //   }).then(() => this.props.history.push('/sign-in'));
    // } else {
    //   axios.post('/checkretailer', { email: localStorage.email }).then(res => {
    //     if (res.data === false) {
    //       swal.fire({
    //         title: 'You must retailer to access this page!',
    //         icon: 'error',
    //         showConfirmButton: false,
    //         timer: 1500
    //       }).then(() => this.props.history.push('/'));
    //     }
    //   });
    // }

    axios.post('/getpoints', { email: localStorage.email }).then((res) => {
      if (res.data.rows[0].points < 45) {
        $('#dog-food-prize, #cat-food-prize')
          .css('filter', 'grayscale(100%)')
          .addClass('disabled-link');
      }
      if (res.data.rows[0].points < 40) {
        $('#bottle-prize')
          .css('filter', 'grayscale(100%)')
          .addClass('disabled-link');
      }
      if (res.data.rows[0].points < 35) {
        $('#t-shirt-prize')
          .css('filter', 'grayscale(100%)')
          .addClass('disabled-link');
      }
      if (res.data.rows[0].points < 30) {
        $('#tote-bag-prize')
          .css('filter', 'grayscale(100%)')
          .addClass('disabled-link');
      }
      if (res.data.rows[0].points < 5) {
        $('#stickers-prize')
          .css('filter', 'grayscale(100%)')
          .addClass('disabled-link');
      }

      this.setState({
        points: res.data.rows[0].points,
      });
    });
  }

  selectPrize(e) {
    localStorage.selectedPrize = e.target.id;
    this.props.history.push('/retail-training/redeem/ship');
  }

  render() {
    return (
      <div>
        <div className="container contact-us-body">
          <div className="row">
            <div className="col contact-us-text">REDEEM</div>
          </div>
          <div className="row page-divider" />
          <div className="row my-4">
            <div className="col-auto" style={{ fontFamily: 'Futura-Bold', fontSize: '48px' }}>
              Points: {this.state.points}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <img
                id="dog-food-prize"
                onClick={this.selectPrize}
                className="img-fluid w-100 quiz-redeem-item quiz-redeem-rel"
                src={
                  'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/dog-food-prize-bar1.png'
                }
              />
            </div>
            <div className="col-12">
              <img
                id="cat-food-prize"
                onClick={this.selectPrize}
                className="img-fluid w-100 quiz-redeem-item quiz-redeem-rel"
                src={
                  'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/cat-food-prize-bar1.png'
                }
              />
            </div>
{/*  <div className="col-12">
              <img
                id="bottle-prize"
                onClick={this.selectPrize}
                className="img-fluid w-100 quiz-redeem-item quiz-redeem-rel"
                style={{ top: '-5%' }}
                src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/bottle-prize-bar.png'}
              />
            </div>
            <div className="col-12">
              <img
                id="t-shirt-prize"
                onClick={this.selectPrize}
                className="img-fluid w-100 quiz-redeem-item quiz-redeem-rel"
                style={{ top: '-12%' }}
                src={
                  'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/t-shirt-prize-bar.png'
                }
              />
            </div>
            <div className="col-12">
              <img
                id="tote-bag-prize"
                onClick={this.selectPrize}
                className="img-fluid w-100 quiz-redeem-item quiz-redeem-rel"
                style={{ top: '-32%' }}
                src={
                  'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/tote-bag-prize-bar.png'
                }
              />
            </div>
            <div className="col-12">
              <img
                id="stickers-prize"
                onClick={this.selectPrize}
                className="img-fluid w-100 quiz-redeem-item quiz-redeem-rel"
                style={{ top: '-41%' }}
                src={
                  'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/stickers-prize-bar.png'
                }
              />
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default QuizRedeem;
