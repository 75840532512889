import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBFileInput, MDBBtn } from 'mdbreact';
import { Route, Link } from 'react-router-dom';
import Axios from 'axios';

import AllStoreImages from './AllStoreImages.jsx';
import SampleLightBox from './SampleLightBox.jsx';

const {
  endPoint: { retailStores, filesUploads },
} = require('../_helper/stringVars');
const { handleErrors } = require('../_helper/reqsHandlers');
const { resetOrientation, getOrientation, correctImgOrientation } = require('./helper.jsx');

export default class AwsUploadTest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedImageBase64: '',
      fileInfo: '',
    };
  }

  componentDidMount() {
    // this.testS3();
  }

  testS3 = async () => {
    try {
      const url = await Axios.get(`${filesUploads.client}/get-s3-info`);
      const correctedImgBlob = await correctImgOrientation(
        'data:image/jpeg;base64,' + url.data.body,
      );

      this.setState({
        fileInfo: 'data:image/jpeg;base64,' + url.data.body,
        selectedImageBase64: correctedImgBlob,
      });
    } catch (err) {
      console.error(err);
    }
  };

  testS3Head = () => {
    Axios.get(`${filesUploads.client}/get-s3-head`)
      .then((res) => {
        console.log({ res });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getValue = (value) => {
    const fileInfo = value;
    const form = new FormData();

    for (let i = 0; i < fileInfo.length; i += 1) {
      this.setState({ exif: URL.createObjectURL(fileInfo[i]) });
      console.log(fileInfo[i]);
      getOrientation(fileInfo[i], (orientation) => {
        alert('orientation: ' + orientation);
      });

      form.append('photos', fileInfo[i]);

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };

      Axios.post(`${filesUploads.client}/upload-product-img`, form, config)
        .then((res) => {
          console.log({ res });
        })
        .catch((err) => {
          console.error(err);
          const { status } = err.response;
          if (status === 406) return handleErrors(406);
        });
    }
  };

  submitImage = (e) => {
    e.preventDefault();
    const { fileInfo } = this.state;
  };

  render() {
    return (
      <MDBContainer className="aws-upload-test margin-top-adjustment">
        <MDBRow>
          <MDBCol>
            <SampleLightBox />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            <h1 className="header1 futura-font">Hello this is AWS upload test</h1>
          </MDBCol>
          <MDBCol size="12">
            <MDBBtn color="dark" className="ml-0" onClick={this.testS3}>
              Test s3
            </MDBBtn>
            <MDBBtn color="dark" className="ml-0" onClick={this.testS3Head}>
              Test s3 Head
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow className="my-3">
          <MDBCol size="12" md="8" className="pl-2">
            <MDBFileInput getValue={this.getValue} multiple btnColor="info" reset />
            <span className="review__span--img-upload-resize font-weight-bolder ml-2 futura-font">
              * JPG/JPEG file only.
            </span>
          </MDBCol>
          <MDBCol size="12" md="5">
            <MDBBtn onClick={this.submitImage} color="primary" className="ml-0">
              Upload Images
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="4">
            <img
              className="img-fluid"
              src="https://lotus-pet-foods-image-upload.s3-us-west-1.amazonaws.com/1569258302964-02-04-2020.jpeg"
            />
          </MDBCol>
          <MDBCol size="3">
            <img
              // style={{ maxWidth: '100%', maxHeight: '100%' }}
              className="img-fluid"
              src={this.state.selectedImageBase64}
            />
          </MDBCol>
          <MDBCol size="3">
            <img id="image-reset" className="img-fluid" src={this.state.fileInfo} />
          </MDBCol>
        </MDBRow>
        <MDBRow className="my-5">
          <Link to="/aws-upload-test/stores-images" className="ml-2">
            <MDBBtn color="amber">Load store Images</MDBBtn>
          </Link>
          <Link to="/aws-upload-test" className="ml-2">
            <MDBBtn color="dark">Go Back</MDBBtn>
          </Link>
        </MDBRow>
        <Route path="/aws-upload-test/stores-images" component={AllStoreImages} />
      </MDBContainer>
    );
  }
}
