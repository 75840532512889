import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
import Axios from 'axios';
import swal from 'sweetalert2';

import IsLoadingChild from '../../../../../IsLoading/IsLoadingChild.jsx';
import SimpleInputField from '../../../../../FieldComponents/InputFields/InputField.jsx';
import ImgRender from '../../../../../ImgUploader/ImgRender.jsx';

const {
  endPoint: { retailStores, filesUploads },
} = require('../../../../../_helper/stringVars');
const { handleErrors } = require('../../../../../_helper/reqsHandlers');
const {
  onChange,
  setToken,
  tokenStrOnly,
  uploadImgToS3,
  getUploadImg,
} = require('../../../../../_helper/index.jsx');
const {
  convertStoreInfoForInput,
  handleStoreStatus,
  confirmStoreDelete,
  validateFields,
  correctImgRotation,
} = require('../helper.jsx');

export default class StoreEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      storeLoaded: false,
      fileInfo: {},
      uploadedImgDataUrl: '',
      uploadedImgLoaded: false,
      imgLoader: false,
      updatedImg: false,
    };

    this.token = { headers: setToken('admin') };
  }

  componentDidMount() {
    this.getStore();
  }

  getStore = async () => {
    try {
      const { storeId } = this.props.match.params;

      const { data } = await Axios.get(`${retailStores.admin}/edit-store`, {
        headers: { Authorization: tokenStrOnly('admin') },
        params: { _id: storeId },
      });

      const updatedInputArr = await convertStoreInfoForInput(data);
      // Create store keys and val as state
      Object.keys(data).forEach((key) => {
        this.setState({
          [key]: data[key],
          storeFields: updatedInputArr,
          storeLoaded: true,
        });
      });
    } catch (err) {
      console.error(err);
      handleErrors(500);
    }
  };

  updateStore = async (e) => {
    e.preventDefault();
    e.target.className += ' was-validated';

    // Need to update image informationg if it changes
    const {
      _id,
      storeFields,
      storeLoaded,
      picture_url,
      website_url,
      page,
      uploadedImgDataUrl,
      uploadedImgLoaded,
      imgLoader,
      fileInfo,
      updatedImg,
      ...updatedStore
    } = this.state;

    if (validateFields(updatedStore)) {
      swal.fire({
        title: 'Loading...',
        text: 'Please wait while we update the store.',
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const { data } = await Axios.post(
          `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${this.state.address}`,
        );
        const { lat, lng } = data.results[0].geometry.location;
        let newImg;
        if (updatedImg) {
          const newUploadImg = await uploadImgToS3(fileInfo, 'store');
          newImg = newUploadImg;
        }

        Object.assign(updatedStore, { _id, lat, lng, picture_url: newImg, website_url });
        console.log({ updatedStore });
        const { status } = await Axios.put(
          `${retailStores.admin}/update-store`,
          { params: updatedStore },
          this.token,
        );
        console.log({ status });
        if (status === 201) {
          this.getStore();
          this.setState({
            uploadedImgDataUrl: '',
          });
          handleStoreStatus({
            title: 'Store updated',
            text: 'You have updated store information',
            status: 201,
          });
        }
      } catch (err) {
        console.error(err);
        const { status } = err.response;
        if (status === 400) return handleErrors(400);
        if (status === 404) return handleErrors(404);
        if (status === 403) return handleErrors(403);
        handleErrors(500);
      }
    }
  };

  /* start of image function */

  removeImg = async (_id, e) => {
    e.preventDefault();
    const confirmedDelete = await confirmStoreDelete('image');

    if (confirmedDelete) {
      try {
        const { status } = await Axios.put(
          `${retailStores.admin}/remove-img`,
          { params: { _id } },
          this.token,
        );
        if (status === 201) {
          this.getStore();
          return handleStoreStatus({
            title: 'Image removed!',
            text: 'Store image has been removed.',
            status: 201,
          });
        }
      } catch (err) {
        console.error(err);
        handleErrors(500);
      }
    }
  };
  /* End of image function */

  renderInputFields = () => {
    const { state } = this;
    return state.storeFields.map((storeInput, i) => {
      if (storeInput.value === 'website_url' || storeInput.value === 'picture_url') {
        return (
          <MDBCol key={i} size="12" className="px-1">
            <SimpleInputField
              name={storeInput.value}
              label={storeInput.text}
              value={state[storeInput.value]}
              onChange={(e) => onChange(this, e)}
            />
          </MDBCol>
        );
      }
      return (
        <MDBCol key={i} size="12" className="px-1">
          <SimpleInputField
            name={storeInput.value}
            label={storeInput.text}
            value={state[storeInput.value]}
            onChange={(e) => onChange(this, e)}
            required={true}
          />
        </MDBCol>
      );
    });
  };

  render() {
    const { state, props } = this;

    if (!state.storeLoaded) return <IsLoadingChild />;
    return (
      <form
        className="needs-validation d-flex justify-content-center"
        noValidate
        onSubmit={this.updateStore}>
        <MDBRow className="admin-stores__new-store" center>
          <MDBCol size="12">
            <h1 className="admin-main__header2 futura-font">
              Upadting store: {state.name} - {state._id}
            </h1>
          </MDBCol>

          {this.renderInputFields()}

          {state.picture_url ? (
            <MDBCol size="12">
              <p className="admin-main__p font-weight-bolder ml-2 futura-font">Store Image</p>
              <img className="img-fluid admin-stores__store-img my-3" src={state.picture_url} />
              <MDBBtn
                onClick={(e) => this.removeImg(state._id, e)}
                className="admin-stores__remove-img-btn"
                flat
                circle
                size="lg">
                <MDBIcon className="admin-stores__remove-img-icon" icon="ban" />
              </MDBBtn>
            </MDBCol>
          ) : (
            <p className="admin-main__p font-weight-bolder ml-2 futura-font">No Store Image</p>
          )}

          <ImgRender
            getUploadImg={(value) => getUploadImg(this, value)}
            uploadedImgDataUrl={state.uploadedImgDataUrl}
            uploadedImgLoaded={state.uploadedImgLoaded}
            imgLoader={state.imgLoader}
          />

          <MDBCol size="12">
            <MDBBtn color="primary" type="submit">
              Save Updates
              <MDBIcon far icon="save" className="ml-1 white-text" />
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </form>
    );
  }
}
