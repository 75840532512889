import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import SwipePopUp from '../SwipePopUp';

class BeefMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
      reviews: {
        dogStew: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogLoaf: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogJuicy: {
          total: 0,
          stars: 0,
          average: 0
        }
      }
    };
    this.anotherProtein = this.anotherProtein.bind(this);
  }

  componentWillMount() {
    axios.post('/getallreviews').then(res => {
      const reviews = {
        dogStew: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogLoaf: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogJuicy: {
          total: 0,
          stars: 0,
          average: 0
        }
      };

      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].product_id === 25) {
          reviews.dogStew.total++;
          reviews.dogStew.stars += res.data[i].stars;
          reviews.dogStew.average = reviews.dogStew.stars / reviews.dogStew.total;
        }

        if (res.data[i].product_id === 29) {
          reviews.dogLoaf.total++;
          reviews.dogLoaf.stars += res.data[i].stars;
          reviews.dogLoaf.average = reviews.dogLoaf.stars / reviews.dogLoaf.total;
        }

        if (res.data[i].product_id === 35) {
          reviews.dogJuicy.total++;
          reviews.dogJuicy.stars += res.data[i].stars;
          reviews.dogJuicy.average = reviews.dogJuicy.stars / reviews.dogJuicy.total;
        }
      }

      this.setState({
        reviews: reviews
      });
    });
  }

  anotherProtein() {
    const protein = $('#protein').val();
    this.props.history.push(`/${protein}`);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const htmlWidth = $('html').width();
    const scroll = htmlWidth * 0.514;
    $('.scrolling-wrapper').scrollLeft(scroll);
  }

  render() {
    return (
      <div>
      <SwipePopUp />
        <div className='container chicken-container'>
          <div className='row d-flex align-items-center justify-content-center mb-5'>
            <div className='col-12 text-center another-protein'>Try Another Protein?</div>
            <div className='col-8 mt-3 mb-2'>
              <select id='protein' className='form-control'>
                <option value='rabbit'>Rabbit</option>
                <option value='pork'>Pork</option>
                <option value='duck'>Duck</option>
                <option value='chicken'>Chicken</option>
                <option value='green-tripe'>Green Tripe</option>
                <option value='venison'>Venison</option>
                <option value='turkey'>Turkey</option>
                <option value='lamb'>Lamb</option>
                <option value='fish'>Fish</option>
              </select>
            </div>
            <div className='col-12 d-flex align-content-center justify-content-center'>
              <button type='submit' className='protein-submit-button' onClick={this.anotherProtein}>
                Submit
              </button>
            </div>
          </div>
          <div className='protein-pet-header text-center'>DOG</div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 protein-card align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Beef/stews.png'} />
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/stews/beef' href='/dog-food/stews/beef'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Beef/dogstew5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#ed5339' }}>
                    BEEF RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='beef-protein'
                    starCount={5}
                    value={this.state.reviews.dogStew.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogStew.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/stews/beef' href='/dog-food/stews/beef'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Beef/dogstew12.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#ed5339' }}>
                    BEEF RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='beef-protein'
                    starCount={5}
                    value={this.state.reviews.dogStew.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogStew.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 protein-card align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Beef/loafs.png'} />
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/loafs/beef' href='/dog-food/loafs/beef'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Beef/dogloaf12.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#ed5339' }}>
                    BEEF RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='beef-protein'
                    starCount={5}
                    value={this.state.reviews.dogLoaf.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogLoaf.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 protein-card align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Beef/just-juicy.png'} />
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/just-juicy/beef' href='/dog-food/just-juicy/beef'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Beef/dogjuicy5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#ed5339' }}>
                    BEEF RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='beef-protein'
                    starCount={5}
                    value={this.state.reviews.dogJuicy.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogJuicy.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/just-juicy/beef' href='/dog-food/just-juicy/beef'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Beef/dogjuicy12.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#ed5339' }}>
                    BEEF RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='beef-protein'
                    starCount={5}
                    value={this.state.reviews.dogJuicy.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogJuicy.total}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BeefMobile;
