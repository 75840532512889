const shopItem = {
  totes: {
    dog: {
      name: 'Lotus Dog Guitar Tote',
      price: '3.00',
      SKU: '950069-001',
      totesImgs: [
        {
          main: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/dog+640-01.png',
          zoom: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/dog+1920-01.png',
          thumb: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/dog+320-01.png',
        },
      ],
      description: [
        'Polypropylene Woven Laminate Bag',
        'Artwork by award-winning artist, Nicholas Wilton',
        'Dimensions: 15”W x 14”H x 8”D',
      ],
    },

    cat: {
      name: 'Lotus Cat Bird Watcher',
      price: '3.00',
      SKU: '950069-001',
      totesImgs: [
        {
          main:
            'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/cat-totes/bird+watcher+640-01.png',
          zoom:
            'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/cat-totes/bird+watcher+1920-01.png',
          thumb:
            'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/cat-totes/bird+watcher+320-01.png',
        },
        {
          main:
            'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/cat-totes/bird+watcher+back+640-01.png',
          zoom:
            'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/cat-totes/bird+watcher+back+1920-01-01.png',
          thumb:
            'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/cat-totes/bird+watcher+back+320-01.png',
        },
      ],
      description: [
        'Polypropylene Woven Laminate Bag',
        'Artwork by award-winning artist, Nicholas Wilton',
        'Dimensions: 15”W x 14”H x 8”D',
      ],
    },
  },

  shirts: {
    'happy-camper': {
      name: 'Happy Camper T-Shirt',
      price: '20.00',
      type: 'Ring Spun Cotton Shirt',
      shirtColors: [
        { color: 'red', hex: '#632121' },
        { color: 'pink', hex: '#ECAED0' },
      ],
      SKU: {
        'Small-Red': '20002-001',
        'Medium-Red': '20002-002',
        'Large-Red': '20002-003',
        'X-Large-Red': '20002-004',
        '2X-Large-Red': '20002-005',
        'Small-Pink': '20003-001',
        'Medium-Pink': '20003-002',
        'Large-Pink': '20003-003',
        'XLarge-Pink': '20003-004',
        '2-XLarge-Pink': '20003-005',
      },
      shirtImages: {
        red: [
          {
            main:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/happy-camper/happy-camper-red-640.png',
            zoom:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/happy-camper/happy-camper-red-1920.png',
            thumb:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/happy-camper/happy-camper-red-320.png',
          },

          {
            main:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/happy-camper/happy-camper-red-back-640.png',
            zoom:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/happy-camper/happy-camper-red-back-1920.png',
            thumb:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/happy-camper/happy-camper-red-back-320.png',
          },
        ],
        pink: [
          {
            main:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/happy-camper/happy-camper-pink-640.png',
            zoom:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/happy-camper/happy-camper-pink-1920.png',
            thumb:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/happy-camper/happy-camper-pink-320.png',
          },
          {
            main:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/happy-camper/pink-shirt-back-640.png',
            zoom:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/happy-camper/pink-shirt-back-1920.png',
            thumb:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/happy-camper/pink-shirt-back-320.png',
          },
        ],
      },
      description: [
        ' Fabric: Fine Jersey',
        '100% Combed Ring-Spun Cotton',
        'Neck: Crew Neck',
        'Binding: Set-in Collar 1x1 Baby Rib; Side Seamed',
      ],
    },

    'bird-watcher': {
      name: 'Bird Watcher T-Shirt',
      price: '20.00',
      type: 'Ring Spun Cotton Shirt',
      shirtColors: [{ color: 'black', hex: '#0B0B0B' }],
      SKU: {
        Small: '20001-001',
        Medium: '20001-002',
        Large: '20001-003',
        'X-large': '20001-004',
        '2X-Large': '20001-005',
      },
      shirtImages: {
        black: [
          {
            main:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/bird-watcher/bird-watcher-640.png',
            zoom:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/bird-watcher/bird-watcher-1920.png',
            thumb:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/bird-watcher/bird-watcher-320.png',
          },
          {
            main:
              'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Shop/bird-watcher/640x640-back-2.png',
            zoom:
              'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Shop/bird-watcher/back-of-shirt-2.png',
            thumb:
              'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Shop/bird-watcher/320-x-320--back-2.png',
          },
        ],
      },
      description: [
        'Fabric: Fine Jersey',
        '100% Combed Ring-Spun Cotton',
        'Neck: Crew Neck',
        'Binding: Set-in Collar 1x1 Baby Rib; Side Seamed',
      ],
    },
    'lotus-raw': {
      name: 'Bird Watcher T-Shirt',
      price: '20.00',
      type: 'Ring Spun Cotton Shirt',
      shirtColors: [{ color: 'olive', hex: '#97896e' }],
      SKU: {
        Small: '20001-001',
        Medium: '20001-002',
        Large: '20001-003',
        'X-large': '20001-004',
        '2X-Large': '20001-005',
      },
      shirtImages: {
        olive: [
          {
            main:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/raw-shirt/dog-raw-front-640.png',
            zoom:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/raw-shirt/dog-raw-front-1920.png',
            thumb:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/raw-shirt/dog-raw-front-320.png',
          },
          {
            main:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/raw-shirt/dog-raw-back-640.png',
            zoom:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/raw-shirt/dog-raw-back-1920.png',
            thumb:
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/raw-shirt/dog-raw-back-320.png',
          },
        ],
      },
      description: [
        ' Fabric: Fine Jersey',
        '100% Combed Ring-Spun Cotton',
        'Neck: Crew Neck',
        'Binding: Set-in Collar 1x1 Baby Rib; Side Seamed',
      ],
    },
  },
};

module.exports = {
  helper: {
    /**
     * @param {Object} options { itemCategory, itemType, currentColor, shirt }
     * @param {String} itemCat
     * @param {String} itemType
     * @return {Object} object to render for Shop Item
     */

    setShopitemToRender: (options) => {
      const noContent = undefined;
      const { itemType, itemCat } = noContent || options;
      const shopItemInfo = shopItem[itemCat][itemType];

      return shopItemInfo;
    },

    /**
     * @param {Object} options {itemCat, SKU};
     * @return {Object[] || String}
     */
    setSkuForOptions: (options) => {
      const noContent = undefined;
      const { itemCat, SKU } = noContent || options;
      if (itemCat === 'totes' && typeof SKU === 'string') return SKU;

      return Object.keys(SKU).map((key) => {
        return { text: key, value: SKU[key] };
      });
    },

    /**
     * @param {Objec[]} imgArr
     * @return {String}
     */
    setMainImgSrc: (imgArr) => {
      const [firstImgObj] = imgArr;
      return firstImgObj.main;
    },

    /**
     * @param {Object[]} imgArr
     * @return {Object[]} array containing zoomImg
     */
    setLightBoxImgArr: (imgArr) => {
      return imgArr.map((img) => img.zoom);
    },
  },
};
