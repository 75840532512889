import React, { Component, Fragment } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTabContent,
  MDBTabPane,
  MDBNav,
  MDBNavItem,
  MDBAnimation,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from 'mdbreact';
import { Link, Route } from 'react-router-dom';
import Axios from 'axios';

import history from '../../../../History/History.jsx';
import IsLoadingChild from '../../../../IsLoading/IsLoadingChild.jsx';

import NewStore from './StoreComps/NewStore.jsx';
import StoreEdit from './StoreComps/StoreEdit.jsx';
import StoreCard from './StoreComps/StoreCard.jsx';
import StoreSearch from './StoreComps/StoreSearch.jsx';

const {
  endPoint: { retailStores },
} = require('../../../../_helper/stringVars');

const { setToken, tokenStrOnly } = require('../../../../_helper/index.jsx');
const { confirmStoreDelete } = require('./helper.jsx');
const { success } = require('../../../../_helper/SweetAlerts/alerts.jsx');
const { handleErrors } = require('../../../../_helper/reqsHandlers');

export default class Stores extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: '0',
      stores: [],
      storesLoaded: false,
      storeEditModal: false,
      additionalStoresLoaded: false,
      query: '',
      searchBy: 'name',
      page: 1,
      typingTimeout: 0,
      searchUsed: false,
      previousStoreLength: 0,
      searchOps: [
        { text: 'Address', value: 'address' },
        { text: 'Store name', value: 'name' },
        { text: 'Store Id', value: '_id' },
      ],
    };

    this.token = { headers: setToken('admin') };
    this.tokenString = tokenStrOnly('admin');
    this.adminUrlPath = window.location.href.indexOf('sales') > -1 ? 'sales' : 'admin';
  }

  componentDidMount() {
    this.getStores({ page: 1 });
  }

  /**
   * getSTores from DB
   * @param {Object} options {page, searchBy, query}
   * @return setState for stores
   */
  getStores = async (options) => {
    const nonContent = undefined;
    const { page, searchBy, query, searched, previousStoreLength } = nonContent || options;

    const context = this;
    try {
      const storesInfo = await Axios.get(`${retailStores.admin}/get-stores`, {
        params: { page, searchBy, query, previousStoreLength },
        headers: { Authorization: tokenStrOnly('admin') },
      });

      setTimeout(
        () =>
          context.setState((prevState) => ({
            stores: searched ? storesInfo.data : prevState.stores.concat(storesInfo.data),
            storesLoaded: true,
            additionalStoresLoaded: true,
          })),
        500,
      );
    } catch (err) {
      console.error(err);
      const { status } = err.response;
      if (status === 400) return handleErrors(400);
      handleErrors(500);
    }
  };

  toggleTab = (tab) => {
    const { currentTab } = this.state;
    if (currentTab !== tab) {
      this.setState({ currentTab: tab });
    }
  };

  /* Start of Stores btn Func */
  editStore = () => {
    const { page } = this.state;
    if (this.state.storeEditModal) {
      this.getStores({ page, searched: true });
      history.push(`/${this.adminUrlPath}/main/stores`);
      this.setState({
        storeEditModal: !this.state.storeEditModal,
      });
    } else {
      this.setState({
        storeEditModal: !this.state.storeEditModal,
      });
    }
  };

  /**
   * @param {Number} _id
   * @param {String} storeName
   * @return
   */
  deleteStore = async (_id, storeName, e) => {
    e.preventDefault();

    const { page } = this.state;
    try {
      const confirmDelete = await confirmStoreDelete(storeName);

      if (confirmDelete) {
        const { status } = await Axios.delete(`${retailStores.admin}/delete-store`, {
          data: { _id },
          headers: { Authorization: tokenStrOnly('admin') },
        });
        if (status === 200) {
          success({
            title: 'Store Deleted',
            text: 'Store successfully deleted',
            button: 'Okay',
          });
          return this.getStores({ page, searched: true });
        }
      }
    } catch (err) {
      console.error(err);
      const { status } = err.response;
      if (status === 400) return handleErrors(400);
      if (status === 404) return handleErrors(404);
      handleErrors(500);
    }
  };
  /* End of Stores btn Func */

  /**
   * Pagination method to get more store from DB
   * @param {}
   * @return
   */
  storePagination = async () => {
    const { page } = this.state;
    this.setState({ additionalStoresLoaded: false });
    try {
      this.setState((prevState) => {
        return { page: prevState.page + 1 };
      });
      this.getStores({ page });
    } catch (err) {
      console.error(err);
      handleErrors(500);
    }
  };

  _onChange = (e) => {
    const { value, name } = e.target;
    const { typingTimeout, searchBy, stores, previousStoreLength } = this.state;
    const context = this;

    const storesLen = stores.length > previousStoreLength ? stores.length : previousStoreLength;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      [name]: value,
      storesLoaded: false,
      searchUsed: true,
      previousStoreLength: storesLen,
      typingTimeout: setTimeout(() => {
        context.getStores({ searchBy, query: value, searched: true });
      }, 2000),
    });
  };

  goBackToAllStores = () => {
    const { page, previousStoreLength } = this.state;
    this.setState({
      searchUsed: false,
      storesLoaded: false,
      stores: [],
    });
    this.getStores({ page, previousStoreLength });
  };

  searchStores = () => {
    const { searchBy, stores, previousStoreLength, query } = this.state;
    const storesLen = stores.length > previousStoreLength ? stores.length : previousStoreLength;

    this.setState({
      stores: this.getStores({ searchBy, query, searched: true }),
      previousStoreLength: storesLen,
      searchUsed: true,
      storesLoaded: false,
    });
  };

  /**
   * @param {String} value
   * @return {String} setState for searchBy
   */
  getValue = (value) => {
    const [searchBy] = value;
    this.setState({
      searchBy,
    });
  };

  render() {
    const category = ['Edit stores', 'Add stores'];
    const { state, props } = this;

    return (
      <div className="admin-main__admin-stores">
        <MDBContainer className="admin-main__navtabs classic-tabs" id="admin-stores">
          <MDBAnimation type="fadeIn">
            <MDBNav classicTabs color="primary" className="mt-5">
              {category.map((StoreFunc, i) => (
                <Fragment key={i}>
                  <MDBNavItem>
                    <Link
                      className={`text-dark font-weight-bolder futura-font ${
                        state.currentTab === String(i) ? 'active' : ''
                      }`}
                      to="#"
                      onClick={() => this.toggleTab(String(i))}
                      role="tab">
                      {StoreFunc}
                    </Link>
                  </MDBNavItem>
                </Fragment>
              ))}
            </MDBNav>
            <MDBTabContent className="admin-main__tab-content" activeItem={state.currentTab}>
              <MDBTabPane tabId="0" role="tabpanel">
                <MDBRow>
                  <MDBCol size="12">
                    {state.searchUsed ? (
                      <MDBBtn className="ml-0" onClick={this.goBackToAllStores} color="dark">
                        Go Back to all stores
                      </MDBBtn>
                    ) : (
                      <h2 className="admin-main__header2 futura-font">All Stores</h2>
                    )}
                  </MDBCol>
                  <MDBCol size="12">
                    <StoreSearch
                      query={state.query}
                      searchBy={state.searchBy}
                      searchStores={this.searchStores}
                      getValue={this.getValue}
                      searchOps={state.searchOps}
                      onChange={this._onChange}
                    />
                  </MDBCol>
                  {/* Render Stores */}
                  <MDBCol size="12">
                    <MDBRow>
                      {!state.storesLoaded ? (
                        <IsLoadingChild />
                      ) : (
                        state.stores.map((store, i) => {
                          return (
                            <Fragment key={i}>
                              <MDBCol className="mx-0 my-3" size="12" sm="6" lg="4">
                                <StoreCard
                                  editStore={this.editStore}
                                  deleteStore={this.deleteStore}
                                  store={store}
                                  storeEditModal={state.storeEditModal}
                                />
                              </MDBCol>
                            </Fragment>
                          );
                        })
                      )}
                      {!state.additionalStoresLoaded && !state.stores.length < 1 ? (
                        <IsLoadingChild />
                      ) : null}
                    </MDBRow>
                  </MDBCol>
                  {/* End of render stores */}

                  <MDBCol>
                    {!state.searchUsed ? (
                      <MDBBtn className="ml-0" onClick={this.storePagination} color="dark">
                        Load more...
                      </MDBBtn>
                    ) : null}
                  </MDBCol>
                </MDBRow>
              </MDBTabPane>
              <MDBTabPane className="admin-stores__new-store-wrapper" tabId="1" role="tabpanel">
                <NewStore />
              </MDBTabPane>
            </MDBTabContent>
          </MDBAnimation>

          {/* Edit store modal */}
          <MDBModal
            size="lg"
            centered
            animation="bottom"
            className="modal-notify modal-primary text-white"
            isOpen={state.storeEditModal}
            toggle={this.editStore}>
            <MDBModalHeader tag="p" toggle={this.editStore}>
              Store Editor:
            </MDBModalHeader>
            <MDBModalBody>
              <Route
                path={`/${this.adminUrlPath}/main/stores/edit-store/:storeId`}
                component={StoreEdit}
              />
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </div>
    );
  }
}
