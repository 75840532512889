import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class FleaTick extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
      }

  render() {
    return (
        <div>
                <div className="pb-3 blog-margin-adjustment-desktop">
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/flea-banner.png'} 
                        className="banner img-fluid w-100" 
                        alt="lotus Pet Foods - Flea and Tick"/>
                    </div>
                </MDBAnimation>
                </div>
            
            <div className='container mt-5'>
                <p style={{"color": "grey"}}>June, 01, 2022</p>
            </div>
       
            <div className='container mt-5 text-center d-none-mobile'>
                <h1><span className="LemonMilkbold-font blog-hb">Flea and Tick</span></h1>
            </div>

            <div className='container mt-5 text-center d-none-desktop'>
                <h1><span className="LemonMilkbold-font blog-h">Flea and Tick</span></h1>
            </div>
  
            <div className='container' style={{"marginTop": "60px"}}>
                <div className='mb-5 futura-font blog-p'>
                    <p>When it comes to enjoying time outside with our best friends, the last thing you want to worry about is bugs crawling all over them. 
                        Whether you’re using prescription flea & tick prevention or prefer au natural products – We’re going to cover all the options to keep 
                        your 4 legged family members safe & healthy.</p>
                    <br />
                    <p>Fleas & ticks are not only a pesky problem, they’re carriers of diseases and can cause major health problems for your dog or cat, like dermatitis, 
                        tapeworms, and Lyme disease.  Prevention is the best way to avoid problems caused by these jumping bugs. There are several types of flea & tick 
                        preventative that you can get as a prescription. This requires a trip to the vet to have your pet weighed, checked for any underlying issues associated 
                        with flea and ticks, like heartworm. Depending on where you live, your lifestyle and preference, you will be able to choose from an oral flea and tick 
                        medication – which comes as chewable or pills. Or a topical treatment which is applied between the shoulder blades.</p>
                    <br />
                    <p>Oral tablets maybe a preferred method of treatment of flea & ticks If the family has small children, the oral medications are ingested by your pet & then 
                        transmitted to the adult bugs when they bite your pet, causing the adults to die and/or so the larva they lay cannot be developed. Topical treatments are 
                        effective but families with children may have to quarantine their dog for 48hr after application.  They are typically applied once a month, between the 
                        shoulder blades of your pet, they work by killing fleas & ticks (sometimes even mosquitos) by depositing chemicals into the skin. The medication seeps into 
                        the sebaceous glands of the pet  which releases and translocates through the glands that lubricate a cat or dogs coat with oil.These pesticides will attack 
                        the flea & tick nervous systems so they cannot feed on your pets.  It’s important to note that the treatment must be applied between the shoulder blades so 
                        the pet cannot lick the medication.</p>
                     <br />
                     <p>Over the counter flea & tick prevention can be effective as well. Your local pet store (insert store locator link here) can help determine which option is best
                        for you and your pet. While some argue that over the counter products are not as effective as what you can get from your vet – there are safe and reliable options.  
                        There will be oral tabs, topical treatments, shampoos, powders and essential oil based preventative options. </p>
                     <br />
                     <p>Collar preventatives are also available at your neighborhood pet store, but many have been proven to be ineffective, except for the seresto collar. It’s for dogs 
                        and cats and effective for up to 8 months. It works like topical treatments with a slow release of medication over 8 months.</p>
                     <br />
                     <p>Many pet owners have been looking for less toxic, more natural products for their best friends, there are many products on the market for you to choose from. 
                        Powders, essential oil based topical treatments, natural treats and medication. Your local pet shop (again link here for store locator) may have a powder called 
                        Diatomaceas Earth (DE) that can eliminate fleas. It’s important you get food grade Diatomaceas earth, it’s the only approved DE for pets. You can put DE on your 
                        pets food daily to help deter fleas & other bugs, or put it on their fur. This option can get messy, but is one of the most natural options.</p>
                     <br />
                     <p>A topical treatment called Natural Defense is an essential oil- based treatment that is applied between the dog or cats shoulder blades, it uses botanical extracts 
                        that provide natural protection from flea & ticks for up to 4 months.</p>
                     <br />
                     <p>If you need something quick & convenient, look for a flea & tick spray. This is best for dogs, not cats. This option may be best for you if your pets are not outside 
                        a lot, but you’re going to a bonfire, or a once in a while hike – this will work as a bug spray for your dog, the spray will be effective in repelling fleas and other 
                        pests from your dog.  There are several options for sprays on the shelf at your local pet store.  However, if you’re going into a tick heavy area, I would recommend 
                        thinking a head and making sure your pet is protected. Fleas & ticks can create a multitude of problems for your furry family members, and because of the different 
                        types of treatment, please discuss with your vet or local pet store which treatment option is best for you and your pet’s lifestyle. </p>
                </div>
             </div>
        </div>
    );
  }
}

export default FleaTick;