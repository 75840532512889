import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class Locations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.refreshPage = this.refreshPage.bind(this);
  }

  componentDidMount() {
    let max = 0;
    for (let i = 0; i < $('.location-text-container').length; i++) {
      if ($('.location-text-container')[i].clientHeight > max) {
        max = $('.location-text-container')[i].clientHeight;
      }
    }

    $('.location-text-container').height(max);

    if (window.innerWidth <= 768) {
      for (let i = 0; i < this.props.markers.length; i++) {
        if (i % 2) {
          $(`#location-col${i}`)
            .removeClass('.px-sm-2')
            .css('padding-left', '7.5px');
        } else {
          $(`#location-col${i}`)
            .removeClass('.px-sm-2')
            .css('padding-right', '7.5px');
        }
      }
    }
  }

  refreshPage() {
    window.location.reload();
  }

  render() {
    return (
      <div>
        <div className="locations-container">
          <div className="row">
            <div className="col buy-lotus">BUY LOTUS THROUGH OUR TRUSTED RETAILERS</div>
          </div>
          <div className="row">
            <div className="col page-divider mt-2 mb-4" />
          </div>
          <div className="row my-3">
            <div className="col search-another" onClick={this.refreshPage}>
              Search another location?
            </div>
          </div>
          {this.props.type !== 'current' && (
            <div className="form-group row align-items-center h-100">
              <label
                htmlFor="us-search-radius"
                className="col-sm-auto col-12 col-form-label location-search-text-label">
                Search Radius
              </label>
              <div className="col-lg-2 col-md-3">
                <select
                  id="us-search-radius"
                  className="form-control"
                  onChange={this.props.searchRadius}>
                  <option value="5">5 miles</option>
                  <option value="10">10 miles</option>
                  <option value="15">15 miles</option>
                  <option value="25">25 miles</option>
                  <option value="50">50 miles</option>
                </select>
              </div>
            </div>
          )}
          {this.props.type === 'city' && (
            <div className="form-group row align-items-center h-100">
              <label
                htmlFor="city"
                className="col-sm-auto col-12 col-form-label location-search-text-label">
                City
              </label>
              <div className="col-lg-2 col-md-3">
                <input
                  className="form-control"
                  id="city"
                  type="text"
                  onKeyPress={this.props.cityStateEnter}
                  placeholder="Enter City..."
                />
                <div className="search-error" id="city-error">
                  Please provide a valid city.
                </div>
              </div>
              <label
                htmlFor="state"
                className="col-sm-auto col-12 col-form-label location-search-text-label">
                State
              </label>
              <div className="col-lg-2 col-md-3">
                <select id="state" className="form-control">
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
              <div className="col-md-auto col-12">
                <button
                  type="submit"
                  className="location-search-button"
                  onClick={this.props.cityState}>
                  Search
                </button>
              </div>
            </div>
          )}
          {this.props.type === 'zip' && (
            <div className="form-group row align-items-center h-100">
              <label
                htmlFor="zip"
                className="col-sm-auto col-12 col-form-label location-search-text-label">
                Zip Code
              </label>
              <div className="col-xl-2 col-md-3">
                <input
                  className="form-control"
                  id="zip"
                  type="text"
                  onKeyPress={this.props.zipcodeEnter}
                  placeholder="Enter Zip Code..."
                />
                <div className="search-error" id="zip-error">
                  Please provide a valid zip code.
                </div>
              </div>
              <div className="col-md-auto col-12">
                <button
                  type="submit"
                  className="location-search-button"
                  onClick={this.props.zipcode}>
                  Search
                </button>
              </div>
            </div>
          )}
          {this.props.type === 'address' && (
            <div className="form-group row align-items-center h-100">
              <label
                htmlFor="address"
                className="col-sm-auto col-12 col-form-label location-search-text-label">
                Address
              </label>
              <div className="col-lg-3 col-md-4">
                <input
                  className="form-control"
                  id="address"
                  type="text"
                  onKeyPress={this.props.addressEnter}
                  placeholder="Enter Address..."
                />
                <div className="search-error" id="address-error">
                  Please provide a valid address.
                </div>
              </div>
              <div className="col-md-auto col-12">
                <button
                  type="submit"
                  className="location-search-button"
                  onClick={this.props.address}>
                  Search
                </button>
              </div>
            </div>
          )}
          {this.props.type === 'int-city' && (
            <div className="form-group row align-items-center h-100">
              <label
                htmlFor="country"
                className="col-sm-auto col-12 col-form-label location-search-text-label">
                Country
              </label>
              <div className="col-lg-2 col-md-3">
                <select id="country" className="form-control">
                  <option value="CN">China</option>
                  <option value="JP">Japan</option>
                  <option value="KR">South Korea</option>
                  <option value="TW">Taiwan</option>
                </select>
              </div>
              <label
                htmlFor="int-city"
                className="col-sm-auto col-12 col-form-label location-search-text-label">
                City
              </label>
              <div className="col-lg-2 col-md-3">
                <input
                  className="form-control"
                  id="int-city"
                  type="text"
                  onKeyPress={this.props.countryCityEnter}
                  placeholder="Enter City..."
                />
                <div className="search-error" id="int-city-error">
                  Please provide a valid city.
                </div>
              </div>
              <div className="col-md-auto col-12">
                <button
                  type="submit"
                  className="location-search-button"
                  onClick={this.props.countryCity}>
                  Search
                </button>
              </div>
            </div>
          )}
          {this.props.type === 'postal-code' && (
            <div className="form-group row align-items-center h-100">
              <label
                htmlFor="postal-code"
                className="col-sm-auto col-12 col-form-label location-search-text-label">
                Postal Code
              </label>
              <div className="col-xl-2 col-md-3">
                <input
                  className="form-control"
                  id="postal-code"
                  type="text"
                  onKeyPress={this.props.postalCodeEnter}
                  placeholder="Enter Postal Code..."
                />
                <div className="search-error" id="postal-error">
                  Please provide a valid postal code.
                </div>
              </div>
              <div className="col-md-auto col-12">
                <button
                  type="submit"
                  onClick={this.props.postalCode}
                  className="location-search-button">
                  Search
                </button>
              </div>
            </div>
          )}
          {this.props.type === 'int-address' && (
            <div className="form-group row align-items-center h-100">
              <label
                htmlFor="int-address"
                className="col-sm-auto col-12 col-form-label location-search-text-label">
                Address
              </label>
              <div className="col-lg-3 col-md-4">
                <input
                  className="form-control"
                  id="int-address"
                  type="text"
                  onKeyPress={this.props.intAddressEnter}
                  placeholder="Enter Address..."
                />
                <div className="search-error" id="int-address-error">
                  Please provide a valid address.
                </div>
              </div>
              <div className="col-md-auto col-12">
                <button
                  type="submit"
                  className="location-search-button"
                  onClick={this.props.intAddress}>
                  Search
                </button>
              </div>
            </div>
          )}
          <div className="row mt-4 mb-5">
            {this.props.markers.map((location, i) => {
              return (
                <div
                  id={`location-col${i}`}
                  key={i}
                  title={i}
                  className=" col-6 col-md-4 col-xl-3 mb-sm-5 my-3">
                  <div
                    className="more-info w-100 justify-content-center d-flex align-items-center"
                    style={{ background: this.props.color }}
                  />

                  <div className="location-img-container">
                    <img className="location-img" src={location.picture_url} />
                  </div>

                  <div
                    className="location-text-container px-3 py-2 justify-content-center d-flex align-items-center"
                    style={{ flexDirection: 'column' }}>
                    <div className="text-center location-name" style={{ color: this.props.color }}>
                      {location.name}
                    </div>
                    <div className="text-center mt-2 location-address text-truncate">
                      {location.line1}
                    </div>
                    <div className="text-center location-address text-truncate">
                      {location.line2}
                    </div>
                    <div className="text-center mt-2 location-phone-number text-truncate">
                      {location.phone}
                    </div>
                  </div>
                  {location.website_url.length > 1 ? (
                    <div className="visit-site w-100 justify-content-center d-flex align-items-center">
                      <a
                        className="visit-site-link"
                        target="_blank"
                        href={`http://${location.website_url}`}>
                        Visit Site
                      </a>
                    </div>
                  ) : (
                    <div className="visit-site w-100 justify-content-center d-flex align-items-center">
                      <a
                        className="visit-site-link"
                        target="_blank"
                        href={`http://${location.website_url}`}
                        style={{ display: 'none' }}>
                        Visit Site
                      </a>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Locations;
