import React from 'react';
import { MDBRow, MDBCol, MDBInput } from 'mdbreact';
import SimpleInputField from '../FieldComponents/InputFields/InputField.jsx';

export default function ProductReviewInput(props) {
  return (
    <MDBRow>
      <MDBCol size="6">
        <MDBRow>
          <SimpleInputField
            name="title"
            label="Review title"
            value={props.title}
            onChange={props.onChange}
          />
        </MDBRow>
      </MDBCol>
      <MDBCol size="12">
        <MDBInput
          type="textarea"
          label="Write your review"
          row="2"
          name="review"
          value={props.review}
          onChange={props.onChange}
        />
      </MDBCol>
    </MDBRow>
  );
}
