import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
  InfoWindow,
} from 'react-google-maps';
import swal from 'sweetalert2';
import ScrollAnimation from 'react-animate-on-scroll';
import Markers from './Markers.jsx';
import Locations from './Locations.jsx';
import IsLoadingChild from '../../IsLoading/IsLoadingChild.jsx';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from 'mdbreact';
import { Link } from 'react-router-dom';

const {
  endPoint: { retailStores },
} = require('../../_helper/stringVars');

const distance = (lat1, lng1, lat2, lng2) => {
  if (lat1 === lat2 && lng1 === lng2) {
    return 0;
  }
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lng1 - lng2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  return dist;
};

class StoreLocator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      allLocations: [],
      lat: 41,
      lng: -97,
      zoom: 5,
      searchRadius: 15,
      circleRadius: 24140.16,
      animation: null,
      showCircle: false,
      showLocations: false,
      showUnitedStates: true,
      showInternational: false,
      showOnline: false,
      searchType: null,
      color: null,
      storesLoaded: false,
      displayTaiwanCity: false,
    };
  }

  componentDidMount() {
    if (window.innerWidth <= 1280) {
      this.setState({
        zoom: 3,
      });
    }
    this.getStores();
    window.scrollTo(0, 0);
  }

  getStores = async () => {
    const context = this;
    try {
      const storesInfo = await axios.get(`${retailStores.client}/get-stores`);

      const markers = await Promise.all(
        storesInfo.data.map(async (store) => {
          const y = store.address.split(',');
          const a = y[0];
          const b = `${y[1]},${y[2]}`;
          store.line1 = a;
          store.line2 = b;
          return store;
        }),
      );
      setTimeout(
        () =>
          context.setState({
            markers,
            allLocations: markers,
            storesLoaded: true,
          }),
        1000,
      );
    } catch (err) {
      console.error(err);
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.showUnitedStates || nextState.showInternational || nextState.showOnline) {
      return true;
    }
    return this.state.lat !== nextState.lat;
  }

  currentLocation = () => {
    let zoom = 10;

    if (window.innerWidth <= 768) {
      zoom = 9;
    }

    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(boundPosition);
      } else {
        alert('Geolocation is not supported by this browser.');
      }
    }

    function showPosition(position) {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      const markers = [];

      for (let i = 0; i < this.state.allLocations.length; i++) {
        if (
          distance(lat, lng, this.state.allLocations[i].lat, this.state.allLocations[i].lng) <=
          this.state.searchRadius
        ) {
          markers.push(this.state.allLocations[i]);
        }
      }
      this.setState({
        markers,
        lat,
        lng,
        zoom,
        animation: 2,
        showCircle: true,
        showUnitedStates: false,
        showInternational: false,
        showLocations: true,
        searchType: 'current',
        color: '#e8d9b4',
      });
    }
    const boundPosition = showPosition.bind(this);
    getLocation();
    window.scrollTo(0, 0);
  };

  cityState = () => {
    let zoom = 10;
    let state;

    if (window.innerWidth <= 768) {
      zoom = 9;
      state = $('#state-mobile').val();
    } else {
      state = $('#state').val();
    }

    $('#city').css('border-color', '#ced4da');

    const city = $('#city').val();

    if (city === '') {
      $('#city').css('border-color', 'red');
      swal.fire({
        title: 'Please provide a valid city.',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      axios({
        method: 'post',
        url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${city}+${state}`,
      })
        .then((res) => {
          const lat = res.data.results[0].geometry.location.lat;
          const lng = res.data.results[0].geometry.location.lng;
          const markers = [];

          for (let i = 0; i < this.state.allLocations.length; i++) {
            if (
              distance(lat, lng, this.state.allLocations[i].lat, this.state.allLocations[i].lng) <=
              this.state.searchRadius
            ) {
              markers.push(this.state.allLocations[i]);
            }
          }

          this.setState({
            markers: markers,
            lat: lat,
            lng: lng,
            zoom: zoom,
            animation: 2,
            showCircle: true,
            showUnitedStates: false,
            showInternational: false,
            showLocations: true,
            searchType: 'city',
            color: '#f7c3af',
          });

          window.scrollTo(0, 0);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            $('#city').css('border-color', 'red');
            swal.fire({
              title: 'Please provide a valid city.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    }
  };

  cityStateEnter = (e) => {
    if (e.key === 'Enter') {
      let zoom = 10;
      let state;

      e.preventDefault();
      e.stopPropagation();

      if (window.innerWidth <= 768) {
        zoom = 9;
        state = $('#state-mobile').val();
      } else {
        state = $('#state').val();
      }

      $('#city').css('border-color', '#ced4da');

      const city = $('#city').val();

      if (city === '') {
        $('#city').css('border-color', 'red');
        swal.fire({
          title: 'Please provide a valid city.',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        axios({
          method: 'post',
          url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${city}+${state}`,
        })
          .then((res) => {
            const lat = res.data.results[0].geometry.location.lat;
            const lng = res.data.results[0].geometry.location.lng;
            const markers = [];

            for (let i = 0; i < this.state.allLocations.length; i++) {
              if (
                distance(
                  lat,
                  lng,
                  this.state.allLocations[i].lat,
                  this.state.allLocations[i].lng,
                ) <= this.state.searchRadius
              ) {
                markers.push(this.state.allLocations[i]);
              }
            }

            this.setState({
              markers: markers,
              lat: lat,
              lng: lng,
              zoom: zoom,
              animation: 2,
              showCircle: true,
              showUnitedStates: false,
              showInternational: false,
              showLocations: true,
              searchType: 'city',
              color: '#f7c3af',
            });

            window.scrollTo(0, 0);
          })
          .catch((err) => {
            if (err.response.status === 400) {
              $('#city').css('border-color', 'red');
              swal.fire({
                title: 'Please provide a valid city.',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
              });
            }
          });
      }
    }
  };

  zipcode = () => {
    let zoom = 10;

    if (window.innerWidth <= 768) {
      zoom = 9;
    }

    $('#zip').css('border-color', '#ced4da');

    const zipcode = $('#zip').val();

    axios({
      method: 'post',
      url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${zipcode}`,
    })
      .then((res) => {
        //console.log(res);
        const lat = res.data.results[0].geometry.location.lat;
        const lng = res.data.results[0].geometry.location.lng;
        const markers = [];

        for (let i = 0; i < this.state.allLocations.length; i++) {
          if (
            distance(lat, lng, this.state.allLocations[i].lat, this.state.allLocations[i].lng) <=
            this.state.searchRadius
          ) {
            markers.push(this.state.allLocations[i]);
          }
        }

        this.setState({
          markers: markers,
          lat: lat,
          lng: lng,
          zoom: zoom,
          animation: 2,
          showCircle: true,
          showUnitedStates: false,
          showInternational: false,
          showLocations: true,
          searchType: 'zip',
          color: '#ffcf51',
        });

        window.scrollTo(0, 0);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          $('#zip').css('border-color', 'red');
          swal.fire({
            title: 'Please provide a valid zip code.',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  zipcodeEnter = (e) => {
    if (e.key === 'Enter') {
      let zoom = 10;

      if (window.innerWidth <= 768) {
        zoom = 9;
      }

      e.preventDefault();
      e.stopPropagation();

      $('#zip').css('border-color', '#ced4da');

      const zipcode = $('#zip').val();

      axios({
        method: 'post',
        url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${zipcode}`,
      })
        .then((res) => {
          const lat = res.data.results[0].geometry.location.lat;
          const lng = res.data.results[0].geometry.location.lng;
          const markers = [];

          for (let i = 0; i < this.state.allLocations.length; i++) {
            if (
              distance(lat, lng, this.state.allLocations[i].lat, this.state.allLocations[i].lng) <=
              this.state.searchRadius
            ) {
              markers.push(this.state.allLocations[i]);
            }
          }

          this.setState({
            markers: markers,
            lat: lat,
            lng: lng,
            zoom: zoom,
            animation: 2,
            showCircle: true,
            showUnitedStates: false,
            showInternational: false,
            showLocations: true,
            searchType: 'zip',
            color: '#ffcf51',
          });

          window.scrollTo(0, 0);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            $('#zip').css('border-color', 'red');
            swal.fire({
              title: 'Please provide a valid zip code.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    }
  };

  address = () => {
    let zoom = 10;

    if (window.innerWidth <= 768) {
      zoom = 9;
    }

    $('#address').css('border-color', '#ced4da');

    const address = $('#address').val();
    const encodedAddress = encodeURI(address);

    axios({
      method: 'post',
      url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${encodedAddress}`,
    })
      .then((res) => {
        const lat = res.data.results[0].geometry.location.lat;
        const lng = res.data.results[0].geometry.location.lng;
        const markers = [];

        for (let i = 0; i < this.state.allLocations.length; i++) {
          if (
            distance(lat, lng, this.state.allLocations[i].lat, this.state.allLocations[i].lng) <=
            this.state.searchRadius
          ) {
            markers.push(this.state.allLocations[i]);
          }
        }

        this.setState({
          markers: markers,
          lat: lat,
          lng: lng,
          zoom: zoom,
          animation: 2,
          showCircle: true,
          showUnitedStates: false,
          showInternational: false,
          showLocations: true,
          searchType: 'address',
          color: '#f47821',
        });

        window.scrollTo(0, 0);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          $('#address').css('border-color', 'red');
          swal.fire({
            title: 'Please provide a valid address.',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  addressEnter = (e) => {
    if (e.key === 'Enter') {
      let zoom = 10;

      if (window.innerWidth <= 768) {
        zoom = 9;
      }

      e.preventDefault();
      e.stopPropagation();

      $('#address').css('border-color', '#ced4da');

      const address = $('#address').val();
      const encodedAddress = encodeURI(address);

      axios({
        method: 'post',
        url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${encodedAddress}`,
      })
        .then((res) => {
          const lat = res.data.results[0].geometry.location.lat;
          const lng = res.data.results[0].geometry.location.lng;
          const markers = [];

          for (let i = 0; i < this.state.allLocations.length; i++) {
            if (
              distance(lat, lng, this.state.allLocations[i].lat, this.state.allLocations[i].lng) <=
              this.state.searchRadius
            ) {
              markers.push(this.state.allLocations[i]);
            }
          }

          this.setState({
            markers: markers,
            lat: lat,
            lng: lng,
            zoom: zoom,
            animation: 2,
            showCircle: true,
            showUnitedStates: false,
            showInternational: false,
            showLocations: true,
            searchType: 'address',
            color: '#f47821',
          });

          window.scrollTo(0, 0);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            $('#address').css('border-color', 'red');
            swal.fire({
              title: 'Please provide a valid address.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    }
  };

  countryCity = (e) => {
    let zoom = 10;

    if (window.innerWidth <= 768) {
      zoom = 9;
    }

    e.preventDefault();
    e.stopPropagation();

    $('#int-city').css('border-color', '#ced4da');

    const city = $('#int-city').val();
    const country = $('#country').val();
    const taiwanCity = $('#taiwan-city').val();
    const encodedCity = encodeURI(city);

    if (city === '') {
      $('#int-city').css('border-color', 'red');
      swal.fire({
        title: 'Please provide a valid city.',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (country.toLowerCase() === 'cn' && city.toLowerCase() === 'taiwan') {
      // Hack to make sure that when customer search China
      // and input Taiwan, it will point over to Taiwan
      const encodedTaiwanCity = encodeURI(taiwanCity);
      axios({
        method: 'post',
        url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${encodedTaiwanCity}&components=country:${city}`,
      })
        .then((res) => {
          if (res.data.status === 'ZERO_RESULTS') {
            $('#int-city').css('border-color', 'red');
            swal.fire({
              title: 'Please provide a valid city.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1500,
            });
          }

          const lat = res.data.results[0].geometry.location.lat;
          const lng = res.data.results[0].geometry.location.lng;
          const markers = [];

          for (let i = 0; i < this.state.allLocations.length; i++) {
            if (
              distance(lat, lng, this.state.allLocations[i].lat, this.state.allLocations[i].lng) <=
              this.state.searchRadius
            ) {
              markers.push(this.state.allLocations[i]);
            }
          }

          this.setState({
            markers: markers,
            lat: lat,
            lng: lng,
            zoom: zoom,
            animation: 2,
            showCircle: true,
            showUnitedStates: false,
            showInternational: false,
            showLocations: true,
            searchType: 'int-city',
            color: '#1cb1d1',
          });

          window.scrollTo(0, 0);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            $('#int-city').css('border-color', 'red');
            swal.fire({
              title: 'Please provide a valid city.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    } else {
      axios({
        method: 'post',
        url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${encodedCity}&components=country:${country}`,
      })
        .then((res) => {
          if (res.data.status === 'ZERO_RESULTS') {
            $('#int-city').css('border-color', 'red');
            swal.fire({
              title: 'Please provide a valid city.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1500,
            });
          }

          const lat = res.data.results[0].geometry.location.lat;
          const lng = res.data.results[0].geometry.location.lng;
          const markers = [];

          for (let i = 0; i < this.state.allLocations.length; i++) {
            if (
              distance(lat, lng, this.state.allLocations[i].lat, this.state.allLocations[i].lng) <=
              this.state.searchRadius
            ) {
              markers.push(this.state.allLocations[i]);
            }
          }

          this.setState({
            markers: markers,
            lat: lat,
            lng: lng,
            zoom: zoom,
            animation: 2,
            showCircle: true,
            showUnitedStates: false,
            showInternational: false,
            showLocations: true,
            searchType: 'int-city',
            color: '#1cb1d1',
          });

          window.scrollTo(0, 0);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            $('#int-city').css('border-color', 'red');
            swal.fire({
              title: 'Please provide a valid city.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    }
  };

  countryCityEnter = (e) => {
    if (e.key === 'Enter') {
      let zoom = 10;

      if (window.innerWidth <= 768) {
        zoom = 9;
      }

      e.preventDefault();
      e.stopPropagation();

      $('#int-city').css('border-color', '#ced4da');

      const city = $('#int-city').val();
      const country = $('#country').val();
      const taiwanCity = $('#taiwan-city').val();
      const encodedCity = encodeURI(city);

      if (city === '') {
        $('#int-city').css('border-color', 'red');
        swal.fire({
          title: 'Please provide a valid city.',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
        });
      } else if (country.toLowerCase() === 'cn' && city.toLowerCase() === 'taiwan') {
        // Hack to make sure that when customer search China
        // and input Taiwan, it will point over to Taiwan
        const encodedTaiwanCity = encodeURI(taiwanCity);
        axios({
          method: 'post',
          url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${encodedTaiwanCity}&components=country:${city}`,
        })
          .then((res) => {
            if (res.data.status === 'ZERO_RESULTS') {
              $('#int-city').css('border-color', 'red');
              swal.fire({
                title: 'Please provide a valid city.',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
              });
            }

            const lat = res.data.results[0].geometry.location.lat;
            const lng = res.data.results[0].geometry.location.lng;
            const markers = [];

            for (let i = 0; i < this.state.allLocations.length; i++) {
              if (
                distance(
                  lat,
                  lng,
                  this.state.allLocations[i].lat,
                  this.state.allLocations[i].lng,
                ) <= this.state.searchRadius
              ) {
                markers.push(this.state.allLocations[i]);
              }
            }

            this.setState({
              markers: markers,
              lat: lat,
              lng: lng,
              zoom: zoom,
              animation: 2,
              showCircle: true,
              showUnitedStates: false,
              showInternational: false,
              showLocations: true,
              searchType: 'int-city',
              color: '#1cb1d1',
            });

            window.scrollTo(0, 0);
          })
          .catch((err) => {
            if (err.response.status === 400) {
              $('#int-city').css('border-color', 'red');
              swal.fire({
                title: 'Please provide a valid city.',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
              });
            }
          });
      } else {
        axios({
          method: 'post',
          url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${encodedCity}&components=country:${country}`,
        })
          .then((res) => {
            if (res.data.status === 'ZERO_RESULTS') {
              $('#int-city').css('border-color', 'red');
              swal.fire({
                title: 'Please provide a valid city.',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
              });
            }

            const lat = res.data.results[0].geometry.location.lat;
            const lng = res.data.results[0].geometry.location.lng;
            const markers = [];

            for (let i = 0; i < this.state.allLocations.length; i++) {
              if (
                distance(
                  lat,
                  lng,
                  this.state.allLocations[i].lat,
                  this.state.allLocations[i].lng,
                ) <= this.state.searchRadius
              ) {
                markers.push(this.state.allLocations[i]);
              }
            }

            this.setState({
              markers: markers,
              lat: lat,
              lng: lng,
              zoom: zoom,
              animation: 2,
              showCircle: true,
              showUnitedStates: false,
              showInternational: false,
              showLocations: true,
              searchType: 'int-city',
              color: '#1cb1d1',
            });

            window.scrollTo(0, 0);
          })
          .catch((err) => {
            if (err.response.status === 400) {
              $('#int-city').css('border-color', 'red');
              swal.fire({
                title: 'Please provide a valid city.',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
              });
            }
          });
      }
    }
  };

  postalCode = () => {
    let zoom = 10;

    if (window.innerWidth <= 768) {
      zoom = 9;
    }

    $('#postal-code').css('border-color', '#ced4da');

    const postalCode = $('#postal-code').val();

    axios({
      method: 'post',
      url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${postalCode}`,
    })
      .then((res) => {
        if (res.data.status === 'ZERO_RESULTS') {
          $('#postal-code').css('border-color', 'red');
          swal.fire({
            title: 'Please provide a valid postal code.',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
          });
        }

        const lat = res.data.results[0].geometry.location.lat;
        const lng = res.data.results[0].geometry.location.lng;
        const markers = [];

        for (let i = 0; i < this.state.allLocations.length; i++) {
          if (
            distance(lat, lng, this.state.allLocations[i].lat, this.state.allLocations[i].lng) <=
            this.state.searchRadius
          ) {
            markers.push(this.state.allLocations[i]);
          }
        }

        this.setState({
          markers: markers,
          lat: lat,
          lng: lng,
          zoom: zoom,
          animation: 2,
          showCircle: true,
          showUnitedStates: false,
          showInternational: false,
          showLocations: true,
          searchType: 'postal-code',
          color: '#96ca51',
        });

        window.scrollTo(0, 0);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          $('#postal-code').css('border-color', 'red');
          swal.fire({
            title: 'Please provide a valid postal code.',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
  };

  postalCodeEnter = (e) => {
    if (e.key === 'Enter') {
      let zoom = 10;

      if (window.innerWidth <= 768) {
        zoom = 9;
      }

      e.preventDefault();
      e.stopPropagation();

      $('#postal-code').css('border-color', '#ced4da');

      const postalCode = $('#postal-code').val();

      axios({
        method: 'post',
        url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${postalCode}`,
      })
        .then((res) => {
          if (res.data.status === 'ZERO_RESULTS') {
            $('#postal-code').css('border-color', 'red');
            swal.fire({
              title: 'Please provide a valid postal code.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1500,
            });
          }

          const lat = res.data.results[0].geometry.location.lat;
          const lng = res.data.results[0].geometry.location.lng;
          const markers = [];

          for (let i = 0; i < this.state.allLocations.length; i++) {
            if (
              distance(lat, lng, this.state.allLocations[i].lat, this.state.allLocations[i].lng) <=
              this.state.searchRadius
            ) {
              markers.push(this.state.allLocations[i]);
            }
          }

          this.setState({
            markers: markers,
            lat: lat,
            lng: lng,
            zoom: zoom,
            animation: 2,
            showCircle: true,
            showUnitedStates: false,
            showInternational: false,
            showLocations: true,
            searchType: 'postal-code',
            color: '#96ca51',
          });

          window.scrollTo(0, 0);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            $('#postal-code').css('border-color', 'red');
            swal.fire({
              title: 'Please provide a valid postal code.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    }
  };

  intAddress = () => {
    let zoom = 10;

    if (window.innerWidth <= 768) {
      zoom = 9;
    }

    $('#int-address').css('border-color', '#ced4da');

    const address = $('#int-address').val();

    if (address === '') {
      $('#int-address').css('border-color', 'red');
      swal.fire({
        title: 'Please provide a valid address.',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      axios({
        method: 'post',
        url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${address}`,
      }).then((res) => {
        if (res.data.status === 'ZERO_RESULTS') {
          $('#int-address').css('border-color', 'red');
          swal.fire({
            title: 'Please provide a valid address.',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
          });
        }

        const lat = res.data.results[0].geometry.location.lat;
        const lng = res.data.results[0].geometry.location.lng;
        const markers = [];

        for (let i = 0; i < this.state.allLocations.length; i++) {
          if (
            distance(lat, lng, this.state.allLocations[i].lat, this.state.allLocations[i].lng) <=
            this.state.searchRadius
          ) {
            markers.push(this.state.allLocations[i]);
          }
        }

        this.setState({
          markers: markers,
          lat: lat,
          lng: lng,
          zoom: zoom,
          animation: 2,
          showCircle: true,
          showUnitedStates: false,
          showInternational: false,
          showLocations: true,
          searchType: 'int-address',
          color: '#4a6a65',
        });

        window.scrollTo(0, 0);
      });
    }
  };

  intAddressEnter = (e) => {
    if (e.key === 'Enter') {
      let zoom = 10;

      if (window.innerWidth <= 768) {
        zoom = 9;
      }

      e.preventDefault();
      e.stopPropagation();

      $('#int-address').css('border-color', '#ced4da');

      const address = $('#int-address').val();

      if (address === '') {
        $('#int-address').css('border-color', 'red');
        swal.fire({
          title: 'Please provide a valid address.',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        axios({
          method: 'post',
          url: `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${address}`,
        }).then((res) => {
          if (res.data.status === 'ZERO_RESULTS') {
            $('#int-address').css('border-color', 'red');
            swal.fire({
              title: 'Please provide a valid address.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1500,
            });
          }

          const lat = res.data.results[0].geometry.location.lat;
          const lng = res.data.results[0].geometry.location.lng;
          const markers = [];

          for (let i = 0; i < this.state.allLocations.length; i++) {
            if (
              distance(lat, lng, this.state.allLocations[i].lat, this.state.allLocations[i].lng) <=
              this.state.searchRadius
            ) {
              markers.push(this.state.allLocations[i]);
            }
          }

          this.setState({
            markers: markers,
            lat: lat,
            lng: lng,
            zoom: 10,
            animation: 2,
            showCircle: true,
            showUnitedStates: false,
            showInternational: false,
            showLocations: true,
            searchType: 'int-address',
            color: '#4a6a65',
          });

          window.scrollTo(0, 0);
        });
      }
    }
  };

  showInternational = () => {
    let zoom = 5;

    if (window.innerWidth <= 768) {
      zoom = 3;
    }

    this.setState({
      showUnitedStates: false,
      showInternational: true,
      showOnline: false,
      lat: 34,
      lng: 115,
      zoom: zoom,
    });
  };

  showUnitedStates = () => {
    let zoom = 5;

    if (window.innerWidth <= 768) {
      zoom = 3;
    }

    this.setState({
      showUnitedStates: true,
      showInternational: false,
      showOnline: false,
      lat: 41,
      lng: -97,
      zoom: zoom,
    });
  };

  showOnline = () => {
    this.setState({
      showUnitedStates: false,
      showInternational: false,
      showOnline: true,
    });
  };

  searchRadius = (e) => {
    const searchRadius = parseInt(e.target.value);
    let circleRadius;

    if (searchRadius === 5) {
      circleRadius = 8046.72;
    } else if (searchRadius === 10) {
      circleRadius = 16093.4;
    } else if (searchRadius === 15) {
      circleRadius = 24140.2;
    } else if (searchRadius === 25) {
      circleRadius = 40233.6;
    } else if (searchRadius === 50) {
      circleRadius = 80467.2;
    }

    this.setState({
      searchRadius,
      circleRadius,
    });
  };

  showTaiwanCity = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setTimeout(() => {
      if (value.toLowerCase() === 'taiwan') {
        this.setState({
          displayTaiwanCity: true,
        });
      } else {
        this.setState({
          displayTaiwanCity: false,
        });
      }
    }, 2000);
  };

  render() {
    if (!this.state.storesLoaded) {
      return (
        <div className="container margin-top-adjustment">
          <h1 className="header1 futura-font my-5 text-center">
            Please wait. Our stores are loading...
          </h1>
          <div className="my-5">
            <IsLoadingChild />
          </div>
        </div>
      );
    }
    const Map = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          options={{
            gestureHandling: 'greedy',
            scrollwheel: false,
            noClear: true,
          }}
          center={{ lat: this.state.lat, lng: this.state.lng }}
          zoom={this.state.zoom}>
          <Markers
            markers={this.state.markers}
            animation={this.state.animation}
            lat={this.state.lat}
            lng={this.state.lng}
            showYouAreHere={this.state.showLocations}
          />
          {this.state.showCircle ? (
            <Circle
              center={{ lat: this.state.lat, lng: this.state.lng }}
              radius={this.state.circleRadius}
              options={{
                fillColor: '#ffffff63',
                strokeColor: this.state.color,
              }}
            />
          ) : null}
        </GoogleMap>
      )),
    );
    return (
      <div>
        <Map
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GEOCODE_KEY}&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div className="loading-element" style={{ height: `100vh` }} />}
          containerElement={<div className="container-element" style={{ height: `100vh` }} />}
          mapElement={<div className="map-element" style={{ height: `100vh` }} />}
        />
        <div className="gradient-line" />
        {this.state.showUnitedStates && (
          <div className="search-container1">
            <div className="row">
              <div className="col contact-us-text">WHERE TO BUY</div>
            </div>
            <div className="row page-divider mb-5" />
            <ul className="nav nav-tabs nav-justified mt-4" role="tablist">
              <li className="nav-item locator-tab" onClick={this.showUnitedStates}>
                <a
                  className="regular-adult-nav-link nav-link d-flex align-items-center justify-content-center active"
                  role="tab"
                  data-toggle="tab">
                  United States
                </a>
              </li>
              <li className="nav-item locator-tab" onClick={this.showInternational}>
                <a
                  className="regular-adult-nav-link nav-link d-flex align-items-center justify-content-center"
                  role="tab"
                  data-toggle="tab">
                  International
                </a>
              </li>
              <li className="nav-item locator-tab" onClick={this.showOnline}>
                <a
                  className="regular-adult-nav-link nav-link d-flex align-items-center justify-content-center"
                  role="tab"
                  data-toggle="tab">
                  Online Retailers
                </a>
              </li>
            </ul>
            <div className="search-container2">
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <div className="col-lg-3 col-md-4 col-12 mb-3">
                  <label
                    htmlFor="us-search-radius"
                    className="col-12 col-form-label search-radius text-center">
                    Search Radius
                  </label>
                  <select
                    id="us-search-radius"
                    className="form-control"
                    onChange={this.searchRadius}>
                    <option value="5">5 miles</option>
                    <option value="10">10 miles</option>
                    <option value="15" defaultValue="selected">
                      15 miles
                    </option>
                    <option value="25">25 miles</option>
                    <option value="50">50 miles</option>
                  </select>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <div className="w-100" tabIndex="0">
                  <img
                    className="img-fluid w-100 current-location-row store-row-1 img-fluid-mobile-adj"
                    onClick={this.currentLocation}
                    src={
                      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Store+Locator/use-current-location.png'
                    }
                    alt="Use current location"
                  />
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <div className="d-flex search-row store-row-2">
                  <img
                    className="img-fluid search-image img-fluid-mobile-adj"
                    src={
                      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Store+Locator/search-by-city.png'
                    }
                  />
                  <div className="flex-fill input-y-margin">
                    <div className="city-state-input mx-auto">
                      <form>
                        <div className="form-group row align-items-center h-100">
                          <label htmlFor="city" className="col-4 col-form-label search-text-label">
                            City
                          </label>
                          <div className="col-md-8 col-8 px-0">
                            <input
                              className="form-control"
                              id="city"
                              type="text"
                              placeholder="City"
                              onKeyPress={this.cityStateEnter}
                            />
                          </div>
                          <label htmlFor="state" className="col-4 col-form-label search-text-label">
                            State
                          </label>
                          <div className="col-md-8 col-4 px-0 mobile-states">
                            <select id="state-mobile" className="form-control">
                              <option value="AL">AL</option>
                              <option value="AK">AK</option>
                              <option value="AZ">AZ</option>
                              <option value="AR">AR</option>
                              <option value="CA">CA</option>
                              <option value="CO">CO</option>
                              <option value="CT">CT</option>
                              <option value="DE">DE</option>
                              <option value="DC">DC</option>
                              <option value="FL">FL</option>
                              <option value="GA">GA</option>
                              <option value="HI">HI</option>
                              <option value="ID">ID</option>
                              <option value="IL">IL</option>
                              <option value="IN">IN</option>
                              <option value="IA">IA</option>
                              <option value="KS">KS</option>
                              <option value="KY">KY</option>
                              <option value="LA">LA</option>
                              <option value="ME">ME</option>
                              <option value="MD">MD</option>
                              <option value="MA">MA</option>
                              <option value="MI">MI</option>
                              <option value="MN">MN</option>
                              <option value="MS">MS</option>
                              <option value="MO">MO</option>
                              <option value="MT">MT</option>
                              <option value="NE">NE</option>
                              <option value="NV">NV</option>
                              <option value="NH">NH</option>
                              <option value="NJ">NJ</option>
                              <option value="NM">NM</option>
                              <option value="NY">NY</option>
                              <option value="NC">NC</option>
                              <option value="ND">ND</option>
                              <option value="OH">OH</option>
                              <option value="OK">OK</option>
                              <option value="OR">OR</option>
                              <option value="PA">PA</option>
                              <option value="RI">RI</option>
                              <option value="SC">SC</option>
                              <option value="SD">SD</option>
                              <option value="TN">TN</option>
                              <option value="TX">TX</option>
                              <option value="UT">UT</option>
                              <option value="VT">VT</option>
                              <option value="VA">VA</option>
                              <option value="WA">WA</option>
                              <option value="WV">WV</option>
                              <option value="WI">WI</option>
                              <option value="WY">WY</option>
                            </select>
                          </div>
                          <div className="col-md-8 col-6 px-0 desktop-states">
                            <select id="state" className="form-control">
                              <option value="AL">Alabama</option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District Of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </select>
                          </div>
                        </div>
                      </form>
                      <div className="row">
                        <div className="col px-0">
                          <button type="submit" className="search-button" onClick={this.cityState}>
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <div className="d-flex search-row store-row-3">
                  <img
                    className="img-fluid search-image img-fluid-mobile-adj"
                    src={
                      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Store+Locator/search-by-zip.png'
                    }
                  />
                  <div className="flex-fill input-y-margin">
                    <div className="zip-input mx-auto">
                      <form>
                        <div className="form-group row align-items-center h-100">
                          <label
                            htmlFor="zip"
                            className="col-lg-4 col-12 col-form-label search-text-label">
                            Zip Code
                          </label>
                          <div className="col-lg-8 col-12 px-0">
                            <input
                              className="form-control"
                              id="zip"
                              type="text"
                              onKeyPress={this.zipcodeEnter}
                              placeholder="Zip Code"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="row">
                        <div className="col px-0">
                          <button type="submit" className="search-button" onClick={this.zipcode}>
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <div className="d-flex search-row store-row-4">
                  <img
                    className="img-fluid search-image img-fluid-mobile-adj"
                    src={
                      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Store+Locator/search-by-address.png'
                    }
                  />
                  <div className="flex-fill input-y-margin">
                    <div className="address-input mx-auto">
                      <form>
                        <div className="form-group row align-items-center h-100">
                          <label
                            htmlFor="address"
                            className="col-lg-4 col-12 col-form-label search-text-label">
                            Address
                          </label>
                          <div className="col-lg-8 col-12 px-0">
                            <input
                              className="form-control"
                              id="address"
                              type="text"
                              onKeyPress={this.addressEnter}
                              placeholder="Address"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="row">
                        <div className="col px-0">
                          <button type="submit" className="search-button" onClick={this.address}>
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        )}
        {this.state.showInternational && (
          <div className="search-container1">
            <div className="row">
              <div className="col contact-us-text">WHERE TO BUY</div>
            </div>
            <div className="row page-divider mb-5" />
            <ul className="nav nav-tabs nav-justified mt-4 " role="tablist">
              <li className="nav-item locator-tab" onClick={this.showUnitedStates}>
                <a
                  className="regular-adult-nav-link nav-link d-flex align-items-center justify-content-center "
                  role="tab"
                  data-toggle="tab">
                  United States
                </a>
              </li>
              <li className="nav-item locator-tab" onClick={this.showInternational}>
                <a
                  className="regular-adult-nav-link nav-link d-flex align-items-center justify-content-center active"
                  role="tab"
                  onClick={this.showInternational}
                  data-toggle="tab">
                  International
                </a>
              </li>
              <li className="nav-item locator-tab" onClick={this.showOnline}>
                <a
                  className="regular-adult-nav-link nav-link d-flex align-items-center justify-content-center"
                  role="tab"
                  data-toggle="tab">
                  Online Retailers
                </a>
              </li>
            </ul>
            <div className="search-container2">
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <div className="col-lg-3 col-md-4 col-12 mb-3">
                  <label
                    htmlFor="int-search-radius"
                    className="col-12 col-form-label search-radius text-center">
                    Search Radius
                  </label>
                  <select
                    id="int-search-radius"
                    className="form-control"
                    onChange={this.searchRadius}>
                    <option value="5">5 miles</option>
                    <option value="10">10 miles</option>
                    <option value="15" defaultValue="selected">
                      15 miles
                    </option>
                    <option value="25">25 miles</option>
                    <option value="50">50 miles</option>
                  </select>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <img
                  className="img-fluid w-100 current-location-row store-row-1 img-fluid-mobile-adj"
                  onClick={this.currentLocation}
                  src={
                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Store+Locator/dog-art.png'
                  }
                />
              </ScrollAnimation>
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <div className="d-flex search-row store-row-2">
                  <img
                    className="img-fluid search-image img-fluid-mobile-adj"
                    src={
                      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Store+Locator/int-country.png'
                    }
                  />
                  <div className="flex-fill input-y-margin">
                    <div className="city-state-input mx-auto">
                      <form>
                        <div className="form-group row align-items-center h-100">
                          <label
                            htmlFor="country"
                            className="col-4 col-form-label search-text-label">
                            Country/Area/District
                          </label>
                          <div className="col-md-8 col-6 px-0">
                            <select id="country" className="form-control">
                              <option value="CA">Canada</option>
                              <option value="CN">China</option>
                              <option value="JP">Japan</option>
                              <option value="KR">South Korea</option>
                            </select>
                          </div>
                          <label
                            htmlFor="int-city"
                            className="col-4 col-form-label search-text-label">
                            City
                          </label>
                          <div className="col-md-8 col-6 px-0">
                            <input
                              className="form-control"
                              id="int-city"
                              type="text"
                              // onChange={this.showTaiwanCity}
                              onKeyPress={this.countryCityEnter}
                              placeholder="City"
                            />
                          </div>

                          <label
                            htmlFor="taiwan-city"
                            className={`col-4 col-form-label search-text-label ${
                              this.state.displayTaiwanCity ? '' : 'd-none'
                            }`}>
                            City in Taiwan
                          </label>

                          <div
                            className={`col-md-8 col-6 px-0 ${
                              this.state.displayTaiwanCity ? '' : 'd-none'
                            }`}>
                            <input
                              className="form-control"
                              id="taiwan-city"
                              type="text"
                              onKeyPress={this.countryCityEnter}
                              placeholder="City in Taiwan"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="row">
                        <div className="col px-0">
                          <button
                            type="submit"
                            className="search-button"
                            onClick={this.countryCity}>
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <div className="d-flex search-row store-row-3">
                  <img
                    className="img-fluid search-image img-fluid-mobile-adj"
                    src={
                      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Store+Locator/int-zip-code.png'
                    }
                  />
                  <div className="flex-fill input-y-margin">
                    <div className="zip-input mx-auto">
                      <form>
                        <div className="form-group row align-items-center h-100">
                          <label
                            htmlFor="postal-code"
                            className="col-lg-4 col-12 col-form-label search-text-label px-0">
                            Postal Code
                          </label>
                          <div className="col-lg-8 col-12 px-0">
                            <input
                              className="form-control"
                              id="postal-code"
                              type="text"
                              onKeyPress={this.postalCodeEnter}
                              placeholder="Postal Code"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="row">
                        <div className="col px-0">
                          <button type="submit" className="search-button" onClick={this.postalCode}>
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <div className="d-flex search-row store-row-4">
                  <img
                    className="img-fluid search-image img-fluid-mobile-adj"
                    src={
                      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Store+Locator/int-address.png'
                    }
                  />
                  <div className="flex-fill input-y-margin">
                    <div className="address-input mx-auto">
                      <form>
                        <div className="form-group row align-items-center h-100">
                          <label
                            htmlFor="address"
                            className="col-lg-4 col-12 col-form-label search-text-label">
                            Address
                          </label>
                          <div className="col-lg-8 col-12 px-0">
                            <input
                              className="form-control"
                              id="int-address"
                              type="text"
                              onKeyPress={this.intAddressEnter}
                              placeholder="Address"
                            />
                          </div>
                        </div>
                      </form>
                      <div className="row">
                        <div className="col px-0">
                          <button type="submit" className="search-button" onClick={this.intAddress}>
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        )}
        {this.state.showOnline && (
          <div className="search-container1">
            <div className="row">
              <div className="col contact-us-text">WHERE TO BUY</div>
            </div>
            <div className="row page-divider mb-5" />
            <ul className="nav nav-tabs nav-justified mt-4 " role="tablist">
              <li className="nav-item locator-tab" onClick={this.showUnitedStates}>
                <a
                  className="regular-adult-nav-link nav-link d-flex align-items-center justify-content-center"
                  role="tab"
                  data-toggle="tab">
                  United States
                </a>
              </li>
              <li className="nav-item locator-tab" onClick={this.showInternational}>
                <a
                  className="regular-adult-nav-link nav-link d-flex align-items-center justify-content-center"
                  role="tab"
                  onClick={this.showInternational}
                  data-toggle="tab">
                  International
                </a>
              </li>
              <li className="nav-item locator-tab" onClick={this.showOnline}>
                <a
                  className="regular-adult-nav-link nav-link d-flex align-items-center justify-content-center active"
                  role="tab"
                  data-toggle="tab">
                  Online Retailers
                </a>
              </li>
            </ul>
            <div className="online-container onlinestore">
              <div className="row">
                <div className="col-12 disclaimer">
                  DISCLAIMER: Amazon, Jet.com, Petco, and eBay are not authorized retailers. Lotus
                  does not warranty products sold through unauthorized resellers, as it could be
                  tampered with, expired, stored or transferred improperly, or counterfeit.
                </div>
              </div>
              <div className="row">
                <div className="col websites-col">
                  <div className="authorized mb-3">Authorized Websites</div>
                  {/* New Section */}
                  {/* Hearty pet */}
                  <MDBRow>
                    <MDBCol xs="12" sm="6" md="6" lg="4" xl="3" className="mt-5">
                      <div>
                        <MDBCard className="storecard">
                          <MDBCardBody>
                            <MDBRow>
                              <MDBCol xs="12" sm="12" md="12" >
                                <a href="https://www.heartypet.com/collections/lotus" target="_blank"><img className="img-fluid" src="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Store+Locator/heartypet.png" /></a>
                              </MDBCol>
                              <MDBCol xs="12" sm="12" md="12">
                                <p className="futura-font text-center text-lg-left heartypetlotus">
                                Hearty Pet is a family-owned, independent retailer of premium pet nutrition and accessories. Since 2006, it’s been their mission to help pets (and their parents) live their best lives. At Hearty Pet, you can shop for the all the products your furry BFF’s will love – and their top notch customer care team is always at the ready to answer any questions! 
                                </p>
                                <div className="text-center">
                                <Link to="/heartypetlotus" className="mt-1 text-center" target="_blank">
                                  <MDBBtn className="futura-bold-font" color="dark">                                   
                                    Shop Now                    
                                  </MDBBtn>
                                </Link>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardBody>
                        </MDBCard>
                      </div>
                    </MDBCol>
                    {/* K9 Cuisine */}
                    <MDBCol xs="12" sm="6" md="6" lg="4" xl="3" className="mt-5">
                      <div>
                        <MDBCard className="storecard">
                          <MDBCardBody>
                            <MDBRow>
                              <MDBCol xs="12" sm="12" md="12" >
                                <a href="https://www.k9cuisine.com/lotus-pet-food" target="_blank"><img className="img-fluid" src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Store+Locator/k9_cuisine.png" /></a>
                              </MDBCol>
                              <MDBCol xs="12" sm="12" md="12">
                                <p className="futura-font text-center text-lg-left k9cuisinelotus">
                                Founded in 2007.  Family and independantly owned since 2014.  Based out of Omaha NE.  Ships nationally.  Knowledgeable customer service reps ready to help.
                                </p>
                                <div className="text-center">
                                <Link to="/k9cuisinelotus" className="mt-1" target="_blank">
                                  <MDBBtn className="futura-bold-font" color="dark">                                   
                                    Shop Now                    
                                  </MDBBtn>
                                </Link>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardBody>
                        </MDBCard>
                      </div>
                    </MDBCol>
                    {/* Pet Food Station */}
                    <MDBCol xs="12" sm="6" md="6" lg="4" xl="3" className="mt-5">
                      <div>
                        <MDBCard className="storecard">
                          <MDBCardBody>
                            <MDBRow>
                              <MDBCol xs="12" sm="12" md="12" >
                                <a href="https://petfoodstation.com/pages/search-results-page?collection=all&rb_vendor=Lotus" target="_blank"><img className="img-fluid" src="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Store+Locator/pfs_logo_new_220x.png" /></a>
                              </MDBCol>
                              <MDBCol xs="12" sm="12" md="12">
                                <p className="futura-font text-center text-lg-left petfoodstationlotus">
                                Family and independantly owned.  Approved Amazon retailer.  Ships nationally.  Based in MD
                                </p>
                                <div className="text-center">
                                <Link to="/petfoodstationlotus" className="mt-1 text-center" target="_blank"> 
                                  <MDBBtn className="futura-bold-font" color="dark">                                   
                                    Shop Now                    
                                  </MDBBtn>
                                </Link>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardBody>
                        </MDBCard>
                      </div>
                    </MDBCol>
                    {/* TreatBuddy */}
                    <MDBCol xs="12" sm="6" md="6" lg="4" xl="3" className="mt-5">
                      <div>
                        <MDBCard className="storecard">
                          <MDBCardBody>
                            <MDBRow>
                              <MDBCol xs="12" sm="12" md="12">
                                <a href="https://treatbuddy.com/brands/Lotus-Pet-Foods.html" target="_blank"><img className="img-fluid" src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Store+Locator/TreatBuddy.png" /></a>
                              </MDBCol>
                              <MDBCol xs="12" sm="12" md="12">
                                <p className="futura-font text-center text-lg-left treatbuddylotus">
                                Family and independantly owned. Approved Amazon retailer. Ships nationally. Based out of Palm Springs, CA.
                                </p>
                                <div className="text-center">
                                <Link to="/treatbuddylotus" className="mt-1 text-center" target="_blank">
                                  <MDBBtn className="futura-bold-font" color="dark">                                   
                                    Shop Now                    
                                  </MDBBtn>
                                </Link>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardBody>
                        </MDBCard>
                      </div>
                    </MDBCol>
                    {/* Pet Flow */}
                    <MDBCol xs="12" sm="6" md="6" lg="4" xl="3" className="mt-5">
                      <div>
                        <MDBCard className="storecard">
                          <MDBCardBody>
                            <MDBRow>
                              <MDBCol xs="12" sm="12" md="12" >
                                <a href="https://www.petflow.com/lotus" target="_blank"><img className="img-fluid" src="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Store+Locator/petflow.png" /></a>
                              </MDBCol>
                              <MDBCol xs="12" sm="12" md="12">
                                <p className="futura-font text-center text-lg-left petflowlotus">
                                Ships nationally.
                                </p>
                                <div className="text-center">
                                <Link to="/petflowlotus" className="mt-1 text-center" target="_blank">
                                  <MDBBtn className="futura-bold-font" color="dark">                                   
                                    Shop Now                    
                                  </MDBBtn>
                                </Link>
                                </div>
                              </MDBCol>
                            </MDBRow>
                          </MDBCardBody>
                        </MDBCard>
                      </div>
                    </MDBCol>
                    {/* Raw Boxx */}
                    <MDBCol xs="12" sm="6" md="6" lg="4" xl="3" className="mt-5">
                        <div>
                          <MDBCard className="storecard">
                            <MDBCardBody>
                              <MDBRow>
                                <MDBCol xs="12" sm="12" md="12" >
                                  <div style={{textAlign:"center"}}>
                                  <a href="https://www.rawboxxjax.com/lotus" target="_blank"><img className="img-fluid" src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Store+Locator/raw_boxx.png" width="40%"/></a>
                                  </div>
                                </MDBCol>
                                <MDBCol xs="12" sm="12" md="12">
                                  <p className="futura-font text-center text-lg-left rawboxxlotus">
                                  Family owned and operated.  Ships Raw Frozen Foods and more.  Only services the Greater Jacksonville FL area.
                                  </p>
                                  <div className="text-center">
                                  <Link to="/rawboxxlotus" className="mt-1 text-center" target="_blank">
                                    <MDBBtn className="futura-bold-font" color="dark">                                   
                                      Shop Now                    
                                    </MDBBtn>
                                  </Link>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                            </MDBCardBody>
                          </MDBCard>
                        </div>
                      </MDBCol>
                  </MDBRow>
                  {/*<div className="mb-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="website"
                      href="https://www.chewy.com/s?rh=brand_facet%3ALotus">
                      <img
                        className="online-logo"
                        src={
                          'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Store+Locator/chewy.png'
                        }
                      />
                    </a>
                  </div>
                */}
                </div>
              </div>
              <div className="row computer">
                <div className="col-12 mt-5 mx-0 px-0">
                  <img
                    className="img-fluid"
                    src={
                      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Store+Locator/computer.png'
                    }
                  />
                </div>
              </div>
            </div>

          </div>
        )}
        {this.state.showLocations && (
          <Locations
            markers={this.state.markers}
            type={this.state.searchType}
            color={this.state.color}
            cityState={this.cityState}
            zipcode={this.zipcode}
            address={this.address}
            cityStateEnter={this.cityStateEnter}
            zipcodeEnter={this.zipcodeEnter}
            addressEnter={this.addressEnter}
            countryCity={this.countryCity}
            postalCode={this.postalCode}
            intAddress={this.intAddress}
            countryCityEnter={this.countryCityEnter}
            intAddressEnter={this.intAddressEnter}
            postalCodeEnter={this.postalCodeEnter}
            searchRadius={this.searchRadius}
            locationCurrent={this.locationCurrent}
          />
        )}
      </div>
    );
  }
}

export default StoreLocator;
