import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBSpinner } from 'mdbreact';

export default function IsLoading() {
  return (
    <MDBContainer className="margin-top-adjustment spinner">
      <MDBRow center>
        <MDBCol className="text-center" size="6">
          <MDBSpinner big />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
