import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class WhyGarlic extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
      }

  render() {
    return (
        <div>
                <div className="pb-3 blog-margin-adjustment-desktop">
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/garlic-banner.jpg'} 
                        className="banner img-fluid w-100" 
                        alt="lotus Pet Foods - Why Garlic?"/>
                    </div>
                </MDBAnimation>
                </div>
       
            <div className='container mt-5'>
                <p style={{"color": "grey"}}>Aug, 12, 2022</p>
            </div>

            <div className='container mt-5 text-center d-none-mobile'>
                <h1><span className="LemonMilkbold-font blog-hb">Garlic. Why Garlic?</span></h1>
            </div>

            <div className='container mt-5 text-center d-none-desktop'>
                <h1><span className="LemonMilkbold-font blog-h">Garlic. Why Garlic?</span></h1>
            </div>
  
            <div className='container' style={{"marginTop": "60px"}}>
                <div className='mb-5 futura-font blog-p'>
                    <h4>Can dogs and cats eat garlic? Is it toxic? Let’s get into the truths about garlic, why we use it, and how it can benefit your pet.</h4>
                    <br />
                    <p>Garlic contains compounds called disulfides and thiosulphates which can be toxic to dogs and cats if ingested. However, studies have 
                        found that it takes approximately 15-30 grams of garlic per kilogram of body weight to produce toxicity in the pet's blood. To put 
                        that into perspective, a clove of garlic in the grocery store weighs about 7 grams. A dog would need to eat A LOT of garlic for it 
                        to be harmful. Example: if your dog weighs 10lbs they would need to eat 10 cloves of garlic to be harmful. We do recommend you keep 
                        garlic in your home away from your dog and be careful when prepping food!</p>
                    <br />
                    <p>So how much garlic does Lotus use? We have about a quarter of a percentage of garlic in our food, not nearly enough to be harmful but 
                        enough to reap the benefits!</p>
                     <br />
                     <h4>Benefits? What benefit does garlic have for dogs and cats?</h4>
                     <br />
                     <p>The main benefit and reason why we use garlic in our formulas is for a very powerful antioxidant called selenium. Selenium is used to 
                        synthesize proteins known as selenoproteins – an enzyme that can aid the thyroid gland, support a healthy immune system, and protect 
                        cells by inhibiting the activity of free radicals.</p>
                     <br />
                     <p>Other benefits of Garlic include, helping to detoxify the liver, prevent fleas/ticks, fight off internal and external bacteria and 
                        help with inflammation in the joints!</p>
                     <br />
                </div>
            </div>
        </div>
    );
  }
}

export default WhyGarlic;