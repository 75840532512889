import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import { MDBRow, MDBCol } from 'mdbreact';

const { convertToReactTagsObj } = require('../helper.jsx');

export default function Ingredients(props) {
  return (
    <div className="admin-main__react-tag-comp admin-main--border-divider">
      <MDBRow>
        <MDBCol>
          <h2 className="admin-main__header2 text-capitalize">{props.title}</h2>
          <div className="admin-main--margin-x-5">
            <ReactTags
              tags={convertToReactTagsObj(props.colInfo)}
              placeholder="Add new"
              inputFieldPosition="bottom"
              handleAddition={(tagInfo) => props.addColInfoReactTag(tagInfo, props.colName)}
              handleDrag={(tagInfo, currPos, newPos) =>
                props.dragReactTagInfo(tagInfo, currPos, newPos, props.colInfo, props.colName)
              }
              handleDelete={(tagInfo) => props.deleteColInfoReactTag(tagInfo, props.colName)}
            />
          </div>
        </MDBCol>
      </MDBRow>
    </div>
  );
}
