const swal = require('sweetalert2');
const { success, error, warning } = require('./SweetAlerts/alerts.jsx');

/** @module SweetAlerts/HelperMethods */
/**
 * @function
 * @param {Number} status
 * @param {String} info
 * @return {Object}
 */
const handleErrors = (status, info) => {
  if (status === 406) {
    return error({
      title: 'Unaccepted Image Type!',
      text: 'Please ensure file type is jpeg/jpg.',
      button: 'Go Back',
    });
  }
  if (status === 404) {
    return error({
      title: 'Not Found',
      text: `Incorrect ${info} was received, please check your information or request and try again.`,
      button: 'Okay',
    });
  }
  if (status === 400) {
    return error({ title: 'Bad Request', text: 'Please try again later.', button: 'Okay' });
  }
  if (status === 403) {
    return error({
      title: 'Unathorized',
      text: 'You do not have permission for this request',
      button: 'Okay',
    });
  }
  if (status === 500) {
    return error({
      title: 'Server Error',
      text: 'Server Error, please try again later.',
      button: false,
    });
  }
};

/**
 * @function
 * @param {String} status
 * @param {Object} history
 * @returns {Void}
 */
const handleLoggedInErr = (status, history) => {
  if (status === 'unauthorized') {
    return swal
      .fire({
        icon: 'error',
        title: 'Unathorized Access',
        text: 'You must be a retailer to view this page',
        showConfirmButton: true,
        confirmButtonText: 'Take me back to homepage!',
      })
      .then((result) => {
        history.push('/');
      });
  }
  if (status === 'not logged in') {
    return swal
      .fire({
        icon: 'error',
        title: 'Unathorized Access',
        text: 'You must be logged in to view this page.',
        showConfirmButton: true,
        confirmButtonText: 'Take me back to homepage!',
      })
      .then((result) => {
        history.push('/');
      });
  }
  if (status === 'unauthorized prize') {
    return swal
      .fire({
        icon: 'error',
        title: 'Unathorized Access',
        text: 'You cannot access this page without selecting a prize.',
        showConfirmButton: true,
        confirmButtonText: 'Take me to redemption page!',
      })
      .then((result) => {
        history.push('/retail-training/reward-redemption');
      });
  }
};

/**
 *
 * @param {Object} history
 * @returns {Boolean}
 */
const handleAuthorization = (history) => {
  if (localStorage.length < 1) {
    handleLoggedInErr('not logged in', history);
    return false;
  }
  const retailer =
    JSON.parse(localStorage.getItem('user')) || JSON.parse(localStorage.getItem('admin'));
  const retailerCheck = retailer.retailer || false;
  if (!retailerCheck) {
    handleLoggedInErr('unauthorized', history);
    return false;
  }
  return true;
};

/**
 * @returns {Void}
 */
const handleInvalidForm = () => {
  swal.fire({
    icon: 'error',
    title: 'Missing Information',
    text: 'Please review the missing fields on form.',
    showConfirmButton: true,
    confirmButtonText: 'Review Form',
  });
};

module.exports = { handleErrors, handleLoggedInErr, handleAuthorization, handleInvalidForm };
