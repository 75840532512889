const capitalizedFirstLetters = (string) => {
  const stringSplit = string.split(' ');
  return stringSplit.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};
const createQuizCategory = (quizArr) => {
  return quizArr.map((quiz) => {
    return { text: capitalizedFirstLetters(quiz.name), value: quiz.name };
  });
};
const createAnswersTups = (answersObj) => {
  return Object.keys(answersObj).map((answer) => {
    return { letter: answer, text: answersObj[answer] };
  });
};

module.exports = { createQuizCategory, createAnswersTups };
