import React, { Component } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import swal from 'sweetalert2';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBAnimation,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from 'mdbreact';

import IsLoadingChild from '../IsLoading/IsLoadingChild.jsx';
import QuizPanels from './QuizChildComps/QuizPanels.jsx';
import QuizPanelsTwo from './QuizChildComps/QuizPanelsTwo.jsx';
import NoMatch from '../NoMatch/NoMatch.jsx';

const { checkIfQuizIsTaken, checkPermissionForPage } = require('./helper.js');
const { handleErrors } = require('../_helper/reqsHandlers');
const { success, error, warning } = require('../_helper/SweetAlerts/alerts.jsx');
const { setToken } = require('../_helper/index.jsx');
const {
  endPoint: { quizEndPoint },
} = require('../_helper/stringVars');

export default class RetailerQuiz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quizzes: [],
      quizzes_scenario:[{_id:17, name:'scenario_1'}, {_id:18, name:'scenario_2'}, {_id:19, name:'scenario_3'}, {_id:20, name:'scenario_4'}],
      quizLoaded: false,
      currentQuizId: '',
      currentQuizVideo: '',
      userQuizzes: [],
      modal0: false,
    };

    this.token = { headers: setToken('user') };
  }

  componentDidMount() {
    if (this.checkRetailUser()) {
      this.setUserQuizzes();
      this.setQuizzes();
    }
  }

  checkRetailUser = () => {
    const { history } = this.props;
    const retailerCheck = checkPermissionForPage(localStorage);
    if (!retailerCheck) {
      swal
        .fire({
          title: 'You are not Logged in or a Retailer.',
          text: 'Please ensure that you are a Retailer and are Logged in to view quizzes.',
          icon: 'warning',
          confirmButtonText: 'Go back to homepage',
        })
        .then(() => history.push('/'), 3000);
      return false;
    }
    return true;
  };

  setQuizzes = () => {
    Axios.get(`${quizEndPoint.client}/get-all-quiz`, this.token)
      .then((res) => {
        console.log(res.data);
        const { data, status } = res;
        if (status === 404) {
          return error({
            title: 'Error',
            text: 'Quiz not found. Please try again.',
            button: 'Okay',
          });
        }
        this.setState({
          quizzes: data,
          quizLoaded: true,
        });
      })
      .catch((err) => {
        console.error(err);
        const { status } = err.response;
        const { history } = this.props;

        error({ title: 'Server Error', text: 'Please try again later.', button: 'Okay' });
      });
  };

  setUserQuizzes = () => {
    if (localStorage.getItem('user') === null) return;

    const { _id } = JSON.parse(localStorage.getItem('user'));
    Axios.get(`${quizEndPoint.client}/get-user-quiz-and-points`, {
      params: { _id },
      headers: setToken('user'),
    })
      .then((res) => {
        //console.log(res);
        const { data, status } = res;
        if (status === 400) return handleErrors(400);
        if (status === 404) return handleErrors(404, 'user information');

        this.setState({
          userQuizzes: data.quizzes,
        });
      })
      .catch((err) => {
        console.error(err);
        error({ title: 'Server Error', text: 'Please try again later.', button: 'Okay' });
      });
  };

  getQuizVid = (e, quizId, quizName) => {
    e.preventDefault();
    this.setState({
      currentQuizId: quizId,
      currentQuizVideo: quizName,
      showVideo: true,
    });
  };

  toggleModal = (nr) => () => {
    const modalNumber = 'modal' + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  modal = () => {
    return (
      <MDBModal
        modalStyle="info"
        isOpen={this.state.modal0}
        toggle={this.toggleModal(0)}
        centered
        position="center">
        <MDBModalHeader
          tag="h3"
          className="retailer-quiz__header3 futura-font"
          toggle={this.toggleModal(0)}>
          Welcome to Lotus Pet Foods Quizzes!
        </MDBModalHeader>
        <MDBModalBody className="retailer-quiz__modal-p futura-font text-dark">
          On this page, you will be able to take a variety of quizzes that will help you earn points
          towards our rewards. Every answer that you get correct, you will <b>earn 1 point</b> and
          that can be spent in our redeem page. You can earn up to a maximum of <b>55 points</b>{' '}
          based on 11 quizzes that we offer. Keep in mind, once a quiz is taken, you will no longer
          earn points for that quiz, so be sure to watch the videos and do your best to earn all 55
          points. <br />
          Good luck!
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="primary" onClick={this.toggleModal(0)}>
            Close
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  };

  renderQuizComponent = () => {
    const { state } = this;
    if (!state.quizLoaded) {
      return <IsLoadingChild />;
    }
    /* It needs to be done here */
    return state.quizzes.map((quiz, i) => {
      return (
        <MDBCol key={i} size="12" sm="6">
          <QuizPanels
            name={quiz.name}
            quizId={quiz._id}
            taken={checkIfQuizIsTaken(quiz._id, state.userQuizzes)}
          />
        </MDBCol>
      );
    });
  };

  renderQuizComponentTwo = () => {
    const { state } = this;
    if (!state.quizLoaded) {
      return <IsLoadingChild />;
    }
   
    return state.quizzes_scenario.map((quiztwo, i) => {
      return (
        <MDBCol key={i} size="12" sm="6">
          <QuizPanelsTwo
            name={quiztwo.name}
            quizId={quiztwo._id}
          />
        </MDBCol>
      );
    });
  };

  render() {
    return (
      <MDBContainer className="margin-top-adjustment retailer-quiz">
        <MDBAnimation type="fadeIn">
          <MDBRow>
            <MDBCol size="12">
              <h1 className="retailer-quiz__header1 brandon-font">Video Quizzes</h1>
              <div className="page-divider mb-3"></div>
            </MDBCol>
            <MDBCol size="12">
              <MDBBtn className="ml-0" color="primary" onClick={this.toggleModal(0)}>
                View Instruction
              </MDBBtn>
              <Link to="/retail-training/reward-redemption">
                <MDBBtn color="amber">Redeem Points</MDBBtn>
              </Link>
            </MDBCol>
          </MDBRow>
          <MDBRow>{this.renderQuizComponent()}</MDBRow>

          {/* Videos without Quiz*/}
          <MDBRow>{this.renderQuizComponentTwo()}</MDBRow>

        </MDBAnimation>
        {this.modal()}
      </MDBContainer>
    );
  }
}