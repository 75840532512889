import React, { Component } from 'react';
import { MDBCol, MDBInput, MDBIcon } from 'mdbreact';

class PasswordField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPass: false,
      radio: 1,
    };

    this.showPassword = this.showPassword.bind(this);
  }

  showPassword(e) {
    const { showPass } = this.state;
    this.setState({
      showPass: !showPass,
    });
  }

  render() {
    const { showPass } = this.state;
    const { name, label, value, onChange, optionClass } = this.props;

    return (
      <MDBCol sm="12" className="password-field-input">
        <MDBInput
          type={showPass ? 'text' : 'password'}
          name={name}
          value={value}
          onChange={onChange}
          label={label}
        />
        <MDBIcon
          className={`show-password-icon ${optionClass} grey-color-adjustment`}
          far
          icon={showPass ? 'eye' : 'eye-slash'}
          id="showPassword"
          onClick={this.showPassword}
          checked={showPass}
          label="show or hide password."
        />
      </MDBCol>
    );
  }
}

export default PasswordField;