import React from 'react';
import { MDBListGroupItem } from 'mdbreact';

export default function ListGroup(props) {
  return (
    <MDBListGroupItem
      color={props.i % 2 === 0 ? 'light' : 'warning'}
      className="d-flex justify-content-between product-main__asfed-list">
      <p className="product-main__text">{props.name}</p>
      <p className="product-main__text">
        {props.data !== ' undefined' ? props.data : 'Not Applicable'}
      </p>
    </MDBListGroupItem>
  );
}
