import React from 'react';
import { MDBRow, MDBCol, MDBSelect, MDBBtn } from 'mdbreact';

export default function ShirtOptions(props) {
  return (
    <MDBCol>
      <MDBRow>
        <MDBCol>
          <MDBSelect
            getValue={props.getSKUVal}
            getTextContent={props.getShirtSize}
            options={props.SKU}
            selected="Medium"
            label="Choose your shirt size"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="3">
          {props.shirtColors.map((color, i) => (
            <MDBBtn
              key={i}
              flat
              circle
              size="sm"
              className="mx-0 pl-0 pr-2"
              onClick={props.changeShirtColor}
              data-color={color.color}>
              <span
                className="color-circle selected-color"
                style={{ backgroundColor: color.hex }}></span>
            </MDBBtn>
          ))}
        </MDBCol>
      </MDBRow>
    </MDBCol>
  );
}
