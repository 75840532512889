import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Elements } from 'react-stripe-elements';
import NavBar from '../NavBar/NavBar.jsx';
import NavBarTop from '../NavBar/NavBarTop.jsx';

//Main Page
import LandingPage from '../LandingPage/HomePage/HomePage.jsx';

//Overview Page
import DogFood from '../ProductPages/DogFood/DogFood.jsx';
import CatFood from '../ProductPages/CatFood/CatFood.jsx';
import ProductOverviewClass from '../ProductPages/ProductOverview/ProductOverviewClass.jsx';

//Main Product Page
import MainProductPage from '../ProductPages/MainProductPage/MainProductPage.jsx';

//Review
import Review from '../Review/Review.jsx';

//Coupon
import CouponsAndOffers from '../CouponsAndOffers/CouponsAndOffers.jsx';
import QuarterlyCoupon from '../CouponsAndOffers/QuarterlyCoupon.jsx';
//import TestCoupon from '../CouponsAndOffers/TestCoupon.jsx';
//import FifteenOffCoupon from '../CouponsAndOffers/FifteenOffCoupon.jsx';
import LoveYourPetCoupon from '../CouponsAndOffers/LoveYourPetCoupon.jsx';
import UnleashedCoupon from '../CouponsAndOffers/unleashedCoupon.jsx';
import MyPetMarketCoupon from '../CouponsAndOffers/MyPetMarketCoupon.jsx';
import ChoicePetCoupon from '../CouponsAndOffers/ChoicePetCoupon.jsx';
import WoodlandsPetandTreats from '../CouponsAndOffers/WoodlandsPetandTreats.jsx';
import AllPetsConsidered from '../CouponsAndOffers/AllPetsConsideredCoupon.jsx';
import HealthyPetCoupon from '../CouponsAndOffers/HealthyPetCoupon.jsx';
import PremierPetCoupon from '../CouponsAndOffers/PremierPetCoupon.jsx';
import fourtyOff from '../CouponsAndOffers/fourtyOff.jsx';
//import GoldenCoupon from '../MailchimpPages/Coupon/GoldenCoupon.jsx';

//Search By Protein
import Chicken from '../Protein/Chicken/Chicken.jsx';
import Pork from '../Protein/Pork/Pork.jsx';
import Lamb from '../Protein/Lamb/Lamb.jsx';
import Duck from '../Protein/Duck/Duck.jsx';
import Fish from '../Protein/Fish/Fish.jsx';
import Venison from '../Protein/Venison/Venison.jsx';
import Turkey from '../Protein/Turkey/Turkey.jsx';
import Tripe from '../Protein/Tripe/Tripe.jsx';
import Beef from '../Protein/Beef/Beef.jsx';
import Rabbit from '../Protein/Rabbit/Rabbit.jsx';

//Shop & Checkout
import Shop from '../ShopInfo/Shop/Shop.jsx';
import Accessories from '../ShopInfo/Shop/Accessories/Accessories.jsx';
import Shirts from '../ShopInfo/Shop/Shirts/Shirts.jsx';
import ShopItemsMain from '../ShopInfo/Shop/ShopItemsMain.jsx';
import Cart from '../Cart/Cart.jsx';

//Retail training
import RetailerQuiz from '../Quiz/RetailerQuiz.jsx';
import QuizRedeem from '../Quiz/QuizRedeem/QuizRedeem.jsx';
import QuizShip from '../Quiz/QuizShip.jsx';
import QuizRedemptionShip from '../Quiz/QuizRedeem/QuizRemdemptionShip/QuizRedemptionShip.jsx';
import QuizVideo from '../Quiz/QuizChildComps/QuizVideo.jsx';
import QuizVideoTwo from '../Quiz/QuizChildComps/QuizVideoTwo.jsx';
import QuizResult from '../Quiz/QuizChildComps/QuizResults.jsx';
import RenderQuizQuestions from '../Quiz/QuizQuestions/RenderQuizQuestions.jsx';
import OldQuizRedeem from '../Quiz/QuizRedeem.jsx';

//Footer
import Footer from '../Footer/Footer.jsx';

//Others
import ShareStory from '../LandingPage/ShareStory/ShareStory';
//import SignUpPage from '../ClientInteraction/UserAccForms/SignUpPage/SignUpPage'
import Checkout from '../Checkout/Checkout.jsx';
//import Checkout from '../../Containers/Checkout/Checkout';
import StoreLocator from '../ShopInfo/StoreLocator/StoreLocator.jsx';
import ResetEmailRequest from '../ClientInteraction/ResetEmailRequest/ResetEmailRequest.jsx';
import PasswordReset from '../ClientInteraction/PasswordReset/PasswordReset.jsx';
import Subscribe from '../Subscribe/Subscribe.jsx';
import ContactUs from '../ContactUs/ContactUs.jsx';
import Ingredients from '../Ingredients/Ingredients.jsx';
import FAQ from '../FAQ/FAQ.jsx';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy.jsx';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions.jsx';
import Careers from '../Career/Career.jsx';
import OvenBaked from '../OvenBaked/OvenBaked.jsx';
import OvenBakedTest from '../OvenBaked/OvenBakedTest.jsx';
import AboutUs from '../AboutUs/AboutUs.jsx';
import Admin from '../Admin/Admin.jsx';
import AdminMain from '../Admin/AdminMain/AdminMain.jsx';
import SalesMain from '../Admin/AdminMain/SalesMain.jsx';
import SiteMap from '../SiteMap/SiteMap.jsx';
import Media from '../Media/Media.jsx';
import Newsletter from '../Newsletter/Newsletter.jsx';
import NewsItem from '../Newsletter/NewsItem.jsx';
import NoMatch from '../NoMatch/NoMatch.jsx';
import UnderConstruction from '../UnderConstruction/UnderConstruction.jsx';
//import BuildABowl from '../Calculator/BuildABowl/BuildABowl';
import FeedingCalculator from '../Calculator/FeedingCalculator/FeedingCalculator';
import Transition from '../LandingPage/Transition/Transition.jsx';
import Blog from '../Blog/Blog.jsx';
import blogVideo from '../Blog/blogVideo.jsx';
import BenefitsOfBaking from '../Blog/BenefitsOfBaking.jsx';
import FullCannery from '../Blog/FullCannery.jsx';
import FullRaw from '../Blog/FullRaw.jsx';
import LargeBreedDogs from '../Blog/LargeBreedDogs.jsx';
import Nutritional from '../Blog/Nutritional.jsx';
import SpecialNeeds from '../Blog/SpecialNeeds.jsx';
import Starch from '../Blog/Starch.jsx';
import VacationWithPet from '../Blog/Articles/VacationWithPet.jsx';
import SuperfoodTopper from '../Blog/Articles/SuperfoodTopper.jsx';
import ProperTopper from '../Blog/Articles/ProperTopper.jsx';
import FleaTick from '../Blog/Articles/FleaTick.jsx';
import CatObesity from '../Blog/Articles/CatObesity.jsx';
import MistakeBuyFood from '../Blog/Articles/MistakeBuyFood.jsx';
import KidneyDisease from '../Blog/Articles/KidneyDisease.jsx';
import BakeExtruded from '../Blog/Articles/BakeExtruded.jsx';
import WhyGarlic from '../Blog/Articles/WhyGarlic.jsx';
import PetAppetite from '../Blog/Articles/PetAppetite.jsx';
import DogAndKidney from '../Blog/Articles/DogAndKidney.jsx';
import DogTraining from '../Blog/Articles/DogTraining.jsx';
import Thanksgiving from '../Blog/Articles/Thanksgiving';
import ContactUsTemp from '../ContactUs/ContactUsTemp.jsx';
import RegularBites from '../ProductPages/DogFood/RegularBites.jsx';
import SmallBites from '../ProductPages/DogFood/SmallBites.jsx';
import DogTripeLoaf from '../ProductPages/DogFood/DogCanned/Loafs/Tripe/Tripe.jsx';
import CatFoodKibble from '../ProductPages/CatFood/Kibble.jsx';
import CatRawFood from '../ProductPages/CatFood/RawCatFoods/RawFood/RawFood.jsx';
import RawFoodsComponents from '../ProductPages/CatFood/RawCatFoods/RawFoodsComponents.jsx';
import DogRawVariety from '../ProductPages/DogFood/DogRaw/DogRawVariety.jsx';
import UserAccForms from '../ClientInteraction/UserAccForms/UserAccForms.jsx';
import StoreEdit from '../Admin/AdminMain/AdminPages/Stores/StoreComps/StoreEdit.jsx';
import AwsUploadTest from '../AwsUpload/AwsUploadTest.jsx';

const App = () => {
  return (
    <div>
      <Route path="/" component={NavBar} />
      <Route path="/" component={NavBarTop} />
      
      <Switch>
        {/*Main Page*/}
        <Route exact path="/" component={LandingPage} />
        

        {/*Overview Page*/}
        <Route exact path="/dog-food" component={DogFood} />
        <Route exact path="/cat-food" component={CatFood} />
        <Route exact path="/overview/:petType/:foodType" component={ProductOverviewClass} />

        {/*Main Product Page*/}
        <Route exact path="/product-view/:petType/:foodType/:ingredient-:id" component={MainProductPage} />

        {/*Review*/}
        <Route exact path="/review/:id/:foodName" component={Review} />

        {/*Coupon*/}
        {/*<Route exact path="/staging-coupon-test" component={TestCoupon} />*/}
        <Route exact path="/decidDcfdcdfim48desgse" component={CouponsAndOffers} />
        <Route exact path="/Quarterly93FkePOEx934CJDDKe" component={QuarterlyCoupon} />
        {/*<Route exact path="/noveomdscd23987f3jweikd938" component={CouponsAndOffers} />*/}
        {/*<Route exact path="/sWHen8xSANSObY16gy52U1N4D5E9R1T0A9L5E" component={QuarterlyCoupon} />*/}
        {/*<Route exact path="/imecjEFSDWJUIKvkEFDFG34T6H7JDKOXSWJDO" component={FifteenOffCoupon} />*/}
        {/*<Route exact path="/octspecial340fkjeerkflpwwwrkofer" component={FifteenOffCoupon} />*/}
        <Route exact path="/7YjK9nWpR4s2eQx1L5oM6vFtIaGz3" component={LoveYourPetCoupon} />
        <Route exact path="/aB7D2fLpVr5WvZqY3xM8kNtIhGjXw" component={PremierPetCoupon} />
        <Route exact path="/1pKz3TnLqW8VcBv5YdJxM6r2RfNtX" component={HealthyPetCoupon} />
        <Route exact path="/C7bMj4gXw3Tq8zYrNpVfW2L6vK1Ae" component={AllPetsConsidered} />
        <Route exact path="/2fEjGvMxNtPq5YdZr8wB6LzT3K7X1" component={WoodlandsPetandTreats} />
        <Route exact path="/WnLzY7KtFm5aGqXbNcP2rZj1D3vVw" component={MyPetMarketCoupon} />
        <Route exact path="/9TfNvP7eXmYqZ2rKjWbGwLzR1cVn5" component={ChoicePetCoupon} />
        <Route exact path="/G2aQ5rLjBx8M6Y7zNcTnW3XtK1vFp" component={UnleashedCoupon} />
        {/*<Route exact path="/40offcoupon" component={fourtyOff} />*/}
        {/*<Route exact path="/15-off-coupon" component={GoldenCoupon} />*/}

        {/*Search By Protein */}
        <Route exact path="/chicken" component={Chicken} />
        <Route exact path="/rabbit" component={Rabbit} />
        <Route exact path="/pork" component={Pork} />
        <Route exact path="/duck" component={Duck} />
        <Route exact path="/beef" component={Beef} />
        <Route exact path="/green-tripe" component={Tripe} />
        <Route exact path="/venison" component={Venison} />
        <Route exact path="/turkey" component={Turkey} />
        <Route exact path="/lamb" component={Lamb} />
        <Route exact path="/fish" component={Fish} />

        {/*Shop & Checkout*/}
        <Route exact path="/shop" component={Shop} />
        <Route exact path="/shop/accessories" component={Accessories} />
        <Route exact path="/shop/accessories/shirts" component={Shirts} />
        <Route exact path="/shop/accessories/:itemCat/:itemType" component={ShopItemsMain} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/checkout" component={() => (<Elements><Checkout /></Elements>)} />

        {/*Retail training*/}
        <Route exact path="/retail-training" component={RetailerQuiz} />
        <Route exact path="/retail-training/reward-redemption" component={QuizRedeem} />
        <Route exact path="/retail-training/reward-redemption/shipping-info" component={QuizRedemptionShip} />
        <Route exact path="/retail-training/redeem/ship" component={QuizShip} />
        <Route exact path="/retail-training/quiz/video-:quizName" component={QuizVideo} />
        <Route exact path="/retail-training/quiz/videoTwo-:quizName" component={QuizVideoTwo} />
        <Route exact path="/retail-training/quiz/questions/:quizName-:quizId" component={RenderQuizQuestions} />
        <Route exact path="/retail-training/quiz/results/:quizName" component={QuizResult} />
        <Route exact path="/retail-training/old/redeem" component={OldQuizRedeem} />

        {/*Others*/}
        <Route exact path="/share-story-external" component={() => { 
        window.location.href = 'https://www.instagram.com/lotuspetfoods'; 
        return null;
        }} />

        <Route exact path="/heartypetlotus" component={() => { 
        window.location.href = 'https://www.heartypet.com/collections/lotus'; 
        return null;
        }} />
        <Route exact path="/k9cuisinelotus" component={() => { 
        window.location.href = 'https://www.k9cuisine.com/lotus-pet-food'; 
        return null;
        }} />
        <Route exact path="/petfoodstationlotus" component={() => { 
        window.location.href = 'https://petfoodstation.com/pages/search-results-page?collection=all&rb_vendor=Lotus'; 
        return null;
        }} />
        <Route exact path="/treatbuddylotus" component={() => { 
        window.location.href = 'https://treatbuddy.com/brands/Lotus-Pet-Foods.html'; 
        return null;
        }} />
        <Route exact path="/petflowlotus" component={() => { 
        window.location.href = 'https://www.petflow.com/lotus'; 
        return null;
        }} />
        <Route exact path="/rawboxxlotus" component={() => { 
        window.location.href = 'https://www.rawboxxjax.com/lotus'; 
        return null;
        }} />

        <Route exact path="/share-story" component={ShareStory} />
        {/*<Route exact path="/signup-form" component={SignUpPage} />*/}
        {/*<Route exact path="/build-a-bowl-test-demo" component={BuildABowl} />*/}
        <Route exact path="/admin" component={Admin} />
        <Route path="/admin/main" component={AdminMain} />
        <Route path="/sales/main" component={SalesMain} />
        <Route exact path="/store-locator" component={() => <StoreLocator />} />
        <Route exact path="/password-reset" component={ResetEmailRequest} />
        <Route exact path="/password-reset/:resetToken" component={PasswordReset} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/subscribe" component={Subscribe} />
        <Route exact path="/ingredients" component={Ingredients} />
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
        <Route exact path="/careers" component={Careers} />
        <Route exact path="/newsletter" component={Newsletter} />
        <Route exact path="/newsletter/:id" component={NewsItem} />
        <Route exact path="/site-map" component={SiteMap} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/oven-baked" component={OvenBaked} />
        <Route exact path="/oven-baked-staging" component={OvenBakedTest} />
        <Route exact path="/media" component={Media} />
        <Route exact path="/under-remodeling" component={UnderConstruction} />
        <Route exact path="/food-transition" component={Transition} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/blog-video" component={blogVideo} />
        <Route exact path="/benefitsofbaking" component={BenefitsOfBaking} />
        <Route exact path="/fullcannery" component={FullCannery} />
        <Route exact path="/fullraw" component={FullRaw} />
        <Route exact path="/largebreeddogs" component={LargeBreedDogs} />
        <Route exact path="/nutritional" component={Nutritional} />
        <Route exact path="/specialneeds" component={SpecialNeeds} />
        <Route exact path="/starch" component={Starch} />
        <Route exact path="/vacation-with-pet" component={VacationWithPet} />
        <Route exact path="/superfood-topper" component={SuperfoodTopper} />
        <Route exact path="/proper-topper" component={ProperTopper} />
        <Route exact path="/flea-tick" component={FleaTick} />
        <Route exact path="/cat-obesity" component={CatObesity} />
        <Route exact path="/mistake-buying-pet-food" component={MistakeBuyFood} />
        <Route exact path="/pet-kidney-disease" component={KidneyDisease} />
        <Route exact path="/baking-and-extruded" component={BakeExtruded} />
        <Route exact path="/why-garlic" component={WhyGarlic} />
        <Route exact path="/pet-appetite" component={PetAppetite} />
        <Route exact path="/dog-and-kidney" component={DogAndKidney} />
        <Route exact path="/dog-training" component={DogTraining} />
        <Route exact path="/happy-thanksgiving" component={Thanksgiving} />
        <Route render={() => <NoMatch />} />
        <Route exact path="/feeding-calculator" component={FeedingCalculator} />
        {/*<Route exact path="/dog-food/regular-bites" component={RegularBites} />*/}
        {/*<Route exact path="/dog-food/small-bites" component={SmallBites} />*/}
        {/*<Route exact path="/dog-food/loafs/green-tripe" component={DogTripeLoaf} />*/}
        {/*<Route exact path="/product-view/dog/raw-foods/variety" component={DogRawVariety} />*/}
        {/*<Route exact path="/cat-food/kibble" component={CatFoodKibble} />*/}
        {/*<Route exact path="/cat-food/raw-food" component={CatRawFood} />*/}
        {/*<Route exact path="/cat-food/raw-food/:type" component={RawFoodsComponents} />*/}
        {/*<Route exact path="/checkout-test" component={() => (<Elements><CheckoutTest /></Elements>)}/> */}
        {/*<Route path="/aws-upload-test" component={AwsUploadTest} />*/}
        {/*<Route exact path="/test-form" component={UserAccForms} />*/}
      </Switch>
      <Route path="/" component={Footer} />
    </div>
  );
};

export default App;
