import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import swal from 'sweetalert2';

class QuizShip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      points: null,
    };
    this.redeemPrize = this.redeemPrize.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    // if (!localStorage.email) {
    //   swal.fire({
    //     title: 'You must be logged in to view this page!',
    //     icon: 'error',
    //     showConfirmButton: false,
    //     timer: 1500,
    //   }).then(() => this.props.history.push('/sign-in'));
    // } else {
    //   axios.post('/checkretailer', { email: localStorage.email }).then((res) => {
    //     if (res.data === false) {
    //       swal.fire({
    //         title: 'You must retailer to access this page!',
    //         icon: 'error',
    //         showConfirmButton: false,
    //         timer: 1500,
    //       }).then(() => this.props.history.push('/'));
    //     }
    //   });
    // }

    let selected;

    // if (localStorage.selectedPrize === 'dog-food-prize') {
    //   selected = 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/dog-food-prize-bar1.png';
    // } else if (localStorage.selectedPrize === 'cat-food-prize') {
    //   selected = 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/cat-food-prize-bar1.png';
    // } else if (localStorage.selectedPrize === 'bottle-prize') {
    //   selected = 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/bottle-prize-bar.png';
    // } else if (localStorage.selectedPrize === 't-shirt-prize') {
    //   selected = 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/t-shirt-prize-bar.png';
    // } else if (localStorage.selectedPrize === 'tote-bag-prize') {
    //   selected = 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/tote-bag-prize-bar.png';
    // } else if (localStorage.selectedPrize === 'stickers-prize') {
    //   selected = 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/stickers-prize-bar.png';
    // } else if (!localStorage.selectedPrize) {
    //   swal.fire({
    //     title: 'Please select a prize!',
    //     icon: 'error',
    //     showConfirmButton: false,
    //     timer: 2000,
    //   }).then(() => {
    //     this.props.history.push('/retail-training/redeem');
    //   });
    // }

    axios.post('/getpoints', { email: localStorage.email }).then((res) => {
      this.setState({
        points: res.data.rows[0].points,
        selected,
      });

      let newPoints;

      if (
        localStorage.selectedPrize === 'dog-food-prize' ||
        localStorage.selectedPrize === 'cat-food-prize'
      ) {
        newPoints = res.data.rows[0].points - 45;
      } else if (localStorage.selectedPrize === 'bottle-prize') {
        newPoints = res.data.rows[0].points - 40;
      } else if (localStorage.selectedPrize === 't-shirt-prize') {
        newPoints = res.data.rows[0].points - 35;
      } else if (localStorage.selectedPrize === 'tote-bag-prize') {
        newPoints = res.data.rows[0].points - 30;
      } else if (localStorage.selectedPrize === 'stickers-prize') {
        newPoints = res.data.rows[0].points - 5;
      }

      if (newPoints < 0) {
        swal
          .fire({
            title: 'You do not have enough points!',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
          })
          .then(() => this.props.history.push('/retail-training/redeem'));
      }
    });
  }

  redeemPrize(e) {
    e.preventDefault();

    const lotusEmail = localStorage.email;
    const email = $(`#email`).val();
    const shippingFirstName = $(`#shippingfirstname`).val();
    const shippingLastName = $(`#shippinglastname`).val();
    const shippingAddress = $(`#shippingaddress`).val();
    const shippingCity = $(`#shippingcity`).val();
    const shippingState = $(`#shippingstate`).val();
    const shippingZip = $(`#shippingzip`).val();
    const phone = $(`#phone`).val();
    const prize = $(`#select-prize`).val() || $(`#select-shirt`).val();
    const shirtSize = $(`#select-size`).val();
    const errors = [];

    $(
      '#shippingfirstname, #shippinglastname, #shippingaddress, #shippingcity, #shippingzip, #email, #phone',
    ).css('border-color', '#ced4da');

    if (email === '') {
      errors.push('Email');
      $('#email').css('border-color', 'red');
    }

    if (phone === '') {
      errors.push('Phone Number');
      $('#phone').css('border-color', 'red');
    }

    if (
      shippingFirstName === '' ||
      shippingLastName === '' ||
      shippingAddress === '' ||
      shippingCity === '' ||
      shippingZip === ''
    ) {
      if (shippingFirstName === '') {
        $('#shippingfirstname').css('border-color', 'red');
      }
      if (shippingLastName === '') {
        $('#shippinglastname').css('border-color', 'red');
      }
      if (shippingAddress === '') {
        $('#shippingaddress').css('border-color', 'red');
      }
      if (shippingCity === '') {
        $('#shippingcity').css('border-color', 'red');
      }
      if (shippingZip === '') {
        $('#shippingzip').css('border-color', 'red');
      }

      errors.push('Shipping Address');
    }

    if (errors.length > 0) {
      swal.fire({
        title: `Please complete the following:`,
        text: errors.join(', '),
        icon: 'error',
      });
    }

    if (errors.length === 0) {
      const currentPoints = this.state.points;
      let newPoints;

      if (
        localStorage.selectedPrize === 'dog-food-prize' ||
        localStorage.selectedPrize === 'cat-food-prize'
      ) {
        newPoints = currentPoints - 45;
      } else if (localStorage.selectedPrize === 'bottle-prize') {
        newPoints = currentPoints - 40;
      } else if (localStorage.selectedPrize === 't-shirt-prize') {
        newPoints = currentPoints - 35;
      } else if (localStorage.selectedPrize === 'tote-bag-prize') {
        newPoints = currentPoints - 30;
      } else if (localStorage.selectedPrize === 'stickers-prize') {
        newPoints = currentPoints - 5;
      }

      if (newPoints < 0) {
        swal
          .fire({
            title: 'You do not have enough points!',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
          })
          .then(() => this.props.history.push('/retail-training/redeem'));
      } else {
        swal
          .fire({
            title: `Your new balance will be ${newPoints} point(s).`,
            type: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
          })
          .then((result) => {
            if (result.value) {
              const data = {
                lotusEmail: lotusEmail,
                email: email,
                shippingFirstName: shippingFirstName,
                shippingLastName: shippingLastName,
                shippingStreet: shippingAddress,
                shippingCity: shippingCity,
                shippingState: shippingState,
                shippingZip: shippingZip,
                prizeType: localStorage.selectedPrize,
                shirtSize: shirtSize,
                prize: prize,
                phone: phone,
              };

              axios.post('/shipprize', data).then((res) => {
                if (res.data === 'error') {
                  swal.fire({
                    title: 'An error has occurred.',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                } else if (res.status) {
                  swal
                    .fire({
                      title: 'Success!',
                      text: 'You will receive an email when your item is shipped.',
                      icon: 'success',
                    })
                    .then(() => {
                      $('.redeem-button')
                        .prop('disabled', false)
                        .css('background-color', 'black')
                        .css('cursor', 'pointer');
                      this.props.history.push('/retail-training/redeem');
                    });
                }
              });
            }
          });
      }
    }
  }

  render() {
    return (
      <div>
        <div className="container contact-us-body">
          <div className="row">
            <div className="col contact-us-text">REDEEM</div>
          </div>
          <div className="row page-divider" />
          <div className="row mt-4 mb-3">
            <div className="col-auto" style={{ fontFamily: 'Futura-Bold', fontSize: '48px' }}>
              Points: {this.state.points}
            </div>
          </div>
          <div className="row d-flex justify-content-center align-content-center">
            <div className="col-12">
              <img className="img-fluid w-100 mb-4" src={this.state.selected} />
            </div>
            <div className="col-12 text-center">
              <div className="mx-auto col-lg-3 col-md-4 col-12 mb-3">
                <label
                  htmlFor="select-prize"
                  className="col-12 col-form-label search-radius text-center">
                  Select Your Prize
                </label>
                {localStorage.selectedPrize === 'dog-food-prize' ? (
                  <select id="select-prize" className="form-control">
                    <option value="Regular Bites Chicken">Regular Bites Chicken</option>
                    <option value="Regular Bites Puppy Chicken">Regular Bites Puppy Chicken</option>
                    <option value="Regular Bites Senior Chicken">
                      Regular Bites Senior Chicken
                    </option>
                    <option value="Regular Bites Turkey">Regular Bites Turkey</option>
                    <option value="Regular Bites Sardine">Regular Bites Sardine</option>
                    <option value="Regular Bites Duck">Regular Bites Duck</option>
                    <option value="Regular Bites Lamb">Regular Bites Lamb</option>
                    <option value="Small Bites Chicken">Small Bites Chicken</option>
                    <option value="Small Bites Puppy Chicken">Small Bites Puppy Chicken</option>
                    <option value="Small Bites Senior Chicken">Small Bites Senior Chicken</option>
                    <option value="Small Bites Turkey">Small Bites Turkey</option>
                    <option value="Small Bites Sardine">Small Bites Sardine</option>
                    <option value="Small Bites Duck">Small Bites Duck</option>
                    <option value="Small Bites Lamb">Small Bites Lamb</option>
                    <option value="Chicken Stew">Chicken Stew</option>
                    <option value="Pork Stew">Pork Stew</option>
                    <option value="Turkey Stew">Turkey Stew</option>
                    <option value="Venison Stew">Venison Stew</option>
                    <option value="Beef Stew">Beef Stew</option>
                    <option value="Chicken Loaf">Chicken Loaf</option>
                    <option value="Pork Loaf">Pork Loaf</option>
                    <option value="Turkey Loaf">Turkey Loaf</option>
                    <option value="Beef Loaf">Beef Loaf</option>
                    <option value="Duck Loaf">Duck Loaf</option>
                    <option value="Rabbit Loaf">Rabbit Loaf</option>
                    <option value="Salmon Loaf">Salmon Loaf</option>
                    <option value="Sardine Loaf">Sardine Loaf</option>
                    <option value="Tripe Loaf">Tripe Loaf</option>
                    <option value="Just Juicy Beef">Just Juicy Beef</option>
                    <option value="Just Juicy Pork">Just Juicy Pork</option>
                  </select>
                ) : localStorage.selectedPrize === 'cat-food-prize' ? (
                  <select id="select-prize" className="form-control">
                    <option value="Chicken Kibble">Chicken Kibble</option>
                    <option value="Sardine Kibble">Sardine Kibble</option>
                    <option value="Duck Kibble">Duck Kibble</option>
                    <option value="Low Fat Chicken Kibble">Low Fat Chicken Kibble</option>
                    <option value="Chicken Pâté">Chicken Pâté</option>
                    <option value="Pork Pâté">Pork Pâté</option>
                    <option value="Turkey Pâté">Turkey Pâté</option>
                    <option value="Duck Pâté">Duck Pâté</option>
                    <option value="Rabbit Pâté">Rabbit Pâté</option>
                    <option value="Salmon Pâté">Salmon Pâté</option>
                    <option value="Sardine Pâté">Sardine Pâté</option>
                    <option value="Just Juicy Chicken">Just Juicy Chicken</option>
                    <option value="Just Juicy Pork">Just Juicy Pork</option>
                    <option value="Just Juicy Turkey">Just Juicy Turkey</option>
                    <option value="Just Juicy Pollock">Just Juicy Pollock</option>
                    <option value="Just Juicy Venison">Just Juicy Venison</option>
                    <option value="Just Juicy Salmon">Just Juicy Salmon</option>
                  </select>
                ) : localStorage.selectedPrize === 'bottle-prize' ? (
                  <select id="select-prize" className="form-control">
                    <option value="Black Dog Bottle">Black Dog Bottle</option>
                    <option value="Blue Dog Bottle">Blue Dog Bottle</option>
                    <option value="Black Cat Bottle">Black Cat Bottle</option>
                    <option value="Blue Cat Bottle">Blue Cat Bottle</option>
                  </select>
                ) : localStorage.selectedPrize === 't-shirt-prize' ? (
                  <div>
                    <select id="select-shirt" className="form-control">
                      <option value="Bird Watcher (Black)">Bird Watcher (Black)</option>
                      <option value="Happy Camper (Red)">Happy Camper (Red)</option>
                      <option value="Happy Camper (Pink)">Happy Camper (Pink)</option>
                    </select>
                    <label
                      htmlFor="select-size"
                      className="col-12 col-form-label search-radius text-center">
                      Select Your Size
                    </label>
                    <select id="select-size" className="form-control">
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="2XL">2XL</option>
                    </select>
                  </div>
                ) : localStorage.selectedPrize === 'tote-bag-prize' ? (
                  <select id="select-prize" className="form-control">
                    <option value="Dog Tote">Dog Tote</option>
                    <option value="Cat Tote">Cat Tote</option>
                  </select>
                ) : localStorage.selectedPrize === 'stickers-prize' ? (
                  <select id="select-prize" className="form-control">
                    <option value="Dog Sticker">Dog Sticker</option>
                    <option value="Cat Sticker">Cat Sticker</option>
                  </select>
                ) : null}
              </div>
            </div>
            <div className="col-md-9 col-12 mt-5">
              <div className="checkout-subtitle">Email</div>
              <div className="row mt-2">
                <div className="col-lg-6 col-12 mt-3">
                  <div className="form-group">
                    <input type="text" className="form-control" id="email" />
                  </div>
                </div>
              </div>
              <div className="checkout-subtitle mt-4">Phone Number</div>
              <div className="row mt-2">
                <div className="col-lg-6 col-12 mt-3">
                  <div className="form-group">
                    <input type="text" className="form-control" id="phone" />
                  </div>
                </div>
              </div>
              <div className="checkout-subtitle mt-4">Shipping Address</div>
              <div className="row mt-2 d-flex align-content-center justify-content-center">
                <div className="col-lg-6 col-12 mt-3">
                  <div className="form-group">
                    <label htmlFor="shippingname">First Name</label>
                    <input type="text" className="form-control" id="shippingfirstname" />
                  </div>
                </div>
                <div className="col-lg-6 col-12 mt-3">
                  <div className="form-group">
                    <label htmlFor="shippingname">Last Name</label>
                    <input type="text" className="form-control" id="shippinglastname" />
                  </div>
                </div>
              </div>
              <div className="row mt-2 d-flex align-content-center justify-content-center">
                <div className="col-12 mt-3">
                  <div className="form-group">
                    <label htmlFor="shippingaddress">Address</label>
                    <input type="text" className="form-control" id="shippingaddress" />
                  </div>
                </div>
              </div>
              <div className="row mt-2 d-flex align-content-center justify-content-center">
                <div className="col-lg-5 col-12 mt-3">
                  <div className="form-group">
                    <label htmlFor="shippingaddress">City</label>
                    <input type="text" className="form-control" id="shippingcity" />
                  </div>
                </div>
                <div className="col-lg-2 col-12 mt-3">
                  <div className="form-group">
                    <label htmlFor="shippingaddress">State</label>
                    <select id="shippingstate" className="form-control">
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-5 col-12 mt-3">
                  <div className="form-group">
                    <label htmlFor="shippingaddress">Zip Code</label>
                    <input type="text" className="form-control" id="shippingzip" />
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="submit-contact redeem-button"
                      onClick={this.redeemPrize}
                      style={{ fontSize: '24px' }}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QuizShip;
