import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';

import Answers from './Answers.jsx';

const bgLinks = [
  'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/pink-dog-bar.jpg',
  'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/green-cat-bar.jpg',
  'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/red-dog-bar.jpg',
  'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/pink-cat-bar.jpg',
  'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/peach-dog-bar.jpg',
];

export default function Questions(props) {
  const { questionsBank } = props;

  return (
    <MDBRow className="retailer-quiz__questions">
      {questionsBank.map((bank, i) => {
        return (
          <MDBCol
            key={i}
            size="12"
            className="retailer-quiz__bg-wrapper my-5"
            style={{ backgroundImage: `url(${bgLinks[i]})` }}>
            <MDBRow className="my-4">
              <MDBCol className="text-center">
                <h2 className="retailer-quiz__question-header futura-font font-weight-bolder mb-2">
                  {bank.number}. {bank.question}
                </h2>
                <Answers
                  checkAnswer={props.checkAnswer}
                  answers={bank.answers}
                  correctAnswer={bank.correctAnswer}
                  questionNum={bank.number}
                />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        );
      })}
    </MDBRow>
  );
}
