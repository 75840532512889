import React from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBBtn,
} from 'mdbreact';

import GeneralSelectInput from './QuizRemdemptionShip/PrizePicker/GeneralSelectInput.jsx';

const { frontendPrizeText } = require('../QuizRedeem/inputOptions');

export default function QuizPrizes(props) {
  return props.prizeItems.map((prize, i) => (
    <MDBCol size="12" md="6" key={i} className=" py-4">
      <MDBCard className="quiz-redeem--card-height-adj">
        <div className="overflow-hidden">
          <MDBCardImage
            className={`img-fluid ${
              prize.points <= props.currentPoints || props.enabledPrizes[prize.prizeType]
                ? 'image-zoom-effect '
                : 'retailer-quiz--disabled-prizes'
            }`}
            src={prize.imgSrc}
            alt={prize.alt}
            waves
          />
        </div>

        <MDBCardBody>
          <MDBCardTitle>Lotus {frontendPrizeText[prize.prizeType]}</MDBCardTitle>
          <MDBCardText className="quiz-redeem--card-text">{prize.alt}</MDBCardText>

          <GeneralSelectInput
            prizeType={prize.prizeType}
            inputValsArr={props.prizeSelections[prize.prizeType]}
            selectPrizes={props.selectPrizes}
            prizePoints={prize.points}
            enabled={props.enabledPrizes[prize.prizeType]}
          />
          <MDBBtn
            color={props.enabledPrizes[prize.prizeType] ? 'amber' : 'dark'}
            className="ml-0 mt-3"
            onClick={(e) => props.enablePrizeSelection(prize.prizeType, prize.points, e)}
            disabled={prize.points > props.currentPoints && !props.enabledPrizes[prize.prizeType]}>
            Select prize
          </MDBBtn>
        </MDBCardBody>

        <MDBCardFooter></MDBCardFooter>
      </MDBCard>
    </MDBCol>
  ));
}
