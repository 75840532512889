import React from 'react';
import { MDBRow, MDBCol, MDBInputGroup } from 'mdbreact';

const { convertToArrTup } = require('../helper.jsx');

export default function FieldInputs(props) {
  const arrToMap = convertToArrTup(props.colInfo);
  return (
    <div className="admin-main__input-fields my-3 admin-main--border-divider">
      <MDBRow>
        <MDBCol>
          <h2 className="admin-main__header2 text-capitalize">{props.title}</h2>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        {arrToMap.map((anlObj, i) => {
          if (anlObj.name === 'calories') {
            const calArr = convertToArrTup(anlObj.value);
            return (
              <MDBCol key={i} className="admin-main--margin-x-10" size="10">
                <MDBRow>
                  {calArr.map((itemVal, j) => (
                    <MDBCol key={j} size="4">
                      <MDBInputGroup
                        ariaLabel={itemVal.name}
                        onChange={(e) => props.getInputFields(e, props.colName, 'anlCal')}
                        containerClassName="my-0"
                        material
                        value={itemVal.value}
                        prepend={
                          <span className="input-group-text md-addon text-capitalize">
                            Calories {itemVal.name}
                          </span>
                        }
                      />
                    </MDBCol>
                  ))}
                </MDBRow>
              </MDBCol>
            );
          }
          return (
            <MDBCol className="admin-main--margin-x-10" size="6" sm="4" md="3" key={i}>
              <MDBInputGroup
                ariaLabel={anlObj.name}
                onChange={(e) => props.getInputFields(e, props.colName)}
                containerClassName="my-0"
                material
                value={anlObj.value}
                prepend={
                  <span className="input-group-text md-addon text-capitalize">
                    {anlObj.name === 'calories' ? '' : anlObj.name}
                  </span>
                }
              />
            </MDBCol>
          );
        })}
      </MDBRow>
    </div>
  );
}
