import React, { useState, useEffect } from 'react';
import Modal from 'react-responsive-modal';
import { MDBInput } from 'mdbreact';
import subscribeToNewsLetter from '@Requests/subscribeToNewsLetter';
import helper from './helper';
import styles from './PopUp.module.scss';

export default function PopUp(props) {
  const [isOpen, openModal] = useState(false);
  const [email, setEmail] = useState('');
  const [zip, setZip] = useState('');

  useEffect(() => {
    if (!helper.readCookies('hide')) {
      openModal(true);
      console.log({ styles });
    }
  }, []);

  const closeModal = (e) => {
    helper.createCookie('hide', true, 1);
    openModal(false);
  };

  return (
    <Modal
      center
      open={isOpen}
      onClose={() => closeModal()}
      showCloseIcon={false}
      classNames={{
        overlay: `customOverlay ${isOpen ? styles['fade-in'] : styles['fade-out']}`,
        modal: 'customModal scale-in-center',
      }}>
      <>
        <div className="text-right popup-close">
          <span className="popup-icon" onClick={(e) => closeModal(e)}>
            <img
              src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Landing+Page/multiply.png'}
            />
          </span>
        </div>
        <div className={styles.popup__wrapper}>
          <img
            className="img-fluid d-none d-sm-block"
            src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/popup-image-2022.png"
            alt="Lotus cat raw image"
          />
          <section className={styles.popup__content}>
            <div className={`${styles.popup__title} text-uppercase futura-bold-font`}>
              Sign up meow & save furrever
            </div>
            <div className={`${styles.popup__p}`}>JOIN OUR MAILING LIST &</div>
            <div className={`${styles['popup__sub-title']} text-uppercase futura-bold-font`}>
              Get $5 off your next purchase
            </div>
            <div className={`${styles.popup__p} ${styles['popup__p--margin-bottom']}`}>
              Plus monthly deals, new product info & tips for keeping your pet happy and healthy
            </div>
            <MDBInput
              background
              onChange={({ target }) => setZip(target.value)}
              outline
              label="Zip code"
            />
            <MDBInput
              background
              onChange={({ target }) => setEmail(target.value)}
              outline
              label="email address"
            />
            <span className={styles.popup__terms}>
              By signing up, you are accepting our terms and conditions
            </span>
            <div className={styles.popup__btn__wrapper}>
              <button
                className={`${styles['popup__btn--signup']} ${styles.popup__btn}`}
                onClick={() => subscribeToNewsLetter({ email, zip })}>
                Sign Up
              </button>
            </div>
          </section>
        </div>
      </>
    </Modal>
  );
}
