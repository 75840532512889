import React, { Component } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBAnimation,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdbreact';
import { Link } from 'react-router-dom';

import IsLoading from '../../../../IsLoading/IsLoading.jsx';

const { modifyFeedingGuidelineObj, renderCorrectTxtInfo } = require('./FeedingGuidelineHelper.jsx');

export default class FeedingGuidelines extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: '0',
      feedingGuidelines: [],
      isLoaded: false,
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.modifyFeedingGuideline = this.modifyFeedingGuideline.bind(this);
    this.renderFeedingGuideline = this.renderFeedingGuideline.bind(this);
  }

  componentDidMount() {
    this.modifyFeedingGuideline(this.props.feedingGuidelines, this.props.pet);
  }

  modifyFeedingGuideline(guideline, pet) {
    if (guideline === null) return this.setState({ feedingGuidelines: [] });
    const newGuidelineObj = modifyFeedingGuidelineObj(guideline, pet);
    this.setState({
      feedingGuidelines: newGuidelineObj,
      isLoaded: true,
    });
  }

  renderFeedingGuideline(feedingGuidelines) {
    if (feedingGuidelines.length < 1 || feedingGuidelines === null) return <p>N/A</p>;
    return feedingGuidelines
      .sort((a, b) => a.size - b.size)
      .map((guideline, i) => {
        return (
          <MDBTabPane key={i} tabId={String(i)}>
            <MDBAnimation type="fadeIn">
              <MDBTable responsive>
                <MDBTableHead className="futura-font" columns={guideline.data.cols} />
                <MDBTableBody rows={guideline.data.rows} />
              </MDBTable>
            </MDBAnimation>
          </MDBTabPane>
        );
      });
  }

  toggleTab(tab) {
    if (this.state.currentTab !== tab) {
      this.setState({
        currentTab: tab,
      });
    }
  }

  render() {
    const { props, state } = this;
    return (
      <div className="product-main__feeding-guideline-wrapper">
        <MDBRow>
          <MDBCol>
            <MDBContainer className="classic-tabs">
              <MDBNav classicTabs>
                {props.size.map((item, i) => (
                  <MDBNavItem key={i}>
                    <Link
                      className={`text-dark font-weight-bolder futura-font ${
                        state.currentTab === String(i) ? 'active' : ''
                      }`}
                      to="#"
                      onClick={() => this.toggleTab(`${i}`)}
                      role="tab">
                      {item}
                    </Link>
                  </MDBNavItem>
                ))}
              </MDBNav>
              <MDBTabContent
                className="product-main__tab-content--no-shadow"
                activeItem={state.currentTab}>
                {this.renderFeedingGuideline(state.feedingGuidelines)}
              </MDBTabContent>
              <MDBRow>
                <MDBCol>{renderCorrectTxtInfo(props.foodType, props.name)}</MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}
