import React from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';

import ImgRender from '../../../../../ImgUploader/ImgRender.jsx';

export default function ProductImg(props) {

  return (
    <MDBRow>
      <MDBCol className="my-3" size="12">
        <span className="admin-main__span futura-font text-capitalize">
          You are editing this product&lsquo;s {props.selectedSize} {props.selectedView} image.
        </span>
      </MDBCol>
      <MDBCol size="12">
        <img className="img-fluid text-center" src={props.selectedImg.thumb} />
      </MDBCol>
      {props.uploadedImgLoaded ? (
        <MDBCol className="mt-5">
          <span className="admin-main__span futura-font text-capitalize">
            You will be replacing previous image with this new image:
          </span>
        </MDBCol>
      ) : null}

      <ImgRender
        imgLoader={props.imgLoader}
        uploadedImgDataUrl={props.uploadedImgDataUrl}
        uploadedImgLoaded={props.uploadedImgLoaded}
        selectedImg={props.selectedImg}
        getUploadImg={props.getUploadImg}
      />

      <MDBCol size="12">
        <MDBBtn onClick={props.updateImages} color="primary">
          Update Image
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  );
}
