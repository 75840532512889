import React from 'react';
import Modal from 'react-responsive-modal';
import $ from 'jquery';

export default class SwipePopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentWillMount() {
    const readCookie = (name) => {
      var nameEQ = name + '=';
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    };

    if (!readCookie('protein')) {
      this.setState({
        open: true,
      });
    } else {
      this.setState({
        open: false,
      });
    }
  }

  componentDidMount() {
    const that = this;
    setTimeout(function() {
      const createCookie = (name, value, days) => {
        if (days) {
          var date = new Date();
          date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
          var expires = '; expires=' + date.toGMTString();
        } else var expires = '';
        document.cookie = name + '=' + value + expires + '; path=/';
      };

      createCookie('protein', true, 1);
      that.setState({
        open: false,
      });
    }, 1750);
  }

  onCloseModal(e) {
    const createCookie = (name, value, days) => {
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        var expires = '; expires=' + date.toGMTString();
      } else var expires = '';
      document.cookie = name + '=' + value + expires + '; path=/';
    };

    if (e) {
      createCookie('protein', true, 1);
      this.setState({
        open: false,
      });
    }
  }

  render() {
    return (
      <div>
        <Modal
          center
          open={this.state.open}
          onClose={this.onCloseModal}
          showCloseIcon={false}
          classNames={{
            overlay: 'proteinOverlay',
            modal: 'proteinModal',
          }}>
          <div>
            <img
              className="swipe-icon"
              src="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/swipe-icon.png"
            />
          </div>
          <div>Swipe left to view more sizes!</div>
        </Modal>
      </div>
    );
  }
}
