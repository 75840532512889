import React from 'react';
import { MDBRow, MDBCol, MDBInputGroup } from 'mdbreact';

const { convertToArrForFeeding } = require('../helper.jsx');

/*
  Since feeding Guideline has size of the actual product
  Obj needs to be rendered:
    size: {name: size, value: 5}
    guideline: [{name:weight, value: 5 }]
*/
export default function FeedingGuidelines(props) {
  const feedingArr = convertToArrForFeeding(props.feedingGuidelines);
  return (
    <div className="admin-main__feeding-guidelines my-3 admin-main--border-divide">
      <MDBRow>
        <MDBCol>
          <h2 className="admin-main__header2 text-capitalize">Feeding Guideline</h2>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        {feedingArr.map((feedingInfo, i) => {
          const { size, guideline } = feedingInfo;
          return (
            <MDBCol className="admin-main--margin-x-10" size="12" sm="6" key={i}>
              <span className="input-group-text md-addon text-capitalize">
                Food Package Size {size.value}
              </span>

              {guideline.map((info, key) => {
                return (
                  <MDBCol className="admin-main--margin-x-10" size="12" key={key}>
                    <MDBInputGroup
                      ariaLabel={info.name}
                      onChange={(e) => props.getInputFields(e, props.colName, size.value)}
                      containerClassName="my-0"
                      material
                      value={info.value}
                      prepend={
                        <span className="input-group-text md-addon text-capitalize">
                          Pet Weight: {info.name}
                        </span>
                      }
                    />
                  </MDBCol>
                );
              })}
            </MDBCol>
          );
        })}
      </MDBRow>
    </div>
  );
}
