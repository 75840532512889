import React, { Component } from 'react';
import Axios from 'axios';
import swal from 'sweetalert2';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBAnimation } from 'mdbreact';

import IsLoadingChild from '../../IsLoading/IsLoadingChild.jsx';
import Questions from './Questions.jsx';

const { success, error, warning } = require('../../_helper/SweetAlerts/alerts.jsx');
const { handleErrors } = require('../../_helper/reqsHandlers.js');
const { setToken } = require('../../_helper/index.jsx');
const {
  setCorrectQuiz,
  countCorrectAnswers,
  checkIfAllQuestionsAnswered,
  updateLocalStorageQuizInfo,
} = require('../helper.js');
const {
  endPoint: { quizEndPoint, usersEndpoint },
} = require('../../_helper/stringVars');

export default class RenderQuizQuestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quiz: {},
      quizName: '',
      answers: {},
      questionsAnswered: 0,
      quizLoaded: false,
      taken: false,
      modal: false,
    };
  }

  componentDidMount() {
    const { quizId } = this.props.match.params;
    this.getQuiz(quizId);
  }

  getQuiz = (quizId) => {
    const config = { headers: setToken('user'), params: { quizId } };
    Axios.get(`${quizEndPoint.client}/get-quiz`, config)
      .then((res) => {
        const { data, status } = res;
        const quizName = this.setCorrectName(data.name);
        const { taken } = this.props.location.state;
        if (status !== 200) return handleErrors(status);
        this.setState({
          quiz: data,
          quizLoaded: true,
          quizName,
          taken,
        });
      })
      .catch((err) => {
        console.error(err);
        error({ title: 'Server Error', text: 'Please try again later.', button: 'Okay' });
      });
  };

  setCorrectName = (name) => {
    const quiz = setCorrectQuiz(name);
    return quiz.main;
  };

  checkAnswer = (e, answer, correctAnswer) => {
    e.preventDefault();
    const { id } = e.target;
    this.setState((state) => ({
      answers: { ...state.answers, [id]: [correctAnswer, answer] },
      questionsAnswered: state.questionsAnswered + 1,
    }));
  };

  updateUserQuizInfo = (quizId, userId, newPoints, localUserObj) => {
    Axios.put(`${usersEndpoint}/update-quiz-info`, {
      params: { userId, quizId, newPoints },
    })
      .then((res) => {
        const { data, status } = res;
        if (status === 400) return handleErrors(400);
        if (status === 404) return handleErrors(404);
        /* No need to do anything */
        updateLocalStorageQuizInfo(quizId, localUserObj);
      })
      .catch((err) => {
        const { status } = err.response;
        console.error(err);
        error({ title: 'Error', text: 'Server Error. Please try again later.', button: 'Okay' });
      });
  };

  submitQuiz = (e) => {
    e.preventDefault();
    const { state } = this;
    const { history } = this.props;
    const { quizId } = this.props.match.params;
    const localUserObj = JSON.parse(localStorage.getItem('user'));
    const { _id } = localUserObj;

    const alertTxt = state.taken
      ? 'You have taken this quiz previously. You will not earn any points for this submission.'
      : 'Once submitted, you can retake this quiz, but will no longer earn any points for this quiz.';

    if (!checkIfAllQuestionsAnswered(state.answers, state.questionsAnswered)) {
      return error({
        title: 'Missing answers.',
        text: 'Please make sure that all questions are answered.',
        button: 'Okay',
      });
    }
    swal
      .fire({
        title: 'Ready to submit quiz?',
        text: alertTxt,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Submit',
        cancelButtonText: 'Review Quiz',
      })
      .then((result) => {
        if (result.value) {
          const correctAnswers = countCorrectAnswers(state.answers);
          this.updateUserQuizInfo(parseInt(quizId, 10), _id, correctAnswers, localUserObj);
          history.push({
            pathname: `/retail-training/quiz/results/${state.quizName}`,
            state: { results: correctAnswers, taken: state.taken },
          });
        }
      });
  };

  renderComponent = () => {
    const { state } = this;
    if (!state.quizLoaded) return <IsLoadingChild />;
    return <Questions checkAnswer={this.checkAnswer} questionsBank={state.quiz.questions_bank} />;
  };

  render() {
    const { quizName } = this.state;

    return (
      <MDBContainer className="container margin-top-adjustment retailer-quiz__questions">
        <MDBAnimation type="fadeIn" duration=".5">
          <MDBRow center>
            <MDBCol size="12">
              <h1 className="retailer-quiz__header1 brandon-font text-uppercase">
                {quizName} Quiz
              </h1>
              <div className="page-divider mb-3"></div>
            </MDBCol>
            <MDBCol size="12">{this.renderComponent()}</MDBCol>
            <MDBCol size="12" className="d-flex justify-content-center">
              <MDBBtn onClick={this.submitQuiz} color="dark">
                Submit Quiz
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBAnimation>
      </MDBContainer>
    );
  }
}
