import React from 'react';

class AboutUs extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="container about-us">
          <div className="row">
            <div className="col-12">
              <h1 className="about-us__title brandon-font">ABOUT US</h1>
            </div>
            <div className="col-12 page-divider about-us__divider page-divider" />
            <div className="col-12 mb-5">
              <p className="about-us__text-info futura-font">
                Family Owned and Operated. From the beginning, Lotus has always been family owned
                and operated. In 2003, we created Lotus because of our love for pets and because we
                knew we could make something better. We also wanted to be very hands-on owners by
                learning and doing all aspects of making dog and cat food. We decide how our foods
                are made (small batches of kibble that are oven-baked and small batches of cans that
                are made in our own micro-cannery), and what ingredients go into them, which we feel
                are the very best. There is no multi-national conglomerate or private equity company
                telling us how to make our food.
              </p>
            </div>
          </div>
          <div className="col-12 overflow-hidden">
            <img
              className="img-fluid image-zoom-effect"
              src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/About+Us/about-us.png'}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
