import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class CatObesity extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
      }

  render() {
    return (
        <div>
                <div className="pb-3 blog-margin-adjustment-desktop">
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/fat-cat-banner.png'} 
                        className="banner img-fluid w-100" 
                        alt="lotus Pet Foods - Why is my cat fat?"/>
                    </div>
                </MDBAnimation>
                </div>

            <div className='container mt-5'>
                <p style={{"color": "grey"}}>May, 01, 2022</p>
            </div>

            <div className='container mt-5 text-center d-none-mobile'>
                <h1><span className="LemonMilkbold-font blog-hb">Why is my Cat Fat?</span></h1>
            </div>
       
            <div className='container mt-5 text-center d-none-desktop'>
                <h1><span className="LemonMilkbold-font blog-h">Why is my Cat Fat?</span></h1>
            </div>
  
            <div className='container' style={{"marginTop": "60px"}}> 
                <div className='mb-5 futura-font blog-p'>
                    <p>Do you have a chonk at home? A Garfield? A big boy or girl? Fat cats are very cute, and very common, According to the Association for Pet Obesity Prevention’s 
                        2015 survey, veterinarians consider an estimated 58 percent of cats to be overweight or obese.  It is important to think about the effects the extra weight 
                        has on your kitty.</p>
                    <br />
                    <p>First you need to determine if your cat is just fluffy, or fat. A few tips:</p>
                    <ul>
                        <li>Can you feel their ribs?  rub your fingers down the side of your cat with light pressure, if you can feel their ribs – they’re likely at an ideal weight.</li>
                        <li>Can you see your cat’s waist? If you look at your cat from above, can you see a defined waist? If not, they may be overweight.</li>
                        <li>If you’re unable to determine at home if your cat is overweight, a trip to the vet maybe in order</li>
                    </ul>
                    <br />
                    <p>One of the first things we’re recommended as new cat parents is to get our kitties fixed! (and they should be) Although having your cat spayed or neutered is the 
                        best way to control the overpopulation of cats, after cats get spayed or neutered, they tend to gain weight. This procedure changes their hormones and tends to 
                        slow down their metabolism and also increases a cat’s appetite, both of these problems can contribute to an over- weight cat.  Some vets will recommend reducing 
                        your cat’s food intake by 30% after they have this procedure done. </p>
                     <br />
                     <p>So how do we help our over-weight cats? The most obvious way to help them is to make sure they’re eating an appropriate diet, as well as the right amount. Many 
                        cat owners appreciate the independence their cats have, we fill up their bowl of food, and that cat will make SURE to let us know when it’s half empty so we can 
                        replenish their meals.  But this isn’t always the best practice.</p>
                     <br />
                     <p>Cats are carnivores by nature, what does that mean? It means cat need a very meat heavy diet. In a perfect world our cats would be running around hunting for their 
                        food and reaping all the benefits of a whole prey diet, but most of our cats have adapted to different diets. When choosing a kibble, a grain-free oven baked kibble 
                        is best for keeping starch delivery down, which can be a contributing factor to an over-weight cat. Make sure a dry food has fresh meat such as chicken, duck or fish 
                        as the first ingredient, keep the grains & starch to an minimum.</p>
                     <br />
                     <p>If you can feed your cat canned food, this is better than dry food, due to the high meat quantity and low carbohydrates. Canned food is also lower in calories per oz, 
                        so your cat may find it more satisfying. Find a can that is 90-95% meat, with limited carbohydrates. And the best you can do for your cat is to feed them a raw meat diet. 
                        Being obligate carnivores, cats really thrive on this type of diet. Frozen raw is safe to feed and can keep your cat happy, healthy & lean.</p>
                     <br />
                     <p>At the end of the day, make sure your cat is eating the appropriate amount, between 20-35 kcals per lb of body weight, per day. This depends on activity of your cat, 
                        cats that don’t move much would be closer to 20kcals, and cats that go outside would need 35 kcals per day. Dividing the feedings between 2 or 3 meals will help keep your 
                        cat stay satiated throughout the day. If you’re moving from a free-feed scenario, you will, of course, need to get your cat used to the change, gradually. Cats take a little 
                        longer, but they will adapt to a feeding schedule! When measuring your cat’s food, make sure to use a measuring cup (not a scoop, they’re not reliable) or a food scale. 
                        You may need to start at 35 Kcals a day, even for couch potato kitties, and slowly move down to a more appropriate level that will help them get to, and maintain a healthy 
                        body weight. </p>
                </div>
             </div>
        </div>
    );
  }
}

export default CatObesity;