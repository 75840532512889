import React, { Component } from 'react';
import Axios from 'axios';
import {
  MDBContainer,
  MDBDataTable,
  MDBTabContent,
  MDBTabPane,
  MDBAnimation,
  MDBNav,
} from 'mdbreact';

import IsLoading from '../../../../IsLoading/IsLoading.jsx';

const {
  endPoint: { adminUserEndpoint },
} = require('../../../../_helper/stringVars');
const { handleErrors } = require('../../../../_helper/reqsHandlers');
const { setToken } = require('../../../../_helper/index.jsx');
const { converToMDBTable } = require('./helper');

export default class RetailersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      retailers: [],
      retailersLoaded: false,
    };

    this.token = { headers: setToken('admin') };
  }

  componentDidMount() {
    this.getRetailersUsers();
  }

  getRetailersUsers = () => {
    Axios.get(`${adminUserEndpoint}/get-users`, this.token)
      .then((res) => {
        const { data, status } = res;
        if (status === 400) return handleErrors(400);
        if (status === 404) return handleErrors(404, 'retailer information');
        const modifiedData = converToMDBTable(data);
        this.setState({
          retailers: modifiedData,
          retailersLoaded: true,
        });
      })
      .catch((err) => {
        console.log({ err });
        const { status } = err.response;
        if (status === 500) return handleErrors(500);
      });
  };

  renderComponent = () => {
    const { retailers, retailersLoaded } = this.state;
    if (!retailersLoaded) return <IsLoading />;
    return (
      <MDBContainer className="admin-main__retailers-list admin-main__retailer-list--width-adjustment">
        <MDBAnimation type="fadeIn">
          <MDBNav classicTabs color="primary" className="mt-5">
            <h1 className="admin-main__header1 m-4">Retailers Info</h1>
          </MDBNav>

          <MDBTabContent className="admin-main__tab-content">
            <MDBTabPane>
              <MDBDataTable
                autoWidth
                exportToCSV
                entries={50}
                entriesOptions={[50, 100, 200]}
                striped
                responsive
                bordered
                hover
                noBottomColumns
                data={retailers}
              />
            </MDBTabPane>
          </MDBTabContent>
        </MDBAnimation>
      </MDBContainer>
    );
  };

  render() {
    return this.renderComponent();
  }
}
