const productIcons = {
  Stew: [
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/no-carra.png',
      toolTip:
        'This recipe does not contain carrageenan, which is a red algae that used as a thickener and can be processed chemically. We use agar agar instead as thickener. Agar agar is also a red algae but is processed using hot water.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/grain-free-icon.png',
      toolTip: 'A recipe made without grains.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/limited-can.png',
      toolTip:
        'A grain-free stew that is free from corn, wheat, and soy made with single or very limited species animal proteins.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/mussel-icon.png',
      toolTip:
        'These mussels are Harvested from the waters of New Zealand and are a natural source of glucosamine and chondroitin.',
      text: null,
    },
  ],

  Loaf: [
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/no-carra.png',
      toolTip:
        'This recipe does not contain carrageenan, which is a red algae that used as a thickener and can be processed chemically. We use agar agar instead as thickener. Agar agar is also a red algae but is processed using hot water.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/grain-free-icon.png',
      toolTip: 'A recipe made without grains.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/limited-can.png',
      toolTip:
        'A grain-free stew that is free from corn, wheat, and soy made with single or very limited species animal proteins.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/mussel-icon.png',
      toolTip:
        'These mussels are Harvested from the waters of New Zealand and are a natural source of glucosamine and chondroitin.',
      text: null,
    },
  ],
  Pate: [
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/no-carra.png',
      toolTip:
        'This recipe does not contain carrageenan, which is a red algae that used as a thickener and can be processed chemically. We use agar agar instead as thickener. Agar agar is also a red algae but is processed using hot water.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/grain-free-icon.png',
      toolTip: 'A recipe made without grains.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/limited-can.png',
      toolTip:
        'A grain-free stew that is free from corn, wheat, and soy made with single or very limited species animal proteins.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/mussel-icon.png',
      toolTip:
        'These mussels are Harvested from the waters of New Zealand and are a natural source of glucosamine and chondroitin.',
      text: null,
    },
  ],
  Juicy: [
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/no-carra.png',
      toolTip:
        'This recipe does not contain carrageenan, which is a red algae that used as a thickener and can be processed chemically. We use agar agar instead as thickener. Agar agar is also a red algae but is processed using hot water.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/grain-free-icon.png',
      toolTip: 'A recipe made without grains.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/limited-can.png',
      toolTip:
        'A grain-free stew that is free from corn, wheat, and soy made with single or very limited species animal proteins.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/95meat.png',
      toolTip: 'A recipe made with 95% meat.',
      text: null,
    },
  ],
  'Just Juicy': [],
  Treats: [
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/aloe-vera.png',
      toolTip:
        'Organic aloe vera gel extract sourced from farms in USA. It is also grown from Non-GMO aloe vera seeds. It can help disgestion by reducing acidity.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/grain-free.png',
      toolTip: 'A recipe made without grains.',
      text: null,
    },
    {
      src:
        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/lim-ingredient-treat.png',
      toolTip:
        'A grain-free treat that is free from corn, wheat, and soy and made with fresh, single species animal proteins.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/oven-baked.png',
      toolTip:
        'Our treats are Oven-Baked and not extruded like most other kibble. This means that our treats are less processed, retains more nutrients, and delivers less starch and sugar.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/render-meal-icon.png',
      toolTip: 'A recipe that does not contain any rendered animal protein like chicken meal.',
      text: null,
    },
  ],
  Kibble: [
    {
      src:
        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Cat+Food/Kibble/Icons/potato-free.png',
      toolTip: 'A recipe made without white potato.',
      text: null,
    },
    {
      src:
        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Cat+Food/Kibble/Icons/oven-baked.png',
      toolTip:
        'Our kibble is Oven-Baked and not extruded like most other kibble. This means our kibble is less processed and retains more nutrients and delivers less starch and sugar.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Cat+Food/Kibble/Icons/limited.png',
      toolTip:
        'A grain-free recipe that is free from corn, wheat, and soy with a select choice of fresh duck for appropriate nutrition.',
      text: null,
    },
    {
      src:
        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Cat+Food/Kibble/Icons/grain-free.png',
      toolTip: 'A recipe made without grains.',
      text: null,
    },
    {
      src:
        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Cat+Food/Kibble/Icons/low-starch.png',
      toolTip:
        'Lotus Oven-Baked kibble delivers half the amount of starch and sugar of a comparable extruded kibble.',
      text: null,
    },
  ],
  'Raw Food': [
    {
      src:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/limited-ingredient-icon.png',
      toolTip:
        'Limited Ingredient Diets – Our limited ingredient raw recipes are made with same species meat or poultry cuts with limited carbohydrates.',
      text: null,
    },
    {
      src:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/no-veggies-icon.png',
      toolTip:
        'NO Vegetables or Fruits – Our raw recipes for cats do not contain any vegetables or fruits. This helps texture and palatability.',
      text: null,
    },
    /*{
      src:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/no-bone-icon.png',
      toolTip:
        'NO Ground Bone – Our raw recipes do not contain any ground bone. This helps texture and palatability. It also helps maintain proper calcium and phosphorus levels',
      text: null,
    },
    {
      src:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/meats-icon.png',
      toolTip:
        'Meats with no added hormones or antibiotics – We only use meats and poultry that are vegetarian fed and our not given any hormones or antibiotics.',
      text: null,
    },*/
  ],
  'Raw Foods': [
    {
      src:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/limited-ingredient-icon.png',
      toolTip:
        'Limited Ingredient Diets – Our limited ingredient raw recipes are made with same species meat or poultry cuts with limited carbohydrates.',
      text: null,
    },
    {
      src:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/no-veggies-icon.png',
      toolTip:
        'NO Vegetables or Fruits – Our raw recipes for cats do not contain any vegetables or fruits. This helps texture and palatability.',
      text: null,
    },
    {
      src:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/no-bone-icon.png',
      toolTip:
        'NO Ground Bone – Our raw recipes do not contain any ground bone. This helps texture and palatability. It also helps maintain proper calcium and phosphorus levels',
      text: null,
    },
    {
      src:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/meats-icon.png',
      toolTip:
        'Meats with no added hormones or antibiotics – We only use meats and poultry that are vegetarian fed and our not given any hormones or antibiotics.',
      text: null,
    },
  ],
  'Regular Bites': [
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/starch-icon.png',
      toolTip:
        'Lotus Oven-Baked kibble delivers half the amount of starch and sugar of a comparable extruded kibble.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/oven-baked-icon.png',
      toolTip:
        'Our kibble is Oven-Baked and not extruded like most other kibble. This means that our kibble is less processed, retains more nutrients, and delivers less starch and sugar.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/calcium-icon.png',
      toolTip:
        'Formulated for optimized calcium levels below 1.5% and phosphorus levels below 1%. This is the perfect amount for proper bone growth for large and giant breed dogs. This is also optimized for a healthy urinary track system for all dogs, especially smaller dogs.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/no-spray-icon.png',
      toolTip:
        'A recipe that does not contain any hydrolyzed flavors(natural flavors). We don’t use these man-made flavorings because we use plenty of fresh meat. Our food tastes good because we use good ingredients.',
      text: null,
    },
  ],
  'Small Bites': [
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/starch-icon.png',
      toolTip:
        'Lotus Oven-Baked kibble delivers half the amount of starch and sugar of a comparable extruded kibble.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/oven-baked-icon.png',
      toolTip:
        'Our kibble is Oven-Baked and not extruded like most other kibble. This means that our kibble is less processed, retains more nutrients, and delivers less starch and sugar.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/calcium-icon.png',
      toolTip:
        'Formulated for optimized calcium levels below 1.5% and phosphorus levels below 1%. This is the perfect amount for proper bone growth for large and giant breed dogs. This is also optimized for a healthy urinary track system for all dogs, especially smaller dogs.',
      text: null,
    },
    {
      src: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/no-spray-icon.png',
      toolTip:
        'A recipe that does not contain any hydrolyzed flavors(natural flavors). We don’t use these man-made flavorings because we use plenty of fresh meat. Our food tastes good because we use good ingredients.',
      text: null,
    },
  ],
  Topper: [
    {
      src: 'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Product+Page/icon-tripe2.png',
      toolTip: 'NO Added Hormones or Antibiotics',
      text: null,
    },
    {
      src: 'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Product+Page/icon-tripe.png',
      toolTip: 'Made with Beef Tripe & Water',
      text: null,
    },
  ],
  Toppers: [
    {
      src: 'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Product+Page/icon-tripe2.png',
      toolTip: 'NO Added Hormones or Antibiotics',
      text: null,
    },
    {
      src: 'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Product+Page/icon-tripe.png',
      toolTip: 'Made with Beef Tripe & Water',
      text: null,
    },
  ],
};

module.exports = { productIcons };



