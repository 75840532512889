import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact';
import HeroBanner from '../ProductCard/HeroBanner.jsx';
import HeroDescription from '../ProductCard/HeroDescription.jsx';
import ProductCard from '../ProductCard/ProductCard.jsx';
import DogRawAddition from '../Custom/dogRaw/DogRawAddition.jsx';
import CatRawAddition from '../Custom/catRaw/CatRawAddition.jsx';
import DogTripeRawAddition from '../Custom/dogRaw/DogTripeRawAddition.jsx';

export default function SimpleOverview(props) {
  const { title, banner, description, products } = props.state;
  const { petType, foodType } = props;

  const renderAdditions = () => {
    if (petType === 'dog' && foodType === 'raw-foods') {
      return (
        <MDBAnimation type="fadeIn">
          <DogRawAddition foodType={foodType} petType={petType} />
        </MDBAnimation>
      );
    } else if (petType === 'cat' && foodType === 'raw-food'){
      return (
        <MDBAnimation type="fadeIn">
          <CatRawAddition foodType={foodType} petType={petType} />
        </MDBAnimation>
      )
    } else if ((petType === 'dog' || petType === 'cat') && (foodType === 'topper' || foodType === 'toppers')){
      return (
        <MDBAnimation type="fadeIn">
          <DogTripeRawAddition foodType={foodType} petType={petType} />
        </MDBAnimation>
      ) 
    } /*else if (petType === 'cat' && foodType === 'toppers'){
        return (
          <MDBAnimation type="fadeIn">
            <DogTripeRawAddition foodType={foodType} petType={petType} />
          </MDBAnimation>
        ) 
      }*/
    return null;
  };
  return (
    <div className="product-overview__simple-overview mb-5">
      <MDBAnimation type="fadeIn">
        <HeroBanner banner={banner} />
      </MDBAnimation>
      <MDBContainer className="my-4">
        <MDBAnimation type="fadeIn">
          <HeroDescription title={title} description={description} />
        </MDBAnimation>
        <MDBAnimation type="slideInUp">
          <ProductCard products={products} foodType={foodType} petType={petType} />
        </MDBAnimation>
      </MDBContainer>
      {renderAdditions()}
    </div>
  );
}
