import React from 'react';
import { Link } from 'react-router-dom';

function Shop(props) {
  return (
    <div>
      <div className="container margin-top-adjustment">
        <div className="row">
          <div className="col-md-6 col-12 text-center mb-5">
            <Link to="/shop/accessories/shirts" href="/shop/accessories/shirts">
              <img
                className="shop-category"
                src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/shirts.png'}
              />
              <img
                className="shop-category shop-category-pic"
                src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/shirts-pic.png'}
              />
            </Link>
          </div>
          <div className="col-md-6 col-12 text-center">
            <Link to="/shop/accessories" href="/shop/accessories">
              <img
                className="shop-category"
                src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/accessories.png'}
              />
              <img
                className="shop-category shop-category-pic"
                src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/accessories-pic.png'}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shop;
