import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import SwipePopUp from '../SwipePopUp';

class Venison extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
      reviews: {
        dogStew: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogTreat: {
          total: 0,
          stars: 0,
          average: 0
        },
        catJuicy: {
          total: 0,
          stars: 0,
          average: 0
        }
      }
    };
    this.anotherProtein = this.anotherProtein.bind(this);
  }

  componentWillMount() {
    axios.post('/getallreviews').then(res => {
      const reviews = {
        dogStew: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogTreat: {
          total: 0,
          stars: 0,
          average: 0
        },
        catJuicy: {
          total: 0,
          stars: 0,
          average: 0
        }
      };

      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].product_id === 24) {
          reviews.dogStew.total++;
          reviews.dogStew.stars += res.data[i].stars;
          reviews.dogStew.average = reviews.dogStew.stars / reviews.dogStew.total;
        }

        if (res.data[i].product_id === 20) {
          reviews.dogTreat.total++;
          reviews.dogTreat.stars += res.data[i].stars;
          reviews.dogTreat.average = reviews.dogTreat.stars / reviews.dogTreat.total;
        }

        if (res.data[i].product_id === 52) {
          reviews.catJuicy.total++;
          reviews.catJuicy.stars += res.data[i].stars;
          reviews.catJuicy.average = reviews.catJuicy.stars / reviews.catJuicy.total;
        }
      }

      this.setState({
        reviews: reviews
      });
    });
  }

  anotherProtein() {
    const protein = $('#protein').val();
    this.props.history.push(`/${protein}`);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const htmlWidth = $('html').width();
    const scroll = htmlWidth * 0.514;
    $('.scrolling-wrapper').scrollLeft(scroll);
  }

  render() {
    return (
      <div>
        <SwipePopUp />
        <div className='container chicken-container'>
          <div className='row d-flex align-items-center justify-content-center mb-5'>
            <div className='col-12 text-center another-protein'>Try Another Protein?</div>
            <div className='col-8 mt-3 mb-2'>
              <select id='protein' className='form-control'>
                <option value='rabbit'>Rabbit</option>
                <option value='chicken'>Chicken</option>
                <option value='duck'>Duck</option>
                <option value='beef'>Beef</option>
                <option value='green-tripe'>Green Tripe</option>
                <option value='pork'>Pork</option>
                <option value='turkey'>Turkey</option>
                <option value='lamb'>Lamb</option>
                <option value='fish'>Fish</option>
              </select>
            </div>
            <div className='col-12 d-flex align-content-center justify-content-center'>
              <button type='submit' className='protein-submit-button' onClick={this.anotherProtein}>
                Submit
              </button>
            </div>
          </div>
          <div className='protein-pet-header text-center'>DOG</div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 protein-card align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Venison/dog-treats.png'} />
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/treats/venison' href='/dog-food/treats/venison'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Venison/treats.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#673918' }}>
                    VENISON RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='venison-protein'
                    starCount={5}
                    value={this.state.reviews.dogTreat.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogTreat.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 protein-card align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Venison/stews.png'} />
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/regular-bites/lamb' href='/dog-food/regular-bites/lamb'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Venison/dogstew5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#673918' }}>
                    VENISON RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='venison-protein'
                    starCount={5}
                    value={this.state.reviews.dogStew.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogStew.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/regular-bites/lamb' href='/dog-food/regular-bites/lamb'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Venison/dogstew12.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#673918' }}>
                    VENISON RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='venison-protein'
                    starCount={5}
                    value={this.state.reviews.dogStew.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogStew.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-5 ingredient-divider' />
          <div className='protein-pet-header mt-5 text-center'>CAT</div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 protein-card align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Venison/just-juicy.png'} />
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/regular-bites/lamb' href='/dog-food/regular-bites/lamb'>
                  <img className='small-can-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Venison/catjuicy2.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#673918' }}>
                    VENISON RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='venison-protein'
                    starCount={5}
                    value={this.state.reviews.catJuicy.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catJuicy.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/regular-bites/lamb' href='/dog-food/regular-bites/lamb'>
                  <img style={{ maxWidth: '77%' }} src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Venison/catjuicy5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#673918' }}>
                    VENISON RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='venison-protein'
                    starCount={5}
                    value={this.state.reviews.catJuicy.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catJuicy.total}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Venison;
