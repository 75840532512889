import React from 'react';
import { MDBRow, MDBCol, MDBSelect, MDBBtn, MDBIcon } from 'mdbreact';

import SimpleInputFields from '../../../../../FieldComponents/InputFields/InputField.jsx';

export default function StoreSearch(props) {
  const { query, searchStores, onChange, getValue, searchOps } = props;
  return (
    <MDBRow>
      <MDBCol size="12">
        <h2 className="admin-main__header2 futura-font">Search Stores:</h2>
      </MDBCol>
      <MDBCol size="12" sm="4">
        <MDBSelect options={searchOps} getValue={getValue} label="Select options" />
      </MDBCol>
      <MDBCol size="12" sm="7" className="px-0">
        <SimpleInputFields
          name="query"
          value={query}
          label="Search stores..."
          onChange={onChange}
        />
      </MDBCol>
      <MDBCol size="1" className="admin-stores__search-btn d-flex">
        <MDBBtn onClick={searchStores} flat circle size="lg">
          <MDBIcon icon="search" />
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  );
}
