/**
 * Count how many quiz has been taken
 * @param {Object} quizObj
 * @return {Number}
 */

const countQuizzesTaken = (quizObj) => {
  if (quizObj === null || quizObj === 'null') return 'N/A';
  let count = 0;
  const parsedQuiz = JSON.parse(quizObj);
  const doubleParsed = typeof parsedQuiz === 'string' ? JSON.parse(parsedQuiz) : parsedQuiz;
  doubleParsed.forEach((quiz) => {
    if (quiz.taken === true) {
      count += 1;
    }
  });
  return count;
};

/**
 *
 * @param {Object} storeObj
 * @return {Object} with removed stores === null
 */
const removeNullStores = (storeObj) => {
  return Object.keys(storeObj).reduce((finalObj, keys) => {
    if (storeObj[keys] === 'nu' || storeObj[keys] === undefined || storeObj[keys] === 'null') {
      finalObj[keys] = 'N/A';
      return finalObj;
    }
    finalObj[keys] = storeObj[keys].trim();
    return finalObj;
  }, {});
};

/**
 *
 * @param {Object} storeStr
 * @return {Object} modify Store to render onto Front-end
 */
const modifyStoreInfo = (storeStr) => {
  if (!storeStr) {
    return { store_name: 'N/A', store_city: 'N/A', store_state: 'N/A' };
  }
  const storeName = storeStr.slice(0, storeStr.indexOf('-') - 1);
  const storeNameAndAddr = storeStr.split(/-/);
  const storeAddr = storeNameAndAddr[storeNameAndAddr.length - 1].split(',');
  const storeState = storeAddr[storeAddr.length - 1];
  const storeCity = storeAddr[storeAddr.length - 2];
  const storeInfo = {
    store_name: storeName,
    store_city: storeCity,
    store_state: storeState,
  };
  const modifiedStoreInfo = removeNullStores(storeInfo);
  return modifiedStoreInfo;
};

const modifyPrizesInfo = (prizeArr) => {
  if (prizeArr !== null) {
    return prizeArr.map((prizes) => prizes.prizeName).join('; ');
  }
  return 'No prizes redeemed.';
};

/**
 *
 * @param {Object} headerObj
 * @return {Object[]} Correct MDBHeader to be used for MDBTable
 */
const buildTableCol = (headerObj) => {
  return Object.keys(headerObj).map((key) => {
    const mdbColObj = { field: key, label: headerObj[key], sort: 'asc', width: 300 };
    return mdbColObj;
  });
};

/**
 *
 * @param {Object[]} usersData
 * @return {Object[]} modified object to be used MDBTable
 */
const buildTableRows = (usersData) => {
  const redeemInfo = 'redeemed_info';
  return usersData
    .map((userData) => {
      const user = userData;
      const storeInfo = modifyStoreInfo(user.retail_store);
      const selectedPrizes = modifyPrizesInfo(user['redeemed_info.selected_prizes']);
      let redeemDate = { redeem_date: user[`${redeemInfo}.createdAt`] };

      user.taken = countQuizzesTaken(user.quizzes);
      if (user.spent_points === null) {
        user.spent_points = 0;
      }
      if (user.points === null) {
        user.points = 0;
      }
      if (user[`${redeemInfo}.createdAt`] === null) {
        redeemDate = { redeem_date: 'No prize redeemed.' };
      }
      Object.assign(user, storeInfo, { selected_prizes: selectedPrizes }, redeemDate);

      delete user['redeemed_info.selected_prizes'];
      delete user.retailer;
      delete user.quizzes;
      delete user.retail_store;
      delete user[`${redeemInfo}._id`];
      delete user[`${redeemInfo}.user_id`];
      delete user[`${redeemInfo}.spent_points`];
      delete user[`${redeemInfo}.current_points`];
      delete user[`${redeemInfo}.createdAt`];
      delete user[`${redeemInfo}.updatedAt`];

      return user;
    })
    .sort((a, b) => a._id - b._id);
};

/**
 * convert info to useable MDB data
 * @param {Object} retailerUsersObj
 * @return {Object}
 */
const converToMDBTable = (retailerUsersObj) => {
  const retailHeaders = {
    _id: 'User Id',
    email: 'Email',
    first_name: 'First',
    last_name: 'Last',
    points: 'Current Points',
    spent_points: 'Points Spent',
    taken: 'Quizzes taken',
    store_name: 'Store Name',
    store_city: 'Store City',
    store_state: 'Store State',
    selected_prizes: 'Selected Prizes',
    redeem_date: 'Redeem Date',
  };
  const colData = buildTableCol(retailHeaders);
  const rowData = buildTableRows(retailerUsersObj);

  return { columns: colData, rows: rowData };
  /* Return the obj that is converted into Retailer DB Table */
};

module.exports = { converToMDBTable };
