import React from 'react';
import { MDBRow, MDBCol, MDBListGroup, MDBListGroupItem } from 'mdbreact';

import ListGroup from './ListGroup.jsx';

const { mapCorrectDataNutrient, chunkArr } = require('./helper.jsx');

export default function Nutrients(props) {
  const asFed = mapCorrectDataNutrient(props.asFed);
  const dryMatter = mapCorrectDataNutrient(props.dryMatter);
  const calories = mapCorrectDataNutrient(props.calories);
  const chunkedAsFed = chunkArr(asFed, 4);
  const chunkedDryMatter = chunkArr(dryMatter, 4);
  const chunkedCalories = chunkArr(calories, 1);

  return (
    <div className="product-main__nutrient-wrapper">
      <MDBRow>
        <MDBCol>
          <MDBRow className="mb-4">
            <MDBCol>
              <h2 className="product-main__header2">As Fed Percentage</h2>
              <MDBRow className="detailed-nutrient-divider mb-4"></MDBRow>
              <MDBRow>
                {chunkedAsFed.map((arr, index) => (
                  <MDBCol key={index} size="12" sm="6" lg="4">
                    <MDBListGroup>
                      {arr.map((info, i) => (
                        <ListGroup key={i} i={i} name={info.name} data={info.data} />
                      ))}
                    </MDBListGroup>
                  </MDBCol>
                ))}
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow className="my-4">
            <MDBCol>
              <h2 className="product-main__header2">Dry Matter Analysis</h2>
              <MDBRow className="detailed-nutrient-divider mb-4"></MDBRow>
              <MDBRow>
                {chunkedDryMatter.map((arr, index) => (
                  <MDBCol key={index} size="12" sm="6" lg="4">
                    <MDBListGroup>
                      {arr.map((info, i) => (
                        <ListGroup key={i} i={i} name={info.name} data={info.data} />
                      ))}
                    </MDBListGroup>
                  </MDBCol>
                ))}
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow className="my-4">
            <MDBCol>
              <h2 className="product-main__header2">Calories from Protein, Fat and Carbohydrates</h2>
              <MDBRow className="detailed-nutrient-divider mb-4"></MDBRow>
              <MDBRow>
                {chunkedCalories.map((arr, index) => (
                  <MDBCol key={index} size="12" sm="6" lg="4">
                    <MDBListGroup>
                      {arr.map((info, i) => (
                        <ListGroup key={i} i={i} name={info.name} data={info.data} />
                      ))}
                    </MDBListGroup>
                  </MDBCol>
                ))}
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </div>
  );
}
