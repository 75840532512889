// product to build
// [{text: name, value: id}]

/**
 * @param {Object} product
 * @return {Object[]} to render MDBInputSelect
 */
const modifyValuesForSelect = (product) => {
  const tempObj = {};
  Object.keys(product).forEach((key) => {
    tempObj.text = product.name + ' - Id: ' + product.product_id;
    tempObj.value = '' + product.product_id;
  });
  return tempObj;
};

/**
 * @param {Object} context
 * @param {Object} products
 * set State for product based on
 */
const categorizedProducts = (context, products) => {
  const tempObj = { dogs: [], cats: [] };
  const { dogs, cats } = tempObj;
  products.forEach((product) => {
    const { category } = product;
    if (category === 'cat') {
      const options = modifyValuesForSelect(product);
      cats.push(options);
      cats.sort((a, b) => a.value - b.value);
    } else {
      const options = modifyValuesForSelect(product);
      dogs.push(options);
      dogs.sort((a, b) => a.value - b.value);
    }
  });

  context.setState({
    products: tempObj,
    setProduct: true,
  });
};

/**
 * convert array into usable arr for reactTags
 * @param {Object[]} arr
 * @return {Object[]}
 */
const convertToReactTagsObj = (arr) => {
  return arr.map((text, i) => {
    const tempObj = { id: String(i), text };
    return tempObj;
  });
};

const convertDbObjReactTag = (arr) => {
  return arr.map((item) => item.text);
};

const handleDrag = (tagInfo, currPos, newPos, description) => {
  const newDescription = convertToReactTagsObj(description.slice());
  newDescription.splice(currPos, 1);
  newDescription.splice(newPos, 0, tagInfo);
  return convertDbObjReactTag(newDescription);
};

const convertToArrTup = (obj) => {
  return Object.keys(obj).map((keys) => {
    return { name: keys, value: obj[keys] };
  });
};

const convertToArrForFeeding = (obj) => {
  return Object.keys(obj).map((sizes) => {
    const tempObj = { guideline: [] };
    tempObj.size = { name: 'size', value: sizes };
    Object.keys(obj[sizes]).forEach((innerKeys) => {
      const guidelineObj = {};
      guidelineObj.name = innerKeys;
      guidelineObj.value = obj[sizes][innerKeys];
      tempObj.guideline.push(guidelineObj);
    });
    return tempObj;
  });
};

const convertToDbObj = (arr) => {
  return arr.reduce((key, values) => {
    const { name, value } = values;
    key[name] = value;
    return key;
  }, {});
};

/**
 * return object to setState for productimages
 * @param {Object} imgObj
 * @return {Object[]}
 */

const updateImgObjToArr = (imgObj) => {
  return Object.keys(imgObj).reduce((newImg, imgSize) => {
    const viewObj = imgObj[imgSize];
    if (viewObj !== null) {
      const images = Object.keys(viewObj).map((imgView) => {
        return { viewType: imgView, imgSrc: viewObj[imgView] };
      });
      newImg.push({ size: imgSize, images });
    } else {
      newImg.push({ size: imgSize, images: [] });
    }
    return newImg;
  }, []);
};

/**
 *
 * @param {Object} options { selectedSize1, selectedView, newImgs }
 * @return {Object} to be send over to DB for udpate
 */

/*
  {size: small, viewType: front, newImages: {main, zoom, thumb}}
*/
const convertUpdatedImgForDB = (options) => {
  const temp = {};
  const imgResolutions = ['zoom', 'main', 'thumb'];
  const noContent = undefined;
  const { selectedSize, selectedView, files } = noContent || options;
  const newImages = files.reduce((newObj, imgSrc, i) => {
    newObj[imgResolutions[i]] = imgSrc.s3Link;
    return newObj;
  }, {});
  Object.assign(temp, { size: selectedSize, view: selectedView, newImages });

  return temp;
};

module.exports = {
  categorizedProducts,
  convertToReactTagsObj,
  handleDrag,
  convertToArrTup,
  convertToArrForFeeding,
  convertToDbObj,
  updateImgObjToArr,
  convertUpdatedImgForDB,
};
