const loadImage = require('blueimp-load-image');
const swal = require('sweetalert2');
const { success, error, warning } = require('../../../../_helper/SweetAlerts/alerts.jsx');

module.exports = {
  /**
   * invoke alerts depending on status
   * @param {Object} options
   * @return
   */
  handleStoreStatus: (options) => {
    const nonContent = undefined;
    const { title, text, status } = nonContent || options;
    if (status === 200) {
      error({
        title,
        text,
        button: 'Okay',
      });
    } else if (status === 201) {
      success({
        title,
        text,
        button: 'Okay',
      });
    }
  },

  /**
   * @param {String} imageFile
   * @return {String} Updated buffer w/ correct rotation
   */
  correctImgRotation: async (imageFile) => {
    // console.log(reader.readAsDataURL(imageFile));
    return new Promise((resolve, reject) => {
      try {
        loadImage(
          imageFile,
          (canvas) => {
            const updatedImg = canvas.toDataURL();
            resolve(updatedImg);
          },
          { orientation: true, maxWidth: 700 },
        );
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  },

  /**
   * @param {String} storeName
   * @return {Boolean}
   */
  confirmStoreDelete: (storeName) => {
    return new Promise((resolve, reject) => {
      swal
        .fire({
          title: `Deleting store: ${storeName}`,
          text: 'Are you sure you want to delete this store/image?',
          confirmButtonColor: '#ff3547',
          // cancelButtonColor: '#ff354700',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Delete',
        })
        .then((result) => {
          if (result.value) return resolve(true);
          resolve(false);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  /**
   * @param {Object} fields
   * @return {Boolean}
   */
  validateFields: (fields) => {
    const { website_url, ...requiredFields } = fields;
    return Object.keys(requiredFields).every((key) => requiredFields[key] !== '');
  },

  /**
   * built [{value: fromDb, text: store info}]
   * @param {Object} store
   * @return {Object} New input object
   */

  convertStoreInfoForInput: (store) => {
    const textObj = {
      _id: 'Store ID',
      name: 'Store name',
      phone: 'Store Phone',
      website_url: 'Website',
    };

    const { _id, lat, lng, picture_url, ...updatedStoreInfo } = store;

    return Object.keys(updatedStoreInfo).map((key) => {
      const temp = {};
      if (textObj.hasOwnProperty(key)) {
        Object.assign(temp, { value: key, text: textObj[key] });
      } else {
        Object.assign(temp, { value: key, text: key });
      }
      return temp;
    });
  },
};
