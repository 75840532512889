import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact';
import Axios from 'axios';

import IsLoadingChild from '../IsLoading/IsLoadingChild.jsx';

const {
  endPoint: { retailStores, filesUploads },
} = require('../_helper/stringVars');
const { correctImgOrientation } = require('./helper');

export default class AllStoreImages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stores: [],
      doneLoading: false,
    };
  }

  componentDidMount() {
    this.getImages();
  }

  modifyStoreImages = async (storesInfo) => {
    try {
      return await Promise.all(
        storesInfo.map(async (store) => {
          // if (store !== null) {
          if (store.picture_url !== null && store.picture_url.includes('data')) {
            const correctedImgBlob = await correctImgOrientation(store.picture_url);
            store.picture_url = correctedImgBlob;
          }

          return store;
          // }
        }),
      );
    } catch (err) {
      console.error(err);
    }
  };

  getImages = async () => {
    const stores = [55, 627, 670, 774, 1018];

    try {
      const storesInfo = await Axios.get(`${retailStores.client}/get-stores`, {
        params: { stores },
      });
      // console.log({ storesInfo });
      // const modifiedStoresInfo = await this.modifyStoreImages(storesInfo.data);
      this.setState({
        stores: storesInfo.data,
        doneLoading: true,
      });
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    if (!this.state.doneLoading) return <IsLoadingChild />;
    return (
      <MDBRow>
        <MDBCol>
          <h1 className="header1 text-uppercase futura-font font-weight-light">
            Stores Images test
          </h1>
          <MDBAnimation type="fadeIn" delay="1s">
            <MDBRow>
              {this.state.stores.map((store, key) => {
                return (
                  <MDBCol size="4" key={key}>
                    <h2 className="header2 futura-font">{store.name}</h2>
                    <span>{store.address}</span>
                    <img className="img-fluid" src={store.picture_url} />
                  </MDBCol>
                );
              })}
            </MDBRow>
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
    );
  }
}
