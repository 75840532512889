import React from 'react';
import { MDBCol, MDBInput } from 'mdbreact';

export default function SimpleInputField(props) {
  const { name, label, value, onChange, id } = props;
  return (
    <MDBCol sm="12" className="simple-input-field">
      <MDBInput
        name={name}
        id={id}
        label={label}
        type="text"
        value={value}
        onChange={onChange}
        disabled={props.disabled}>
        <div className="invalid-feedback ml-1">Please provide a valid {label}</div>
        <div className="valid-feedback ml-1">Looks good!</div>
      </MDBInput>
    </MDBCol>
  );
}