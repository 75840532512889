import React, { Component } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBCardImage,
} from 'mdbreact';
import Axios from 'axios';
import swal from 'sweetalert2';

import ProductImgEdit from './ProductImgEdit.jsx';

const { setToken, uploadImgToS3, getUploadImg } = require('../../../../../_helper/index.jsx');
const {
  endPoint: { productsEndPoint, filesUploads },
} = require('../../../../../_helper/stringVars');
const { handleErrors } = require('../../../../../_helper/reqsHandlers');
const { updateImgObjToArr, convertUpdatedImgForDB } = require('../helper.jsx');
const { success } = require('../../../../../_helper/SweetAlerts/alerts.jsx');

/**
 * state sizes are referring to the actual size of product
 * ie small = 2.5oz
 * medium = anything > 5.0oz || 6.0oz
 * large = anything > 12oz
 */
export default class ProductImages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      newImages: {},
      product_id: 0,
      imagesModalOpen: false,
      selectedImg: {},
      selectedSize: '',
      selectedView: '',
      fileInfo: {},
      uploadedImgDataUrl: '',
      uploadedImgLoaded: false,
      imgLoader: false,
    };
    this.token = setToken('admin');
  }

  componentDidMount() {
    this.getProductImages();
  }

  componentDidUpdate(prevProps) {
    if (this.props.product_id !== prevProps.product_id) {
      this.getProductImages();
    }
  }

  getProductImages = async () => {
    try {
      const { product_id } = this.props;

      const { data, status } = await Axios.get(`${productsEndPoint.admin}/get-product-images`, {
        headers: this.token,
        params: { product_id },
      });
      if (status === 400) return handleErrors(400);
      if (status === 400) return handleErrors(403);
      if (status === 404) return handleErrors(404);

      const [images] = data.products_additions;

      this.setState({
        images,
        product_id,
      });
    } catch (err) {
      console.error(err);
      handleErrors(500);
    }
  };

  updateImages = async (e) => {
    e.preventDefault();

    swal.fire({
      title: 'Updating...',
      text: 'Please wait while image is being upadeted.',
      onBeforeOpen: () => {
        swal.showLoading();
      },
    });

    // Upload Image here.
    try {
      const { fileInfo, uploadedImgLoaded, selectedSize, selectedView, product_id } = this.state;
      if (!uploadedImgLoaded) {
        return swal.fire({
          icon: 'error',
          title: 'No image file!',
          text: 'Please upload image file.',
          confirmButtonText: 'Okay',
        });
      }
      const { files, accepted } = await uploadImgToS3(fileInfo, 'product');
      if (!accepted) return handleErrors(400);

      const newImages = await convertUpdatedImgForDB({ selectedSize, selectedView, files });

      const { data, status } = await Axios.put(
        `${productsEndPoint.admin}/update-product-img`,
        { params: { product_id, newImages } },
        { headers: this.token },
      );

      if (status === 400) return handleErrors(400);
      if (status === 404) return handleErrors(404);
      success({ title: 'Image updated', text: 'New image uploaded', button: 'Okay' });
    } catch (err) {
      console.error(err);
      const { status } = err.response;
      if (status === 400) return handleErrors(400);
      handleErrors(500);
    }
  };

  openProductImgModal = (e) => {
    // e.preventDefault();
    const { images, imagesModalOpen } = this.state;
    if (!imagesModalOpen) {
      const size = e.currentTarget.attributes['data-size'].value;
      const viewType = e.currentTarget.attributes['data-view'].value;
      const selectedImg = images[size][viewType];

      this.setState({
        selectedImg,
        selectedView: viewType,
        selectedSize: size,
        uploadedImgDataUrl: '',
        uploadedImgLoaded: false,
        imgLoader: false,
      });
    }
    this.setState({
      imagesModalOpen: !this.state.imagesModalOpen,
    });
    this.getProductImages();
  };

  render() {
    const { state } = this;
    const updatedImagesArr = updateImgObjToArr(state.images);

    return (
      <MDBContainer className="admin-main__product-images mt-5 px-0">
        <MDBRow>
          <MDBCol>
            <h2 className="admin-main__header2">Product Images</h2>
          </MDBCol>
        </MDBRow>

        {updatedImagesArr.map((imgInfo, i) => (
          <MDBRow key={i} className="admin-main--border-divider mx-1">
            <MDBCol size="12" className="mt-5 mb-3">
              <span className="admin-main__span futura-font text-capitalize">
                Item size: {imgInfo.size}
              </span>
            </MDBCol>
            {imgInfo.images.map((img, j) => (
              <MDBCol key={j} size="6" md="3" className="mt-1 mb-4">
                <MDBCard className="text-center">
                  <MDBCardImage
                    className="img-fluid admin-product__img"
                    src={img.imgSrc.thumb}
                    waves
                    cascade
                  />
                  <MDBCardBody>
                    <MDBCardTitle tag="p" className="text-capitalize text-center futura-font">
                      {img.viewType}
                    </MDBCardTitle>
                    <MDBBtn
                      onClick={this.openProductImgModal}
                      size="sm"
                      color="primary"
                      floating
                      data-size={imgInfo.size}
                      data-view={img.viewType}>
                      <MDBIcon far icon="edit" />
                    </MDBBtn>
                    <MDBBtn size="sm" color="danger" floating disabled>
                      <MDBIcon icon="ban" />
                    </MDBBtn>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            ))}
          </MDBRow>
        ))}

        <MDBModal
          animation="bottom"
          centered
          className="modal-notify modal-primary text-white"
          size="lg"
          isOpen={state.imagesModalOpen}
          toggle={this.openProductImgModal}>
          <MDBModalHeader tag="p" toggle={this.openProductImgModal}>
            Product Image Update:
          </MDBModalHeader>
          <MDBModalBody>
            <ProductImgEdit
              updateImages={this.updateImages}
              selectedSize={state.selectedSize}
              selectedView={state.selectedView}
              imgLoader={state.imgLoader}
              uploadedImgDataUrl={state.uploadedImgDataUrl}
              uploadedImgLoaded={state.uploadedImgLoaded}
              selectedImg={state.selectedImg}
              getUploadImg={(value) => getUploadImg(this, value)}
            />
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    );
  }
}
