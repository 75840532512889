import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const newsletters = [
  {
    month: `May 2019`,
    link: `/newsletter/may19`,
    description: `Learn more about our micro cannery with Lotus Pet Foods' very own, Alyssa!`,
  },
  {
    month: `April 2019`,
    link: `/newsletter/apr19`,
    description: `Discover more information about Lotus Pet Foods' cannery located in Torrance, CA!`,
  },
  {
    month: `March 2019`,
    link: `/newsletter/mar19`,
    description: `Check out why Lotus Pet Foods' oven-baked cat kibble are the best choice for your cats!`,
  },
  {
    month: `February 2019`,
    link: `/newsletter/feb19`,
    description: `Lotus Pet Foods introduces our line of soft baked dog treats!`,
  },
  {
    month: `January 2019`,
    link: `/newsletter/jan19`,
    description: `Lotus Pet Foods is launching our brand new website for the new year!`,
  },
];

export default function Newsletter(props) {
  return (
    <div>
      <div className="container contact-us-body">
        <div className="row">
          <div className="col-6 contact-us-text">NEWSLETTER</div>
          <div className="col-6 contact-us-text">BLOG</div>
        </div>
        <div className="row page-divider mb-5" />
        <div className="row">
          <div className="col-6">
            {newsletters.map((item, i) => {
              return (
                <div key={i} className="mb-4">
                  <div style={{ fontFamily: 'Futura-Bold', fontSize: '24px' }}>{item.month}</div>
                  <div>{item.description}</div>
                  <Link to={item.link} href={item.link}>
                    Read More
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="col-6">No blogs yet!</div>
        </div>
      </div>
    </div>
  );
}
