import { capitalizedFirstLetters } from '../../../_helper/index.jsx';

const analysisName = {
  ascorbicAcid: 'Ascorbic Acid',
  ash: 'Ash (Max)',
  calories: 'Calorie Content........',
  fat: 'Cude Fat (Min)',
  fiber: 'Crude Fiber (Max)',
  moisture: 'Moisture (Max)',
  omega3: 'Omega 3 Fatty Acids* (Min)',
  omega6: 'Omega 6 Fatty Acids* (Min)',
  protein: 'Crude Protein (Min)',
  vitaminE: 'Vitamin E (Min)',
};

const createDiffTuple = (obj1, key, string, obj2) => {
  if (obj2 !== undefined) {
    return { name: obj1[key], data: obj2[key] + ' ' + string };
  }
  return { name: capitalizedFirstLetters(key), data: obj1[key] + ' ' + string };
};

const mapCorrectDataFormatAnalysis = (obj1) => {
  return Object.keys(obj1).map((key) => {
    const tuple = { name: analysisName[key], data: obj1[key] + ' %' };
    if (key === 'calories') {
      const temp = { name: analysisName[key], data: obj1[key].kg + ' kcal/kg' };
      Object.assign(tuple, temp);
    }
    if (key === 'vitaminE') {
      const temp = createDiffTuple(analysisName, key, 'IU/kg', obj1);
      Object.assign(tuple, temp);
    }
    if (key === 'ascorbicAcid') {
      const temp = createDiffTuple(analysisName, key, 'mg/kg', obj1);
      Object.assign(tuple, temp);
    }
    if (obj1[key] === null || obj1[key] === undefined || obj1[key] === '') {
      const temp = { name: key, data: obj1[key] };
      Object.assign(tuple, temp);
    }
    return tuple;
  });
};

const mapCorrectDataNutrient = (obj) => {
  return Object.keys(obj).map((key) => {
    const capitalizeName = capitalizedFirstLetters(key);
    const tuple = { name: capitalizeName, data: obj[key] + ' %' };
    if (key.includes('vitamin')) {
      Object.assign(tuple, createDiffTuple(obj, key, 'IU/kg'));
    }
    if (
      key === 'vitaminC' ||
      key === 'iron' ||
      key === 'copper' ||
      key === 'zinc' ||
      key === 'iodine'
    ) {
      Object.assign(tuple, createDiffTuple(obj, key, 'mg/kg'));
    }
    if (key === 'kcal') {
      Object.assign(tuple, createDiffTuple(obj, key, 'kcal/kg'));
    }
    if (obj[key] === '') {
      Object.assign(tuple, createDiffTuple(obj, key, undefined));
    }
    return tuple;
  });
};

const chunkArr = (array, size) => {
  const chunkedArr = [];
  let index = 0;
  while (index < array.length) {
    chunkedArr.push(array.slice(index, size + index));
    index += size;
  }
  return chunkedArr;
};

module.exports = {
  mapCorrectDataFormatAnalysis,
  mapCorrectDataNutrient,
  chunkArr,
};
