import React from 'react';
import $ from 'jquery';
import Lightbox from 'react-image-lightbox';
import StarRatingComponent from 'react-star-rating-component';
import { Link } from 'react-router-dom';
import swal from 'sweetalert2';
import axios from 'axios';

const related = [
  {
    link: 'beef',
    name: 'Beef Loaf',
    img: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Beef/12oz0-main.jpg'
  },
  {
    link: 'chicken',
    name: 'Chicken Loaf',
    img: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Chicken/12oz0-main.jpg'
  },
  {
    link: 'turkey',
    name: 'Turkey Loaf',
    img: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Turkey/12oz0-main.jpg'
  },
  {
    link: 'duck',
    name: 'Duck Loaf',
    img: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Duck/12oz0-main.jpg'
  },
  {
    link: 'pork',
    name: 'Pork Loaf',
    img: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Pork/12oz0-main.jpg'
  },
  {
    link: 'rabbit',
    name: 'Rabbit Loaf',
    img: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Rabbit/12oz0-main.jpg'
  },
  {
    link: 'salmon',
    name: 'Salmon Loaf',
    img: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Salmon/12oz0-main.jpg'
  },
  {
    link: 'sardine',
    name: 'Sardine Loaf',
    img: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Sardine/12oz0-main.jpg'
  }
];

const products = related.slice(0, 3).map(function() {
  return this.splice(Math.floor(Math.random() * this.length), 1)[0];
}, related.slice());

const five = [
  {
    main: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/5oz0-main.jpg',
    jpg: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/5oz0.jpg',
    png: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/5oz0.png'
  },
  {
    main: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/5oz1-main.jpg',
    jpg: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/5oz1.jpg',
    png: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/5oz1.png'
  },
  {
    main: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/5oz2-main.jpg',
    jpg: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/5oz2.jpg',
    png: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/5oz2.png'
  }
];

const twelve = [
  {
    main: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/12oz0-main.jpg',
    jpg: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/12oz0.jpg',
    png: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/12oz0.png'
  },
  {
    main: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/12oz1-main.jpg',
    jpg: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/12oz1.jpg',
    png: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/12oz1.png'
  },
  {
    main: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/12oz2-main.jpg',
    jpg: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/12oz2.jpg',
    png: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/Tripe/12oz2.png'
  }
];

class DogTripeLoaf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      asFed: {},
      ingredients: [],
      feedingGuidelines: { '5': {}, '12': {} },
      guaranteedAnalysis: { calories: {} },
      dryMatter: {},
      calories: {},
      description: [],
      id: 34,
      currentMainImage: twelve[0].main,
      currentFoodSize: twelve,
      imageView: 0,
      photoIndex: 0,
      showLightbox: false,
      totalReviews: [],
      nestedReviews: [],
      reviewIndex: 0,
      renderedReviews: [],
      reviewFirst: 1,
      reviewLast: 3,
      stars: 0,
      starHistogram: { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 },
      isDesktop: false
    };
    this.currentMainImage = this.currentMainImage.bind(this);
    this.changeView = this.changeView.bind(this);
    this.selectFoodSize = this.selectFoodSize.bind(this);
    this.showLightbox = this.showLightbox.bind(this);
    this.convertDate = this.convertDate.bind(this);
    this.reviewsByStar = this.reviewsByStar.bind(this);
    this.allReviews = this.allReviews.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentWillMount() {
    axios.post('/getreviews', { id: this.state.id }).then(res => {
      if (res.data.length > 0) {
        let reviews = res.data;
        let stars;
        let total = 0;
        const starHistogram = {
          5: 0,
          4: 0,
          3: 0,
          2: 0,
          1: 0
        };

        for (let i = 0; i < reviews.length; i++) {
          total += reviews[i].stars;
          starHistogram[reviews[i].stars]++;
        }

        stars = Math.round((total / reviews.length) * 2) / 2;

        for (let star in starHistogram) {
          starHistogram[star] = Math.round((starHistogram[star] / reviews.length) * 100);
        }

        const allReviews = reviews;
        const nestedReviews = [];
        let nested = [];

        for (let i = 0; i < allReviews.length; i++) {
          nested.push(allReviews[i]);
          if (nested.length === 3 || i === allReviews.length - 1) {
            nestedReviews.push(nested);
            nested = [];
          }
        }

        this.setState({
          stars: stars,
          starHistogram: starHistogram,
          nestedReviews: nestedReviews,
          renderedReviews: nestedReviews[0],
          totalReviews: reviews
        });
      } else {
        this.setState({
          stars: 0,
          starHistogram: {
            5: 0,
            4: 0,
            3: 0,
            2: 0,
            1: 0
          },
          nestedReviews: [],
          renderedReviews: [],
          totalReviews: []
        });
      }
    });

    axios.post('/getproductinfo', { id: this.state.id }).then(res => {
      const asFed = JSON.parse(res.data.rows[0].as_fed);
      const calories = JSON.parse(res.data.rows[0].calories);
      const description = JSON.parse(res.data.rows[0].description);
      const dryMatter = JSON.parse(res.data.rows[0].dry_matter);
      const feedingGuidelines = JSON.parse(res.data.rows[0].feeding_guidelines);
      const guaranteedAnalysis = JSON.parse(res.data.rows[0].guaranteed_analysis);
      const ingredients = JSON.parse(res.data.rows[0].ingredients);

      this.setState({
        asFed: asFed,
        ingredients: ingredients,
        feedingGuidelines: feedingGuidelines,
        guaranteedAnalysis: guaranteedAnalysis,
        dryMatter: dryMatter,
        calories: calories,
        description: description
      });
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.updatePredicate();
    window.addEventListener('resize', this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth < 1200 });
  }

  currentMainImage(e) {
    this.setState({
      photoIndex: parseInt(e.target.id),
      currentMainImage: e.target.name,
      show3D: false
    });
    $('img').removeClass('selected-dog-tripe-loaf-thumbnail');
    $(`img[id=${e.target.id}]`).addClass('selected-dog-tripe-loaf-thumbnail');
  }

  changeView(e) {
    this.setState({
      imageView: e.target.value
    });
  }

  selectFoodSize(e) {
    this.setState({
      show3D: false
    });

    if (e.target.id === 'five') {
      this.setState({
        currentFoodSize: five,
        currentMainImage: five[0].main
      });
    } else if (e.target.id === 'twelve') {
      this.setState({
        currentFoodSize: twelve,
        currentMainImage: twelve[0].main
      });
    }

    $('img').removeClass('selected-dog-tripe-loaf-thumbnail');
    $('img[id=0]').addClass('selected-dog-tripe-loaf-thumbnail');
    $('.dog-tripe-loaf-size-color').removeClass('selected-dog-tripe-loaf-size');
    $(`#${e.target.id}`).addClass('selected-dog-tripe-loaf-size');
  }

  showLightbox(e) {
    this.setState({
      photoIndex: parseInt(e.target.id),
      showLightbox: true
    });
  }

  changeView(e) {
    this.setState({
      imageView: e.target.value
    });
  }

  convertDate(date) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let month = months[date.substring(0, 2) - 1];
    let day = date.substring(3, 5);
    let year = date.slice(-4);

    if (day[0] === '0') {
      day = day[1];
    } else if (day[1] === '/') {
      day = day[0];
    }

    return `${month} ${day}, ${year}`;
  }

  reviewsByStar(e) {
    const star = e.currentTarget.getAttribute('value');
    const reviews = [];
    const nestedReviews = [];
    let nested = [];

    for (let i = 0; i < this.state.totalReviews.length; i++) {
      if (this.state.totalReviews[i].stars == star) {
        reviews.push(this.state.totalReviews[i]);
      }
    }

    for (let i = 0; i < reviews.length; i++) {
      nested.push(reviews[i]);
      if (nested.length === 3 || i === reviews.length - 1) {
        nestedReviews.push(nested);
        nested = [];
      }
    }

    this.setState({
      nestedReviews: nestedReviews,
      renderedReviews: nestedReviews[0],
      reviewFirst: 1,
      reviewLast: nestedReviews[0].length,
      reviewIndex: 0
    });
  }

  allReviews() {
    const allReviews = reviews;
    const nestedReviews = [];
    let nested = [];

    for (let i = 0; i < allReviews.length; i++) {
      nested.push(allReviews[i]);
      if (nested.length === 3 || i === allReviews.length - 1) {
        nestedReviews.push(nested);
        nested = [];
      }
    }
    this.setState({
      nestedReviews: nestedReviews,
      renderedReviews: nestedReviews[0],
      reviewFirst: 1,
      reviewLast: nestedReviews[0].length,
      reviewIndex: 0
    });
  }

  nextPage() {
    const newIndex = (this.state.reviewIndex += 1);
    const newReviews = this.state.nestedReviews[newIndex];
    const total = this.state.nestedReviews.flat().length;
    let newFirst = (this.state.reviewFirst += 3);
    let newLast = (this.state.reviewLast += 3);

    if (newLast > total) {
      newLast = total;
    }

    this.setState({
      reviewIndex: newIndex,
      renderedReviews: newReviews,
      reviewFirst: newFirst,
      reviewLast: newLast
    });
  }

  previousPage() {
    const newIndex = (this.state.reviewIndex -= 1);
    const newReviews = this.state.nestedReviews[newIndex];
    let newFirst = (this.state.reviewFirst -= this.state.nestedReviews[this.state.reviewIndex].length);
    let newLast;

    if (newFirst < 0) {
      newFirst = 1;
      newLast = 3;
    } else if (this.state.reviewFirst === this.state.nestedReviews.flat().length) {
      newLast = this.state.nestedReviews[this.state.reviewIndex + 1].length;
    } else {
      newLast = this.state.reviewLast -= this.state.nestedReviews[this.state.reviewIndex + 1].length;
    }

    this.setState({
      reviewIndex: newIndex,
      renderedReviews: newReviews,
      reviewFirst: newFirst,
      reviewLast: newLast
    });
  }

  render() {
    return (
      <div>
        {/* Lightbox Toggler */}
        {this.state.showLightbox && (
          <Lightbox
            mainSrc={this.state.currentFoodSize[this.state.photoIndex].jpg}
            nextSrc={this.state.currentFoodSize[(this.state.photoIndex + 1) % this.state.currentFoodSize.length].jpg}
            prevSrc={this.state.currentFoodSize[(this.state.photoIndex + this.state.currentFoodSize.length - 1) % this.state.currentFoodSize.length].jpg}
            onCloseRequest={() => this.setState({ showLightbox: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + this.state.currentFoodSize.length - 1) % this.state.currentFoodSize.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + 1) % this.state.currentFoodSize.length
              })
            }
          />
        )}
        <div className='product-body'>
          {/* Product Overview Row */}
          <div className='row'>
            {/* Thumbnail Column */}
            <div className='col-lg-1 col-2'>
              {this.state.currentFoodSize.map((picture, i) => {
                return (
                  <div key={i} className='product-thumbnail-container mb-2'>
                    <img
                      id={i}
                      className={i === 0 ? 'selected-dog-tripe-loaf-thumbnail product-thumbnail' : 'product-thumbnail'}
                      name={picture.main}
                      onMouseEnter={this.currentMainImage}
                      onClick={window.innerWidth <= 768 ? this.currentMainImage : this.showLightbox}
                      src={picture.png}
                    />
                  </div>
                );
              })}
            </div>
            {/* Main Image Column */}
            <div className='col-lg-5 col-10 d-flex align-items-center justify-content-center'>
              {this.state.currentFoodSize === five && (
                <img
                  className='fivemaindogcan'
                  src={this.state.currentMainImage}
                  id={$('.selected-dog-tripe-loaf-thumbnail').attr('id')}
                  onClick={window.innerWidth <= 768 ? this.showLightbox : null}
                />
              )}
              {this.state.currentFoodSize === twelve && (
                <img
                  className='twelvemaindogcan'
                  src={this.state.currentMainImage}
                  id={$('.selected-dog-tripe-loaf-thumbnail').attr('id')}
                  onClick={window.innerWidth <= 768 ? this.showLightbox : null}
                />
              )}
            </div>
            {/* Description Column */}
            <div className='col-lg-6 col-12 description-col'>
              <div className='col-12'>
                <img className='w-100' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/beef-green-tripe-title.png'} />
              </div>
              <hr className='dog-tripe-loaf-line' />
              <div style={{ fontSize: 32 }} className='stars d-flex align-items-center'>
                <StarRatingComponent
                  name='product'
                  starCount={5}
                  value={this.state.stars}
                  editing={false}
                  renderStarIcon={(index, value) => {
                    return (
                      <span>
                        <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                      </span>
                    );
                  }}
                  renderStarIconHalf={() => {
                    return (
                      <span>
                        <span style={{ position: 'absolute' }}>
                          <i className='far fa-star' />
                        </span>
                        <span>
                          <i className='fas fa-star-half' />
                        </span>
                      </span>
                    );
                  }}
                />
                <div className='rating-count'>{this.state.totalReviews.length}</div>
              </div>
              <div className='product-description'>
                <ul className='product-bullets'>
                  {this.state.description.map(description => {
                    return <li>{description}</li>;
                  })}
                </ul>
              </div>
              <Link to='/store-locator' href='/store-locator'>
                <button type='submit' className='buy-button'>
                  Where to Buy
                </button>
              </Link>
              <div className='available-sizes mt-2'>AVAILABLE SIZES</div>
              <div className='row justify-content-start'>
                <div className='col-auto size-options text-center pr-0'>
                  <div className='dog-tripe-loaf-size-color' onMouseEnter={this.selectFoodSize} onClick={this.selectFoodSize} id='five'>
                    5.3 oz
                  </div>
                </div>
                <div className='col-auto size-options text-center px-2'>
                  <div className='dog-tripe-loaf-size-color selected-dog-tripe-loaf-size' onMouseEnter={this.selectFoodSize} onClick={this.selectFoodSize} id='twelve'>
                    12.5 oz
                  </div>
                </div>
              </div>
              <div className='row pr-5 mt-4'>
                <div className='col-sm-6 col-12'>
                  <span
                    data-tooltip='This recipe does not contain carrageenan, which is a red algae that used as a thickener and can be processed chemically. We use agar agar instead as thickener. Agar agar is also a red algae but is processed using hot water.'
                    data-tooltip-position='bottom'
                  >
                    <img className='product-icon' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/no-carra.png'} />
                  </span>
                </div>
                <div className='col-sm-6 col-12'>
                  <span data-tooltip='A recipe made without grains.' data-tooltip-position='bottom'>
                    <img className='product-icon' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/grain-free-icon.png'} />
                  </span>
                </div>
              </div>
              <div className='row pr-5 mt-1'>
                <div className='col-sm-6 col-12'>
                  <span
                    data-tooltip='A grain-free stew that is free from corn, wheat, and soy made with single or very limited species animal proteins.'
                    data-tooltip-position='bottom'
                  >
                    <img className='product-icon' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/limited-can.png'} />
                  </span>
                </div>
                <div className='col-sm-6 col-12'>
                  <span data-tooltip='A recipe made with 95% meat.' data-tooltip-position='bottom'>
                    <img className='product-icon' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/95meat.png'} />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* Tablist Row */}
          <div className='row mt-5'>
            <div className='col'>
              <ul className='nav nav-tabs nav-justified mt-4' role='tablist'>
                <li className='nav-item dog-tripe-loaf-product-tab'>
                  <a className='nav-link dog-tripe-loaf-nav-link active d-flex align-items-center justify-content-center' href='#ingredients' role='tab' data-toggle='tab'>
                    INGREDIENTS
                  </a>
                </li>
                <li className='nav-item dog-tripe-loaf-product-tab'>
                  <a className='nav-link dog-tripe-loaf-nav-link d-flex align-items-center justify-content-center' href='#feeding-guidelines' role='tab' data-toggle='tab'>
                    FEEDING GUIDELINES
                  </a>
                </li>
                <li className='nav-item dog-tripe-loaf-product-tab'>
                  <a className='nav-link dog-tripe-loaf-nav-link d-flex align-items-center justify-content-center' href='#guaranteed-analysis' role='tab' data-toggle='tab'>
                    GUARANTEED ANALYSIS
                  </a>
                </li>
                <li className='nav-item dog-tripe-loaf-product-tab'>
                  <a className='nav-link dog-tripe-loaf-nav-link d-flex align-items-center justify-content-center' href='#detailed-nutrients' role='tab' data-toggle='tab'>
                    DETAILED NUTRIENTS
                  </a>
                </li>
              </ul>
              <div className='tab-content'>
                <div role='tabpanel' className='tab-pane active' id='ingredients'>
                  <div className='container-fluid mt-3'>
                    <div className='row'>
                      <div className='col-12 mb-3 ingredients-text'>{this.state.ingredients.join(', ')}</div>
                    </div>
                    <img className='all-life-stages my-3' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/all-life-stage-food-dog.png'} />
                  </div>
                </div>
                <div role='tabpanel' className='tab-pane' id='feeding-guidelines'>
                  <div className='container-fluid mt-3'>
                    <div className='row'>
                      <div className='col-12 mb-3'>
                        {this.state.currentFoodSize === five ? <div className='mb-3 can-table-font-size'>5.3 oz</div> : <div className='mb-3 can-table-font-size'>12.5 oz</div>}
                        {this.state.isDesktop ? (
                          <table className='table feeding-guidelines-table'>
                            <tbody>
                              <tr className='table-border-black' align='center'>
                                <td className='table-border-black feeding-guidelines-text align-middle'>Cans Per Day</td>
                                <td className='table-border-black feeding-guidelines-text align-middle'>Dog Weight (lb)</td>
                              </tr>
                              <tr className='table-border-black' align='center'>
                                <td className='table-border-black align-middle'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][5] : this.state.feedingGuidelines[12][5]}
                                </td>
                                <td className='table-border-black align-middle'>5</td>
                              </tr>
                              <tr className='table-border-black' align='center'>
                                <td className='table-border-black align-middle'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][10] : this.state.feedingGuidelines[12][10]}
                                </td>
                                <td className='table-border-black align-middle'>10</td>
                              </tr>
                              <tr className='table-border-black' align='center'>
                                <td className='table-border-black align-middle'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][20] : this.state.feedingGuidelines[12][20]}
                                </td>
                                <td className='table-border-black align-middle'>20</td>
                              </tr>
                              <tr className='table-border-black' align='center'>
                                <td className='table-border-black align-middle'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][30] : this.state.feedingGuidelines[12][30]}
                                </td>
                                <td className='table-border-black align-middle'>30</td>
                              </tr>
                              <tr className='table-border-black' align='center'>
                                <td className='table-border-black align-middle'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][40] : this.state.feedingGuidelines[12][40]}
                                </td>
                                <td className='table-border-black align-middle'>40</td>
                              </tr>
                              <tr className='table-border-black' align='center'>
                                <td className='table-border-black align-middle'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][60] : this.state.feedingGuidelines[12][60]}
                                </td>
                                <td className='table-border-black align-middle'>60</td>
                              </tr>
                              <tr className='table-border-black' align='center'>
                                <td className='table-border-black align-middle'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][80] : this.state.feedingGuidelines[12][80]}
                                </td>
                                <td className='table-border-black align-middle'>80</td>
                              </tr>
                              <tr className='table-border-black' align='center'>
                                <td className='table-border-black align-middle'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][100] : this.state.feedingGuidelines[12][100]}
                                </td>
                                <td className='table-border-black align-middle'>100</td>
                              </tr>
                            </tbody>
                          </table>
                        ) : (
                          <table className='table feeding-guidelines-table'>
                            <tbody>
                              <tr className='table-border-black'>
                                <td className='table-border-black feeding-guidelines-text'>Dog Weight (lb)</td>
                                <td className='table-border-black align-middle' align='center'>
                                  5
                                </td>
                                <td className='table-border-black align-middle' align='center'>
                                  10
                                </td>
                                <td className='table-border-black align-middle' align='center'>
                                  20
                                </td>
                                <td className='table-border-black align-middle' align='center'>
                                  30
                                </td>
                                <td className='table-border-black align-middle' align='center'>
                                  40
                                </td>
                                <td className='table-border-black align-middle' align='center'>
                                  60
                                </td>
                                <td className='table-border-black align-middle' align='center'>
                                  80
                                </td>
                                <td className='table-border-black align-middle' align='center'>
                                  100
                                </td>
                              </tr>
                              <tr className='table-border-black align-middle'>
                                <td className='table-border-black align-middle feeding-guidelines-text'>Cans Per Day</td>
                                <td className='table-border-black align-middle' align='center'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][5] : this.state.feedingGuidelines[12][5]}
                                </td>
                                <td className='table-border-black align-middle' align='center'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][10] : this.state.feedingGuidelines[12][10]}
                                </td>
                                <td className='table-border-black align-middle' align='center'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][20] : this.state.feedingGuidelines[12][20]}
                                </td>
                                <td className='table-border-black align-middle' align='center'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][30] : this.state.feedingGuidelines[12][30]}
                                </td>
                                <td className='table-border-black align-middle' align='center'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][40] : this.state.feedingGuidelines[12][40]}
                                </td>
                                <td className='table-border-black align-middle' align='center'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][60] : this.state.feedingGuidelines[12][60]}
                                </td>
                                <td className='table-border-black align-middle' align='center'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][80] : this.state.feedingGuidelines[12][80]}
                                </td>
                                <td className='table-border-black align-middle' align='center'>
                                  {this.state.currentFoodSize === five ? this.state.feedingGuidelines[5][100] : this.state.feedingGuidelines[12][100]}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                        <br />
                        Always provide a source of clean, fresh water.
                        <br />
                        <br />
                        Reproduction: Provide increasing amounts of food the latter half of gestation. The amount may be twice normal intake. Feed to consumption during lactation.
                        <br />
                        <br />
                        Growth: Introduce dry food around the 5th week and provide as much food as puppies will consume from weaning until full grown.
                        <br />
                        <br />
                        Lotus Beef Green Tripe Loaf for Dogs is formulated to meet the nutritional levels established by the AAFCO (Association of American Feed Control Officials)
                        Dog Nutrient Profiles for all life stages, including the growth of large breed dogs (70 lb or more as an adult).
                      </div>
                    </div>
                  </div>
                </div>
                <div role='tabpanel' className='tab-pane' id='guaranteed-analysis'>
                  <div className='container-fluid my-3'>
                    <div className='row '>
                      <div className='col-md-6 col-12 mb-3'>
                        {this.state.currentFoodSize === five ? <div className='mb-3 can-table-font-size'>5.3 oz</div> : <div className='mb-3 can-table-font-size'>12.5 oz</div>}
                        <table className='table detailed-nutrient-table'>
                          <tbody>
                            <tr>
                              <td>Crude Protein (Min)</td>
                              <td align='right'>{this.state.guaranteedAnalysis.protein}%</td>
                            </tr>
                            <tr className='table-highlight'>
                              <td>Crude Fat (Min)</td>
                              <td align='right'>{this.state.guaranteedAnalysis.fat}%</td>
                            </tr>
                            <tr>
                              <td>Crude Fiber (Max)</td>
                              <td align='right'>{this.state.guaranteedAnalysis.fiber}%</td>
                            </tr>
                            <tr className='table-highlight'>
                              <td>Moisture (Max)</td>
                              <td align='right'>{this.state.guaranteedAnalysis.moisture}%</td>
                            </tr>
                            <tr>
                              <td>Ash (Max)</td>
                              <td align='right'>{this.state.guaranteedAnalysis.ash}%</td>
                            </tr>
                            <tr className='table-highlight'>
                              <td>
                                Omega 6 Fatty Acids<sup>*</sup> (Min)
                              </td>
                              <td align='right' className='align-middle'>
                                {this.state.guaranteedAnalysis.omega6}%
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Omega 3 Fatty Acids<sup>*</sup> (Min)
                              </td>
                              <td align='right' className='align-middle'>
                                {this.state.guaranteedAnalysis.omega3}%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='col-md-6 col-12'>
                        * Not recognized as an essential nutrient by the AAFCO Dog Food Nutrient Profiles.
                        <br />
                        <br />
                        Calorie Content . . . . . . . {this.state.guaranteedAnalysis.calories.kg} kcal/kg (
                        {this.state.currentFoodSize === five
                          ? `${this.state.guaranteedAnalysis.calories[5]} kcal/5.3 oz can`
                          : `${this.state.guaranteedAnalysis.calories[12]} kcal/12.5 oz can`}
                        )
                      </div>
                    </div>
                  </div>
                </div>
                <div role='tabpanel' className='tab-pane' id='detailed-nutrients'>
                  <div className='container-fluid my-3'>
                    <div className='row'>
                      <div className='col detailed-nutrients-text'>As Fed Percentage</div>
                    </div>
                    <div className='row detailed-nutrient-divider mb-2' />
                    <div className='row'>
                      <div className='col-xl-2 col-lg-6 col-12 px-2'>
                        <table className='table detailed-nutrient-table'>
                          <tbody>
                            <tr>
                              <td className='table-divider'>Protein</td>
                              <td align='right'>{this.state.asFed.protein}%</td>
                            </tr>
                            <tr className='table-highlight'>
                              <td className='table-divider'>Fat</td>
                              <td align='right'>{this.state.asFed.fat}%</td>
                            </tr>
                            <tr>
                              <td className='table-divider'>Moisture</td>
                              <td align='right'>{this.state.asFed.moisture}%</td>
                            </tr>
                            <tr className='table-highlight'>
                              <td className='table-divider'>Ash</td>
                              <td align='right'>{this.state.asFed.ash}%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='col-xl-2 col-lg-6 col-12 px-2'>
                        <table className='table detailed-nutrient-table'>
                          <tbody>
                            <tr>
                              <td className='table-divider'>Fiber</td>
                              <td align='right'>{this.state.asFed.fiber}%</td>
                            </tr>
                            <tr className='table-highlight'>
                              <td className='table-divider'>Carbs</td>
                              <td align='right'>{this.state.asFed.carbs}%</td>
                            </tr>
                            <tr>
                              <td className='table-divider'>Phosphorus</td>
                              <td align='right'>{this.state.asFed.phosphorus}%</td>
                            </tr>
                            <tr className='table-highlight'>
                              <td className='table-divider'>Calcium</td>
                              <td align='right'>{this.state.asFed.calcium}%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='col-xl-2 col-lg-6 col-12 px-2'>
                        <table className='table detailed-nutrient-table'>
                          <tbody>
                            <tr>
                              <td className='table-divider'>Magnesium</td>
                              <td align='right'>{this.state.asFed.magnesium}%</td>
                            </tr>
                            <tr className='table-highlight'>
                              <td className='table-divider'>Potassium</td>
                              <td align='right'>{this.state.asFed.potassium}%</td>
                            </tr>
                            <tr>
                              <td className='table-divider'>Sodium</td>
                              <td align='right'>{this.state.asFed.sodium}%</td>
                            </tr>
                            <tr className='table-highlight'>
                              <td className='table-divider'>Iron</td>
                              <td align='right'>{this.state.asFed.iron} mg/kg</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='col-xl-2 col-lg-6 col-12 px-2'>
                        <table className='table detailed-nutrient-table'>
                          <tbody>
                            <tr>
                              <td className='table-divider'>Copper</td>
                              <td align='right'>{this.state.asFed.copper} mg/kg</td>
                            </tr>
                            <tr className='table-highlight'>
                              <td className='table-divider'>Zinc</td>
                              <td align='right'>{this.state.asFed.zinc} mg/kg</td>
                            </tr>
                            <tr>
                              <td className='table-divider'>Omega-6 FA</td>
                              <td align='right'>{this.state.asFed.omega6}%</td>
                            </tr>
                            <tr className='table-highlight'>
                              <td className='table-divider'>Omega-3 FA</td>
                              <td align='right'>{this.state.asFed.omega3}%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='col-xl-2 col-lg-6 col-12 px-2'>
                        <table className='table detailed-nutrient-table'>
                          <tbody>
                            <tr>
                              <td className='table-divider'>Vitamin C</td>
                              <td align='right'>{this.state.asFed.vitaminC} mg/kg</td>
                            </tr>
                            <tr className='table-highlight'>
                              <td className='table-divider'>Vitamin D</td>
                              <td align='right'>{this.state.asFed.vitaminD} IU/kg</td>
                            </tr>
                            <tr>
                              <td className='table-divider'>Vitamin E</td>
                              <td align='right'>{this.state.asFed.vitaminE} IU/kg</td>
                            </tr>
                            <tr className='table-highlight'>
                              <td className='table-divider'>Iodine</td>
                              <td align='right'>{this.state.asFed.iodine} mg/kg</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='col-xl-2 col-lg-6 col-12 px-2'>
                        <table className='table detailed-nutrient-table'>
                          <tbody>
                            <tr>
                              <td className='table-divider'>Taurine</td>
                              <td align='right'>{this.state.asFed.taurine}%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col detailed-nutrients-text'>Dry Matter Analysis</div>
                    </div>
                    <div className='row detailed-nutrient-divider mb-2' />
                    <div className='row'>
                      <div className='col-xl-2 col-lg-4 col-12 px-2'>
                        <table className='table detailed-nutrient-table'>
                          <tbody>
                            <tr>
                              <td className='table-divider'>Protein</td>
                              <td align='right'>{this.state.dryMatter.protein}%</td>
                            </tr>
                            <tr className='table-highlight'>
                              <td className='table-divider'>Fat</td>
                              <td align='right'>{this.state.dryMatter.fat}%</td>
                            </tr>
                            <tr>
                              <td className='table-divider'>Moisture</td>
                              <td align='right'>{this.state.dryMatter.moisture}</td>
                            </tr>
                            <tr className='table-highlight'>
                              <td className='table-divider'>Ash</td>
                              <td align='right'>{this.state.dryMatter.ash}%</td>
                            </tr>
                            <tr>
                              <td className='table-divider'>Fiber</td>
                              <td align='right'>{this.state.dryMatter.fiber}%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='col-xl-2 col-lg-4 col-12 px-2'>
                        <table className='table detailed-nutrient-table'>
                          <tbody>
                            <tr className={window.innerWidth <= 992 ? 'table-highlight' : null}>
                              <td className='table-divider'>Carbs</td>
                              <td align='right'>{this.state.dryMatter.carbs}%</td>
                            </tr>
                            <tr className={window.innerWidth >= 992 ? 'table-highlight' : null}>
                              <td className='table-divider'>Phosphorus</td>
                              <td align='right'>{this.state.dryMatter.phosphorus}%</td>
                            </tr>
                            <tr className={window.innerWidth <= 992 ? 'table-highlight' : null}>
                              <td className='table-divider'>Calcium</td>
                              <td align='right'>{this.state.dryMatter.calcium}%</td>
                            </tr>
                            <tr className={window.innerWidth >= 992 ? 'table-highlight' : null}>
                              <td className='table-divider'>Magnesium</td>
                              <td align='right'>{this.state.dryMatter.magnesium}%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className='col-xl-2 col-lg-4 col-12 px-2'>
                        <table className='table detailed-nutrient-table'>
                          <tbody>
                            <tr className={window.innerWidth <= 992 ? 'table-highlight' : null}>
                              <td className='table-divider'>Potassium</td>
                              <td align='right'>{this.state.dryMatter.potassium}%</td>
                            </tr>
                            <tr className={window.innerWidth >= 992 ? 'table-highlight' : null}>
                              <td className='table-divider'>Sodium</td>
                              <td align='right'>{this.state.dryMatter.sodium}%</td>
                            </tr>
                            <tr className={window.innerWidth <= 992 ? 'table-highlight' : null}>
                              <td className='table-divider'>Omega-6 FA</td>
                              <td align='right'>{this.state.dryMatter.omega6}%</td>
                            </tr>
                            <tr className={window.innerWidth >= 992 ? 'table-highlight' : null}>
                              <td className='table-divider'>Omega-3 FA</td>
                              <td align='right'>{this.state.dryMatter.omega3}%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col detailed-nutrients-text'>Calories</div>
                    </div>
                    <div className='row detailed-nutrient-divider mb-2' />
                    <div className='row'>
                      <div className='col-xl-6 col-12 px-2'>
                        {window.innerWidth <= 768 ? (
                          <table className='table detailed-nutrient-table'>
                            <tbody>
                              <tr>
                                <td className='align-middle'>% From Protein</td>
                                <td align='right' className='align-middle'>
                                  {this.state.calories.protein}%
                                </td>
                              </tr>
                              <tr className='table-highlight'>
                                <td className='align-middle'>% From Fat</td>
                                <td align='right' className='align-middle'>
                                  {this.state.calories.fat}%
                                </td>
                              </tr>
                              <tr>
                                <td className='align-middle'>% From Carbohydrates</td>
                                <td align='right' className='align-middle'>
                                  {this.state.calories.carbohydrates}%
                                </td>
                              </tr>
                              <tr className='table-highlight'>
                                <td className='align-middle'>kcal/kg</td>
                                <td align='right' className='align-middle'>
                                  {this.state.calories.kcal}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ) : (
                          <table className='table detailed-nutrient-table'>
                            <tbody>
                              <tr align='center'>
                                <td className='table-no-background align-middle'>% From Protein</td>
                                <td className='table-no-background align-middle'>% From Fat</td>
                                <td className='table-no-background align-middle'>% From Carbohydrates</td>
                                <td className='table-no-background align-middle'>kcal/kg</td>
                              </tr>
                              <tr className='table-white-background align-middle' align='center'>
                                <td className='table-divider'>{this.state.calories.protein}%</td>
                                <td className='table-divider'>{this.state.calories.fat}%</td>
                                <td className='table-divider'>{this.state.calories.carbohydrates}%</td>
                                <td>{this.state.calories.kcal}</td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Review Section */}
          <div className='mt-5 row'>
            <div className='col-xl-4 col-md-6 col-12'>
              <div className='what-customers'>WHAT CUSTOMERS ARE SAYING</div>
              <div className='row dog-tripe-loaf-line mb-2 mx-0' />
              <div style={{ fontSize: 24 }} className='mt-2 stars d-flex align-items-center'>
                <StarRatingComponent
                  name='product'
                  starCount={5}
                  value={this.state.stars}
                  editing={false}
                  renderStarIcon={(index, value) => {
                    return (
                      <span>
                        <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                      </span>
                    );
                  }}
                  renderStarIconHalf={() => {
                    return (
                      <span>
                        <span style={{ position: 'absolute' }}>
                          <i className='far fa-star' />
                        </span>
                        <span>
                          <i className='fas fa-star-half' />
                        </span>
                      </span>
                    );
                  }}
                />
                <div className='review-count text-truncate' onClick={this.allReviews}>
                  {this.state.totalReviews.length === 1 ? '1 customer review' : `${this.state.totalReviews.length} customer reviews`}
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-3 star-col'>
                  <div className='review-stars'>5 star</div>
                  <div className='review-stars'>4 star</div>
                  <div className='review-stars'>3 star</div>
                  <div className='review-stars'>2 star</div>
                  <div className='review-stars'>1 star</div>
                </div>
                <div className='col-4 px-0'>
                  <div className='progress'>
                    <div className='progress-bar' role='progressbar' style={{ width: `${this.state.starHistogram[5]}%` }} aria-valuemin='0' aria-valuemax='100' />
                  </div>
                  <div className='progress'>
                    <div className='progress-bar' role='progressbar' style={{ width: `${this.state.starHistogram[4]}%` }} aria-valuemin='0' aria-valuemax='100' />
                  </div>
                  <div className='progress'>
                    <div className='progress-bar' role='progressbar' style={{ width: `${this.state.starHistogram[3]}%` }} aria-valuemin='0' aria-valuemax='100' />
                  </div>
                  <div className='progress'>
                    <div className='progress-bar' role='progressbar' style={{ width: `${this.state.starHistogram[2]}%` }} aria-valuemin='0' aria-valuemax='100' />
                  </div>
                  <div className='progress mb-3'>
                    <div className='progress-bar' role='progressbar' style={{ width: `${this.state.starHistogram[1]}%` }} aria-valuemin='0' aria-valuemax='100' />
                  </div>
                </div>
                <div className='col-3'>
                  <div className='review-percent' value='5' onClick={this.reviewsByStar}>
                    {this.state.starHistogram[5]}%
                  </div>
                  <div className='review-percent' value='4' onClick={this.reviewsByStar}>
                    {this.state.starHistogram[4]}%
                  </div>
                  <div className='review-percent' value='3' onClick={this.reviewsByStar}>
                    {this.state.starHistogram[3]}%
                  </div>
                  <div className='review-percent' value='2' onClick={this.reviewsByStar}>
                    {this.state.starHistogram[2]}%
                  </div>
                  <div className='review-percent' value='1' onClick={this.reviewsByStar}>
                    {this.state.starHistogram[1]}%
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-2 col-md-3 col-12'>
              {localStorage.token ? (
                <Link
                  to='/review'
                  href='/review'
                  onClick={() => {
                    localStorage.setItem('productId', this.state.id);
                    localStorage.setItem('prevPath', this.props.location.pathname);
                  }}
                >
                  <img className='review-icon' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/write-a-review-icon.png'} />
                </Link>
              ) : (
                <a
                  onClick={() => {
                    swal.fire({
                      title: 'You must be signed in to review.',
                      icon: 'error',
                      showConfirmButton: false,
                      timer: 1500
                    });
                  }}
                >
                  <img className='review-icon' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/write-a-review-icon.png'} />
                </a>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-auto'>
              {this.state.totalReviews.length === 0 ? (
                <div className='mt-5 review-divider-count no-reviews'>No reviews yet. Be the first!</div>
              ) : (
                <div className='mt-5 review-divider-count'>
                  {this.state.reviewFirst}
                  {this.state.nestedReviews.flat().length === 2 ? '-2' : this.state.reviewFirst !== this.state.nestedReviews.flat().length ? -this.state.reviewLast : null} of{' '}
                  {this.state.nestedReviews.flat().length} Reviews
                </div>
              )}
            </div>
            <div className='col'>
              {this.state.reviewIndex < this.state.nestedReviews.length - 1 ? (
                <div className='mt-5 ml-2 next-page' onClick={this.nextPage}>
                  Next Page
                </div>
              ) : null}
              {this.state.reviewIndex > 0 && this.state.reviewIndex !== this.state.nestedReviews.length - 1 ? (
                <div>
                  <span className='previous-page mt-5'>/</span>
                  <div className='mt-5 mr-2 previous-page' onClick={this.previousPage}>
                    Previous Page
                  </div>
                </div>
              ) : this.state.reviewIndex === this.state.nestedReviews.length - 1 && this.state.reviewIndex > 0 ? (
                <div className='mt-5 mr-2 previous-page' onClick={this.previousPage}>
                  Previous Page
                </div>
              ) : null}
            </div>
          </div>
          <div className='row mb-3 page-divider' />
          {this.state.renderedReviews.map(review => {
            return (
              <div key={review.review_id}>
                <div className='row align-items-center h-100'>
                  <div className='col-auto my-auto'>
                    {review.avatar === 'null' ? (
                      <img className='review-avatar' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/missing.jpg'} />
                    ) : (
                      <img className='review-avatar' src={review.avatar} />
                    )}
                  </div>
                  <div className='col my-auto px-0 review-name'>
                    {review.first_name} {review.last_name}
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col'>
                    <div style={{ fontSize: 18 }}>
                      <StarRatingComponent
                        name='product'
                        starCount={5}
                        value={review.stars}
                        editing={false}
                        renderStarIcon={(index, value) => {
                          return (
                            <span>
                              <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                            </span>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col review-date'>{this.convertDate(review.review_date)}</div>
                </div>
                <div className='row mt-2'>
                  <div className='col-12 review-title'>{review.title}</div>
                </div>
                <div className='row'>
                  <div className='col-12 review-text'>{review.review}</div>
                </div>
                <div className='row mt-3'>
                  {JSON.parse(review.pictures).map((picture, i) => {
                    return (
                      <div key={i} className='col-auto pr-0'>
                        <div className='review-picture'>
                          <img src={picture} />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className='row mt-3 mb-3 page-divider' />
              </div>
            );
          })}
          <div className='row mt-5'>
            <div className='col-xl-4 col-md-6 col-12'>
              <div className='related-products'>RELATED PRODUCTS</div>
              <div className='row dog-tripe-loaf-line mb-2 mx-0' />
            </div>
          </div>
          <div className='row mt-2'>
            {products.map((product, i) => {
              return (
                <div className='mt-3 col-xl-3 col-md-4 col-6 text-center' key={i}>
                  <Link to={`/dog-food/loafs/${product.link}`} href={`/dog-food/loafs/${product.link}`} className='related-products-text'>
                    <img className='img-fluid related-products-image' src={product.img} />
                    <div className='mt-1'>{product.name}</div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default DogTripeLoaf;
