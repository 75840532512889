import React from 'react';
import $ from 'jquery';
import { MDBBtn, MDBCol, MDBModal, MDBModalBody, MDBModalHeader, MDBAlert } from 'mdbreact';
import Lightbox from 'react-image-lightbox';
import StarRatingComponent from 'react-star-rating-component';
import { Link } from 'react-router-dom';
import swal from 'sweetalert2';
import axios from 'axios';

import UserAccForms from '../../../../../ClientInteraction/UserAccForms/UserAccForms.jsx';

const related = [
  {
    link: 'pork-58',
    name: 'Pork',
    state: { initialThumbSize: 'small', initialSizeIndex: '1' },
    img:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/pork/24oz/pork+320.png',
  },
  {
    link: 'turkey-57',
    name: 'Turkey',
    state: { initialThumbSize: 'small', initialSizeIndex: '1' },
    img:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/turkey/24oz/turkey+320.png',
  },
  {
    link: 'chicken-60',
    name: 'Chicken',
    state: { initialThumbSize: 'small', initialSizeIndex: '1' },
    img:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Chicken/24/chicken+320.png',
  },
  {
    link: 'lamb-54',
    name: 'Lamb',
    state: { initialThumbSize: 'small', initialSizeIndex: '1' },
    img:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/lamb/24oz/lamb+320.png',
  },
  {
    link: 'venison-55',
    name: 'Venison',
    state: { initialThumbSize: 'small', initialSizeIndex: '1' },
    img:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/venison/24oz/venison+320.png',
  },
  {
    link: 'beef-59',
    name: 'Beef',
    state: { initialThumbSize: 'small', initialSizeIndex: '1' },
    img:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Beef/24oz/beef+320.png',
  },
];

const products = related.slice(0, 3).map(function() {
  return this.splice(Math.floor(Math.random() * this.length), 1)[0];
}, related.slice());

/* Used for 3.5 */
const five = [
  {
    main:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/variety1+640.png',
    jpg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/variety1+1920.png',
    png:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/variety1+320.png',
  },
  {
    main:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/variety2+640.png',
    jpg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/variety2+1920.png',
    png:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/variety2+320.png',
  },
  {
    main:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/beef+1920.png',
    jpg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/beef+1920.png',
    png:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/beef+320.png',
  },
  {
    main:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/chicken+640.png',
    jpg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/chicken+1920.png',
    png:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/chicken+320.png',
  },
  {
    main:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/pork+640.png',
    jpg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/pork+1920.png',
    png:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/pork+320.png',
  },
  {
    main:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/turkey+640.png',
    jpg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/turkey+1920.png',
    png:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/turkey+320.png',
  },
  {
    main:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/venison+640.png',
    jpg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/venison+1920.png',
    png:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/venison+320.png',
  },
  {
    main:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/lamb+640.png',
    jpg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/lamb+1920.png',
    png:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/Variety+pack/lamb+320.png',
  },
];

class RawBeef extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: [],
      /* This is where the actual information will be */
      /* Need to insert the information into the database manually */
      id: 56,
      name: 'Raw Variety',
      currentMainImage: five[0].main,
      show3D: false,
      currentFoodSize: five,
      imageView: 0,
      photoIndex: 0,
      showLightbox: false,
      totalReviews: [],
      nestedReviews: [],
      reviewIndex: 0,
      renderedReviews: [],
      reviewFirst: 1,
      reviewLast: 3,
      stars: 0,
      starHistogram: { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 },
      isDesktop: false,
      signInModal: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios.post('/getreviews', { id: this.state.id }).then((res) => {
      if (res.data.length > 0) {
        let reviews = res.data;
        let stars;
        let total = 0;
        const starHistogram = {
          5: 0,
          4: 0,
          3: 0,
          2: 0,
          1: 0,
        };

        for (let i = 0; i < reviews.length; i++) {
          total += reviews[i].stars;
          starHistogram[reviews[i].stars]++;
        }

        stars = Math.round((total / reviews.length) * 2) / 2;

        for (let star in starHistogram) {
          starHistogram[star] = Math.round((starHistogram[star] / reviews.length) * 100);
        }

        const allReviews = reviews;
        const nestedReviews = [];
        let nested = [];

        for (let i = 0; i < allReviews.length; i++) {
          nested.push(allReviews[i]);
          if (nested.length === 3 || i === allReviews.length - 1) {
            nestedReviews.push(nested);
            nested = [];
          }
        }

        this.setState({
          stars: stars,
          starHistogram: starHistogram,
          nestedReviews: nestedReviews,
          renderedReviews: nestedReviews[0],
          totalReviews: reviews,
        });
      } else {
        this.setState({
          stars: 0,
          starHistogram: {
            5: 0,
            4: 0,
            3: 0,
            2: 0,
            1: 0,
          },
          nestedReviews: [],
          renderedReviews: [],
          totalReviews: [],
        });
      }
    });

    axios.post('/getproductinfo', { id: this.state.id }).then((res) => {
      const asFed = JSON.parse(res.data.rows[0].as_fed);
      const calories = JSON.parse(res.data.rows[0].calories);
      const description = JSON.parse(res.data.rows[0].description);
      const dryMatter = JSON.parse(res.data.rows[0].dry_matter);
      const feedingGuidelines = JSON.parse(res.data.rows[0].feeding_guidelines);
      const guaranteedAnalysis = JSON.parse(res.data.rows[0].guaranteed_analysis);
      const ingredients = JSON.parse(res.data.rows[0].ingredients);

      this.setState({
        asFed,
        ingredients,
        feedingGuidelines,
        guaranteedAnalysis,
        dryMatter,
        calories,
        description,
      });
    });
    this.updatePredicate();
    window.addEventListener('resize', this.updatePredicate);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updatePredicate);
  };

  updatePredicate = () => {
    this.setState({ isDesktop: window.innerWidth < 1200 });
  };

  currentMainImage = (e) => {
    this.setState({
      photoIndex: parseInt(e.target.id),
      currentMainImage: e.target.name,
      show3D: false,
    });
    $('img').removeClass('selected-variety-raw-thumbnail');
    $(`img[id=${e.target.id}]`).addClass('selected-variety-raw-thumbnail');
  };

  show3D = (e) => {
    this.setState({
      show3D: true,
    });
    $('img').removeClass('selected-variety-raw-thumbnail');
    $(`img[id=${e.target.id}]`).addClass('selected-variety-raw-thumbnail');
  };

  changeView = (e) => {
    this.setState({
      imageView: e.target.value,
    });
  };

  selectLabelColor = (e) => {
    $('.variety-raw-size-color').removeClass('selected-raw-highlight');
  };

  showLightbox = (e) => {
    this.setState({
      photoIndex: parseInt(e.target.id),
      showLightbox: true,
    });
  };

  changeView = (e) => {
    this.setState({
      imageView: e.target.value,
    });
  };

  convertDate = (date) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let month = months[date.substring(0, 2) - 1];
    let day = date.substring(3, 5);
    let year = date.slice(-4);

    if (day[0] === '0') {
      day = day[1];
    } else if (day[1] === '/') {
      day = day[0];
    }

    return `${month} ${day}, ${year}`;
  };

  reviewsByStar = (e) => {
    const star = e.currentTarget.getAttribute('value');
    const reviews = [];
    const nestedReviews = [];
    let nested = [];

    for (let i = 0; i < this.state.totalReviews.length; i++) {
      if (this.state.totalReviews[i].stars == star) {
        reviews.push(this.state.totalReviews[i]);
      }
    }

    for (let i = 0; i < reviews.length; i++) {
      nested.push(reviews[i]);
      if (nested.length === 3 || i === reviews.length - 1) {
        nestedReviews.push(nested);
        nested = [];
      }
    }

    this.setState({
      nestedReviews,
      renderedReviews: nestedReviews[0],
      reviewFirst: 1,
      reviewLast: nestedReviews[0].length,
      reviewIndex: 0,
    });
  };

  allReviews = () => {
    const allReviews = reviews;
    const nestedReviews = [];
    let nested = [];

    for (let i = 0; i < allReviews.length; i++) {
      nested.push(allReviews[i]);
      if (nested.length === 3 || i === allReviews.length - 1) {
        nestedReviews.push(nested);
        nested = [];
      }
    }
    this.setState({
      nestedReviews: nestedReviews,
      renderedReviews: nestedReviews[0],
      reviewFirst: 1,
      reviewLast: nestedReviews[0].length,
      reviewIndex: 0,
    });
  };

  nextPage = () => {
    const newIndex = (this.state.reviewIndex += 1);
    const newReviews = this.state.nestedReviews[newIndex];
    const total = this.state.nestedReviews.flat().length;
    let newFirst = (this.state.reviewFirst += 3);
    let newLast = (this.state.reviewLast += 3);

    if (newLast > total) {
      newLast = total;
    }

    this.setState({
      reviewIndex: newIndex,
      renderedReviews: newReviews,
      reviewFirst: newFirst,
      reviewLast: newLast,
    });
  };

  toggleSignInModal = (e) => {
    e.preventDefault();
    this.setState({
      signInModal: !this.state.signInModal,
    });
  };

  userAccModal = () => {
    const { history } = this.props;
    return (
      <MDBModal
        onClick
        modalStyle="warning"
        toggle={this.toggleSignInModal}
        centered
        size="lg"
        isOpen={this.state.signInModal}>
        <MDBModalHeader
          tag="p"
          className="futura-font text-dark font-weight-light"
          toggle={this.toggleSignInModal}>
          Users Account
        </MDBModalHeader>
        <MDBModalBody className="text-dark p-0">
          <MDBAlert
            color="danger"
            dismiss
            className="product-main--review-warning text-center font-weight-bolder mb-0">
            *You must sign in or have an account to write a review.
          </MDBAlert>
          <UserAccForms toggleSignInModal={this.toggleSignInModal} history={history} />
        </MDBModalBody>
      </MDBModal>
    );
  };

  renderReviewBtn = () => {
    const { id, name } = this.state;
    const user = localStorage.getItem('user') || localStorage.getItem('admin');

    return (
      <MDBCol size="12" className="d-flex justify-content-md-evenly align-items-center">
        <Link
          className="d-flex justify-content-evenly align-items-center text-dark"
          to={user !== null ? `/review/${id}/${name}` : '#'}>
          <button
            onClick={user === null ? this.toggleSignInModal : ''}
            className="product-main__review-btn product-main--no-highlight variety-raw-color btn"
            title={user !== null ? 'Write a review' : 'You must be logged in to write a review.'}>
            <div className="d-flex justify-content-evenly align-items-center">
              <img
                className="product-main__review-icon img-fluid"
                src="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/review+blk-01.png"
              />
              <p className="product-review-button__p-text futura-font font-weight-bolder text-capitalize ml-2 text-dark">
                Write a Review
              </p>
            </div>
          </button>
        </Link>
      </MDBCol>
    );
  };

  previousPage = () => {
    const newIndex = (this.state.reviewIndex -= 1);
    const newReviews = this.state.nestedReviews[newIndex];
    let newFirst = (this.state.reviewFirst -= this.state.nestedReviews[
      this.state.reviewIndex
    ].length);
    let newLast;

    if (newFirst < 0) {
      newFirst = 1;
      newLast = 3;
    } else if (this.state.reviewFirst === this.state.nestedReviews.flat().length) {
      newLast = this.state.nestedReviews[this.state.reviewIndex + 1].length;
    } else {
      newLast = this.state.reviewLast -= this.state.nestedReviews[
        this.state.reviewIndex + 1
      ].length;
    }

    this.setState({
      reviewIndex: newIndex,
      renderedReviews: newReviews,
      reviewFirst: newFirst,
      reviewLast: newLast,
    });
  };

  render() {
    return (
      <div className="row justify-content-center">
        {/* Lightbox Toggler */}
        {this.state.showLightbox && (
          <Lightbox
            mainSrc={this.state.currentFoodSize[this.state.photoIndex].jpg}
            nextSrc={
              this.state.currentFoodSize[
                (this.state.photoIndex + 1) % this.state.currentFoodSize.length
              ].jpg
            }
            prevSrc={
              this.state.currentFoodSize[
                (this.state.photoIndex + this.state.currentFoodSize.length - 1) %
                  this.state.currentFoodSize.length
              ].jpg
            }
            onCloseRequest={() => this.setState({ showLightbox: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex + this.state.currentFoodSize.length - 1) %
                  this.state.currentFoodSize.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + 1) % this.state.currentFoodSize.length,
              })
            }
          />
        )}
        <div className="container cat-raw-product-body">
          {/* Product Overview Row */}
          <div className="row justify-content-center">
            {/* Main Image Column */}
            {this.state.show3D ? (
              <div className="col-md-6 col-xs-12 m-auto">
                <div className="slidecontainer text-center">
                  <img
                    className="main-image img-fluid"
                    src={this.state.currentFoodSize[this.state.imageView].main}
                  />
                  <input
                    className="slider regular-adult-slider"
                    type="range"
                    min="0"
                    max={this.state.currentFoodSize.length - 2}
                    value={this.state.imageView}
                    onChange={this.changeView.bind(this)}
                  />
                </div>
              </div>
            ) : (
              <div className="col-md-6 col-xs-12 d-flex align-items-center justify-content-center mb-4">
                {this.state.currentFoodSize === five && (
                  <div className="col-12">
                    <img
                      className="main-image img-fluid"
                      src={this.state.currentMainImage}
                      id={$('.selected-variety-raw-thumbnail').attr('id')}
                      onClick={window.innerWidth <= 768 ? this.showLightbox : null}
                    />
                  </div>
                )}

                {/* Thumbnail Column */}
                <div className="col-12 position-absolute d-flex justify-content-center justify-content-lg-start product-image-thumbnails product-image-thumbnails--variety-adjustment">
                  {this.state.currentFoodSize.slice(0, 4).map((picture, i) => {
                    return (
                      <div key={i} className="product-thumbnail-container mb-2">
                        <img
                          id={i}
                          className={
                            i === 0
                              ? 'selected-variety-raw-thumbnail product-thumbnail'
                              : 'product-thumbnail'
                          }
                          name={picture.main}
                          onMouseEnter={this.currentMainImage}
                          onClick={
                            window.innerWidth <= 768 ? this.currentMainImage : this.showLightbox
                          }
                          src={picture.png}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="col-12 position-absolute d-flex justify-content-center justify-content-lg-start product-image-thumbnails product-image-thumbnails--variety-adjustment2">
                  {this.state.currentFoodSize.slice(4).map((picture, i) => {
                    return (
                      <div key={i + 4} className="product-thumbnail-container mb-2">
                        <img
                          id={i + 4}
                          className={'product-thumbnail'}
                          name={picture.main}
                          onMouseEnter={this.currentMainImage}
                          onClick={
                            window.innerWidth <= 768 ? this.currentMainImage : this.showLightbox
                          }
                          src={picture.png}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* Description Column */}
            <div className="col-lg-6 col-12 description-col">
              <div className="row">
                <div className="col-12 description-col__title-header">
                  <span className="description-col__product-span-title">RAW FOOD</span>
                  <h1 className="description-col__product-title" style={{ fontSize: '34px' }}>
                    6 count variety pack
                  </h1>
                </div>
              </div>
              <hr className="variety-raw-line" />
              <div style={{ fontSize: 24 }} className="stars d-flex align-items-center">
                <StarRatingComponent
                  name="product"
                  starCount={5}
                  value={this.state.stars}
                  editing={false}
                  renderStarIcon={(index, value) => {
                    return (
                      <span>
                        <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                      </span>
                    );
                  }}
                  renderStarIconHalf={() => {
                    return (
                      <span>
                        <span style={{ position: 'absolute' }}>
                          <i className="far fa-star" />
                        </span>
                        <span>
                          <i className="fas fa-star-half" />
                        </span>
                      </span>
                    );
                  }}
                />
                <div className="rating-count">{this.state.totalReviews.length}</div>
              </div>
              <div className="product-description">
                <ul className="product-bullets">
                  {this.state.description.map((description) => {
                    return <li>{description}</li>;
                  })}
                </ul>
              </div>
              <Link className="where-to-buy" to="/store-locator" href="/store-locator">
                <button type="submit" className="buy-button">
                  Where to Buy
                </button>
              </Link>
              <div className="available-sizes futura-font text-capitalize mt-4 mb-1">
                Variety Pack Includes:
              </div>
              <div className="row justify-content-start variety-pack-includes">
                {related.map((item, key) => (
                  <div
                    className="col-4 size-options text-center"
                    key={key}
                    onMouseEnter={this.selectLabelColor}
                    onClick={this.selectLabelColor}>
                    <Link
                      className="d-flex justify-content-center variety-raw-size-color variety-pack-includes__link-to"
                      to={{
                        pathname: `/product-view/cat/raw-food/${item.link}`,
                        state: item.state,
                      }}>
                      <span className="text-capitalize text-dark ">{item.name}</span>
                    </Link>
                  </div>
                ))}
              </div>
              <div className="row pr-5 mt-4">
                <div className="col-sm-6 col-12">
                  <span
                    data-tooltip="Limited Ingredient Diets – Our limited ingredient raw recipes are made with same species meat or poultry cuts with limited carbohydrates."
                    data-tooltip-position="bottom">
                    <img
                      className="product-icon"
                      src={
                        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/limited-ingredient-icon.png'
                      }
                    />
                  </span>
                </div>
                <div className="col-sm-6 col-12">
                  <span
                    data-tooltip="NO Vegetables or Fruits – Our raw recipes for cats do not contain any vegetables or fruits. This helps texture and palatability."
                    data-tooltip-position="bottom">
                    <img
                      className="product-icon"
                      src={
                        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/no-veggies-icon.png'
                      }
                    />
                  </span>
                </div>
              </div>
              <div className="row pr-5 mt-1">
                <div className="col-sm-6 col-12">
                  <span
                    data-tooltip="NO Ground Bone – Our raw recipes do not contain any ground bone. This helps texture and palatability. It also helps maintain proper calcium and phosphorus levels"
                    data-tooltip-position="bottom">
                    <img
                      className="product-icon"
                      src={
                        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/no-bone-icon.png'
                      }
                    />
                  </span>
                </div>
                <div className="col-sm-6 col-12">
                  <span
                    data-tooltip="Meats with no added hormones or antibiotics – We only use meats and poultry that are vegetarian fed and our not given any hormones or antibiotics."
                    data-tooltip-position="bottom">
                    <img
                      className="product-icon"
                      src={
                        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/meats-icon.png'
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 page-divider"></div>
          {/* Review Section */}
          <div className="mt-5 row">
            <div className="col-md-6 col-12">
              <div className="what-customers">WHAT CUSTOMERS ARE SAYING</div>
              <div className="row variety-raw-line mb-2 mx-0" />
              <div style={{ fontSize: 18 }} className="mt-2 stars d-flex align-items-center">
                <StarRatingComponent
                  name="product"
                  starCount={5}
                  value={this.state.stars}
                  editing={false}
                  renderStarIcon={(index, value) => {
                    return (
                      <span>
                        <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                      </span>
                    );
                  }}
                  renderStarIconHalf={() => {
                    return (
                      <span>
                        <span style={{ position: 'absolute' }}>
                          <i className="far fa-star" />
                        </span>
                        <span>
                          <i className="fas fa-star-half" />
                        </span>
                      </span>
                    );
                  }}
                />
                <div className="review-count text-truncate" onClick={this.allReviews}>
                  {this.state.totalReviews.length === 1
                    ? '1 customer review'
                    : `${this.state.totalReviews.length} customer reviews`}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-3 star-col">
                  <div className="review-stars">5 star</div>
                  <div className="review-stars">4 star</div>
                  <div className="review-stars">3 star</div>
                  <div className="review-stars">2 star</div>
                  <div className="review-stars">1 star</div>
                </div>
                <div className="col-4 px-0">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${this.state.starHistogram[5]}%` }}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${this.state.starHistogram[4]}%` }}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${this.state.starHistogram[3]}%` }}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${this.state.starHistogram[2]}%` }}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${this.state.starHistogram[1]}%` }}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="review-percent" value="5" onClick={this.reviewsByStar}>
                    {this.state.starHistogram[5]}%
                  </div>
                  <div className="review-percent" value="4" onClick={this.reviewsByStar}>
                    {this.state.starHistogram[4]}%
                  </div>
                  <div className="review-percent" value="3" onClick={this.reviewsByStar}>
                    {this.state.starHistogram[3]}%
                  </div>
                  <div className="review-percent" value="2" onClick={this.reviewsByStar}>
                    {this.state.starHistogram[2]}%
                  </div>
                  <div className="review-percent" value="1" onClick={this.reviewsByStar}>
                    {this.state.starHistogram[1]}%
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 product-review-button pointer">
              {this.renderReviewBtn()}
            </div>
          </div>
          <div className="row">
            <div className="col-auto">
              {this.state.totalReviews.length === 0 ? (
                <div className="mt-5 review-divider-count no-reviews">
                  No reviews yet. Be the first!
                </div>
              ) : (
                <div className="mt-5 review-divider-count">
                  {this.state.reviewFirst}
                  {this.state.nestedReviews.flat().length === 2
                    ? '-2'
                    : this.state.reviewFirst !== this.state.nestedReviews.flat().length
                    ? -this.state.reviewLast
                    : null}{' '}
                  of {this.state.nestedReviews.flat().length} Reviews
                </div>
              )}
            </div>
            <div className="col">
              {this.state.reviewIndex < this.state.nestedReviews.length - 1 ? (
                <div className="mt-5 ml-2 next-page" onClick={this.nextPage}>
                  Next Page
                </div>
              ) : null}
              {this.state.reviewIndex > 0 &&
              this.state.reviewIndex !== this.state.nestedReviews.length - 1 ? (
                <div>
                  <span className="previous-page mt-5">/</span>
                  <div className="mt-5 mr-2 previous-page" onClick={this.previousPage}>
                    Previous Page
                  </div>
                </div>
              ) : this.state.reviewIndex === this.state.nestedReviews.length - 1 &&
                this.state.reviewIndex > 0 ? (
                <div className="mt-5 mr-2 previous-page" onClick={this.previousPage}>
                  Previous Page
                </div>
              ) : null}
            </div>
          </div>
          <div className="row mb-3 page-divider" />
          {this.state.renderedReviews.map((review) => {
            return (
              <div key={review.review_id}>
                <div className="row align-items-center h-100">
                  <div className="col-auto my-auto">
                    {review.avatar === 'null' ? (
                      <img
                        className="review-avatar"
                        src={
                          'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/missing.jpg'
                        }
                      />
                    ) : (
                      <img className="review-avatar" src={review.avatar} />
                    )}
                  </div>
                  <div className="col my-auto px-0 review-name">
                    {review.first_name} {review.last_name}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div style={{ fontSize: 18 }}>
                      <StarRatingComponent
                        name="product"
                        starCount={5}
                        value={review.stars}
                        editing={false}
                        renderStarIcon={(index, value) => {
                          return (
                            <span>
                              <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                            </span>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col review-date">{this.convertDate(review.review_date)}</div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 review-title">{review.title}</div>
                </div>
                <div className="row">
                  <div className="col-12 review-text">{review.review}</div>
                </div>
                <div className="row mt-3">
                  {JSON.parse(review.pictures).map((picture, i) => {
                    return (
                      <div key={i} className="col-auto pr-0">
                        <div className="review-picture">
                          <img src={picture} />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="row mt-3 mb-3 page-divider" />
              </div>
            );
          })}
          <div className="row mt-5">
            <div className="col-xl-4 col-md-6 col-12">
              <div className="related-products">RELATED PRODUCTS</div>
              <div className="row variety-raw-line mb-2 mx-0" />
            </div>
          </div>
          <div className="row mt-2">
            {products.map((product, i) => {
              return (
                <div className="mt-3 col-xl-3 col-md-4 col-6 text-center" key={i}>
                  <Link to={`/cat-food/raw-food/${product.link}`} className="related-products-text">
                    <img className="img-fluid related-products-image" src={product.img} />
                    <div className="mt-1">{product.name}</div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        {this.userAccModal()}
      </div>
    );
  }
}

export default RawBeef;
