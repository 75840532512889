import React from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';

import QuestionsComponent from './QuestionsComponent.jsx';

export default function RenderQuiz(props) {
  // eslint-disable-next-line camelcase
  const { _id, name, questions_bank } = props.quiz;

  const renderQuizQuestionsComp = (questionsBank) => {
    if (!questionsBank) return null;

    return questionsBank.map((quizObj, i) => {
      const { question, number, correctAnswer, answers } = quizObj;
      return (
        <div key={i} className="admin-quizzes__question-wrapper my-3">
          <MDBRow>
            <MDBCol size="12">
              <p className="admin-quizzes__p-text">
                {number}. {question}
              </p>
            </MDBCol>
            <MDBCol size="12">
              <form className="admin-quizz__answers-wrapper d-flex flex-column">
                <QuestionsComponent
                  questionNum={i + 1}
                  changeAnswer={props.changeAnswer}
                  answers={answers}
                  correctAnswer={correctAnswer}
                />
              </form>
            </MDBCol>
          </MDBRow>
        </div>
      );
    });
  };
  return (
    <MDBRow>
      <MDBCol size="12">
        <h2 className="admin-main__header2">You are currently editing: {name}</h2>
        {/* eslint-disable-next-line camelcase */}
        {renderQuizQuestionsComp(questions_bank)}
      </MDBCol>
      <MDBCol size="12">
        <MDBBtn onClick={props.saveNewAnswers} className="ml-0" color="warning">
          Update Quiz
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  );
}
