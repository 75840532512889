import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact';

import SimpleInputField from '../../../FieldComponents/InputFields/InputField.jsx';
import PasswordField from '../../../FieldComponents/InputFields/PasswordField.jsx';

const terms = () => {
  window.open(
    window.location.origin + '/terms-and-conditions',
    '_blank',
    'toolbar=0,location=0,menubar=0',
  );
};

export default function SignUpFields(props) {
  const { firstName, lastName, email, password, onChange, signUp, onSubscribeChange } = props;
  return (
    <form onSubmit={signUp}>
      <SimpleInputField label="First name" value={firstName} name="firstName" onChange={onChange} id="firstnamee"/>
      <SimpleInputField label="Last name" value={lastName} name="lastName" onChange={onChange} id="lastnamee"/>
      <SimpleInputField label="Email" value={email} name="email" onChange={onChange} id="emaill"/>
      <PasswordField label="Password" value={password} name="password" onChange={onChange} id="passwordd"/>

      <div className="mb-3">
      <MDBInput label="Subscribe to our newsletter and receive exclusive updates and promotion." 
      type="checkbox"
      id="checkbox1"
      onChange={onSubscribeChange}
      />
      </div>

      <MDBCol size="12" className="mb-2">
        <label className="sign-up-fields__label" htmlFor="signup-agree" id="terms">
          By creating an account, you are agreeing to our
          <br />{' '}
          <a className="terms-link" onClick={terms}>
            Terms and Conditions
          </a>
          .
        </label>
      </MDBCol>
      
      <MDBBtn className="ml-2" type="submit" color="dark">
        Create Account
      </MDBBtn>

      

    </form>
  );
}
