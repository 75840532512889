import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class VacationWithPet extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
      }

  render() {
    return (
        <div>
                <div className="pb-3 blog-margin-adjustment-desktop">
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/vacation-banner.jpg'} 
                        className="banner img-fluid w-100" 
                        alt="lotus Pet Foods - Vacation with Pet"/>
                    </div>
                </MDBAnimation>
                </div>
       
            <div className='container mt-5'>
                <p style={{"color": "grey"}}>April, 01, 2022</p>
            </div>

            <div className='container mt-5 text-center d-none-mobile'>
                <h1><span className="LemonMilkbold-font blog-hb">Tips for Vacationing with Pets</span></h1>
            </div>

            <div className='container mt-5 text-center d-none-desktop'>
                <h1><span className="LemonMilkbold-font blog-h">Tips for Vacationing with Pets</span></h1>
            </div>
  
            <div className='container' style={{"marginTop": "60px"}}>
                <div className='mb-5 futura-font blog-p'>
                    <p>1. Make sure you have the proper restraints. If you’re flying, make sure you contact your air carrier and get 
                    all of the details of proper crate/carrier for air travel, and of course, don’t forget to pay for their seat! If you’re driving, its important 
                    to keep the pets in a crate, or in a properly designed pet seatbelt or booster seat.</p>
                    <br />
                    <p>2. Check that your hotel, resort, or Airbnb is pet friendly. If they are, make sure there are no breed restrictions 
                    that your pet may fall into.</p>
                    <br />
                    <p>3. Have your pet properly ID’d! When pets are in unfamiliar places, they may spook easily and run off! a collar
                     with an ID tag attached is the quickest way to reunite you with your best friend. Before you leave make sure the ID tag is up to date with your 
                     current phone number. Microchips are helpful too!</p>
                     <br />
                     <p>4. Check with your local pet store for any calming aids to keep your pet comfy during travel. Bring vet paperwork, 
                     vaccination records and any other paperwork you may need to show when you arrive.</p>
                     <br />
                     <p>5. Don’t forget their necessities to make the vacation feel like home. Bringing your pets food and water bowls, 
                     leash and harness, beds, blankets will help them feel more comfortable. Don’t forget their LOTUS PET FOOD in their favorite flavor to keep any 
                     stomach issues from coming up on a sudden switch of food.</p>
                     <br />
                     <p>6. Plan out a pet friendly route, that allows for plenty of stops to stretch the legs, and get a potty break in 
                     when needed</p>
                     <br />
                     <p>7. Be flexible. If your pet is not used to traveling, you may need to make unexpected route changes, or stay changes. 
                     Be prepared to be as flexible as you can to keep you and your pet stress free during your memory making vacation!</p>
                     <br />
                </div>
            </div>
        </div>
    );
  }
}

export default VacationWithPet;