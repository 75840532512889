const api = '/api';
const client = '/client';

const endPoint = {
  adminUserEndpoint: `${api}/account`,
  usersEndpoint: '/user',
  productsEndPoint: {
    admin: `${api}/products`,
    client: `${client}/products`,
  },
  ingredientEndPoint: {
    admin: `${api}/ingredients`,
    client: `${client}/ingredients`,
  },

  reviewEndPoint: {
    admin: `${api}/products-reviews`,
    client: `${client}/products-reviews`,
  },
  quizEndPoint: {
    admin: `${api}/retailer-quiz`,
    client: `${client}/retailer-quiz`,
  },
  quizRedeem: {
    client: `${client}/quiz-redeem`,
  },
  retailStores: {
    admin: `${api}/stores`,
    client: `${client}/stores`,
  },
  filesUploads: {
    admin: `${api}/files`,
    client: `${client}/files`,
  },
  redemptionCheck: {
    admin: `${api}/redemption`,
    client: `${client}/redemption`,
  },
};

module.exports = { endPoint };
