import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';

export default function MainImg(props) {
  return (
    <div className="product-main__main-img-wrapper">
      <img
        onClick={props.openLightBox}
        className="img-fluid product-main__main-img"
        src={props.mainImg}
        alt=""
      />
    </div>
  );
}

/*
  This will only store main component
  onImgChange should effectively change
*/
