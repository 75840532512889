import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

export default function ContactUsTemp(props) {
  return (
    <MDBContainer className="margin-top-adjustment">
      <MDBRow className="row align-items-center justify-content-center pb-5">
        <MDBCol size="12">
          <h1 className="header1 brandon-font text-center ">Contact Us</h1>
          <div className="page-divider mb-3"></div>
        </MDBCol>
        <MDBCol className="text-center futura-font">
          <p>
            Our contact form is currently unavailable. If you need to contact us directly, please
            send us a direct email at{' '}
            <a href="mailto: info@lotuspetfoods.com">info@lotuspetfoods.com</a>
          </p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
