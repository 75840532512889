import React from 'react';

class Media extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="container contact-us-body">
          <div className="row">
            <div className="col contact-us-text">Media</div>
          </div>
          <div className="row page-divider mb-2" />
          <div className="col-12 p-0 mb-5">
            Use the links below to download high resolution images of our products!
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-6 mb-5 col-12">
              <div className="media-title">Dog Regular Bites</div>
              <div className="mt-2 media-title-upload">
                Chicken
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-chicken-5lbs.zip">
                  5 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-chicken-12lbs.zip">
                  12.5 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-chicken-25lbs.zip">
                  25 lbs
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Puppy Chicken
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-puppy-chicken-5lbs.zip">
                  5 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-puppy-chicken-12lbs.zip">
                  12.5 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-puppy-chicken-25lbs.zip">
                  25 lbs
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Senior Chicken
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-senior-chicken-5lbs.zip">
                  5 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-senior-chicken-12lbs.zip">
                  12.5 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-senior-chicken-25lbs.zip">
                  25 lbs
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Turkey
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-turkey-4lbs.zip">
                  4 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-turkey-10lbs.zip">
                  10 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-turkey-20lbs.zip">
                  20 lbs
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Sardine
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-sardine-4lbs.zip">
                  4 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-sardine-10lbs.zip">
                  10 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-sardine-20lbs.zip">
                  20 lbs
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Duck
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-duck-4lbs.zip">
                  4 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-duck-10lbs.zip">
                  10 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-duck-20lbs.zip">
                  20 lbs
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Lamb & Turkey
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-lamb-4lbs.zip">
                  4 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-lamb-10lbs.zip">
                  10 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/regular-bites-lamb-20lbs.zip">
                  20 lbs
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 mb-5 col-12">
              <div className="media-title">Dog Small Bites</div>
              <div className="mt-2 media-title-upload">
                Chicken
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/small-bites-chicken-5lbs.zip">
                  5 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/small-bites-chicken-12lbs.zip">
                  12.5 lbs
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Puppy Chicken
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/small-bites-puppy-chicken-5lbs.zip">
                  5 lbs
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Senior Chicken
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/small-bites-senior-chicken-5lbs.zip">
                  5 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/small-bites-senior-chicken-12lbs.zip">
                  12.5 lbs
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Turkey
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/small-bites-turkey-4lbs.zip">
                  4 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/small-bites-turkey-10lbs.zip">
                  10 lbs
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Sardine
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/small-bites-sardine-4lbs.zip">
                  4 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/small-bites-sardine-10lbs.zip">
                  10 lbs
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Duck
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/small-bites-duck-4lbs.zip">
                  4 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/small-bites-duck-10lbs.zip">
                  10 lbs
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Lamb & Turkey
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/small-bites-lamb-4lbs.zip">
                  4 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/small-bites-lamb-10lbs.zip">
                  10 lbs
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 mb-5 col-12">
              <div className="media-title">Dog Treats</div>
              <div className="mt-2 media-title-upload">
                Chicken
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/chicken-treats-10oz.zip">
                  10 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Sardine & Herring
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/sardine-treats-10oz.zip">
                  10 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Turkey
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/turkey-treats-10oz.zip">
                  10 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Venison
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/venison-treats-10oz.zip">
                  10 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Lamb
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/lamb-treats-10oz.zip">
                  10 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Duck
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/duck-treats-10oz.zip">
                  10 oz
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 mb-5 col-12">
              <div className="media-title">Dog Stews</div>
              <div className="mt-2 media-title-upload">
                Chicken
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/chicken-stew-5oz.zip">
                  5.3 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/chicken-stew-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Pork
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/pork-stew-5oz.zip">
                  5.3 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/pork-stew-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Turkey
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/turkey-stew-5oz.zip">
                  5.3 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/turkey-stew-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Venison
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/venison-stew-5oz.zip">
                  5.3 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/venison-stew-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Beef
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/beef-stew-5oz.zip">
                  5.3 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/beef-stew-12oz.zip">
                  12.5 oz
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 mb-5 col-12">
              <div className="media-title">Dog Loafs</div>
              <div className="mt-2 media-title-upload">
                Chicken
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/chicken-loaf-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Pork
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/pork-loaf-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Turkey
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/turkey-loaf-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Beef
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/beef-loaf-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Duck
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/duck-loaf-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Rabbit
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/rabbit-loaf-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Salmon
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/salmon-loaf-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Sardine
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/sardine-loaf-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Green Tripe
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/tripe-loaf-5oz.zip">
                  5.3 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/tripe-loaf-12oz.zip">
                  12.5 oz
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 mb-5 col-12">
              <div className="media-title">Dog Just Juicy</div>
              <div className="mt-2 media-title-upload">
                Beef
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-dog-beef-5oz.zip">
                  5.3 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-dog-beef-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Pork
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-dog-pork-5oz.zip">
                  5.3 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-dog-pork-12oz.zip">
                  12.5 oz
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 mb-5 col-12">
              <div className="media-title">Cat Kibble</div>
              <div className="mt-2 media-title-upload">
                Chicken
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/chicken-kibble-2lbs.zip">
                  2.2 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/chicken-kibble-5lbs.zip">
                  5 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/chicken-kibble-11lbs.zip">
                  11 lbs
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Sardine
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/sardine-kibble-2lbs.zip">
                  2.2 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/sardine-kibble-5lbs.zip">
                  5 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/sardine-kibble-11lbs.zip">
                  11 lbs
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Duck
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/duck-kibble-2lbs.zip">
                  2.2 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/duck-kibble-5lbs.zip">
                  5 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/duck-kibble-11lbs.zip">
                  11 lbs
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Low Fat Chicken
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/low-fat-chicken-kibble-2lbs.zip">
                  2.2 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/low-fat-chicken-kibble-5lbs.zip">
                  5 lbs
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/low-fat-chicken-kibble-11lbs.zip">
                  11 lbs
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 mb-5 col-12">
              <div className="media-title">Cat Pâtés</div>
              <div className="mt-2 media-title-upload">
                Chicken
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/chicken-pate-2oz.zip">
                  2.75 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/chicken-pate-5oz.zip">
                  5.3 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/chicken-pate-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Pork
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/pork-pate-2oz.zip">
                  2.75 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/pork-pate-5oz.zip">
                  5.3 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/pork-pate-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Turkey
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/turkey-pate-2oz.zip">
                  2.75 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/turkey-pate-5oz.zip">
                  5.3 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/turkey-pate-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Duck
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/duck-pate-2oz.zip">
                  2.75 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/duck-pate-5oz.zip">
                  5.3 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/duck-pate-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Rabbit
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/rabbit-pate-2oz.zip">
                  2.75 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/rabbit-pate-5oz.zip">
                  5.3 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/rabbit-pate-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Salmon
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/salmon-pate-2oz.zip">
                  2.75 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/salmon-pate-5oz.zip">
                  5.3 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/salmon-pate-12oz.zip">
                  12.5 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Sardine
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/sardine-pate-2oz.zip">
                  2.75 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/sardine-pate-5oz.zip">
                  5.3 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/sardine-pate-12oz.zip">
                  12.5 oz
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 mb-5 col-12">
              <div className="media-title">Cat Just Juicy</div>
              <div className="mt-2 media-title-upload">
                Chicken
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-cat-chicken-2oz.zip">
                  2.5 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-cat-chicken-5oz.zip">
                  5.3 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Pork
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-cat-pork-2oz.zip">
                  2.5 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-cat-pork-5oz.zip">
                  5.3 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Turkey
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-cat-turkey-2oz.zip">
                  2.5 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-cat-turkey-5oz.zip">
                  5.3 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Pollock
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-cat-pollock-2oz.zip">
                  2.5 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-cat-pollock-5oz.zip">
                  5.3 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Venison
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-cat-venison-2oz.zip">
                  2.5 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-cat-venison-5oz.zip">
                  5.3 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Salmon
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-cat-salmon-2oz.zip">
                  2.5 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Media/juicy-cat-salmon-5oz.zip">
                  5.3 oz
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 mb-5 col-12">
              <div className="media-title">Cat Raw</div>
              <div className="mt-2 media-title-upload">
                Chicken
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/3.5/chicken.zip">
                  3.5 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/16/chicken.zip">
                  16 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/24/chicken.zip">
                  24 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Pork
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/3.5/pork.zip">
                  3.5 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/16/pork.zip">
                  16 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/24/pork.zip">
                  24 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Turkey
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/3.5/turkey.zip">
                  3.5 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/16/turkey.zip">
                  16 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/24/turkey.zip">
                  24 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Beef
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/3.5/beef.zip">
                  3.5 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/16/beef.zip">
                  16 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/24/beef.zip">
                  24 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Venison
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/3.5/venison.zip">
                  3.5 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/16/venison.zip">
                  16 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/24/venison.zip">
                  24 oz
                </a>
              </div>
              <div className="mt-2 media-title-upload">
                Lamb
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/3.5/lamb.zip">
                  3.5 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/16/lamb.zip">
                  16 oz
                </a>
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/raw/24/lamb.zip">
                  24 oz
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 mb-5 col-12">
              <div className="media-title">Lotus Logo</div>
              <div className="mt-2 media-title-upload">
                Lotus Logos
                <a
                  className="media-flavor ml-4"
                  href="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Media/logos/Lotus+Logo.zip">
                  All Logos
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Media;
