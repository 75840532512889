const ingredientPictures = {
  pork: {
    text: 'Pork Shoulder',
    img: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Ingredients/pork.jpg',
  },
  beef: {
    text: 'Beef Chuck Tenders',
    img: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Ingredients/beef.jpg',
  },
  chicken: {
    text: 'Chicken Breast',
    img: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Ingredients/chicken.jpg',
  },
  clam: {
    text: 'Clam',
    img: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Ingredients/clams.jpg',
  },
  duck: {
    text: 'Ground Duck',
    img: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Ingredients/duck.jpg',
  },
  lamb: {
    text: 'Ground Lamb',
    img: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Ingredients/lamb.jpg',
  },
  pollock: {
    text: 'Pollock Filet',
    img: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Ingredients/pollock.jpg',
  },
  salmon: {
    text: 'Salmon Filet',
    img: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Ingredients/salmon.jpg',
  },
  rabbit: {
    text: 'Ground Rabbit',
    img: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Ingredients/rabbit.jpg',
  },
  sardine: {
    text: 'Whole Sardine',
    img: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Ingredients/sardine.jpg',
  },
  turkey: {
    text: 'Turkey Breast',
    img: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Ingredients/turkey.jpg',
  },
  venison: {
    text: 'Venison Trim',
    img: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Ingredients/venison.jpg',
  },
};

module.exports = { ingredientPictures };
