import React from 'react';
import LoginComponents from '../ClientInteraction/UserAccForms/SignIn/LoginFields.jsx';

export default function Admin(props) {
  return (
    <div className="container contact-us-body text-center">
      <LoginComponents position={'text-center'} history={props.history} />
    </div>
  );
}
