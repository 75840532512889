/** @exports Popup/Helper */
export default {
  /**
   *
   * @param {String} name
   * @return {String | Null}
   */
  readCookies(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },

  /**
   *
   * @param {String} name
   * @param {String} value
   * @param {Number} days
   * @return {Void}
   */
  createCookie(name, value, days) {
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      const expires = '; expires=' + date.toGMTString();
      document.cookie = name + '=' + value + expires + '; path=/';
    }
  },
};
