import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import StarRatingComponent from 'react-star-rating-component';
import axios from 'axios';
import ScrollAnimation from 'react-animate-on-scroll';

class RegularBites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdult: false,
      showPuppy: false,
      showSenior: false,
      showTurkey: false,
      showSardine: false,
      showDuck: false,
      showLamb: false,
      reviews: {
        adult: {
          total: 0,
          stars: 0,
          average: 0,
        },
        puppy: {
          total: 0,
          stars: 0,
          average: 0,
        },
        senior: {
          total: 0,
          stars: 0,
          average: 0,
        },
        turkey: {
          total: 0,
          stars: 0,
          average: 0,
        },
        sardine: {
          total: 0,
          stars: 0,
          average: 0,
        },
        duck: {
          total: 0,
          stars: 0,
          average: 0,
        },
        lamb: {
          total: 0,
          stars: 0,
          average: 0,
        },
      },
    };
    this.showAdult = this.showAdult.bind(this);
    this.showPuppy = this.showPuppy.bind(this);
    this.showSenior = this.showSenior.bind(this);
    this.showTurkey = this.showTurkey.bind(this);
    this.showSardine = this.showSardine.bind(this);
    this.showDuck = this.showDuck.bind(this);
    this.showLamb = this.showLamb.bind(this);
    this.hide = this.hide.bind(this);
    this.showAdultSizes = this.showAdultSizes.bind(this);
    this.hideAdultSizes = this.hideAdultSizes.bind(this);
    this.showPuppySizes = this.showPuppySizes.bind(this);
    this.hidePuppySizes = this.hidePuppySizes.bind(this);
    this.showSeniorSizes = this.showSeniorSizes.bind(this);
    this.hideSeniorSizes = this.hideSeniorSizes.bind(this);
    this.showTurkeySizes = this.showTurkeySizes.bind(this);
    this.hideTurkeySizes = this.hideTurkeySizes.bind(this);
    this.showSardineSizes = this.showSardineSizes.bind(this);
    this.hideSardineSizes = this.hideSardineSizes.bind(this);
    this.showDuckSizes = this.showDuckSizes.bind(this);
    this.hideDuckSizes = this.hideDuckSizes.bind(this);
    this.showLambSizes = this.showLambSizes.bind(this);
    this.hideLambSizes = this.hideLambSizes.bind(this);
  }

  componentWillMount() {
    axios.post('/getallreviews').then((res) => {
      const reviews = {
        adult: {
          total: 0,
          stars: 0,
          average: 0,
        },
        puppy: {
          total: 0,
          stars: 0,
          average: 0,
        },
        senior: {
          total: 0,
          stars: 0,
          average: 0,
        },
        turkey: {
          total: 0,
          stars: 0,
          average: 0,
        },
        sardine: {
          total: 0,
          stars: 0,
          average: 0,
        },
        duck: {
          total: 0,
          stars: 0,
          average: 0,
        },
        lamb: {
          total: 0,
          stars: 0,
          average: 0,
        },
      };

      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].product_id === 1) {
          reviews.adult.total++;
          reviews.adult.stars += res.data[i].stars;
          reviews.adult.average = reviews.adult.stars / reviews.adult.total;
        }

        if (res.data[i].product_id === 2) {
          reviews.puppy.total++;
          reviews.puppy.stars += res.data[i].stars;
          reviews.puppy.average = reviews.puppy.stars / reviews.puppy.total;
        }

        if (res.data[i].product_id === 3) {
          reviews.senior.total++;
          reviews.senior.stars += res.data[i].stars;
          reviews.senior.average = reviews.senior.stars / reviews.senior.total;
        }

        if (res.data[i].product_id === 4) {
          reviews.duck.total++;
          reviews.duck.stars += res.data[i].stars;
          reviews.duck.average = reviews.duck.stars / reviews.duck.total;
        }

        if (res.data[i].product_id === 5) {
          reviews.lamb.total++;
          reviews.lamb.stars += res.data[i].stars;
          reviews.lamb.average = reviews.lamb.stars / reviews.lamb.total;
        }

        if (res.data[i].product_id === 6) {
          reviews.sardine.total++;
          reviews.sardine.stars += res.data[i].stars;
          reviews.sardine.average = reviews.sardine.stars / reviews.sardine.total;
        }

        if (res.data[i].product_id === 7) {
          reviews.turkey.total++;
          reviews.turkey.stars += res.data[i].stars;
          reviews.turkey.average = reviews.turkey.stars / reviews.turkey.total;
        }
      }

      this.setState({
        reviews: reviews,
      });
    });
  }

  showAdult() {
    this.setState({
      showAdult: true,
    });
  }

  showPuppy() {
    this.setState({
      showPuppy: true,
    });
  }

  showSenior() {
    this.setState({
      showSenior: true,
    });
  }

  showTurkey() {
    this.setState({
      showTurkey: true,
    });
  }

  showSardine() {
    this.setState({
      showSardine: true,
    });
  }

  showDuck() {
    this.setState({
      showDuck: true,
    });
  }

  showLamb() {
    this.setState({
      showLamb: true,
    });
  }

  hide() {
    this.setState({
      showAdult: false,
      showPuppy: false,
      showSenior: false,
      showTurkey: false,
      showSardine: false,
      showDuck: false,
      showLamb: false,
    });
  }

  showAdultSizes(e) {
    $('#regular-chicken-adult-name, #regular-chicken-adult-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hideAdultSizes(e) {
    $('#regular-chicken-adult-name, #regular-chicken-adult-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  showPuppySizes(e) {
    $('#regular-chicken-puppy-name, #regular-chicken-puppy-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hidePuppySizes(e) {
    $('#regular-chicken-puppy-name, #regular-chicken-puppy-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  showSeniorSizes(e) {
    $('#regular-chicken-senior-name, #regular-chicken-senior-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hideSeniorSizes(e) {
    $('#regular-chicken-senior-name, #regular-chicken-senior-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  showTurkeySizes(e) {
    $('#regular-turkey-name, #regular-turkey-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hideTurkeySizes(e) {
    $('#regular-turkey-name, #regular-turkey-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  showSardineSizes(e) {
    $('#regular-sardine-name, #regular-sardine-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hideSardineSizes(e) {
    $('#regular-sardine-name, #regular-sardine-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  showDuckSizes(e) {
    $('#regular-duck-name, #regular-duck-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hideDuckSizes(e) {
    $('#regular-duck-name, #regular-duck-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  showLambSizes(e) {
    $('#regular-lamb-name, #regular-lamb-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hideLambSizes(e) {
    $('#regular-lamb-name, #regular-lamb-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <img
          className="banner img-fluid w-100"
          src={
            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Regular+Bites/dog-regular-bites-banner.png'
          }
        />
        <div className="container regular-bites-container">
          <div className="row">
            <div className="col dog-food-text regular-bites">REGULAR BITES</div>
          </div>
          <div className="row">
            <div className="col dog-food-text description">
              Lotus Oven-Baked kibble for dogs is made by a process so uncommonly wholesome it has
              an “®” after its name. Itʼs called Baked-In Goodness®(see?), and itʼs the big reason
              Lotus tastes delicious and is closer to homemade. Oven-baking means Lotus Oven-Baked
              kibble is less processed, has less starch, has more fresh meats, fish and poultry than
              most other kibbles, and hey, it just plain tastes better. Itʼs never extruded like
              most other dog foods. What the heck is extrusion, you say? Itʼs the process of using
              heat and high pressure (three times atmospheric pressure!) to form kibble in dies.
              Then theyʼre sprayed with “flavor” and fats. Plastic is often formed by extrusion,
              too. Creepy, right? Good thing someone (hint, hint) is committed to always oven-baking
              wholesome food for your dog.
            </div>
          </div>
          <ScrollAnimation animateOnce={true} animateIn="slideInUp">
            <div className="row dog-food-row-1">
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/dog/regular-bites/Chicken%20Adult-6"
                  href="/product-view/dog/regular-bites/Chicken%20Adult-6">
                  <div
                    className="img-gif-container"
                    onMouseEnter={this.showAdult}
                    onMouseLeave={this.hide}>
                    <div>
                      <img
                        className="img-fluid"
                        onMouseEnter={this.showAdult}
                        onMouseLeave={this.hide}
                        src={
                          'https://lotus-pet-foods-product-images.s3-us-west-1.amazonaws.com/1-chicken-5lbs-1920-01-320-02-17-2020.png'
                        }
                      />
                    </div>
                    {this.state.showAdult && (
                      <div className="gif-container" onMouseEnter={this.showAdult}>
                        <img
                          className="img-fluid"
                          onMouseEnter={this.showAdult}
                          src={
                            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Regular+Bites/adult-min.gif'
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="regular-chicken d-flex justify-content-center flex-column"
                    onMouseEnter={this.showAdultSizes}
                    onMouseLeave={this.hideAdultSizes}>
                    <div className="recipe-name" id="regular-chicken-adult-name">
                      CHICKEN RECIPE
                    </div>
                    <div className="regular-bites-sizes" id="regular-chicken-adult-sizes">
                      5 lbs | 12.5 lbs | 25 lbs
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.adult.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.adult.total}</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/dog/regular-bites/Chicken%20Puppy-4"
                  href="/product-view/dog/regular-bites/Chicken%20Puppy-4">
                  <div
                    className="img-gif-container"
                    onMouseEnter={this.showPuppy}
                    onMouseLeave={this.hide}>
                    <div>
                      <img
                        className="img-fluid"
                        onMouseEnter={this.showPuppy}
                        onMouseLeave={this.hide}
                        src={
                          'https://lotus-pet-foods-product-images.s3-us-west-1.amazonaws.com/1-puppy-5lbs-1920-01-320-02-17-2020.png'
                        }
                      />
                    </div>
                    {this.state.showPuppy && (
                      <div className="puppy-gif-container" onMouseEnter={this.showPuppy}>
                        <img
                          className="img-fluid"
                          onMouseEnter={this.showPuppy}
                          src={
                            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Regular+Bites/puppy-min.gif'
                          }
                        />
                      </div>
                    )}
                    <img
                      className="puppy-icon"
                      src={
                        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/puppy-icon.png'
                      }
                    />
                  </div>
                  <div
                    className="regular-puppy d-flex justify-content-center flex-column"
                    onMouseEnter={this.showPuppySizes}
                    onMouseLeave={this.hidePuppySizes}>
                    <div className="recipe-name" id="regular-chicken-puppy-name">
                      CHICKEN RECIPE
                    </div>
                    <div className="regular-bites-sizes" id="regular-chicken-puppy-sizes">
                      5 lbs | 12.5 lbs | 25 lbs
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.puppy.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.puppy.total}</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/dog/regular-bites/Chicken%20Senior-53"
                  href="/product-view/dog/regular-bites/Chicken%20Senior-53">
                  <div
                    className="img-gif-container"
                    onMouseEnter={this.showSenior}
                    onMouseLeave={this.hide}>
                    <div>
                      <img
                        className="img-fluid"
                        onMouseEnter={this.showSenior}
                        onMouseLeave={this.hide}
                        src={
                          'https://lotus-pet-foods-product-images.s3-us-west-1.amazonaws.com/1-senior-5lbs-1920-01-320-02-18-2020.png'
                        }
                      />
                    </div>
                    {this.state.showSenior && (
                      <div className="gif-container" onMouseEnter={this.showSenior}>
                        <img
                          className="img-fluid"
                          onMouseEnter={this.showSenior}
                          src={
                            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Regular+Bites/senior-min.gif'
                          }
                        />
                      </div>
                    )}
                    <img
                      className="puppy-icon"
                      src={
                        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/senior-icon.png'
                      }
                    />
                  </div>
                  <div
                    className="regular-senior d-flex justify-content-center flex-column"
                    onMouseEnter={this.showSeniorSizes}
                    onMouseLeave={this.hideSeniorSizes}>
                    <div className="recipe-name" id="regular-chicken-senior-name">
                      CHICKEN RECIPE
                    </div>
                    <div className="regular-bites-sizes" id="regular-chicken-senior-sizes">
                      5 lbs | 12.5 lbs | 25 lbs
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.senior.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.senior.total}</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/dog/regular-bites/Turkey-13"
                  href="/product-view/dog/regular-bites/Turkey-13">
                  <div
                    className="img-gif-container"
                    onMouseEnter={this.showTurkey}
                    onMouseLeave={this.hide}>
                    <div>
                      <img
                        className="img-fluid"
                        src={
                          'https://lotus-pet-foods-product-images.s3-us-west-1.amazonaws.com/1-turkey-4lbs-1920-01-320-02-17-2020.png'
                        }
                      />
                    </div>
                    {this.state.showTurkey && (
                      <div className="gif-container">
                        <img
                          className="img-fluid"
                          src={
                            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Regular+Bites/turkey-min.gif'
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="regular-turkey d-flex justify-content-center flex-column"
                    onMouseEnter={this.showTurkeySizes}
                    onMouseLeave={this.hideTurkeySizes}>
                    <div className="recipe-name" id="regular-turkey-name">
                      TURKEY RECIPE
                    </div>
                    <div className="regular-bites-sizes" id="regular-turkey-sizes">
                      4 lbs | 10 lbs | 20 lbs
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.turkey.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.turkey.total}</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/dog/regular-bites/Sardine-11"
                  href="/product-view/dog/regular-bites/Sardine-11">
                  <div
                    className="img-gif-container"
                    onMouseEnter={this.showSardine}
                    onMouseLeave={this.hide}>
                    <div>
                      <img
                        className="img-fluid"
                        src={
                          'https://lotus-pet-foods-product-images.s3-us-west-1.amazonaws.com/1-fish-4lbs-1920-01-320-02-17-2020.png'
                        }
                      />
                    </div>
                    {this.state.showSardine && (
                      <div className="gif-container">
                        <img
                          className="img-fluid"
                          src={
                            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Regular+Bites/sardine-min.gif'
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="regular-sardine d-flex justify-content-center flex-column"
                    onMouseEnter={this.showSardineSizes}
                    onMouseLeave={this.hideSardineSizes}>
                    <div className="recipe-name" id="regular-sardine-name">
                      SARDINE RECIPE
                    </div>
                    <div className="regular-bites-sizes" id="regular-sardine-sizes">
                      4 lbs | 10 lbs | 20 lbs
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.sardine.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.sardine.total}</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/dog/regular-bites/Duck-20"
                  href="/product-view/dog/regular-bites/Duck-20">
                  <div
                    className="img-gif-container"
                    onMouseEnter={this.showDuck}
                    onMouseLeave={this.hide}>
                    <div>
                      <img
                        className="img-fluid"
                        src={
                          'https://lotus-pet-foods-product-images.s3-us-west-1.amazonaws.com/1-duck-4lbs-1920-01-320-02-17-2020.png'
                        }
                      />
                    </div>
                    {this.state.showDuck && (
                      <div className="gif-container">
                        <img
                          className="img-fluid"
                          src={
                            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Regular+Bites/duck-min.gif'
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="regular-duck d-flex justify-content-center flex-column"
                    onMouseEnter={this.showDuckSizes}
                    onMouseLeave={this.hideDuckSizes}>
                    <div className="recipe-name" id="regular-duck-name">
                      DUCK RECIPE
                    </div>
                    <div className="regular-bites-sizes" id="regular-duck-sizes">
                      4 lbs | 10 lbs | 20 lbs
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.duck.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.duck.total}</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/dog/regular-bites/Lamb-5"
                  href="/product-view/dog/regular-bites/Lamb-5">
                  <div
                    className="img-gif-container"
                    onMouseEnter={this.showLamb}
                    onMouseLeave={this.hide}>
                    <div>
                      <img
                        className="img-fluid"
                        src={
                          'https://lotus-pet-foods-product-images.s3-us-west-1.amazonaws.com/1-lamb-4lbs-1920-320-02-17-2020.png'
                        }
                      />
                    </div>
                    {this.state.showLamb && (
                      <div className="gif-container">
                        <img
                          className="img-fluid"
                          src={
                            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Regular+Bites/lamb-min.gif'
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="regular-lamb d-flex justify-content-center flex-column"
                    onMouseEnter={this.showLambSizes}
                    onMouseLeave={this.hideLambSizes}>
                    <div className="recipe-name" id="regular-lamb-name">
                      LAMB RECIPE
                    </div>
                    <div className="regular-bites-sizes" id="regular-lamb-sizes">
                      4 lbs | 10 lbs | 20 lbs
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.lamb.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.lamb.total}</div>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    );
  }
}

export default RegularBites;
