import React from 'react';

const getCorrectGuidelineInfo = (foodType, name) => {
  const info = {
    'Raw Food': {
      reproduction: `Reproduction: Provide increasing amounts of food the latter half of gestation. 
        The amount may be twice normal intake. Feed to consumption during Lactation.`,
      growth: `Growth: Introduce raw food around the 5th week and provide as much food as kittens will 
        consume from weaning till full grown. Always provide a source of clean fresh water. Lotus ${foodType} ${name} for cats is 
        formulated to meet the nutritional levels established 
        by the AAFCO (Association of American Feed Control Officials) Cat Food Nutrient Profiles for all life stages.`,
      other:
        'Safe Handling: Lotus Raw recipes for cats contain raw meat. Separate from other foods and wash hands, utensils and surfaces thoroughly.',
    },
    // Dog Raw Food info
    'Raw Foods': {
      reproduction: `Reproduction: Provide increasing amounts of food the latter half of gestation. 
        The amount may be twice normal intake. Feed to consumption during Lactation.`,
      growth: `Growth: Introduce raw food around the 5th week and provide as much food as puppies will consume from weaning till full grown.
         Always provide a source of clean fresh water. Lotus ${foodType} ${name} for dogs is formulated to meet the nutritional levels established 
        by the AAFCO (Association of American Feed Control Officials) Dog Food Nutrient Profiles for all life stages including growth of Large Breed Puppies (70 lb or more as an adult).`,
      other:
        'Safe Handling: Lotus Raw recipes for dogs contain raw meat. Separate from other foods and wash hands, utensils and surfaces thoroughly.',
    },
    'Regular Bites': {
      reproduction: `
        Reproduction: Provide increasing amounts of food the latter half of gestation. The amount may be twice normal intake. 
        Feed to consumption during lactation.`,
      growth:
        'Growth: Introduce food around the 5th week and provide as much food as puppies will consume from weaning until full grown.',
      other: `Lotus Pet Food Oven-Baked Grain Free ${name} for Dogs is formulated to meet the nutritional levels 
        established by the AAFCO (Association of American Feed Control Officials) 
        Dog Nutrient Profiles for all life stages, including the growth of large breed dogs (70 lb or more as an adult).`,
    },
    'Small Bites': {
      reproduction: `Reproduction: Provide increasing amounts of food the latter half of gestation. 
        The amount may be twice normal intake. Feed to consumption during lactation.`,
      growth:
        'Growth: Introduce food around the 5th week and provide as much food as puppies will consume from weaning until full grown.',
      other: `Lotus Pet Food Oven-Baked Grain Free ${name} for Dogs is formulated to meet the nutritional levels established 
        by the AAFCO (Association of American Feed Control Officials) 
        Dog Nutrient Profiles for all life stages, including the growth of large breed dogs (70 lb or more as an adult).`,
    },
    Treats: {
      other:
        'Feed as a treat only. Always provide a source of clean fresh water. Store in a cool, dry place.',
    },
    Stew: {
      reproduction: `Reproduction: Provide increasing amounts of food the latter half of gestation. 
        The amount may be twice normal intake. Feed to consumption during lactation.`,
      growth:
        'Introduce food around the 5th week and provide as much food as puppies will consume from weaning until full grown.',
      other: `Lotus ${name} for Dogs is formulated to meet the nutritional levels established by the AAFCO (Association of American Feed Control Officials)
        Dog Nutrient Profiles for all life stages, 
        including the growth of large breed dogs (70 lb or more as an adult).`,
    },
    Loaf: {
      reproduction: `Reproduction: Provide increasing amounts of food the latter half of gestation. 
      The amount may be twice normal intake. Feed to consumption during lactation.`,
      growth:
        'Introduce food around the 5th week and provide as much food as puppies will consume from weaning until full grown.',
      other: `Lotus ${name} Loafs for Dogs is formulated to meet the nutritional levels established by the AAFCO (Association of American 
          Feed Control Officials) Dog Nutrient Profiles for all life stages, including the growth of large breed dogs (70 lb or more as an adult).`,
    },
    Juicy: {
      reproduction: `Reproduction: Provide increasing amounts of food the latter half of gestation. 
        The amount may be twice normal intake. Feed to consumption during lactation.`,
      other: `Lotus ${name} cat Just Juicy is formulated to meet the nutritional levels established by the AAFCO (Association of American Feed Control Officials) 
        Cat Food Nutrient Profiles for all life stages.`,
    },
    'Just Juicy': {
      reproduction: `Reproduction: Provide increasing amounts of food the latter half of gestation. 
      The amount may be twice normal intake. Feed to consumption during lactation.`,
      growth:
        'Growth: Introduce food around the 5th week and provide as much food as puppies will consume from weaning until full grown.',
      other: `Lotus ${name} Just Juicy for Dogs is formulated to meet the nutritional levels established by the AAFCO (Association of 
        American Feed Control Officials) Dog Nutrient Profiles for all life stages, 
        including the growth of large breed dogs (70 lb or more as an adult).`,
    },
    Pate: {
      reproduction: `Reproduction: Provide increasing amounts of food the latter half of gestation. 
      The amount may be twice normal intake. Feed to consumption during lactation.`,
      other: `Lotus ${name} Pate is formulated to meet the nutritional levels established by the AAFCO (Association of American Feed Control Officials) 
      Cat Food Nutrient Profiles for all life stages.`,
    },
    Kibble: {
      reproduction: `Reproduction: Provide increasing amounts of food the latter half of gestation. 
        The amount may be twice normal intake. Feed to consumption during lactation.`,
      growth:
        'Growth: Introduce dry food around the 5th week and provide as much food as kittens will consume from weaning until full grown.',
      other: `Lotus baked grain free ${name} for cat is formulated to meet the nutritional levels established by the AAFCO (Association of American Feed Control Officials) 
        Cat Food Nutrient Profiles for all life stages.`,
    },
    Topper: {
      'Feeding Guidelines': 'Feeding Guidelines: Provide up to 1 oz per 7 lbs of body weight daily',
      other: 'Always provide a source of clean fresh water. Lotus Raw Beef Tripe Entree for Dogs and Cats is intended for intermittant or supplemental feeding only.',
    },
    Toppers: {
      'Feeding Guidelines': 'Feeding Guidelines: Provide up to 1 oz per 7 lbs of body weight daily',
      other: 'Always provide a source of clean fresh water. Lotus Raw Beef Tripe Entree for Dogs and Cats is intended for intermittant or supplemental feeding only.',
    },
  };
  return info[foodType];
};

const renderCorrectTxtInfo = (foodType, name) => {
  const guidelineTextInfo = getCorrectGuidelineInfo(foodType, name);
  return Object.keys(guidelineTextInfo).map((item, key) => {
    return (
      <p key={key} className="product-main__text text-dark my-3">
        {guidelineTextInfo[item]}
      </p>
    );
  });
};

const modifyFeedingGuidelineObj = (guideline, pet) => {
  return Object.keys(guideline).map((size) => {
    const tempObj = {
      cols: [{ label: `${pet} Weight (lb)` }],
      rows: [{ heading0: 'Container Per Day' }],
    };
    Object.keys(guideline[size]).forEach((innerKeys, i) => {
      if (tempObj.cols[i + 1] === undefined) {
        tempObj.cols.push({ label: innerKeys });
      }
      tempObj.rows[0][`heading${i + 1}`] = guideline[size][innerKeys];
    });
    const sizeObj = {};
    sizeObj.size = size;
    sizeObj.data = tempObj;
    return sizeObj;
  });
};
module.exports = { renderCorrectTxtInfo, modifyFeedingGuidelineObj };
