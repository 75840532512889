import React from 'react';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: null,
      total: null,
      isDesktop: null,
      shippingAddress: {
        name: '',
        address: '',
        city: '',
        state: '',
        zip: ''
      },
      billingAddress: {
        name: '',
        address: '',
        city: '',
        state: '',
        zip: ''
      },
      sameAddress: false,
      shippingOptions: [],
      selectedShipping: null,
      shippingCost: 0,
      tax: 0,
      taxRate: null,
      subtotal: 0
    };
    this.updatePredicate = this.updatePredicate.bind(this);
    this.submit = this.submit.bind(this);
    this.sameAddress = this.sameAddress.bind(this);
    this.calculateShipping = this.calculateShipping.bind(this);
    this.selectShipping = this.selectShipping.bind(this);
    this.calculateTax = this.calculateTax.bind(this);
  }

  componentWillMount() {
    if (localStorage.cart) {
      const cart = JSON.parse(localStorage.cart);
      let total = 0;

      for (let i = 0; i < cart.length; i++) {
        total += cart[i].price * cart[i].quantity;
      }

      this.setState({
        cart: cart,
        subtotal: total.toFixed(2)
      });
    } else {
      this.setState({
        cart: []
      });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.updatePredicate();
    window.addEventListener('resize', this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 768 });
  }

  calculateShipping(e) {
    const street1 = $(`#shippingaddress`).val();
    const city = $(`#shippingcity`).val();
    const state = $(`#shippingstate`).val();
    const zip = $(`#shippingzip`).val();
    let ready = false;
    let quantity = 0;

    if (localStorage.cart) {
      const cart = JSON.parse(localStorage.cart);

      for (let i = 0; i < cart.length; i++) {
        quantity += parseInt(cart[i].quantity);
      }
    }

    const addressTo = {
      street1: street1,
      city: city,
      state: state,
      zip: zip,
      country: 'US'
    };

    const data = {
      quantity: quantity,
      addressTo: addressTo
    };

    if (street1.length > 0 && city.length > 0 && state.length > 0 && zip.length > 4) {
      ready = true;
    }

    if (ready) {
      axios.post('/calculateshipping', data).then(res => {
        //console.log(res);
        const shippingOptions = [];

        for (let i = 0; i < res.data.rates.length; i++) {
          if (
            (res.data.rates[i].servicelevel.token === 'fedex_ground' && res.data.rates[i].servicelevel.terms === 'ONERATE') ||
            (res.data.rates[i].servicelevel.token === 'fedex_express_saver' && res.data.rates[i].servicelevel.terms === 'ONERATE') ||
            (res.data.rates[i].servicelevel.token === 'fedex_2_day' && res.data.rates[i].servicelevel.terms === 'ONERATE') ||
            (res.data.rates[i].servicelevel.token === 'fedex_2_day_am' && res.data.rates[i].servicelevel.terms === 'ONERATE') ||
            (res.data.rates[i].servicelevel.token === 'fedex_standard_overnight' && res.data.rates[i].servicelevel.terms === 'ONERATE')
          ) {
            shippingOptions.push(res.data.rates[i]);
          }
        }
        this.setState({ shippingOptions: shippingOptions });
      });
    }
  }

  calculateTax() {
    const postalCode = $(`#shippingzip`).val();
    const country = 'US';
    let ready = false;

    if (postalCode.length > 4) {
      ready = true;
    }

    if (ready) {
      axios
        .get(`https://sandbox-rest.avatax.com/api/v2/taxrates/byaddress?postalCode=${postalCode}&country=${country}`, {
          headers: { Authorization: 'Basic YmF0Y2hzdmNAY2xvdWRsZW5kaW5nOiMhMTEwMDExISNSYW0=' }
        })
        .then(res => {
          this.setState({ taxRate: res.data.totalRate * 100, tax: Math.round(this.state.subtotal * res.data.totalRate * 100) / 100 });
        });
    }
  }

  submit(e) {
    e.preventDefault();

    $('.pay-button')
      .prop('disabled', true)
      .css('background-color', '#676767')
      .css('cursor', 'default');

    const email = $(`#email`).val();
    const shippingFirstName = $(`#shippingfirstname`).val();
    const shippingLastName = $(`#shippinglastname`).val();
    const shippingAddress = $(`#shippingaddress`).val();
    const shippingCity = $(`#shippingcity`).val();
    const shippingState = $(`#shippingstate`).val();
    const shippingZip = $(`#shippingzip`).val();
    const phone = $(`#phone`).val();
    const errors = [];
    let billingFirstName, billingLastName, billingAddress, billingCity, billingState, billingZip;
    const items = [];
    let quantity = 0;

    if (localStorage.cart) {
      const cart = JSON.parse(localStorage.cart);

      for (let i = 0; i < cart.length; i++) {
        quantity += parseInt(cart[i].quantity);
      }
    }

    for (let i = 0; i < this.state.cart.length; i++) {
      const item = {};

      item.color = this.state.cart[i].selectedColor;
      item.size = this.state.cart[i].selectedSize;
      item.productName = this.state.cart[i].name;
      item.productSKU = this.state.cart[i].selectedSKU;
      item.unitPrice = this.state.cart[i].price;
      item.quantity = this.state.cart[i].quantity.toString();
      item.totalBeforeTax = (this.state.cart[i].price * this.state.cart[i].quantity).toFixed(2);
      item.itemTaxAmount = (Math.round(item.totalBeforeTax * (this.state.taxRate * 0.01) * 100) / 100).toFixed(2);

      items.push(item);
    }

    if (this.state.sameAddress) {
      billingFirstName = $(`#shippingfirstname`).val();
      billingLastName = $(`#shippinglastname`).val();
      billingAddress = $(`#shippingaddress`).val();
      billingCity = $(`#shippingcity`).val();
      billingState = $(`#shippingstate`).val();
      billingZip = $(`#shippingzip`).val();
    } else {
      billingFirstName = $(`#billingfirstname`).val();
      billingLastName = $(`#billinglastname`).val();
      billingAddress = $(`#billingaddress`).val();
      billingCity = $(`#billingcity`).val();
      billingState = $(`#billingstate`).val();
      billingZip = $(`#billingzip`).val();
    }

    $('#shippingfirstname, #shippinglastname, #shippingaddress, #shippingcity, #shippingzip').css('border-color', '#ced4da');
    $('#billingfirstname, #billinglastname, #billingaddress, #billingcity, #billingzip, #email, #phone, #cardnumber, #cardcvc, #cardexpiry').css('border-color', '#ced4da');

    if (email === '') {
      errors.push('Email');
      $('#email').css('border-color', 'red');
    }

    if (phone === '') {
      errors.push('Phone Number');
      $('#phone').css('border-color', 'red');
    }

    if (shippingFirstName === '' || shippingLastName === '' || shippingAddress === '' || shippingCity === '' || shippingZip === '') {
      if (shippingFirstName === '') {
        $('#shippingfirstname').css('border-color', 'red');
      }
      if (shippingLastName === '') {
        $('#shippinglastname').css('border-color', 'red');
      }
      if (shippingAddress === '') {
        $('#shippingaddress').css('border-color', 'red');
      }
      if (shippingCity === '') {
        $('#shippingcity').css('border-color', 'red');
      }
      if (shippingZip === '') {
        $('#shippingzip').css('border-color', 'red');
      }

      errors.push('Shipping Address');
    }

    if (billingFirstName === '' || billingLastName === '' || billingAddress === '' || billingCity === '' || billingZip === '') {
      if (billingFirstName === '') {
        $('#billingfirstname').css('border-color', 'red');
      }
      if (billingLastName === '') {
        $('#billinglastname').css('border-color', 'red');
      }
      if (billingAddress === '') {
        $('#billingaddress').css('border-color', 'red');
      }
      if (billingCity === '') {
        $('#billingcity').css('border-color', 'red');
      }
      if (billingZip === '') {
        $('#billingzip').css('border-color', 'red');
      }

      errors.push('Billing Address');
    }

    if (this.state.shippingCost === 0) {
      errors.push('Shipping Option');
    }

    if (errors.length > 0) {
      swal.fire({
        title: `Please complete the following:`,
        text: errors.join(', '),
        type: 'error'
      });

      $('.pay-button')
        .prop('disabled', false)
        .css('background-color', 'black')
        .css('cursor', 'pointer');
    }

    const data = {
      email: email,
      shippingFirstName: shippingFirstName,
      shippingLastName: shippingLastName,
      shippingStreet: shippingAddress,
      shippingCity: shippingCity,
      shippingState: shippingState,
      shippingZip: shippingZip,
      billingFirstName: billingFirstName,
      billingLastName: billingLastName,
      billingStreet: billingAddress,
      billingCity: billingCity,
      billingState: billingState,
      billingZip: billingZip,
      items: items,
      phone: phone,
      quantity: quantity,
      selectedShipping: this.state.selectedShipping,
      salesTax: this.state.taxRate.toString(),
      totalTaxAmount: this.state.tax.toString(),
      shippingCost: this.state.shippingCost,
      orderTotal: (Math.round((parseFloat(this.state.tax) + parseFloat(this.state.shippingCost) + parseFloat(this.state.subtotal)) * 100) / 100).toFixed(2)
    };

    if (errors.length === 0) {
      this.props.stripe
        .createToken({
          name: `${billingFirstName} ${billingLastName}`,
          address_line1: billingAddress,
          address_city: billingCity,
          address_state: billingState,
          address_zip: billingZip,
          address_country: 'US',
        })
        .then(({ token, error }) => {
          let ready = true;
          if (error) {
            console.log({error});
            ready = false;
            if (error.code === 'incorrect_number') {
              $('#cardnumber').css('border-color', 'red');
              swal.fire({
                title: 'Your card number is incorrect.',
                icon: 'error',
              });
            }
            
            if (error.code === 'incomplete_number') {
              $('#cardnumber').css('border-color', 'red');
              swal.fire({
                title: 'Your card number is incomplete.',
                icon: 'error',
              });
            }

            if (error.code === 'incomplete_expiry') {
              $('#cardexpiry').css('border-color', 'red');
              swal.fire({
                title: 'Please enter a valid expiration date.',
                icon: 'error',
              });
            }

            if (error.code === 'incomplete_cvc') {
              $('#cardcvc').css('border-color', 'red');
              swal.fire({
                title: 'Please enter a valid CVC.',
                icon: 'error',
              });
            }

            $('.pay-button')
              .prop('disabled', false)
              .css('background-color', 'black')
              .css('cursor', 'pointer');
          }

          token.total = parseFloat(parseFloat(this.state.subtotal) + parseFloat(this.state.shippingCost) + parseFloat(this.state.tax)).toFixed(2);
          data.token = token;


          if (ready) {
            swal.fire({
              title: `You will be charged $${(parseFloat(this.state.subtotal) + parseFloat(this.state.shippingCost) + parseFloat(this.state.tax)).toFixed(2)}.`,
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33'
            }).then(result => {
              if (result.value) {
                console.log(data);
                axios.post('/charge', data).then((res) => {
                    console.log(res); //test
                    if (res.data.code !== null){
                      swal.fire({
                        title: 'An error occurs, please try again',
                        icon: 'error',
                      });
                    $('.pay-button')
                      .prop('disabled', false)
                      .css('background-color', 'black')
                      .css('cursor', 'pointer');
                    }
                    if (res.data.body.order_status == "PAID") {
                    swal.fire({
                      title: 'Payment successful!',
                      html: `<div>We will email you when the item is shipped.</div>`,
                      icon: 'success'
                    }).then(() => {
                      localStorage.cart = '[]';
                      this.setState({
                        cart: []
                      });
                    });
                  }  else {
                    swal.fire({
                      title: 'An error occurs, please try again or contact us',
                      icon: 'error',
                    });
                  $('.pay-button')
                    .prop('disabled', false)
                    .css('background-color', 'black')
                    .css('cursor', 'pointer');
                  }
                });
              } else {
                $('.pay-button')
                  .prop('disabled', false)
                  .css('background-color', 'black')
                  .css('cursor', 'pointer');
              }
            });
          }
        });
    }
  }

  sameAddress() {
    const newBool = !this.state.sameAddress;

    this.setState({
      sameAddress: newBool
    });
  }

  selectShipping(e) {
    const index = e.target.id.replace('shippingoption', '');
    const selectedShipping = this.state.shippingOptions[index].servicelevel.name;

    $('.shipping-option').prop('checked', false);
    $(`#${e.target.id}`).prop('checked', true);
    this.setState({
      shippingCost: (Math.round(e.target.value * 100) / 100).toFixed(2),
      selectedShipping: selectedShipping
    });
  }

  render() {
    return (
      <div>
        <div className='container contact-us-body'>
          <div className='row'>
            <div className='col contact-us-text'>Checkout</div>
          </div>
          <div className='row page-divider mb-5' />
          {this.state.cart.length === 0 ? <div>No items in your cart!</div> : null}
          {this.state.cart.length > 0 && (
            <div className='row'>
              <div className='col-md-9 col-12 pr-lg-5'>
                <div className='checkout-subtitle'>Email</div>
                <div className='row mt-2'>
                  <div className='col-lg-6 col-12 mt-3'>
                    <div className='form-group'>
                      <input type='text' className='form-control' id='email' />
                    </div>
                  </div>
                </div>
                <div className='checkout-subtitle mt-4'>Phone Number</div>
                <div className='row mt-2'>
                  <div className='col-lg-6 col-12 mt-3'>
                    <div className='form-group'>
                      <input type='text' className='form-control' id='phone' />
                    </div>
                  </div>
                </div>
                <div className='checkout-subtitle mt-4'>Shipping Address</div>
                <div className='row mt-2 d-flex align-content-center justify-content-center'>
                  <div className='col-lg-6 col-12 mt-3'>
                    <div className='form-group'>
                      <label htmlFor='shippingname'>First Name</label>
                      <input type='text' className='form-control' id='shippingfirstname' />
                    </div>
                  </div>
                  <div className='col-lg-6 col-12 mt-3'>
                    <div className='form-group'>
                      <label htmlFor='shippingname'>Last Name</label>
                      <input type='text' className='form-control' id='shippinglastname' />
                    </div>
                  </div>
                </div>
                <div className='row mt-2 d-flex align-content-center justify-content-center'>
                  <div className='col-12 mt-3'>
                    <div className='form-group'>
                      <label htmlFor='shippingaddress'>Address</label>
                      <input type='text' className='form-control' id='shippingaddress' onChange={this.calculateShipping} />
                    </div>
                  </div>
                </div>
                <div className='row mt-2 d-flex align-content-center justify-content-center'>
                  <div className='col-lg-5 col-12 mt-3'>
                    <div className='form-group'>
                      <label htmlFor='shippingaddress'>City</label>
                      <input type='text' className='form-control' id='shippingcity' onChange={this.calculateShipping} />
                    </div>
                  </div>
                  <div className='col-lg-2 col-12 mt-3'>
                    <div className='form-group'>
                      <label htmlFor='shippingaddress'>State</label>
                      <select id='shippingstate' onChange={this.calculateShipping} className='form-control'>
                        <option value='AL'>Alabama</option>
                        <option value='AK'>Alaska</option>
                        <option value='AZ'>Arizona</option>
                        <option value='AR'>Arkansas</option>
                        <option value='CA'>California</option>
                        <option value='CO'>Colorado</option>
                        <option value='CT'>Connecticut</option>
                        <option value='DE'>Delaware</option>
                        <option value='DC'>District Of Columbia</option>
                        <option value='FL'>Florida</option>
                        <option value='GA'>Georgia</option>
                        <option value='HI'>Hawaii</option>
                        <option value='ID'>Idaho</option>
                        <option value='IL'>Illinois</option>
                        <option value='IN'>Indiana</option>
                        <option value='IA'>Iowa</option>
                        <option value='KS'>Kansas</option>
                        <option value='KY'>Kentucky</option>
                        <option value='LA'>Louisiana</option>
                        <option value='ME'>Maine</option>
                        <option value='MD'>Maryland</option>
                        <option value='MA'>Massachusetts</option>
                        <option value='MI'>Michigan</option>
                        <option value='MN'>Minnesota</option>
                        <option value='MS'>Mississippi</option>
                        <option value='MO'>Missouri</option>
                        <option value='MT'>Montana</option>
                        <option value='NE'>Nebraska</option>
                        <option value='NV'>Nevada</option>
                        <option value='NH'>New Hampshire</option>
                        <option value='NJ'>New Jersey</option>
                        <option value='NM'>New Mexico</option>
                        <option value='NY'>New York</option>
                        <option value='NC'>North Carolina</option>
                        <option value='ND'>North Dakota</option>
                        <option value='OH'>Ohio</option>
                        <option value='OK'>Oklahoma</option>
                        <option value='OR'>Oregon</option>
                        <option value='PA'>Pennsylvania</option>
                        <option value='RI'>Rhode Island</option>
                        <option value='SC'>South Carolina</option>
                        <option value='SD'>South Dakota</option>
                        <option value='TN'>Tennessee</option>
                        <option value='TX'>Texas</option>
                        <option value='UT'>Utah</option>
                        <option value='VT'>Vermont</option>
                        <option value='VA'>Virginia</option>
                        <option value='WA'>Washington</option>
                        <option value='WV'>West Virginia</option>
                        <option value='WI'>Wisconsin</option>
                        <option value='WY'>Wyoming</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-lg-5 col-12 mt-3'>
                    <div className='form-group'>
                      <label htmlFor='shippingaddress'>Zip Code</label>
                      <input
                        type='text'
                        className='form-control'
                        id='shippingzip'
                        onChange={() => {
                          this.calculateShipping();
                          this.calculateTax();
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='checkout-subtitle mt-4'>Billing Address</div>
                <div className='form-check mt-1'>
                  <input type='checkbox' id='sameaddresscheck' className='form-check-input' checked={this.state.sameAddress} onClick={this.sameAddress} />
                  <label className='form-check-label' for='sameaddresscheck'>
                    Same as Shipping Address
                  </label>
                </div>
                {!this.state.sameAddress ? (
                  <div>
                    <div className='row mt-2 d-flex align-content-center justify-content-center'>
                      <div className='col-lg-6 col-12 mt-3'>
                        <div className='form-group'>
                          <label htmlFor='billingname'>First Name</label>
                          <input type='text' className='form-control' id='billingfirstname' />
                        </div>
                      </div>
                      <div className='col-lg-6 col-12 mt-3'>
                        <div className='form-group'>
                          <label htmlFor='billingname'>Last Name</label>
                          <input type='text' className='form-control' id='billinglastname' />
                        </div>
                      </div>
                    </div>
                    <div className='row mt-2 d-flex align-content-center justify-content-center'>
                      <div className='col-12 mt-3'>
                        <div className='form-group'>
                          <label htmlFor='billingaddress'>Address</label>
                          <input type='text' className='form-control' id='billingaddress' />
                        </div>
                      </div>
                    </div>
                    <div className='row mt-2 d-flex align-content-center justify-content-center'>
                      <div className='col-lg-5 col-12 mt-3'>
                        <div className='form-group'>
                          <label htmlFor='billingaddress'>City</label>
                          <input type='text' className='form-control' id='billingcity' />
                        </div>
                      </div>
                      <div className='col-lg-2 col-12 mt-3'>
                        <div className='form-group'>
                          <label htmlFor='shippingaddress'>State</label>
                          <select id='shippingstate' onChange={this.calculateShipping} className='form-control'>
                            <option value='AL'>Alabama</option>
                            <option value='AK'>Alaska</option>
                            <option value='AZ'>Arizona</option>
                            <option value='AR'>Arkansas</option>
                            <option value='CA'>California</option>
                            <option value='CO'>Colorado</option>
                            <option value='CT'>Connecticut</option>
                            <option value='DE'>Delaware</option>
                            <option value='DC'>District Of Columbia</option>
                            <option value='FL'>Florida</option>
                            <option value='GA'>Georgia</option>
                            <option value='HI'>Hawaii</option>
                            <option value='ID'>Idaho</option>
                            <option value='IL'>Illinois</option>
                            <option value='IN'>Indiana</option>
                            <option value='IA'>Iowa</option>
                            <option value='KS'>Kansas</option>
                            <option value='KY'>Kentucky</option>
                            <option value='LA'>Louisiana</option>
                            <option value='ME'>Maine</option>
                            <option value='MD'>Maryland</option>
                            <option value='MA'>Massachusetts</option>
                            <option value='MI'>Michigan</option>
                            <option value='MN'>Minnesota</option>
                            <option value='MS'>Mississippi</option>
                            <option value='MO'>Missouri</option>
                            <option value='MT'>Montana</option>
                            <option value='NE'>Nebraska</option>
                            <option value='NV'>Nevada</option>
                            <option value='NH'>New Hampshire</option>
                            <option value='NJ'>New Jersey</option>
                            <option value='NM'>New Mexico</option>
                            <option value='NY'>New York</option>
                            <option value='NC'>North Carolina</option>
                            <option value='ND'>North Dakota</option>
                            <option value='OH'>Ohio</option>
                            <option value='OK'>Oklahoma</option>
                            <option value='OR'>Oregon</option>
                            <option value='PA'>Pennsylvania</option>
                            <option value='RI'>Rhode Island</option>
                            <option value='SC'>South Carolina</option>
                            <option value='SD'>South Dakota</option>
                            <option value='TN'>Tennessee</option>
                            <option value='TX'>Texas</option>
                            <option value='UT'>Utah</option>
                            <option value='VT'>Vermont</option>
                            <option value='VA'>Virginia</option>
                            <option value='WA'>Washington</option>
                            <option value='WV'>West Virginia</option>
                            <option value='WI'>Wisconsin</option>
                            <option value='WY'>Wyoming</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-lg-5 col-12 mt-3'>
                        <div className='form-group'>
                          <label htmlFor='billingaddress'>Zip Code</label>
                          <input type='text' className='form-control' id='billingzip' />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.shippingOptions.length > 0 ? (
                  <div>
                    <div className='checkout-subtitle mt-4'>
                      Shipping
                      <p className='mb-0' style={{ fontFamily: 'Futura', fontSize: '16px' }}>
                        <i>Customers are responsible for returns</i>
                      </p>
                    </div>
                    <div className='row mt-2 d-flex align-content-center justify-content-center'>
                      {this.state.shippingOptions.map((option, i) => {
                        return (
                          <div className='col-sm-12 col-10 mt-3'>
                            <input class='form-check-input shipping-option' onChange={this.selectShipping} type='radio' id={`shippingoption${i}`} value={option.amount} />
                            <label class='form-check-label' for={`shippingoption${i}`}>
                              ${option.amount} - {`${option.provider} ${option.servicelevel.name}`}
                              <br />
                              Estimated arrival: Within {option.estimated_days} business days from shipping
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                {window.innerWidth > 450 ? (
                  <div className='checkout-subtitle mt-4'>
                    Payment
                    <img className='ml-3 card-icon img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/visa.png'} />
                    <img className='ml-3 card-icon img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/master.png'} />
                    <img className='ml-3 card-icon img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/amex.png'} />
                    <img className='ml-3 card-icon img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/discover.png'} />
                    <img className='ml-3 card-icon img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/diners.png'} />
                    <img className='ml-3 card-icon img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/jcb.png'} />
                  </div>
                ) : (
                  <div className='checkout-subtitle mt-4'>
                    Payment
                    <br />
                    <div className='mt-2'>
                      <img className='card-icon img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/visa.png'} />
                      <img className='ml-3 card-icon img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/master.png'} />
                      <img className='ml-3 card-icon img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/amex.png'} />
                      <img className='ml-3 card-icon img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/discover.png'} />
                      <img className='ml-3 card-icon img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/diners.png'} />
                      <img className='ml-3 card-icon img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/jcb.png'} />
                    </div>
                  </div>
                )}
                <div className='row mt-2 d-flex align-content-left justify-content-left'>
                  <div className='col-lg-6 col-12 mt-3'>
                    <div className='form-group'>
                      <div className='col-lg-8 col-12 px-0'>
                        <label htmlFor='ccinfo'>Card Number</label>
                        <CardNumberElement style={{ base: { fontSize: '18px', fontFamily: 'Futura' } }} className='form-control' id='cardnumber' />
                      </div>
                      <div className='col-lg-6 col-12 px-0 mt-3'>
                        <label htmlFor='ccinfo'>Expiration Date</label>
                        <CardExpiryElement style={{ base: { fontSize: '18px', fontFamily: 'Futura' } }} className='form-control' id='cardexpiry' />
                      </div>
                      <div className='col-lg-6 col-12 px-0 mt-3'>
                        <label htmlFor='ccinfo'>CVC</label>
                        <CardCVCElement style={{ base: { fontSize: '18px', fontFamily: 'Futura' } }} className='form-control' id='cardcvc' />
                      </div>
                    </div>
                    <button className='pay-button mt-3' type='submit' onClick={this.submit}>
                      PAY
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-12 pl-lg-5 checkout-items'>
                <div className='col-md-3 col-12 px-0' style={window.innerWidth > 768 ? { position: 'fixed', top: '30%' } : null}>
                  <div className='my-5'>
                    <div className='mt-3 cart-subtitle'>Subtotal: {`$${this.state.subtotal}`}</div>
                    {this.state.taxRate ? (
                      <div className='mt-3 cart-subtitle'>
                        Tax: {`$${(Math.round(this.state.tax * 100) / 100).toFixed(2)}`} ({this.state.taxRate.toFixed(2)}%)
                      </div>
                    ) : (
                      <div className='mt-3 cart-subtitle'>Tax: {`$0.00`}</div>
                    )}
                    <div className='mt-3 cart-subtitle'>Shipping: {this.state.shippingCost === 0 ? '$0.00' : `$${this.state.shippingCost}`}</div>
                    <div className='total-divider my-3' />
                    <div className='mt-3 checkout-total'>
                      Total: {`$${(Math.round((parseFloat(this.state.tax) + parseFloat(this.state.shippingCost) + parseFloat(this.state.subtotal)) * 100) / 100).toFixed(2)}`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default injectStripe(Checkout);

// import React from 'react';
// import {
//   injectStripe,
//   CardNumberElement,
//   CardExpiryElement,
//   CardCVCElement,
// } from 'react-stripe-elements';
// import axios from 'axios';
// import $ from 'jquery';
// import swal from 'sweetalert2';

// class Checkout extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       cart: null,
//       total: null,
//       isDesktop: null,
//       shippingAddress: {
//         name: '',
//         address: '',
//         city: '',
//         state: '',
//         zip: '',
//       },
//       billingAddress: {
//         name: '',
//         address: '',
//         city: '',
//         state: '',
//         zip: '',
//       },
//       sameAddress: false,
//       shippingOptions: [],
//       selectedShipping: null,
//       shippingCost: 0,
//       tax: 0,
//       taxRate: null,
//       subtotal: 0,
//     };
//   }

//   componentWillMount() {
//     if (localStorage.cart) {
//       const cart = JSON.parse(localStorage.cart);
//       let total = 0;

//       for (let i = 0; i < cart.length; i++) {
//         total += cart[i].price * cart[i].quantity;
//       }

//       this.setState({
//         cart: cart,
//         subtotal: total.toFixed(2),
//       });
//     } else {
//       this.setState({
//         cart: [],
//       });
//     }
//   }

//   componentDidMount() {
//     window.scrollTo(0, 0);

//     this.updatePredicate();
//     window.addEventListener('resize', this.updatePredicate);
//   }

//   updatePredicate = () => {
//     this.setState({ isDesktop: window.innerWidth > 768 });
//   };

//   calculateShipping = (e) => {
//     const street1 = $(`#shippingaddress`).val();
//     const city = $(`#shippingcity`).val();
//     const state = $(`#shippingstate`).val();
//     const zip = $(`#shippingzip`).val();
//     let ready = false;
//     let quantity = 0;

//     if (localStorage.cart) {
//       const cart = JSON.parse(localStorage.cart);

//       for (let i = 0; i < cart.length; i++) {
//         quantity += parseInt(cart[i].quantity);
//       }
//     }

//     const addressTo = {
//       street1: street1,
//       city: city,
//       state: state,
//       zip: zip,
//       country: 'US',
//     };

//     const data = {
//       quantity: quantity,
//       addressTo: addressTo,
//     };

//     if (street1.length > 0 && city.length > 0 && state.length > 0 && zip.length > 4) {
//       ready = true;
//     }

//     if (ready) {
//       axios.post('/calculateshipping', data).then((res) => {
//         const shippingOptions = [];

//         for (let i = 0; i < res.data.rates.length; i++) {
//           if (
//             (res.data.rates[i].servicelevel.token === 'fedex_ground' &&
//               res.data.rates[i].servicelevel.terms === 'ONERATE') ||
//             (res.data.rates[i].servicelevel.token === 'fedex_express_saver' &&
//               res.data.rates[i].servicelevel.terms === 'ONERATE') ||
//             (res.data.rates[i].servicelevel.token === 'fedex_2_day' &&
//               res.data.rates[i].servicelevel.terms === 'ONERATE') ||
//             (res.data.rates[i].servicelevel.token === 'fedex_2_day_am' &&
//               res.data.rates[i].servicelevel.terms === 'ONERATE') ||
//             (res.data.rates[i].servicelevel.token === 'fedex_standard_overnight' &&
//               res.data.rates[i].servicelevel.terms === 'ONERATE')
//           ) {
//             shippingOptions.push(res.data.rates[i]);
//           }
//         }
//         this.setState({ shippingOptions: shippingOptions });
//       });
//     }
//   };

//   calculateTax() {
//     const postalCode = $(`#shippingzip`).val();
//     const country = 'US';
//     let ready = false;

//     if (postalCode.length > 4) {
//       ready = true;
//     }

//     if (ready) {
//       axios
//         .get(
//           `https://sandbox-rest.avatax.com/api/v2/taxrates/byaddress?postalCode=${postalCode}&country=${country}`,
//           {
//             headers: { Authorization: 'Basic YmF0Y2hzdmNAY2xvdWRsZW5kaW5nOiMhMTEwMDExISNSYW0=' },
//           },
//         )
//         .then((res) => {
//           this.setState({
//             taxRate: res.data.totalRate * 100,
//             tax: Math.round(this.state.subtotal * res.data.totalRate * 100) / 100,
//           });
//         });
//     }
//   }

//   submit = (e) => {
//     e.preventDefault();

//     $('.pay-button')
//       .prop('disabled', true)
//       .css('background-color', '#676767')
//       .css('cursor', 'default');

//     const email = $(`#email`).val();
//     const shippingFirstName = $(`#shippingfirstname`).val();
//     const shippingLastName = $(`#shippinglastname`).val();
//     const shippingAddress = $(`#shippingaddress`).val();
//     const shippingCity = $(`#shippingcity`).val();
//     const shippingState = $(`#shippingstate`).val();
//     const shippingZip = $(`#shippingzip`).val();
//     const phone = $(`#phone`).val();
//     const errors = [];
//     let billingFirstName, billingLastName, billingAddress, billingCity, billingState, billingZip;
//     const items = [];
//     let quantity = 0;

//     if (localStorage.cart) {
//       const cart = JSON.parse(localStorage.cart);

//       for (let i = 0; i < cart.length; i++) {
//         quantity += parseInt(cart[i].quantity);
//       }
//     }

//     for (let i = 0; i < this.state.cart.length; i++) {
//       const item = {};

//       item.color = this.state.cart[i].selectedColor;
//       item.size = this.state.cart[i].selectedSize;
//       item.productName = this.state.cart[i].name;
//       item.productSKU = this.state.cart[i].selectedSKU;
//       item.unitPrice = this.state.cart[i].price;
//       item.quantity = this.state.cart[i].quantity.toString();
//       item.totalBeforeTax = (this.state.cart[i].price * this.state.cart[i].quantity).toFixed(2);
//       item.itemTaxAmount = (
//         Math.round(item.totalBeforeTax * (this.state.taxRate * 0.01) * 100) / 100
//       ).toFixed(2);

//       items.push(item);
//     }

//     if (this.state.sameAddress) {
//       billingFirstName = $(`#shippingfirstname`).val();
//       billingLastName = $(`#shippinglastname`).val();
//       billingAddress = $(`#shippingaddress`).val();
//       billingCity = $(`#shippingcity`).val();
//       billingState = $(`#shippingstate`).val();
//       billingZip = $(`#shippingzip`).val();
//     } else {
//       billingFirstName = $(`#billingfirstname`).val();
//       billingLastName = $(`#billinglastname`).val();
//       billingAddress = $(`#billingaddress`).val();
//       billingCity = $(`#billingcity`).val();
//       billingState = $(`#billingstate`).val();
//       billingZip = $(`#billingzip`).val();
//     }

//     $('#shippingfirstname, #shippinglastname, #shippingaddress, #shippingcity, #shippingzip').css(
//       'border-color',
//       '#ced4da',
//     );
//     $(
//       '#billingfirstname, #billinglastname, #billingaddress, #billingcity, #billingzip, #email, #phone, #cardnumber, #cardcvc, #cardexpiry',
//     ).css('border-color', '#ced4da');

//     if (email === '') {
//       errors.push('Email');
//       $('#email').css('border-color', 'red');
//     }

//     if (phone === '') {
//       errors.push('Phone Number');
//       $('#phone').css('border-color', 'red');
//     }

//     if (
//       shippingFirstName === '' ||
//       shippingLastName === '' ||
//       shippingAddress === '' ||
//       shippingCity === '' ||
//       shippingZip === ''
//     ) {
//       if (shippingFirstName === '') {
//         $('#shippingfirstname').css('border-color', 'red');
//       }
//       if (shippingLastName === '') {
//         $('#shippinglastname').css('border-color', 'red');
//       }
//       if (shippingAddress === '') {
//         $('#shippingaddress').css('border-color', 'red');
//       }
//       if (shippingCity === '') {
//         $('#shippingcity').css('border-color', 'red');
//       }
//       if (shippingZip === '') {
//         $('#shippingzip').css('border-color', 'red');
//       }

//       errors.push('Shipping Address');
//     }

//     if (
//       billingFirstName === '' ||
//       billingLastName === '' ||
//       billingAddress === '' ||
//       billingCity === '' ||
//       billingZip === ''
//     ) {
//       if (billingFirstName === '') {
//         $('#billingfirstname').css('border-color', 'red');
//       }
//       if (billingLastName === '') {
//         $('#billinglastname').css('border-color', 'red');
//       }
//       if (billingAddress === '') {
//         $('#billingaddress').css('border-color', 'red');
//       }
//       if (billingCity === '') {
//         $('#billingcity').css('border-color', 'red');
//       }
//       if (billingZip === '') {
//         $('#billingzip').css('border-color', 'red');
//       }

//       errors.push('Billing Address');
//     }

//     if (this.state.shippingCost === 0) {
//       errors.push('Shipping Option');
//     }

//     if (errors.length > 0) {
//       swal.fire({
//         title: `Please complete the following:`,
//         text: errors.join(', '),
//         icon: 'error',
//       });

//       $('.pay-button')
//         .prop('disabled', false)
//         .css('background-color', 'black')
//         .css('cursor', 'pointer');
//     }

//     const data = {
//       email,
//       shippingFirstName,
//       shippingLastName,
//       shippingAddress,
//       shippingCity,
//       shippingState,
//       shippingZip,
//       billingFirstName,
//       billingLastName,
//       billingAddress,
//       billingCity,
//       billingState,
//       billingZip,
//       items,
//       phone,
//       quantity,
//       selectedShipping: this.state.selectedShipping,
//       salesTax: this.state.taxRate.toString(),
//       totalTaxAmount: this.state.tax.toString(),
//       shippingCost: this.state.shippingCost,
//       orderTotal: (
//         Math.round(
//           (parseFloat(this.state.tax) +
//             parseFloat(this.state.shippingCost) +
//             parseFloat(this.state.subtotal)) *
//             100,
//         ) / 100
//       ).toFixed(2),
//     };
//     if (errors.length === 0) {
//       this.props.stripe
//         .createToken({
//           name: `${billingFirstName} ${billingLastName}`,
//           address_line1: billingAddress,
//           address_city: billingCity,
//           address_state: billingState,
//           address_zip: billingZip,
//           address_country: 'US',
//         })
//         .then(({ token, error }) => {
//           let ready = true;
//           if (error) {
//             console.log({ error });
//             ready = false;
//             if (error.code === 'incomplete_number') {
//               $('#cardnumber').css('border-color', 'red');
//               swal.fire({
//                 title: 'Please enter a valid card number.',
//                 icon: 'error',
//               });
//             }

//             if (error.code === 'incomplete_expiry') {
//               $('#cardexpiry').css('border-color', 'red');
//               swal.fire({
//                 title: 'Please enter a valid expiration date.',
//                 icon: 'error',
//               });
//             }

//             if (error.code === 'incomplete_cvc') {
//               $('#cardcvc').css('border-color', 'red');
//               swal.fire({
//                 title: 'Please enter a valid CVC.',
//                 icon: 'error',
//               });
//             }

//             $('.pay-button')
//               .prop('disabled', false)
//               .css('background-color', 'black')
//               .css('cursor', 'pointer');
//           }

//           token.total = parseFloat(
//             parseFloat(this.state.subtotal) +
//               parseFloat(this.state.shippingCost) +
//               parseFloat(this.state.tax),
//           ).toFixed(2);
//           data.token = token;

//           if (ready) {
//             swal
//               .fire({
//                 title: `You will be charged $${(
//                   parseFloat(this.state.subtotal) +
//                   parseFloat(this.state.shippingCost) +
//                   parseFloat(this.state.tax)
//                 ).toFixed(2)}.`,
//                 icon: 'info',
//                 showCancelButton: true,
//                 confirmButtonColor: '#3085d6',
//                 cancelButtonColor: '#d33',
//               })
//               .then((result) => {
//                 console.log({ result });
//                 if (result.value) {
//                   axios.post('/charge', data).then((res) => {
//                     console.log({ res });
//                     if (res.data === 'error') {
//                       swal.fire({
//                         title: 'An error has occurred.',
//                         icon: 'error',
//                         showConfirmButton: false,
//                         timer: 1500,
//                       });

//                       $('.pay-button')
//                         .prop('disabled', false)
//                         .css('background-color', 'black')
//                         .css('cursor', 'pointer');
//                     } else if (res.status) {
//                       swal
//                         .fire({
//                           title: 'Payment successful!',
//                           html: `<div>We will email you when the item is shipped.</div>
//                           <div class='my-2'><a href=${res.data.receipt_url} target='_blank'>View Receipt</a></div>`,
//                           icon: 'success',
//                         })
//                         .then(() => {
//                           localStorage.cart = '[]';
//                           this.setState({
//                             cart: [],
//                           });
//                         });
//                     }
//                   });
//                 } else {
//                   $('.pay-button')
//                     .prop('disabled', false)
//                     .css('background-color', 'black')
//                     .css('cursor', 'pointer');
//                 }
//               });
//           }
//         });
//     }
//   };

//   sameAddress = () => {
//     const newBool = !this.state.sameAddress;

//     this.setState({
//       sameAddress: newBool,
//     });
//   };

//   selectShipping = (e) => {
//     const index = e.target.id.replace('shippingoption', '');
//     const selectedShipping = this.state.shippingOptions[index].servicelevel.name;

//     $('.shipping-option').prop('checked', false);
//     $(`#${e.target.id}`).prop('checked', true);
//     this.setState({
//       shippingCost: (Math.round(e.target.value * 100) / 100).toFixed(2),
//       selectedShipping,
//     });
//   };

//   render() {
//     return (
//       <div>
//         <div className="container contact-us-body">
//           <div className="row">
//             <div className="col contact-us-text">Checkout</div>
//           </div>
//           <div className="row page-divider mb-5" />
//           {this.state.cart.length === 0 ? <div>No items in your cart!</div> : null}
//           {this.state.cart.length > 0 && (
//             <div className="row">
//               <div className="col-md-9 col-12 pr-lg-5">
//                 <div className="checkout-subtitle">Email</div>
//                 <div className="row mt-2">
//                   <div className="col-lg-6 col-12 mt-3">
//                     <div className="form-group">
//                       <input type="text" className="form-control" id="email" />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="checkout-subtitle mt-4">Phone Number</div>
//                 <div className="row mt-2">
//                   <div className="col-lg-6 col-12 mt-3">
//                     <div className="form-group">
//                       <input type="text" className="form-control" id="phone" />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="checkout-subtitle mt-4">Shipping Address</div>
//                 <div className="row mt-2 d-flex align-content-center justify-content-center">
//                   <div className="col-lg-6 col-12 mt-3">
//                     <div className="form-group">
//                       <label htmlFor="shippingname">First Name</label>
//                       <input type="text" className="form-control" id="shippingfirstname" />
//                     </div>
//                   </div>
//                   <div className="col-lg-6 col-12 mt-3">
//                     <div className="form-group">
//                       <label htmlFor="shippingname">Last Name</label>
//                       <input type="text" className="form-control" id="shippinglastname" />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="row mt-2 d-flex align-content-center justify-content-center">
//                   <div className="col-12 mt-3">
//                     <div className="form-group">
//                       <label htmlFor="shippingaddress">Address</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="shippingaddress"
//                         onChange={this.calculateShipping}
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="row mt-2 d-flex align-content-center justify-content-center">
//                   <div className="col-lg-5 col-12 mt-3">
//                     <div className="form-group">
//                       <label htmlFor="shippingaddress">City</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="shippingcity"
//                         onChange={this.calculateShipping}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-lg-2 col-12 mt-3">
//                     <div className="form-group">
//                       <label htmlFor="shippingaddress">State</label>
//                       <select
//                         id="shippingstate"
//                         onChange={this.calculateShipping}
//                         className="form-control">
//                         <option value="AL">Alabama</option>
//                         <option value="AK">Alaska</option>
//                         <option value="AZ">Arizona</option>
//                         <option value="AR">Arkansas</option>
//                         <option value="CA">California</option>
//                         <option value="CO">Colorado</option>
//                         <option value="CT">Connecticut</option>
//                         <option value="DE">Delaware</option>
//                         <option value="DC">District Of Columbia</option>
//                         <option value="FL">Florida</option>
//                         <option value="GA">Georgia</option>
//                         <option value="HI">Hawaii</option>
//                         <option value="ID">Idaho</option>
//                         <option value="IL">Illinois</option>
//                         <option value="IN">Indiana</option>
//                         <option value="IA">Iowa</option>
//                         <option value="KS">Kansas</option>
//                         <option value="KY">Kentucky</option>
//                         <option value="LA">Louisiana</option>
//                         <option value="ME">Maine</option>
//                         <option value="MD">Maryland</option>
//                         <option value="MA">Massachusetts</option>
//                         <option value="MI">Michigan</option>
//                         <option value="MN">Minnesota</option>
//                         <option value="MS">Mississippi</option>
//                         <option value="MO">Missouri</option>
//                         <option value="MT">Montana</option>
//                         <option value="NE">Nebraska</option>
//                         <option value="NV">Nevada</option>
//                         <option value="NH">New Hampshire</option>
//                         <option value="NJ">New Jersey</option>
//                         <option value="NM">New Mexico</option>
//                         <option value="NY">New York</option>
//                         <option value="NC">North Carolina</option>
//                         <option value="ND">North Dakota</option>
//                         <option value="OH">Ohio</option>
//                         <option value="OK">Oklahoma</option>
//                         <option value="OR">Oregon</option>
//                         <option value="PA">Pennsylvania</option>
//                         <option value="RI">Rhode Island</option>
//                         <option value="SC">South Carolina</option>
//                         <option value="SD">South Dakota</option>
//                         <option value="TN">Tennessee</option>
//                         <option value="TX">Texas</option>
//                         <option value="UT">Utah</option>
//                         <option value="VT">Vermont</option>
//                         <option value="VA">Virginia</option>
//                         <option value="WA">Washington</option>
//                         <option value="WV">West Virginia</option>
//                         <option value="WI">Wisconsin</option>
//                         <option value="WY">Wyoming</option>
//                       </select>
//                     </div>
//                   </div>
//                   <div className="col-lg-5 col-12 mt-3">
//                     <div className="form-group">
//                       <label htmlFor="shippingaddress">Zip Code</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="shippingzip"
//                         onChange={() => {
//                           this.calculateShipping();
//                           this.calculateTax();
//                         }}
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="checkout-subtitle mt-4">Billing Address</div>
//                 <div className="form-check mt-1">
//                   <input
//                     type="checkbox"
//                     id="sameaddresscheck"
//                     className="form-check-input"
//                     checked={this.state.sameAddress}
//                     onClick={this.sameAddress}
//                   />
//                   <label className="form-check-label" for="sameaddresscheck">
//                     Same as Shipping Address
//                   </label>
//                 </div>
//                 {!this.state.sameAddress ? (
//                   <div>
//                     <div className="row mt-2 d-flex align-content-center justify-content-center">
//                       <div className="col-lg-6 col-12 mt-3">
//                         <div className="form-group">
//                           <label htmlFor="billingname">First Name</label>
//                           <input type="text" className="form-control" id="billingfirstname" />
//                         </div>
//                       </div>
//                       <div className="col-lg-6 col-12 mt-3">
//                         <div className="form-group">
//                           <label htmlFor="billingname">Last Name</label>
//                           <input type="text" className="form-control" id="billinglastname" />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="row mt-2 d-flex align-content-center justify-content-center">
//                       <div className="col-12 mt-3">
//                         <div className="form-group">
//                           <label htmlFor="billingaddress">Address</label>
//                           <input type="text" className="form-control" id="billingaddress" />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="row mt-2 d-flex align-content-center justify-content-center">
//                       <div className="col-lg-5 col-12 mt-3">
//                         <div className="form-group">
//                           <label htmlFor="billingaddress">City</label>
//                           <input type="text" className="form-control" id="billingcity" />
//                         </div>
//                       </div>
//                       <div className="col-lg-2 col-12 mt-3">
//                         <div className="form-group">
//                           <label htmlFor="shippingaddress">State</label>
//                           <select
//                             id="shippingstate"
//                             onChange={this.calculateShipping}
//                             className="form-control">
//                             <option value="AL">Alabama</option>
//                             <option value="AK">Alaska</option>
//                             <option value="AZ">Arizona</option>
//                             <option value="AR">Arkansas</option>
//                             <option value="CA">California</option>
//                             <option value="CO">Colorado</option>
//                             <option value="CT">Connecticut</option>
//                             <option value="DE">Delaware</option>
//                             <option value="DC">District Of Columbia</option>
//                             <option value="FL">Florida</option>
//                             <option value="GA">Georgia</option>
//                             <option value="HI">Hawaii</option>
//                             <option value="ID">Idaho</option>
//                             <option value="IL">Illinois</option>
//                             <option value="IN">Indiana</option>
//                             <option value="IA">Iowa</option>
//                             <option value="KS">Kansas</option>
//                             <option value="KY">Kentucky</option>
//                             <option value="LA">Louisiana</option>
//                             <option value="ME">Maine</option>
//                             <option value="MD">Maryland</option>
//                             <option value="MA">Massachusetts</option>
//                             <option value="MI">Michigan</option>
//                             <option value="MN">Minnesota</option>
//                             <option value="MS">Mississippi</option>
//                             <option value="MO">Missouri</option>
//                             <option value="MT">Montana</option>
//                             <option value="NE">Nebraska</option>
//                             <option value="NV">Nevada</option>
//                             <option value="NH">New Hampshire</option>
//                             <option value="NJ">New Jersey</option>
//                             <option value="NM">New Mexico</option>
//                             <option value="NY">New York</option>
//                             <option value="NC">North Carolina</option>
//                             <option value="ND">North Dakota</option>
//                             <option value="OH">Ohio</option>
//                             <option value="OK">Oklahoma</option>
//                             <option value="OR">Oregon</option>
//                             <option value="PA">Pennsylvania</option>
//                             <option value="RI">Rhode Island</option>
//                             <option value="SC">South Carolina</option>
//                             <option value="SD">South Dakota</option>
//                             <option value="TN">Tennessee</option>
//                             <option value="TX">Texas</option>
//                             <option value="UT">Utah</option>
//                             <option value="VT">Vermont</option>
//                             <option value="VA">Virginia</option>
//                             <option value="WA">Washington</option>
//                             <option value="WV">West Virginia</option>
//                             <option value="WI">Wisconsin</option>
//                             <option value="WY">Wyoming</option>
//                           </select>
//                         </div>
//                       </div>
//                       <div className="col-lg-5 col-12 mt-3">
//                         <div className="form-group">
//                           <label htmlFor="billingaddress">Zip Code</label>
//                           <input type="text" className="form-control" id="billingzip" />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 ) : null}
//                 {this.state.shippingOptions.length > 0 ? (
//                   <div>
//                     <div className="checkout-subtitle mt-4">
//                       Shipping
//                       <p className="mb-0" style={{ fontFamily: 'Futura', fontSize: '16px' }}>
//                         <i>Customers are responsible for returns</i>
//                       </p>
//                     </div>
//                     <div className="row mt-2 d-flex align-content-center justify-content-center">
//                       {this.state.shippingOptions.map((option, i) => {
//                         return (
//                           <div className="col-sm-12 col-10 mt-3">
//                             <input
//                               class="form-check-input shipping-option"
//                               onChange={this.selectShipping}
//                               type="radio"
//                               id={`shippingoption${i}`}
//                               value={option.amount}
//                             />
//                             <label class="form-check-label" for={`shippingoption${i}`}>
//                               ${option.amount} - {`${option.provider} ${option.servicelevel.name}`}
//                               <br />
//                               Estimated arrival: Within {option.estimated_days} business days from
//                               shipping
//                             </label>
//                           </div>
//                         );
//                       })}
//                     </div>
//                   </div>
//                 ) : null}
//                 {window.innerWidth > 450 ? (
//                   <div className="checkout-subtitle mt-4">
//                     Payment
//                     <img
//                       className="ml-3 card-icon img-fluid"
//                       src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/visa.png'}
//                     />
//                     <img
//                       className="ml-3 card-icon img-fluid"
//                       src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/master.png'}
//                     />
//                     <img
//                       className="ml-3 card-icon img-fluid"
//                       src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/amex.png'}
//                     />
//                     <img
//                       className="ml-3 card-icon img-fluid"
//                       src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/discover.png'}
//                     />
//                     <img
//                       className="ml-3 card-icon img-fluid"
//                       src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/diners.png'}
//                     />
//                     <img
//                       className="ml-3 card-icon img-fluid"
//                       src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/jcb.png'}
//                     />
//                   </div>
//                 ) : (
//                   <div className="checkout-subtitle mt-4">
//                     Payment
//                     <br />
//                     <div className="mt-2">
//                       <img
//                         className="card-icon img-fluid"
//                         src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/visa.png'}
//                       />
//                       <img
//                         className="ml-3 card-icon img-fluid"
//                         src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/master.png'}
//                       />
//                       <img
//                         className="ml-3 card-icon img-fluid"
//                         src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/amex.png'}
//                       />
//                       <img
//                         className="ml-3 card-icon img-fluid"
//                         src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/discover.png'}
//                       />
//                       <img
//                         className="ml-3 card-icon img-fluid"
//                         src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/diners.png'}
//                       />
//                       <img
//                         className="ml-3 card-icon img-fluid"
//                         src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/jcb.png'}
//                       />
//                     </div>
//                   </div>
//                 )}
//                 <div className="row mt-2 d-flex align-content-left justify-content-left">
//                   <div className="col-lg-6 col-12 mt-3">
//                     <div className="form-group">
//                       <div className="col-lg-8 col-12 px-0">
//                         <label htmlFor="ccinfo">Card Number</label>
//                         <CardNumberElement
//                           style={{ base: { fontSize: '18px', fontFamily: 'Futura' } }}
//                           className="form-control"
//                           id="cardnumber"
//                         />
//                       </div>
//                       <div className="col-lg-6 col-12 px-0 mt-3">
//                         <label htmlFor="ccinfo">Expiration Date</label>
//                         <CardExpiryElement
//                           style={{ base: { fontSize: '18px', fontFamily: 'Futura' } }}
//                           className="form-control"
//                           id="cardexpiry"
//                         />
//                       </div>
//                       <div className="col-lg-6 col-12 px-0 mt-3">
//                         <label htmlFor="ccinfo">CVC</label>
//                         <CardCVCElement
//                           style={{ base: { fontSize: '18px', fontFamily: 'Futura' } }}
//                           className="form-control"
//                           id="cardcvc"
//                         />
//                       </div>
//                     </div>
//                     <button className="pay-button mt-3" type="submit" onClick={this.submit}>
//                       PAY
//                     </button>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-md-3 col-12 pl-lg-5 checkout-items">
//                 <div
//                   className="col-md-3 col-12 px-0"
//                   style={window.innerWidth > 768 ? { position: 'fixed', top: '30%' } : null}>
//                   <div className="my-5">
//                     <div className="mt-3 cart-subtitle">Subtotal: {`$${this.state.subtotal}`}</div>
//                     {this.state.taxRate ? (
//                       <div className="mt-3 cart-subtitle">
//                         Tax: {`$${(Math.round(this.state.tax * 100) / 100).toFixed(2)}`} (
//                         {this.state.taxRate.toFixed(2)}%)
//                       </div>
//                     ) : (
//                       <div className="mt-3 cart-subtitle">Tax: {`$0.00`}</div>
//                     )}
//                     <div className="mt-3 cart-subtitle">
//                       Shipping:{' '}
//                       {this.state.shippingCost === 0 ? '$0.00' : `$${this.state.shippingCost}`}
//                     </div>
//                     <div className="total-divider my-3" />
//                     <div className="mt-3 checkout-total">
//                       Total:{' '}
//                       {`$${(
//                         Math.round(
//                           (parseFloat(this.state.tax) +
//                             parseFloat(this.state.shippingCost) +
//                             parseFloat(this.state.subtotal)) *
//                             100,
//                         ) / 100
//                       ).toFixed(2)}`}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     );
//   }
// }

// export default injectStripe(Checkout);
