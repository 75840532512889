import React from 'react';

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className='container contact-us-body'>
          <div className='row'>
            <div className='col contact-us-text'>Terms and Conditions</div>
          </div>
          <div className='row page-divider mb-3' />
          <div className='privacy-policy mb-5'>
            <p>
              The Lotus Pet Food, Inc. (“Lotus,”, “we,” “us,” or “our”) provides the website www.lotuspetfoods.com (the "Website") to authorized users (“Users,” “you,” or “your”)
              for informational purposes only. By accessing and using our Website you agree to the following Terms of Use. You may not access or use the Website or accept the Terms
              of Use if you are not at least 18 years old. If you do not agree with all of these Terms of Use, do not access or use this Website.
              <br />
              <br />
              We reserve the right, at our sole discretion, to change or modify these Terms of Use at any time. If we do this, we will post the changes on this page and will
              indicate at the top of this page the date these terms were last revised. Your continued use of the Website after the date any such changes become effective
              constitutes your acceptance of the new Terms of Use. <br />
              <br />
              THESE TERMS OF USE REQUIRE ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO
              YOU IN THE EVENT OF A DISPUTE.
              <br />
              <br />
              <h2>Intellectual Property Rights; Use Restrictions</h2>
              <br />
              The Website, its features and functionality (including all software, the graphic user interface and the design, selection and arrangement thereof) and the content of
              this Website are owned by us or by our licensors or other providers, and are protected by United States copyright, trademark, patent, trade secret and other
              intellectual property or proprietary rights laws.
              <br />
              <br />
              You may use the Website and any available content solely for your personal, non-commercial use. You may not use, download, upload, copy, print, display, perform,
              reproduce, publish, republish modify, delete, add to, rent, lease, lend, sell, license, post, transmit or distribute any of our Website content for any public or
              commercial purpose, including, without limitation, on any Internet, Intranet or Extranet site or incorporate the content in any other database or compilation, nor may
              you copy, decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Website or Website content, and
              any other use of the Website content is strictly prohibited. In accessing or using our Website, you will not engage in or use any data mining, robots, scraping or
              similar data gathering or extraction methods. You may not attempt to gain unauthorized access to the Website through hacking, password mining or any other means. You
              may not create frames around any of our Website pages or use other techniques that alter in any way the visual presentation or appearance of our Website. You may not
              use the Website in any manner that could damage, disable, overburden or impair the Website or interfere with any other person’s use of the Website. <br />
              <br />
              If you violate these use restrictions, your permission to access the Website and use the content automatically terminates and you may be blocked from accessing the
              Website. If you are blocked by Lotus from accessing the Website (including by blocking your IP address), you agree not to implement any measures to circumvent such
              blocking (e.g., by masking your IP address or using a proxy IP address). You may print or download one copy of a reasonable number of pages of the available content
              for your personal, non-commercial use if you also retain all copyright and other proprietary notices contained on the content. Otherwise, you may not copy,
              distribute, modify, create derivative works from, publicly display or perform, republish, download, store or transmit any of the content available on the Website, for
              commercial or public purposes, without our prior written consent. <br />
              <br />
              Any use of our Website or content other than as specifically authorized in these Terms of Use is strictly prohibited. Unauthorized use may also violate applicable
              laws including without limitation copyright and trademark laws and applicable communications regulations and statutes. These Terms of Use shall not be construed as
              conferring any license to intellectual property rights, whether by estoppel, implication or otherwise. <br />
              <br />
              <h2>Trademark Rights</h2>
              <br />
              Our company name, “The Lotus Pet Food,” the names of our r products, the Lotus® Trademark, and all other trade names, trademarks, service marks, product and service
              names, slogans, designs and logos (collectively, the “Marks”) displayed on this Website are proprietary to us or our licensors. Nothing contained on this Website is
              to be construed as a grant, by implication, estoppel, or otherwise, of any license or right to use any of the Marks without our prior written permission. All other
              trademarks, service marks, product and service names, slogans, designs and logos on this Website are the intellectual property of their respective owners.
              <br />
              <br />
              <h2>Copyright Complaints</h2>
              <br />
              If you believe that this Website infringes on any copyright which you own or control, you may send a written notification of such infringement to our Designated
              Agent:
              <br />
              <br />
              Daron Matsuura
              <br />
              2727 Maricopa Street
              <br />
              Torrance, CA 90503
              <br />
              dmatsuura@lotuspetfoods.com
              <br />
              <br />
              To meet the notice restrictions under the Digital Millennium Copyright Act, the notification must be a written communication that includes the following:
              <br />
              <br />
              1. A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
              <br />
              <br />
              2. Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single
              notification, a representative list of such works at that site;
              <br />
              <br />
              3. Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be
              disabled, and information reasonably sufficient to permit us to locate the material;
              <br />
              <br />
              4. Information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number and, if available, an electronic mail address
              at which the complaining party may be contacted;
              <br />
              <br />
              5. A statement that the complaining party has a good-faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its
              agent or the law; and
              <br />
              <br />
              6. A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the
              owner of an exclusive right that is allegedly infringed.
              <br />
              <br />
              <h2>Links to this Website</h2>
              <br />
              You may link to the www.lotuspetfoods.com landing page, but only if you do so in a way that is fair, legal and does not damage our reputation or take advantage of it.
              You must not frame any content or establish a link in such a way as to suggest any form of association, approval or endorsement on our part without our express prior
              written consent. You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking
              permission without notice.
              <br />
              <br />
              <h2>Links to Other Websites</h2>
              <br />
              We may establish links between this Website and the websites, webpages or other resources provided by third parties, as well as to text, graphics, videos, images,
              music, sounds, and information belonging to or originating from other third-parties. These links are provided solely for your convenience and do not imply our
              endorsement or approval of such third-party websites or resources. We are not responsible for third party websites or content accessed through the Website. We do not
              investigate, monitor, or review any third party websites or content to ensure their accuracy, completeness, or appropriateness.. You agree that Lotus shall have no
              liability with respect to any loss or damage of any kind incurred as a result of any dealings between you and any third party, or as a result of the presence of such
              third party on the Website. We have no control over the privacy policies of, the content of, or other resources available at such websites or such websites and accept
              no responsibility for them or for any loss or damage that may arise from your use of them. Your access and use of such third-party websites is governed by the terms
              of use and privacy policies of those websites and is at your own risk.
              <br />
              <br />
              <h2>Prohibited Uses</h2>
              <br />
              Except as otherwise stated in these Terms of Use or as expressly authorized by us in writing, you may not:
              <br />
              <br />
              Use this Website or any available content in violation of applicable law or regulation;
              <br />
              <br />
              Use this Website in any manner that could disable, damage or overburden the Website, our servers or any of the networks connected to our servers, or as determined by
              us, in a way that may harm Lotus or expose Lotus to liability;
              <br />
              <br />
              Use this Website in any manner that would interfere with another party's use of the Website;
              <br />
              <br />
              Include any of the Marks or any variation of them as a meta-tag or hidden textual element;
              <br />
              <br />
              Use any robot, spider or other automatic device, or any manual process or means to search, monitor or copy this Website or the information and other content on,
              generated by or obtained from this Website, whether through links or otherwise;
              <br />
              <br />
              Introduce any viruses, Trojan horses, worms, logic bombs or other material which is malicious or technologically harmful;
              <br />
              <br />
              Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer
              or database connected to the Website;
              <br />
              <br />
              Attack the Website via a denial-of-service attack or a distributed denial-of-service attack;
              <br />
              <br />
              Otherwise attempt to interfere with the proper working of the Website; or
              <br />
              <br />
              Use this Website and its content in any manner that could create impression of affiliation, sponsorship or endorsement by Lotus.
              <br />
              <br />
              <h2>User Submissions and User Generated Content</h2>
              <br />
              The personal information you submit to Lotus is governed by the Lotus Privacy Policy. To the extent there is an inconsistency between the Terms of Use and the Privacy
              Policy, the Privacy Policy shall govern.
              <br />
              <br />
              Our Website permits users to post comments, reviews and other information, and also to upload photos and videos of them, their families and their pets (“User
              Generated Content”).
              <br />
              <br />
              Except as provided in this section and under Submission of Ideas (below), Lotus does not claim ownership of any information or material a user provides to Lotus or
              posts, uploads, input, submits, or transmits to the Website ("Submission"). However, by making a Submission, you agree that such Submission is non-confidential,
              non-proprietary, and may be disseminated or used by Lotus for any lawful purpose. If you make a Submission, you automatically grant, or warrant that the owner of such
              User Generated Content has expressly granted, Lotus a royalty-free, perpetual, irrevocable, worldwide nonexclusive license to use, reproduce, create derivative works
              from, modify, publish, edit, translate, distribute, perform, and display the Submission, with or without attribution, in any media or medium, or any form, format, or
              forum now known or hereafter developed. Notwithstanding the foregoing, Lotus is not required to use any Submission.
              <br />
              <br />
              By making a Submission, you represent and warrant to Lotus that you have the legal right to grant the license described in the above paragraph for the User Generated
              Content submitted and that such Submission does not violate any person’s copyrights, trademarks, intellectual property rights or rights of publicity. You further
              represent and warrant that you have the legal right to grant this license for all pets and persons who appear in any photo or video you submit. You shall defend,
              indemnify and hold us harmless for any breach of these representations and warranties.
            </p>
            <p>
              You agree that you will not make any Submission that is, in whole or in part, libelous; scandalous; inflammatory; discriminatory; defamatory; false; threatening;
              vulgar; obscene; pornographic; profane; abusive; harassing; invasive of another's privacy; hateful or bashing; aimed at gender, race, color, sexual orientation,
              national origin, religious views, or disability; in violation of local, state, national, or international law; or that infringes on, or violates, any right of any
              party. Additionally, you agree that you will not: (a) make any Submission that is an advertisement or solicitation of business or for any other commercial purpose;
              (b) disrupt the normal flow of dialogue or make a Submission unrelated to the topic being discussed (unless it is clear the discussion is free-form); (c) post a chain
              letter or pyramid scheme; impersonate another person; (d) distribute viruses or other harmful computer code; (e) harvest or otherwise collects information about
              others, including email addresses, without their consent; (f) post the same note more than once or "spamming"; or (g) engage in any other conduct that restricts or
              inhibits any other person from using or enjoying the Website, or which, in the judgment of Lotus, exposes Lotus or any of its licensors, partners, or customers to any
              liability or detriment of any type.
              <br />
              <br />
              You are solely responsible for your Submission, the consequences of making a Submission, and your reliance on any Submissions. Lotus is not responsible for the
              consequences of any Submission. Lotus is not responsible for screening or monitoring Submissions made to the Website by users. Submissions do not represent the views
              or opinions of Lotus. We do not verify the accuracy, truthfulness or validity of information contained in any Submission and we are not responsible for your reliance
              on any information contained in a Submission, including without limitation information that is false, misleading, ineffective or unsafe. If notified by a user of a
              Submission allegedly in violation of these Terms of Use, Lotus may investigate the allegation and determine in good faith and its sole discretion whether to remove
              such Submission. Lotus will have no liability or responsibility to users for performance or nonperformance of such activities.
              <br />
              <br />
              Lotus reserves the right (but is not obligated) to: (a) record the dialogue on the Website; (b) investigate an allegation that a Submission does not comply with these
              Terms of Use and determine in its sole discretion to remove or request the removal of the Submission; (c) remove Submissions which are abusive, illegal, disruptive,
              or outdated, or that otherwise fail to comply with these Terms of Use; (d) terminate a user's access to any or all parts of the Website upon any breach of these Terms
              of Use or the law; (e) monitor, edit, or disclose any Submission; (f) edit or delete any Submission posted on the Website, regardless of whether such Submission
              violates these Terms of Use.
              <br />
              <br />
              <h2>Product Reviews</h2>
              <br />
              As a condition of posting any product rating or product review on our Website, you represent, and warrant to us that: <br />
              <br />
              You are 18 years of age or older and a resident of the United States;
              <br />
              <br />
              You have not been compensate or offered compensation by Lotus or anyone acting on our behalf; <br />
              <br />
              You are not a Lotus employee or work for anyone that Lotus has engaged to market and sell Lotus® Products; and
              <br />
              <br />
              All information that you submit is accurate, up to date, reflects your true opinion, and is based on your actual experience with the product being reviewed; and{' '}
              <br />
              <br />
              No information submitted by you is, to your knowledge, false, inaccurate, or misleading.
              <br />
              <br />
              If you post a product rating or product review on our Website, you agree with us that we may use your rating or review for advertising, marketing, promotional,
              selling or other purposes and you will not be entitled to any compensation for such use.
              <br />
              <br />
              <h2>Unsolicited Ideas</h2>
              <br />
              Lotus's policy is to not accept or review unsolicited ideas or suggestions from persons outside the company, including ideas for new products or services. You should
              not transmit any material to the Website that you consider to be confidential or proprietary. Notwithstanding such policy, any material, images, ideas, suggestions,
              know-how, or concepts that are offered or communicated to Lotus through the Website or otherwise shall be the property of Lotus, and may be treated by Lotus as
              non-confidential and non-proprietary.
              <br />
              <br />
              Except as expressly provided in our Privacy Policy, you give Lotus an unrestricted, irrevocable, worldwide, royalty-free license to use, reproduce, display, publicly
              perform, transmit and distribute any such information. You further agree that Lotus has the right to use, without any payment or accounting to you or others, any
              concepts, know-how or ideas that you (or those who act on your behalf) submit to the Website, including for the development, manufacturing, marketing and advertising
              of any products or services.
              <br />
              <br />
              <h2>Pet Health, Fitness and Nutrition</h2>
              <br />
              This Website may from time to time contain information relating to nutrition and various medical, health and fitness conditions of dogs and cats and their treatment.
              This is for informational purposes only and is not a substitute for the advice provided by your own veterinarian. You should not use such information for diagnosing a
              pet's health or fitness You should always consult your own veterinarian.
              <br />
              <br />
              <h2>Disclaimer of Warranties</h2>
              <br />
              All content available on the Website is provided for informational purposes only. The Website content may include technical inaccuracies or typographical errors.
              Lotus may make changes or improvements at any time. <br />
              <br />
              You understand that we cannot and do not guarantee or warrant that files available for downloading from the Internet or the Website will be free of viruses or other
              destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and
              accuracy of data input and output, and for maintaining a means external to our Website for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR
              DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS,
              DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR PRODUCTS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL
              POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
              <br />
              <br />
              YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR PRODUCTS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR
              PRODUCTS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NONE OF
              LOTUS AND ANY PERSON ASSOCIATED WITH LOTUS MAKE ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR
              AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NONE OF LOTUS AND ANY PERSON ASSOCIATED WITH LOTUS REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT
              OR ANY SERVICES OR PRODUCTS OBTAINED THROUGH THE WEBSITE WILL BE COMPLETE, ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR
              WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE, THE CONTENT OR ANY SERVICES OR PRODUCTS OBTAINED
              THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
              <br />
              <br />
              LOTUS HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
              NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.
              <br />
              <br />
              THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              <br />
              <br />
              <h2>Limitation of Liability</h2>
              <br />
              LOTUS AND ITS AFFILIATES, SHAREHOLDERS, OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS WILL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DAMAGES OF ANY KIND UNDER ANY LEGAL
              THEORY (INCLUDING TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE), ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY
              WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR PRODUCTS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING,
              BUT NOT LIMITED TO, DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL (INCLUDING, BUT NOT LIMITED TO, LOST PROFITS, LOSS OF REVENUE, LOSS OF BUSINESS OR
              ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,) OR PUNITIVE DAMAGES, EVEN IF FORESEEABLE AND EVEN IF LOTUS HAS BEEN ADVISED OF THE POSSIBILITY OF
              SUCH DAMAGES OR LOSSES. <br />
              <br />
              <h2>Indemnification</h2>
              <br />
              In consideration of your access to this Website and use of its content, you shall defend, indemnify and hold us and our shareholders, officers, directors, employees,
              agents, licensors and suppliers, successors and assigns harmless from and against any and all losses, liability, costs and expenses (including, but not limited to,
              reasonable attorneys' fees) incurred as a result of or arising from your access of this Website and use of its content (including any unauthorized or otherwise
              inappropriate use of any of the content of this Website), your violation of these Terms of Use and any decisions that you make based on any content available at this
              Website.
              <br />
              <br />
              <h2>Release</h2>
              <br />
              You hereby release and forever discharge Lotus (affiliates, shareholders, officers, directors, employees, agents, licensors and suppliers, successors and assigns)
              from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of
              action of every kind and nature (including personal injuries, death, and property damage), that has arisen or arises directly or indirectly out of, or that relates
              directly or indirectly to, the Website (including any interactions with, or act or omission of, other Website users or any third-party links). IF YOU ARE A CALIFORNIA
              RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
              CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR
              HER SETTLEMENT WITH THE DEBTOR.”
              <br />
              <br />
              <h2>Governing Law, Exclusive Jurisdiction and Venue</h2>
              <br />
              All matters relating to this Website, its content, these Terms of Use and any dispute or claim arising from or related to them are governed by and will be construed
              in accordance with the laws of the State of California, without regard to conflict of law provisions. You and Lotus agree to submit to the personal and exclusive
              jurisdiction and venue of the state and federal courts located within Los Angeles County, California, and you waive any and all objections to the exercise of
              jurisdiction over you by such courts and to venue in such courts.
              <br />
              <br />
              Arbitration, Waiver of Jury Trial and Class Action Rights
              <br />
              <br />
              Please read this Arbitration Agreement carefully. It is part of your contract with Lotus and affects your rights. It contains procedures for MANDATORY, BINDING
              ARBITRATION AND A CLASS ACTION WAIVER.
              <br />
              <br />
              (a) Applicability of Arbitration Agreement. All disputes, claims, or controversies (excluding claims for injunctive or other equitable relief as set forth below)
              (“Claims”) arising out of or relating to any aspect of the relationship between you and us, including but not limited to your use of the Website, its content, or
              these Terms of Use, whether based in contract, tort, statute, fraud, misrepresentation or any other legal theory the Terms or the use of any product or service
              provided by us that cannot be resolved informally or in small claims court shall be resolved by binding arbitration on an individual basis under the terms of this
              Arbitration Agreement. Unless otherwise agreed to, all arbitration proceedings shall be held in English. This Arbitration Agreement applies to you and Lotus, and to
              any affiliates, shareholders, officers, directors, employees, agents, licensors and suppliers, predecessors in interest, successors, and assigns, as well as all
              authorized or unauthorized Users of this Website.
              <br />
              <br />
              (b) Notice Requirement and Informal Dispute Resolution. Before either party may seek arbitration, the party must first send to the other party a written Notice of
              Dispute (“Notice”) describing the nature and basis of the Claim and the requested relief. A Notice to Lotus should be sent to: 2727 Maricopa Street, Torrance, CA
              90503 attention: Daron Matsuura. After the Notice is received, you and Lotus may attempt to resolve the Claim informally. If you and Lotus do not resolve the Claim
              within thirty (30) days after the Notice is received, either party may begin an arbitration proceeding. The amount of any settlement offer made by any party may not
              be disclosed to the arbitrator until after the arbitrator has determined the amount of the award, if any, to which either party is entitled.
              <br />
              <br />
              (c) Arbitration Rules. Arbitration shall be initiated through the American Arbitration Association (“AAA”), an established alternative dispute resolution provider
              (“ADR Provider”) that offers arbitration as set forth in this section. If AAA is not available to arbitrate, the parties shall agree to select an alternative ADR
              Provider. The rules of the ADR Provider shall govern all aspects of the arbitration, including but not limited to the method of initiating or demanding arbitration,
              except to the extent such rules are in conflict with these Terms of Use. The AAA Commercial Arbitration Rules and Mediation Procedures-Expedited Procedures
              (“Arbitration Rules”) governing the arbitration are available online at www.adr.org or by calling the AAA at 1-800-778-7879. The arbitration shall be held in Los
              Angeles, California and conducted by a single, neutral arbitrator. Any judgment on the award rendered by the arbitrator may be entered in any court of competent
              jurisdiction. Each party shall bear its own costs (including attorney’s fees) and disbursements arising out of the arbitration and shall pay an equal share of the
              fees and costs of the ADR Provider.
              <br />
              <br />
              (d) Time Limits. If you or Lotus pursue arbitration, the arbitration action must be initiated and/or demanded within the statute of limitations (i.e., the legal
              deadline for filing a claim) and within any deadline imposed under the AAA Rules for the pertinent claim.
              <br />
              <br />
              (e) Authority of Arbitrator. If arbitration is initiated, the arbitrator will decide the rights and liabilities, if any, of you and Lotus, and the dispute will not be
              consolidated with any other matters or joined with any other cases or parties. The arbitrator shall have the authority to grant motions dispositive of all or part of
              any claim. The arbitrator shall have the authority to award monetary damages, and to grant any non-monetary remedy or relief available to an individual under
              applicable law, the AAA Rules, and the Terms of Use. The arbitrator will not have the power to award damages in excess of the limitation on actual compensatory,
              direct damages set forth in these Terms of Use and may not multiply actual damages or award punitive damages or any other damages that are specifically excluded under
              these Terms of Use, and each party hereby irrevocably waives any claim to such damages. The arbitrator may, in his or her discretion, assess costs and expenses
              (including the reasonable legal fees and expenses of the prevailing party) against any party to a proceeding. Any party refusing to comply with an order of the
              arbitrator will be liable for costs and expenses, including attorneys' fees, incurred by the other party in enforcing the award. The arbitrator shall issue a written
              award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded. The
              arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have. The award of the arbitrator is final and binding
              upon you and Lotus.
              <br />
              <br />
              (f) Waiver of Jury Trial. THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead
              electing that all Claims shall be resolved by arbitration under this Arbitration Agreement. Arbitration procedures are typically more limited, more efficient and less
              costly than rules applicable in a court and are subject to very limited review by a court. If any litigation should arise between you and Lotus in any state or
              federal court in a suit to vacate or enforce an arbitration award or otherwise, unless then prohibited by California law, YOU AND Lotus WAIVE ALL RIGHTS TO A JURY
              TRIAL, instead electing that the dispute be resolved by a judge.
              <br />
              <br />
              (g) Waiver of Class or Consolidated Actions. ALL CLAIMS WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT
              ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER USER. <br />
              <br />
              (h) Confidentiality. All aspects of the arbitration proceeding, including but not limited to the award of the arbitrator and compliance therewith, shall be strictly
              confidential. The parties agree to maintain confidentiality unless otherwise required by law. This paragraph shall not prevent a party from submitting to a court of
              law any information necessary to enforce this Agreement, to enforce an arbitration award, or to seek injunctive or equitable relief.
              <br />
              <br />
              (i) Severability. If any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable by a court of competent jurisdiction, then
              such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Agreement shall continue in full force and effect.
              <br />
              <br />
              (j) Right to Waive. Any or all of the rights and limitations set forth in this Arbitration Agreement may be waived by the party against whom the claim is asserted.
              Such waiver shall not waive or affect any other portion of this Arbitration Agreement.
              <br />
              <br />
              (k) Survival of Agreement. This Arbitration Agreement will survive the termination of your relationship with Lotus or the termination of these Terms of Use.
              <br />
              <br />
              (l) Small Claims Court. Notwithstanding the foregoing, either you or Lotus may bring an individual action in small claims court.
              <br />
              <br />
              (m) Emergency Equitable Relief. Notwithstanding the foregoing, either party may seek emergency equitable relief before a state or federal court in order to maintain
              the status quo pending arbitration. A request for interim measures shall not be deemed a waiver of any other rights or obligations under this Arbitration Agreement.
              <br />
              <br />
              (n) Claims Not Subject to Arbitration. Notwithstanding the foregoing, claims of defamation or libel, violation of the Computer Fraud and Abuse Act, and infringement
              or misappropriation of the other party’s patent, copyright, trademark or trade secrets shall not be subject to this Arbitration Agreement.
              <br />
              <br />
              (o) Courts. In any circumstances where the foregoing Arbitration Agreement permits the parties to litigate in court, the parties hereby agree to submit to the
              personal jurisdiction of the courts located within Los Angeles County, California, for such purpose as provided above.
              <br />
              <br />
              <h2>Waiver</h2>
              <br />
              No waiver by Lotus of any right under or term or provision of these Terms of Use will be deemed a waiver of any other right, term, or provision of these Terms of Use
              at the time of such waiver or a waiver of that or any other right, term, or provision of these Terms of Use at any other time. Any failure by us to assert a right or
              provision under these Terms of Use will not constitute a waiver of such right or provision.
              <br />
              <br />
              <h2>Severability</h2>
              <br />
              If an arbitrator or court of competent jurisdiction finds any provision of these Terms of Use to be invalid, illegal or unenforceable for any reason, such provision
              will be eliminated or will be limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.
              <br />
              <br />
              <h2>Entire Agreement</h2>
              <br />
              These Terms of Use constitute the sole and entire agreement between you and us with respect to the Website and supersede all prior and contemporaneous understandings,
              agreements, representations and warranties, both written and oral, with respect to the Website.
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsAndConditions;
