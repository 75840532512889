import React, { useState, useEffect } from 'react';
import { MDBInputGroup, MDBBtn, MDBCol, MDBInput } from 'mdbreact';
import subscribeToNewsLetter from '@Requests/subscribeToNewsLetter';
import initialStateFooter from './initialStateFooter';
import { Link } from 'react-router-dom';

const { success, error, warning } = require('@Comps/_helper/SweetAlerts/alerts');

/**
 * Newsletter subscription input
 * acceptAll enable user to accept term and newsletter
 * @param {Boolean} props.acceptAll
 * @param {String} props.email
 * @param {Boolean} props.enableBtn
 */
export default function NewsLetterSubFooter(props) {
  const { acceptAll, email, enableBtn } = props || {};
  const [state, setState] = useState(initialStateFooter);

  useEffect(() => {
    if (acceptAll) {
      setState({ email, terms: true, newsletter: true });
    }
  }, []);

    const print = async () => {
    const w = window.open();
    let html = '<!DOCTYPE HTML>';
    html += '<html lang="en-us">';
    html += '<head><style></style></head>';
    html += '<body oncontextmenu="return false;">';
    html +=
      '<img className="coupon" src="https://lotus-coupons.s3.amazonaws.com/signup+coupon-2022.jpg" width="60%"></img>';
    html += '</body>';
    w.document.write(html);
    setTimeout(() => {
      w.print();
      history.push('/Quarterly93FkePOEx934CJDDKe');
      localStorage.setItem('tempToken', randomString());
    }, 1000);
  };

  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div className="popup__p footerTextSmall">I own a</div>
        <div><MDBInput onChange={(e) => setState({...state, tag: e.target.id})} type="radio" id="Dog" name="breedType" value="HTML" label="Dog" className="btn nextBtn"/></div>
        <div><MDBInput onChange={(e) => setState({...state, tag: e.target.id})} type="radio" id="Cat" name="breedType" value="HTML" label="Cat" className="btn nextBtn"/></div>
        <div><MDBInput onChange={(e) => setState({...state, tag: e.target.id})} type="radio" id="Both" name="breedType" value="HTML" label="Both" className="btn nextBtn"/></div>
  </div>
      <MDBInputGroup
        onChange={({ target }) => setState({ ...state, firstName: target.value })}
        outline
        className="my-2"
        hint="First Name"
      />
      <MDBInputGroup
        hint="Email address"
        onChange={({ target }) => setState({ ...state, email: target.value })}
        append={
          enableBtn ? (
            <MDBBtn
              color="warning"
              onClick={() => {subscribeToNewsLetter({ email: state.email, tag:state.tag, firstName:state.firstName })}}
              className="newsletterBtn m-0 px-3 py-2 z-depth-0">
              Submit
            </MDBBtn>
          ) : null
        }
      />
      <div className="icons text-right">
        <a href="https://www.facebook.com/LotusPetFoods/" target="_blank" rel="noopener">
          <i className="facebook-footer footer-icon fab fa-facebook fa-xs" />
        </a>
        <a href="https://twitter.com/lotuspetfoods" target="_blank" rel="noopener">
          <i className="footer-icon fab fa-twitter fa-xs" />
        </a>
        <a href="https://www.instagram.com/lotuspetfoods/" target="_blank" rel="noopener">
          <i className="footer-icon fab fa-instagram fa-xs" />
        </a>
        <a href="https://www.youtube.com/channel/UC6sjHqH-HibPj3hqk211wuQ" target="_blank" rel="noopener">
          <i className="footer-icon fab fa-youtube fa-xs" />
        </a>
        <Link to="/contact-us" href="/contact-us">
          <i className="mail-footer-icon far fa-envelope fa-xs" />
        </Link>
      </div>
    </>
  );
}
