module.exports = {
  /**
   * @param {Object[]} reviewsArr
   * @return {Number} average star rating
   */
  countNumberOfStars: (reviewsArr) => {
    let numbersOfReviews = reviewsArr.length;
    return (
      reviewsArr.reduce((totalStars, review) => {
        if (!review.approved) numbersOfReviews -= 1;
        return totalStars + review.rating;
      }, 0) / numbersOfReviews
    );
  },

  /**
   * @param {Object} reviewsArr
   * @return {Number} total reviews
   */
  countNumbersOfApprovedReviews: (reviewsArr) => {
    let numbersOfReviews = reviewsArr.length;
    reviewsArr.forEach((review) => {
      if (!review.approved) numbersOfReviews -= 1;
    });
    return numbersOfReviews;
  },

  /**
  * Build out review object
  * @param {Object[]} reviewsArr
  * @return {Object[]}
  * [{
      ratingStar: 5 stars || 4 stars etc
      averageRatingVal: 100% / 50%
    }]
  */
  reviewBreakdown: (reviewsArr) => {
    const ratings = [5, 4, 3, 2, 1];
    let numbersOfReviews = reviewsArr.length;

    return ratings.map((rating) => {
      const temp = {};
      let numberOfThisReview = 0;
      reviewsArr.forEach((review) => {
        numberOfThisReview += 1;
        if (!review.approved){
          numbersOfReviews -= 1;
          numberOfThisReview -= 1;}
        const averageReviews = Math.floor(parseFloat(numberOfThisReview / numbersOfReviews) * 100);
        if (review.rating === rating) {
          Object.assign(temp, { rating: review.rating, averageReviews });
        } else {
          temp.rating = rating;
        }
      });
      return temp;
    });
  },

  renderReviewImages: (imgArr) => {
    if (imgArr[0] === '' || imgArr.length < 1) return null;
    if (imgArr[0].charAt(0) === '[') return JSON.parse(imgArr[0]);
    return imgArr;
  },
};

