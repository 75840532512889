import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class ProperTopper extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
      }

  render() {
    return (
        <div>
                <div className="pb-3 blog-margin-adjustment-desktop">
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/propertopper-banner.png'} 
                        className="banner img-fluid w-100" 
                        alt="lotus Pet Foods - Vacation with Pet"/>
                    </div>
                </MDBAnimation>
                </div>

            <div className='container mt-5'>
                <p style={{"color": "grey"}}>Dec, 01, 2021</p>
            </div>

            <div className='container mt-5 text-center d-none-mobile'>
                <h1><span className="LemonMilkbold-font blog-hb">Toppers</span></h1>
            </div>
       
            <div className='container mt-5 text-center d-none-desktop'>
                <h1><span className="LemonMilkbold-font blog-h">Toppers</span></h1>
            </div>
  
            <div className='container' style={{"marginTop": "60px"}}>
                <div className='futura-font blog-p'>
                    <p>Adding variety to your pets bowl is becoming increasingly popular – because there are so many benefits to it!</p>
                    <br />
                    <p>From adding moisture and flavor, to boosting protein and cancer fighting antioxidants – there are a lot of reasons 
                    why you should add a delicious and nutritious topper to your pets dry kibble. We offer many different flavor options 
                    for topping a kibble, we will start off by talking about our cans.</p>
                    <br />
                    <p>Our cans offer a protein punch that your dog or cat will gobble up, 80-95% quality protein in all of our cans will 
                    help boost the essential amino acids, which are the building blocks of your pets body! Many of our cans have whole 
                    chunks of fruits and veggies in them as well, so the addition of antioxidants could help keep a pep in your pets step longer.</p>
                     <br />
                     <p>We especially love our loafs or pates for topping, a couple of table spoons onto your oven baked kibble, a little mixing – you’ll 
                        have a bowl full of coated kibble that your dog or cat will love!</p>
                </div>
             </div>
             <MDBAnimation type="fadeIn">
                <div className='text-right'>
                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/propertopper-img-01.jpg'} 
                    className="img-fluid" 
                    alt="lotus Pet Foods - Vacation with Pet"
                    width="80%"
                    />
                </div>
            </MDBAnimation>
            <div className='container'>
                <div className='futura-font blog-p'>
                    <p>An even better option than cans would be topping your pets kibble with our 95% raw meat diets.
                    many different flavors to pick from, no ground bone or veggies, our raw diets offer high protein 
                    and the added benefits of raw fresh meat!</p>
                    <br />
                    <p>Our 3.75oz tubs are the perfect way for a messy free, topping option – just pop it, plop it & recycle it. 
                        Did you know the addition of raw meat to a pets diet can help their dental health? Raw meat contains natural 
                        enzymes that actually HELP to break down plaque and tartar to help keep their teeth and gums in tip top shape.</p>
                    <br />
                </div>
             </div>
             <MDBAnimation type="fadeIn">
                <div className='text-right'>
                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/propertopper-img-02.jpg'} 
                    className="img-fluid" 
                    alt="lotus Pet Foods - Vacation with Pet"
                    width="80%"
                    />
                </div>
            </MDBAnimation>
            <div className='container'>
                <div className='futura-font blog-p'>
                    <p>There are many other options to top your pets bowl as well, whether its goat milk, bone broth, or raw fruits and veggies,
                        we believe that topping your pets food with one or more of these things can be very beneficial to the happiness and 
                        wellbeing, of your pets life.</p>
                    <br />
                </div>
             </div>
             <MDBAnimation type="fadeIn">
                <div className='text-right'>
                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/propertopper-img-03.jpg'} 
                    className="img-fluid" 
                    alt="lotus Pet Foods - Vacation with Pet"
                    width="80%"
                    />
                </div>
            </MDBAnimation>

        </div>
    );
  }
}

export default ProperTopper;