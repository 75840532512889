const { default: Title } = require("@Comps/ProductPages/MainProductPage/OverviewInfo/Title");

const updatePictureArr = (arr) => {
  if (arr === '' || arr[0] === '') return [];
  if (typeof arr[0] === 'string' && arr[0][0] === '[') return JSON.parse(arr[0]);
  return arr;
};

const convertToMDBTableData = (reviewObj) => {
  const reviewHeaders = {
    'products_info.name': 'Product Name',
    'users_info.first_name': 'Reviewer Name',
    rating: 'Rating',
    approved: 'Approved?',
    review: 'Review',
    title: 'Review Title',
    review_date: 'Review Date',
    _id: 'Review Id',
  };

  return Object.keys(reviewObj).map((key, i) => {
    const tempObj = {};
    if (reviewHeaders.hasOwnProperty(key)) {
      const rowInfo = { col0: reviewHeaders[key], col1: `${reviewObj[key]}` };
      Object.assign(tempObj, rowInfo);
      //{col0: 'Review Id', col1: 4,}
      //{col0: 'Review Title', col1: message}
    }
    //console.log(tempObj);
    return tempObj;
  
  });
};
module.exports = { updatePictureArr, convertToMDBTableData };
