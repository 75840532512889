import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact';
import LoginComponent from './LoginFields.jsx';

export default function SignIn(props) {
  const init = () => {
    window.scrollTo(0, 0);
    window.fbAsyncInit = function() {
      FB.init({
        appId: '1030022257187734',
        cookie: true,
        xfbml: true,
        version: 'v3.2',
      });
    }.bind(this);

    ((d, s, id) => {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  };

  const { history } = props;
  init();
  return (
    <MDBContainer className="sign-in-modal">
      <MDBRow center>
        <MDBCol size="12">
          <MDBAnimation type="fadeIn">
            <MDBRow center className="sign-in--background-white">
              <LoginComponent toggleSignInModal={props.toggleSignInModal} history={history} position="text-center my-0" />
            </MDBRow>
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
