import React, { Fragment } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';

export default function Purchase(props) {
  return (
    <MDBCol>
      <MDBContainer>
        <MDBRow>
          <MDBCol
            size="3"
            className="quantity-container mt-1 d-flex align-content-center justify-content-center">
            <MDBBtn
              flat
              circle
              className="m-auto quantity-sign p-1"
              onClick={props.decrementQuantity}>
              -
            </MDBBtn>
            <span className="m-auto quantity-number">{props.quantities}</span>
            <MDBBtn
              flat
              circle
              className="m-auto quantity-sign p-1"
              onClick={props.incrementQuantity}>
              +
            </MDBBtn>
          </MDBCol>
          <MDBCol size="9">
            <MDBBtn onClick={props.addToCart}>Add to Cart</MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBCol>
  );
}
