const { checkString } = require('../../_helper/index.jsx');

const overviewInformation = {
  'raw-food': {
    title: 'raw food for cats',
    banner: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/raw-banner.png',
    description: `Coastal breezes, lush central valley farmlands, rolling green hills packed with vineyards...what better 
    place to think local? That’s exactly what we do every time we make a batch of one of our Lotus raw recipes for cats 
    here in our own Southern California craft raw-ery. These are real cuts of meats and poultry, as well as whole fish, 
    just like you would find in your own kitchen. Our recipes are ground and filled locally in small batches by our own 
    staff (yes, people we know and like). And our meat, poultry and whole sardines are of course hormone free, antibiotic 
    free, vegetarian fed and wild caught. It just seems natural to us, but it’s kind of a big deal, because so many other 
    pet foods don’t use this same quality and high standard for their meat, poultry, and fish. So, every time you feed your 
    pet Lotus, you can pat yourself on the back for feeding the very best ingredients and doing your part to support the local economy.
    `,
  },
  /*raw: {
    title: 'raw food for cats',
    banner: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/raw-banner.png',
    description: `Coastal breezes, lush central valley farmlands, rolling green hills packed with
    vineyards...what better place to think local? Thatʼs exactly what we do every time we
    make a batch of one of our Lotus raw recipes for cats here in our own Southern
    California craft raw-ery. These are real cuts of meats and poultry, like you would
    find in your own kitchen, ground and filled locally in
    small batches by our own staff (yes, people we know and like). And our cuts of meat
    and poultry are of course hormone, antibiotic free and vegetarian fed. It just seems
    natural to us, but itʼs kind of a big deal, because so many other pet foods don’t use
    this same quality and high standard for their meat and poultry. So every time you feed
    your pet Lotus, you can pat yourself on the back for feeding the very best ingredients
    and doing your part to support the local economy.`,
  },*/
  kibble: {
    title: 'cat kibble',
    banner:
      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Cat+Food/Kibble/oven-baked-banner-min.png',
    description: `Lotus Oven-Baked kibble for cats is made by a process so uncommonly wholesome it has
  an “®” after its name. Itʼs called Baked-In Goodness®(see?), and itʼs the big reason
  Lotus tastes delicious and is closer to homemade. Oven-baking means Lotus Oven-Baked
  kibble is less processed, has less starch, has more fresh meats, fish and poultry than
  most other kibbles, and hey, it just plain tastes better. Itʼs never extruded like
  most other cat foods. What the heck is extrusion, you say? Itʼs the process of using
  heat and high pressure (three times atmospheric pressure!) to form kibble in dies.
  Then theyʼre sprayed with “flavor” and fats. Plastic is often formed by extrusion,
  too. Creepy, right? Good thing someone (hint, hint) is committed to always oven-baking
  wholesome food for your cat.`,
  },
  pate: {
    title: 'CAT PÂTÉS',
    banner:
      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Cat+Food/Pates/cat-pate-banner-min.png',
    description: `Coastal breezes, lush central valley farmlands, rolling green hills packed with
  vineyards...what better place to think local? Thatʼs exactly what we do every time we
  make a can of Lotus pâtés here in our own Southern California micro-cannery. These are
  real, holistic ingredients, canned locally in small batches by our own staff (yes,
  people we know and like). It just seems natural to us, but itʼs kind of a big deal,
  because so many other pet foods are factory-canned overseas. So every time you feed
  your pet Lotus, you can pat yourself on the back for reducing your petʼs carbon “paw”
  print, and for supporting our economy. Oh, and youʼll also get
  “my-pet-is-environmentally-responsible” bragging rights.`,
  },
  'just-juicy': {
    title: 'just juicy',
    banner:
      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Just+Juicy/just-juicy-banner.png',
    description: `Coastal breezes, lush central valley farmlands, rolling green hills packed with
  vineyards...what better place to think local? Thatʼs exactly what we do every time we
  make a can of Lotus Stew for Dogs here in our own Southern California micro-cannery.
  These are real, holistic ingredients, canned locally in small batches by our own staff
  (yes, people we know and like). It just seems natural to us, but itʼs kind of a big
  deal, because so many other pet foods are factory-canned overseas. So every time you
  feed your pet Lotus, you can pat yourself on the back for reducing your petʼs carbon
  “paw” print, and for supporting our economy. Oh, and youʼll also get
  “my-pet-is-environmentally-responsible” bragging rights.`,
  },
  juicy: {
    title: 'just juicy',
    banner:
      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Cat+Food/Just+Juicy/just-juicy-banner.png',
    description: `Coastal breezes, lush central valley farmlands, rolling green hills packed with
      vineyards...what better place to think local? Thatʼs exactly what we do every time we
      make a can of Lotus pâtés here in our own Southern California micro-cannery. These are
      real, holistic ingredients, canned locally in small batches by our own staff (yes,
      people we know and like). It just seems natural to us, but itʼs kind of a big deal,
      because so many other pet foods are factory-canned overseas. So every time you feed
      your pet Lotus, you can pat yourself on the back for reducing your petʼs carbon “paw”
      print, and for supporting our economy. Oh, and youʼll also get
      “my-pet-is-environmentally-responsible” bragging rights.`,
  },
  'regular-bites': {
    title: 'regular bites',
    banner:
      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Regular+Bites/dog-regular-bites-banner.png',
    description: `Lotus Oven-Baked kibble for dogs is made by a process so uncommonly wholesome it has
  an “®” after its name. Itʼs called Baked-In Goodness®(see?), and itʼs the big reason
  Lotus tastes delicious and is closer to homemade. Oven-baking means Lotus Oven-Baked
  kibble is less processed, has less starch, has more fresh meats, fish and poultry than
  most other kibbles, and hey, it just plain tastes better. Itʼs never extruded like
  most other dog foods. What the heck is extrusion, you say? Itʼs the process of using
  heat and high pressure (three times atmospheric pressure!) to form kibble in dies.
  Then theyʼre sprayed with “flavor” and fats. Plastic is often formed by extrusion,
  too. Creepy, right? Good thing someone (hint, hint) is committed to always oven-baking
  wholesome food for your dog.`,
  },
  'small-bites': {
    title: 'small bites',
    banner:
      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Small+Bites/small-bites-banner.png',
    description: `Lotus Oven-Baked kibble for dogs is made by a process so uncommonly wholesome it has
  an “®” after its name. Itʼs called Baked-In Goodness®(see?), and itʼs the big reason
  Lotus tastes delicious and is closer to homemade. Oven-baking means Lotus Oven-Baked
  kibble is less processed, has less starch, has more fresh meats, fish and poultry than
  most other kibbles, and hey, it just plain tastes better. Itʼs never extruded like
  most other dog foods. What the heck is extrusion, you say? Itʼs the process of using
  heat and high pressure (three times atmospheric pressure!) to form kibble in dies.
  Then theyʼre sprayed with “flavor” and fats. Plastic is often formed by extrusion,
  too. Creepy, right? Good thing someone (hint, hint) is committed to always oven-baking
  wholesome food for your dog.`,
  },
  treats: {
    title: 'dog treats',
    banner:
      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Treats/dog-treats-banner-min.png',
    description: `Oven-baking means less processed, has less starch, has more fresh meats, fish and
  poultry than most other kibbles, and hey, it just plain tastes better.`,
  },
  stew: {
    title: 'stews for dogs',
    banner:
      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Stews/dog-stew-banner-min.png',
    description: `Coastal breezes, lush central valley farmlands, rolling green hills packed with
  vineyards...what better place to think local? Thatʼs exactly what we do every time we
  make a can of Lotus Stew for Dogs here in our own Southern California micro-cannery.
  These are real, holistic ingredients, canned locally in small batches by our own staff
  (yes, people we know and like). It just seems natural to us, but itʼs kind of a big
  deal, because so many other pet foods are factory-canned overseas. So every time you
  feed your pet Lotus, you can pat yourself on the back for reducing your petʼs carbon
  “paw” print, and for supporting our economy. Oh, and youʼll also get
  “my-pet-is-environmentally-responsible” bragging rights.`,
  },
  loaf: {
    title: 'loafs for dogs',
    banner:
      'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Loafs/dog-loaf-banner-min.png',
    description: `Coastal breezes, lush central valley farmlands, rolling green hills packed with
    vineyards...what better place to think local? Thatʼs exactly what we do every time we
    make a can of Lotus loafs recipes for dogs here in our own Southern California
    micro-cannery. These are real, holistic ingredients, canned locally in small batches
    by our own staff (yes, people we know and like). It just seems natural to us, but itʼs
    kind of a big deal, because so many other pet foods are factory-canned overseas. So
    every time you feed your pet Lotus, you can pat yourself on the back for reducing your
    petʼs carbon “paw” print, and for supporting our economy. Oh, and youʼll also get
    “my-pet-is-environmentally-responsible” bragging rights.`,
  },
  /*'raw-foods': {
    title: 'raw food for dogs',
    banner: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/overview_banner.jpg',
    description: `Coastal breezes, lush central valley farmlands, rolling green hills packed with vineyards...what better place to think local? 
    That's exactly what we do every time we make a batch of one of our Lotus raw recipes for dogs here in our own Southern California “raw-ery”. 
    These are real cuts of These are real cuts of meats and poultry, like you would
    find in your own kitchen, mixed together and filled locally in small batches 
    by our own staff (yes, people we know and like). And our cuts of meat and poultry are of course hormone, antibiotic free and vegetarian fed. It just seems natural to us, 
    but it's kind of a big deal, because so many other pet foods don't use this same quality and high standard for their meat and poultry. So, every time you feed your pet Lotus, 
    you can pat yourself on the back for feeding, the very best ingredients and doing your part so support the local economy.`,
  },*/
  /*tripe:{
    title: 'RAW TOPPERS FOR DOGS AND CATS',
    banner: 'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Dog+Food/raw/tripe-banner.png',
    description: `Coastal breezes, lush central valley farmlands, rolling green hills packed with vineyards...what better place to think local? 
    That’s exactly what we do every time we make a batch of one of our Lotus raw toppers for dogs and cats here in our own Southern California craft raw-ery. 
    These simple raw toppers are made from ingredients like Beef Green Tripe. We grind and fill these ingredients locally in small batches by our own staff 
    (yes, people we know and like). And grass-fed beef green tripe of course hormone free and antibiotic free. It just seems natural to us, but it’s kind of 
    a big deal, because so many other pet foods don’t use this same quality and high standard for their proteins. So, every time you feed your pet Lotus, you 
    can pat yourself on the back for feeding the very best ingredients and doing your part to support the local economy.`,
  },*/
  topper:{
    title: 'RAW TOPPERS FOR DOGS AND CATS',
    banner: 'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Dog+Food/raw/tripe-banner.png',
    description: `Coastal breezes, lush central valley farmlands, rolling green hills packed with vineyards...what better place to think local? 
    That’s exactly what we do every time we make a batch of one of our Lotus raw toppers for dogs and cats here in our own Southern California craft raw-ery. 
    These simple raw toppers are made from ingredients like Beef Green Tripe. We grind and fill these ingredients locally in small batches by our own staff 
    (yes, people we know and like). And grass-fed beef green tripe of course hormone free and antibiotic free. It just seems natural to us, but it’s kind of 
    a big deal, because so many other pet foods don’t use this same quality and high standard for their proteins. So, every time you feed your pet Lotus, you 
    can pat yourself on the back for feeding the very best ingredients and doing your part to support the local economy.`,
  },
  toppers:{
    title: 'RAW TOPPERS FOR CATS AND DOGS',
    banner: 'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Dog+Food/raw/tripe-banner.png',
    description: `Coastal breezes, lush central valley farmlands, rolling green hills packed with vineyards...what better place to think local? 
    That’s exactly what we do every time we make a batch of one of our Lotus raw toppers for dogs and cats here in our own Southern California craft raw-ery. 
    These simple raw toppers are made from ingredients like Beef Green Tripe. We grind and fill these ingredients locally in small batches by our own staff 
    (yes, people we know and like). And grass-fed beef green tripe of course hormone free and antibiotic free. It just seems natural to us, but it’s kind of 
    a big deal, because so many other pet foods don’t use this same quality and high standard for their proteins. So, every time you feed your pet Lotus, you 
    can pat yourself on the back for feeding the very best ingredients and doing your part to support the local economy.`,
  },
};

/**
 *
 * @param {String} foodType
 * @return {Object} return object key from object above
 */
const changeIngredientType = (foodType) => {
  return overviewInformation[foodType];
};

/**
 *
 * @param {Object} resObj response Obj from DB
 * @return {Object} modified image for product
 */
const modifyResProduct = async (resObj) => {
  const PRODUCT_SMALL = 'small';
  const PRODUCT_MEDIUM = 'medium';
  const PRODUCT_LARGE = 'large';
  const PRODUCT_SIZE = 'size';
  const PRODUCT_COLOR = 'color_palette';
  const promise = new Promise((resolve, reject) => {
    try {
      const mappedNewState = resObj.map((product, i) => {
        //console.log(product);
        const newState = {};
        const productAdditions = product.products_additions[0];
        const productReviews = product.product_reviews;

        let rating = 0;
        let totalStars = 0;

        Object.keys(productAdditions).forEach((productKeys) => {
          if (newState[productKeys] === undefined) {
            newState[productKeys] = productAdditions[productKeys];
            if (productAdditions[PRODUCT_LARGE] !== null) {
              newState.image = productAdditions[PRODUCT_LARGE].front.thumb;
            } else if (productAdditions[PRODUCT_MEDIUM] !== null) {
              newState.image = productAdditions[PRODUCT_MEDIUM].front.thumb;
            } else {
              newState.image = productAdditions[PRODUCT_SMALL].front.thumb;
            }
            newState.size = productAdditions[PRODUCT_SIZE];
            newState.color = productAdditions[PRODUCT_COLOR];
          }
        });

        productReviews.forEach((review) => {
          if (review.approved) {
            rating += 1;
            totalStars += review.rating;
            newState.ratings = rating;
          }
        });

        newState.starsRatingVal = totalStars / rating;
        newState.name = checkString(product.name);
        newState.product_id = product.product_id;
        delete newState[PRODUCT_SMALL];
        delete newState[PRODUCT_LARGE];
        delete newState[PRODUCT_MEDIUM];
        delete newState[PRODUCT_COLOR];
        return newState;
      });
      resolve(mappedNewState);
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
  // return newState;
  return promise;
};

module.exports = { overviewInformation, changeIngredientType, modifyResProduct };
