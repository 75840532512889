import React, { Component } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTabContent,
  MDBTabPane,
  MDBNav,
  MDBNavItem,
  MDBAnimation,
  MDBSelect,
  MDBBtn,
} from 'mdbreact';
import { Link } from 'react-router-dom';
import Axios from 'axios';

import IsLoading from '../../../../IsLoading/IsLoading.jsx';
import IsLoadingChild from '../../../../IsLoading/IsLoadingChild.jsx';
import ProductComponents from './ProductComponents/ProductComponents.jsx';

const { setToken } = require('../../../../_helper/index.jsx');
const { categorizedProducts, handleDrag, convertDbObj } = require('./helper.jsx');
const { success, error, warning } = require('../../../../_helper/SweetAlerts/alerts.jsx');
const {
  endPoint: { productsEndPoint },
} = require('../../../../_helper/stringVars');

const token = { headers: setToken('admin') };

export default class AdminProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: {},
      currentProduct: {},
      currentProductId: 1,
      setProduct: false,
      currentTab: '0',
      productLoaded: false,
      imagesModalOpen: false,
    };
  }

  componentDidMount() {
    this.getAllProducts();
  }

  getAllProducts = () => {
    Axios.get(`${productsEndPoint.admin}/get-all-products`, token)
      .then(({ data }) => {
        categorizedProducts(this, data);
      })
      .catch((err) => {
        console.error(err);
        const { status } = err.res;
        if (status === 500) {
          return error({ title: 'Error', text: 'Server Error, please try again', button: 'Okay.' });
        }
      });
  };

  getProductInfo = (productId) => {
    const config = {
      headers: setToken('admin'),
      params: { value: productId },
    };
    Axios.get(`${productsEndPoint.admin}/get-products-info`, config)
      .then((res) => {
        const product = res.data;
        this.setState({
          currentProduct: product,
          productLoaded: true,
        });
      })
      .catch((err) => {
        const { status } = err.res;
        if (status === 500) return error({ title: 'Error', text: 'Server Error', button: 'Okay' });
        return error({
          title: 'Error',
          text: '400 or 404 has occurred. Please try again.',
          button: 'Okay.',
        });
      });
  };

  getOptionVal = (context, value) => {
    const [productId] = value;
    setTimeout(() => {
      context.getProductInfo(productId);
      context.setState({
        currentProductId: productId,
      });
    }, 500);
  };

  toggleTab = (tab) => {
    const { currentTab } = this.state;
    if (currentTab !== tab) {
      this.setState({ currentTab: tab, currentProduct: {}, productLoaded: false });
    }
  };

  /* React Tags */
  dragReactTagInfo = (tagInfo, currPos, newPos, colToUpdate, colName) => {
    const { currentProduct } = this.state;
    const newColInfo = { [colName]: handleDrag(tagInfo, currPos, newPos, colToUpdate) };
    const changedProduct = Object.assign(currentProduct, newColInfo);
    this.setState({
      currentProduct: changedProduct,
    });
  };

  addColInfoReactTag = (tagInfo, colName) => {
    const { currentProduct } = this.state;
    currentProduct[colName].push(tagInfo.text);
    this.setState({
      currentProduct,
    });
  };

  deleteColInfoReactTag = (tagInfo, colName) => {
    const { currentProduct } = this.state;
    currentProduct[colName].splice(tagInfo, 1);
    this.setState({
      currentProduct,
    });
  };
  /* End of ReactTag */

  getInputFields = (e, colName, type) => {
    const { value } = e.target;
    const label = e.target.attributes['aria-label'].value;
    const { currentProduct } = this.state;
    if (colName === 'feeding_guidelines') {
      currentProduct[colName][type][label] = value;
    } else if (type === 'anlCal') {
      currentProduct[colName].calories[label] = value;
    } else {
      currentProduct[colName][label] = value;
    }
    this.setState({
      currentProduct,
    });
  };

  saveProductChanges = () => {
    const productInfoObj = this.state.currentProduct;
    Axios.put(`${productsEndPoint.admin}/update-products-info`, { params: productInfoObj }, token)
      .then((product) => {
        const diff = product.data;
        if (Object.keys(diff).length < 1) {
          return warning({ title: 'Warning', text: 'No updates.', button: 'Okay.' });
        }
        return success({ title: 'Succcess!', text: 'Product has been updated.' });
      })
      .catch((err) => {
        console.error(err);
        const { status } = err.res;
        if (status === 500) {
          return error({ title: 'Error', text: 'Server Error. Please try again', button: 'Okay.' });
        }
        return error({ title: 'Error', text: '404. Not Found.', button: 'Okay.' });
      });
  };

  /* render Components */
  renderProductComponent = () => {
    const { state } = this;
    if (state.productLoaded) {
      return (
        <ProductComponents
          product_id={state.currentProductId}
          dragReactTagInfo={this.dragReactTagInfo}
          product={state.currentProduct}
          addColInfoReactTag={this.addColInfoReactTag}
          deleteColInfoReactTag={this.deleteColInfoReactTag}
          getInputFields={this.getInputFields}
          saveProductChanges={this.saveProductChanges}
        />
      );
    }
    if (Object.keys(state.currentProduct).length < 1) {
      return (
        <MDBRow center>
          <MDBCol>
            <h2 className="admin-main__header2 text-uppercase futura-font">
              Please select a Product to continue
            </h2>
          </MDBCol>
        </MDBRow>
      );
    }
    return <IsLoadingChild />;
  };

  /* End of Render Components */

  render() {
    const category = ['dogs', 'cats'];
    const { state } = this;

    if (!state.setProduct) {
      return <IsLoading />;
    }
    return (
      <div className="admin-main__products">
        <MDBContainer className="admin-main__navtabs classic-tabs">
          <MDBAnimation type="fadeIn">
            <MDBNav classicTabs color="primary" className="mt-5">
              {category.map((petType, i) => (
                <MDBNavItem key={i}>
                  <Link
                    className={`text-dark font-weight-bolder futura-font ${
                      state.currentTab === String(i) ? 'active' : ''
                    }`}
                    to="#"
                    onClick={() => this.toggleTab(String(i))}
                    role="tab">
                    {petType}
                  </Link>
                </MDBNavItem>
              ))}
            </MDBNav>
            <MDBTabContent className="admin-main__tab-content" activeItem={state.currentTab}>
              {category.map((animal, i) => (
                <MDBTabPane key={i} tabId={`${i}`} role="tabpanel">
                  <MDBRow>
                    <MDBCol size="6" className="z-index-99">
                      <MDBSelect
                        getValue={(value) => this.getOptionVal(this, value)}
                        search
                        options={state.products[animal]}
                        selected="Choose Product"
                        label="Product"
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBAnimation type="fadeIn">{this.renderProductComponent()}</MDBAnimation>
                </MDBTabPane>
              ))}
            </MDBTabContent>
          </MDBAnimation>
        </MDBContainer>
      </div>
    );
  }
}
