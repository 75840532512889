import Axios from 'axios';
import { success, warning, error } from '@Comps/_helper/SweetAlerts/alerts';

/**
 * @exports SubscribeToNewsLetter
 * @param {String} email
 * @param {Object} history
 * @description Subscribe to Lotus Newsletter
 */
export default async function (values) {
  const { email, firstName, tag} = values || '';

  if (email === '' || email === undefined){
    return error({ title: 'Please enter a valid email', text: 'Please try again', button: 'Close' });
  } else {
    try {
      const subscriberInfo = {
        email_address: email,
        status: 'subscribed',
        merge_fields: {
          FNAME: firstName,
          //LNAME: lastName,
          //MMERGE7: zip,
          MMERGE6: tag,
        },
      };
      const { data } = await Axios.post('/client/subscribe/new-subscriber', {
        data: subscriberInfo,
      });
      const message = data;
      if (message === 'Forgotten Email Not Subscribed') {
        return warning({ title: 'Warning', text: 'Email already subscribed', button: 'Close' });
      }
      if (message === '') {
        return success({
          title: 'Success!',
          text: 'You have successfully subscribed to our newsletter',
        });
      }
      error({ title: message, text: 'Please try again', button: 'Close' });
    } catch (err) {
      error({ title: 'Error Occurred', text: 'Please try again', button: 'Close' });
    }
  }
}


