import React from 'react';
import { Link } from 'react-router-dom';

class ShareStory extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
          <img
          className="banner img-fluid"
          src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/share-story-banner.jpg'}
            />
        <div className="container share-story">
          <div className="row"> 
            <div className="col-12 mb-3">
                <div className="share-story__title LemonMilkbold-font">
                    <h1>YOUR PETS ARE HEROES TOO</h1>
                </div>
                <div className="share-story__text-info LemonMilkbold-font">
                    <h5>Share your story with Lotus on Instagram</h5>
                </div>
            </div>

            <div className="col-12 mb-3">
                <div className="share-story__text-info futura-font">
                <h6>Entry Rules:</h6>
                </div>
            </div>

            <div className="col-12 mb-5 futura-font">
              <p>
                  <p>1. Must “follow” @lotuspetfoods on instagram</p>
                  <p>2. Must “like” contest post</p>
                  <p>3. Must post photo of pet, with story about how Lotus pet foods improved their life/allergies/skin etc</p>
                  <p>4. Must tag @lotuspetfoods on instagram post featuring pet & information</p>
              </p>
            </div>

            <div className="col-12 mb-3">
                <div className="futura-font share-story-text">
                <p>Contest runs until 11:59 EST. Photo and pet information must be posted before 11:59 EST on 03/31/2022. One winner will be selected March 31st. 
                    Odds of winning are dependent on number of entries – One entry per person. Winner will be posted/announced by @lotuspetfoods on April 1st 2022. 
                    Winner will be contacted by @lotuspetfoods via direct message. Lotus Pet Foods reserves the right to cancel or terminate the contest at any 
                    time without prior notice and without cause.</p>
                </div>
            </div>
            
            <div className="col-md-6 col-sm-12">
              <button type="submit" className="gotoreview">
              <Link to="/share-story-external" href="/share-story-external" target="_blank">
                Get started
              </Link>
              </button>
            </div>
            
          </div>

        </div>
      </div>
    );
  }
}

export default ShareStory;