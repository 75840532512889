import React from 'react';
import { Link } from 'react-router-dom';
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBCardFooter,
} from 'mdbreact';

export default function StoreCard(props) {
  const { store } = props;
  const adminUrlPath = window.location.href.indexOf('sales') > -1 ? 'sales' : 'admin';

  return (
    <MDBCard>
      <MDBCardImage className="img-fluid admin-stores__store-imgs" src={store.picture_url} waves />
      <MDBCardBody>
        <MDBCardTitle className="admin-stores__header4 futura-font">{store.name}</MDBCardTitle>
        <MDBCardText>Address: {store.address}</MDBCardText>
        <MDBCardText>
          Website:{' '}
          {store.website_url ? (
            <a href={`https://${store.website_url}`}>{store.website_url}</a>
          ) : (
            'Not available.'
          )}
        </MDBCardText>
        <MDBCardText>Phone: {store.phone}</MDBCardText>
        <div className="d-flex ml-0 mt-3 mb-1">
          <Link to={`/${adminUrlPath}/main/stores/edit-store/${store._id}`}>
            <MDBBtn
              onClick={props.editStore}
              color="primary"
              className="admin-stores__edit-btn ml-0">
              Edit Store
            </MDBBtn>
          </Link>
          <MDBBtn
            onClick={(e) => props.deleteStore(store._id, store.name, e)}
            color="danger"
            className="admin-stores__edit-btn">
            Delete Store
          </MDBBtn>
        </div>
      </MDBCardBody>
      <MDBCardFooter></MDBCardFooter>
    </MDBCard>
  );
}
