const quizVideoSrc = {
  // update new id 7 (done)
  // Update new meats2.mp4 (done)
  meats: { 
    main: 'Meats',
    vid: 'bxvLHrUvJx4',
    title: 'Video Narrated by Alyssa',
    sub: 'Northeast Region Representative',
    presenterImg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/alyssa-01.png',
  },
  // update new id 4 (done)
  // *** No new video (done)
  dog: { 
    main: 'Special Needs Dog', 
    vid: '3wiMGmCfj7Y',
    title: 'Video Narrated by Devin',
    sub: 'Southwest Region Representative',
    presenterImg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/devin-01-01.png',
  },
  // update new id 10
  // *** No new video
  large_breed: {
    main: 'Large Breed',
    vid: 'NcXL40frhr8',
    title: 'Video Narrated by Ashleigh',
    sub: 'Midwest Region Representative',
    presenterImg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/alshly-01.png',
  },
  // *** No new video
  starch: {
    main: 'Starch',
    vid: '9V-rUT9uZCo',
    title: 'Video Narrated by Amanda',
    sub: 'Northeast Region Representative',
    presenterImg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/amanda-01.png',
  },
  //update new id 3
  //Update new cat video.mp4
  cat: { 
    main: 'Special needs cat',
    vid: 'tHo9fsq-3n0',
    title: 'Video Narrated by Carrie',
    sub: 'Southeast Region Representative',
    presenterImg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/carrie-01.png',
  },
  //update new id 1
  //*** No new video
  baking: { 
    main: '5 Benefits of Baking',
    vid: 'BMK7DXkLVVo',
    title: 'Video Narrated by Ashleigh',
    sub: 'Midwest Region Representative',
    presenterImg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/alshly-01.png',
  },
  //Delete
  /*oils: { 
    main: 'Oils',
    vid: 'IwqlPLvuHAE',
    title: 'Video Narrated by Carrie',
    sub: 'Southeast Region Representative',
    presenterImg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/carrie-01.png',
  },*/
  //*** No new video
  nutrition: {
    main: 'Pet food philoshophy',
    vid: 'vQMBdocVyn4',
    title: 'Video Narrated by Amanda',
    sub: 'Northeast Region Representative',
    presenterImg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/amanda-01.png',
  },
  //update new id 5
  //Update new grains.mp4
  grains: {
    main: 'Grains',
    vid: 'vv8lLjUqR90',
    title: 'Video Narrated by Devin',
    sub: 'Southwest Region Representative',
    presenterImg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/devin-01-01.png',
  },
  //update new id 2
  //Update new cannery 1221.mp4
  cannery: { 
    main: 'Cannery',
    vid: '0kTLeD-8zO0',
    title: 'Video Narrated by Alyssa',
    sub: 'Northeast Region Representative',
    presenterImg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/alyssa-01.png',
  },
  // ***No new video
  raw: {
    main: 'Cat Raw Food',
    vid: 'OovQRLW_cxY',
    title: 'Video Narrated by Amanda and Asleigh',
    sub: 'Northwest and Midwest Region Represenative',
    presenterImg:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/alshly-01.png',
  },
  //////////////////////////////////////////////////////////////
  // update new id 12 (new)
  // update new raw dog.mp4
  dog_raw: { 
    main: 'Dog Raw Food',
    vid: 'y0rJ9mn4gpY',
    title: 'Video Narrated by Devin',
    sub: 'Southwest Region Representative',
    presenterImg: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/devin-01-01.png',
  },
  //update new id 13(new) 
  //update new textures1.mp4
  textures:{ 
    main: 'Textures',
    vid: 'gobQd1xddd4',
    title: 'Video Narrated by Ashleigh',
    sub: 'Midwest Region Representative',
    presenterImg: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/alshly-01.png',
  },
  //update new id 14 (new)
  //update new toppers.mp4
  toppers: {   
    main: 'Toppers',
    vid: 'rhs0cBwwQAQ',
    title: 'Video Narrated by Carrie',
    sub: 'Southeast Region Representative',
    presenterImg:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/carrie-01.png',
  },
  // update new id 15 (new)
  // update new treats video.mp4
  treats: { 
    main: 'Treats',
    vid: 'NJZMH8qsmng',
    title: 'Video Narrated by Alyssa',
    sub: 'Northeast Region Representative',
    presenterImg:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/alyssa-01.png',
  },
  // update new id 16 (new)
  // update new tripe video.mp4
  'raw green tripe': { 
    main: 'Raw Green Tripe',
    vid: 'bPwNsLBZ9Bw',
    title: 'Video Narrated by Devin and Amanda',
    sub: 'Region Representative',
    presenterImg: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/devin-01-01.png',
  },
  //id 17 (new)
  //scenario 1.mp4
  scenario_1: { 
    main: 'Senario 1',
    vid: 'q1psYz8a9qM',
    title: 'Video Narrated by Carrie and Alyssa',
    sub: 'Region Representative',
    presenterImg: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/carrie-01.png',
  },
  //id 18 (new)
  //scenario 2.mp4
  scenario_2: { 
    main: 'Senario 2',
    vid: 'YS6MyHvjy4s',
    title: 'Video Narrated by Devin and Amanda',
    sub: 'Region Representative',
    presenterImg: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/devin-01-01.png',
  },
  //id 19 (new)
  //scenario 3.mp4
  scenario_3: { 
    main: 'Senario 3',
    vid: 'rMHpDB-1LEo',
    title: 'Video Narrated by Carrie and Asleigh', 
    sub: 'Region Representative',
    presenterImg: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/alshly-01.png',
  },
  //id 20 (new)
  //scenario 4.mp4
  scenario_4: { 
    main: 'Senario 4',
    vid: 'JhSDySrgDzs',
    title: 'Video Narrated by Carrie and Amanda',
    sub: 'Region Representative',
    presenterImg: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Quiz/presenter_icons/amanda-01.png',
  },
  }
/** @module QuizRedemption/Helper */
/**
 * @function
 * @param {String} quizName
 * @returns {String}
 */
const setCorrectQuiz = (quizName) => {
  return quizVideoSrc[quizName];
};

/**
 * @function
 * @param {Object[]} arr
 * @return {String}
 */
const generateRandomBg = (arr) => {
  return arr.sort(() => Math.random() - 0.5).slice(0, 5);
};

/**
 * @function
 * @param {Object} answersObj
 * @return {Object[][]}
 */
const createAnswersTups = (answersObj) => {
  return Object.keys(answersObj).map((answer) => {
    return { letter: answer, text: answersObj[answer] };
  });
};

/**
 *
 * @param {Object} answersObj
 * @return {Boolean}
 */
const checkAnswersLength = (answersObj) => {
  if (Object.keys(answersObj).length < 5) return false;
  return true;
};

/**
 * @function
 * @param {Object} answers
 * @return {Number}
 */
const countCorrectAnswers = (answers) => {
  let counter = 0;
  Object.keys(answers).forEach((key) => {
    if (answers[key][0] === answers[key][1]) counter += 1;
  });
  return counter;
};

/**
 * @function
 * @param {Object} answers
 * @param {Object} questionsAnswered
 * @returns {Boolean}
 */
const checkIfAllQuestionsAnswered = (answers, questionsAnswered) => {
  if (!checkAnswersLength(answers) && questionsAnswered < 5) return false;
  return true;
};

/**
 * @function
 * @param {Number | String} quizId
 * @param {Object[]} userQuizArr
 * @returns {Boolean}
 */
const checkIfQuizIsTaken = (quizId, userQuizArr) => {
  const quizArr = typeof userQuizArr === 'string' ? JSON.parse(userQuizArr) : userQuizArr;

  let taken = false;
  quizArr.forEach((quiz) => {
    // If quiz is taken, set to true
    if (quiz.quizId === quizId && quiz.taken) {
      taken = true;
    }
  });
  return taken;
};

/**
 * @function
 * @param {String} localStorage
 * @return {Boolean}
 */
const checkPermissionForPage = (localStorage) => {
  const retailerInfo = JSON.parse(localStorage.getItem('user'));
  if (localStorage.length < 1) return false;
  if (localStorage.admin) return true;
  if (localStorage.user && retailerInfo.retailer) {
    return true;
  }
};

module.exports = {
  setCorrectQuiz,
  generateRandomBg,
  createAnswersTups,
  checkAnswersLength,
  countCorrectAnswers,
  checkIfAllQuestionsAnswered,
  checkIfQuizIsTaken,
  checkPermissionForPage,
};
