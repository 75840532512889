import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function LightBox(props) {
  const { lightBoxImagesArr, lightBoxIsOpen, lightBoxImgIndex, changeImg, openLightBox } = props;
  return (
    <div className="product-main__lightbox-icon-wrapper">
      <span className="product-main__lightbox-span futura-font">
        Click Thumbs or Image to expand
      </span>
      {lightBoxIsOpen && (
        <Lightbox
          mainSrc={lightBoxImagesArr[lightBoxImgIndex]}
          nextSrc={lightBoxImagesArr[(lightBoxImgIndex + 1) % lightBoxImagesArr.length]}
          prevSrc={
            lightBoxImagesArr[
              (lightBoxImgIndex + lightBoxImagesArr.length - 1) % lightBoxImagesArr.length
            ]
          }
          onCloseRequest={openLightBox}
          onMovePrevRequest={() => changeImg('prev')}
          onMoveNextRequest={() => changeImg()}
        />
      )}
    </div>
  );
}
