import React from 'react';
import { MDBRow, MDBCol, MDBListGroup, MDBListGroupItem } from 'mdbreact';

export default function Description(props) {
  return (
    <div className="product-main__product-description">
      <MDBRow>
        <MDBCol>
          <MDBListGroup>
            <span className="product-main__span futura-font font-weight-normal">Features:</span>
            {props.description.map((item, i) => (
              <MDBListGroupItem
                key={i}
                className="product-main__list-item product-main__list-item--no-outline futura-font py-0">
                <p className="product-main__list-item-text my-1">{item}</p>
              </MDBListGroupItem>
            ))}
          </MDBListGroup>
        </MDBCol>
      </MDBRow>
    </div>
  );
}
