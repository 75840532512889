import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import StarRatingComponent from 'react-star-rating-component';

export default function ProductOverview(props) {
  return (
    <MDBRow>
      <MDBCol className="d-flex flex-row align-items-center">
        <img className="img-fluid review__img-thumb" src={props.imgSrc} />
        <div className="review__rating-and-name">
          <span className="review__span text-capitlize futura-font">{props.productName}</span>
          <span className="review__span futura-font">Overall rating: </span>
          <StarRatingComponent
            name="review"
            starCount={5}
            value={props.rating}
            onStarClick={props.starRating}
            renderStarIcon={(index, value) => {
              return (
                <span>
                  <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                </span>
              );
            }}
          />
        </div>
      </MDBCol>
    </MDBRow>
  );
}
