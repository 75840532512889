import React from 'react';
import { MDBRow, MDBCol, MDBFileInput, MDBBtn } from 'mdbreact';

export default function ProductImagesUpload(props) {
  return (
    <div className="review__upload-img">
      <MDBRow className="my-3">
        <MDBCol className="pl-2">
          <MDBFileInput
            getValue={(value) => props.onChange(value)}
            multiple
            btnColor="info"
            reset
          />
          <span className="review__span--img-upload-resize font-weight-bolder ml-2 futura-font">
            * 2 Images Max. Please select multiples.
          </span>
        </MDBCol>
      </MDBRow>
    </div>
  );
}
