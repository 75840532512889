import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import swal from 'sweetalert2';

import { MDBInput } from 'mdbreact';

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.sendEmail = this.sendEmail.bind(this);

    /*Subscribe Button state*/
    this.state = {
      subscribe: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /*Subscribe Button*/
  onSubscribeChange = () => {
    this.setState({
      subscribe: !this.state.subscribe,
    });
  };


  sendEmail() {
    const name = $('#name').val();
    const email = $('#email').val();
    const subject = $('#subject').val();
    const message = $('#message').val();

    $('#message, #email, #name').css('border-color', '#ced4da');

    if (name === '') {
      $('#name').css('border-color', 'red');
      swal.fire({
        title: 'Please provide a name.',
        icon: 'error',
        showConfirmButton: false,
        timer: 2000,
      });
    }

    if (email === '') {
      $('#email').css('border-color', 'red');
      swal.fire({
        title: 'Please provide an email.',
        icon: 'error',
        showConfirmButton: false,
        timer: 2000,
      });
    }

    if (message === '') {
      $('#message').css('border-color', 'red');
      swal.fire({
        title: 'Please provide a message.',
        icon: 'error',
        showConfirmButton: false,
        timer: 2000,
      });
    }

    if (message.length > 0 && email.length > 0 && name.length > 0) {
      const data = {
        from:`LOTUS CONTACT US <postmaster@${process.env.MAIL_GUN_DOMAIN}>`,
        to: 'info@lotuspetfoods.com',
        'h:Reply-To': `${email}`,
        subject,
        html: `<html><div>Name: ${name}</div><br><div>Message: ${message}</div></html>`,
      };

      axios({
        url: '/contactus',
        method: 'post',
        data: data,
      }).then((res) => {

        /*Add new subscriber to mailchimp*/
        if(this.state.subscribe === true){
          const newSub = {
            email_address: `${email}`,
            status: 'subscribed'
          };
          const { data } = axios.post('/client/subscribe/new-subscriber', {
            data: newSub,
          });
        }

          swal.fire({
            title: 'Message Sent!',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            const name = $('#name').val('');
            const email = $('#email').val('');
            const subject = $('#subject').val('');
            const message = $('#message').val('');
          });
        })
        .catch((err) => {
          console.error(err);
          swal.fire({
            title: 'An error has occurred.',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  }

  render() {
    return (
      <div>
        <div className="container contact-us-body">
          <div className="row">
            <div className="col contact-us-text">CONTACT US</div>
          </div>
          <div className="row page-divider about-us-divider" />
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <div className="form-group">
                <label className="mt-2" htmlFor="name">
                  Name<sup>*</sup>
                </label>
                <input type="text" className="form-control" id="name" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <div className="form-group">
                <label className="mt-2" htmlFor="email">
                  Email Address<sup>*</sup>
                </label>
                <input type="text" className="form-control" id="email" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <div className="form-group">
                <label className="mt-2" htmlFor="subject">
                  Subject
                </label>
                <input type="text" className="form-control" id="subject" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="mt-2" htmlFor="Message">
                  Message<sup>*</sup>
                </label>
                <textarea type="text" rows="8" className="form-control" id="message" />
              </div>
            </div>
          </div>
          <div>
            <sup>*</sup>
            <i>Required</i>
          </div>

          {/*Subscribe Button*/}
          <div className="mb-3 mt-3">
          <MDBInput label="Subscribe to our newsletter and receive exclusive updates and promotions." 
          type="checkbox"
          id="checkbox1"
          onChange={this.onSubscribeChange}
          />
          </div>

          <div className="row pb-5">
            <div className="col-md-3 col-sm-12 offset-md-9 text-center">
              <button type="submit" className="submit-contact" onClick={this.sendEmail}>
                Submit
              </button>
            </div>
          </div>
          <div className="row mb-5 pb-5">
            <div className="col-md-auto col-sm-12 corp-office">
              <div>Corporate Office</div>
              <div className="corp-office-bold">Lotus Pet Food, Inc.</div>
              <div>2727 Maricopa Street</div>
              <div>Torrance, CA 90503</div>
              <div>888-99-LOTUS(56887)</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
