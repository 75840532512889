import React, { Component } from 'react';
import Axios from 'axios';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBAnimation,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from 'mdbreact';
import CountUp from 'react-countup';
import Swal from 'sweetalert2';

import IsLoadingChild from '../../IsLoading/IsLoadingChild.jsx';
import QuizPrizes from './QuizPrizes.jsx';
import QuizRemptionShip from './QuizRemdemptionShip/QuizRedemptionShip.jsx';

const {
  endPoint: { quizEndPoint },
} = require('../../_helper/stringVars');
const { handleErrors, handleAuthorization } = require('../../_helper/reqsHandlers');
const { prizeItems, prizeTypeObj } = require('./inputOptions');
const {
  checkIfPrizeIsEnabled,
  removeSelectedPrizeIfNotEnabled,
  updatePoints,
  checkIfAllKeysAreDisabled,
} = require('./helper');

export default class QuizRedeem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPoints: 0,
      spentPoints: 0,
      userId: 0,
      selectedPrizes: {},
      enabledPrizes: {},
      prizeSelections: {},
      rewardItemsLoaded: false,
      openRedeemModal: false,
      redeemBtn: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (handleAuthorization(history)) {
      const { _id } =
        JSON.parse(localStorage.getItem('user')) || JSON.parse(localStorage.getItem('admin'));
      this.setUserPoints(_id);
    }
  }

  setUserPoints = (id) => {
    Axios.get(`${quizEndPoint.client}/get-user-quiz-and-points`, { params: { _id: id } })
      .then((res) => {
        const { status, data } = res;
        if (status === 400) return handleErrors(400);
        if (status === 404) return handleErrors(404, 'quiz information');
        const spentPoints = data.spent_points || 0;
        const currentPoints = data.points || 0;
        this.setState({
          currentPoints,
          spentPoints,
          rewardItemsLoaded: true,
          prizeItems,
          prizeSelections: prizeTypeObj,
          userId: id,
        });
      })
      .catch((err) => {
        const { status } = err.response;
        console.error(err);
        if (status === 500) return handleErrors(500);
      });
  };

  /* Start of prize selection */

  enablePrizeSelection = (prizeType, prizePoints, e) => {
    e.preventDefault();
    const { selectedPrizes, enabledPrizes, currentPoints, spentPoints } = this.state;
    const updatedSelectedPrizes = removeSelectedPrizeIfNotEnabled(selectedPrizes, {
      ...enabledPrizes,
      [prizeType]: !this.state.enabledPrizes[prizeType],
    });
    const { newCurrentPoints, newSpentPoints } = updatePoints(
      currentPoints,
      spentPoints,
      prizeType,
      prizePoints,
      { ...enabledPrizes, [prizeType]: !this.state.enabledPrizes[prizeType] },
    );
    this.setState((state) => ({
      enabledPrizes: { ...state.enabledPrizes, [prizeType]: !state.enabledPrizes[prizeType] },
      selectedPrizes: updatedSelectedPrizes,
      currentPoints: newCurrentPoints,
      spentPoints: newSpentPoints,
      redeemBtn: checkIfAllKeysAreDisabled({
        ...enabledPrizes,
        [prizeType]: !state.enabledPrizes[prizeType],
      }),
    }));
  };

  selectPrizes = (value, prizePoints, prizeType) => {
    const [selectedPrize] = value;
    const { enabledPrizes } = this.state;
    if (checkIfPrizeIsEnabled(selectedPrize, prizeType, enabledPrizes)) {
      this.setState((state) => ({
        selectedPrizes: { ...state.selectedPrizes, [prizeType]: { selectedPrize, prizePoints } },
      }));
    }
  };
  /* End of prize selection */

  toggleModal = () => {
    this.setState({
      openRedeemModal: !this.state.openRedeemModal,
    });
  };

  renderShippingInfo = () => {
    const { state } = this;
    return (
      <MDBModal
        animation="bottom"
        modalStyle="warning"
        size="lg"
        isOpen={this.state.openRedeemModal}
        toggle={this.toggleModal}>
        <MDBModalHeader toggle={this.toggleModal}>Shipping Information</MDBModalHeader>
        <MDBModalBody>
          <QuizRemptionShip
            selectedPrizes={state.selectedPrizes}
            currentPoints={state.currentPoints}
            spentPoints={state.spentPoints}
            userId={state.userId}
          />
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="dark" onClick={this.toggleModal}>
            Close
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  };

  renderComponent = () => {
    const {
      rewardItemsLoaded,
      spentPoints,
      currentPoints,
      enabledPrizes,
      prizeSelections,
      redeemBtn,
    } = this.state;

    if (!rewardItemsLoaded) return <IsLoadingChild />;

    return (
      <MDBRow>
        <MDBCol size="12">
          <MDBRow>
            <MDBCol>
              <h1 className="retailer-quiz__header1 brandon-font text-uppercase">
                Points Redemption
              </h1>
              <div className="page-divider mb-3"></div>
            </MDBCol>
          </MDBRow>

          <MDBRow className="mt-2 mb-3">
            <MDBCol size="6">
              <h2 className="retailer-quiz__header2--redeem brandon-font">
                Current Points: {<CountUp start={0} end={currentPoints} duration={3} delay={1} />}
              </h2>
            </MDBCol>
            <MDBCol size="6">
              <h2 className="retailer-quiz__header2--redeem brandon-font">
                Spent Points: {<CountUp start={0} end={spentPoints} duration={5} delay={1} />}
              </h2>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="6">
              <MDBBtn
                onClick={this.toggleModal}
                className="ml-0"
                color="amber"
                disabled={!redeemBtn}>
                Redeem Now
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12">
              <span className="retailer-quiz__span futura-font my-4">
                *Please choose your reward(s).
              </span>
            </MDBCol>

            <QuizPrizes
              prizeItems={prizeItems}
              currentPoints={currentPoints}
              selectPrizes={this.selectPrizes}
              enablePrizeSelection={this.enablePrizeSelection}
              enabledPrizes={enabledPrizes}
              prizeSelections={prizeSelections}
            />
          </MDBRow>
        </MDBCol>
      </MDBRow>
    );
  };

  render() {
    return (
      <MDBContainer className="retailer-quiz__quiz-redeem margin-top-adjustment">
        <MDBAnimation type="fadeIn" duration="2s">
          {this.renderComponent()}
        </MDBAnimation>
        {this.renderShippingInfo()}
      </MDBContainer>
    );
  }
}
