const { frontendPrizeText } = require('./inputOptions');
const { capitalizedFirstLetters } = require('../../_helper/index.jsx');

/** @module PrizePicker/Helper */
/**
 * @function
 * @param {Object[]} prizeInfoArr
 * @returns {Object[]} updates disabled to true;
 * @description disable input value for MDBInput under
 * GeneralSelectInput and ShirtSelectInput
 */
const disableInputVal = (prizeInfoArr) => {
  return prizeInfoArr.map((prizeInfo) => {
    const info = prizeInfo;
    info.disabled = true;
    return info;
  });
};

/**
 * @function
 * @description enable input value for MDBInput under
 * GeneralSelectInput and ShirtSelectInput
 * @param {Object[]} prizeInfoArr
 * @returns {Object[]} updates disabled to false;
 */
const enableInputVal = (prizeInfoArr) => {
  return prizeInfoArr.map((prizeInfo) => {
    const info = prizeInfo;
    info.disabled = false;
    return info;
  });
};

/**
 * @function
 * @description Check to see prize is enabled for the same
 * prize Type.
 * @param {String} selectedPrizeName
 * @param {String} selectedPrizeType
 * @param {Object} enabledPrizes
 * @returns {boolean} true or false
 */
const checkIfPrizeIsEnabled = (selectedPrizeName, selectedPrizeType, enabledPrizes) => {
  let isEnabled = false;
  Object.keys(enabledPrizes).forEach((key) => {
    if (selectedPrizeType === key && enabledPrizes[key] && selectedPrizeName !== undefined) {
      isEnabled = true;
    }
  });
  return isEnabled;
};

/**
 * Remove item from selectedPrizes if selectPrizes
 * is click and the prize is disable
 * @param {Object} selectedPrizes
 * @param {Object} enabledPrizes
 * @returns {Object} new SelectedPrizes to replace old selectedPrizes state
 */
const removeSelectedPrizeIfNotEnabled = (selectedPrizes, enabledPrizes) => {
  let temp = selectedPrizes;
  Object.keys(enabledPrizes).forEach((key) => {
    if (!enabledPrizes[key] && selectedPrizes[key] !== undefined) {
      delete temp[key];
    } else if (selectedPrizes[key] !== undefined) {
      temp = selectedPrizes;
    }
  });
  return temp;
};

/**
 * Update client points when selecting reward
 * @param {Number} currentPoints
 * @param {Number} spentPoints
 * @param {String} prizeType
 * @param {Number} prizePoints,
 * @param {Object} enabledPrizes
 * @returns {Object} updated {newCurrentPoints, newSpentPoints}
 */
const updatePoints = (currentPoints, spentPoints, prizeType, prizePoints, enabledPrizes) => {
  // if enabledPrizes === false then move spent over to current
  // else move current over to spent
  let newCurrentPoints = currentPoints;
  let newSpentPoints = spentPoints;
  if (enabledPrizes[prizeType]) {
    newCurrentPoints -= prizePoints;
    newSpentPoints += prizePoints;
  } else {
    newCurrentPoints += prizePoints;
    newSpentPoints -= prizePoints;
  }

  return { newCurrentPoints, newSpentPoints };
};

/**
 * build [{}] to render correct shipping info for prize
 * @param {Object} selectedPrizes
 * @returns {Object[]}
 */
const convertSelectedPrizeToArr = (selectedPrizes) => {
  return Object.keys(selectedPrizes).map((key) => {
    return {
      prizeType: capitalizedFirstLetters(frontendPrizeText[key]),
      prizeName: selectedPrizes[key].selectedPrize,
    };
  });
};

const checkIfAllKeysAreDisabled = (obj) => {
  return Object.values(obj).some((val) => {
    return val;
  });
};

module.exports = {
  disableInputVal,
  enableInputVal,
  checkIfPrizeIsEnabled,
  removeSelectedPrizeIfNotEnabled,
  updatePoints,
  convertSelectedPrizeToArr,
  checkIfAllKeysAreDisabled,
};
