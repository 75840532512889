import React from 'react';
import { Link } from 'react-router-dom';

const products = [
  {
    name: 'Bird Watcher',
    price: '20.00',
    type: 'Ring Spun Cotton Shirt',
    colors: [['Black', '#0B0B0B']],
    img: {
      small:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/bird-watcher/bird-watcher-320.png',
      colors: [
        {
          thumbnail: ['https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/bird-watcher.jpg'],
          main: ['https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/bird-watcher-main.jpg'],
        },
      ],
    },
    link: 'bird-watcher',
  },
  {
    name: 'Happy Camper',
    price: '20.00',
    type: 'Ring Spun Cotton Shirt',
    colors: [
      ['Red', '#632121'],
      ['Pink', '#ECAED0'],
    ],
    img: {
      small:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/happy-camper/happy-camper-red-320.png',
      colors: [
        {
          thumbnail: [
            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/happy-camper.jpg',
            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/happy-camper-pink.jpg',
            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/happy-camper.jpg',
          ],
          main: [
            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/happy-camper-main.jpg',
            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/happy-camper-pink-main.jpg',
            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/Treats/Chicken/10oz1-main.jpg',
          ],
        },
        {
          thumbnail: [
            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/happy-camper-pink.jpg',
          ],
          main: [
            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/happy-camper-pink-main.jpg',
          ],
        },
      ],
    },
    link: 'happy-camper',
  },
  {
    name: 'Lotus Raw',
    price: '20.00',
    type: 'Ring Spun Cotton Shirt',
    shirtColors: [['olive', '#97896e']],
    SKU: {
      SBlack: '20001-001',
      MBlack: '20001-002',
      LBlack: '20001-003',
      XLBlack: '20001-004',
      '2XLBlack': '20001-005',
    },
    img: {
      small:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/raw-shirt/dog-raw-front-320.png',
    },
    shirtImages: {
      black: [
        {
          main:
            'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/raw-shirt/dog-raw-front-640.png',
          zoom:
            'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/raw-shirt/dog-raw-front-1920.png',
          thumb:
            'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/raw-shirt/dog-raw-front-320.png',
        },
        {
          main:
            'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/raw-shirt/dog-raw-back-640.png',
          zoom:
            'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/raw-shirt/dog-raw-back-1920.png',
          thumb:
            'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/raw-shirt/dog-raw-back-320.png',
        },
      ],
    },
    link: 'lotus-raw',
    description: [
      ' Fabric: Fine Jersey',
      '100% Combed Ring-Spun Cotton',
      'Neck: Crew Neck',
      'Binding: Set-in Collar 1x1 Baby Rib; Side Seamed',
    ],
  },
];

class Shirts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <img
          className="banner img-fluid"
          src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/banner.png'}
        />
        <div className="container food-type">
          <div className="row">
            {products.map((item, i) => {
              return (
                <div key={i} className="col-md-3 col-sm-4 col-6 text-center my-sm-5 my-3">
                  <Link className="no-underline" to={`/shop/accessories/shirts/${item.link}`}>
                    <div className="shop-item-container">
                      <img className="img-fluid" src={item.img.small} />
                      <div className="shop-item-name">{item.name}</div>
                      <div className="shop-item-type">{item.type}</div>
                      <div className="shop-item-price">${item.price}</div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Shirts;
