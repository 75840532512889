const prizeItems = [
  {
    prizeType: 'catDry',

    alt: 'Bag of cat food. Your choice of 2 pounds of dry cat food',
    imgSrc: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/cat-food-prize-bar1.png',
    points: 45,
  },
  {
    prizeType: 'dogDry',
    alt: 'Bag of dog food. Your choice of 5 pounds of dry dog food',
    imgSrc: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/dog-food-prize-bar1.png',
    points: 45,
  }/*,
  {
    prizeType: 'waterbottles',
    alt: 'Lotus waterbottles. Your choice of from 4 designs',
    imgSrc: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/bottle-prize-bar.png',
    points: 40,
  },
  {
    prizeType: 'tShirts',
    alt: 'Lotus T-shirt. Your choice of from 2 stylish designs',
    imgSrc: 'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Quiz/t-shirt-prize-bar4.png',
    points: 35,
  },
  {
    prizeType: 'totes',
    alt: 'Lotus totes bag, A resuable everyday tote',
    imgSrc: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/tote-bag-prize-bar.png',
    points: 30,
  },
  {
    prizeType: 'stickers',
    alt: 'Lotus stickers. Your choice from 2 designs',
    imgSrc: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Quiz/stickers-prize-bar.png',
    points: 5,
  },*/
];

const prizeTypeObj = {
  dogDry: [
    { text: 'Dog Regular Bites Duck', value: 'Dog Regular Bites Duck' },
    { text: 'Dog - Regular Bites Lamb', value: 'Dog - Regular Bites Lamb' },
    { text: 'Dog - Regular Bites Chicken Adult', value: 'Dog - Regular Bites Chicken Adult' },
    { text: 'Dog - Regular Bites Sardine', value: 'Dog - Regular Bites Sardine' },
    { text: 'Dog - Regular Bites Turkey', value: 'Dog - Regular Bites Turkey' },
    { text: 'Dog - Regular Bites Chicken Senior', value: 'Dog - Regular Bites Chicken Senior' },
    { text: 'Dog Regular Bites Chicken Puppy', value: 'Dog Regular Bites Chicken Puppy' },
    { text: 'Dog - Small Bites Chicken Senior', value: 'Dog - Small Bites Chicken Senior' },
    { text: 'Dog - Small Bites Chicken Adult', value: 'Dog - Small Bites Chicken Adult' },
    { text: 'Dog - Small Bites Sardine', value: 'Dog - Small Bites Sardine' },
    { text: 'Dog - Small Bites Chicken Puppy', value: 'Dog - Small Bites Chicken Puppy' },
    { text: 'Dog - Small Bites Duck', value: 'Dog - Small Bites Duck' },
    { text: 'Dog - Small Bites Lamb', value: 'Dog - Small Bites Lamb' },
    { text: 'Dog Small Bites Turkey', value: 'Dog Small Bites Turkey' },
  ],
  catDry: [
    { text: 'Cat - Low Fat Kibble', value: 'Cat - Low Fat Kibble' },
    { text: 'Cat - Duck Kibble', value: 'Cat - Duck Kibble' },
    { text: 'Cat - Sardine Kibble', value: 'Cat - Sardine Kibble' },
    { text: 'Cat - Chicken Kibble', value: 'Cat - Chicken Kibble' },
  ]/*,
  waterbottles: [
    { text: 'Black dog bottle', value: 'Black dog bottle' },
    { text: 'Blue dog bottle', value: 'Blue dog bottle' },
    { text: 'Black cat bottle', value: 'Black cat bottle' },
    { text: 'Blue cat bottle', value: 'Blue cat bottle' },
  ],
  totes: [
    { text: 'Dog tote', value: 'Dog tote' },
    { text: 'Cat tote', value: 'Cat tote' },
  ],
  tShirts: [
    { text: 'Bird watcher (Black) (S)', value: 'Bird watcher (Black) (S)' },
    { text: 'Bird watcher (Black) (M)', value: 'Bird watcher (Black) (M)' },
    { text: 'Bird watcher (Black) (L)', value: 'Bird watcher (Black) (L)' },
    { text: 'Bird watcher (Black) (XL)', value: 'Bird watcher (Black) (XL)' },
    { text: 'Bird watcher (Black) (2XL)', value: 'Bird watcher (Black) (2XL)' },
    { text: 'Happy camper (Red) (S)', value: 'Happy camper (Red) (S)' },
    { text: 'Happy camper (Red) (M)', value: 'Happy camper (Red) (M)' },
    { text: 'Happy camper (Red) (L)', value: 'Happy camper (Red) (L)' },
    { text: 'Happy camper (Red) (XL)', value: 'Happy camper (Red) (XL)' },
    { text: 'Happy camper (Red) (2XL)', value: 'Happy camper (Red) (2XL)' },
    { text: 'Happy camper (Pink) (S)', value: 'Happy camper (Pink) (S)' },
    { text: 'Happy camper (Pink) (M)', value: 'Happy camper (Pink) (M)' },
    { text: 'Happy camper (Pink) (L)', value: 'Happy camper (Pink) (L)' },
    { text: 'Happy camper (Pink) (XL)', value: 'Happy camper (Pink) (XL)' },
    { text: 'Happy camper (Pink) (2XL)', value: 'Happy camper (Pink) (2XL)' },
    { text: 'Duck (S)', value: 'Duck (S)' },
    { text: 'Duck (M)', value: 'Duck (M)' },
    { text: 'Duck (L)', value: 'Duck (L)' },
    { text: 'Duck (XL)', value: 'Duck (XL)' },
    { text: 'Duck (2XL)', value: 'Duck (2XL)' },
  ],
  stickers: [
    { text: 'Dog sticker', value: 'Dog sticker' },
    { text: 'Cat sticker', value: 'Cat sticker' },
  ],*/
};

const quantities = [
  { value: 1, text: 1 },
  { value: 2, text: 2 },
  { value: 3, text: 3 },
  { value: 4, text: 4 },
  { value: 5, text: 5 },
];

const frontendPrizeText = {
  dogDry: 'dog dry food',
  catDry: 'cat dry food',
  //waterbottles: 'water bottles',
  //totes: 'totes',
  //tShirts: 't-shirts',
  //stickers: ' stickers',
};

const usStates = [
  { text: 'ALABAMA', value: 'AL' },
  { text: 'ALASKA', value: 'AK' },
  { text: 'AMERICAN SAMOA', value: 'AS' },
  { text: 'ARIZONA', value: 'AZ' },
  { text: 'ARKANSAS', value: 'AR' },
  { text: 'CALIFORNIA', value: 'CA' },
  { text: 'COLORADO', value: 'CO' },
  { text: 'CONNECTICUT', value: 'CT' },
  { text: 'DELAWARE', value: 'DE' },
  { text: 'DISTRICT OF COLUMBIA', value: 'DC' },
  { text: 'FEDERATED STATES OF MICRONESIA', value: 'FM' },
  { text: 'FLORIDA', value: 'FL' },
  { text: 'GEORGIA', value: 'GA' },
  { text: 'GUAM', value: 'GU' },
  { text: 'HAWAII', value: 'HI' },
  { text: 'IDAHO', value: 'ID' },
  { text: 'ILLINOIS', value: 'IL' },
  { text: 'INDIANA', value: 'IN' },
  { text: 'IOWA', value: 'IA' },
  { text: 'KANSAS', value: 'KS' },
  { text: 'KENTUCKY', value: 'KY' },
  { text: 'LOUISIANA', value: 'LA' },
  { text: 'MAINE', value: 'ME' },
  { text: 'MARSHALL ISLANDS', value: 'MH' },
  { text: 'MARYLAND', value: 'MD' },
  { text: 'MASSACHUSETTS', value: 'MA' },
  { text: 'MICHIGAN', value: 'MI' },
  { text: 'MINNESOTA', value: 'MN' },
  { text: 'MISSISSIPPI', value: 'MS' },
  { text: 'MISSOURI', value: 'MO' },
  { text: 'MONTANA', value: 'MT' },
  { text: 'NEBRASKA', value: 'NE' },
  { text: 'NEVADA', value: 'NV' },
  { text: 'NEW HAMPSHIRE', value: 'NH' },
  { text: 'NEW JERSEY', value: 'NJ' },
  { text: 'NEW MEXICO', value: 'NM' },
  { text: 'NEW YORK', value: 'NY' },
  { text: 'NORTH CAROLINA', value: 'NC' },
  { text: 'NORTH DAKOTA', value: 'ND' },
  { text: 'NORTHERN MARIANA ISLANDS', value: 'MP' },
  { text: 'OHIO', value: 'OH' },
  { text: 'OKLAHOMA', value: 'OK' },
  { text: 'OREGON', value: 'OR' },
  { text: 'PALAU', value: 'PW' },
  { text: 'PENNSYLVANIA', value: 'PA' },
  { text: 'PUERTO RICO', value: 'PR' },
  { text: 'RHODE ISLAND', value: 'RI' },
  { text: 'SOUTH CAROLINA', value: 'SC' },
  { text: 'SOUTH DAKOTA', value: 'SD' },
  { text: 'TENNESSEE', value: 'TN' },
  { text: 'TEXAS', value: 'TX' },
  { text: 'UTAH', value: 'UT' },
  { text: 'VERMONT', value: 'VT' },
  { text: 'VIRGIN ISLANDS', value: 'VI' },
  { text: 'VIRGINIA', value: 'VA' },
  { text: 'WASHINGTON', value: 'WA' },
  { text: 'WEST VIRGINIA', value: 'WV' },
  { text: 'WISCONSIN', value: 'WI' },
  { text: 'WYOMING', value: 'WY' },
];

module.exports = { prizeItems, usStates, prizeTypeObj, frontendPrizeText, quantities };
