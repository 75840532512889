import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AdminCoupons from './AdminPages/Coupons/AdminCoupons.jsx';
import AdminReviews from './AdminPages/Reviews/AdminReviews.jsx';
import AdminProducts from './AdminPages/AdminProducts/AdminProducts.jsx';
import Stores from './AdminPages/Stores/Stores.jsx';
import AdminIngredients from './AdminPages/ingredients/AdminIngredients.jsx';
import AdminTransactions from './AdminPages/AdminTransactions.jsx';
import AdminQuizzes from './AdminPages/Quiz/AdminQuizzes.jsx';
import RetailersList from './AdminPages/Retailers/RetailersList.jsx';

export default function AdminMainComponents(props) {
  const url = '/admin/main';
  return (
    <Switch>
      <Route path={`${url}/coupons`} component={AdminCoupons} />
      <Route path={`${url}/reviews`} component={AdminReviews} />
      <Route path={`${url}/products`} component={AdminProducts} />
      <Route path={`${url}/stores`} component={Stores} />
      <Route path={`${url}/ingredients`} component={AdminIngredients} />
      <Route path={`${url}/transactions`} component={AdminTransactions} />
      <Route path={`${url}/quizzes`} component={AdminQuizzes} />
      <Route path={`${url}/retailers`} component={RetailersList} />
    </Switch>
  );
}
