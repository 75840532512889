import React, { Component } from 'react';
import Axios from 'axios';
import swal from 'sweetalert2';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { Link } from 'react-router-dom';

import PasswordField from '../../FieldComponents/InputFields/PasswordField.jsx';

const { success, error, warning } = require('../../_helper/SweetAlerts/alerts.jsx');
const { onChange } = require('../../_helper/index.jsx');

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      resetToken: '',
    };

    this.checkToken = this.checkToken.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.checkToken();
  }

  checkToken() {
    const { resetToken } = this.props.match.params;
    const { history } = this.props;
    Axios.get('/user/check-reset-token', { params: { reset_token: resetToken } })
      .then((token) => {
        const { status } = token;
        if (status === 200) return this.setState({ resetToken });
      })
      .catch((err) => {
        console.error(err);
        error({
          title: 'Error',
          text:
            'Your reset token has expired or does not exist, \n' +
            'please send another request. \n' +
            'You will be redirected to our password reset request page.',
          button: 'Okay',
        });
        setTimeout(() => history.push('/password-reset'), 3000);
      });
  }

  onSubmit(e) {
    e.preventDefault();
    const { history } = this.props;
    const { password, confirmPassword } = this.state;
    if (password !== confirmPassword || password.length < 1) {
      return error({
        title: 'Non Matching Password!',
        text: 'Your passwords do not match, please try again',
        button: 'Okay.',
      });
    }
    Axios.put('/user/update-password', { params: this.state })
      .then((data) => {
        const { status } = data;
        if (status === 200) {
          swal.fire({
            title: 'Password Updated!',
            text: 'Your password has been updated. Taking you back to the homepage',
            icon: 'success',
            timer: 3000,
            timerProgressBar: true,
          });
          setTimeout(() => history.push('/'), 3000);
        }
      })
      .catch((err) => {
        const { status } = err.response;
        if (status === 400) {
          return error({
            title: 'Error',
            text: 'Please check password are the same.',
            button: 'Okay.',
          });
        }
        if (status === 500) return error({ title: 'Error!', text: 'Server Error', button: 'Okay' });
        error({
          title: 'Error',
          text:
            'Your reset token no longer exists or has expired. \n Please request a new one. \n You will be redirected.',
          button: false,
        });
        setTimeout(() => history.push('/password-reset'), 2000);
      });
  }

  render() {
    const { password, confirmPassword } = this.state;
    return (
      <MDBContainer className="margin-top-adjustment">
        <MDBRow center>
          <MDBCol xs="12" lg="8">
            <MDBRow center>
              <MDBCol className="text-center">
                <p className="contact-us-text">Password Reset</p>
              </MDBCol>
            </MDBRow>
            <div className="row page-divider mb-5" />
            <form className="tab-content animated fadeIn" onSubmit={this.onSubmit}>
              <MDBRow>
                <MDBCol>
                  <PasswordField
                    label="Password"
                    name="password"
                    value={password}
                    onChange={(e) => onChange(this, e)}
                  />
                  <PasswordField
                    label="Confirm Password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => onChange(this, e)}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow center className="text-left">
                <MDBCol sm="12" className="my-3">
                  <MDBBtn type="submit" color="amber">
                    Update Password
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
