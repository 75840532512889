import React from 'react';
import { StripeProvider } from 'react-stripe-elements';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './components/App/App.jsx';
import history from './components/History/History.jsx';
import "./styles/main.scss";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

ReactDOM.render(
  <StripeProvider apiKey={process.env.STRIPE}>
    <Router history={history}>
      <App />
    </Router>
  </StripeProvider>,
  document.getElementById('app'),
);
