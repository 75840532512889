import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle } from 'mdbreact';
import Axios from 'axios';
import { Link } from 'react-router-dom';

const { success, error, warning } = require('../../../_helper/SweetAlerts/alerts.jsx');
const { checkString, capitalizedFirstLetters } = require('../../../_helper/index.jsx');
const { getCorrectImgSrc } = require('../MainProductHelper.jsx');

export default class Related extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }

  componentDidMount() {
    const { foodType, pet } = this.props;
    this.getRelatedProduct(foodType, pet);
  }

  getRelatedProduct = (foodType, petType) => {
    const query = { foodType, petType };
    //console.log({ query });
    Axios.get('/client/products/get-related-products', { params: { type: 'name', value: query } })
      .then((product) => {
        const { data } = product;
        if (data.length < 1) {
          return error({
            title: 'Error',
            text: 'Server Error. Cannot get Info.',
            button: 'Close.',
          });
        }
        this.setRelatedProducts(data);
      })
      .catch((err) => console.error(err));
  };

  setRelatedProducts = async (products) => {
    const shuffle = (array) => {
      return array.sort(() => Math.random() - 0.5);
    };
    const buildRelatedObj = (products) => {
      const productArr = [];
      products.forEach((product) => {
        const relatedObj = { id: product.product_id };
        Object.keys(product).forEach((key) => {
          if (key === 'name') {
            const correctString = checkString(capitalizedFirstLetters(product[key]));
            Object.assign(relatedObj, { name: correctString });
          }
        });
        const imgSRc = getCorrectImgSrc(product);
        Object.assign(relatedObj, { img: imgSRc });
        productArr.push(relatedObj);
      });
      return shuffle(productArr).slice(0, 4);
    };

    const builtRelatedArr = await buildRelatedObj(products);
    this.setState({
      products: builtRelatedArr,
    });
  };

  render() {
    const { state } = this;
    const { pet, foodType } = this.props;
    return (
      <div className="product-main__related-wapper my-5">
        <MDBRow>
          <MDBCol>
            <MDBRow>
              <MDBCol size="12" sm="6">
                <h3 className="product-main__header3 product-main__header3--underline dk-font">
                  Related Products
                </h3>
                <div
                  className="product-main__header3-underline"
                  style={{ backgroundColor: this.props.color }}></div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              {state.products.map((product, i) => (
                <MDBCol key={i} size="6" sm="2" md="3" className="my-4">
                  <a
                    className="text-dark"
                    href={`/product-view/${pet.toLowerCase()}/${foodType.toLowerCase()}/${product.name.toLowerCase()}-${
                      product.id
                    }`}>
                    <MDBCard className="product-main__related-product-card  product-main__related-product-card--no-shadow">
                      <MDBCardImage
                        top
                        src={product.img}
                        overlay="white-slight"
                        hover
                        waves
                        alt={product.name}
                      />
                      <MDBCardBody className="text-center">
                        <MDBCardTitle className="product-main__related-product-header futura-font">
                          {product.name} Recipe
                        </MDBCardTitle>
                      </MDBCardBody>
                    </MDBCard>
                  </a>
                </MDBCol>
              ))}
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}
