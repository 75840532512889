import React, { Component } from 'react';
import Axios from 'axios';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact';

import Lightbox from '../../LightBox/LightBox.jsx';
import IsLoading from '../../IsLoading/IsLoading.jsx';
import MainImg from './Images/MainImg.jsx';
import Thumbs from './Images/Thumbs.jsx';
import OverviewInfo from './OverviewInfo/OverviewInfo.jsx';
import Size from './Images/Size.jsx';
import InfoTabs from './InfoTabs/InfoTabs.jsx';
import ProductReviews from './ProductReviews/ProductReviews.jsx';
import Related from './Related/Related.jsx';
import DetailIcons from './OverviewInfo/DetailIcons.jsx';

const { success, error, warning } = require('../../_helper/SweetAlerts/alerts.jsx');
const { checkString, capitalizedFirstLetters } = require('../../_helper/index.jsx');
const { countNumberOfStars, countNumbersOfApprovedReviews } = require('./ProductReviews/helper');
const {
  setRecipeIcon,
  waitForState,
  getCorrectImgSrc,
  checkToRenderThumbSize,
  checkToSetInitialSize,
} = require('./MainProductHelper.jsx');

export default class MainProductPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      as_fed: {},
      calories: {},
      description: [],
      dry_matter: {},
      feeding_guidelines: {},
      guaranteed_analysis: {},
      ingredients: [],
      name: '',
      product_id: '',
      size: [],
      color_palette: '',
      product_icons: [],
      mainImg: '',
      zoomImg: '',
      small: {},
      medium: {},
      large: {},
      foodType: '',
      recipeIcon: '',
      stateUpdated: false,
      pet: '',
      product_reviews: [],
      activeSizeIndex: '',
      currentThumbSize: '',
      currentHighlight: '1',
      lightBoxImagesArr: [],
      lightBoxIsOpen: false,
      lightBoxImgIndex: 0,
      signInModal: false,
    };
  }

  componentDidMount() {
    //product-view/:petType/:foodType/:ingredient-:id
    this.getComponentState();
  }

  getComponentState = () => {
    if (waitForState(this.checkRouteParams, this.getProductInfo)) {
      setTimeout(() => {
        this.setState({
          stateUpdated: true,
        });
      }, 1000);
    }
  };

  getProductInfo = () => {
    const { id } = this.props.match.params;
    Axios.get('/client/products/get-products-info', { params: { type: 'product_id', value: id } })
      .then((product) => {
        const { data } = product;
        this.setInitialState(data);
      })
      .catch((err) => {
        console.error(err);
        const { status } = err.res;
        if (status === 500) {
          return error({ title: 'Server Error', text: 'Issue with Server.', button: 'Okay.' });
        }
        error({ title: 'Error', text: 'Cannot get Product info', button: 'Okay.' });
      });
  };

  setMainImg = (src) => {
    this.setState({
      mainImg: src,
    });
  };

  setInitialSize = (state) => {
    const { currentThumbSize, activeSizeIndex } = checkToSetInitialSize(state);
    const initialSizeIndex = this.props.location.state
      ? this.props.location.state.initialSizeIndex
      : undefined;
    const initialThumbSize = this.props.location.state
      ? this.props.location.state.initialThumbSize
      : undefined;
    /* set currentThumbSize to small if client came from raw variety page */
    const setInitialThumbSize = initialThumbSize || currentThumbSize;
    const setActiveSizeIndex = initialSizeIndex || activeSizeIndex;

    this.setState({
      currentThumbSize: setInitialThumbSize,
      activeSizeIndex: setActiveSizeIndex,
      mainImg: this.resetMainImg(setInitialThumbSize),
    });
    this.convertImgArrLightBox(this.state[setInitialThumbSize]);
  };

  setInitialState = (product) => {
    let additions;
    //console.log(product);
    Object.keys(product).forEach((productKey) => {
      if (productKey !== 'product_reviews' || productKey !== 'products_additions') {
        this.setState({
          [productKey]: product[productKey],
        });
        additions = product.products_additions;
      }
    });

    this.setState({
      name: checkString(product.name) + ' Recipe',
    });
    this.setState({
      recipeIcon: setRecipeIcon(checkString(product.name)),
    });
    this.setProductImages(additions);
    this.setInitialSize(this.state);
  };

  setProductImages = (imgObj) => {
    Object.keys(imgObj[0]).forEach((imgKey) => {
      this.setState({
        [imgKey]: imgObj[0][imgKey],
      });
    });
    const src = getCorrectImgSrc(this.state);
    this.setMainImg(src);
  };

  mainImgOnChange = (src) => {
    this.setState({
      mainImg: src,
    });
  };

  changeThumbHighlight = (e) => {
    const currentHighlight = e.currentTarget.attributes['data-imgindex'].value;
    const mainSrc = e.currentTarget.attributes['data-mainsrc'].value;
    this.setState({
      currentHighlight,
    });
    this.mainImgOnChange(mainSrc);
  };

  changeSize = (e) => {
    e.preventDefault();

    const sizeText = e.currentTarget.textContent;
    const num = e.currentTarget.id;
    const currentThumbSize = checkToRenderThumbSize(sizeText, num);

    this.setState({
      activeSizeIndex: num,
      currentThumbSize,
      currentHighlight: '1',
      mainImg: this.resetMainImg(currentThumbSize),
    });
    this.convertImgArrLightBox(this.state[currentThumbSize]);
    this.getCorrectImgObjForThumbs();
  };

  getCorrectImgObjForThumbs = () => {
    const { currentThumbSize } = this.state;
    const setThumb = this.state[currentThumbSize];
    return setThumb;
  };

  /* Light box functions */
  convertImgArrLightBox = (sizeType) => {
    const lightBoxImagesArr = Object.keys(sizeType).map((key) => {
      return sizeType[key].zoom;
    });
    this.setState({
      lightBoxImagesArr,
    });
  };

  changeImg = (prev) => {
    const { lightBoxImgIndex, lightBoxImagesArr } = this.state;
    if (prev !== undefined) {
      return this.setState({
        lightBoxImgIndex:
          (lightBoxImgIndex + lightBoxImagesArr.length - 1) % lightBoxImagesArr.length,
      });
    }
    return this.setState({
      lightBoxImgIndex: (lightBoxImgIndex + 1) % lightBoxImagesArr.length,
    });
  };

  openLightBox = (e) => {
    /* Set current img */
    const lightBoxImgIndex = parseInt(this.state.currentHighlight, 10) - 1;

    e.preventDefault();
    this.setState({
      lightBoxIsOpen: !this.state.lightBoxIsOpen,
      lightBoxImgIndex,
    });
  };
  /* End of lightbox functions */

  resetMainImg = (currentThumbSize) => {
    const mainSrc = this.state[currentThumbSize].front.main;
    return mainSrc;
  };

  checkRouteParams = () => {
    /* Need a logic to write and ensure that the params are correct */
    // TODO for later;
    // Include error handling
    //product-view/:petType/:foodType/:ingredient-:id
    const { petType, foodType, ingredient, id } = this.props.match.params;
    const updateFoodType = capitalizedFirstLetters(foodType.replace(/-/g, ' ').toLowerCase());
    const updatePetType = capitalizedFirstLetters(petType.replace(/-/g, ' ').toLowerCase());
    this.setState({
      foodType: updateFoodType,
      pet: updatePetType,
    });
  };

  toggleSignInModal = () => {
    this.setState({
      signInModal: !this.state.signInModal,
    });
  };

  renderComponent = () => {
    const { state, props } = this;
    if (!state.stateUpdated) {
      return <IsLoading />;
    }
    let showtab;
    if ( state.product_id === 56){
      showtab = 
      <div>
        <div className="available-sizes futura-font text-capitalize mt-4 mb-1">
          Variety Pack Includes:
        </div>
        <div className="row justify-content-start variety-pack-includes">
            <div className="col-4 size-options text-center">
              <div className="d-flex justify-content-center variety-raw-size-color variety-pack-includes__link-to">
                <span className="text-capitalize text-dark"><a href="https://www.lotuspetfoods.com/product-view/cat/raw-food/pork-58" style={{color:'black'}}>Pork</a></span>
              </div>
            </div>
            <div className="col-4 size-options text-center">
              <div className="d-flex justify-content-center variety-raw-size-color variety-pack-includes__link-to">
                <span className="text-capitalize text-dark"><a href="https://www.lotuspetfoods.com/product-view/cat/raw-food/turkey-57" style={{color:'black'}}>Turkey</a></span>
              </div>
            </div>
            <div className="col-4 size-options text-center">
              <div className="d-flex justify-content-center variety-raw-size-color variety-pack-includes__link-to">
                <span className="text-capitalize text-dark"><a href="https://www.lotuspetfoods.com/product-view/cat/raw-food/chicken-60" style={{color:'black'}}>Chicken</a></span>
              </div>
            </div>
            <div className="col-4 size-options text-center">
              <div className="d-flex justify-content-center variety-raw-size-color variety-pack-includes__link-to">
                <span className="text-capitalize text-dark"><a href="https://www.lotuspetfoods.com/product-view/cat/raw-food/lamb-54" style={{color:'black'}}>Lamb</a></span>
              </div>
            </div>
            <div className="col-4 size-options text-center">
              <div className="d-flex justify-content-center variety-raw-size-color variety-pack-includes__link-to">
                <span className="text-capitalize text-dark"><a href="https://www.lotuspetfoods.com/product-view/cat/raw-food/venison-55" style={{color:'black'}}>Venison</a></span>
              </div>
            </div>
            <div className="col-4 size-options text-center">
              <div className="d-flex justify-content-center variety-raw-size-color variety-pack-includes__link-to">
                <span className="text-capitalize text-dark"><a href="https://www.lotuspetfoods.com/product-view/cat/raw-food/beef-59" style={{color:'black'}}>Beef</a></span>
              </div>
            </div>
        </div>
      </div>
    } else if ( state.product_id === 67) {
      showtab = 
      <div>
        <div className="available-sizes futura-font text-capitalize mt-4 mb-1">
          Variety Pack Includes:
        </div>
        <div className="row justify-content-start variety-pack-includes">
            <div className="col-4 size-options text-center">
              <div className="d-flex justify-content-center variety-raw-size-color variety-pack-includes__link-to">
                <span className="text-capitalize text-dark"><a href="https://www.lotuspetfoods.com/product-view/dog/raw-foods/pork-64" style={{color:'black'}}>Pork</a></span>
              </div>
            </div>
            <div className="col-4 size-options text-center">
              <div className="d-flex justify-content-center variety-raw-size-color variety-pack-includes__link-to">
                <span className="text-capitalize text-dark"><a href="https://www.lotuspetfoods.com/product-view/dog/raw-foods/turkey-65" style={{color:'black'}}>Turkey</a></span>
              </div>
            </div>
            <div className="col-4 size-options text-center">
              <div className="d-flex justify-content-center variety-raw-size-color variety-pack-includes__link-to">
                <span className="text-capitalize text-dark"><a href="https://www.lotuspetfoods.com/product-view/dog/raw-foods/chicken-62" style={{color:'black'}}>Chicken</a></span>
              </div>
            </div>
            <div className="col-4 size-options text-center">
              <div className="d-flex justify-content-center variety-raw-size-color variety-pack-includes__link-to">
                <span className="text-capitalize text-dark"><a href="https://www.lotuspetfoods.com/product-view/dog/raw-foods/lamb-63" style={{color:'black'}}>Lamb</a></span>
              </div>
            </div>
            <div className="col-4 size-options text-center">
              <div className="d-flex justify-content-center variety-raw-size-color variety-pack-includes__link-to">
                <span className="text-capitalize text-dark"><a href="https://www.lotuspetfoods.com/product-view/dog/raw-foods/venison-66" style={{color:'black'}}>Venison</a></span>
              </div>
            </div>
            <div className="col-4 size-options text-center">
              <div className="d-flex justify-content-center variety-raw-size-color variety-pack-includes__link-to">
                <span className="text-capitalize text-dark"><a href="https://www.lotuspetfoods.com/product-view/dog/raw-foods/beef-61" style={{color:'black'}}>Beef</a></span>
              </div>
            </div>
        </div>
      </div>
      
    } else {
      showtab = <Size
      sizeType={this.getCorrectImgObjForThumbs}
      changeSize={this.changeSize}
      changeSizeHandleChild={this.changeSizeHandleChild}
      activeSizeIndex={state.activeSizeIndex}
      color={state.color_palette}
      sizes={state.size}
    />
    }

    return (
      <MDBAnimation type="fadeIn">
        <MDBContainer className="margin-top-adjustment product-main">
          <MDBRow>
            <MDBCol className="d-flex align-items-center justify-content-center" size="12" lg="6">
              <MainImg mainImg={state.mainImg} openLightBox={this.openLightBox} />

              <MDBCol className="position-absolute product-main__thumb">
                <Lightbox
                  lightBoxImagesArr={state.lightBoxImagesArr}
                  lightBoxImgIndex={state.lightBoxImgIndex}
                  lightBoxIsOpen={state.lightBoxIsOpen}
                  changeImg={this.changeImg}
                  openLightBox={this.openLightBox}
                />

                <Thumbs
                  changeThumbHighlight={this.changeThumbHighlight}
                  color={state.color_palette}
                  currentHighlight={state.currentHighlight}
                  sizeType={this.getCorrectImgObjForThumbs()}
                  openLightBox={this.openLightBox}
                />
              </MDBCol>
            </MDBCol>
            <MDBCol size="12" lg="6">
              <OverviewInfo
                pet={state.pet}
                color={state.color_palette}
                foodType={state.foodType}
                title={state.name}
                icon={state.recipeIcon}
                description={state.description}
                starsRating={countNumberOfStars(state.product_reviews)}
                numsOfRating={countNumbersOfApprovedReviews(state.product_reviews)}
                toggleSignInModal={this.toggleSignInModal}
                signInModal={state.signInModal}
                id={state.product_id}
                name={state.name}
                color={state.color_palette}
                reviews={state.product_reviews}
                history={props.history}
              />
              {/*Show tab or hide tab if it is the Rawofood Variety*/}
              {showtab}

              <DetailIcons foodType={state.foodType} icons={state.product_icons} />
            </MDBCol>
          </MDBRow>

          <MDBRow className="product-main--section-divider">
            <MDBCol>
              <InfoTabs
                name={state.name}
                size={state.size}
                color={state.color_palette}
                ingredient={state.ingredients}
                feedingGuidelines={state.feeding_guidelines}
                analysis={state.guaranteed_analysis}
                asFed={state.as_fed}
                pet={state.pet}
                foodType={state.foodType}
                dryMatter={state.dry_matter}
                calories={state.calories}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow className="product-main--section-divider">
            <MDBCol>
              <ProductReviews
                toggleSignInModal={this.toggleSignInModal}
                signInModal={state.signInModal}
                id={state.product_id}
                name={state.name}
                color={state.color_palette}
                reviews={state.product_reviews}
                history={props.history}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow className="mt-4">
            <MDBCol>
              <Related color={state.color_palette} pet={state.pet} foodType={state.foodType} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBAnimation>
    );
  };

  render() {
    return this.renderComponent();
  }
}
