import React from 'react';
import axios from 'axios';
import xlsx from 'xlsx';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

class AdminTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      histogram: [],
    };
    this.downloadSpreadsheet = this.downloadSpreadsheet.bind(this);
  }

  componentDidMount() {
    axios.post('/gettransactions').then((res) => {
      const transactions = [];
      const histogram = [];

      res.data.rows.sort((a, b) => {
        return parseInt(a.invoice_number) - parseInt(b.invoice_number);
      });

      for (let i = 0; i < res.data.rows.length; i++) {
        const date = new Date(parseInt(res.data.rows[i].invoice_number) * 1000);
        const month = date.toString().slice(4, 7);
        const year = date.toString().slice(11, 15);

        if (histogram.indexOf(`${month} ${year}`) === -1) {
          histogram.push(`${month} ${year}`);
        }

        transactions.push({
          'Date of Purchase': date,
          Date: `${month} ${year}`,
          'Invoice Number': res.data.rows[i].invoice_number,
          'Transaction ID': res.data.rows[i].transaction_id,
          'Card Authorization': res.data.rows[i].card_auth,
          'Sales Tax Percent': res.data.rows[i].sales_tax,
          'Item Tax': res.data.rows[i].item_tax,
          'Total Tax': res.data.rows[i].total_tax,
          'Stripe Fee': res.data.rows[i].stripe_fee,
          'Shipping Cost': res.data.rows[i].shipping_cost,
          'Total Before Tax': res.data.rows[i].total_before_tax,
          'Order Total': res.data.rows[i].order_total,
          'Product Name': res.data.rows[i].product_name,
          'Product SKU': res.data.rows[i].product_sku,
          Quantity: res.data.rows[i].quantity,
          'Unit Price': res.data.rows[i].unit_price,
          'Customer First Name': res.data.rows[i].customer_first_name,
          'Customer Last Name': res.data.rows[i].customer_last_name,
          Email: res.data.rows[i].email,
          Phone: res.data.rows[i].phone,
          'Shipping Street': res.data.rows[i].shipping_street,
          'Shipping City': res.data.rows[i].shipping_city,
          'Shipping State': res.data.rows[i].shipping_state,
          'Shipping Zip': res.data.rows[i].shipping_zip,
          'Billing Street': res.data.rows[i].billing_street,
          'Billing City': res.data.rows[i].billing_city,
          'Billing State': res.data.rows[i].billing_state,
          'Billing Zip': res.data.rows[i].billing_zip,
        });
      }

      this.setState({
        transactions,
        histogram,
      });
    });
  }

  downloadSpreadsheet(e) {
    const target = e.target.firstChild.nodeValue;
    const filteredTransactions = [];

    for (let i = 0; i < this.state.transactions.length; i++) {
      if (this.state.transactions[i].Date === target) {
        delete this.state.transactions[i].Date;
        filteredTransactions.push(this.state.transactions[i]);
      }
    }

    const workbook = xlsx.utils.book_new();
    const ws = xlsx.utils.json_to_sheet(filteredTransactions);
    xlsx.utils.book_append_sheet(workbook, ws, 'Results');
    xlsx.writeFile(workbook, `${target}.xlsx`, { type: 'file' });
  }

  render() {
    return (
      <MDBContainer>
        <MDBRow>
          {this.state.histogram.map((item, i) => {
            return (
              <MDBCol className="text-center my-2" size="4" key={i}>
                <div className="col-auto" onClick={this.downloadSpreadsheet}>
                  <div className="transaction-month">{item}</div>
                </div>
              </MDBCol>
            );
          })}
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default AdminTransactions;
