import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

const LandingCarousel = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 250,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  const bannersInfo = [
    {
      url:
        'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/keep-it-raw.jpg',
      alt: 'Raw Beef Green Tripe for Dogs and Cats',
      href: './overview/dog/topper',
    }
  ];

  const videoInfo = [
    {
      src:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/oct-carousel/banner(1).mp4',
      href: '#',
    },
    //{
    //  src:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/oct-carousel/banner+raw.mp4',
    //  href: 'overview/dog/raw-foods',
    //},
    {
      src:'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Landing+Page/oct-carousel/new+cannery+2.mp4',
      href: '#',
    }
  ];
 

  return (
    <Slider {...sliderSettings}>

      {bannersInfo.map((banner, i) => (
        <div key={i}>
          <Link className="pointer d-block w-100" to={banner.href}>
            <img className="img-fluid w-100" src={banner.url} alt={banner.alt} />
          </Link>
        </div>
      ))}

      {videoInfo.map((videoBanner, i) => (
      <div key={i}>
        <Link className="carousel__banner-item--vid-resize cannery-link-adjustment" to={videoBanner.href}>
          <video
            playsInline
            autoPlay
            muted
            loop
            className="img-fluid w-100 banner-item__video-vid-margin-resize">
            <source
              src={videoBanner.src}
              type="video/mp4"
            />
          </video>
        </Link>
      </div>
      ))}

        {/*<div>
          <Link className="pointer d-block w-100" to='#'>
            <img className="img-fluid w-100" src='https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/oct-carousel/for-feb-14.jpg' alt='National Love Your Pet Day' />
          </Link>
        </div>*/}

      {/*<div>
        <Link className="carousel__banner-item--vid-resize cannery-link-adjustment" to=''>
          <video
            playsInline
            autoPlay
            muted
            loop
            className="img-fluid w-100 banner-item__video-vid-margin-resize">
            <source
              src='https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/oct-carousel/banner(1).mp4'
              type="video/mp4"
            />
          </video>
        </Link>
      </div>*/}

    </Slider>
  );
};

export default LandingCarousel;
