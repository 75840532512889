import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class KidneyDisease extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
      }

  render() {
    return (
        <div>
            <div className="pb-3 blog-margin-adjustment-desktop">
            <MDBAnimation type="fadeIn">
                <div>
                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/kidneyDisease-banner.jpg'} 
                    className="banner img-fluid w-100" 
                    alt="lotus Pet Foods - Mistake when Buying Pet Food"/>
                </div>
            </MDBAnimation>
            </div>

            <div className='container mt-5'>
                <p style={{"color": "grey"}}>Nov, 01, 2021</p>
            </div>
       
            <div className='container mt-5 text-center d-none-mobile'>
                <h1><span className="LemonMilkbold-font blog-hb">WHAT YOU NEED TO KNOW ABOUT KIDNEY DISEASE</span></h1>
            </div>

            <div className='container mt-5 text-center d-none-desktop'>
                <h1><span className="LemonMilkbold-font blog-h">WHAT YOU NEED TO KNOW ABOUT KIDNEY DISEASE</span></h1>
            </div>

            <div className='container text-center d-none-mobile'>
                <h2><span className="futura-font">Signs & Prevention:</span></h2>
            </div>

            <div className='container text-center d-none-desktop'>
                <h4><span className="futura-font">Signs & Prevention:</span></h4>
            </div>
  
            <div className='container' style={{"marginTop": "60px"}}>
                <div className='mb-5 futura-font blog-p text-center'>
                    <p>What is Kidney Disease? Any condition that causes the kidneys to stop functioning properly is referred 
                    to as kidney or renal disease in cats and dogs, and may vary significantly in severity. While damage is in progress, 
                    there is still functional tissue left. If you suspect that your pet is suffering from a kidney-related problem, 
                    take him to your vet to get the right diagnosis.
                    </p>           
                </div>
                <div className='mb-5 futura-font blog-p text-center'>
                    <p>When looking for a diet to feed your pet that is suffering from this issue, you want to</p> 
                    <p><strong><span style={{"color":"red", "fontWeight": "900"}}>REDUCE</span></strong> these levels in their food:</p>          
                </div>
             </div>
            <div className='container text-center'>
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/kidneyDisease-icons.png'} 
                        className="img-fluid"
                        alt="lotus Pet Foods - phosphorous, minerals, protein, salt"/>
                    </div>
                </MDBAnimation>
            </div>
            <br />
            <div className='container text-center d-none-mobile mt-5'>
                <p><span className="blog-pb futura-bold-font">Our Recommendations</span></p>
            </div>

            <div className='container text-center d-none-desktop mt-5'>
                <p><span className="blog-p LemonMilkbold-font">Our Recommendations</span></p>
            </div>

            <MDBContainer>
             <MDBRow className="d-flex justify-content-center align-items-center mt-5">
                <MDBCol xs="12" sm="12" md="6" className="px-5 py-4">
                    <div className="futura-bold-font blog-h-color">
                        <h3>Just Juicy for Cats</h3>
                    </div>
              
                    <div className="futura-font blog-p">
                        <p>Our Just Juicy line of cat cans maybe appropriate as well depending on the level of protein & phosphorous your vet recommends. Always check with your petʼs vet before switching their diet.</p>
                    </div>
                </MDBCol>
                <MDBCol xs="12" sm="12" md="6" className="d-flex justify-content-center px-0">
                    <div>
                        <img
                            src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/kidneyDisease-image1.jpg"
                            className="img-fluid"
                        />
                    </div>
                </MDBCol>
            </MDBRow>

       
            <MDBRow className="d-flex justify-content-center align-items-center">
                <MDBCol xs="12" sm="12" md="5" className="d-flex justify-content-center px-0">
                    <div>
                        <img
                            src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/kidneyDisease-image2.jpg"
                            className="img-fluid d-none-mobile"
                        />
                    </div>
                </MDBCol>
                <MDBCol xs="12" sm="12" md="6" className="px-5 py-4">
                        <div className="futura-bold-font blog-h-color">
                            <h3>Kibble for Senior Dogs</h3>
                        </div>
                    
                        <div className="futura-font blog-p">
                            <p>Lotus senior diet for dogs has moderate level of protein at 18%, low phosphorus at 0.49% and low sodium at 0.15%.</p>
                        </div>
                </MDBCol>
                <MDBCol xs="12" sm="12" md="5" className="d-flex justify-content-center px-0">
                    <div>
                        <img
                            src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/kidneyDisease-image2.jpg"
                            className="img-fluid d-none-desktop"
                        />
                    </div>
                </MDBCol>
            </MDBRow>


            <MDBRow className="d-flex justify-content-center align-items-center mt-5">
                <MDBCol xs="12" sm="12" md="6" className="px-5 py-4">
                    <div className="futura-bold-font blog-h-color">
                        <h3>Raw for Dogs & Cats</h3>
                    </div>
                   
                    <div className="futura-font blog-p">
                        <p>Our Lotus Raw food for dogs & cats are also a good option, with no ground bones we keep the phosphorus levels low, with the lowest formula being Venison for cats, with a phosphorus level of 0.24%
                            protein 15% and sodium at 0.13%.</p>
                    </div>
                </MDBCol>
                <MDBCol xs="12" sm="12" md="6" className="d-flex justify-content-center px-0">
                    <div>
                        <img
                            src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/kidneyDisease-image3.jpg"
                            className="img-fluid"
                        />
                    </div>
                </MDBCol>
            </MDBRow>
            </MDBContainer>
           

        </div>
    );
  }
}

export default KidneyDisease;