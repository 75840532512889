import React from 'react';
import { Marker, InfoWindow } from 'react-google-maps';

class Markers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: this.props.markers,
      showYouAreHere: false,
      showInfo: false,
      place: null,
      index: null,
      markerSize: new google.maps.Size(40, 69),
      hereSize: new google.maps.Size(120, 66),
      animation: this.props.animation,
    };
    this.showInfo = this.showInfo.bind(this);
    this.hideInfo = this.hideInfo.bind(this);
  }

  componentWillMount() {
    if (window.innerWidth <= 768) {
      this.state.markerSize = new google.maps.Size(20, 35);
      this.state.hereSize = new google.maps.Size(76, 42);
    }
  }

  showInfo(index, place) {
    this.setState({
      showInfo: true,
      index: index,
      place: place,
    });
  }

  hideInfo() {
    this.setState({
      showInfo: false,
      index: null,
      place: null,
    });
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({
          showYouAreHere: true,
        });
      }.bind(this),
      1000,
    );
  }

  render() {
    return (
      <div>
        {this.props.markers &&
          this.props.markers.map((place, i) => {
            let lat = parseFloat(place.lat, 10);
            let lng = parseFloat(place.lng, 10);

            return (
              <Marker
                key={i}
                animation={this.state.animation}
                onClick={() => this.showInfo(i, place)}
                position={{ lat: lat, lng: lng }}
                icon={{
                  url: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Store+Locator/pin.png',
                  scaledSize: this.state.markerSize,
                }}>
                {this.state.showYouAreHere && this.props.showYouAreHere && (
                  <Marker
                    defaultZIndex={1000}
                    animation={2}
                    position={{ lat: this.props.lat, lng: this.props.lng }}
                    icon={{
                      url:
                        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Store+Locator/you-are-here-pin.png',
                      scaledSize: this.state.hereSize,
                    }}
                  />
                )}
                {this.state.showInfo && this.state.index === i ? (
                  <InfoWindow onCloseClick={this.hideInfo} defaultOptions={{ maxWidth: 1000 }}>
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-auto col info-col pl-0 my-auto">
                          <img className="info-picture" src={this.state.place.picture_url} />
                        </div>
                        <div className="col-sm-auto col p-0 info-col mt-2 my-auto">
                          <div className="info-name mb-1">{this.state.place.name}</div>
                          <div className="info-line1">{this.state.place.line1}</div>
                          <div className="info-line2 mb-1">{this.state.place.line2}</div>
                          <div className="info-line2 mb-1">{this.state.place.phone}</div>
                          <a
                            target="_blank"
                            href={`https://www.google.com/maps/search/?api=1&query=${this.state.place.lat},${this.state.place.lng}`}>
                            <button type="input" className="info-button">
                              Directions
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </InfoWindow>
                ) : null}
              </Marker>
            );
          })}
      </div>
    );
  }
}

export default Markers;
