import React from 'react';
import $ from 'jquery';

class FAQ extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    $('.faq-img').on('load', function() {
      let imgHeight = $('.faq-img').height();
      let imgWidth = $('.faq-img').width();

      $('.front, .back, .flip-container')
        .height(imgHeight)
        .width(imgWidth);
    });

    $(document).ready(function() {
      $(window).resize(function() {
        let imgHeight = $('.faq-img').height();
        let imgWidth = $('.faq-img').width();

        $('.front, .back, .flip-container')
          .height(imgHeight)
          .width(imgWidth);
      });
    });
  }

  render() {
    return (
      <div>
        <div className='container faq-body'>
          <div className='row'>
            <div className='col faq-text mb-sm-0 mb-3'>FAQ</div>
          </div>
          <div className='row page-divider mb-5 about-us-divider' />
          <div className='row'>
          <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/FAQ/Where-is-the-Food-Is-made-p0927.jpg'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/FAQ/Where-is-the-Food-Is-made-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/FAQ/Raw-Cat-Food-P0927.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/FAQ/Raw-Cat-Food-0927.png'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/FAQ/Bone-in-raw-food-p0927.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/FAQ/Bone-in-raw-food-0927.png'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/FAQ/Phosphorus-in-Raw-Cat-p0927.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/FAQ/Phosphorus-in-Raw-Cat-0927.png'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/baking-temp.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Baking-Temperature-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/BPA-free.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/BPA-Free-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/brown-rice.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Brown-Rice-0927-1.png'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/by-products.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/By-Products-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/chickens.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/chicken-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/DMA-analysis.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/DMA-Analysis-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/duck-dry.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Duck-Dry-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/extruded-cooking.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Extruded-Cooking-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/extruded-foods.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Extruded-Foods-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/fat.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Fat-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/feeding-trials.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Feeding-Trials-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/fish-meal-dry.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Fish-Meal-Dry-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/fresh-meat.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Fresh-Meat-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/garlic.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Garlic-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/good-grains.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Good-Grains-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/herrign-meal-dry.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Herring-Meal-Dry-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/meat-dry.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Meat-Dry-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/meat-meals.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Meat-Meals-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/pea-fiber.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Pea-Fiber-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/peanut-products.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Peanut-Products-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/pollock.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Pollock-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/potasssium-chloride.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Potassium-Chloride-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/proteins.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/proteins-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/salmon-pate.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Salmon-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/sardine.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Sardine-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/taurine.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Taurine-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/temp-cans.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Temperature-Cans-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/tripolyphosphate.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Tripolyphosphate-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/turkey.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Turkey-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/vit-mineral.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Vitamin-Mineral-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/vitamins.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Vitamins-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-4 col-xl-3 col-6 d-flex justify-content-center align-content-center px-0 pb-md-4 pb-3'>
              <div className='flip-container'>
                <div className='flipper'>
                  <div className='front'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/vitmain-k.png'} />
                  </div>
                  <div className='back'>
                    <img className='faq-img' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/Vitamin-K-0927.jpg'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
