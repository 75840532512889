import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class BakeExtruded extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
      }

  render() {
    return (
        <div>
            <div className="pb-3 blog-margin-adjustment-desktop">
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/baked-banner.jpg'} 
                        className="banner img-fluid w-100" 
                        alt="lotus Pet Foods - Baking VS Extruded Foods"/>
                    </div>
                </MDBAnimation>
            </div>

            <div className='container mt-5'>
                <p style={{"color": "grey"}}>July, 01, 2022</p>
            </div>
    
            <div className='container mt-5 text-center d-none-mobile'>
                <h1><span className="LemonMilkbold-font blog-hb">Baking vs Extruded Foods</span></h1>
            </div>

            <div className='container mt-5 text-center d-none-desktop'>
                <h1><span className="LemonMilkbold-font blog-h">Baking vs Extruded Foods</span></h1>
            </div>

            <div className='container'>
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/oily.gif'} 
                        className="banner img-fluid w-100" 
                        alt="lotus Pet Foods - Baking VS Extruded Foods"/>
                    </div>
                </MDBAnimation>
             </div>

             <div className='container' style={{"marginTop": "60px"}}>
                <div className='mb-5 futura-font blog-p'>
                    <p>More fresh meat: Lotus kibble uses more fresh meat than any premium extruded kibble on the market. Lotus uses 33-40% fresh meat in all of our formulas. This means we can have lower Ash levels with better Calcium and Phosphorus levels. Fresh meat is more bioavailable, and digestible than meal, which is why Lotus kibble is baked so the kibble can contain as much fresh meat as possible.
                    </p> 
                    <br/>
                    <p>90-100% Protein Retention: 90-100% of the amino acids are retained in the oven baking process compared to a 20% loss in amino acids in extruded kibble. </p>
                    <br/>
                    <p>90-100% Vitamin Retention: 90-100% of the vitamins are retained in the oven baking process compared to the 40% loss of vitamins in extruded kibble. 
                    </p>
                    <br/>
                    <p>No sprayed on flavors: The palatability of Lotus kibble comes from the baking process that allows for a high percentage of fresh meat. During the baking process the natural juices from our meats flavor our kibble naturally, just like cooking at home. This means we do not have to spray on any flavoring at the end of our baking process. Our kibble is not greasy, it is dry, kind of like a dog biscuit. (visuals of the Lotus kibble and then grease spots from extruded kibble).
                    </p>
                    <br/>
                    <p>50% less starch: Oven baking allows for 50% less starch delivery to the dog or cat when consumed. This will allow for half the glucose/sugar delivery. Lowering the starch in a food can help ease issues pertaining to yeast infections. 
                    </p>
                    <br/>
                </div>
            </div>
        </div>
    );
  }
}

export default BakeExtruded;