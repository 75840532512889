import loadImage from 'blueimp-load-image';
import Axios from 'axios';

const { handleErrors } = require('./reqsHandlers');
const {
  endPoint: { filesUploads },
} = require('./stringVars');
const { correctImgRotation } = require('../Admin/AdminMain/AdminPages/Stores/helper.jsx');
/* for state management */
const onChange = (context, e) => {
  const { name, value } = e.target;
  context.setState({
    [name]: value,
  });
  e.preventDefault();
};

const validateForm = (state, event) => {
  event.preventDefault();
  let validated = true;
  event.target.className += ' was-validated';
  Object.keys(state).forEach((key) => {
    if (typeof state[key] === 'string' && state[key] === '') {
      validated = false;
    }
  });
  return validated;
};

/* for DB queries */
const setUserLocalStorageInfo = (type, obj) => {
  const { _id, email, first_name, last_name, retailer, quizzes, points, token } = obj;
  const adjustData = { _id, email, first_name, last_name, retailer, quizzes, points, token };
  localStorage.setItem(type, JSON.stringify(adjustData));
};

const setToken = (tokenType) => {
  if (!localStorage.getItem(tokenType)) return { Authorization: 'none' };
  const { token } = JSON.parse(localStorage.getItem(tokenType));
  const authHeader = { Authorization: 'Bearer ' + token };
  return authHeader;
};

const tokenStrOnly = (tokenType) => {
  if (!localStorage.getItem(tokenType)) return 'none';
  const { token } = JSON.parse(localStorage.getItem(tokenType));
  const authHeader = 'Bearer ' + token;
  return authHeader;
};

const setNewUserObj = (obj, retailer) => {
  const { firstName, lastName, email, password, selectedStore } = obj;
  const newUser = {
    first_name: firstName,
    last_name: lastName,
    email,
    password,
    retail_store: selectedStore,
    retailer,
  };
  return newUser;
};

/* other helpers */
const capitalizedFirstLetters = (string) => {
  const stringSplit = string.split(' ');
  return stringSplit.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

const checkString = (productName) => {
  const recipeName = [
    'chicken',
    'beef',
    'turkey',
    'pork',
    'venison',
    'lamb',
    'salmon',
    'sardine',
    'rabbit',
    'duck',
    'pollock',
    'adult',
    'puppy',
    'senior',
    'low',
    'fat',
    'tripe',
  ];
  return productName
    .split(' ')
    .filter((word) => {
      for (let i = 0; i < recipeName.length; i += 1) {
        if (word.toLowerCase() === recipeName[i]) {
          return recipeName[i];
        }
      }
    })
    .join(' ');
};

/**
 * @param {String} s3Imgurl
 * @returns {String} Returns correct oriented blob info
 */
const correctImgOrientation = async (s3Imgurl) => {
  return new Promise((resolve, reject) => {
    try {
      loadImage(
        s3Imgurl,
        (canvas) => {
          resolve(canvas.toDataURL());
        },
        { orientation: true },
      );
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
};

/**
 * @param {Object} file
 * @returns {String} s3 bucket and key
 */

const uploadImgToS3 = async (file, imgFor) => {
  const form = new FormData();

  const endPoint = {
    store: 'single-upload',
    product: 'upload-product-img',
  };

  form.append('photos', file);
  const config = {
    headers: { 'Content-Type': 'multipart/form-data', Authorization: tokenStrOnly('admin') },
  };

  try {
    const { data } = await Axios.post(`${filesUploads.admin}/${endPoint[imgFor]}`, form, config);
    if (imgFor === 'product') return { files: data.files, accepted: data.accepted };
    return data.fileData.fileName;
  } catch (err) {
    const { status } = err.response;
    console.error(err);

    if (status === 400) return handleErrors(400);
    if (status === 406) return handleErrors(406);
    handleErrors(500);
  }
};

/**
 *
 * @param {Object} context switches this context to Component
 * @param {Object} value
 */
const getUploadImg = async (context, value) => {
  // start img loader
  context.setState({
    imgLoader: true,
    updatedImg: true,
  });

  const fileInfo = value;
  const fileBlob = URL.createObjectURL(fileInfo[0]);
  try {
    const uploadedImgDataUrl = await correctImgRotation(fileBlob);

    context.setState({
      fileInfo: fileInfo[0],
      uploadedImgDataUrl,
      uploadedImgLoaded: true,
      imgLoader: false,
    });
  } catch (err) {
    console.error(err);
    handleErrors(500);
  }
};

const randomString = () => {
  return (
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15)
  );
};

module.exports = {
  onChange,
  validateForm,
  setUserLocalStorageInfo,
  setToken,
  setNewUserObj,
  capitalizedFirstLetters,
  checkString,
  correctImgOrientation,
  tokenStrOnly,
  uploadImgToS3,
  getUploadImg,
  randomString,
};
