import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import SwipePopUp from '../SwipePopUp';

class FishMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
      reviews: {
        dogRegular: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogSmall: {
          total: 0,
          stars: 0,
          average: 0
        },
        treat: {
          total: 0,
          stars: 0,
          average: 0
        },
        salmonLoaf: {
          total: 0,
          stars: 0,
          average: 0
        },
        sardineLoaf: {
          total: 0,
          stars: 0,
          average: 0
        },
        catKibble: {
          total: 0,
          stars: 0,
          average: 0
        },
        salmonPate: {
          total: 0,
          stars: 0,
          average: 0
        },
        sardinePate: {
          total: 0,
          stars: 0,
          average: 0
        },
        salmonJuicy: {
          total: 0,
          stars: 0,
          average: 0
        },
        pollockJuicy: {
          total: 0,
          stars: 0,
          average: 0
        }
      }
    };
    this.anotherProtein = this.anotherProtein.bind(this);
  }

  componentWillMount() {
    axios.post('/getallreviews').then(res => {
      const reviews = {
        dogRegular: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogSmall: {
          total: 0,
          stars: 0,
          average: 0
        },
        treat: {
          total: 0,
          stars: 0,
          average: 0
        },
        salmonLoaf: {
          total: 0,
          stars: 0,
          average: 0
        },
        sardineLoaf: {
          total: 0,
          stars: 0,
          average: 0
        },
        catKibble: {
          total: 0,
          stars: 0,
          average: 0
        },
        salmonPate: {
          total: 0,
          stars: 0,
          average: 0
        },
        sardinePate: {
          total: 0,
          stars: 0,
          average: 0
        },
        salmonJuicy: {
          total: 0,
          stars: 0,
          average: 0
        },
        pollockJuicy: {
          total: 0,
          stars: 0,
          average: 0
        }
      };

      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].product_id === 6) {
          reviews.dogRegular.total++;
          reviews.dogRegular.stars += res.data[i].stars;
          reviews.dogRegular.average = reviews.dogRegular.stars / reviews.dogRegular.total;
        }

        if (res.data[i].product_id === 13) {
          reviews.dogSmall.total++;
          reviews.dogSmall.stars += res.data[i].stars;
          reviews.dogSmall.average = reviews.dogSmall.stars / reviews.dogSmall.total;
        }

        if (res.data[i].product_id === 18) {
          reviews.treat.total++;
          reviews.treat.stars += res.data[i].stars;
          reviews.treat.average = reviews.treat.stars / reviews.treat.total;
        }

        if (res.data[i].product_id === 32) {
          reviews.salmonLoaf.total++;
          reviews.salmonLoaf.stars += res.data[i].stars;
          reviews.salmonLoaf.average = reviews.salmonLoaf.stars / reviews.salmonLoaf.total;
        }

        if (res.data[i].product_id === 33) {
          reviews.sardineLoaf.total++;
          reviews.sardineLoaf.stars += res.data[i].stars;
          reviews.sardineLoaf.average = reviews.sardineLoaf.stars / reviews.sardineLoaf.total;
        }

        if (res.data[i].product_id === 38) {
          reviews.catKibble.total++;
          reviews.catKibble.stars += res.data[i].stars;
          reviews.catKibble.average = reviews.catKibble.stars / reviews.catKibble.total;
        }

        if (res.data[i].product_id === 46) {
          reviews.salmonPate.total++;
          reviews.salmonPate.stars += res.data[i].stars;
          reviews.salmonPate.average = reviews.salmonPate.stars / reviews.salmonPate.total;
        }

        if (res.data[i].product_id === 47) {
          reviews.sardinePate.total++;
          reviews.sardinePate.stars += res.data[i].stars;
          reviews.sardinePate.average = reviews.sardinePate.stars / reviews.sardinePate.total;
        }

        if (res.data[i].product_id === 53) {
          reviews.salmonJuicy.total++;
          reviews.salmonJuicy.stars += res.data[i].stars;
          reviews.salmonJuicy.average = reviews.salmonJuicy.stars / reviews.salmonJuicy.total;
        }

        if (res.data[i].product_id === 51) {
          reviews.pollockJuicy.total++;
          reviews.pollockJuicy.stars += res.data[i].stars;
          reviews.pollockJuicy.average = reviews.pollockJuicy.stars / reviews.pollockJuicy.total;
        }
      }

      this.setState({
        reviews: reviews
      });
    });
  }

  anotherProtein() {
    const protein = $('#protein').val();
    this.props.history.push(`/${protein}`);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const htmlWidth = $('html').width();
    const scroll = htmlWidth * 0.514;
    $('.scrolling-wrapper').scrollLeft(scroll);
  }

  render() {
    return (
      <div>
        <SwipePopUp />
        <div className='container chicken-container'>
          <div className='row d-flex align-items-center justify-content-center mb-5'>
            <div className='col-12 text-center another-protein'>Try Another Protein?</div>
            <div className='col-8 mt-3 mb-2'>
              <select id='protein' className='form-control'>
                <option value='rabbit'>Rabbit</option>
                <option value='pork'>Pork</option>
                <option value='duck'>Duck</option>
                <option value='beef'>Beef</option>
                <option value='green-tripe'>Green Tripe</option>
                <option value='venison'>Venison</option>
                <option value='turkey'>Turkey</option>
                <option value='lamb'>Lamb</option>
                <option value='chicken'>Chicken</option>
              </select>
            </div>
            <div className='col-12 d-flex align-content-center justify-content-center'>
              <button type='submit' className='protein-submit-button' onClick={this.anotherProtein}>
                Submit
              </button>
            </div>
          </div>
          <div className='protein-pet-header text-center'>DOG</div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 protein-card align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/baked-kibble.png'} />
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/regular-bites/sardine' href='/dog-food/regular-bites/sardine'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/dogregular4.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#596e69' }}>
                    SARDINE RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.dogRegular.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogRegular.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/regular-bites/sardine' href='/dog-food/regular-bites/sardine'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/dogregular10.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#596e69' }}>
                    SARDINE RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.dogRegular.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogRegular.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/regular-bites/sardine' href='/dog-food/regular-bites/sardine'>
                  <img className='large-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/dogregular20.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#596e69' }}>
                    SARDINE RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.dogRegular.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogRegular.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 protein-card align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/small-bites.png'} />
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog/small-bites/sardine' href='/dog/small-bites/sardine'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/dogsmall4.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#596e69' }}>
                    SARDINE RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.dogSmall.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogSmall.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog/small-bites/sardine' href='/dog/small-bites/sardine'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/dogsmall10.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#596e69' }}>
                    SARDINE RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.dogSmall.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogSmall.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 protein-card align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/dog-treats.png'} />
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/treats/sardine' href='/dog-food/treats/sardine'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/treat.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#596e69' }}>
                    SARDINE RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.treat.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.treat.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 protein-card align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/loafs-1.png'} />
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/loafs/sardine' href='/dog-food/loafs/sardine'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/sardineloaf.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#596e69' }}>
                    SARDINE RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.sardineLoaf.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.sardineLoaf.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/dog-food/loafs/salmon' href='/dog-food/loafs/salmon'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/salmonloaf.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#87ead8' }}>
                    SALMON RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.salmonLoaf.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.salmonLoaf.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-5 ingredient-divider' />
          <div className='protein-pet-header mt-5 text-center'>CAT</div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 protein-card align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/baked-kibble.png'} />
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/cat-food/kibble/sardine' href='/cat-food/kibble/sardine'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/catkibble2.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#596e69' }}>
                    SARDINE RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.catKibble.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catKibble.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/cat-food/kibble/sardine' href='/cat-food/kibble/sardine'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/catkibble5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#596e69' }}>
                    SARDINE RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.catKibble.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catKibble.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/cat-food/kibble/sardine' href='/cat-food/kibble/sardine'>
                  <img className='large-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/catkibble11.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#596e69' }}>
                    SARDINE RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.catKibble.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catKibble.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 protein-card align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/pates-2.png'} />
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/cat-food/pates/sardine' href='/cat-food/pates/sardine'>
                  <img className='small-can-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/catpatesardine22.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#596e69' }}>
                    SARDINE RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.sardinePate.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.sardinePate.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/cat-food/pates/sardine' href='/cat-food/pates/sardine'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/catpatesardine5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#596e69' }}>
                    SARDINE RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.sardinePate.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.sardinePate.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/cat-food/pates/sardine' href='/cat-food/pates/sardine'>
                  <img className='large-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/catpatesardine12.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#596e69' }}>
                    SARDINE RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.sardinePate.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.sardinePate.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 protein-card align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/pates-1.png'} />
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/cat-food/pates/salmon' href='/cat-food/pates/salmon'>
                  <img className='small-can-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/catpatesalmon22.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#87ead8' }}>
                    SALMON RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.salmonPate.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.salmonPate.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/cat-food/pates/salmon' href='/cat-food/pates/salmon'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/catpatesalmon5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#87ead8' }}>
                    SALMON RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.salmonPate.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.salmonPate.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/cat-food/pates/salmon' href='/cat-food/pates/salmon'>
                  <img className='large-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/catpatesalmon12.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#87ead8' }}>
                    SALMON RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.salmonPate.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.salmonPate.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 protein-card align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/just-juicy-2.png'} />
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/cat-food/just-juicy/salmon' href='/cat-food/just-juicy/salmon'>
                  <img className='small-can-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/catjuicysalmon2.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#006385' }}>
                    SALMON RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.salmonJuicy.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.salmonJuicy.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/cat-food/just-juicy/salmon' href='/cat-food/just-juicy/salmon'>
                  <img style={{ maxWidth: '77%' }} src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/catjuicysalmon5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#006385' }}>
                    SALMON RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.salmonJuicy.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.salmonJuicy.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 protein-card align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/just-juicy-1.png'} />
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/cat-food/just-juicy/pollock' href='/cat-food/just-juicy/pollock'>
                  <img className='small-can-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/catjuicypollock2.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#b1e866' }}>
                    POLLOCK RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.pollockJuicy.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.pollockJuicy.total}</div>
                </div>
              </div>
              <div className='col-12 protein-card align-self-end text-center'>
                <Link to='/cat-food/just-juicy/pollock' href='/cat-food/just-juicy/pollock'>
                  <img style={{ maxWidth: '77%' }} src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Fish/catjuicypollock5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#b1e866' }}>
                    POLLOCK RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='fish-protein'
                    starCount={5}
                    value={this.state.reviews.pollockJuicy.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.pollockJuicy.total}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FishMobile;
