module.exports = {
    infoText: [
      {
        alt: 'USDA Inspected & passed meats',
        src:
          'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/usds-inspected-meats.png',
      },
      {
        alt: 'Good source of calcium',
        src:
          'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/source-of-calcium.png',
      },
      {
        alt: 'Natural source of Thiamine',
        src:
          'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/source-of-thiamine.png',
      },
    ],
  
    whatsInsideInfo: [
      {
        icon:
          'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/bone-icon.png',
        title: 'No Ground Bone',
        text:
          'Our raw recipes do not contain any ground bone. This helps texture and palatability. It also helps maintain proper calcium and phosphorous levels. *Sardine recipe contains whole ground sardines.',
      },
      {
        icon:
          'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/meat-icon.png',
        title: ' No added hormones or antibiotics',
        text: `We only use pasture raised, grass fed and or vegetarian fed meats, free range poultry and wild caught sardines. We use real cuts of meats, 
        poultry and fish like you would find in your kitchen from New Zealand and humane suppliers in the USA . No hormones added. No antibiotics added.`,
      },
      {
        icon:
          'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/vegatable-icon.png',
        title: 'No vegetables or fruits',
        text:
          'Our raw recipes for cats do not contain any vegatables or fruits. This helps texture and palatability.',
      },
      {
        icon:
          'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/chicken-leg.png',
        title: 'Humanely Raised',
        text:
          'Our chicken and turkey thighs, hearts and livers come from free range chickens and turkeys that are qualified by the Global Animal Welfare as Step 3 raised poultry. Our Venison and lamb meat, hearts and livers are pasture raised in New Zealand. Our grass fed and vegetarian fed pork suppliers have requirements for animal welfare, health, and treatment.',
      },
    ],
  
    benefitsInfo: [
      {
        icon:
          'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/thumbnail-1.jpg',
  
        title: 'Naturally clean teeth',
        text:
          'Many dry foods leave a starchy coating on teeth, which promotes plague. Feeding a raw diet can lead to healthier teeth and gum.',
      },
      {
        icon:
          'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Cat+Food/Rawfood/benefit_thumbnail_2.jpg',
  
        title: 'Healthy skin and coat',
        text:
          'Many cats are allergic to grains, which can lead to pathologic skin conditions. Also feeding raw is a great way to naturally keep your cat hydrated',
      },
      {
        icon:
          'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Cat+Food/Rawfood/benefit_thumbnail_3.jpg',
  
        title: 'Natural weight control',
        text: ` Cats are healthiest on a high protein, low carbohydrate diet. Also feeding raw is a great way to naturally keep your cat hydrated.
        Feeding raw closely mirrors what cats would naturally eat in the wild. Cat's digestive systems are designed to ingest raw meat and 
        healthiest eating what nature intended.`,
      },
    ],
  };