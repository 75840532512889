import React from 'react';
import swal from 'sweetalert2';
import axios from 'axios';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { Link } from 'react-router-dom';

import PasswordField from '../../../FieldComponents/InputFields/PasswordField.jsx';
import SimpleInputField from '../../../FieldComponents/InputFields/InputField.jsx';

const { userLogin } = require('../helper');
const { onChange } = require('../../../_helper/index.jsx');

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };
  }

  render() {
    const { email, password } = this.state;
    return (
      <MDBContainer>
        <MDBRow center>
          <MDBCol size="12">
            <form onSubmit={(e) => userLogin(e, axios, this.state, this.props)}>
              <SimpleInputField
                label="Email"
                onChange={(e) => onChange(this, e)}
                name="email"
                value={email}
              />
              <PasswordField
                value={password}
                label="Password"
                onChange={(e) => onChange(this, e)}
                name="password"
              />
              <MDBCol size="12" className="login-fields--margin-top-adjustment">
                {/* ToggleSignInModal is passed down from UserAccForm */}
                <Link
                  onClick={this.props.toggleSignInModal}
                  to="/password-reset"
                  className="login-fields__reset-link-btn font-italic">
                  Forgot password?
                </Link>
              </MDBCol>
              <MDBCol size="12" className="my-3 pl-1">
                <MDBBtn className="py-2" color="dark" type="submit">
                  Login
                </MDBBtn>
              </MDBCol>
            </form>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default LoginComponent;
