import React from 'react';
import YouTube from 'react-youtube';
import swal from 'sweetalert2';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBAnimation } from 'mdbreact';

export default function QuizVideoTwo(props) {
  const { state } = props.location;
  const { history } = props;
  const youtubeOpts = {
    playerVars: {
      controls: '0',
    },
  };

  return (
    <MDBContainer className="retailer-quiz__training-video margin-top-adjustment">
      <MDBRow>
        <MDBCol size="12">
          <h1 className="retailer-quiz__header1 brandon-font text-center">
            {state.quizName} Quiz Video
          </h1>
          <div className="page-divider mb-3"></div>
        </MDBCol>
        <MDBCol size="12" className="retailer-quiz__video-wrapper mt-4">
          <MDBAnimation type="fadeIn" delay="1s" duration="500">
            <YouTube
              className="retailer-quiz__youtube-vid"
              opts={youtubeOpts}
              videoId={state.vidSrc}
            />
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}