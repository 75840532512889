import React, { forwardRef } from 'react';
import { MDBRow, MDBCol } from 'mdbreact';

function Thumbs(props) {
  const { sizeType, color, currentHighlight } = props;
  const thumbArr = ['front', 'back', 'top', 'label'];
  const highlight = { border: `solid 2px ${color}` };
  const noHighlight = { borderColor: '' };
  return (
    <div className="product-main__img-thumbs-wrapper">
      <MDBRow>
        <MDBCol className="d-flex justify-content-center justify-contents-md-start">
          {thumbArr.map((item, i) => {
            return (
              <div key={i} className="product-main__img-wrapper">
                <img
                  onClick={props.openLightBox}
                  onMouseEnter={props.changeThumbHighlight}
                  data-imgindex={String(i + 1)}
                  data-mainsrc={sizeType[item] === undefined ? '' : sizeType[item].main}
                  data-zoomsrc={sizeType[item] === undefined ? '' : sizeType[item].zoom}
                  className={`img-fluid product-main__img-thumb ${
                    sizeType[item] === undefined ? 'd-none' : ''
                  }`}
                  style={currentHighlight === String(i + 1) ? highlight : noHighlight}
                  src={sizeType[item] === undefined ? '' : sizeType[item].thumb}
                />
              </div>
            );
          })}
        </MDBCol>
      </MDBRow>
    </div>
  );
}

export default Thumbs;
