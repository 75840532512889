import React, { Component } from 'react';
import {
  MDBRow,
  MDBCol,
  MDBAnimation,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
} from 'mdbreact';
import { Link } from 'react-router-dom';
import StarRatingComponent from 'react-star-rating-component';

import CatRawBrochure from '../Custom/catRaw/CatRawBrochure.jsx';
import DogRawBrochure from '../Custom/dogRaw/DogRawBrochure.jsx';

const { lightOrDarkBg } = require('../../helper');

export default function ProductImage(props) {
  const { petType, foodType, products } = props;

  const addBrochure = () => {
    if (petType === 'cat' && foodType === 'raw-food') {
      return <CatRawBrochure foodType={foodType} petType={petType} />;
    }
    if (petType === 'dog' && foodType === 'raw-foods') {
      return <DogRawBrochure />;
    }
    return null;
  };

  return (
    <MDBRow className="my-3">
      <MDBCol size="12">
        <MDBRow className="product-cards">
          {/*console.log(products)*/}
          {products.map((product, keys) => {
            const { name, image, product_id, size, color, ratings, starsRatingVal } = product;
            const textColor = lightOrDarkBg(color) === 'dark' ? '#fff' : '#212529';
            return (
              <MDBCol size="12" md="6" lg="4" xl="3" key={keys} className="product-cards__product">
                <MDBCard className="card--no-shadow card--max-height card--110-percent-width my-4">
                  <Link
                    className="no-underline overflow-hidden product-overview__product-link"
                    to={`/product-view/${petType}/${foodType}/${name.toLowerCase()}-${product_id}`}>
                    <div className="image-zoom-effect">
                      <MDBCardImage
                        top
                        src={image}
                        overlay="white-slight"
                        hover
                        waves
                        alt={name + ' Recipe'}
                        className={
                          petType === 'dog' && foodType === 'raw-foods' ? 'card--smaller-width' : ''
                        }
                      />
                    </div>
                  </Link>
                  <MDBCardBody className="text-center card--body-text-adjustment card--margin-bottom-adjustment p-0">
                    <Link
                      className="no-underline text-dark d-block"
                      to={`/product-view/${petType}/${foodType}/${name}-${product_id}`}>
                      <div
                        className="product-cards__product-info"
                        style={{ backgroundColor: color, color: textColor }}>
                        <MDBCardTitle className="dk-font product-cards__product-name">
                          {name} Recipe
                        </MDBCardTitle>
                        <span
                          // style={{ color: `${textColor}` }}
                          className={`futura-font product-cards__product-size font-weight-normal ${
                            lightOrDarkBg(color) === 'dark' ? 'text-white' : 'dark-text'
                          }`}>
                          {size.join(' | ')}
                        </span>
                      </div>
                    </Link>
                    <MDBRow center className="mt-2">
                      <MDBCol size="12">
                        <StarRatingComponent
                          className="product-cards__star-rating"
                          name="product"
                          starCount={5}
                          value={starsRatingVal || null}
                          renderStarIcon={(index, value) => {
                            return (
                              <span>
                                <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                              </span>
                            );
                          }}
                          editing={false}
                        />
                        <p className="product-cards__review-text">{ratings || 0}</p>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            );
          })}
          {addBrochure()}
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
}
