import React, { Component, Fragment } from 'react';
import { MDBBtn } from 'mdbreact';

const { createAnswersTups } = require('../helper.js');

export default class Answers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentAnswer: '',
    };
  }

  selectAnswer = (e, answer, correctAnswer) => {
    e.preventDefault();
    this.setState({
      currentAnswer: answer,
    });
    this.props.checkAnswer(e, answer, correctAnswer);
  };

  render() {
    const { props } = this;
    const { currentAnswer } = this.state;
    const answersArr = createAnswersTups(props.answers);

    return (
      <div className="retailer-quiz__answer-btn-wrapper">
        <Fragment>
          {answersArr.map((answer, index) => (
            <MDBBtn
              id={`question${props.questionNum}`}
              key={index}
              className="retailer-quiz__answer-btn ml-0"
              color={currentAnswer === answer.letter ? 'amber' : 'default'}
              onClick={(e) => this.selectAnswer(e, answer.letter, props.correctAnswer)}>
              {answer.letter + '. ' + answer.text}
            </MDBBtn>
          ))}
        </Fragment>
      </div>
    );
  }
}
