import React from 'react';
import axios from 'axios';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBAnimation} from 'mdbreact';

class CouponsAndOffers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coupons: [],
    };
    this.couponImg = React.createRef();
    this.print = this.print.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    axios.get('/client/coupons/get-coupon').then((res) => {
      const { data } = res;
      this.setState({ coupons: data }).catch((err) => console.error(err));
    });
  }
  print(e) {
    const { coupons } = this.state;
    const w = window.open();

    let html = '<!DOCTYPE HTML>';
    html += '<html lang="en-us">';
    html += '<head><style></style></head>';
    html += '<body oncontextmenu="return false;">';

    html += coupons.map(
      (coupon) => `<img className="coupon" src="${coupon.url}" width="80%"></img>`,
    );
    html += '</body>';
    w.document.write(html);
    setTimeout(() => {
      w.print();
    }, 1000);
  }

  render() {
    return (
      <>
        {/*<MDBContainer className="marginTopCoupon">
        <MDBRow className="row align-items-center justify-content-center pb-5">
          <MDBCol size="12">
            <MDBRow>
              <MDBCol>
                <h1 className="futura-bold-font text-center headerCoupon">Brain Game</h1>
                <h4 className="text-center futura-font">Can you find the baby bear among the reindeers?</h4>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
    </MDBContainer>
      
      <div className="containerCoupon text-center">
        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Coupon/bear-01.jpg'} 
        className="img-fluid" 
        width="90%"
        alt="lotus Pet Foods - Can you find the baby bear among the reindeers?"/>
      </div>

      <div className="paraContainerCoupon paraCoupon futura-font mt-5 mb-5">
        <p>Christmas is just weeks away, which means Santa Claus is busying himself with the finishing touches for his annual Dec. 25 routine. 
          The elves are wrapping up the final presents. Mrs. Claus is making sure the sleigh is ready to go. And the reindeers are ... swarming Santa? 
          In this seek-and-find puzzle, Santa's standing in a sea of reindeer, and there's a baby bear lost among them.</p>
        <br />
        <p>For this fun little brainteaser, a baby bear is hiding among all of the reindeers crowding Santa in one of the winter forests in the North Pole. 
          Be warned, though, the bear and reindeers look almost identical, sans the antlers, of course, so this seek-and-find puzzle isn't as easy as you 
          may think. See if you can find the bear below:</p>
      </div>

      <div className="containerCoupon mb-5 text-center">
        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Coupon/bear-02.jpg'} 
        className="img-fluid" 
        width="90%"
        alt="lotus Pet Foods - Can you find the baby bear among the reindeers? revealed"/>
    </div>*/}
    <MDBContainer className="marginTopCoupon">
      <MDBContainer>
        <MDBRow className="row align-items-center justify-content-center pb-5 ">
          <MDBCol size="12">
            <MDBRow>
              <MDBCol>
                <h1 className="futura-bold-font text-center headerCoupon">In-Store Coupons</h1>
                <div className="page-divider mb-3"></div>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBRow center>
            {this.state.coupons.map((coupon, i) => (
              <MDBCol size="8" key={i} className="my-3">
                <img
                  className="img-fluid"
                  src={coupon.url}
                  alt={coupon.name}
                  ref={coupon.url}
                  id={coupon._id}
                />
              </MDBCol>
            ))}
          </MDBRow>
          <MDBCol className="mt-2 d-flex align-items-center justify-content-center">
            <MDBBtn
              color="dark"
              type="submit"
              className="submit-contact"
              id="print-coupon"
              onClick={this.print}>
              Print
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      </MDBContainer>
      </>
    );
  }
}

export default CouponsAndOffers;
