import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBAnimation } from 'mdbreact';

export default function QuizResults(props) {
  const { results, taken } = props.location.state;
  const { quizName } = props.match.params;
  const notifyTakenQuiz = (taken, results) => {
    if (taken) {
      return (
        <MDBCol size="12" className="text-center">
          <h2 className="retailer-quiz__header2--taken-quiz futura-font">
            You will not earn any points for this quiz since you already have taken it.
          </h2>
        </MDBCol>
      );
    }
    return (
      <MDBCol size="12" className="text-center">
        <h2 className="retailer-quiz__header2--taken-quiz futura-font">
          You have earned {results} points towards your rewards.
        </h2>
      </MDBCol>
    );
  };
  return (
    <MDBContainer className="margin-top-adjustment quiz-completion">
      <MDBAnimation type="fadeIn" duration="500">
        <MDBRow center>
          <MDBCol size="12">
            <h1 className="retailer-quiz__header1 brandon-font text-uppercase text-center">
              {quizName} Quiz Results:
            </h1>
            <div className="page-divider mb-3"></div>
          </MDBCol>
          <MDBAnimation type="fadeIn" duration="800ms" delay="1s">
            {notifyTakenQuiz(taken, results)}
          </MDBAnimation>
          <MDBCol size="12" className="text-center my-2">
            <p className="retailer-quiz__result-num futura-font">
              <CountUp start={0} end={results} duration={5} delay={1} /> / 5
            </p>
          </MDBCol>
          <MDBCol size="8" className="text-center mt-4">
            <Fragment>
              <Link to="/retail-training/reward-redemption">
                <MDBBtn color="dark">Reedem Points</MDBBtn>
              </Link>
              <Link to="/retail-training">
                <MDBBtn color="warning">Take Another Quiz</MDBBtn>
              </Link>
            </Fragment>
          </MDBCol>
        </MDBRow>
      </MDBAnimation>
    </MDBContainer>
  );
}
