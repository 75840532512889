const loadImage = require('blueimp-load-image');

const resetOrientation = (srcBase64, srcOrientation, callback) => {
  const img = new Image();
  img.onload = () => {
    const { width, height } = img;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // set proper canvas dimensions before transform & export
    if (srcOrientation > 4 && srcOrientation < 9) {
      canvas.width = height;
      canvas.height = width - 100;
    } else {
      canvas.width = width;
      canvas.height = height;
    }

    // transform context before drawing image
    switch (srcOrientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, width, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, width, height);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, height);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, height, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, height, width);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, width);
        break;
      default:
        break;
    }

    // draw image
    ctx.drawImage(img, 0, 0);

    // export base64
    callback(canvas.toDataURL());
  };

  img.src = srcBase64;
};

const getOrientation = (file, callback) => {
  var reader = new FileReader();

  reader.onload = function(event) {
    var view = new DataView(event.target.result);

    if (view.getUint16(0, false) != 0xffd8) return callback(-2);

    var length = view.byteLength,
      offset = 2;

    while (offset < length) {
      var marker = view.getUint16(offset, false);
      offset += 2;

      if (marker == 0xffe1) {
        if (view.getUint32((offset += 2), false) != 0x45786966) {
          return callback(-1);
        }
        var little = view.getUint16((offset += 6), false) == 0x4949;
        offset += view.getUint32(offset + 4, little);
        var tags = view.getUint16(offset, little);
        offset += 2;

        for (var i = 0; i < tags; i++)
          if (view.getUint16(offset + i * 12, little) == 0x0112)
            return callback(view.getUint16(offset + i * 12 + 8, little));
      } else if ((marker & 0xff00) != 0xff00) break;
      else offset += view.getUint16(offset, false);
    }
    return callback(-1);
  };

  reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
};

/**
 * @param {String} s3Imgurl
 * @returns {String} Returns correct oriented blob info
 */
const correctImgOrientation = async (s3Imgurl) => {
  return new Promise((resolve, reject) => {
    try {
      loadImage(
        s3Imgurl,
        (canvas) => {
          resolve(canvas.toDataURL());
        },
        { orientation: true },
      );
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
};

module.exports = { resetOrientation, getOrientation, correctImgOrientation };
