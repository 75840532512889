import React from 'react';
import axios from 'axios';
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import swal from 'sweetalert2';
import Fuse from 'fuse.js';

import UserAccForms from '../ClientInteraction/UserAccForms/UserAccForms.jsx';

/* Update Search items */
const searchItems = [
  { title: 'Dog - Regular Bites', link: '/dog-food/regular-bites' },
  { title: 'Dog - Small Bites', link: '/dog/small-bites' },
  { title: 'Dog - Treats', link: '/dog-food/treats' },
  { title: 'Dog - Stews', link: '/dog-food/stews' },
  { title: 'Dog - Loafs', link: '/dog-food/loafs' },
  { title: 'Dog - Just Juicy', link: '/dog-food/just-juicy' },
  { title: 'Cat - Kibble', link: '/cat-food/kibble' },
  { title: 'Cat - Pâtés', link: '/cat-food/pates' },
  { title: 'Cat - Just Juicy', link: '/cat-food/just-juicy' },
  { title: 'Dog - Regular Bites Chicken Adult', link: '/dog-food/regular-bites/chicken-adult' },
  { title: 'Dog - Regular Bites Chicken Puppy', link: '/dog-food/regular-bites/chicken-puppy' },
  { title: 'Dog - Regular Bites Chicken Senior', link: '/dog-food/regular-bites/chicken-senior' },
  { title: 'Dog - Regular Bites Duck', link: '/dog-food/regular-bites/duck' },
  { title: 'Dog - Regular Bites Lamb', link: '/dog-food/regular-bites/lamb' },
  { title: 'Dog - Regular Bites Sardine', link: '/dog-food/regular-bites/sardine' },
  { title: 'Dog - Regular Bites Turkey', link: '/dog-food/regular-bites/turkey' },
  { title: 'Dog - Small Bites Chicken Adult', link: '/dog/small-bites/chicken-adult' },
  { title: 'Dog - Small Bites Chicken Puppy', link: '/dog/small-bites/chicken-puppy' },
  { title: 'Dog - Small Bites Chicken Senior', link: '/dog/small-bites/chicken-senior' },
  { title: 'Dog - Small Bites Duck', link: '/dog/small-bites/duck' },
  { title: 'Dog - Small Bites Lamb', link: '/dog/small-bites/lamb' },
  { title: 'Dog - Small Bites Sardine', link: '/dog/small-bites/sardine' },
  { title: 'Dog - Small Bites Turkey', link: '/dog/small-bites/turkey' },
  { title: 'Dog - Chicken Treats', link: '/dog-food/treats/chicken' },
  { title: 'Dog - Duck Treats', link: '/dog-food/treats/duck' },
  { title: 'Dog - Lamb Treats', link: '/dog-food/treats/lamb' },
  { title: 'Dog - Sardine Treats', link: '/dog-food/treats/sardine' },
  { title: 'Dog - Turkey Treats', link: '/dog-food/treats/turkey' },
  { title: 'Dog - Venison Treats', link: '/dog-food/treats/venison' },
  { title: 'Dog - Chicken Stew', link: '/dog-food/stews/chicken' },
  { title: 'Dog - Pork Stew', link: '/dog-food/stews/pork' },
  { title: 'Dog - Turkey Stew', link: '/dog-food/stews/turkey' },
  { title: 'Dog - Venison Stew', link: '/dog-food/stews/venison' },
  { title: 'Dog - Beef Stew', link: '/dog-food/stews/beef' },
  { title: 'Dog - Chicken Loaf', link: '/dog-food/loafs/chicken' },
  { title: 'Dog - Pork Loaf', link: '/dog-food/loafs/pork' },
  { title: 'Dog - Turkey Loaf', link: '/dog-food/loafs/turkey' },
  { title: 'Dog - Beef Loaf', link: '/dog-food/loafs/beef' },
  { title: 'Dog - Duck Loaf', link: '/dog-food/loafs/duck' },
  { title: 'Dog - Rabbit Loaf', link: '/dog-food/loafs/rabbit' },
  { title: 'Dog - Salmon Loaf', link: '/dog-food/loafs/salmon' },
  { title: 'Dog - Sardine Loaf', link: '/dog-food/loafs/sardine' },
  { title: 'Dog - Tripe Loaf', link: '/dog-food/loafs/green-tripe' },
  { title: 'Dog - Beef Just Juicy', link: '/dog-food/just-juicy/beef' },
  { title: 'Dog - Pork Just Juicy', link: '/dog-food/just-juicy/pork' },
  { title: 'Cat - Chicken Kibble', link: '/cat-food/kibble/chicken' },
  { title: 'Cat - Sardine Kibble', link: '/cat-food/kibble/sardine' },
  { title: 'Cat - Duck Kibble', link: '/cat-food/kibble/duck' },
  { title: 'Cat - Low Fat Kibble', link: '/cat-food/kibble/low-fat-chicken' },
  { title: 'Cat - Chicken Pâté', link: '/cat-food/pates/chicken' },
  { title: 'Cat - Pork Pâté', link: '/cat-food/pates/pork' },
  { title: 'Cat - Turkey Pâté', link: '/cat-food/pates/turkey' },
  { title: 'Cat - Duck Pâté', link: '/cat-food/pates/duck' },
  { title: 'Cat - Rabbit Pâté', link: '/cat-food/pates/rabbit' },
  { title: 'Cat - Salmon Pâté', link: '/cat-food/pates/salmon' },
  { title: 'Cat - Sardine Pâté', link: '/cat-food/pates/sardine' },
  { title: 'Cat - Chicken Just Juicy', link: '/cat-food/just-juicy/chicken' },
  { title: 'Cat - Pork Just Juicy', link: '/cat-food/just-juicy/pork' },
  { title: 'Cat - Turkey Just Juicy', link: '/cat-food/just-juicy/turkey' },
  { title: 'Cat - Pollock Just Juicy', link: '/cat-food/just-juicy/pollock' },
  { title: 'Cat - Venison Just Juicy', link: '/cat-food/just-juicy/venison' },
  { title: 'Cat - Salmon Just Juicy', link: '/cat-food/just-juicy/salmon' },
  { title: 'Store Locator', link: '/store-locator' },
  { title: 'Dog Food', link: '/dog-food' },
  { title: 'Cat Food', link: '/cat-food' },
  { title: 'About Us', link: '/about-us' },
  { title: 'Subscribe', link: '/subscribe' },
  { title: 'Ingredients', link: '/ingredients' },
  { title: 'FAQ', link: '/faq' },
  { title: 'Privacy Policy', link: '/privacy-policy' },
  { title: 'Terms and Conditions', link: '/terms-and-conditions' },
  { title: 'Newsletter', link: '/newsletter' },
  { title: 'Site Map', link: '/site-map' },
  { title: 'Shop', link: '/shop' },
  { title: 'Shop - Shirts', link: '/shop/shirts' },
  { title: 'Shop - Accessories', link: '/shop/accessories' },
  { title: 'Cart', link: '/cart' },
  { title: 'Contact Us', link: '/contact-us' },
  { title: 'Oven Baking', link: '/oven-baking' },
  { title: 'Media', link: '/media' },
];

const fuseOptions = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: ['title'],
};

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopUp: false,
      isDesktop: false,
      selectedOption: null,
      cartSize: 0,
      query: '',
      searchResults: [],
      signInModal: false,
    };
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener('resize', this.updatePredicate);

    /* Wait what the?? Would this constantly fire? */
    setInterval(() => {
      if (localStorage.cart !== undefined) {
        let cartSize = 0;
        const cart = JSON.parse(localStorage.cart);

        for (let i = 0; i < cart.length; i++) {
          cartSize += parseInt(cart[i].quantity);
        }

        this.setState({
          cartSize,
        });
      }
    }, 100);

    window.fbAsyncInit = function() {
      FB.init({
        appId: '1030022257187734',
        cookie: true,
        xfbml: true,
        version: 'v3.2',
      });
    }.bind(this);

    ((d, s, id) => {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
  }

  updatePredicate = () => {
    this.setState({ isDesktop: window.innerWidth < 1200 });
  };

  selectDog = (e) => {
    $('.cat-food').addClass('not-selected');
    $('.dog-food').removeClass('not-selected');
  };

  selectCat = (e) => {
    $('.dog-food').addClass('not-selected');
    $('.cat-food').removeClass('not-selected');
  };

  resetSelect = () => {
    $('.dog-food, .cat-food').removeClass('not-selected');
  };

  hideMenu = () => {
    $('#navbarSupportedContent').removeClass('show');
  };

  signOut = () => {
    const history = this.props;

    // Esentially useless function
    // Not doing anything currently...
    const forceMyOwnLogout = (response) => {
      cookie.remove('MyGoogleID', { path: '/' });
      if (window.gapi) {
        const auth2 = window.gapi.auth2.getAuthInstance();
        if (auth2 != null) {
          auth2.signOut().then(auth2.disconnect().then(this.props.onLogoutSuccess));
        }
      }
      this.forceUpdate();
    };

    const removeUser = (localStorage) => {
      Object.keys(localStorage).forEach((key) => {
        localStorage.removeItem(key);
      });
    };

    swal
      .fire({
        title: 'Logging out',
        text: 'Are you sure you want to logout?',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Logout',
        cancelButtonText: 'Stay Signed In',
      })
      .then((result) => {
        if (result.value) {
          removeUser(localStorage);
          this.setState(this.state);
          window.location.reload();
        }
      });
  };

  hideSearch = () => {
    $('.search, .search-list').addClass('hidesearchbar');
    $('#search').val('');
    this.setState({ query: '' });
  };

  searchLink = () => {
    $('.search, .search-list').addClass('hiddensearch');
    $('#search').val('');
    this.setState({ query: '' });
  };

  showSearch = () => {
    $('.search')
      .removeClass('hidesearchbar')
      .removeClass('hiddensearch')
      .addClass('searchbar');

    $('.search-list')
      .removeClass('hidesearchbar')
      .removeClass('hiddensearch')
      .addClass('search-res-slide-in');
  };

  onChange = (e) => {
    const { target = {} } = e;
    const { value = '' } = target;
    this.setState({ query: value });
  };

  toggleSignInModal = () => {
    this.setState({
      signInModal: !this.state.signInModal,
    });
  };

  /* modal render Component */
  signInModal = () => {
    const { history } = this.props;
    return (
      <MDBModal
        modalStyle="warning"
        toggle={this.toggleSignInModal}
        centered
        size="lg"
        isOpen={this.state.signInModal}>
        <MDBModalHeader
          tag="p"
          className="futura-font text-dark font-weight-light"
          toggle={this.toggleSignInModal}>
          Users Account
        </MDBModalHeader>
        <MDBModalBody className="text-dark p-0">
          <UserAccForms toggleSignInModal={this.toggleSignInModal} history={history} />
        </MDBModalBody>
      </MDBModal>
    );
  };

  render() {
    const fuse = new Fuse(searchItems, fuseOptions);
    const data = this.state.query ? fuse.search(this.state.query) : this.state.searchResults;
    const results = data.slice(0, 9);
    const Result = ({ result }) => {
      const { content } = result;
      return (
        <Link to={result.link} href={result.link}>
          <li className="list-group-item search-list-item" onClick={this.searchLink}>
            {result.title} 
          </li>
        </Link>
      );
    };
    const currentUser =
      localStorage.length > 0
        ? JSON.parse(localStorage.getItem('admin')) || JSON.parse(localStorage.getItem('user'))
        : '';
    const searchResultsJsx = results.map((x, i) => <Result key={i} result={x} />);
    return (
      <div>
        {this.state.showPopUp ? <PopUp /> : null}
        <nav className="navbar navbar-expand-xl fixed-top fixed-top-two">
          <div onMouseLeave={this.hideSearch}>
            <input
              type="text"
              id="search"
              className="hiddensearch search"
              placeholder="Search..."
              autoComplete="off"
              onChange={this.onChange}
            />
            <ul className="list-group search-list list-group-flush">{searchResultsJsx}</ul>
          </div>
          <Link to="/" href="/">
            <div className="navbar-brand">
              <img
                className="top-logo top-logo-two"
                src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Nav+Bar/Lotuspetfoods-logo.png'}
              />
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <i className="fas fa-bars" style={{ color: 'white' }} />
          </button>
          {this.state.isDesktop ? (
            <div className="navbar-collapse collapse" id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li className="nav-item my-2">
                  <Link to="/" href="/" className="burger-link" onClick={this.hideMenu}>
                    Home
                  </Link>
                </li>
                <li className="nav-item my-2">
                  <Link
                    to="/dog-food"
                    href="/dog-food"
                    className="burger-link"
                    onClick={this.hideMenu}>
                    Dog Food
                  </Link>
                </li> 
                <li className="nav-item my-2">
                  <Link
                    to="/cat-food"
                    href="/cat-food"
                    className="burger-link"
                    onClick={this.hideMenu}>
                    Cat Food
                  </Link>
                </li>
                <li className="nav-item my-2">
                  <Link
                    to="/store-locator"
                    href="/store-locator"
                    className="burger-link"
                    onClick={this.hideMenu}>
                    Store Locator
                  </Link>
                </li>
                
                <li className="nav-item my-2">
                  <Link
                    to="/about-us"
                    href="/about-us"
                    className="burger-link"
                    onClick={this.hideMenu}>
                    About Us
                  </Link>
                </li>

                <li className="nav-item my-2">
                  <Link
                    to="/oven-baked"
                    href="/oven-baked"
                    className="burger-link"
                    onClick={this.hideMenu}>
                    Why Baked
                  </Link>
                </li>

                {/*<li className="nav-item my-2">
                  <Link to="/shop" href="/shop" className="burger-link" onClick={this.hideMenu}>
                    Shop
                  </Link>
                </li>*/}

                <li className="nav-item my-2">
                  <Link
                    to="/ingredients"
                    href="/ingredients"
                    className="burger-link"
                    onClick={this.hideMenu}>
                    Ingredients
                  </Link>
                </li>
                <li className="nav-item my-2">
                  <Link
                    to="/blog"
                    href="/blog"
                    className="burger-link"
                    onClick={this.hideMenu}>
                    Blog
                  </Link>
                </li>
                <li className="nav-item my-2">
                  <Link
                    to="/blog-video"
                    href="/blog-video"
                    className="burger-link"
                    onClick={this.hideMenu}>
                    Videos
                  </Link>
                </li>
                <li className="nav-item my-2">
                  <Link
                    to="/contact-us"
                    href="/contact-us"
                    className="burger-link"
                    onClick={this.hideMenu}>
                    Contact Us
                  </Link>
                </li>

                <li className="nav-item">
                <Link to="/cart" href="/cart">
                  <i className="nav-icon nav-icon-two fas fa-shopping-cart" />
                  <span className="cart-size">{this.state.cartSize}</span>
                </Link>
                </li>
                
                <li className="nav-item mx-0 my-0">
                  {currentUser ? (
                    <div
                      tabIndex="0"
                      className="nav-link nav-link-two nav-bar-link mx-0 my-0 px-0 navbar--sign-in-font-size">
                      Hello, {currentUser.first_name} {currentUser.last_name} /{' '}
                      <a className="sign-out" onClick={this.signOut}>
                        Sign Out
                      </a>
                    </div>
                  ) : (
                    <div
                      tabIndex="0"
                      className="nav-item mx-0 mx-0 navbar--sign-in-font-size"
                      style={{ color: 'white' }}>
                      <button
                        onClick={this.toggleSignInModal}
                        className="sign-up sign-up-two navbar__sign-in-btn pl-0 font-weight-light">
                        Sign In / Sign Up
                      </button>{' '}
                    </div>
                  )}
                </li>
              </ul>
            </div>
          ) : null}
          {/*<div className="navbar-collapse collapse w-60">
            <ul className="navbar-nav mr-auto d-flex align-items-end text-center">
              <li className="nav-item dropdown">
                <a
                  className="nav-link nav-bar-link"
                  id="products"
                  href="/products"
                  data-toggle="dropdown">
                  Products
                </a>
                <div className="dropdown-menu dropdown-menu-nav product-drop">
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol className="navbar__header-img">
                        <img
                          className="img-fluid"
                          src={
                            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/product-header.png'
                          }
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol size="6" className="navbar__img-wrapper">
                        <Link
                          className="navbar__img-link"
                          to="/dog-food"
                          onMouseEnter={this.selectDog}
                          onMouseLeave={this.resetSelect}
                          role="button">
                          <img
                            className="img-fluid"
                            src={
                              'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/dog-option.png'
                            }
                          />
                        </Link>
                      </MDBCol>
                      <MDBCol
                        size="6"
                        className="navbar__img-wrapper navbar__img-wrapper--left-adjustment">
                        <Link
                          className="navbar__img-link"
                          to="/cat-food"
                          onMouseEnter={this.selectCat}
                          onMouseLeave={this.resetSelect}
                          role="button">
                          <img
                            className="img-fluid"
                            src={
                              'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/cat-option.png'
                            }
                          />
                        </Link>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link nav-bar-link"
                  id="store-locator"
                  data-toggle="dropdown"
                  href="/store-locator">
                  Store Locator
                </a>
                <div className="dropdown-menu dropdown-menu-nav" id="store-drop">
                  <div className="container">
                    <div className="row store-drop-row">
                      <div className="col">
                        <Link to="/store-locator" href="/store-locator">
                          <img
                            src={
                              'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/store-locator-option.png'
                            }
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link nav-bar-link"
                  id="about-us"
                  data-toggle="dropdown"
                  href="/about-us">
                  About Us
                </a>
                <div className="dropdown-menu dropdown-menu-nav product-drop">
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol className="navbar__header-img">
                        <img
                          className="img-fluid"
                          src={
                            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/about-us-bar.png'
                          }
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol size="6" className="navbar__img-wrapper">
                        <Link
                          className="navbar__img-link"
                          to="/about-us"
                          href="/about-us"
                          onMouseEnter={this.selectDog}
                          onMouseLeave={this.resetSelect}>
                          <img
                            className="img-fluid"
                            src={
                              'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/about-us-option1.png'
                            }
                          />
                        </Link>
                      </MDBCol>
                      <MDBCol
                        size="6"
                        className="navbar__img-wrapper navbar__img-wrapper--left-adjustment">
                        <Link
                          className="navbar__img-link"
                          to="/oven-baked"
                          href="/oven-baked"
                          onMouseEnter={this.selectCat}
                          onMouseLeave={this.resetSelect}>
                          <img
                            className="img-fluid navbar--white-bg"
                            src={
                              'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/about-us-option2.png'
                            }
                          />
                        </Link>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link nav-bar-link" id="store" data-toggle="dropdown" href="/shop">
                  Shop
                </a>
                <div className="dropdown-menu dropdown-menu-nav product-drop">
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol className="navbar__header-img">
                        <img
                          className="img-fluid"
                          src={
                            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/store-bar.png'
                          }
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol size="6" className="navbar__img-wrapper">
                        <Link
                          className="navbar__img-link"
                          to="/shop/accessories/shirts"
                          href="/shop/shirts"
                          onMouseEnter={this.selectDog}
                          onMouseLeave={this.resetSelect}>
                          <img
                            className="img-fluid"
                            src={
                              'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/shop-option1.png'
                            }
                          />
                        </Link>
                      </MDBCol>
                      <MDBCol
                        size="6"
                        className="navbar__img-wrapper navbar__img-wrapper--left-adjustment">
                        <Link
                          className="navbar__img-link"
                          to="/shop/accessories"
                          href="/shop/accessories"
                          onMouseEnter={this.selectCat}
                          onMouseLeave={this.resetSelect}>
                          <img
                            className="img-fluid"
                            src={
                              'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/shop-option2.png'
                            }
                          />
                        </Link>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link nav-bar-link"
                  id="nav-ingredients"
                  data-toggle="dropdown"
                  href="/ingredients">
                  Ingredients
                </a>
                <div className="dropdown-menu dropdown-menu-nav" id="nav-ingredients-drop">
                  <div className="container">
                    <div className="row nav-ingredients-row">
                      <div className="col">
                        <Link to="/ingredients" href="/ingredients">
                          <img
                            src={
                              'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/ingredients.png'
                            }
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link nav-bar-link"
                  id="contact-us"
                  data-toggle="dropdown"
                  href="/contact-us">
                  Contact Us
                </a>
                <div className="dropdown-menu dropdown-menu-nav" id="contact-us-drop">
                  <div className="container">
                    <div className="row contact-us-row">
                      <div className="col">
                        <Link to="/contact-us" href="/contact-us">
                          <img
                            src={
                              'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/contact-us.png'
                            }
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>*/} 
          <div className="navbar-collapse collapse">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item"> 
                <Link to="/cart" href="/cart">
                  <i className="nav-icon nav-icon-two fas fa-shopping-cart" />
                  <span className="cart-size">{this.state.cartSize}</span>
                </Link>
              </li>
              <li className="nav-item">
                <i className="nav-icon nav-icon-two nav-search fas fa-search" onClick={this.showSearch} />
              </li>
              <li className="nav-item">
                {currentUser ? (
                  <div tabIndex="0" className="nav-link nav-link-two nav-bar-link">
                    Hello, {currentUser.first_name} {currentUser.last_name} /{' '}
                    <a className="sign-out" onClick={this.signOut}>
                      Sign Out
                    </a>
                  </div>
                ) : (
                  <div tabIndex="0" className="nav-link nav-link-two nav-bar-link">
                    <button
                      onClick={this.toggleSignInModal}
                      className="sign-up sign-up-two navbar__sign-in-btn font-weight-light">
                      Sign In / Sign Up
                    </button>
                  </div>
                )}
              </li>
            </ul>
            </div>
        </nav>
        {this.signInModal()}
      </div>
    );
  }
}

export default NavBar;
