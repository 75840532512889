import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';

export default function Thumbs(props) {
  return (
    <MDBCol className="shop-item-main__thumbs">
      {props.images.length > 0 &&
        props.images.map((img, i) => (
          <div
            key={i}
            className={`shop-item-main__thumbs-wrapper my-2 ${
              props.currentThumb === `${i}` ? 'thumbs--active' : ''
            }`}>
            <img
              onClick={props.openLightBox}
              onMouseEnter={props.changeCurrentPicture}
              className="img-fluid"
              src={img.thumb}
              data-imgindex={i}
              data-mainsrc={img.main}
              data-zoomsrc={img.zoom}
            />
          </div>
        ))}
    </MDBCol>
  );
}
