import React from 'react';
import { Link } from 'react-router-dom';
import { MDBInputGroup, MDBBtn, MDBCol, MDBInput } from 'mdbreact';
import NewsLetterSubFooter from '@Containers/NewsletterSub/newsLetterSubFooter';


export default function Footer(props) {
  const backToTopRef = React.createRef();

  function scrollFunction() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      document.getElementById('back-to-top').style.display = 'block';
    } else {
      document.getElementById('back-to-top').style.display = 'none';
    }
  }
  window.onscroll = () => {
    scrollFunction();
  };

  function backToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <div>
      <div id="back-to-top" onClick={backToTop} ref={backToTopRef}>
        Back to Top
      </div>
      <footer className="page-footer footer mt-auto">
        <div className="container footer-container">
          <div className="row">

              <div className="col-6 col-md-4 col-lg-2"> {/*footer-link-col */}
                <ul className="list-unstyled">
                <li class="link-margin">
                  <Link to="/about-us" href="/about-us" className="footer-link">
                    About Us
                  </Link>
                </li>
                <li class="link-margin">
                  <Link to="/store-locator" href="/store-locator" className="footer-link">
                    Store Locator
                  </Link>
                </li>
                <li class="link-margin">
                  <Link to="/blog" href="/blog" className="footer-link">
                    Blog
                  </Link>
                </li>
                <li class="link-margin">
                  <Link to="/oven-baked" href="/oven-baked" className="footer-link">
                    Why Baked
                  </Link>
                </li>
                <li class="link-margin">
                  <Link to="/ingredients" href="/ingredients" className="footer-link">
                    Ingredients
                  </Link>
                </li>
                <li class="link-margin">
                  <Link to="/careers" href="/careers" className="footer-link">
                    Careers
                  </Link>
                </li>
                <li class="link-margin">
                  <Link to="/contact-us" href="/contact-us" className="footer-link">
                    Contact Us
                  </Link>
                </li>
                </ul>
              </div>

          
            <div className="col-6 col-md-4 col-lg-2 mx-lg-5">
              <ul className="list-unstyled">
                <li class="link-margin">
                  <Link to="/subscribe" href="/subscribe" className="footer-link">
                    Subscribe
                  </Link>
                </li>
                <li class="link-margin">
                  <Link to="/newsletter" href="/newsletter" className="footer-link">
                    Newsletter
                  </Link>
                </li>
                <li class="link-margin">
                  <Link to="/media" href="/media" className="footer-link">
                    Media
                  </Link>
                </li>
                <li class="link-margin">
                  <Link to="/admin/main" className="footer-link">
                    Admin
                  </Link>
                </li>
                <li class="link-margin">
                  <Link to="/sales/main" className="footer-link">
                    Sales Admin
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-6 col-md-4 col-lg-2 pr-lg-1">
              <ul className="list-unstyled">
              <li class="link-margin">
                  <Link to="/retail-training" href="/retail-training" className="footer-link">
                    Retail Training
                  </Link>
                </li>
                <li class="link-margin">
                  <Link
                    to="/terms-and-conditions"
                    href="/terms-and-conditions"
                    className="footer-link">
                    Terms and Conditions
                  </Link>
                </li>
                <li class="link-margin">
                  <Link to="/privacy-policy" href="/privacy-policy" className="footer-link">
                    Privacy Policy
                  </Link>
                </li>
                <li class="link-margin">
                  <Link to="/faq" href="/faq" className="footer-link">
                    FAQ
                  </Link>
                </li>
                <li class="link-margin">
                  <Link to="/site-map" href="/site-map" className="footer-link">
                  Site Map
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-md-10 col-lg-4 join-newsletter-section ml-lg-5">
              <div className="popup__p text-left footerText">Join Our Newsletter</div>
              <div> 
              <NewsLetterSubFooter outline={false} acceptAll={true} enableBtn={true} />
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12 copyright-text-two">
                ® 2022 Lotus Pet Foods, All Rights Reserved
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}


