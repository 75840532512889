import React from 'react';
import { MDBRow, MDBCol, MDBListGroup, MDBListGroupItem } from 'mdbreact';
import { mapCorrectDataFormatAnalysis } from './helper.jsx';

import ListGroup from './ListGroup.jsx';

export default function Analysis(props) {
  const { analysis, pet } = props;
  const anaArr = mapCorrectDataFormatAnalysis(analysis);
  return (
    <div className="product-main__analysis-wrapper">
      <MDBRow>
        <MDBCol>
          <MDBRow>
            <MDBCol size="12" sm="6">
              {anaArr.map((analysisItem, i) => {
                if (
                  analysisItem.data === null ||
                  analysisItem.data === undefined ||
                  analysisItem.data === ''
                ) {
                  return (
                    <MDBListGroup className="d-none">
                      <ListGroup key={i} i={i} />
                    </MDBListGroup>
                  );
                }
                return (
                  <MDBListGroup key={i}>
                    <ListGroup i={i} name={analysisItem.name} data={analysisItem.data} />
                  </MDBListGroup>
                );
              })}
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </div>
  );
}
