import React from 'react';
import { Link } from 'react-router-dom';
import imageMapResize from 'image-map-resizer';
import { MDBCol } from 'mdbreact';

class DogFood extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dry: 'regular-bites',
      wet: 'stews',
      raw: 'raw',
      topper: 'tripe',
      clam: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      beef: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      chicken: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      duck: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      lamb: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      pollock: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      pork: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      rabbit: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      salmon: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      proteins: [
        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/rabbit.png',
        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/pork.png',
        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/chicken.png',
        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/duck.png',
        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/beef.png',
        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/green-tripe.png',
        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/venison.png',
        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/turkey.png',
        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/lamb.png',
        'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/fish.png',
      ],
      meats:['rabbit', 'pork', 'chicken', 'duck', 'beef', 'green-tripe', 'venison', 'turkey', 'lamb', 'fish' ],
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    imageMapResize();
  };

  showClam = () => {
    this.setState({
      clam: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/clam3.gif',
    });
  };

  showBeef = () => {
    this.setState({
      beef: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/beef2.gif',
    });
  };

  showChicken = () => {
    this.setState({
      chicken: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/chicken2.gif',
    });
  };

  showDuck = () => {
    this.setState({
      duck: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/duck2.gif',
    });
  };

  showLamb = () => {
    this.setState({
      lamb: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/lamb2.gif',
    });
  };

  showPollock = () => {
    this.setState({
      pollock: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/alaska-fin2.gif',
    });
  };

  showPork = () => {
    this.setState({
      pork: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/pork2.gif',
    });
  };

  showRabbit = () => {
    this.setState({
      rabbit: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/rabbit2.gif',
    });
  };

  showSalmon = () => {
    this.setState({
      salmon: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/salmon2.gif',
    });
  };

  hide = () => {
    this.setState({
      clam: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      beef: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      chicken: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      duck: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      lamb: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      pollock: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      pork: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      rabbit: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      salmon: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
    });
  };

  dryHover = (e) => {
    this.setState({
      dry: e.target.id,
    });
  };

  wetHover = (e) => {
    this.setState({
      wet: e.target.id,
    });
  };

  rawHover = (e) => {
    this.setState({
      raw: e.target.id,
    });
  };

  topperHover = (e) => {
    this.setState({
      topper: e.target.id,
    });
  };

  render() {
    return (
      <div>
        <img
          className="banner img-fluid"
          src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/dog-food-banner.png'}
        />
        <div className="container food-type my-5">
          <div className="choose-food-type">CHOOSE YOUR FOOD TYPE</div>
          <div className="row mt-1 mb-4 page-divider" />

          <div className="food-type1 dog-food-shadow">
            <div className="food-type-main">
              <div className="row justify-content-center">
                {/*Col 1*/}
                <div className="col-md-6 col-sm-12 pr-md-0 mb-3">
                  <div className="food-col">
                    <div>
                      <img
                        className="img-fluid food-bar"
                        src={
                          'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/oven-baked-bar.png'
                        }
                      />
                    </div>
                    <div className="text-center food-image-container">
                      <img
                        className="dry-image"
                        src={`https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/${this.state.dry}.jpg`}
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-12 option-col pr-lg-0">
                        <Link className="no-underline" to="/overview/dog/regular-bites">
                          <div
                            className="text-center option-link dog-regular-bites text-truncate"
                            id="regular-bites"
                            onMouseEnter={this.dryHover}>
                            Regular Bites
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-4 col-12 option-col p-lg-0">
                        <Link className="no-underline" to="/overview/dog/small-bites">
                          <div
                            className="text-center option-link dog-small-bites text-truncate"
                            id="small-bites"
                            onMouseEnter={this.dryHover}>
                            Small Bites
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-4 col-12 option-col pl-lg-0">
                        <Link className="no-underline" to="/overview/dog/treats">
                          <div
                            className="text-center option-link dog-treats"
                            id="treats"
                            onMouseEnter={this.dryHover}>
                            Treats
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/*Col 2*/}
                <div className="col-md-6 col-sm-12 pl-md-0">
                  <div className="food-col">
                    <div>
                      <img
                        className="img-fluid food-bar"
                        src={
                          'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/canned-bar.png'
                        }
                      />
                    </div>
                    <div className="text-center food-image-container">
                      <img
                        className="wet-image"
                        src={`https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Dog+Food/${this.state.wet}.jpg`}
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-12 option-col pr-lg-0">
                        <Link className="no-underline" to="/overview/dog/stew">
                          <div
                            className="text-center option-link dog-stews"
                            id="stews"
                            onMouseEnter={this.wetHover}>
                            Stews
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-4 col-12 option-col p-lg-0">
                        <Link className="no-underline" to="/overview/dog/loaf">
                          <div
                            className="text-center option-link dog-loafs"
                            id="loafs"
                            onMouseEnter={this.wetHover}>
                            Loafs
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-4 col-12 option-col pl-lg-0">
                        <Link className="no-underline" to="/overview/dog/just-juicy">
                          <div
                            className="text-center option-link dog-just-juicy text-truncate"
                            id="just-juicy"
                            onMouseEnter={this.wetHover}>
                            Just Juicy
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="food-type1 dog-food-shadow mt-2">
            <div className="food-type-main">
              <div className="row justify-content-center">
                {/*Col 1*/}
                 {/*<div className="col-md-6 col-sm-12 pr-md-0">
                  <div className="food-col">
                    <div>
                      <img
                        className="img-fluid food-bar"
                        src={
                          'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-food-tab/header_img.png'
                        }
                      />
                    </div>
                    <div className="text-center food-image-container">
                    <object data={`https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-food-tab/${this.state.raw}.jpg`} className="dry-image" type="image/jpg">
                      <img src="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-food-tab/raw.jpg"/>
                    </object>*/}
                      {/*<img
                        className="dry-image"
                        src={`https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-food-tab/${this.state.raw}.jpg`}
                      />*/}
{/*  </div>
                    <div className="row">
                      <div className="col-lg-4 col-12 option-col pr-lg-0">
                        <Link className="no-underline" to="/overview/dog/raw-foods">
                          <div
                            className="text-center option-link text-truncate dog-raw"
                            id="raw"
                            onMouseEnter={this.rawHover}>
                            <span>Raw</span>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-4 col-12 option-col p-lg-0">
                        <Link className="no-underline" to="/product-view/dog/raw-foods/variety-67">
                          <div
                            className="text-center option-link text-truncate dog-raw-variety"
                            id="variety1"
                            onMouseEnter={this.rawHover}>
                            <span>Variety Pack</span>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-4 col-12 option-col pl-lg-0">
                        <div className="text-center option-link dog-raw-empty-third" />
                      </div>
                    </div>
                  </div>
                </div>*/}
                {/*Col 2*/}
                <div className="col-md-6 col-sm-12 pl-md-0">
                  <div className="food-col">
                    <div>
                      <img
                        className="img-fluid food-bar"
                        src={
                          'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Dog+Food/raw/raw-food-tab/raw-topper-title.png'
                        }
                      />
                    </div>
                    <div className="text-center food-image-container">
                    <object data={`https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-food-tab/${this.state.topper}.jpg`} className="dry-image" type="image/jpg">
                      <img src="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-food-tab/tripe.jpg"/>
                    </object>
                      {/*<img
                        className="dry-image"
                        src={`https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-food-tab/${this.state.topper}.jpg`}
                      />*/}
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-12 option-col pr-lg-0">
                        <Link className="no-underline" to="/overview/dog/topper">
                          <div
                            className="text-center option-link text-truncate dog-tripe"
                            id="tripe"
                            onMouseEnter={this.topperHover}>
                            <span>Tripe</span>
                          </div>
                        </Link>
                      </div>
                      <div className="col-lg-4 col-12 option-col p-lg-0">
                        <div className="text-center option-link dog-tripe-empty-second" />
                      </div>
                      <div className="col-lg-4 col-12 option-col pl-lg-0">
                        <div className="text-center option-link dog-tripe-empty-third" />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="search-by-protein">SEARCH BY PROTEIN</div>
          <div className="row mt-1 mb-4 page-divider" />
          <div className="protein-container">
            <div className="row">
              {this.state.proteins.map((protein, i) => (
                <MDBCol size="4" sm="3" md="2" key={i} className="my-2">
                  <Link to={`/${this.state.meats[i]}`} href={`/${this.state.meats[i]}`}>
                    <img className="protein-option protein-rabbit" src={protein} />
                  </Link>
                </MDBCol>
              ))}
            </div>
          </div>
          {window.innerWidth > 768 ? (
            <div className="food-map-body text-center mb-3">
              <img
                className="img-fluid px-3"
                src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Landing+Page/meat1.png'}
              />
              <div className="map-hover-text mt-3 mb-5">
                Hover over a number to view our protein sources!
              </div>
              <div className="faq-map row text-center">
                <div className="col-12">
                  <img
                    usemap="#map"
                    className="img-fluid"
                    src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/map.png'}
                  />
                  <map name="map">
                    <area
                      onMouseEnter={this.showClam}
                      onMouseLeave={this.hide}
                      coords="294,517,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showBeef}
                      onMouseLeave={this.hide}
                      coords="295,445,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showChicken}
                      onMouseLeave={this.hide}
                      coords="468,438,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showDuck}
                      onMouseLeave={this.hide}
                      coords="502,491,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showPollock}
                      onMouseLeave={this.hide}
                      coords="226,187,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showRabbit}
                      onMouseLeave={this.hide}
                      coords="1035,463,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showPork}
                      onMouseLeave={this.hide}
                      coords="449,483,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showLamb}
                      onMouseLeave={this.hide}
                      coords="1945,1076,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showSalmon}
                      onMouseLeave={this.hide}
                      coords="412,318,18"
                      shape="circle"
                    />
                  </map>
                  <img className="map-gif map-clam img-fluid" src={this.state.clam} />
                  <img className="map-gif map-beef img-fluid" src={this.state.beef} />
                  <img className="map-gif map-chicken img-fluid" src={this.state.chicken} />
                  <img className="map-gif map-duck img-fluid" src={this.state.duck} />
                  <img className="map-gif map-pollock img-fluid" src={this.state.pollock} />
                  <img className="map-gif map-rabbit img-fluid" src={this.state.rabbit} />
                  <img className="map-gif map-pork img-fluid" src={this.state.pork} />
                  <img className="map-gif map-lamb img-fluid" src={this.state.lamb} />
                  <img className="map-gif map-salmon img-fluid" src={this.state.salmon} />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default DogFood;
