import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';

const { getIngredientIcons } = require('./helper');

const renderIcons = (icons) => {
  if (icons.canada !== undefined) {
    return (
      <MDBCol
        size="12"
        sm="3"
        md="2"
        className="product-main__ingredient-icon--position-adjustment d-flex justify-content-center justify-content-sm-start">
        <img
          className="img-fluid product-main__ingredient-icon product-main__ingredient-icon--icon-size-adjustment"
          src={icons.canada}
        />
      </MDBCol>
    );
  }
  if (icons.rawIcon !== undefined) {
    return (
      <MDBCol
        size="12"
        sm="4"
        md="3"
        className="product-main__ingredient-icon--position-adjustment d-flex justify-content-center justify-content-sm-start">
        <img
          className="img-fluid product-main__ingredient-icon product-main__ingredient-icon--raw-icon-size-adjustment"
          src={icons.rawIcon}
        />
      </MDBCol>
    );
  }
  return <MDBCol className="d-none"></MDBCol>;
};

export default function Ingredients(props) {
  const icons = getIngredientIcons(props.foodType, props.petType);
  return (
    <div className="product-main__ingredient-wrapper">
      <MDBRow>
        <MDBCol size="12">
          <p className="product-main__text">{props.ingredient.join(', ')}</p>
        </MDBCol>
        <MDBCol size="12" className="mt-2">
          <MDBRow>
            {renderIcons(icons)}
            <MDBCol
              size="12"
              sm="4"
              md="3"
              className="product-main__ingredient-icon--position-adjustment d-flex justify-content-center justify-content-sm-start">
              <img
                className="img-fluid product-main__ingredient-icon--pet-icon-size-adjustment"
                src={icons.petIcon}
              />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </div>
  );
}
