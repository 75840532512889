import React from 'react';
import { MDBRow, MDBCol, MDBProgress } from 'mdbreact';

export default function ReviewBreakdown(props) {
  return (
    <div className="product-main__review-breakdowns">
      <MDBRow>
        <MDBCol size="12" sm="10">
          {props.reviewsBreakdown.map((breakdown, i) => {
            return (
              <MDBRow key={i}>
                <MDBCol className="mx-0" size="3">
                  <p className="product-main__text text-dark futura-font">
                    {breakdown.rating} Star
                  </p>
                </MDBCol>
                <MDBCol className="mx-0" size="7">
                  <MDBProgress color="warning" value={breakdown.averageReviews} />
                </MDBCol>
                <MDBCol className="mx-0" size="2">
                  <p className="product-main__text text-dark futura-font">
                    {breakdown.averageReviews > 0 && breakdown.averageReviews !== Infinity
                      ? breakdown.averageReviews
                      : 0}
                    %
                  </p>
                </MDBCol>
              </MDBRow>
            );
          })}
        </MDBCol>
      </MDBRow>
    </div>
  );
}
