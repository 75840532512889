const swal = require('sweetalert2');
const { setUserLocalStorageInfo } = require('../../_helper/index.jsx');

const userLogin = (e, axios, state, props) => {
  const { email, password } = state;
  const { history } = props;
  e.preventDefault();
  axios
    .post('/user/login', { query: { email, password } })
    .then((userData) => {
      const getUserType = () => {
        if (email.includes('admin@lotus')) {
          return 'admin';
        }
        if (email.includes('sales@lotus')) {
          return 'admin';
        }
        return 'user';
      };
      const userType = getUserType();
      setUserLocalStorageInfo(userType, userData.data);
      if (
        userType === 'admin' &&
        JSON.parse(localStorage.getItem('admin')).email.includes('sales@lotus') &&
        JSON.parse(localStorage.getItem('admin') !== null)
      ) {
        history.push('/sales/main');
        window.location.reload();
      } else if (userType === 'admin') {
        history.push('/admin/main');
        window.location.reload();
      } else {
        window.location.reload();
      }
    })
    .catch((err) => {
      console.error(err);
      swal.fire({
        title: 'Error',
        text: 'Incorrect email or password',
        icon: 'error',
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    });
};

module.exports = { userLogin };
