import React from 'react';
import { MDBCol } from 'mdbreact';
import NewsLetterSubFooter from '@Containers/NewsletterSub/newsLetterSubFooter';
const { randomString } = require('../_helper/index.jsx');

export default function Subscribe(props) {
  return (
    <div className="container contact-us-body">
      
      <MDBCol size="12">
      <div className="row">
        <div className="col contact-us-text">SUBSCRIBE</div>
      </div>
      <div className="row page-divider" />
        <div className="popup__p text-left">JOIN OUR NEWSLETTER AND</div>
        <p className="text-uppercase font-weight-bold popup__sub-title text-left mt-2 mb-3">
          get $5 off your next purchase
        </p>
        <p className="popup__p popup__p--margin-bottom text-left">
          Monthly deals, new product info & tips for keeping your pet happy and healthy.
        </p>
        <NewsLetterSubFooter acceptAll={true} enableBtn={true} />
      </MDBCol>
    </div>
  );
}
