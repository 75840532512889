import React from 'react';
import {
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert,
  MDBAnimation,
} from 'mdbreact';
import { Link } from 'react-router-dom';

import UserAccForms from '../../../ClientInteraction/UserAccForms/UserAccForms.jsx';
import Rating from './Rating.jsx';
import ReviewBreakdown from './ReviewBreakdown.jsx';
import CustomerReview from './CustomerReview.jsx';

const { lightOrDarkBg } = require('../../helper');
const { countNumberOfStars, reviewBreakdown, countNumbersOfApprovedReviews } = require('./helper');

export default function ProductReviews(props) {
  /* modal render Component */
  const user = localStorage.getItem('user');
  const { history, toggleSignInModal, signInModal, color } = props;

  const changeReviewImgIconColor = (color) => {
    if (lightOrDarkBg(color) === 'dark') {
      return (
        <img
          className="product-main__review-icon img-fluid"
          src="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/review+white-01.png"
        />
      );
    }
    return (
      <img
        className="product-main__review-icon img-fluid"
        src="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/review+blk-01.png"
      />
    );
  };
  const renderReviewBtn = () => {
    return (
      <MDBCol size="12" sm="4" md="3" className="d-flex justify-content-evenly align-items-center">
        <Link
          className={`d-flex justify-content-evenly align-items-center ${
            lightOrDarkBg(color) === 'dark' ? 'white-text' : 'text-dark'
          }`}
          to={user !== null ? `/review/${props.id}/${props.name}` : '#'}>
          <button
            onClick={user === null ? props.toggleSignInModal : ''}
            className="product-main__review-btn product-main--no-highlight btn"
            style={{ backgroundColor: props.color }}
            title={user !== null ? 'Write a review' : 'You must be logged in to write a review.'}>
            <div
              className={`d-flex justify-content-evenly align-items-center ${
                lightOrDarkBg(color) === 'dark' ? 'white-text' : 'text-dark'
              }`}>
              {changeReviewImgIconColor(color)}
              <p className="product-review-button__p-text futura-font font-weight-bolder text-capitalize ml-3">
                Write a Review
              </p>
            </div>
          </button>
        </Link>
      </MDBCol>
    );
  };

  return (
    <div className="product-main__product-reviews-wrapper">
      <MDBAnimation type="fadeIn">
        <MDBRow>
          <MDBCol size="12" sm="6">
            <h3 className="product-main__header3 dk-font">What customers are saying</h3>
            <div
              className="product-main__header3-underline"
              style={{ backgroundColor: props.color }}></div>
          </MDBCol>
          {renderReviewBtn()}
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <MDBRow>
              <MDBCol size="8" sm="6">
                <Rating
                  starsRating={countNumberOfStars(props.reviews)}
                  numsOfRating={countNumbersOfApprovedReviews(props.reviews)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="12" sm="6">
                <ReviewBreakdown reviewsBreakdown={reviewBreakdown(props.reviews)} />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <CustomerReview reviews={props.reviews} />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBAnimation>
      <MDBModal
        modalStyle="warning"
        toggle={toggleSignInModal}
        centered
        size="lg"
        isOpen={signInModal}>
        <MDBModalHeader
          tag="p"
          className="futura-font text-dark font-weight-light"
          toggle={toggleSignInModal}>
          Users Account
        </MDBModalHeader>
        <MDBModalBody className="text-dark p-0">
          <MDBAlert
            color="danger"
            dismiss
            className="product-main--review-warning text-center font-weight-bolder mb-0">
            *You must sign in or have an account to write a review.
          </MDBAlert>
          <UserAccForms history={history} />
        </MDBModalBody>
      </MDBModal>
    </div>
  );
}
