import React from 'react';
import YouTube from 'react-youtube';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact';

const helper = require('./helper');

export default function CatRawAddition(props) {
  return (
    <div className="product-overview__cat-raw-addition cat-raw-addition__bg my-3">
      <MDBContainer>
        <MDBRow>
          <MDBCol className="cat-raw-addition__video-wrapper">
            <MDBAnimation type="fadeIn" delay="1s" duration="500">
                <YouTube
                className="cat-raw-addition__youtube-vid"
                //opts={youtubeOpts}
                videoId='8iQzBwObSQU'
                />
            </MDBAnimation>
            {/*<img
              src="https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/video-placeholder.png"
              className="img-fluid"
            />*/}
          </MDBCol>
        </MDBRow>
        <MDBRow between className="my-5">
          {helper.infoText.map((info, i) => (
            <MDBCol key={i} size="12" sm="4" className="d-flex justify-content-center">
              <img
                src={info.src}
                alt={info.alt}
                className="img-fluid cat-raw-addition__info-img my-3"
              />
            </MDBCol>
          ))}
        </MDBRow>

        <MDBRow>
          <MDBCol className="cat-raw-addition__content-wrapper">
            <MDBContainer className="cat-raw-addition__container-bg">
              {/* pet wellness section */}
              <div className="cat-raw-addition__pet-wellness my-3">
                <MDBRow>
                  <MDBCol>
                    <h2 className="cat-raw-addition__header2 font-weight-bold">
                    The first step to pet wellnesss
                    </h2>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12" sm="8">
                    <p className="cat-raw-addition__p">
                    What’s better than Oven-Baked Cat food? A Raw recipe for cats without ground bone, fruits, or vegetables. 
                    That’s why we made a raw recipe for cats. With whole chicken and turkey thighs, beef chuck tenders, pork shoulder, 
                    whole cuts of lamb and venison trim and whole wild caught sardines. Lotus Raw Recipes for cats provides the best 
                    nutrition for your four-legged feline friend. 
                    </p>
                  </MDBCol>
                  {/*<MDBCol size="12" sm="5">
                    <p className="cat-raw-addition__p">
                      These are real cuts of USDA Insepcted and Passed and New Zealand Ministery
                      Inspected and Passed meats and poultry, ground and filled locally in small
                      batches by our own staff. And our cuts of meat and poultry are of course,
                      hormone, antibiotic free and vegeterian fed.
                    </p>
                    </MDBCol>*/}
                </MDBRow>
              </div>
              {/* End of pet wellness section */}

              {/* Essential Supplement section */}
              <div className="cat-raw-addition__essential-sups my-3">
                <MDBRow>
                  <MDBCol>
                    <h2 className="cat-raw-addition__header2 font-weight-bold">
                      Essential Supplements
                    </h2>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12" sm="6">
                    <p className="cat-raw-addition__p cat-raw-addition__p--essential-sups-txt-adj font-weight-lighter">
                      The recipes do not have fruits and vegetables, or ground bone so we can
                      specifically forumlate the recipes to meet the exact nutritional needs of your
                      cat. Liver and Hearts in all recipes for Taurine and Vitamin A. Psyllium Husk
                      promotes regularity and overall disgestive health. A good source of insoluble
                      fiber are from miscanthus grass. All ingredients are non-GMO, hormone and
                      antibiotic free and made in our Lotus Rawery in Torrance, California. Made
                      with lots of love and incompressiable quality. <br />
                      Food quality, not quantity.
                    </p>
                  </MDBCol>
                </MDBRow>
              </div>
              {/* End of Essential Supplement section */}

              {/* whats inside */}
              <div className="cat-raw-addition__whats-inside my-3">
                <MDBRow>
                  <MDBCol>
                    <MDBRow>
                      <MDBCol>
                        <h2 className="cat-raw-addition__header2 font-weight-bold">
                          What's Inside
                        </h2>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      {helper.whatsInsideInfo.map((info, i) => (
                        <MDBCol key={i} size="12" sm="3">
                          <img
                            src={info.icon}
                            className="img-fluid cat-raw-addition__whats-inside-icon my-3"
                          />
                          <h3 className="cat-raw-addition__header3 text-capitalize font-weight-bold">
                            {info.title}
                          </h3>
                          <p className="cat-raw-addition__p cat-raw-addition__p--info-text">
                            {info.text}
                          </p>
                        </MDBCol>
                      ))}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </div>
              {/* End of whats inside */}

              {/* Benefits */}
              <div className="cat-raw-addition__benefits my-3">
                <MDBRow>
                  <MDBCol>
                    <MDBRow>
                      <MDBCol>
                        <h2 className="cat-raw-addition__header2 font-weight-bold">Benefits</h2>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      {helper.benefitsInfo.map((info, i) => (
                        <MDBCol key={i} size="12" sm="4">
                          <img src={info.icon} className="img-fluid my-3" />
                          <h3 className="cat-raw-addition__header3 text-capitalize font-weight-bold">
                            {info.title}
                          </h3>
                          <p className="cat-raw-addition__p cat-raw-addition__p--info-text">
                            {info.text}
                          </p>
                        </MDBCol>
                      ))}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </div>
              {/* End of Benefits */}
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}