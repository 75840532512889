import React from 'react';

class blogVideo extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="container about-us">
          <div className="row">
            <div className="col-12">
              <h1 className="about-us__title brandon-font">VIDEOS</h1>
            </div>
            <div className="col-12 page-divider about-us__divider page-divider" />
            <div className="row col-12 mb-5">
              <a href="https://www.lotuspetfoods.com/fullraw"><div>Full Raw</div></a>
            </div>
            <div className="row col-12 mb-5">
              <a href="https://www.lotuspetfoods.com/starch"><div>Starch</div></a>
            </div>
            <div className="row col-12 mb-5">
              <a href="https://www.lotuspetfoods.com/specialneeds"><div>Special Needs</div></a>
            </div>
            <div className="row col-12 mb-5">
              <a href="https://www.lotuspetfoods.com/nutritional"><div>Nutritional</div></a>
            </div>
            <div className="row col-12 mb-5">
              <a href="https://www.lotuspetfoods.com/largebreeddogs"><div>Large Breed Dogs</div></a>
            </div>
            <div className="row col-12 mb-5">
              <a href="https://www.lotuspetfoods.com/benefitsofbaking"><div>Benefits of Baking</div></a>
            </div>
            <div className="row col-12 mb-5">
              <a href="https://www.lotuspetfoods.com/fullcannery"><div>Full Cannery</div></a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default blogVideo;
