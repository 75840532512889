import React from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn } from 'mdbreact';
import { Link } from 'react-router-dom';

const { setCorrectQuiz } = require('../helper.js');

export default function QuizPanelsTwo(props) {
  const quizOverviewInfo = setCorrectQuiz(props.name);
  const { main, vid, title, sub, presenterImg } = quizOverviewInfo;
  return (
    <div className="retailer-quiz__quiz-panels mt-4">
      <MDBCard className={props.taken ? 'retailer-quiz--disabled-quiz' : 'retailer-quiz__card'}>
        <MDBCardBody>
          <MDBRow className="align-items-center">
            <MDBCol className="d-flex justify-content-center" size="12" lg="4">
              <img className="img-fluid retailer-quiz__presenter-img" src={presenterImg} />
            </MDBCol>
            <MDBCol className="retailer-quiz__video-details-wrapper" size="12" lg="8">
              <h2 className="retailer-quiz__header2 text-uppercase futura-bold-font">
                {main} Video
              </h2>
              <p className="retailer-quiz__p futura-font text-center text-lg-left">{title}</p>
              <p className="retailer-quiz__p futura-font text-center text-lg-left ">{sub}</p>
              <Link
                to={{
                  pathname: `/retail-training/quiz/videoTwo-${props.name}`,
                  state: {
                    vidSrc: vid,
                    quizName: props.name,
                    quizId: props.quizId,
                  },
                }}
                className="mt-1">
                <MDBBtn className="ml-0 text-capitalize futura-bold-font" color="dark">
                  Go to Video
                </MDBBtn>
              </Link>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </div>
  );
}