import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';

import FieldInputs from './FieldInputs.jsx';
import FeedingGuidelines from './FeedingGuidelines.jsx';
import ReactTagComp from './ReactTagComp.jsx';
import ProductImages from './ProductImages.jsx';

export default function ProductComponents(props) {
  const { product } = props;
  return (
    <div className="admin-main__product-components">
      <MDBRow>
        <MDBCol size="12">
          <FieldInputs
            getInputFields={props.getInputFields}
            colName="as_fed"
            colInfo={product.as_fed}
            title="As Fed"
          />
          <FieldInputs
            getInputFields={props.getInputFields}
            colName="calories"
            colInfo={product.calories}
            title="Calories"
          />
          <ReactTagComp
            colName="description"
            title="Description"
            addColInfoReactTag={props.addColInfoReactTag}
            dragReactTagInfo={props.dragReactTagInfo}
            colInfo={product.description}
            deleteColInfoReactTag={props.deleteColInfoReactTag}
          />
          {product.feeding_guidelines !== null ? (
            <FeedingGuidelines
              getInputFields={props.getInputFields}
              feedingGuidelines={product.feeding_guidelines}
              colName="feeding_guidelines"
            />
          ) : (
            <h2 className="admin-main__header2 text-dark text-capitalize admin-main--border-divider my-3">
              Feeding Guideline does not exists for this Product.
            </h2>
          )}
          <FieldInputs
            getInputFields={props.getInputFields}
            colName="guaranteed_analysis"
            colInfo={product.guaranteed_analysis}
            title="Guaranted Analysis"
          />
          <FieldInputs
            getInputFields={props.getInputFields}
            colName="dry_matter"
            colInfo={product.dry_matter}
            title="Dry Matter"
          />
          <ReactTagComp
            colName="ingredients"
            title="Ingredients"
            addColInfoReactTag={props.addColInfoReactTag}
            dragReactTagInfo={props.dragReactTagInfo}
            colInfo={product.ingredients}
            deleteColInfoReactTag={props.deleteColInfoReactTag}
          />
          <MDBBtn
            className="admin-main--margin-x-5"
            onClick={props.saveProductChanges}
            color="dark">
            Update Product Information
          </MDBBtn>
        </MDBCol>
        <MDBCol>
          <ProductImages product_id={props.product_id} />
        </MDBCol>
      </MDBRow>
    </div>
  );
}
