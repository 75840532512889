import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: null,
      total: null,
      isDesktop: null
    };
    this.updateQuantity = this.updateQuantity.bind(this);
    this.updatePredicate = this.updatePredicate.bind(this);
    this.removeFromCart = this.removeFromCart.bind(this);
  }

  componentWillMount() {
    if (localStorage.cart) {
      const cart = JSON.parse(localStorage.cart);
      let total = 0;

      for (let i = 0; i < cart.length; i++) {
        total += cart[i].price * cart[i].quantity;
      }

      this.setState({
        cart: cart,
        total: total
      });
    } else {
      this.setState({
        cart: []
      });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.updatePredicate();
    window.addEventListener('resize', this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 768 });
  }

  updateQuantity(e) {
    const newQuantity = e.target.value;
    const index = e.target.id.replace('quantity', '');

    const newCart = this.state.cart;
    let newTotal = 0;

    newCart[index].quantity = newQuantity;

    for (let i = 0; i < newCart.length; i++) {
      newTotal += newCart[i].price * newCart[i].quantity;
    }

    localStorage.cart = JSON.stringify(newCart);

    this.setState({
      cart: newCart,
      total: newTotal
    });
  }

  removeFromCart(e) {
    const index = e.target.id.replace('remove', '');

    const newCart = this.state.cart;
    newCart.splice(index, 1);

    let newTotal = 0;

    for (let i = 0; i < newCart.length; i++) {
      newTotal += newCart[i].price * newCart[i].quantity;
    }

    localStorage.cart = JSON.stringify(newCart);

    this.setState({
      cart: newCart,
      total: newTotal
    });
  }

  render() {
    return (
      <div>
        <div className='container contact-us-body'>
          <div className='row'>
            <div className='col contact-us-text'>CART</div>
          </div>
          <div className='row page-divider mb-5' />
          {this.state.cart.length === 0 ? <div>No items in your cart!</div> : null}
          {this.state.isDesktop && this.state.cart.length > 0 && (
            <div className='row'>
              <div className='col-2 my-auto text-center' />
              <div className='col-2 my-auto text-center cart-subtitle'>Item</div>
              <div className='col-2 my-auto text-center cart-subtitle'>Size</div>
              <div className='col-2 my-auto text-center cart-subtitle'>Cost</div>
              <div className='col-2 my-auto text-center cart-subtitle'>Quantity</div>
              <div className='col-2 my-auto text-center cart-subtitle'>Price</div>
            </div>
          )}
          {this.state.cart.map((item, i) => {
            return this.state.isDesktop && this.state.cart.length > 0 ? (
              <div className='row py-4 cart-item' id={`cart-item${i}`}>
                <div className='col-2 my-auto text-center'>
                  <img className='img-fluid' src={item.image} />
                </div>
                <div className='col-2 my-auto text-center'>{item.selectedColor ? `${item.name} - ${item.selectedColor}` : `${item.name}`}</div>
                <div className='col-2 my-auto text-center'>{item.selectedSize}</div>
                <div className='col-2 my-auto text-center'>{`$${item.price}`}</div>
                <div className='col-2 px-5 my-auto d-flex justify-content-center align-content-center'>
                  <select style={{ textAlign: 'center', textAlignLast: 'center' }} value={item.quantity} class='form-control' id={`quantity${i}`} onChange={this.updateQuantity}>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5'>5</option>
                    <option value='6'>6</option>
                    <option value='7'>7</option>
                    <option value='8'>8</option>
                    <option value='9'>9</option>
                    <option value='10'>10</option>
                  </select>
                  <div className='mt-3 remove-from-cart' id={`remove${i}`} onClick={this.removeFromCart}>
                    Remove From Cart
                  </div>
                </div>
                <div className='col-2 my-auto text-center'>{`$${(item.price * item.quantity).toFixed(2)}`}</div>
              </div>
            ) : (
              <div className='row py-4 cart-item'>
                <div className='col-4 my-auto text-center'>
                  <img className='img-fluid' src={item.image} />
                  <br />
                  {item.selectedColor ? `${item.name} - ${item.selectedColor}` : `${item.name}`}
                  <br />
                  {item.selectedColor ? `Size ${item.selectedSize}` : null}
                </div>
                <div className='col-4 my-auto px-4 text-center'>
                  <div className='cart-subtitle-mobile'>Cost:</div>
                  <div>{`$${item.price}`}</div>
                  <br />
                  <div className='text-center cart-subtitle-mobile'>Quantity:</div>
                  <div>
                    <select style={{ textAlign: 'center', textAlignLast: 'center' }} value={item.quantity} class='form-control' id={`quantity${i}`} onChange={this.updateQuantity}>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                      <option value='6'>6</option>
                      <option value='7'>7</option>
                      <option value='8'>8</option>
                      <option value='9'>9</option>
                      <option value='10'>10</option>
                    </select>
                  </div>
                </div>
                <div className='col-4 my-auto text-center'>
                  <div className='cart-subtitle-mobile'>Price:</div>
                  <br />
                  {`$${(item.price * item.quantity).toFixed(2)}`}
                </div>
              </div>
            );
          })}
          {this.state.cart.length > 0 ? (
            <div>
              <div className='row mt-5'>
                <div className='col-sm-2 col-12 ml-sm-auto my-auto text-center cart-subtitle'>Subtotal</div>
                <div className='col-sm-2 col-12 my-auto text-center cart-subtitle'>{`$${this.state.total.toFixed(2)}`}</div>
              </div>
              <div className='row py-4'>
                <div className='col-sm-2 col-12 ml-sm-auto my-auto text-center cart-subtitle'>
                  <Link to='/checkout' href='/checkout'>
                    <button className='pay-button'>Checkout</button>
                  </Link>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Cart;
