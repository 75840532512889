import React from 'react';
import YouTube from 'react-youtube';
import swal from 'sweetalert2';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBAnimation } from 'mdbreact';

// ToDo:
// Fix fadeIn animation on load
export default function QuizVideo(props) {
  const { state } = props.location;
  const { history } = props;
  const youtubeOpts = {
    playerVars: {
      controls: '0',
    },
  };

  const checkQuiz = (e) => {
    e.preventDefault();
    swal
      .fire({
        title: 'Ready to retake the quiz?',
        text: 'Please make sure you can finish quiz without going back or exiting out of the quiz.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      })
      .then((result) => {
        if (result.value) {
          history.push({
            pathname: `/retail-training/quiz/questions/${state.quizName}-${state.quizId}`,
            state: {
              taken: state.taken,
            },
          });
        }
      });
  };

  return (
    <MDBContainer className="retailer-quiz__training-video margin-top-adjustment">
      <MDBRow>
        <MDBCol size="12">
          <h1 className="retailer-quiz__header1 brandon-font text-center">
            {state.quizName} Quiz Video
          </h1>
          <div className="page-divider mb-3"></div>
        </MDBCol>
        <MDBCol size="12" className="retailer-quiz__video-wrapper mt-4">
          <MDBAnimation type="fadeIn" delay="1s" duration="500">
            <YouTube
              className="retailer-quiz__youtube-vid"
              opts={youtubeOpts}
              videoId={state.vidSrc}
            />
          </MDBAnimation>
        </MDBCol>
        <MDBCol
          size="12"
          className="retailer-quiz__training-video-btn d-flex justify-content-center mt-2">
          <MDBBtn
            onClick={checkQuiz}
            className="text-capitalize futura-bold-font ml-0 mt-1"
            color="dark">
            Go to Quiz
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
