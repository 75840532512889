import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import StarRatingComponent from 'react-star-rating-component';
import axios from 'axios';
import ScrollAnimation from 'react-animate-on-scroll';

class Kibble extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showChicken: false,
      showSardine: false,
      showDuck: false,
      showLowChicken: false,
      reviews: {
        chicken: {
          total: 0,
          stars: 0,
          average: 0,
        },
        sardine: {
          total: 0,
          stars: 0,
          average: 0,
        },
        duck: {
          total: 0,
          stars: 0,
          average: 0,
        },
        lowchicken: {
          total: 0,
          stars: 0,
          average: 0,
        },
      },
    };
    this.showChicken = this.showChicken.bind(this);
    this.showSardine = this.showSardine.bind(this);
    this.showDuck = this.showDuck.bind(this);
    this.showLowChicken = this.showLowChicken.bind(this);
    this.hide = this.hide.bind(this);
    this.showChickenSizes = this.showChickenSizes.bind(this);
    this.hideChickenSizes = this.hideChickenSizes.bind(this);
    this.showSardineSizes = this.showSardineSizes.bind(this);
    this.hideSardineSizes = this.hideSardineSizes.bind(this);
    this.showDuckSizes = this.showDuckSizes.bind(this);
    this.hideDuckSizes = this.hideDuckSizes.bind(this);
    this.showLowChickenSizes = this.showLowChickenSizes.bind(this);
    this.hideLowChickenSizes = this.hideLowChickenSizes.bind(this);
  }

  componentWillMount() {
    axios.post('/getallreviews').then((res) => {
      const reviews = {
        chicken: {
          total: 0,
          stars: 0,
          average: 0,
        },
        sardine: {
          total: 0,
          stars: 0,
          average: 0,
        },
        duck: {
          total: 0,
          stars: 0,
          average: 0,
        },
        lowchicken: {
          total: 0,
          stars: 0,
          average: 0,
        },
      };

      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].product_id === 37) {
          reviews.chicken.total++;
          reviews.chicken.stars += res.data[i].stars;
          reviews.chicken.average = reviews.chicken.stars / reviews.chicken.total;
        }

        if (res.data[i].product_id === 38) {
          reviews.sardine.total++;
          reviews.sardine.stars += res.data[i].stars;
          reviews.sardine.average = reviews.sardine.stars / reviews.sardine.total;
        }

        if (res.data[i].product_id === 39) {
          reviews.duck.total++;
          reviews.duck.stars += res.data[i].stars;
          reviews.duck.average = reviews.duck.stars / reviews.duck.total;
        }

        if (res.data[i].product_id === 40) {
          reviews.lowchicken.total++;
          reviews.lowchicken.stars += res.data[i].stars;
          reviews.lowchicken.average = reviews.lowchicken.stars / reviews.lowchicken.total;
        }
      }

      this.setState({
        reviews: reviews,
      });
    });
  }

  showChicken() {
    this.setState({
      showChicken: true,
    });
  }

  showSardine() {
    this.setState({
      showSardine: true,
    });
  }

  showDuck() {
    this.setState({
      showDuck: true,
    });
  }

  showLowChicken() {
    this.setState({
      showLowChicken: true,
    });
  }

  hide() {
    this.setState({
      showChicken: false,
      showSardine: false,
      showDuck: false,
      showLowChicken: false,
    });
  }

  showChickenSizes(e) {
    $('#kibble-chicken-name, #kibble-chicken-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hideChickenSizes(e) {
    $('#kibble-chicken-name, #kibble-chicken-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  showSardineSizes(e) {
    $('#kibble-sardine-name, #kibble-sardine-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hideSardineSizes(e) {
    $('#kibble-sardine-name, #kibble-sardine-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  showDuckSizes(e) {
    $('#kibble-duck-name, #kibble-duck-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hideDuckSizes(e) {
    $('#kibble-duck-name, #kibble-duck-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  showLowChickenSizes(e) {
    $('#kibble-lowchicken-name, #kibble-lowchicken-sizes')
      .removeClass('slide-bottom-recipe')
      .addClass('slide-top-recipe');
  }

  hideLowChickenSizes(e) {
    $('#kibble-lowchicken-name, #kibble-lowchicken-sizes')
      .removeClass('slide-top-recipe')
      .addClass('slide-bottom-recipe');
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <img
          className="banner img-fluid w-100"
          src={
            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Cat+Food/Kibble/oven-baked-banner-min.png'
          }
        />
        <div className="container regular-bites-container">
          <div className="row">
            <div className="col dog-food-text regular-bites">CAT KIBBLE</div>
          </div>
          <div className="row">
            <div className="col dog-food-text description">
              Lotus Oven-Baked kibble for cats is made by a process so uncommonly wholesome it has
              an “®” after its name. Itʼs called Baked-In Goodness®(see?), and itʼs the big reason
              Lotus tastes delicious and is closer to homemade. Oven-baking means Lotus Oven-Baked
              kibble is less processed, has less starch, has more fresh meats, fish and poultry than
              most other kibbles, and hey, it just plain tastes better. Itʼs never extruded like
              most other cat foods. What the heck is extrusion, you say? Itʼs the process of using
              heat and high pressure (three times atmospheric pressure!) to form kibble in dies.
              Then theyʼre sprayed with “flavor” and fats. Plastic is often formed by extrusion,
              too. Creepy, right? Good thing someone (hint, hint) is committed to always oven-baking
              wholesome food for your cat.
            </div>
          </div>
          <ScrollAnimation animateOnce={true} animateIn="slideInUp">
            <div className="row dog-food-row-1">
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/cat/kibble/Chicken-50"
                  href="/product-view/cat/kibble/Chicken-50">
                  <div
                    className="img-gif-container"
                    onMouseEnter={this.showChicken}
                    onMouseLeave={this.hide}>
                    <div>
                      <img
                        className="img-fluid"
                        onMouseEnter={this.showChicken}
                        onMouseLeave={this.hide}
                        src={
                          'https://lotus-pet-foods-product-images.s3-us-west-1.amazonaws.com/chicken 2lbs front 1920-320-02-17-2020.png'
                        }
                      />
                    </div>
                    {this.state.showChicken && (
                      <div className="gif-container" onMouseEnter={this.showChicken}>
                        <img
                          className="img-fluid"
                          onMouseEnter={this.showChicken}
                          src={
                            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Cat+Food/Kibble/chicken-min.gif'
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="regular-chicken d-flex justify-content-center flex-column"
                    onMouseEnter={this.showChickenSizes}
                    onMouseLeave={this.hideChickenSizes}>
                    <div className="recipe-name" id="kibble-chicken-name">
                      CHICKEN RECIPE
                    </div>
                    <div className="regular-bites-sizes" id="kibble-chicken-sizes">
                      2.2 lbs | 5 lbs | 11 lbs
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.chicken.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.chicken.total}</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/cat/kibble/Sardine-49"
                  href="/product-view/cat/kibble/Sardine-49">
                  <div
                    className="img-gif-container"
                    onMouseEnter={this.showSardine}
                    onMouseLeave={this.hide}>
                    <div>
                      <img
                        className="img-fluid"
                        onMouseEnter={this.showSardine}
                        onMouseLeave={this.hide}
                        src={
                          'https://lotus-pet-foods-product-images.s3-us-west-1.amazonaws.com/fish 2lbs front 1920-320-02-17-2020.png'
                        }
                      />
                    </div>
                    {this.state.showSardine && (
                      <div className="gif-container" onMouseEnter={this.showSardine}>
                        <img
                          className="img-fluid"
                          onMouseEnter={this.showSardine}
                          src={
                            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Cat+Food/Kibble/sardine-min.gif'
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="regular-sardine d-flex justify-content-center flex-column"
                    onMouseEnter={this.showSardineSizes}
                    onMouseLeave={this.hideSardineSizes}>
                    <div className="recipe-name" id="kibble-sardine-name">
                      SARDINE RECIPE
                    </div>
                    <div className="regular-bites-sizes" id="kibble-sardine-sizes">
                      2.2 lbs | 5 lbs | 11 lbs
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.sardine.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.sardine.total}</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/cat/kibble/Duck-29"
                  href="/product-view/cat/kibble/Duck-29">
                  <div
                    className="img-gif-container"
                    onMouseEnter={this.showDuck}
                    onMouseLeave={this.hide}>
                    <div>
                      <img
                        className="img-fluid"
                        onMouseEnter={this.showDuck}
                        onMouseLeave={this.hide}
                        src={
                          'https://lotus-pet-foods-product-images.s3-us-west-1.amazonaws.com/duck 2lbs front 1920-320-02-17-2020.png'
                        }
                      />
                    </div>
                    {this.state.showDuck && (
                      <div className="gif-container" onMouseEnter={this.showDuck}>
                        <img
                          className="img-fluid"
                          onMouseEnter={this.showDuck}
                          src={
                            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Cat+Food/Kibble/duck-min.gif'
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="regular-duck d-flex justify-content-center flex-column"
                    onMouseEnter={this.showDuckSizes}
                    onMouseLeave={this.hideDuckSizes}>
                    <div className="recipe-name" id="kibble-duck-name">
                      DUCK RECIPE
                    </div>
                    <div className="regular-bites-sizes" id="kibble-duck-sizes">
                      2.2 lbs | 5 lbs | 11 lbs
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.duck.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.duck.total}</div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 col-12 my-sm-5 my-4">
                <Link
                  className="no-underline product-overview__product-link"
                  to="/product-view/cat/kibble/Low%20Fat-3"
                  href="/product-view/cat/kibble/Low%20Fat-3">
                  <div
                    className="img-gif-container"
                    onMouseEnter={this.showLowChicken}
                    onMouseLeave={this.hide}>
                    <div>
                      <img
                        className="img-fluid"
                        onMouseEnter={this.showLowChicken}
                        onMouseLeave={this.hide}
                        src={
                          'https://lotus-pet-foods-product-images.s3-us-west-1.amazonaws.com/LF chicken 2lbs front 1920-320-02-17-2020.png'
                        }
                      />
                    </div>
                    {this.state.showLowChicken && (
                      <div className="gif-container" onMouseEnter={this.showLowChicken}>
                        <img
                          className="img-fluid"
                          onMouseEnter={this.showLowChicken}
                          src={
                            'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Cat+Food/Kibble/lowchicken-min.gif'
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="regular-chicken d-flex justify-content-center flex-column"
                    onMouseEnter={this.showLowChickenSizes}
                    onMouseLeave={this.hideLowChickenSizes}>
                    <div className="recipe-name" id="kibble-lowchicken-name">
                      CHICKEN RECIPE
                    </div>
                    <div className="regular-bites-sizes" id="kibble-lowchicken-sizes">
                      2.2 lbs | 5 lbs | 11 lbs
                    </div>
                  </div>
                </Link>
                <div
                  style={{ fontSize: 24 }}
                  className="mt-2 d-flex justify-content-center align-items-center">
                  <StarRatingComponent
                    name="product"
                    starCount={5}
                    value={this.state.reviews.lowchicken.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                    renderStarIconHalf={() => {
                      return (
                        <span>
                          <span style={{ position: 'absolute' }}>
                            <i className="far fa-star" />
                          </span>
                          <span>
                            <i className="fas fa-star-half" />
                          </span>
                        </span>
                      );
                    }}
                  />
                  <div className="rating-count">{this.state.reviews.lowchicken.total}</div>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    );
  }
}

export default Kibble;
