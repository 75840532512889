import swal from 'sweetalert2';

const success = (props) => {
  const sweetAlertWithoutBtn = {
    icon: 'success',
    title: `${props.title}`,
    text: `${props.text}`,
    timer: 3000,
    timerProgressBar: true,
    showConfirmButton: false,
  };

  const sweetAlertWithBtn = {
    icon: 'success',
    title: `${props.title}`,
    text: `${props.text}`,
    showConfirmButton: true,
    confirmButtonText: `${props.button}`,
  };
  return props.button ? swal.fire(sweetAlertWithBtn) : swal.fire(sweetAlertWithoutBtn);
};

const error = (props) => {
  const sweetAlertWithoutBtn = {
    icon: 'error',
    title: `${props.title}`,
    text: `${props.text}`,
    timer: 3000,
    timerProgressBar: true,
    showConfirmButton: false,
  };

  const sweetAlertWithBtn = {
    icon: 'error',
    title: `${props.title}`,
    text: `${props.text}`,
    showConfirmButton: true,
    confirmButtonText: `${props.button}`,
  };
  return props.button ? swal.fire(sweetAlertWithBtn) : swal.fire(sweetAlertWithoutBtn);
};

const warning = (props) => {
  const sweetAlertWithoutBtn = {
    icon: 'warning',
    title: `${props.title}`,
    text: `${props.text}`,
    timer: 3000,
    timerProgressBar: true,
    showConfirmButton: false,
  };

  const sweetAlertWithBtn = {
    icon: 'warning',
    title: `${props.title}`,
    text: `${props.text}`,
    showConfirmButton: true,
    confirmButtonText: `${props.button}`,
  };
  return props.button ? swal.fire(sweetAlertWithBtn) : swal.fire(sweetAlertWithoutBtn);
};

module.exports = { success, error, warning };
