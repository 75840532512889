import React from 'react';
import { MDBCol, MDBBtn } from 'mdbreact';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 250,
  lazyLoad: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ],
};

const rawFoodProducts = [
  {
    description:
      'An all life stage raw recipe made with USDA inspected and passed boneless chicken thighs, chicken hearts, and chicken livers.',
    src:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Landing+Page/oct-banner/chicken-raw.jpg',
    linkTo: '/product-view/cat/raw-food/chicken-60',
  },
  {
    description:
      'An all life stage raw recipe made with USDA inspected and passed boneless beef atuck, tender, beef hearts and beef livers.',
    src: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Landing+Page/oct-banner/beef-raw.jpg',
    linkTo: '/product-view/cat/raw-food/beef-59',
  },
  {
    description:
      "An all life stage raw recipe made with New Zealand' MPI inspected and passed boneless lamb shoulder, lamb hearts, and lamb livers.",
    src: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Landing+Page/oct-banner/lamb-raw.jpg',
    linkTo: '/product-view/cat/raw-food/lamb-54',
  },
  {
    description:
      'An all life stage raw recipe made with USDA inspected and passed boneless boneless turkey thighs, turkey hearts, and turkey livers.',
    src: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Landing+Page/oct-banner/turkey-raw.jpg',
    linkTo: '/product-view/cat/raw-food/turkey-57',
  },
  {
    description:
      "An all life stage raw recipe made with New Zealand' MPI inspected and passed venison trim, venison hearts, and venison livers.",
    src:
      'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Landing+Page/oct-banner/venison-raw.jpg',
    linkTo: '/product-view/cat/raw-food/venison-55',
  },
  {
    description:
      'An all life stage raw recipe made with USDA inspected and passed boneless pork shoulder, pork hearts, and pork livers',
    src: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Landing+Page/oct-banner/pork-raw.jpg',
    linkTo: '/product-view/cat/raw-food/pork-58',
  },
];

export default function RawCarousel(props) {
  const renderCarousel = () => {
    const builtDivs = rawFoodProducts.map((item, keys) => (
      <MDBCol key={keys} size="12" className="raw-food-product-banners__banner-image px-0">
        <div className="raw-food-product-banners__img overflow-hidden">
          <Link className="pointer" to={item.linkTo}>
            <img className="img-fluid image-zoom-effect" src={item.src} alt={item.description} />
          </Link>
          <div className="raw-food-product-banners__button-wrapper">
            <Link className="d-block raw-food-product-banners__link-btn-anchor" to={item.linkTo}>
              <MDBBtn
                outline
                color="white"
                type="button"
                id={`raw-carousel-button-index-${keys}`}
                className={`pointer raw-food-product-banners__link-btn raw-food-product-banners__link-btn--modifier-index-${keys}`}>
                Learn more
              </MDBBtn>
            </Link>
          </div>
        </div>
      </MDBCol>
    ));

    return (
      <Slider
        id="raw-slider"
        className="m-0 raw-food-product-banners__banner-image"
        {...sliderSettings}>
        {builtDivs}
      </Slider>
    );
  };

  return <MDBCol>{renderCarousel()}</MDBCol>;
}
