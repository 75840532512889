import React from 'react';

function UnderConstruction(props) {
  return (
    <div className="margin-top-adjustment">
      <div className="construction-text text-center futura-font">
        <h1>We are currently doing a rework on this page.</h1>
        <p className="mt-4">Please check back back soon. Thank you for your patience.</p>
      </div>
      <div className="text-center">
        <img
          className="construction-dog"
          src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Store+Locator/computer.png'}
        />
      </div>
    </div>
  );
}

export default UnderConstruction;
