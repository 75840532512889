import React from 'react';
import imageMapResize from 'image-map-resizer';
import Axios from 'axios';
import { MDBRow, MDBCol } from 'mdbreact';

const { ingredientPictures } = require('./helper');
const { success, error, warning } = require('../_helper/SweetAlerts/alerts.jsx');

const {
  endPoint: { ingredientEndPoint },
} = require('../_helper/stringVars');

class Ingredients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clam: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      beef: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      chicken: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      duck: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      lamb: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      pollock: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      pork: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      rabbit: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      salmon: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      ingredients: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    imageMapResize();
    this.getIngredient();
  }

  getIngredient() {
    Axios.get(`${ingredientEndPoint.client}/get-ingredient`)
      .then((res) => {
        const ingredients = res.data;
        this.setState({
          ingredients,
        });
      })
      .catch((err) => {
        const { status } = err.res;
        console.error(err);
        if (status === 400) {
          return error({ title: 'Error', text: 'Unathorized Request', button: 'Okay.' });
        }
      });
  }

  showClam = () => {
    this.setState({
      clam: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/clam3.gif',
    });
  };

  showBeef = () => {
    this.setState({
      beef: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/beef2.gif',
    });
  };

  showChicken = () => {
    this.setState({
      chicken: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/chicken2.gif',
    });
  };

  showDuck = () => {
    this.setState({
      duck: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/duck2.gif',
    });
  };

  showLamb = () => {
    this.setState({
      lamb: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/lamb2.gif',
    });
  };

  showPollock = () => {
    this.setState({
      pollock: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/alaska-fin2.gif',
    });
  };

  showPork = () => {
    this.setState({
      pork: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/pork2.gif',
    });
  };

  showRabbit = () => {
    this.setState({
      rabbit: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/rabbit2.gif',
    });
  };

  showSalmon = () => {
    this.setState({
      salmon: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/salmon2.gif',
    });
  };

  hide = () => {
    this.setState({
      clam: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      beef: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      chicken: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      duck: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      lamb: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      pollock: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      pork: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      rabbit: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      salmon: 'data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
    });
  };

  render() {
    return (
      <div>
        <div className="container ingredient-body">
          {window.innerWidth > 768 ? (
            <div className="row">
              <div className="col contact-us-text">
                <h1></h1>MEAT ORIGIN
              </div>
            </div>
          ) : null}
          <div className="row page-divider about-us-divider" />
          {window.innerWidth > 768 ? (
            <div className="ingredient-map-body text-center">
              <div className="map-hover-text mb-5">
                Hover over a number to view our protein sources!
              </div>
              <div className="faq-map row text-center">
                <div className="col-12">
                  <img
                    useMap="#map"
                    className="img-fluid"
                    src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/FAQ/map.png'}
                  />
                  <map name="map">
                    <area
                      onMouseEnter={this.showClam}
                      onMouseLeave={this.hide}
                      coords="294,517,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showBeef}
                      onMouseLeave={this.hide}
                      coords="295,445,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showChicken}
                      onMouseLeave={this.hide}
                      coords="468,438,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showDuck}
                      onMouseLeave={this.hide}
                      coords="502,491,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showPollock}
                      onMouseLeave={this.hide}
                      coords="226,187,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showRabbit}
                      onMouseLeave={this.hide}
                      coords="1035,463,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showPork}
                      onMouseLeave={this.hide}
                      coords="449,483,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showLamb}
                      onMouseLeave={this.hide}
                      coords="1945,1076,18"
                      shape="circle"
                    />
                    <area
                      onMouseEnter={this.showSalmon}
                      onMouseLeave={this.hide}
                      coords="412,318,18"
                      shape="circle"
                    />
                  </map>
                  <img className="map-gif map-clam img-fluid" src={this.state.clam} />
                  <img className="map-gif map-beef img-fluid" src={this.state.beef} />
                  <img className="map-gif map-chicken img-fluid" src={this.state.chicken} />
                  <img className="map-gif map-duck img-fluid" src={this.state.duck} />
                  <img className="map-gif map-pollock img-fluid" src={this.state.pollock} />
                  <img className="map-gif map-rabbit img-fluid" src={this.state.rabbit} />
                  <img className="map-gif map-pork img-fluid" src={this.state.pork} />
                  <img className="map-gif map-lamb img-fluid" src={this.state.lamb} />
                  <img className="map-gif map-salmon img-fluid" src={this.state.salmon} />
                </div>
              </div>
            </div>
          ) : null}
          <MDBRow>
            <MDBCol size="12" className="contact-us-text">
              <h2>INGREDIENTS</h2>
            </MDBCol>
          </MDBRow>
          <div className="row ingredient-divider mb-4" />
          <MDBCol size="12">
            {this.state.ingredients.map((ingredient, i) => {
              return (
                <div key={i} className="ingredients__description">
                  <MDBRow center className="align-items-center">
                    <MDBCol
                      size="6"
                      sm="3"
                      md="2"
                      className="d-flex flex-column justify-content-center align-items-center text-center">
                      <img className="img-fluid" src={ingredientPictures[ingredient.name].img} />
                      <h3 className="ingredients__header3">
                        {ingredientPictures[ingredient.name].text}
                      </h3>
                    </MDBCol>
                    <MDBCol size="12" sm="12" md="10">
                      <p>{ingredient.description}</p>
                    </MDBCol>
                  </MDBRow>
                  <div className="ingredients--divider my-4" />
                </div>
              );
            })}
          </MDBCol>
        </div>
        <div style={{ overflowY: 'hidden' }}>
          <img
            className="img-fluid bottom-art w-100"
            src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Global/bottom-art.png'}
          />
        </div>
      </div>
    );
  }
}

export default Ingredients;
