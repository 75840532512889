import React, { Component } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTabContent,
  MDBTabPane,
  MDBSelect,
  MDBAnimation,
  MDBBtn,
} from 'mdbreact';

import RenderQuiz from './RenderQuiz.jsx';
import IsLoading from '../../../../IsLoading/IsLoading.jsx';
import IsLoadingChild from '../../../../IsLoading/IsLoadingChild.jsx';

const { setToken } = require('../../../../_helper/index.jsx');
const { success, error, warning } = require('../../../../_helper/SweetAlerts/alerts.jsx');
const { createQuizCategory } = require('./helper.jsx');

const {
  endPoint: { quizEndPoint },
} = require('../../../../_helper/stringVars');

/*
  Implement input to update and change quiz question and answers.
  Implement a new way to add quiz onto DB through admin
*/

export default class AdminQuizzes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quizBanks: [],
      currentQuiz: {},
      quizBankSet: false,
      quizSelected: false,
      quizNames: [],
      answerChanged: false,
    };

    this.getQuizVal = this.getQuizVal.bind(this);
    this.setCurrentQuizView = this.setCurrentQuizView.bind(this);
    this.changeAnswer = this.changeAnswer.bind(this);
    this.saveNewAnswers = this.saveNewAnswers.bind(this);
    this.token = { headers: setToken('admin') };
  }

  componentDidMount() {
    this.getQuizzes();
  }

  getQuizzes() {
    Axios.get(`${quizEndPoint.admin}/get-quiz`, this.token)
      .then((res) => {
        const { data, status } = res;
        if (status === 404) {
          return error({
            title: 'Error',
            text: 'Quizzes not found. Please refresh page.',
            button: 'Okay',
          });
        }
        this.setState({
          quizBanks: data,
          quizNames: createQuizCategory(data),
          quizBankSet: true,
        });
      })
      .catch((err) => {
        console.error(err);
        error({ title: 'Server Error', text: 'Please try again later.', button: 'Okay' });
      });
  }

  getQuizVal(value) {
    const [reviewName] = value;
    const { quizBanks } = this.state;
    setTimeout(() => {
      this.setCurrentQuizView(reviewName, quizBanks);
    }, 500);
  }

  setCurrentQuizView(selectedQuiz, quizArr) {
    // return new quiz Obj;
    const currentQuiz = {};
    quizArr.forEach((quiz) => {
      if (quiz.name === selectedQuiz) {
        Object.assign(currentQuiz, quiz);
      }
    });
    this.setState({
      currentQuiz,
      quizSelected: true,
    });
  }

  changeAnswer(e, questionNum) {
    const { value } = e.target;
    const { currentQuiz } = this.state;
    const newQuiz = {};

    // eslint-disable-next-line dot-notation
    currentQuiz['questions_bank'].forEach((questionObj) => {
      if (questionObj.number === parseInt(questionNum, 10)) {
        questionObj.correctAnswer = value;
      }
    });
    Object.assign(newQuiz, currentQuiz);

    this.setState({
      currentQuiz: newQuiz,
      answerChanged: true,
    });
  }

  saveNewAnswers() {
    const { currentQuiz, answerChanged } = this.state;
    if (!answerChanged) {
      return warning({
        title: 'Warning',
        text: 'You have not updated any answers',
        button: 'Okay',
      });
    }

    Axios.put(`${quizEndPoint.admin}/update-quiz`, { params: currentQuiz }, this.token)
      .then((res) => {
        const { status } = res;

        if (status !== 201) {
          return error({
            title: '404 Error',
            text: 'Quiz not found, please try again.',
            button: 'Okay',
          });
        }
        return success({ title: 'Success!', text: 'Quiz has been updated', button: 'Okay' });
      })
      .catch((err) => {
        console.error(err);
        return error({
          title: 'Error',
          text: 'Server Error. Please try again later.',
          button: 'Okay',
        });
      });
  }

  renderQuizComponent() {
    const { quizSelected, currentQuiz } = this.state;
    if (!quizSelected) {
      return <h2 className="admin-main__header2 futura-font">Please Select Quiz.</h2>;
    }
    return (
      <RenderQuiz
        saveNewAnswers={this.saveNewAnswers}
        changeAnswer={this.changeAnswer}
        quiz={currentQuiz}
      />
    );
  }

  renderComponent() {
    const { quizBankSet, quizBanks, quizNames, quizSelected, currentQuiz } = this.state;
    const quizLink = '/admin/main/quizzes';
    if (!quizBankSet) return <IsLoading />;
    return (
      <div className="admin-main__quizzes">
        <MDBContainer>
          <MDBAnimation type="fadeIn">
            <MDBTabContent className="admin-main__tab-content">
              <MDBTabPane>
                <MDBRow>
                  <MDBCol size="6">
                    <MDBSelect
                      options={quizNames}
                      getValue={this.getQuizVal}
                      selected="Choose Quiz"
                      label="Quizzes"
                    />
                  </MDBCol>
                </MDBRow>
                {this.renderQuizComponent()}
              </MDBTabPane>
            </MDBTabContent>
          </MDBAnimation>
        </MDBContainer>
      </div>
    );
  }

  render() {
    return this.renderComponent();
  }
}
