import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class SuperFoodTopper extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
      }

  render() {
    return (
        <div>
            <div className="pb-3 blog-margin-adjustment-desktop">
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/superfood-topper-banner.jpg'} 
                        className="banner img-fluid w-100" 
                        alt="lotus Pet Foods - Superfood Topper for Dogs and Cats"/>
                    </div>
                </MDBAnimation>
            </div>

            <div className='container mt-5'>
                <p style={{"color": "grey"}}>March, 01, 2022</p>
            </div>
    
            <div className='container mt-5 text-center d-none-mobile'>
                <h1><span className="LemonMilkbold-font blog-hb">Superfood Topper for Dogs & Cats</span></h1>
            </div>

            <div className='container mt-5 text-center d-none-desktop'>
                <h1><span className="LemonMilkbold-font blog-h">Superfood Topper for Dogs & Cats !</span></h1>
            </div>

            <div className='container'>
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/superfood-topper-main.jpg'} 
                        className="banner img-fluid w-100" 
                        alt="lotus Pet Foods - Superfood Topper for Dogs and Cats"/>
                    </div>
                </MDBAnimation>
             </div>

             <div className='container' style={{"marginTop": "60px"}}>
                <div className='mb-5 futura-font blog-p'>
                    <p>So what is tripe exactly? Tripe is the stomach lining of a rudiment – or cud eating, multi stomach animal. 
                        Some types of these animals are cows, lambs and sheep. If you look in the grocery store you can find tripe 
                        but it will be white and have been bleached, whereas ours is the Green Tripe, which has not been bleached. 
                        It is called Green, even though it is more of a brownish color due to being colored by the green grasses 
                        that the animal was eating. We purposely chose the green tripe, in order to keep more of the nutritional 
                        benefits you find in tripe. We also purposely choose our tripe from New Zealand. We want to ensure the 
                        quality of our tripe is top notch. Our tripe is grass fed – no feedlots here, ensuring the tripe is naturally 
                        green (or brown).
                    </p> 
                    <br/>
                    <p>Now you are probably wondering what those benefits are. Well, let me tell you some of them.</p>
                    <br/>
                    <p>One of the biggest ones you will hear is that it help your pets gut health. That is because tripe contains lots 
                        of probiotics, specifically Lactobacillus acidophilis, which is found in all animals’ stomachs. By adding in 
                        the tripe you are increasing the good bacteria in your pets stomach and allowing them to have a healthier gut. 
                        In addition tripe has digestive enzymes. These will allow your pet to break down their food even better and be 
                        able to get even more nutrients from their food.
                    </p>
                    <br/>
                    <p>All of this also allows tripe to be very easy to digest and therefore often is able to be fed to pets with sensitive 
                        stomachs. In addition, it is often recommended for picky eaters. This also partially due to the stink factor. 
                        Not gonna lie, it will smell – it is the stomach we are using, but remember that is what pets go for. And we have 
                        ours in tubs to make them easy to close after scooping some out. Although it is low calorie – so will not load a 
                        bowl with extra calories, it will help entice those dogs that need a little extra in their bowls.
                    </p>
                    <br/>
                    <p>Now I mentioned our tripe is in a tub. 3 easy sizes in fact. Just like the rest of our raw line, they come in 3.75, 17 
                        and 25 oz plastic tubs. Yes the plastic is recyclable,  the #2 plastic that we use is the most easily recyclable plastic. 
                        Thus make the feeding of tripe very easy for the consumer. This is just a supplement. The ingredients are so simple, 
                        its is just green tripe and water. So while tripe has many great benefits for your cat or dog, it does not contain 
                        everything needed to be a complete diet on its own.
                    </p>
                    <br/>
                </div>
            </div>
        </div>
    );
  }
}

export default SuperFoodTopper;