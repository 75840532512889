import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';

export default function HeroDescription(props) {
  const { title, description } = props;
  return (
    <MDBRow className="product-overview__hero-description">
      <MDBCol sm="12">
        <h1 className="header1 text-uppercase futura-bold-font">{title}</h1>
      </MDBCol>
      <MDBCol sm="12">
        <p className="futura-font">{description}</p>
      </MDBCol>
    </MDBRow>
  );
}
