import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class PetAppetite extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
      }

  render() {
    return (
        <div>
            <div className="pb-3 blog-margin-adjustment-desktop">
            <MDBAnimation type="fadeIn">
                <div>
                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/petappetite2.jpg'} 
                    className="banner img-fluid w-100" 
                    alt="lotus Pet Foods - What should I Do If My Pet won't Eat"/>
                </div>
            </MDBAnimation>
            </div>

            <div className='container mt-5'>
                <p style={{"color": "grey"}}>Sept 01, 2022</p>
            </div>

            <div className='container mt-5 text-center d-none-mobile'>
                <h1><span className="LemonMilkbold-font blog-hb-two">What should I Do If My Pet won't Eat</span></h1>
            </div>
       
            <div className='container mt-5 text-center d-none-desktop'>
                <h1><span className="LemonMilkbold-font blog-h">What should I Do If My Pet won't Eat</span></h1>
            </div>
  
            <div className='container mt-4'> 
                <div className='futura-font text-center blog-p main-para-padding'>
                    <h5><span className="lineheightPetAppetite">If there is a sudden change in your petʼs appetite, a trip to the vet is the first course of action. Follow your vetʼs recommendations about 
                        health. If your petʼs health checks out and you suspect your pet to just be tired of the same old food or your pet is known to be finicky, 
                        try out some of these tips.</span></h5>
                    <br />
                </div>
            </div>

             <div className='container text-center'>
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/petappetite3.png'} 
                        className="img-fluid" 
                        width="40%"
                        alt="lotus Pet Foods - What should I Do If My Pet won't Eat"/>
                    </div>
                </MDBAnimation>
             </div>


{/********1**********/}
            <div className="container d-none-tablet d-done-mobile">
                <MDBRow className="d-flex align-items-center futura-font tiponePetAppetite">
                    <MDBCol xs="12" sm="12" md="8">
                        <div className="headingPetAppetite futura-bold-font">
                            <h2>Tip 1</h2>
                        </div>
                        <div className="paraPetAppetite">
                            <p><span className="paralineheightPetAppetite">Make sure youʼre feeding the proper amount of food. Many times, pets are overfed and simply not hungry for their next meal. 
                                Remember that feeding guidelines are just that – guidelines. Adjust to your pets needs.</span></p>
                        </div>
                    </MDBCol>
                    <MDBCol xs="12" sm="12" md="4">
                    
                    </MDBCol>
                </MDBRow>
            </div>

            <div className="containerPetAppetite d-none-desktop-two">
                <MDBRow className="d-flex align-items-center futura-font tiponePetAppetite">
                    <MDBCol xs="12" sm="12" md="8">
                        <div className="headingPetAppetite futura-bold-font">
                            <h2>Tip 1</h2>
                        </div>
                        <div className="paraPetAppetite">
                            <p><span className="paralineheightPetAppetite">Make sure youʼre feeding the proper amount of food. Many times, pets are overfed and simply not hungry for their next meal. 
                                Remember that feeding guidelines are just that – guidelines. Adjust to your pets needs.</span></p>
                        </div>
                    </MDBCol>
                    <MDBCol xs="12" sm="12" md="4">
                    
                    </MDBCol>
                </MDBRow>
            </div>

            <div className='containerPetAppetite text-center d-none-mobile d-none-tablet'>
                <div>
                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/petappetite6.png'} 
                    className="img-fluid" 
                    width="100%"
                    alt="lotus Pet Foods - What should I Do If My Pet won't Eat"/>
                </div>
            </div>

{/*******************/}
             <div className="containerPetAppetite mt-5">
                <MDBRow className="d-flex align-items-center justify-content-center futura-font tiptwoPetAppetite">
                <MDBCol xs="12" sm="12" md="12" lg="3">
                    <div className='containerPetAppetite text-center d-none-mobile d-none-tablet'>
                        <div>
                            <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/petappetite10.png'} 
                            className="img-fluid" 
                            width="100%"
                            alt="lotus Pet Foods - What should I Do If My Pet won't Eat"/>
                        </div>
                    </div>
                    </MDBCol>
                    <MDBCol xs="12" sm="12" md="12" lg="5">
                        <div className="headingPetAppetite futura-bold-font">
                            <h2>Tip 2</h2>
                        </div>
                        <div className="paraPetAppetite">
                            <p><span className="paralineheightPetAppetite">Have a scheduled feeding time. Pets do well on scheduled feeding times, rather than grazing all day – 
                            when you schedule their feeds, you can be sure youʼre giving them the proper amount.</span></p>
                        </div>
                    </MDBCol>
                </MDBRow>
            </div>

{/*******************/}
            <div className="container mt-5 d-none-tablet d-done-mobile">
                <MDBRow className="d-flex align-items-center futura-font tipthreePetAppetite">
                    <MDBCol xs="12" sm="12" md="8">
                        <div className="headingPetAppetite futura-bold-font">
                            <h2>Tip 3</h2>
                        </div>
                        <div className="paraPetAppetite">
                            <p><span className="paralineheightPetAppetite">A random cup or mug could mean youʼre feeding the wrong amount! Using a measuring cup is important to feed our pets 
                            the right amount so they maintain a healthy body weight.</span></p>
                        </div>
                    </MDBCol>
                    <MDBCol xs="12" sm="12" md="4">
                    
                    </MDBCol>
                </MDBRow>
            </div>

            <div className="containerPetAppetite mt-5 d-none-desktop-two">
                <MDBRow className="d-flex align-items-center futura-font tipthreePetAppetite">
                    <MDBCol xs="12" sm="12" md="8">
                        <div className="headingPetAppetite futura-bold-font">
                            <h2>Tip 3</h2>
                        </div>
                        <div className="paraPetAppetite">
                            <p><span className="paralineheightPetAppetite">A random cup or mug could mean youʼre feeding the wrong amount! Using a measuring cup is important to feed our pets 
                            the right amount so they maintain a healthy body weight.</span></p>
                        </div>
                    </MDBCol>
                    <MDBCol xs="12" sm="12" md="4">
                    
                    </MDBCol>
                </MDBRow>
            </div>
             <div className='containerPetAppetite text-center d-none-mobile d-none-tablet'>
                <div>
                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/petappetite7.png'} 
                    className="img-fluid" 
                    width="100%"
                    alt="lotus Pet Foods - What should I Do If My Pet won't Eat"/>
                </div>
             </div>
{/*******************/}
            <div className="containerPetAppetite mt-5">
                <MDBRow className="d-flex align-items-center justify-content-center futura-font tipfourPetAppetite">
                <MDBCol xs="12" sm="12" md="12" lg="6">
                    <div className='containerPetAppetite text-center d-none-mobile d-none-tablet'>
                        <div>
                            <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/petappetite91.png'} 
                            className="img-fluid" 
                            width="100%"
                            alt="lotus Pet Foods - What should I Do If My Pet won't Eat"/>
                        </div>
                    </div>
                    </MDBCol>
                    <MDBCol xs="12" sm="12" md="12" lg="5">
                        <div className="headingPetAppetite futura-bold-font">
                            <h2>Tip 4</h2>
                        </div>
                        <div className="paraPetAppetite">
                            <p><span className="paralineheightPetAppetite">If youʼre using these tips above, and your pet still wonʼt eat: Add in a table spoon or two of a high protein canned food.</span></p>
                        </div>
                    </MDBCol>
                </MDBRow>
            </div>

{/*******************/}
            <div className="container mt-5 d-none-tablet d-done-mobile">
                <MDBRow className="d-flex align-items-center futura-font tipfivePetAppetite">
                    <MDBCol xs="12" sm="12" md="8">
                        <div className="headingPetAppetite futura-bold-font">
                            <h2>Tip 5</h2>
                        </div>
                        <div className="paraPetAppetite">
                            <p><span className="paralineheightPetAppetite"> Add fresh veggies on top, ensure theyʼre not high in sodium and just plain fresh veggies.</span></p>
                        </div>
                    </MDBCol>
                    <MDBCol xs="12" sm="12" md="4">
                    
                    </MDBCol>
                </MDBRow>
            </div>
            <div className="containerPetAppetite mt-5 d-none-desktop-two">
                <MDBRow className="d-flex align-items-center futura-font tipfivePetAppetite">
                    <MDBCol xs="12" sm="12" md="8">
                        <div className="headingPetAppetite futura-bold-font">
                            <h2>Tip 5</h2>
                        </div>
                        <div className="paraPetAppetite">
                            <p><span className="paralineheightPetAppetite"> Add fresh veggies on top, ensure theyʼre not high in sodium and just plain fresh veggies.</span></p>
                        </div>
                    </MDBCol>
                    <MDBCol xs="12" sm="12" md="4">
                    
                    </MDBCol>
                </MDBRow>
            </div>
             <div className='containerPetAppetite text-center d-none-mobile d-none-tablet'>
                <div>
                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/petappetite8.png'} 
                    className="img-fluid" 
                    width="100%"
                    alt="lotus Pet Foods - What should I Do If My Pet won't Eat"/>
                </div>
             </div>
{/*******************/}
                <div className="containerPetAppetite mt-5">
                <MDBRow className="d-flex align-items-center justify-content-center futura-font">
                <MDBCol xs="12" sm="12" md="12" lg="6">
                    <div className='containerPetAppetite text-center d-none-mobile d-none-tablet'>
                        <div>
                            <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/petappetite41.png'} 
                            className="img-fluid" 
                            width="100%"
                            alt="lotus Pet Foods - What should I Do If My Pet won't Eat"/>
                        </div>
                    </div>
                    </MDBCol>
                    <MDBCol xs="12" sm="12" md="12" lg="5">
                        <div className="headingPetAppetite futura-bold-font">
                            <h2>Tip 6</h2>
                        </div>
                        <div className="paraPetAppetite">
                            <p><span className="paralineheightPetAppetite">Keep the table scraps to a minimum inbetween feedings. Our petsʼ bellies are pretty small, and if theyʼre getting extras through the day, it could mean they wonʼt be hungry at meal time. They may start to gain weight which could lead to health problems down the road.</span></p>
                        </div>
                    </MDBCol>
                </MDBRow>
            </div>

{/*******************/}
            <br></br>
            <div className="container mt-5 d-none-tablet d-done-mobile">
                <MDBRow className="d-flex align-items-center futura-font tipsevenPetAppetite">
                    <MDBCol xs="12" sm="12" md="7">
                        <div className="headingPetAppetite futura-bold-font">
                            <h2>Tip 7</h2>
                        </div>
                        <div className="paraPetAppetite">
                            <p><span className="paralineheightPetAppetite">Lotus oven baked kibble has multiple proteins to switch through for both dogs and cats, a wide variety of cans, raw and frozen supplements to top your kibble with to help entice your pet to eat.
                            </span></p>
                        </div>
                    </MDBCol>
                    <MDBCol xs="12" sm="12" md="5">
                    
                    </MDBCol>
                </MDBRow>
            </div>
            <div className="containerPetAppetite mt-5 d-none-desktop-two">
                <MDBRow className="d-flex align-items-center futura-font tipsevenPetAppetite">
                    <MDBCol xs="12" sm="12" md="7">
                        <div className="headingPetAppetite futura-bold-font">
                            <h2>Tip 7</h2>
                        </div>
                        <div className="paraPetAppetite">
                            <p><span className="paralineheightPetAppetite">Lotus oven baked kibble has multiple proteins to switch through for both dogs and cats, a wide variety of cans, raw and frozen supplements to top your kibble with to help entice your pet to eat.
                            </span></p>
                        </div>
                    </MDBCol>
                    <MDBCol xs="12" sm="12" md="5">
                    
                    </MDBCol>
                </MDBRow>
            </div>
             <div className='containerPetAppetite text-center d-none-mobile d-none-tablet'>
                <div>
                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/petappetite5.png'} 
                    className="img-fluid" 
                    width="100%"
                    alt="lotus Pet Foods - What should I Do If My Pet won't Eat"/>
                </div>
             </div>
        </div>
    );
  }
}

export default PetAppetite;