import React from 'react';

import RetailerList from './AdminPages/Retailers/RetailersList.jsx';
import Stores from './AdminPages/Stores/Stores.jsx';

export default function AdminMainComponents(props) {
  const { page } = props.match.params;
  const checkToRenderComponent = (component) => {
    switch (component) {
      case 'stores':
        return <Stores />;
      case 'retailers':
        return <RetailerList />;
      default:
        return '';
    }
  };
  return checkToRenderComponent(page);
}
