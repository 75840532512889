const recipeIcon = {
  chicken: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Recipe_icon/chicken.png',
  'chicken adult': 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Recipe_icon/chicken.png',
  'chicken puppy': 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Recipe_icon/chicken.png',
  'chicken senior': 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Recipe_icon/chicken.png',
  beef: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Recipe_icon/beef.png',
  lamb: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Recipe_icon/lamb.png',
  pork: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Recipe_icon/pork.png',
  turkey: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Recipe_icon/turkey.png',
  venison: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Recipe_icon/venison.png',
  sardine: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Recipe_icon/sardine.png',
  duck: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Recipe_icon/duck.png',
  rabbit: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Recipe_icon/rabbit.png',
  salmon: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Recipe_icon/salmon.png',
  pollock: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Recipe_icon/pollock.png',
};

const setRecipeIcon = (recipeName) => {
  return recipeIcon[recipeName];
};

const waitForState = async (...funcs) => {
  const waitForFuncs = funcs.map((func) => {
    func();
    return true;
  });

  const done = await waitForFuncs[0];
  return done;
};

const getCorrectImgSrc = (product) => {
  const [images] = product.products_additions;
  let picSrc;
  Object.keys(images).forEach((key) => {
    if (
      images[key] !== null &&
      (key.includes('small') || key.includes('large') || key.includes('medium'))
    ) {
      picSrc = images[key].front.main;
    }
  });
  return picSrc;
};

const convertThumbImgToArr = (thumbs) => {
  return Object.keys(thumbs).map((key) => {
    return { [key]: thumbs[key] };
  });
};

const checkToRenderThumbSize = (sizeText, sizeIndex) => {
  let size;
  if (parseFloat(sizeText) < 5 || sizeIndex === '1') {
    size = 'small';
  } else if ((parseFloat(sizeText) > 5 && parseFloat(sizeText) < 11) || sizeIndex === '2') {
    size = 'medium';
  } else if (parseFloat(sizeText) >= 11 || sizeIndex === '3') {
    size = 'large';
  }
  return size;
};

const checkToSetInitialSize = (state) => {
  const SMALL = 'small';
  const MEDIUM = 'medium';
  const LARGE = 'large';
  let activeSizeIndex;
  let currentThumbSize;
  Object.keys(state).forEach((key) => {
    if (
      state[key] !== null &&
      (key.includes(SMALL) || key.includes(LARGE) || key.includes(MEDIUM))
    ) {
      if (key === SMALL) {
        activeSizeIndex = '1';
        currentThumbSize = SMALL;
      } else if (key === MEDIUM) {
        activeSizeIndex = '2';
        currentThumbSize = MEDIUM;
      } else if (key === LARGE) {
        activeSizeIndex = '3';
        currentThumbSize = LARGE;
      }
    }
  });
  return { activeSizeIndex, currentThumbSize };
};

module.exports = {
  setRecipeIcon,
  waitForState,
  getCorrectImgSrc,
  checkToRenderThumbSize,
  checkToSetInitialSize,
  convertThumbImgToArr,
};
