import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';

export default function Title(props) {
  const { pet, foodType, title, icon } = props;
  return (
    <div className="product-main__heading">
      <MDBRow>
        <MDBCol size="10" sm="12">
          {/*<span className="product-main__span--title-adjustment futura-bold-font text-uppercase">
          </span>*/}
          <h1 className="product-main__title product-main__title--margin-top-adjustment LemonMilkbold-font text-upercase" style={{fontSize: 25}}>
          {pet + ' ' + foodType + ' ' + title}
          </h1>
        </MDBCol>
        <MDBCol size="2">
          <div className="img-fluid product-main__icon-img"></div>
        </MDBCol>
      </MDBRow>
    </div>
  );
}
