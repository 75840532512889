import React, { Component } from 'react';
import {
  MDBRow,
  MDBCol,
  MDBTabContent,
  MDBTabPane,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBAnimation,
} from 'mdbreact';

import Ingredients from './Ingredients/Ingredients.jsx';
import FeedingGuidelines from './FeedingGuideline/FeedingGuidelines.jsx';
import Analysis from './Analysis.jsx';
import Nutrients from './Nutrients.jsx';

const { lightOrDarkBg } = require('../../helper');

export default class InfoTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: '',
    };
  }

  componentDidMount() {
    this.setState({ activeItem: '1' });
  }

  toggleTab = (tab) => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
    }
  };

  changeBgAndTxtColor = (bgType, color) => {
    if (bgType === 'dark') {
      return {
        backgroundColor: color,
        color: lightOrDarkBg(color) === 'dark' ? '#fff' : '#212529',
      };
    }
    return {
      backgroundColor: '#c8c8c8',
      color: '#212529',
    };
  };

  render() {
    const { activeItem } = this.state;

    const {
      color,
      ingredient,
      feedingGuidelines,
      analysis,
      asFed,
      pet,
      dryMatter,
      calories,
      size,
      foodType,
      name,
    } = this.props;

    const tabNameList = [
      'Ingredients',
      'Feeding Guidelines',
      'Guaranteed Analysis',
      'Detailed Nutrients',
    ];

    return (
      <div className="product-main__info-tabs">
        <MDBRow>
          <MDBCol size="12" lg="10">
            <MDBNav className="nav-tabs mt-5">
              {tabNameList.map((tabName, i) => (
                <MDBCol key={i} size="12" sm="3" className="px-0">
                  <MDBNavItem>
                    <MDBNavLink
                      className="product-main__tab-links text-center font-weight-bolder futura-font product-main--no-highlight"
                      to={`#${tabName}`}
                      active={activeItem === String(i + 1)}
                      style={
                        activeItem === String(i + 1)
                          ? this.changeBgAndTxtColor('dark', color)
                          : this.changeBgAndTxtColor('light', color)
                      }
                      onClick={() => this.toggleTab(String(i + 1))}
                      role="tab">
                      {tabName}
                    </MDBNavLink>
                  </MDBNavItem>
                </MDBCol>
              ))}
            </MDBNav>
          </MDBCol>
        </MDBRow>
        <MDBTabContent activeItem={activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            <MDBAnimation type="fadeIn">
              <Ingredients petType={pet} foodType={foodType} ingredient={ingredient} />
            </MDBAnimation>
          </MDBTabPane>
          <MDBTabPane tabId="2" role="tabpanel">
            <MDBAnimation type="fadeIn">
              <FeedingGuidelines
                pet={pet}
                foodType={foodType}
                color={color}
                size={size}
                feedingGuidelines={feedingGuidelines}
                name={name}
              />
            </MDBAnimation>
          </MDBTabPane>
          <MDBTabPane tabId="3" role="tabpanel">
            <MDBAnimation type="fadeIn">
              <Analysis pet={pet} analysis={analysis} />
            </MDBAnimation>
          </MDBTabPane>
          <MDBTabPane tabId="4" role="tabpanel">
            <MDBAnimation type="fadeIn">
              <Nutrients asFed={asFed} dryMatter={dryMatter} calories={calories} />
            </MDBAnimation>
          </MDBTabPane>
        </MDBTabContent>
      </div>
    );
  }
}
