import React from 'react';
import { Link } from 'react-router-dom';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import StarRatingComponent from 'react-star-rating-component';
import Slider from 'react-slick';
import NewsLetterSub from '@Containers/NewsletterSub/newsLetterSub';
import TopCarousel from '../Carousel/Carousel';
import PopUp from '../PopUp/PopUp';
import IsLoadingChild from '../../IsLoading/IsLoadingChild';
import Update from '../PopUp/Update';
import RawCarousel from '../Carousel/RawCarousel';
import CustomerReview from '../CustomerReview/CustomerReview';
//import BlogSection from '../BlogSection/BlogSection';
//import IntroSection from '../IntroSection/IntroSection';

const reviews = [
  {
    first_name: 'Prince',
    last_name: 'George',
    review: `Prince George is part Dalmatian, part white Lab, a mixed breed and we were lucky enough to rescue the crowned Prince from a shelter and give him a loving home.

    Once upon a time Prince George’s mom & dad was very sad as after about 8 months of eating food from the big blue bag, he developed terrible diarrhea daily and finally stopped eating as he could no longer tolerate it for some strange reason.
    After trying many alternate kinds of their food, the folks who make the big blue bag of food sadly had come to the conclusion that they no longer had a food that would be suitable for the healthy strapping growing Prince who had stopped eating their foods and none of the foods that Mom & Dad were bringing home seemed to satisfy the royal Prince’s appetite. Mom and Dad tried everything from Grain-Free to canned and everything in between and the Prince would lick it, smell it and turn his nose up at it. :-(

    The Prince had become a very finicky eater and was not eating enough for him to develop normally, So Mom & Dad knew they had to do something because they knew how important it was for their young growing Prince to get a wholesome meal so he could grow up to be big and strong enough to run and carry around a ball on his own.
    One day the Vet suggested that Mom & Dad try going to the doggy boutique where royalty shops and came home with at least a dozen bags of food fit for royalty and let him decide. So Mom & Dad brought home a bunch of trial bags for Prince George. Allowing the finicky little Prince to pick out his own food.

    At first Prince George didn’t know what to make of it when he first saw all these food bowls, Oh my he thought, this could be a lot of fun!
    After about 30 minutes of licking and smelling all the bowls (apparently the young Prince knew the secret to releasing all the flavor in the food was to lick it first and then sniff it to see if the food was really worthy of royalty) he narrowed his choices down to 3 bowls that he kept coming back to lick and sniff.
    He still hadn’t even tasted the foods yet as he first had to make sure that they were truly worthy to be consumed by royalty.
    He then finally went back to his final 3 choices and started nibbling at each one going from bowl to bowl and then back for a second tasting. After another 10 minutes he finally decided on a Winner and polished off the entire bowl! The choice of the Prince was (drum roll) Lotus Chicken Adult Kibble, truly food fit for royalty…as it had passed the lick and sniff tests to beat out all others!

    Fast forward to today, 62lbs and 4 years later. Prince George has grown into a handsome muscular healthy ball player and still has a ravenous appetite for his favorite and still ONLY CHOICE for royalty, Lotus Chicken Adult Kibble!
    …and so the crowned Prince has finally found his true love of foods and is living happily ever after thanks to the folks at Lotus!

    Prince George, Mom & Dad send a huge shout out of thanks to the loving folks at Lotus for making a recipe fit for a king by creating a healthy delectable alternative food filled with TLC that can truly satisfy even the finickiest of royalty!
    `,
    review_date: '04/8/2019',
    stars: 5,
    avatar: 'null',
    product: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Landing+Page/dc-clear.png',
    link: '/dog-food/regular-bites/chicken-adult',
  },
  {
    first_name: 'Susan',
    last_name: 'Lowe',
    review:
      'At best trying to find food with more protein than grains has been ongoing search for a while now. My cat Gonzo has been eating this food and the Chicken flavor for about 3 weeks now. The cat really loves it! He eats less, his coat is more shinny, less dander and we think he has even lost a pound (nice benefit). He seems to be more playful and active also. I would definitely continue using this product and recommend it to other cats, the are carnivorous animals from the beginning, why feed them grains when they crave protein!',
    review_date: '04/5/2019',
    stars: 5,
    avatar: 'null',
    product: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Landing+Page/cs-clear.png',
    link: '/cat-food/kibble/sardine',
  },
  {
    first_name: 'Becky',
    last_name: 'Herzberg',
    review:
      'I switched to Lotus cat food about 6 months ago. My cats immediately accepted the food! Since, they have been more energetic, noticeably talkative, and they are more than expressive when it comes meal time!! Also, I have noticed my 2 cats have slowly been getting back to a healthier weight. They love it and I love that they love it!',
    review_date: '03/3/2019',
    stars: 5,
    avatar: 'null',
    product: 'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Landing+Page/cc-clear.png',
    link: '/cat-food/kibble/chicken',
  },
];

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopUp: false,
      selected: 0,
      isDesktop: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.removeEventListener('resize', this.updatePredicate);

    if (window.innerWidth > 768) {
      setTimeout(() => {
        this.setState({
          showPopUp: true,
        });
      }, 1000);
    }

    this.updatePredicate();
    window.addEventListener('resize', this.updatePredicate);
  }

  updatePredicate = () => {
    this.setState({ isDesktop: window.innerWidth > 768 });
  };

  convertDate = (date) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const month = months[date.substring(0, 2) - 1];
    let day = date.substring(3, 5);
    const year = date.slice(-4);

    if (day[0] === '0') {
      [, day] = day;
    } else if (day === '/') {
      [day] = day;
    }

    return `${month} ${day}, ${year}`;
  };

  render() {
    const sliderSetting2 = {
      dots: false,
      infinite: true,
      speed: 250,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
    };
    return (
      <div>
        <div id="carousel">
          <TopCarousel />
        </div>

        <div className="container container-lotus-width">
          <div className="lotus-raw-cat" id="lotus-raw">
            <div className="row">
              <div className="col-12">
                <Link
                  className="pointer lotus-raw-cat__anchor-tag overflow-hidden"
                  to="/overview/cat/raw-food">
                  <img
                    className="img-fluid lotus-raw-cat__img image-zoom-effect"
                    src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/rawforcat.jpg"
                    alt="Lotus Raw for Cats. Food quality not Food Quantity. Click Here to tell me more."
                  />
                </Link>
              </div>
            </div>
            <div className="row">
              <RawCarousel />
            </div>
          </div>
        </div>
        <div className="soft-bake-treat-banner overflow-hidden d-flex justify-content-center">
          <Link className="pointer w-100" to="/overview/dog/treats">
            <img
              className="soft-bake-treat__img img-fluid image-zoom-effect w-100"
              src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/oct-banner/treats-banner.jpg"
              alt="Soft Baked Treats banner with Description about Oven-baking means less processed, has less starch, has more fresh meats, fish, and poultry than most other kibbles, and hey, it just plain tastes better."
            />
          </Link>
        </div>

        {/*Customer Review Slider*/}
        <CustomerReview />

        <div className="cans-banner-container text-center hide-newsletter-mobile">
          <div className="container container-lotus-width">
            <MDBCol size="12" className="subBg-title">
              <p className="text-uppercase text-dark p_title_three">
                SIGN UP MEOW & SAVE FURREVER
              </p>
            </MDBCol>
            <div className="subBg-sub">
              <MDBCol size="12" md="6">
                <div className="p_title text-left">JOIN OUR MAILING LIST &</div>
                <p className="text-uppercase p_title_two text-left mt-2 mb-3">
                  get $5 off your next purchase
                </p>
                <p className="popup__p popup__p--margin-bottom text-left">
                  Plus monthly deals, new product info & tips for keeping your pet happy and healthy.
                </p>
                <NewsLetterSub outline={false} acceptAll={true} enableBtn={true} />
                <p className="p_title_five-mobile text-left">
                  By signing up, you are accepting our <a href="https://www.lotuspetfoods.com/terms-and-conditions" target="_blank">terms and conditions</a>
                </p>
              </MDBCol>
            </div>
          </div>
        </div>

        {/*Mobile Newsletter*/}
        <div className="cans-banner-container text-center hide-newsletter-desktop newsletter-container-mobile">
          <div className="container container-lotus-width">
            <div className="subBg-sub-mobile">
              <MDBCol size="12" md="6" style={{paddingRight: 0}}>
                <p className="text-uppercase text-dark p_title_three-mobile">
                SIGN UP MEOW & SAVE FURREVER
                </p>
                <div className="p_title-mobile text-left">JOIN OUR MAILING LIST &</div>
                <p className="text-uppercase p_title_two-mobile text-left mt-2 mb-3">
                  get $5 off your next purchase
                </p>
                <p className="popup__p popup__p--margin-bottom text-left p_title_four-mobile">
                  Plus monthly deals, new product info & tips <br></br>for keeping your pet happy and healthy.
                </p>
                <p className="p_title_five-mobile text-left">
                  By signing up, you are accepting our <a href="https://www.lotuspetfoods.com/terms-and-conditions" target="_blank">terms and conditions</a>
                </p>
                <NewsLetterSub outline={false} acceptAll={true} enableBtn={true} />
                <div className="overflow-hidden text-center">
                  <img
                    className="img-fluid"
                    src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/signup-coupon-image.png'}
                    width="100%"
                  />
                </div>
              </MDBCol>
            </div>
          </div>
        </div>

        <Update />

        {/*IntroSection
        <div className="futura-font">
        <IntroSection />
        </div>*/}

        {/*BlogSection
        <div className="container futura-font">
        <BlogSection />
        </div>*/}

      </div>
    );
  }
}


export default LandingPage;
