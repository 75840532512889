import React, { Fragment } from 'react';
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';

import Rating from './Rating.jsx';

const { renderReviewImages } = require('./helper');

export default function CustomerReview(props) {
  return (
    <MDBRow className="product-main__customer-review my-3">
      {props.reviews.map((productReview, i) => {
        if (!productReview.approved) return null;
        const { title, review, rating, pictures } = productReview;
        return (
          <MDBCol className="my-3" size="12" key={i}>
            <MDBRow>
              <MDBCol size="12">
                <div className="customer-review__title futura-font font-weight-bolder">{title}</div>
              </MDBCol>
              <MDBCol size="12">
                <Rating numsOfRating={' '} starsRating={rating} />
              </MDBCol>
              <MDBCol size="12">
                <p className="customer-review__p futura-font">{review}</p>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-3">
              <Fragment>
                {renderReviewImages(pictures) &&
                  renderReviewImages(pictures).map((picture, i) => {
                    return (
                      <MDBCol key={i} size="4">
                        <img className="img-fluid" src={picture} />
                      </MDBCol>
                    );
                  })}
              </Fragment>
            </MDBRow>
          </MDBCol>
        );
      })}
    </MDBRow>
  );
}
