import React from 'react';
import { MDBCol } from 'mdbreact';

export default function DogRawBrochure(props) {
  return (
    <MDBCol size="12" md="6" className="my-sm-1 my-4">
      <div className="row brochure--screen-alignment align-items-center ">
        <MDBCol size="8" md="6">
          <a
            style={{ marginLeft: '20px' }}
            data-tooltip-position="top"
            data-tooltip="May take up to 10 seconds to load."
            className="no-underline"
            href="https://www.flipsnack.com/69AA9ADEFB5/raw-dog-food-_-lotus.html"
            rel="noopener noreferrer"
            target="_blank">
            <img
              className="img-fluid cat-raw-hover brochure--larger-img"
              style={{ marginTop: '25px' }}
              src={
                'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Dog+Food/raw/raw-page-bg/brouchure.png'
              }
              alt="Lotus cat raw food brochure. Links to another website to view PDF Brochure."
            />
          </a>
        </MDBCol>
        <MDBCol size="4" className="brochure__arrow--adjustment">
          <img
            className="img-fluid cat-raw-hover brochure--larger-img"
            style={{ marginTop: '25px', marginLeft: '30px' }}
            src={
              'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/brochure/view-our-brochure.png'
            }
            alt=""
          />
        </MDBCol>
      </div>
    </MDBCol>
  );
}
