import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class MistakeBuyFood extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
      }

  render() {
    return (
        <div>
            <div className="pb-3 blog-margin-adjustment-desktop">
            <MDBAnimation type="fadeIn">
                <div>
                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/Buyingpetfood-banner.jpg'} 
                    className="banner img-fluid w-100" 
                    alt="lotus Pet Foods - Mistake when Buying Pet Food"/>
                </div>
            </MDBAnimation>
            </div>

            <div className='container mt-5'>
                <p style={{"color": "grey"}}>Feb, 01, 2022</p>
            </div>
       
            <div className='container mt-5 text-center d-none-mobile'>
                <h1><span className="LemonMilkbold-font blog-hb">Mistake Pet Owners Make When Buying Pet Food</span></h1>
            </div>

            <div className='container mt-5 text-center d-none-desktop'>
                <h1><span className="LemonMilkbold-font blog-h">Mistake Pet Owners Make When Buying Pet Food</span></h1>
            </div>
  
            <div className='container d-none-mobile' style={{"marginTop": "60px"}}>
                <div className='mb-5 futura-font blog-pb'>
                    <MDBRow className="d-flex align-items-center">
                        <MDBCol xs="1" sm="1" md="1">
                            <MDBAnimation type="fadeIn">
                                <div>
                                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/checkmark.jpg'} 
                                    className="img-fluid"
                                    width="60%" 
                                    alt="lotus Pet Foods - Mistake when Buying Pet Food"/>
                                </div>
                            </MDBAnimation>
                        </MDBCol>
                        <MDBCol xs="11" sm="11" md="11">
                            <p>Not Researching the company background</p>
                        </MDBCol>
                    </MDBRow>
                    <br />
                    <MDBRow className="d-flex align-items-center">
                        <MDBCol xs="1" sm="1" md="1">
                            <MDBAnimation type="fadeIn">
                                <div>
                                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/checkmark.jpg'} 
                                    className="img-fluid"
                                    width="60%" 
                                    alt="lotus Pet Foods - Mistake when Buying Pet Food"/>
                                </div>
                            </MDBAnimation>
                        </MDBCol>
                        <MDBCol xs="11" sm="11" md="11">
                            <p>Have they ever had any recalls?</p>
                        </MDBCol>
                    </MDBRow>
                    <br />
                    <MDBRow className="d-flex align-items-center">
                        <MDBCol xs="1" sm="1" md="1">
                            <MDBAnimation type="fadeIn">
                                <div>
                                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/checkmark.jpg'} 
                                    className="img-fluid"
                                    width="60%" 
                                    alt="lotus Pet Foods - Mistake when Buying Pet Food"/>
                                </div>
                            </MDBAnimation>
                        </MDBCol>
                        <MDBCol xs="11" sm="11" md="11">
                            <p>Not reading the ingredient label</p>
                        </MDBCol>
                    </MDBRow>
                    <br />
                    <MDBRow className="d-flex align-items-center">
                        <MDBCol xs="1" sm="1" md="1">
                            <MDBAnimation type="fadeIn">
                                <div>
                                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/checkmark.jpg'} 
                                    className="img-fluid"
                                    width="60%" 
                                    alt="lotus Pet Foods - Mistake when Buying Pet Food"/>
                                </div>
                            </MDBAnimation>
                        </MDBCol>
                        <MDBCol xs="11" sm="11" md="11">
                            <p>Are ingredients quality, nutritious, or fillers</p>
                        </MDBCol>
                    </MDBRow>
                    <br />
                    <MDBRow className="d-flex align-items-center">
                        <MDBCol xs="1" sm="1" md="1">
                            <MDBAnimation type="fadeIn">
                                <div>
                                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/checkmark.jpg'} 
                                    className="img-fluid"
                                    width="60%" 
                                    alt="lotus Pet Foods - Mistake when Buying Pet Food"/>
                                </div>
                            </MDBAnimation>
                        </MDBCol>
                        <MDBCol xs="11" sm="11" md="11">
                            <p>Not knowing WHERE the ingredients are sourced from</p>
                        </MDBCol>
                    </MDBRow>
                    <br />
                    <MDBRow className="d-flex align-items-center">
                        <MDBCol xs="1" sm="1" md="1">
                            <MDBAnimation type="fadeIn">
                                <div>
                                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/checkmark.jpg'} 
                                    className="img-fluid"
                                    width="60%" 
                                    alt="lotus Pet Foods - Mistake when Buying Pet Food"/>
                                </div>
                            </MDBAnimation>
                        </MDBCol>
                        <MDBCol xs="11" sm="11" md="11">
                            <p>Not rotating proteins and/or adding in canned or fresh toppers</p>
                        </MDBCol>
                    </MDBRow>
                    <br />              
                </div>
             </div>

             <div className='container d-none-desktop' style={{"marginTop": "60px"}}>
                <div className='mb-5 futura-font blog-p'>
                    <ul>
                        <li>Not Researching the company background</li>
                        <li>Have they ever had any recalls?</li>
                        <li>Not reading the ingredient label</li>
                        <li>Are ingredients quality, nutritious, or fillers</li>
                        <li>Not knowing WHERE the ingredients are sourced from</li>
                        <li>Not rotating proteins and/or adding in canned or fresh toppers</li>
                    </ul>
                </div>
             </div>

            <div className='container text-center d-none-mobile'>
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/food-icons2.jpg'} 
                        className="img-fluid"
                        
                        alt="lotus Pet Foods - icon"/>
                    </div>
                </MDBAnimation>
            </div>

            <div className='container text-center d-none-desktop'>
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/food-icons2.jpg'} 
                        className="img-fluid"
                        width="50%"
                        alt="lotus Pet Foods - icon"/>
                    </div>
                </MDBAnimation>
            </div>

            <div className='container mt-5 text-center d-none-mobile'>
                <h1><span className="LemonMilkbold-font blog-hb">HERE AT LOTUS PET FOODS</span></h1>
            </div>

            <div className='container mt-5 text-center d-none-desktop'>
                <h1><span className="LemonMilkbold-font blog-h">HERE AT LOTUS PET FOODS</span></h1>
            </div>

            <div className='container text-center d-none-mobile'>
                <p><span className="blog-pb futura-font">Our priorities are:</span></p>
            </div>

            <div className='container text-center d-none-desktop'>
                <p><span className="blog-p futura-font">Our priorities are:</span></p>
            </div>

            <div className='container text-center mt-5'>
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/food-icons.jpg'} 
                        className="img-fluid"
                        
                        alt="lotus Pet Foods - icon"/>
                    </div>
                </MDBAnimation>
            </div>

        </div>
    );
  }
}

export default MistakeBuyFood;