import React from 'react';
import { Redirect, Link } from 'react-router-dom';

// Raw Food Components
import RawVariety from './RawFood/RawVariety/RawVariety.jsx';

const RawFoodsComponent = (props) => {
  const { type } = props.match.params;
  const { history } = props;

  const redirect = () => history.push('/cat-food');
  const redirectForNonExistingProduct = () => {
    setTimeout(() => {
      redirect();
    }, 5000);
  };
  const emptyProduct = (
    <div style={{ marginTop: '200px' }} clasName="container">
      <h1>
        Sorry, this Product is currently not Available. <br /> Please see one our other selections.
      </h1>
      <span>You will redirect in 5 seconds. Or you can click the button to go back.</span>
      <Link to="/cat-food">
        <button>See Other Products</button>
      </Link>
    </div>
  );

  const checkToRenderComponent = (component) => {
    switch (component) {
      case 'variety':
        return <RawVariety />;
      default:
        return emptyProduct;
    }
  };
  return <div className="container">{checkToRenderComponent(type)}</div>;
};

export default RawFoodsComponent;
