import React from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';

const { lightOrDarkBg } = require('../../helper');

export default function Size(props) {
  return (
    <div className="product-main__size-selector my-4">
      <MDBRow>
        <MDBCol>
          <p className="product-main__text product-main__text--size-font-adjustment futura-font">
            Available Size:
          </p>
          {props.sizes.map((size, i) => {
            return (
              <button
                className={`btn product-main__size-btn Ripple-parent product-main__size-btn--size-text-adjustment futura-font ${
                  props.activeSizeIndex === String(i + 1)
                    ? 'product-main--highlight text-dark'
                    : `product-main--no-highlight ${
                        lightOrDarkBg(props.color) === 'dark' ? 'white-text' : 'text-dark'
                      }`
                } ml-0`}
                id={props.sizes.length < 2 ? i + 3 : i + 1}
                key={i}
                style={{ backgroundColor: props.color }}
                color={props.color}
                onClick={props.changeSize}
                data-test="button"
                type="button">
                {size}
              </button>
            );
          })}
        </MDBCol>
      </MDBRow>
    </div>
  );
}
