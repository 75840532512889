import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';

export default function MainImg(props) {
  return (
    <MDBCol className="d-flex justify-content-end align-items-center">
      <img
        onClick={props.openLightBox}
        className="img-fluid shop-item-main__main-img"
        src={props.mainImg}
      />
    </MDBCol>
  );
}
