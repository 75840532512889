import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class Transition extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
        <div>
            <div className="pb-3 blog-margin-adjustment-desktop">
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/transition-banner-blog.jpg'} 
                        className="banner img-fluid w-100" 
                        alt="Food Transition"/>
                    </div>
                </MDBAnimation>
            </div>

            <div className='container mt-5'>
                <p style={{"color": "grey"}}>Aug, 01, 2022</p>
            </div>

            <div className='container mt-5 text-center d-none-mobile'>
                <h1><span className="LemonMilkbold-font blog-hb">Transitioning your pet to Lotus pet food</span></h1>
            </div>

            <div className='container mt-5 text-center d-none-desktop'>
                <h1><span className="LemonMilkbold-font blog-h">Transitioning your pet to Lotus pet food</span></h1>
            </div>

            <div className='container mb-5 futura-font text-center'>
                    <p className="transition-font">Transitioning your pet to another food brand can seem like a lot of work - but with these tips, you'll transition your dog or cat over to Lotus with ease.</p>
                    <br />
                    <br />
                    <h3>Dog Kibble:</h3>
                    <p className="transition-font">Transitioning from kibble to Lotus kibble will be the easiest transition for you and your dog. Depending on dogs sensitivity, it takes between 7-10 days to 
                    gradually switch. Some dogs don't need as long and others may need up to 14 days. </p>
                <div className="mb-5">
                    <MDBAnimation type="fadeIn">
                        <div className="text-center">
                            <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/transition-imagesw-01.jpg'} 
                            className="img-fluid" 
                            alt="Lotus Pet Foods Food Transition"
                            />
                        </div>
                    </MDBAnimation>
                </div>
            </div>
            <br />
            <br />
            <div className="mb-5">
                <MDBAnimation type="fadeIn">
                    <div className="text-center">
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/transition-imagesw-02.jpg'} 
                        className="img-fluid" 
                        width="60%"
                        alt="Lotus Pet Foods - Cats can be a little more finicky so we recommend a 14 day transition for cats at minimum."
                        />
                    </div>
                </MDBAnimation>
            </div>
            <div className='container mb-5 futura-font text-center'>
                    <p className="transition-font">Start by putting the new food in a bowl next to the cats feeding area so they can get used to the smell. From there you can start mixing in a few pieces, and gradually add more and more each day until you have completely stopped the old food and have successfully transitioned your cat to Lotus oven baked!</p>
            </div>
            <div className="mb-5">
                <MDBAnimation type="fadeIn">
                    <div className="text-center">
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/line-01.png'} 
                        className="img-fluid" 
                        width="60%"
                        />
                    </div>
                </MDBAnimation>
            </div>
            <div className="mb-5">
                <MDBAnimation type="fadeIn">
                    <div className="text-center">
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/transition-imagesw-03.jpg'} 
                        className="img-fluid" 
                        width="60%"
                        alt="Lotus Pet Foods - food tranisition"
                        />
                    </div>
                </MDBAnimation>
            </div>
            <div className='container mb-5 futura-font text-center'>
                    <h3>Transitioning from Kibble to Canned or Raw</h3>
                    <p className="transition-font">Dogs, start by adding in a table spoon of the new canned or raw food, and take away 25% of the old kibble. Please utilize the feeding guidelines on the can and adjust to your dogs needs – keeping in mind canned or raw food can be higher in calories than kibble. Take 7-10 days to gradually start adding in more canned or raw food, while you take away the kibble. If your dog is picky, it may take longer, and may take some tricks!
                    Consistency is key when looking to switch your pet onto a new, healthier food.</p>
            </div>
            {/*Need Replacement*/}
            <div className="mb-5">
                <MDBAnimation type="fadeIn">
                    <div className="text-center">
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/transition-imagesw-04.jpg'} 
                        className="img-fluid" 
                        width="60%"
                        alt="Lotus Pet Foods - Cats, you will really need to be sneaky, especially if they are really addicted to their dry crunchies."
                        />
                    </div>
                </MDBAnimation>
            </div>
            <div className='container mb-5 futura-font text-center'>
                    <p className="transition-font">Similarly, to the kibble transition, you will want to start by placing a small amount of the canned or raw food near their feeding area so they can get used to the smell. It’s recommended to put the new canned or raw food underneath your cats kibble to start. Once your cat is okay with the idea of the new food you can start taking more kibble away, adding in more canned or raw food. Utilize feeding guidelines or kcal count to
                    feed the appropriate amount of food.</p>
                    <br />
                    <p className="transition-font">Please keep in mind raw or canned food must be consumed within 1 hour of placing
                    the food down, feed accordingly.</p>
                    <br />
                    <p className="transition-font">With any change, consistency is key. If your dog or cat are turning their nose up at the new food – keep trying! Our canned and raw foods can be in the refrigerator for 3 days, and can be warmed during the transition period to room temperature, please do not use a microwave to warm any pet food.</p>
                    <br />
                    <p className="transition-font">For any other inquiries contact us at <a href="https://www.lotuspetfoods.com/contact-us" target="_blank">info@lotuspetfoods.com</a></p>
            </div>

        </div>
    );
  }
}

export default Transition;