import React from 'react';
import YouTube from 'react-youtube';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact';

const helper = require('./helper');

export default function DogRawAddition(props) {
  return (
    <div className="product-overview__dog-raw-addition dog-raw-addition__bg my-3">
      <MDBContainer>
        <MDBRow>
          <MDBCol className="cat-raw-addition__video-wrapper">
            <MDBAnimation type="fadeIn" delay="1s" duration="500">
                <YouTube
                className="cat-raw-addition__youtube-vid"
                //opts={youtubeOpts}
                videoId='OWb6dnRSgI0'
                />
            </MDBAnimation>
          </MDBCol>
        </MDBRow>
        <MDBRow between className="my-5">
          {helper.infoText.map((info, i) => (
            <MDBCol key={i} size="12" sm="4" className="d-flex justify-content-center">
              <img
                src={info.src}
                alt={info.alt}
                className="img-fluid dog-raw-addition__info-img my-3"
              />
            </MDBCol>
          ))}
        </MDBRow>

        <MDBRow>
          <MDBCol className="dog-raw-addition__content-wrapper">
            <MDBContainer className="dog-raw-addition__container-bg">
              {/* pet wellness section */}
              <div className="dog-raw-addition__pet-wellness my-3">
                <MDBRow>
                  <MDBCol>
                    <h2 className="dog-raw-addition__header2 font-weight-bold">
                      The first step to pet wellness
                    </h2>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12" sm="5">
                    <p className="dog-raw-addition__p">
                      What's better than Banked Dog food? A raw diet dog food. That's why we made a
                      raw diet for dogs. With whole chicken, turkey thighs, beef check tenders,
                      whole cuts of lamb and venison trim. Lotus Raw Dog recipes food provide the
                      best nutrition for your four legged K9 friend. Here in our own Southern
                      California craft raw-ery.
                    </p>
                  </MDBCol>
                  <MDBCol size="12" sm="5">
                    <p className="dog-raw-addition__p">
                      These are real cuts of USDA Insepcted and Passed and New Zealand Ministery
                      Inspected and Passed meats and poultry, ground and filled locally in small
                      batches by our own staff. And our cuts of meat and poultry are of course,
                      hormone, antibiotic free and vegeterian fed.
                    </p>
                  </MDBCol>
                </MDBRow>
              </div>
              {/* End of pet wellness section */}

              {/* Essential Supplement section */}
              <div className="dog-raw-addition__essential-sups my-3">
                <MDBRow>
                  <MDBCol>
                    <h2 className="dog-raw-addition__header2 font-weight-bold">
                      Essential Supplements
                    </h2>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12" sm="6">
                    <p className="dog-raw-addition__p dog-raw-addition__p--essential-sups-txt-adj font-weight-lighter">
                      The recipes do not have fruits and vegetables, or ground bone so we can
                      specifically forumlate the recipes to meet the exact nutritional needs of your
                      dog. Liver and Hearts in all recipes for Taurine and Vitamin A. Psyllium Husk
                      promotes regularity and overall disgestive health. A good source of insoluble
                      fiber are from miscanthus grass. All ingredients are non-GMO, hormone and
                      antibiotic free and made in our Lotus Rawery in Torrance, California. Made
                      with lots of love and incompressiable quality. <br />
                      Food quality, not quantity.
                    </p>
                  </MDBCol>
                </MDBRow>
              </div>
              {/* End of Essential Supplement section */}

              {/* whats inside */}
              <div className="dog-raw-addition__whats-inside my-3">
                <MDBRow>
                  <MDBCol>
                    <MDBRow>
                      <MDBCol>
                        <h2 className="dog-raw-addition__header2 font-weight-bold">
                          What's Inside
                        </h2>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      {helper.whatsInsideInfo.map((info, i) => (
                        <MDBCol key={i} size="12" sm="3">
                          <img
                            src={info.icon}
                            className="img-fluid dog-raw-addition__whats-inside-icon my-3"
                          />
                          <h3 className="dog-raw-addition__header3 text-capitalize font-weight-bold">
                            {info.title}
                          </h3>
                          <p className="dog-raw-addition__p dog-raw-addition__p--info-text">
                            {info.text}
                          </p>
                        </MDBCol>
                      ))}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </div>
              {/* End of whats inside */}

              {/* Benefits */}
              <div className="dog-raw-addition__benefits my-3">
                <MDBRow>
                  <MDBCol>
                    <MDBRow>
                      <MDBCol>
                        <h2 className="dog-raw-addition__header2 font-weight-bold">Benefits</h2>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      {helper.benefitsInfo.map((info, i) => (
                        <MDBCol key={i} size="12" sm="4">
                          <img src={info.icon} className="img-fluid my-3" />
                          <h3 className="dog-raw-addition__header3 text-capitalize font-weight-bold">
                            {info.title}
                          </h3>
                          <p className="dog-raw-addition__p dog-raw-addition__p--info-text">
                            {info.text}
                          </p>
                        </MDBCol>
                      ))}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </div>
              {/* End of Benefits */}
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
