import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, MDBBtn } from 'mdbreact';
import Axios from 'axios';

import ProductOverview from './ProductOverview.jsx';
import ProductReviewInput from './ProductReviewInput.jsx';
import ProductImagesUpload from './ProductImagesUpload.jsx';

const { success, error, warning } = require('../_helper/SweetAlerts/alerts.jsx');
const { onChange, setToken } = require('../_helper/index.jsx');
const { getCorrectImgSrc, getFileUploadsVals, getS3Links } = require('./helper');
const {
  endPoint: { reviewEndPoint },
} = require('../_helper/stringVars');

export default class Review extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      imgSrc: '',
      product_id: 0,
      review: '',
      title: '',
      rating: 0,
      pictures: [],
      review_date: '',
      productName: '',
      imgCount: 0,
      approved: null,
      user_id: 0,
      user_email: ''
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.getProductImg();
  }

  getProductImg = () => {
    const { id } = this.props.match.params;

    Axios.get(`${reviewEndPoint.client}/get-review`, { headers: setToken('user'), params: { id } })
      .then((res) => {
        const { status, data } = res;
        if (status === 404) {
          return error({ title: 'Error', text: 'Cannot Request: 404', button: 'Okay' });
        }
        const imgSrc = getCorrectImgSrc(res.data);
        const user_id = JSON.parse(localStorage.getItem('user'))._id;

        const user_email = JSON.parse(localStorage.getItem('user')).email;

        this.setState({
          imgSrc,
          productName: data.name,
          product_id: parseInt(id, 10),
          user_id,
          user_email,
        });
      })
      .catch((err) => {
        console.error(err);
        return error({ title: 'Server Error', text: 'Internal Error: 500', button: 'Okay' });
      });
  };

  getUploadImgs = async (value) => {
    function createForm(value) {
      const form = new FormData();
      const pictures = getFileUploadsVals(value);
      pictures.forEach((picture, i) => {
        form.append(picture.name, picture.data);
      });
      return form;
    }

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const form = await createForm(value);

    Axios.post('/reviewupload', form, config)
      .then((res) => {
        const { imageUrl } = res.data;
        const pictures = getS3Links(imageUrl);
        this.setState({
          pictures,
        });
      })
      .catch((err) => {
        console.error(err);
        error({ title: 'Server Error', text: 'Please try again', button: 'Okay' });
      });
  };

  submitReview = (e) => {
    if (this.checkEmptyFields()) {
      e.preventDefault();
      const { product_id, review, title, rating, pictures, approved, user_id, user_email } = this.state;
      const date = new Date();
      let month;
      if (date.getMonth().toString().length === 1) {
        month = '0' + (date.getMonth() + 1);
      } else {
        month = date.getMonth() + 1;
      }
      const review_date = `${month}/${date.getDate()}/${date.getFullYear()}`;

      // eslint-disable-next-line no-underscore-dangle
      const newReview = {
        product_id,
        review,
        title,
        rating,
        pictures,
        review_date,
        approved,
        user_id,
        review_date,
        user_email
      };

      Axios.post(`${reviewEndPoint.client}/new-review`, newReview, { headers: setToken('user') })
        .then((res) => {
          const { status } = res;
          if (status === 201) {

            /*Post to back end subscribeController.js and to Mailchimp API*/
            /*const reviewerInfo = {
              email_address: user_email,
              status: 'subscribed'
            };
            const { data } = Axios.post('/client/subscribe/new-reviewer', {
              data: reviewerInfo,
            });
            const message = data;*/
            
            return success({
              title: 'Successfully Submitted Review',
              text: 'We have received your review. Thank you for your time!',
              button: 'Okay',
            });
          }
        })
        .catch((err) => {
          console.error(err);
          error({
            title: 'Error',
            text: 'Server Error. Cannot post review at this time',
            button: 'Okay',
          });
        });
    }
  };

  starRating = (nextVal, prevVal, name) => {
    this.setState({ rating: nextVal });
  };

  checkEmptyFields = () => {
    const { state } = this;
    if (state.rating < 1) {
      error({
        title: 'Missing Field',
        text: 'Please select a rating for the product',
        button: 'Okay',
      });
      return false;
    }
    if (state.review === '' || state.title === '') {
      error({
        title: 'Missing Field(s)',
        text: 'Title or review cannot be empty.',
        button: 'Okay',
      });
      return false;
    }

    return true;
  };

  render() {
    const { foodName } = this.props.match.params;
    const { state } = this;
    return (
      <MDBAnimation type="fadeIn">
        <MDBContainer className="margin-top-adjustment review">
          <MDBRow>
            <MDBCol size="12" className="text-left">
              <MDBRow className="mb-2">
                <MDBCol>
                  <h1 className="text-uppercase dk-font">Product Review For:</h1>
                </MDBCol>
              </MDBRow>
              {/* Includes row and col */}
              <ProductOverview
                imgSrc={state.imgSrc}
                rating={state.rating}
                productName={state.productName}
                starRating={this.starRating}
              />

              <div className="row page-divider about-us-divider my-3"></div>
              {/* Includes row and col */}
              <ProductReviewInput
                onChange={(e) => onChange(this, e)}
                title={state.title}
                review={state.review}
              />
              {/* Includes row and Col */}
              <ProductImagesUpload pictures={state.pictures} onChange={this.getUploadImgs} />

              <MDBRow>
                <MDBCol>
                  <MDBBtn onClick={this.submitReview} className="mx-0" color="warning">
                    Submit Review
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBAnimation>
    );
  }
}
