import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
import Axios from 'axios';
import swal from 'sweetalert2';

import SimpleInputField from '../../../../../FieldComponents/InputFields/InputField.jsx';
import ImgRender from '../../../../../ImgUploader/ImgRender.jsx';

const {
  onChange,
  setToken,
  tokenStrOnly,
  uploadImgToS3,
  getUploadImg,
} = require('../../../../../_helper/index.jsx');
const { handleErrors } = require('../../../../../_helper/reqsHandlers');
const { handleStoreStatus, correctImgRotation, validateFields } = require('../helper.jsx');
const {
  endPoint: { retailStores, filesUploads },
} = require('../../../../../_helper/stringVars');

export default class NewStore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      address1: '',
      suite: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      website_url: '',
      fileInfo: {},
      uploadedImgDataUrl: '',
      uploadedImgLoaded: false,
      imgLoader: false,
    };

    this.token = { headers: setToken('admin') };
  }

  // address ex 3809 N. Clark Street, Chicago, IL 60613
  // 53 Persimmon St., Ste. 104, Bluffton, SC 29910

  createNewStore = async (event) => {
    event.preventDefault();
    event.target.className += ' was-validated';

    const {
      address1,
      suite,
      city,
      state,
      zip,
      fileInfo,
      uploadedImgDataUrl,
      uploadedImgLoaded,
      imgLoader,
      ...storesInfo
    } = this.state;

    const address = `${address1} ${suite}, ${city}, ${state} ${zip}`;
    console.log({ address });
    if (validateFields(storesInfo)) {
      swal.fire({
        title: 'Loading...',
        text: 'Please wait while we create the store.',
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const { data } = await Axios.post(
          `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.GEOCODE_KEY}&address=${address}`,
        );
        const { lat, lng } = data.results[0].geometry.location;
        const picture_url =
          uploadedImgDataUrl !== '' ? await uploadImgToS3(fileInfo, 'store') : null;

        Object.assign(storesInfo, { picture_url, address, lat, lng });
        // post new Store information into site
        const { status } = await Axios.post(
          `${retailStores.admin}/new-store`,
          storesInfo,
          this.token,
        );
        if (status === 200) {
          handleStoreStatus({
            title: 'Store Exists!',
            text: 'Store already exists.',
            status: 200,
          });
        } else if (status === 201) {
          handleStoreStatus({
            title: 'Store created!',
            text: 'Successfully created new store. Page will refresh, please wait.',
            status: 201,
          });
          setTimeout(() => window.location.reload(), 3000);
        }
      } catch (err) {
        console.error(err);
        const { status } = err.response;
        if (status === 400) return handleErrors(400);
        if (status === 404) return handleErrors(404);
        if (status === 403) return handleErrors(403);
        handleErrors(500);
      }
    }
  };

  render() {
    const { state, props } = this;
    return (
      <form
        className="needs-validation d-flex justify-content-center"
        noValidate
        onSubmit={this.createNewStore}>
        <MDBRow className="admin-stores__new-store" center>
          <MDBCol size="12">
            <h1 className="admin-main__header2 futura-font">Add a new Store: </h1>
          </MDBCol>
          <MDBCol size="12" className="px-0">
            <SimpleInputField
              name="name"
              label="Store name"
              value={state.name}
              onChange={(e) => onChange(this, e)}
              required={true}
            />
          </MDBCol>
          <MDBCol size="12" sm="8" className="px-0">
            <SimpleInputField
              name="address1"
              label="Address"
              value={state.address1}
              onChange={(e) => onChange(this, e)}
              required={true}
            />
          </MDBCol>
          <MDBCol size="12" sm="4" className="px-0">
            <SimpleInputField
              name="suite"
              label="Suite Number"
              value={state.suite}
              onChange={(e) => onChange(this, e)}
            />
          </MDBCol>
          <MDBCol size="12" sm="6" className="px-0">
            <SimpleInputField
              name="city"
              label="City"
              value={state.city}
              onChange={(e) => onChange(this, e)}
              required={true}
            />
          </MDBCol>
          <MDBCol size="12" sm="3" className="px-0">
            <SimpleInputField
              name="state"
              label="State"
              value={state.state}
              onChange={(e) => onChange(this, e)}
              required={true}
            />
          </MDBCol>
          <MDBCol size="12" sm="3" className="px-0">
            <SimpleInputField
              name="zip"
              label="Zip Code"
              value={state.zip}
              onChange={(e) => onChange(this, e)}
              required={true}
            />
          </MDBCol>
          <MDBCol size="12" className="px-0">
            <SimpleInputField
              name="website_url"
              label="Website"
              value={state.website_url}
              onChange={(e) => onChange(this, e)}
            />
          </MDBCol>
          <MDBCol size="12" className="px-0">
            <SimpleInputField
              name="phone"
              label="Phone Number"
              value={state.phone}
              onChange={(e) => onChange(this, e)}
            />
          </MDBCol>

          <ImgRender
            getUploadImg={(value) => getUploadImg(this, value)}
            uploadedImgDataUrl={state.uploadedImgDataUrl}
            uploadedImgLoaded={state.uploadedImgLoaded}
            imgLoader={state.imgLoader}
          />

          <MDBCol size="12">
            <MDBBtn className="ml-0" type="submit" color="primary" size="12">
              Add New Store
              <MDBIcon icon="plus" className="ml-1 white-text" />
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </form>
    );
  }
}
