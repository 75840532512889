import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class Careers extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="product-overview__simple-overview mb-5">
            <MDBAnimation type="fadeIn">
                <div className="overview-product__hero-banner">
                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/lotus_career_banner.png'} className="banner img-fluid w-100" />
                </div>
            </MDBAnimation>
        </div>

        <div className='container career-body'>
          <div className='privacy-policy mb-5 futura-font'>
            <p>Our Employees are our most valuable casset, which is why we offer a comprehensive and competitive Total Rewards program.</p>
            <p>We invest in our Employees because we want to be a place where you love to work, and we have an opportunity culture where we 
            encourage you to come for a job and stay for a career. We truly care about our Employees, and strive to offer meaningful, 
            personalized benefits while helping individuals, families and communities thrive today and in the future.</p>
            <br />
            <p>Lotus Pet Foods  recognizes that our most important assets are our Employees, which is why we make every effort to recruit 
                and retain top talent. We provide salary and benefits packages competitive with those of leading firms nationwide.</p>
            <br />
            <ul>
                <li>Medical and Pharmacy benefits</li>
                <li>Dental benefits</li>
                <li>Vision benefits / eyewear coverage</li>
                <li>Telehealth</li>
                <li>Critical Illness, Accident, and Hospital Indemnity Insurance</li>
                <li>Basic Life /AD&D Insurance</li>
                <li>Employee Assistance Program</li>
                <li>Flexible Spending Accounts</li>
                <li>CalSavers Retirement Benefit Program</li>
                <li>Pet Insurance and Pet Discount Program</li>
                <li>30% Employee Discount</li>
            </ul>

            <div className="row pb-5 pt-3">
                <div className="col-md-3 col-sm-12 text-center">
                    <button type="submit" className="career-button"><a href="https://secure4.entertimeonline.com/ta/6141905.careers?CareersSearch" target="_blank">Career Opportunities</a></button>
                </div>
            </div>
            <br />
            <br />
            <div className="career-div">
            <h2>Transparency in Coverage</h2>
            <p>The Transparency in Coverage Final Rules require certain group health plans to disclose on a public website information regarding in-network 
                provider rates and historical out-of-network allowed amounts and billed charges for covered items and services in two separate machine-readable 
                files (MRFs). The MRFs for the benefit package options under the Group Insurance Plan for Employees and affiliates of Centinela Feed, Inc. are linked below:</p>
            <br />
            <p>The link to United Healthcare’s site is <a href="https://transparency-in-coverage.uhc.com" target="_blank">https://transparency-in-coverage.uhc.com/</a></p>
            <br />
            <p>CMS has a helpful fact sheet on the topic @ <a href="https://www.cms.gov/newsroom/fact-sheets/transparency-coverage-final-rule-fact-sheet-cms-9915-f" target="_blank">
            https://www.cms.gov/newsroom/fact-sheets/transparency-coverage-final-rule-fact-sheet-cms-9915-f</a></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Careers;