import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';

export default function ItemHeader(props) {
  return (
    <MDBCol>
      <h1 className="shop-item-main__header1 futura-bold-font">{props.name}</h1>
      <money className="shop-item-main__currency futura-font">Price: ${props.price}</money>
    </MDBCol>
  );
}
