import React, { Component } from 'react';
import { MDBRow, MDBCol } from 'mdbreact';

const { createAnswersTups } = require('./helper.jsx');

export default class QuestionsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      correctAnswer: '',
      answers: [],
    };
  }

  componentDidMount() {
    this.setAnswer();
  }

  componentDidUpdate(prevProps) {
    const { answers, correctAnswer } = this.props;
    if (answers !== prevProps.answers || correctAnswer !== prevProps.correctAnswer) {
      this.setAnswer();
    }
  }

  setAnswer = () => {
    const { answers, correctAnswer } = this.props;
    const answersArr = createAnswersTups(answers);
    this.setState({
      answers: answersArr,
      correctAnswer,
    });
  };

  setCorrectAnswer = (e) => {
    this.setState({
      correctAnswer: e.target.value,
    });
  };

  onChange = (e) => {
    this.setCorrectAnswer(e);
    this.props.changeAnswer(e, this.props.questionNum);
  }

  render() {
    const { answers, correctAnswer } = this.state;

    return answers.map((answer, i) => (
      <label key={i} className="admin-quizzes__label">
        <input
          className="admin-quizzes__radio-input mr-2"
          onChange={this.onChange}
          checked={correctAnswer === answer.letter}
          type="radio"
          value={answer.letter}
        />
        {answer.letter + '. ' + answer.text}
      </label>
    ));
  }
}
