import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import SwipePopUp from '../SwipePopUp';

class ChickenMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
      reviews: {
        adultRegular: {
          total: 0,
          stars: 0,
          average: 0
        },
        adultSmall: {
          total: 0,
          stars: 0,
          average: 0
        },
        puppyRegular: {
          total: 0,
          stars: 0,
          average: 0
        },
        puppySmall: {
          total: 0,
          stars: 0,
          average: 0
        },
        seniorRegular: {
          total: 0,
          stars: 0,
          average: 0
        },
        seniorSmall: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogStew: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogLoaf: {
          total: 0,
          stars: 0,
          average: 0
        },
        catKibble: {
          total: 0,
          stars: 0,
          average: 0
        },
        catLowKibble: {
          total: 0,
          stars: 0,
          average: 0
        },
        catPate: {
          total: 0,
          stars: 0,
          average: 0
        },
        catJuicy: {
          total: 0,
          stars: 0,
          average: 0
        }
      }
    };
    this.anotherProtein = this.anotherProtein.bind(this);
  }

  componentWillMount() {
    axios.post('/getallreviews').then(res => {
      const reviews = {
        adultRegular: {
          total: 0,
          stars: 0,
          average: 0
        },
        adultSmall: {
          total: 0,
          stars: 0,
          average: 0
        },
        puppyRegular: {
          total: 0,
          stars: 0,
          average: 0
        },
        puppySmall: {
          total: 0,
          stars: 0,
          average: 0
        },
        seniorRegular: {
          total: 0,
          stars: 0,
          average: 0
        },
        seniorSmall: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogStew: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogLoaf: {
          total: 0,
          stars: 0,
          average: 0
        },
        catKibble: {
          total: 0,
          stars: 0,
          average: 0
        },
        catLowKibble: {
          total: 0,
          stars: 0,
          average: 0
        },
        catPate: {
          total: 0,
          stars: 0,
          average: 0
        },
        catJuicy: {
          total: 0,
          stars: 0,
          average: 0
        }
      };

      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].product_id === 1) {
          reviews.adultRegular.total++;
          reviews.adultRegular.stars += res.data[i].stars;
          reviews.adultRegular.average = reviews.adultRegular.stars / reviews.adultRegular.total;
        }

        if (res.data[i].product_id === 2) {
          reviews.puppyRegular.total++;
          reviews.puppyRegular.stars += res.data[i].stars;
          reviews.puppyRegular.average = reviews.puppyRegular.stars / reviews.puppyRegular.total;
        }

        if (res.data[i].product_id === 3) {
          reviews.seniorRegular.total++;
          reviews.seniorRegular.stars += res.data[i].stars;
          reviews.seniorRegular.average = reviews.seniorRegular.stars / reviews.seniorRegular.total;
        }

        if (res.data[i].product_id === 8) {
          reviews.adultSmall.total++;
          reviews.adultSmall.stars += res.data[i].stars;
          reviews.adultSmall.average = reviews.adultSmall.stars / reviews.adultSmall.total;
        }

        if (res.data[i].product_id === 9) {
          reviews.puppySmall.total++;
          reviews.puppySmall.stars += res.data[i].stars;
          reviews.puppySmall.average = reviews.puppySmall.stars / reviews.puppySmall.total;
        }

        if (res.data[i].product_id === 10) {
          reviews.seniorSmall.total++;
          reviews.seniorSmall.stars += res.data[i].stars;
          reviews.seniorSmall.average = reviews.seniorSmall.stars / reviews.seniorSmall.total;
        }

        if (res.data[i].product_id === 21) {
          reviews.dogStew.total++;
          reviews.dogStew.stars += res.data[i].stars;
          reviews.dogStew.average = reviews.dogStew.stars / reviews.dogStew.total;
        }

        if (res.data[i].product_id === 26) {
          reviews.dogLoaf.total++;
          reviews.dogLoaf.stars += res.data[i].stars;
          reviews.dogLoaf.average = reviews.dogLoaf.stars / reviews.dogLoaf.total;
        }

        if (res.data[i].product_id === 37) {
          reviews.catKibble.total++;
          reviews.catKibble.stars += res.data[i].stars;
          reviews.catKibble.average = reviews.catKibble.stars / reviews.catKibble.total;
        }

        if (res.data[i].product_id === 40) {
          reviews.catLowKibble.total++;
          reviews.catLowKibble.stars += res.data[i].stars;
          reviews.catLowKibble.average = reviews.catLowKibble.stars / reviews.catLowKibble.total;
        }

        if (res.data[i].product_id === 41) {
          reviews.catPate.total++;
          reviews.catPate.stars += res.data[i].stars;
          reviews.catPate.average = reviews.catPate.stars / reviews.catPate.total;
        }

        if (res.data[i].product_id === 48) {
          reviews.catJuicy.total++;
          reviews.catJuicy.stars += res.data[i].stars;
          reviews.catJuicy.average = reviews.catJuicy.stars / reviews.catJuicy.total;
        }
      }

      this.setState({
        reviews: reviews
      });
    });
  }

  anotherProtein() {
    const protein = $('#protein').val();
    this.props.history.push(`/${protein}`);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const htmlWidth = $('html').width();
    const scroll = htmlWidth * 0.514;
    $('.scrolling-wrapper').scrollLeft(scroll);
  }

  render() {
    return (
      <div>
        <SwipePopUp />
        <div className='container chicken-container'>
          <div className='row d-flex align-items-center justify-content-center mb-5'>
            <div className='col-12 text-center another-protein'>Try Another Protein?</div>
            <div className='col-8 mt-3 mb-2'>
              <select id='protein' className='form-control'>
                <option value='rabbit'>Rabbit</option>
                <option value='pork'>Pork</option>
                <option value='duck'>Duck</option>
                <option value='beef'>Beef</option>
                <option value='green-tripe'>Green Tripe</option>
                <option value='venison'>Venison</option>
                <option value='turkey'>Turkey</option>
                <option value='lamb'>Lamb</option>
                <option value='fish'>Fish</option>
              </select>
            </div>
            <div className='col-12 d-flex align-content-center justify-content-center'>
              <button type='submit' className='protein-submit-button' onClick={this.anotherProtein}>
                Submit
              </button>
            </div>
          </div>
          <div className='protein-pet-header text-center'>DOG</div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 align-self-center protein-card'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/baked-kibble.png'} />
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog-food/regular-bites/chicken-adult' href='/dog-food/regular-bites/chicken-adult'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/adultregular5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.adultRegular.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.adultRegular.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog-food/regular-bites/chicken-adult' href='/dog-food/regular-bites/chicken-adult'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/adultregular12.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.adultRegular.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.adultRegular.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog-food/regular-bites/chicken-adult' href='/dog-food/regular-bites/chicken-adult'>
                  <img className='large-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/adultregular25.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.adultRegular.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.adultRegular.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 align-self-center protein-card'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/small-bites1.png'} />
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog/small-bites/chicken-adult' href='/dog/small-bites/chicken-adult'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/adultsmall5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.adultSmall.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.adultSmall.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog/small-bites/chicken-adult' href='/dog/small-bites/chicken-adult'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/adultsmall12.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.adultSmall.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.adultSmall.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 align-self-center protein-card'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/baked-kibble.png'} />
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog-food/regular-bites/chicken-puppy' href='/dog-food/regular-bites/chicken-puppy'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/puppyregular5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#fbf7ca' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.puppyRegular.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.puppyRegular.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog-food/regular-bites/chicken-puppy' href='/dog-food/regular-bites/chicken-puppy'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/puppyregular12.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#fbf7ca' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.puppyRegular.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.puppyRegular.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog-food/regular-bites/chicken-puppy' href='/dog-food/regular-bites/chicken-puppy'>
                  <img className='large-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/puppyregular25.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#fbf7ca' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.puppyRegular.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.puppyRegular.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 align-self-center protein-card'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/small-bites1.png'} />
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog/small-bites/chicken-puppy' href='/dog/small-bites/chicken-puppy'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/puppysmall5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#fbf7ca' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.puppySmall.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.puppySmall.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 align-self-center protein-card'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/baked-kibble.png'} />
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog-food/regular-bites/chicken-senior' href='/dog-food/regular-bites/chicken-senior'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/seniorregular5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#bd9b2f' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.seniorRegular.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.seniorRegular.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog-food/regular-bites/chicken-senior' href='/dog-food/regular-bites/chicken-senior'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/seniorregular12.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#bd9b2f' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.seniorRegular.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.seniorRegular.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog-food/regular-bites/chicken-senior' href='/dog-food/regular-bites/chicken-senior'>
                  <img className='large-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/seniorregular25.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#bd9b2f' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.seniorRegular.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.seniorRegular.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 align-self-center protein-card'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/small-bites1.png'} />
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog/small-bites/chicken-senior' href='/dog/small-bites/chicken-senior'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/seniorsmall5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#bd9b2f' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.seniorSmall.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.seniorSmall.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog/small-bites/chicken-senior' href='/dog/small-bites/chicken-senior'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/seniorsmall12.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#bd9b2f' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.seniorSmall.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.seniorSmall.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 align-self-center protein-card'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/stews1.png'} />
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog-food/stews/chicken' href='/dog-food/stews/chicken'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/dogstew5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.dogStew.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogStew.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog-food/stews/chicken' href='/dog-food/stews/chicken'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/dogstew12.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.dogStew.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogStew.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 align-self-center protein-card'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/loafs1.png'} />
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/dog-food/loafs/chicken' href='/dog-food/loafs/chicken'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/dogloaf12.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.dogLoaf.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogLoaf.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-5 ingredient-divider' />
          <div className='protein-pet-header mt-5 text-center'>CAT</div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 align-self-center protein-card'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/baked-kibble.png'} />
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/cat-food/kibble/chicken' href='/cat-food/kibble/chicken'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/catkibble2.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.catKibble.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catKibble.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/cat-food/kibble/chicken' href='/cat-food/kibble/chicken'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/catkibble5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.catKibble.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catKibble.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/cat-food/kibble/chicken' href='/cat-food/kibble/chicken'>
                  <img className='large-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/catkibble11.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.catKibble.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catKibble.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 align-self-center protein-card'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/baked-kibble.png'} />
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/cat-food/kibble/low-fat-chicken' href='/cat-food/kibble/low-fat-chicken'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/catlowkibble2.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.catLowKibble.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catLowKibble.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/cat-food/kibble/low-fat-chicken' href='/cat-food/kibble/low-fat-chicken'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/catlowkibble5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.catLowKibble.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catLowKibble.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/cat-food/kibble/low-fat-chicken' href='/cat-food/kibble/low-fat-chicken'>
                  <img className='large-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/catlowkibble11.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.catLowKibble.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catLowKibble.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 align-self-center protein-card'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/pate.png'} />
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/cat-food/pates/chicken' href='/cat-food/pates/chicken'>
                  <img className='small-can-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/catpate2.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.catPate.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catPate.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/cat-food/pates/chicken' href='/cat-food/pates/chicken'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/catpate5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.catPate.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catPate.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/cat-food/pates/chicken' href='/cat-food/pates/chicken'>
                  <img className='large-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/catpate12.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.catPate.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catPate.total}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex my-5'>
            <div className='scrolling-wrapper'>
              <div className='col-6 align-self-center protein-card'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/just-juicy1.png'} />
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/cat-food/just-juicy/chicken' href='/cat-food/just-juicy/chicken'>
                  <img className='small-can-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/catjuicy2.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.catJuicy.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catJuicy.total}</div>
                </div>
              </div>
              <div className='col-12 align-self-end text-center protein-card'>
                <Link to='/cat-food/just-juicy/chicken' href='/cat-food/just-juicy/chicken'>
                  <img style={{ maxWidth: '77%' }} src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/catjuicy5.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column' style={{ backgroundColor: '#f1d760' }}>
                    CHICKEN RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='chicken-protein'
                    starCount={5}
                    value={this.state.reviews.catJuicy.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.catJuicy.total}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChickenMobile;
