import React from 'react';
import { MDBRow, MDBCol, MDBTable, MDBTableBody } from 'mdbreact';

const { updatePictureArr, convertToMDBTableData } = require('./helper');

export default function ReviewsLayout(props) {
  const { reviews } = props;
  //console.log(reviews);
  const pictureArr = updatePictureArr(reviews.pictures);
  const mdbTableReviewData = convertToMDBTableData(reviews);
  return (
    <MDBRow>
      <MDBCol>
        <MDBTable bordered striped responsive>
          <MDBTableBody rows={mdbTableReviewData}>
            <tr>
              <td>Images: </td>
              <td>
                <MDBRow>
                  {pictureArr.map((img, i) => (
                    <MDBCol key={i} size="12" sm="6" md="4" lg="3">
                      <img className="img-fluid" src={img} />
                    </MDBCol>
                  ))}
                </MDBRow>
              </td>
            </tr>
          </MDBTableBody>
        </MDBTable>
      </MDBCol>
    </MDBRow>
  );
}
