import React from 'react';
import { MDBRow, MDBCol, MDBListGroup, MDBListGroupItem } from 'mdbreact';

export default function Description(props) {
  return (
    <MDBCol className="shop-item-main__description">
      <MDBListGroup>
        {props.description.map((des, i) => (
          <MDBListGroupItem
            key={i}
            className="description__list-item description__list-item--no-outline futura-font py-0">
            <p className="description__p my-1 font-weight-lighter futura-font">{des}</p>
          </MDBListGroupItem>
        ))}
      </MDBListGroup>
    </MDBCol>
  );
}
