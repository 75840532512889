import React from 'react';
import {
    MDBRow,
    MDBCol,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBAlert,
    MDBAnimation,
  } from 'mdbreact';

import { Link } from 'react-router-dom';
import UserAccForms from '../../../ClientInteraction/UserAccForms/UserAccForms.jsx';
const { lightOrDarkBg } = require('../../helper');
import "./OverviewInfo.scss";


export default function reviewButton(props) {
  /* modal render Component */
  const user = localStorage.getItem('user');
  const { history, toggleSignInModal, signInModal, color } = props;

  const changeReviewImgIconColor = (color) => {
    if (lightOrDarkBg(color) === 'dark') {
      return (
        <img
          className="product-main__review-icon img-fluid"
          src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/20x20-white-icon.png"
        />
      );
    }
    return (
      <img
        className="product-main__review-icon img-fluid"
        src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/20x20-black-icon.png"
      />
    );
  };

  const reviewBtn = () => {
    return (
        <MDBCol md="7" lg="12" className="d-flex justify-content-evenly align-items-center">
            <Link
            className={`d-flex justify-content-evenly align-items-center ${
            lightOrDarkBg(color) === 'dark' ? 'white-text' : 'text-dark'
            }`}
            to={user !== null ? `/review/${props.id}/${props.name}` : '#'}>
            <button
            onClick={user === null ? props.toggleSignInModal : ''}
            className="product-main__review-btn product-main--no-highlight btn"
            style={{ backgroundColor: props.color }}
            title={user !== null ? 'Write a review' : 'You must be logged in to write a review.'}>
            <div
              className={`d-flex justify-content-evenly align-items-center ${
                lightOrDarkBg(color) === 'dark' ? 'white-text' : 'text-dark'
              }`}>
              {changeReviewImgIconColor(color)}
              <p className="product-review-button__p-text futura-font font-weight-bolder text-capitalize ml-3">
                Write a Review
              </p>
            </div>
            </button>
            </Link>
        </MDBCol>
    );
  };
  
return (
    <div className="product-main__product-reviews-wrapper" className="btnStyle">
        <MDBAnimation type="fadeIn">
            <MDBRow>
                {reviewBtn()}
            </MDBRow>
        </MDBAnimation>
        <MDBModal
            modalStyle="warning"
            toggle={toggleSignInModal}
            centered
            size="lg"
            isOpen={signInModal}>
                <MDBModalHeader
                tag="p"
                className="futura-font text-dark font-weight-light"
                toggle={toggleSignInModal}>
                Users Account
                </MDBModalHeader>
                <MDBModalBody className="text-dark p-0">
                    <MDBAlert
                    color="danger"
                    dismiss
                    className="product-main--review-warning text-center font-weight-bolder mb-0">
                    *You must sign in or have an account to write a review.
                    </MDBAlert>
                    <UserAccForms history={history} />
                </MDBModalBody>
        </MDBModal>
    </div>
);
}