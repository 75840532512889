import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

//const helper = require('./helper');

export default function DogRawAddition(props) {
  return (
    <div className="product-overview__dog-tripe-raw-addition">{/*<div className="product-overview__dog-tripe-raw-addition dog-tripe-raw-addition__bg my-3"></div>*/}           
      <MDBContainer>
        <MDBRow>
          <MDBCol sm="12" className="d-flex justify-content-center">
            <div className="text-uppercase futura-bold-font">
                <h1>HIGHLIGHTS</h1>
            </div>
          </MDBCol>
        </MDBRow> 

        {/*WHAT IS GREEN TRIPE?*/}
        <MDBRow className="d-flex justify-content-center align-items-center bg-color mt-5">
          <MDBCol xs="12" sm="12" md="5" className="text-center px-5 py-4">
            <div className="text-uppercase futura-bold-font">
                <h3>WHAT IS GREEN TRIPE?</h3>
                <div className="small-line"><hr /></div>
            </div>
            <div className="futura-font" style={{fontSize:14}}>
                <p>Beef Green Tripe is the fourth and final stomach of the cow enzymes and stomach acids are 
                   released for protein and microbial protein digestion. It is green and brown in color from 
                   a cow’s natural diet. It is not bleached or scolded like the “honeycomb” looking tripe 
                   sometimes seen in the grocery store.</p>
            </div>
          </MDBCol>
          <MDBCol xs="12" sm="12" md="7" className="d-flex justify-content-center px-0">
            <div>
                <img
                    src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Dog+Food/raw/1-1.png"
                    className="img-fluid"
                />
            </div>
           </MDBCol>
        </MDBRow>

        {/*KEY HEALTH BENEFITS*/}
        <MDBRow className="d-flex justify-content-center align-items-center bg-color">
            <MDBCol xs="12" sm="12" md="8" className="d-flex justify-content-center px-0">
            <div>
                <img
                    src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Dog+Food/raw/2.png"
                    className="img-fluid d-none-mobile"
                />
            </div>
           </MDBCol>
           <MDBCol xs="12" sm="12" md="4" className="text-center px-5 py-4">
            <div className="text-uppercase futura-bold-font">
                <h3>KEY HEALTH BENEFITS</h3>
                <div className="small-line"><hr /></div>
            </div>
            <div className="futura-font" style={{fontSize:14}}>
                <p>Because our green tripe is not bleached or cooked the enzymes and fermentation products are 
                    still available to your dog or cat.</p>
            </div>
          </MDBCol>
          <MDBCol xs="12" sm="12" md="8" className="d-flex justify-content-center px-0">
            <div>
                <img
                    src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Dog+Food/raw/2.png"
                    className="img-fluid d-none-desktop"
                />
            </div>
           </MDBCol>
        </MDBRow>

        {/*GREAT RAW TOPPER*/}
        <MDBRow className="d-flex justify-content-center align-items-center bg-color">
          <MDBCol xs="12" sm="12" md="5" className="text-center px-5 py-4">
            <div className="text-uppercase futura-bold-font">
                <h3>GREAT RAW TOPPER</h3>
                <div className="small-line"><hr /></div>
            </div>
            <div className="futura-font" style={{fontSize:14}}>
                <p>Green tripe has a very distinct smell due to the fermentation and enzymatic breakdown of 
                    grass and other nutrients. This also makes green tripe very palatable.</p>
            </div>
          </MDBCol>
          <MDBCol xs="12" sm="12" md="7" className="d-flex justify-content-center px-0">
            <div>
                <img
                    src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Dog+Food/raw/3.png"
                    className="img-fluid"
                />
            </div>
           </MDBCol>
        </MDBRow>


      </MDBContainer>
    </div>
  );
}