const getCorrectImgSrc = (state) => {
  let picSrc;
  Object.keys(state).forEach((key) => {
    if (
      state[key] !== null &&
      (key.includes('small') || key.includes('large') || key.includes('medium'))
    ) {
      picSrc = state[key].front.thumb;
    }
  });
  return picSrc;
};

const getFileUploadsVals = (obj) => {
  return Object.keys(obj).map((key) => {
    return { name: [obj[key].name], data: obj[key] };
  });
};

const getS3Links = (imageUrl) => {
  return imageUrl.map((file) => {
    return file.location;
  });
};

module.exports = { getCorrectImgSrc, getFileUploadsVals, getS3Links };
