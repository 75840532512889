import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import LambMobile from './LambMobile';

class Lamb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
      reviews: {
        dogRegular: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogSmall: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogTreat: {
          total: 0,
          stars: 0,
          average: 0
        }
      }
    };
    this.updatePredicate = this.updatePredicate.bind(this);
    this.anotherProtein = this.anotherProtein.bind(this);
  }

  componentWillMount() {
    axios.post('/getallreviews').then(res => {
      const reviews = {
        dogRegular: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogSmall: {
          total: 0,
          stars: 0,
          average: 0
        },
        dogTreat: {
          total: 0,
          stars: 0,
          average: 0
        }
      };

      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].product_id === 5) {
          reviews.dogRegular.total++;
          reviews.dogRegular.stars += res.data[i].stars;
          reviews.dogRegular.average = reviews.dogRegular.stars / reviews.dogRegular.total;
        }

        if (res.data[i].product_id === 12) {
          reviews.dogSmall.total++;
          reviews.dogSmall.stars += res.data[i].stars;
          reviews.dogSmall.average = reviews.dogSmall.stars / reviews.dogSmall.total;
        }

        if (res.data[i].product_id === 17) {
          reviews.dogTreat.total++;
          reviews.dogTreat.stars += res.data[i].stars;
          reviews.dogTreat.average = reviews.dogTreat.stars / reviews.dogTreat.total;
        }
      }

      this.setState({
        reviews: reviews
      });
    });
  }

  anotherProtein() {
    const protein = $('#protein').val();
    this.props.history.push(`/${protein}`);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.updatePredicate();
    window.addEventListener('resize', this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 425 });
  }

  render() {
    return (
      <div>
        <img className='banner img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Lamb/banner.png'} />
        {this.state.isDesktop ? (
          <div className='container chicken-container'>
            <div className='protein-pet-header'>DOG</div>
            <div className='row mb-3 page-divider' />
            <div className='row d-flex align-items-center mb-5'>
              <div className='col-sm-auto col-12 text-center another-protein'>Try Another Protein?</div>
              <div className='col-sm-auto col-12'>
                <select id='protein' className='form-control'>
                  <option value='rabbit'>Rabbit</option>
                  <option value='chicken'>Chicken</option>
                  <option value='duck'>Duck</option>
                  <option value='beef'>Beef</option>
                  <option value='green-tripe'>Green Tripe</option>
                  <option value='venison'>Venison</option>
                  <option value='turkey'>Turkey</option>
                  <option value='pork'>Pork</option>
                  <option value='fish'>Fish</option>
                </select>
              </div>
              <div className='col-sm-auto col-12 d-flex align-content-center justify-content-center'>
                <button type='submit' className='protein-submit-button' onClick={this.anotherProtein}>
                  Submit
                </button>
              </div>
            </div>
            <div className='row d-flex my-5'>
              <div className='col align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Lamb/baked-kibble.png'} />
              </div>
              <div className='col-3 align-self-end text-center'>
                <Link className='no-underline' to='/dog-food/regular-bites/lamb' href='/dog-food/regular-bites/lamb'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Lamb/adultregular4.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column protein-page-name' style={{ backgroundColor: '#ab8bba' }}>
                    LAMB RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='lamb-protein'
                    starCount={5}
                    value={this.state.reviews.dogRegular.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogRegular.total}</div>
                </div>
              </div>
              <div className='col-3 align-self-end text-center'>
                <Link className='no-underline' to='/dog-food/regular-bites/lamb' href='/dog-food/regular-bites/lamb'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Lamb/adultregular10.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column protein-page-name' style={{ backgroundColor: '#ab8bba' }}>
                    LAMB RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='lamb-protein'
                    starCount={5}
                    value={this.state.reviews.dogRegular.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogRegular.total}</div>
                </div>
              </div>
              <div className='col-3 align-self-end text-center'>
                <Link className='no-underline' to='/dog-food/regular-bites/lamb' href='/dog-food/regular-bites/lamb'>
                  <img className='large-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Lamb/adultregular20.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column protein-page-name' style={{ backgroundColor: '#ab8bba' }}>
                    LAMB RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='lamb-protein'
                    starCount={5}
                    value={this.state.reviews.dogRegular.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogRegular.total}</div>
                </div>
              </div>
            </div>
            <div className='row d-flex my-5'>
              <div className='col-3 align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Lamb/small-bites.png'} />
              </div>
              <div className='col-3 align-self-end text-center'>
                <Link className='no-underline' to='/dog/small-bites/lamb' href='/dog/small-bites/lamb'>
                  <img className='small-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Lamb/adultsmall4.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column protein-page-name' style={{ backgroundColor: '#ab8bba' }}>
                    LAMB RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='lamb-protein'
                    starCount={5}
                    value={this.state.reviews.dogSmall.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogSmall.total}</div>
                </div>
              </div>
              <div className='col-3 align-self-end text-center'>
                <Link className='no-underline' to='/dog/small-bites/lamb' href='/dog/small-bites/lamb'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Lamb/adultsmall10.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column protein-page-name' style={{ backgroundColor: '#ab8bba' }}>
                    LAMB RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='lamb-protein'
                    starCount={5}
                    value={this.state.reviews.dogSmall.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogSmall.total}</div>
                </div>
              </div>
              <div className='col-3 align-self-end text-center' style={{ opacity: '0' }}>
                <img className='large-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/adultregular25.jpg'} />
                <div className='protein-text-box d-flex justify-content-center flex-column protein-page-name' style={{ backgroundColor: '#ab8bba' }}>
                  LAMB RECIPE
                </div>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='lamb-protein'
                    starCount={5}
                    value={3}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{0}</div>
                </div>
              </div>
            </div>
            <div className='row d-flex my-5'>
              <div className='col-3 align-self-center'>
                <img className='img-fluid' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Lamb/dog-treats.png'} />
              </div>
              <div className='col-3 align-self-end text-center'>
                <Link className='no-underline' to='/dog-food/treats/lamb' href='/dog-food/treats/lamb'>
                  <img className='medium-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Lamb/treats.jpg'} />
                  <div className='protein-text-box d-flex justify-content-center flex-column protein-page-name' style={{ backgroundColor: '#4e0725', color: 'white' }}>
                    LAMB RECIPE
                  </div>
                </Link>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='lamb-protein'
                    starCount={5}
                    value={this.state.reviews.dogTreat.average}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{this.state.reviews.dogTreat.total}</div>
                </div>
              </div>
              <div className='col-3 align-self-end text-center' style={{ opacity: '0' }}>
                <img className='large-flavor' src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Protein/Chicken/adultregular25.jpg'} />
                <div className='protein-text-box d-flex justify-content-center flex-column protein-page-name' style={{ backgroundColor: '#ab8bba' }}>
                  LAMB RECIPE
                </div>
                <div style={{ fontSize: 24 }} className='mt-2 d-flex justify-content-center align-items-center'>
                  <StarRatingComponent
                    name='lamb-protein'
                    starCount={5}
                    value={3}
                    editing={false}
                    renderStarIcon={(index, value) => {
                      return (
                        <span>
                          <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                      );
                    }}
                  />
                  <div className='rating-count'>{0}</div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <LambMobile history={this.props.history} />
        )}
      </div>
    );
  }
}

export default Lamb;
