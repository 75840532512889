import React from 'react';
import { MDBRow, MDBCol, MDBSelect, MDBContainer } from 'mdbreact';

import SimpleInputField from '../../../FieldComponents/InputFields/InputField.jsx';

const { usStates } = require('../inputOptions');

export default function InputFields(props) {
  return (
    <MDBCol size="12" sm="8" className="p-0">
      <MDBRow>
        <MDBCol size="12">
          <MDBContainer>
            <h2 className="quiz-redeem__header2 futura-font">Contact Information:</h2>
          </MDBContainer>
        </MDBCol>
        <MDBCol size="12" sm="6">
          <SimpleInputField
            name="email"
            label="Email"
            value={props.state.email}
            onChange={props.onChange}
            required="required"
            disabled={true}
          />
        </MDBCol>
        <MDBCol size="12" sm="6">
          <SimpleInputField
            name="phone"
            label="Phone"
            value={props.state.phone}
            onChange={props.onChange}
            required="required"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12">
          <MDBContainer>
            <h2 className="quiz-redeem__header2 futura-font">Shipping Address:</h2>
          </MDBContainer>
        </MDBCol>
        <MDBCol size="12" sm="6">
          <SimpleInputField
            name="firstName"
            label="First Name"
            value={props.state.firstName}
            onChange={props.onChange}
            required="required"
            disabled={true}
          />
        </MDBCol>
        <MDBCol size="12" sm="6">
          <SimpleInputField
            name="lastName"
            label="Last Name"
            value={props.state.lastName}
            onChange={props.onChange}
            required="required"
            disabled={true}
          />
        </MDBCol>
        <MDBCol size="12">
          <SimpleInputField
            name="street1"
            label="Address"
            value={props.state.street1}
            onChange={props.onChange}
            required="required"
          />
        </MDBCol>
        <MDBCol size="12" sm="4">
          <SimpleInputField
            name="city"
            label="City"
            value={props.state.city}
            onChange={props.onChange}
            required="required"
          />
        </MDBCol>
        <MDBCol size="12" sm="4">
          <MDBContainer>
            <MDBSelect
              className="quiz-redeem__state-selector"
              search
              getValue={props.getUsState}
              label="State"
              options={usStates}
              required
            />
          </MDBContainer>
        </MDBCol>
        <MDBCol size="12" sm="4">
          <SimpleInputField
            name="zip"
            label="Zip"
            value={props.state.zip}
            onChange={props.onChange}
            required="required"
          />
        </MDBCol>
      </MDBRow>
    </MDBCol>
  );
}
