import React from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { Link } from 'react-router-dom';

import Title from './Title.jsx';
import Ratingtitle from '../ProductReviews/Ratingtitle.jsx';
import Description from './Description.jsx';
import Reviewbutton from './Reviewbutton.jsx';

export default function OverviewInfo(props) {
  return (
    <MDBRow className="product-main__overview-des">
      <MDBCol size="12">
        <Title title={props.title} pet={props.pet} foodType={props.foodType} icon={props.icon} />
      </MDBCol>
      <MDBCol size="12">
        <hr style={{ backgroundColor: props.color }} className="product-main__heading-dec-line" />
      </MDBCol>
      <MDBCol size="12">
        <MDBRow>
        <MDBCol md="6" size="11">
        <Ratingtitle starsRating={props.starsRating} numsOfRating={props.numsOfRating} />
        </MDBCol>
        <MDBCol md="6" size="1">
        <Reviewbutton 
        toggleSignInModal={props.toggleSignInModal} 
        signInModal={props.signInModal} 
        id={props.id} 
        name={props.name}
        color={props.color}
        reviews={props.reviews}
        history={props.history}
        />
        </MDBCol>
        </MDBRow>
      </MDBCol>
      <MDBCol size="12">
        <Description description={props.description} />
      </MDBCol>
      <MDBCol className="product-main__where-to-buy pl-2" size="12">
        <Link to="/store-locator" className="text-center">
          <MDBBtn type="button" color="dark">
            <p className="product-main__text--where-to-buy-font futura-font text-white">
              Where to buy
            </p>
          </MDBBtn>
        </Link>
      </MDBCol>
    </MDBRow>
  );
}
