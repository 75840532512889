import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import Axios from 'axios';
import swal from 'sweetalert2';

import IsLoadingChild from '../../../IsLoading/IsLoadingChild.jsx';
import InputFields from './InputFields.jsx';

const { success } = require('../../../_helper/SweetAlerts/alerts.jsx');
const {
  endPoint: { quizRedeem, redemptionCheck },
} = require('../../../_helper/stringVars');
const { onChange, validateForm } = require('../../../_helper/index.jsx');
const {
  handleErrors,
  handleLoggedInErr,
  handleAuthorization,
  handleInvalidForm,
} = require('../../../_helper/reqsHandlers');

const { convertSelectedPrizeToArr } = require('../helper');

export default class QuizRemptionShip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      phone: '',
      firstName: '',
      lastName: '',
      street1: '',
      city: '',
      state: '',
      zip: '',
      selectedPrizesArr: [],
      currentPoints: 0,
      spentPoints: 0,
      userId: 0,
      pointsLoaded: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (!this.props) {
      return handleLoggedInErr('unauthorized prize', history);
    }
    if (handleAuthorization(history)) {
      this.setPrizeInfo();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedPrizes !== prevProps.selectedPrizes) {
      this.setState({
        selectedPrizes: this.props.selectedPrizes,
      });
    }
  }

  setPrizeInfo = () => {
    const { selectedPrizes, currentPoints, spentPoints, userId } = this.props;
    const { email, first_name, last_name } =
      JSON.parse(localStorage.getItem('user')) || JSON.parse(localStorage.getItem('admin'));

    const selectedPrizesArr = convertSelectedPrizeToArr(selectedPrizes);
    this.setState({
      selectedPrizesArr,
      currentPoints,
      spentPoints,
      userId,
      email,
      firstName: first_name,
      lastName: last_name,
      pointsLoaded: true,
    });
  };

  getUsState = (value) => {
    const state = value[0];
    this.setState({
      state,
    });
  };

  submitPrize = (e) => {
    e.preventDefault();
    const { userId, pointsLoaded, firstName, lastName, ...userInfo } = this.state;
    const name = { name: firstName + ' ' + lastName };
    Object.assign(userInfo, name);
    if (!validateForm(this.state, e)) {
      return handleInvalidForm();
    }
    Axios.post(`${quizRedeem.client}/send-shipping-info`, { params: { userId, userInfo } })
      .then((res) => {
        const { data, status } = res;
        if (status === 400) return handleErrors(400);
        if (status === 404) return handleErrors(404, 'address information');
        if (status === 406) return handleErrors(404, 'prizes are empty or incorrect prizes');
        swal
          .fire({
            icon: 'success',
            title: 'Order received!',
            text: 'You will receive an email confirming your order and next steps.',
            showConfirmButton: true,
            confirmButtonText: 'I understand.',
          })
          .then((result) => {
            if (result.value) {
              Axios.post(`${redemptionCheck.client}/create-redemption-info`, {
                params: { userId, userInfo },
              })
                .then((response) => {
                  if (response.status === 400) return handleErrors(400);
                  if (response.status === 404) return handleErrors(404);
                  window.location.reload();
                })
                .catch((err) => {
                  console.error(err);
                  handleErrors(500);
                });
            }
          });
      })
      .catch((err) => {
        const { status } = err.response;
        if (status === 500) return handleErrors(500);
      });
  };

  renderComponent = () => {
    const { state } = this;

    if (!state.pointsLoaded) return <IsLoadingChild />;
    return (
      <MDBCol size="12">
        <MDBRow className="mb-4">
          <MDBCol size="12">
            <h2 className="quiz-redeem__header2 futura-font">Your selected prizes:</h2>
          </MDBCol>
          {state.selectedPrizesArr.map((prizeInfo, i) => (
            <MDBCol size="7" key={i}>
              <div className="future-font">
                {prizeInfo.prizeType}: {prizeInfo.prizeName}
              </div>
            </MDBCol>
          ))}
        </MDBRow>
        <MDBRow>
          <form
            className="quiz-redeem__form needs-validation"
            noValidate
            onSubmit={this.submitPrize}>
            <InputFields
              getUsState={this.getUsState}
              state={state}
              onChange={(e) => onChange(this, e)}
            />
            <MDBCol size="12" sm="6">
              <MDBBtn type="submit" color="amber" className="ml-0">
                Submit
              </MDBBtn>
            </MDBCol>
          </form>
        </MDBRow>
      </MDBCol>
    );
  };

  render() {
    return (
      <MDBContainer className="retailer-quiz__quiz-redeem">
        <MDBRow>{this.renderComponent()}</MDBRow>
      </MDBContainer>
    );
  }
}
