import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBSelect } from 'mdbreact';

const { frontendPrizeText } = require('../../inputOptions');
const { disableInputVal, enableInputVal } = require('../../helper');

export default class GeneralSelectInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputVals: [],
    };
  }

  componentDidMount() {
    this.setInputVals(this.props.enabled);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.enabled !== prevProps.enabled) {
      this.setInputVals(this.props.enabled);
    }
  }

  setInputVals = (enabled) => {
    const { props } = this;

    const inputVals = enabled
      ? enableInputVal(props.inputValsArr)
      : disableInputVal(props.inputValsArr);

    this.setState({
      inputVals,
    });
  };

  render() {
    const { props, state } = this;

    return (
      <MDBRow>
        <MDBCol size="8">
          <MDBSelect
            label={`Select your ${frontendPrizeText[props.prizeType]}`}
            selected={`Choose your ${frontendPrizeText[props.prizeType]}`}
            options={state.inputVals}
            getValue={(value) => props.selectPrizes(value, props.prizePoints, props.prizeType)}
          />
        </MDBCol>
      </MDBRow>
    );
  }
}
