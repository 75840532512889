import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTabContent,
  MDBTabPane,
  MDBNav,
  MDBNavItem,
  MDBBtn,
  MDBAnimation,
  MDBSelect,
} from 'mdbreact';

import IsloadingChild from '../../../../IsLoading/IsLoadingChild.jsx';
import ReviewsLayout from './ReviewsLayout.jsx';

const { success, error, warning } = require('../../../../_helper/SweetAlerts/alerts.jsx');
const { setToken } = require('../../../../_helper/index.jsx');

const {
  endPoint: { reviewEndPoint },
} = require('../../../../_helper/stringVars');

export default class AdminReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: '0',
      productLoaded: false,
      reviews: [],
    };
  }

  componentDidMount() {
    this.getReviews();
  }

  getReviews = () => {
    Axios.get(`${reviewEndPoint.admin}/get-review`, { headers: setToken('admin') })
      .then((res) => {
        const reviews = res.data;
        /*Sort array by _id*/
        reviews.sort(function(a, b){
          if(a._id > b._id) return -1;
          if(a._id < b._id) return 1; 
         });
         //console.log(reviews);
        this.setState({
          reviews,
          productLoaded: true,
        });
      })
      .catch((err) => {
        console.error(err);
        const { status } = err.res;
        return error({ title: 'Error', text: `Server error. Status ${status}`, button: 'Okay.' });
      });
  };

  toggleTab = (tab) => {
    const { currentTab } = this.state;
    if (currentTab !== tab) {
      this.setState({ currentTab: tab });
    }
  };

  approveReviews = (e, id) => {
    e.preventDefault();
    Axios.put(
      `${reviewEndPoint.admin}/update-review`,
      { params: { _id: id, approved: true } },
      { headers: setToken('admin') },
    )
      .then((res) => {
        success({
          title: 'Success',
          text: 'Successfully approved review with id of ' + id,
          button: 'Okay.',
        });
        this.getReviews();
      })
      .catch((err) => {
        console.error(err);
        const { status } = err.res;
        if (status === 400) {
          return error({ title: 'Error: 400', text: 'Unathorized.', button: 'Okay' });
        }
        return error({ title: 'Error', text: 'Server Error 500.', button: 'Okay' });
      });
  };

  rejectReviews = (e, id) => {
    e.preventDefault();
    Axios.put(
      `${reviewEndPoint.admin}/update-review`,
      { params: { _id: id, approved: false } },
      { headers: setToken('admin') },
    )
      .then((res) => {
        success({
          title: 'Success',
          text: 'Successfully rejected review with id of ' + id,
          button: 'Okay.',
        });
        this.getReviews();
      })
      .catch((err) => {
        console.error(err);
        const { status } = err.res;
        if (status === 400) {
          return error({ title: 'Error: 400', text: 'Unathorized.', button: 'Okay.' });
        }
        return error({ title: 'Error', text: 'Server Error 500.', button: 'Okay.' });
      });
  };

  renderReviewsPane = (approved, reviews, i) => {
    if (approved === null) {
      return (
        <MDBTabPane tabId="1">
          <div className="admin-reviews__review-wrapper my-3">
            <ReviewsLayout reviews={reviews} key={i} />
            <MDBBtn
              onClick={(e) => this.rejectReviews(e, reviews._id)}
              className="admin-reviews__reject-btn"
              color="dark">
              Reject
            </MDBBtn>
            <MDBBtn
              onClick={(e) => this.approveReviews(e, reviews._id)}
              className="admin-reviews__approve-btn"
              color="warning">
              Approve
            </MDBBtn>
          </div>
        </MDBTabPane>
      );
    }
    if (!approved) {
      return (
        <MDBTabPane tabId="2">
          <div className="admin-reviews__review-wrapper my-3">
            <ReviewsLayout reviews={reviews} key={i} />
            <MDBBtn
              onClick={(e) => this.approveReviews(e, reviews._id)}
              className="admin-reviews__approve-btn"
              color="warning">
              Approve
            </MDBBtn>
          </div>
        </MDBTabPane>
      );
    }
    return (
      <MDBTabPane tabId="0">
        <div className="admin-reviews__review-wrapper my-3">
          <ReviewsLayout reviews={reviews} key={i} />
          <MDBBtn
            onClick={(e) => this.rejectReviews(e, reviews._id)}
            className="admin-reviews__reject-btn"
            color="warning">
            Reject
          </MDBBtn>
        </div>
      </MDBTabPane>
    );
  };

  renderComponent() {
    const reviewTabs = ['Approved', 'Needs Review', 'Rejected'];
    const { state } = this;

    if (!state.productLoaded) {
      return <IsloadingChild />;
    }
    return (
      <div className="admin-main__reviews">
        <MDBContainer className="admin-main__navtabs classic-tabs">
          <MDBAnimation type="fadeIn">
            <MDBNav classicTabs color="primary" className="mt-5">
              {reviewTabs.map((review, i) => (
                <MDBNavItem key={i}>
                  <Link
                    className={`text-dark font-weight-bolder futura-font ${
                      state.currentTab === String(i) ? 'active' : ''
                    }`}
                    to="#"
                    onClick={() => this.toggleTab(String(i))}
                    role="tab">
                    {review}
                  </Link>
                </MDBNavItem>
              ))}
            </MDBNav>
            <MDBTabContent className="admin-main__tab-content" activeItem={state.currentTab}>
              <h2 className="admin-main__header2 futura-font">Product reviews</h2>
              {state.reviews.map((review, i) => {
                return this.renderReviewsPane(review.approved, review, i);
              })}
            </MDBTabContent>
          </MDBAnimation>
        </MDBContainer>
      </div>
    );
  }

  render() {
    return this.renderComponent();
  }
}
