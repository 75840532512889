import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';

export default function Props65(props) {
  return (
    <MDBCol>
      <h2 className="shop-item-main__header2 futura-font">Props 65 Warning</h2>
      <p>
        This product contains a chemical known to the State of California to cause cancer and birth
        defects or other reproductive harm.
      </p>
      <a className="shop-item-main__prop65-link futura-font" href="https://www.p65warnings.ca.gov">
        www.p65warnings.ca.gov
      </a>
    </MDBCol>
  );
}
