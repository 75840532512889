import React from 'react';
import axios from 'axios';
import swal from 'sweetalert2';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact';
import SimpleInputField from '../../../../FieldComponents/InputFields/InputField.jsx';

const { onChange, setToken } = require('../../../../_helper/index.jsx');

const couponApi = '/api/coupons';
const Window = window;

const tokenHeader = { headers: setToken('admin') };

class AdminCoupons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coupons: [],
      couponUrl: '',
      couponName: '',
      _id: '',
      newCouponName: '',
      active: true,
    };

    this.newcoupon = React.createRef();
  }

  componentDidMount() {
    this.getCoupon();
  }

  getCoupon = () => {
    axios.get(`${couponApi}/get-coupon`, tokenHeader).then((res) => {
      const { message } = res.data;
      const coupons = res.data;
      if (coupons.length < 1) return this.couponNotFound(message);
      this.setState({
        coupons,
      }).catch((err) => {
        console.error(err);
      });
    });
  };

  couponNotFound = (message) => {
    this.setState({
      couponName: message,
      _id: 0,
    });
  };

  newUpload = (e) => {
    const input = e.target;
    const reader = new FileReader();
    reader.onload = () => {
      const dataURL = reader.result;
      const output = document.getElementById('newoutput');
      output.src = dataURL;
    };
    reader.readAsDataURL(input.files[0]);
  };

  addCoupon = (e) => {
    e.preventDefault();
    const { newCouponName, active } = this.state;
    const couponPicture = this.newcoupon.current.files;
    console.log({ couponPicture });

    const form = new FormData();

    for (let i = 0; i < couponPicture.length; i++) {
      form.append(couponPicture[i].name, couponPicture[i]);
    }

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    axios.post('/couponupload', form, config).then((res) => {
      const url = res.data.imageUrl[0].location;

      const data = {
        url,
        name: newCouponName,
        active,
      };

      axios.post(`${couponApi}/new-coupon`, data, tokenHeader).then((res) => {
        if (res.status === 201) {
          swal
            .fire({
              title: 'Coupon added!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            .then(() => {
              this.getCoupon();
            });
        } else {
          swal.fire({
            title: 'An error has occurred.',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    });
  };

  removeCoupon = (e) => {
    // eslint-disable-next-line no-underscore-dangle
    const _id = e.target.id;
    if (Window.confirm('Are you sure you want to remove this coupon?')) {
      axios.put(`${couponApi}/update-coupon`, { params: { _id } }, tokenHeader).then((res) => {
        if (res.status === 200) {
          swal
            .fire({
              title: 'Coupon removed.',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            .then(() => {
              this.getCoupon();
            });
        } else {
          swal.fire({
            title: 'An error has occurred.',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    }
  };

  render() {
    const { newCouponName } = this.state;
    return (
      <MDBContainer>
        <MDBAnimation type="fadeIn">
          <MDBRow>
            {this.state.coupons.map((coupon, i) => (
              <MDBCol key={i} size="10">
                <h1>Active Coupons: {coupon.name}</h1>

                <img className="img-fluid" src={coupon.url} />
                <div className="mt-3">
                  <button type="submit" id={coupon._id} onClick={this.removeCoupon}>
                    Remove Coupon
                  </button>
                </div>
              </MDBCol>
            ))}

            <div className="row mt-5">
              <div className="col-12">
                <SimpleInputField
                  onChange={(e) => onChange(this, e)}
                  value={newCouponName}
                  name="newCouponName"
                  label="Coupon name"
                />
              </div>
              <div className="col-auto">
                <img className="img-fluid" id="newoutput" />
                <input
                  type="file"
                  className="form-control-file"
                  id="newcoupon"
                  onChange={this.newUpload}
                  ref={this.newcoupon}
                />
                <button type="submit" onClick={this.addCoupon}>
                  Upload New Coupon
                </button>
              </div>
            </div>
          </MDBRow>
        </MDBAnimation>
      </MDBContainer>
    );
  }
}

export default AdminCoupons;
