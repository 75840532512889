import React from 'react';

class Starch extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="container about-us">
          <div className="row">
            <div className="col-12">
              <h1 className="about-us__title brandon-font">STARCH</h1>
            </div>
            <div className="col-12 page-divider about-us__divider page-divider" />
            <div className="row col-12 mb-5">
              {/* Applying styles using JavaScript object */}
              <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                <iframe
                  style={{ width: '100%', height: '400px' }}
                  frameBorder="0"
                  type="text/html"
                  src="https://www.dailymotion.com/embed/video/x8wvds2?autoplay=1"
                  allowFullScreen
                  title="Dailymotion Video Player"
                  allow="autoplay">
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Starch;
