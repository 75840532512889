import React from 'react';
import { Link } from 'react-router-dom';

class SiteMap extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className='container contact-us-body'>
          <div className='row'>
            <div className='col contact-us-text'>SITE MAP</div>
          </div>
          <div className='row page-divider mb-5' />
          <div className='row'>
            <div className='col-12'>
              <div className='mt-4'>
                <Link className='media-flavor' path='/' to='/'>
                  Home
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/dog-food' to='/dog-food'>
                  Dog Food
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-3' path='/dog-food/regular-bites' to='/dog-food/regular-bites'>
                  Regular Bites
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/regular-bites/chicken-adult' to='/dog-food/regular-bites/chicken-adult'>
                  Adult Chicken
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/regular-bites/chicken-puppy' to='/dog-food/regular-bites/chicken-puppy'>
                  Puppy Chicken
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/regular-bites/chicken-senior' to='/dog-food/regular-bites/chicken-senior'>
                  Senior Chicken
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/regular-bites/turkey' to='/dog-food/regular-bites/turkey'>
                  Turkey
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/regular-bites/sardine' to='/dog-food/regular-bites/sardine'>
                  Sardine
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/regular-bites/duck' to='/dog-food/regular-bites/duck'>
                  Duck
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/regular-bites/lamb' to='/dog-food/regular-bites/lamb'>
                  Lamb
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-3' path='/dog/small-bites' to='/dog/small-bites'>
                  Small Bites
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog/small-bites/chicken-adult' to='/dog/small-bites/chicken-adult'>
                  Adult Chicken
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog/small-bites/chicken-puppy' to='/dog/small-bites/chicken-puppy'>
                  Puppy Chicken
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog/small-bites/chicken-senior' to='/dog/small-bites/chicken-senior'>
                  Senior Chicken
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog/small-bites/turkey' to='/dog/small-bites/turkey'>
                  Turkey
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog/small-bites/sardine' to='/dog/small-bites/sardine'>
                  Sardine
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog/small-bites/duck' to='/dog/small-bites/duck'>
                  Duck
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog/small-bites/lamb' to='/dog/small-bites/lamb'>
                  Lamb
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-3' path='/dog-food/treats' to='/dog-food/treats'>
                  Dog Treats
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/treats/chicken' to='/dog-food/treats/chicken'>
                  Chicken
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/treats/sardine' to='/dog-food/treats/sardine'>
                  Sardine
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/treats/turkey' to='/dog-food/treats/turkey'>
                  Turkey
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/treats/venison' to='/dog-food/treats/venison'>
                  Venison
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/treats/lamb' to='/dog-food/treats/lamb'>
                  Lamb
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/treats/duck' to='/dog-food/treats/duck'>
                  Duck
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-3' path='/dog-food/stews' to='/dog-food/stews'>
                  Dog Stews
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/stews/chicken' to='/dog-food/stews/chicken'>
                  Chicken
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/stews/pork' to='/dog-food/stews/pork'>
                  Pork
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/stews/turkey' to='/dog-food/stews/turkey'>
                  Turkey
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/stews/venison' to='/dog-food/stews/venison'>
                  Venison
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/stews/beef' to='/dog-food/stews/beef'>
                  Beef
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-3' path='/dog-food/loafs' to='/dog-food/loafs'>
                  Dog Loafs
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/loafs/beef' to='/dog-food/loafs/beef'>
                  Beef
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/loafs/chicken' to='/dog-food/loafs/chicken'>
                  Chicken
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/loafs/duck' to='/dog-food/loafs/duck'>
                  Duck
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/loafs/pork' to='/dog-food/loafs/pork'>
                  Pork
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/loafs/rabbit' to='/dog-food/loafs/rabbit'>
                  Rabbit
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/loafs/salmon' to='/dog-food/loafs/salmon'>
                  Salmon
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/loafs/sardine' to='/dog-food/loafs/sardine'>
                  Sardine
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/loafs/green-tripe' to='/dog-food/loafs/green-tripe'>
                  Green Tripe
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/loafs/turkey' to='/dog-food/loafs/turkey'>
                  Turkey
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-3' path='/dog-food/just-juicy' to='/dog-food/just-juicy'>
                  Dog Just Juicy
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/just-juicy/beef' to='/dog-food/just-juicy/beef'>
                  Beef
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/dog-food/just-juicy/pork' to='/dog-food/just-juicy/pork'>
                  Pork
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/cat-food' to='/cat-food'>
                  Cat Food
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-3' path='/cat-food/kibble' to='/cat-food/kibble'>
                  Kibble
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/kibble/chicken' to='/cat-food/kibble/chicken'>
                  Chicken
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/kibble/sardine' to='/cat-food/kibble/sardine'>
                  Sardine
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/kibble/duck' to='/cat-food/kibble/duck'>
                  Duck
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/kibble/low-fat-chicken' to='/cat-food/kibble/low-fat-chicken'>
                  Low Fat Chicken
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-3' path='/cat-food/pates' to='/cat-food/pates'>
                  Pâtés
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/pates/chicken' to='/cat-food/pates/chicken'>
                  Chicken
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/pates/duck' to='/cat-food/pates/duck'>
                  Duck
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/pates/pork' to='/cat-food/pates/pork'>
                  Pork
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/pates/rabbit' to='/cat-food/pates/rabbit'>
                  Rabbit
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/pates/salmon' to='/cat-food/pates/salmon'>
                  Salmon
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/pates/sardine' to='/cat-food/pates/sardine'>
                  Sardine
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/pates/turkey' to='/cat-food/pates/turkey'>
                  Turkey
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-3' path='/cat-food/just-juicy' to='/cat-food/just-juicy'>
                  Cat Just Juicy
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/just-juicy/chicken' to='/cat-food/just-juicy/chicken'>
                  Chicken
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/just-juicy/pollock' to='/cat-food/just-juicy/pollock'>
                  Pollock
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/just-juicy/pork' to='/cat-food/just-juicy/pork'>
                  Pork
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/just-juicy/salmon' to='/cat-food/just-juicy/salmon'>
                  Salmon
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/just-juicy/turkey' to='/cat-food/just-juicy/turkey'>
                  Turkey
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/cat-food/just-juicy/venison' to='/cat-food/just-juicy/venison'>
                  Venison
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/chicken' to='/chicken'>
                  Chicken Protein
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/rabbit' to='/rabbit'>
                  Rabbit Protein
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/pork' to='/pork'>
                  Pork Protein
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/duck' to='/duck'>
                  Duck Protein
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/beef' to='/beef'>
                  Beef Protein
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/green-tripe' to='/green-tripe'>
                  Green Tripe Protein
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/venison' to='/venison'>
                  Venison Protein
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/turkey' to='/turkey'>
                  Turkey Protein
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/lamb' to='/lamb'>
                  Lamb Protein
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/fish' to='/fish'>
                  Fish Protein
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/store-locator' to='/store-locator'>
                  Store Locator
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/about-us' to='/about-us'>
                  About Us
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/why-baked' to='/why-baked'>
                  Why Baked?
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/shop' to='/shop'>
                  Shop
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-3' path='/shop/shirts' to='/shop/shirts'>
                  Shirts
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/shop/shirts/happy-camper' to='/shop/shirts/happy-camper'>
                  Happy Camper
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/shop/shirts/bird-watcher' to='/shop/shirts/bird-watcher'>
                  Bird Watcher
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-3' path='/shop/accessories' to='/shop/accessories'>
                  Accessories
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor ml-5' path='/shop/accessories/tote' to='/shop/accessories/tote'>
                  Tote
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/cart' to='/cart'>
                  Cart
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/checkout' to='/checkout'>
                  Checkout
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/coupons-and-offers' to='/coupons-and-offers'>
                  Coupons and Offers
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/sign-in' to='/sign-in'>
                  Sign In
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/sign-up' to='/sign-up'>
                  Sign Up
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/subscribe' to='/subscribe'>
                  Subscribe
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/ingredients' to='/ingredients'>
                  Ingredients
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/faq' to='/faq'>
                  FAQ
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/privacy-policy' to='/privacy-policy'>
                  Privacy Policy
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/terms-and-conditions' to='/terms-and-conditions'>
                  Terms and Conditions
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/newsletter' to='/newsletter'>
                  Newsletter
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/careers' to='/careers'>
                  Careers
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/retail-training' to='/retail-training'>
                  Retailer Training
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/retail-training/redeem' to='/retail-training/redeem'>
                  Redeem Points
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/retail-training/redeem/ship' to='/retail-training/redeem/ship'>
                  Ship Prize
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/contact-us' to='/contact-us'>
                  Contact Us
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/oven-baking' to='/oven-baking'>
                  Oven Baking
                </Link>
              </div>
              <div className='mt-4'>
                <Link className='media-flavor' path='/media' to='/media'>
                  Media
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SiteMap;
