import React from 'react';
import { MDBRow, MDBCol, MDBTooltip } from 'mdbreact';

import { productIcons } from './detailIconsHelper';

const renderCorrectIcons = (iconObj, dbIcons) => {
  if (dbIcons !== null) {
    return (
      <MDBRow>
        {iconObj.map((icon, i) => {
          return (
            <MDBCol key={i} size="12" sm="6">
              <MDBTooltip domElement tag="span" md placement="bottom">
                <div className="product-main__txt-img my-2">
                  <span>{icon.text !== null ? icon.text : ''}</span>
                  <img className="img-fluid" src={icon.src} />
                </div>
                <span className="product-main__icon-span futura-font">{icon.toolTip}</span>
              </MDBTooltip>
            </MDBCol>
          );
        })}
        {dbIcons.map((icon, i) => (
          <MDBCol key={i} size="12" sm="6">
            <MDBTooltip domElement tag="span" md placement="bottom">
              <div className="product-main__txt-img my-2">
                <span>{icon.text !== null ? icon.text : ''}</span>
                <img className="img-fluid" src={icon.src} />
              </div>
              <span className="product-main__icon-span futura-font">{icon.toolTip}</span>
            </MDBTooltip>
          </MDBCol>
        ))}
      </MDBRow>
    );
  }
  return (
    <MDBRow>
      {iconObj.map((icon, i) => {
        return (
          <MDBCol key={i} size="12" sm="6">
            <MDBTooltip domElement tag="span" md placement="bottom">
              <div className="product-main__txt-img my-2">
                <span>{icon.text !== null ? icon.text : ''}</span>
                <img className="img-fluid" src={icon.src} />
              </div>
              <span className="product-main__icon-span futura-font">{icon.toolTip}</span>
            </MDBTooltip>
          </MDBCol>
        );
      })}
    </MDBRow>
  );
};

export default function DetailIcons(props) {
  const currentIcons = productIcons[props.foodType];
  return (
    <div className="product-main__product-icons">
      {renderCorrectIcons(currentIcons, props.icons)}
    </div>
  );
}
