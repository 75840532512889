import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class Thanksgiving extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
      }

  render() {
    return (
        <div>
            <div className="pb-3 blog-margin-adjustment-desktop">
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/thanksgiving2022.png'} 
                        className="banner img-fluid w-100" 
                        alt="lotus Pet Foods - Thanksgiving"/>
                    </div>
                </MDBAnimation>
            </div>
       
            <div className='container mt-5'>
                <p style={{"color": "grey"}}>Nov 23, 2022</p>
            </div>

            <div className='container mt-5 text-center d-none-mobile'>
                <h1><span className="LemonMilkbold-font blog-hb">Thanksgiving extras! Yes & No List</span></h1>
            </div>

            <div className='container mt-5 text-center d-none-desktop'>
                <h1><span className="LemonMilkbold-font blog-h">Thanksgiving extras! Yes & No List</span></h1>
            </div>
  
            <div className='container' style={{"marginTop": "60px"}}>
                <div className='mb-5 futura-font blog-p'>
                    <p>When pets are family, we include them on all major events – including holidays!  It's also a great time to top the pet's meal with some exciting add-ons, but it's important to use the right options! Over indulging during the holidays is easy to do, so make sure you're monitoring what extras your pets are getting.  Below is a list of common foods served at thanksgiving, and which options are good to share with your dog or cat!</p>
                    <br />

                    <h4>The Yes List:</h4>
                    <ul>
                        <li>Turkey – White meat only </li>
                        <li>Plain mashed potatoes - no additions like dairy!</li>
                        <li>Plain green beans - don't give them green bean casserole but save a few green beans to add to the pets bowl!</li>
                        <li>Plain Sweet Potatoes - no added ingredients</li>
                        <li>Apples! Make sure to cut out the core</li>
                        <li>Pumpkin – Yes, the great pumpkin – make sure its plain pumpkin and not pumpkin pie filling!</li>
                    </ul>
                    <br />
                    <p>The No list is a bit longer, but it's important to know how to keep our pets safe during this time of year. Pets getting into things they shouldn't during the holidays, is the number one reason pets end up at the emergency vet this time of year!  It doesn't have to be the case for your family, by being prepared you can make sure they don't get into what they shouldn't!</p>
                    <br />
                    <h4>The No List:</h4>
                    <ul>
                        <li>Cooked Turkey bones – can splinter and cause major digestive problems, avoid turkey bones!</li>
                        <li>Turkey skin</li>
                        <li>Casseroles</li>
                        <li>Pies, cakes, or any sugar</li>
                        <li>Alcohol</li>
                        <li>Chocolate – every day of the year this is a big NO!</li>
                        <li>Raisins or grapes – very toxic to dogs!</li>
                        <li>Onions Scallions & Shallots </li>
                        <li>Ham</li>
                        <li>Fatty Foods</li>
                    </ul>
                    <br />
                    <p>Being Thankful for our pets is near the top of most of our lists this year! Make sure to share healthy options with them so they can be thankful too! If your pet happens to get into something they shouldn't, you can contact the Pet Poison Helpline or call your local on call vet! </p>
                    <br />
                    <p>If your dog has anxiety with a lot of people around, putting them in a kennel in a quiet place with calming music or a white noise machine might be the best option for them.</p>
                    <br />
                    <p>Happy Thanksgiving from Lotus!</p>
                </div>
            </div>
        </div>
    );
  }
}

export default Thanksgiving;