import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class DogAndKidney extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
      }

  render() {
    return (
        <div>
                <div className="pb-3 blog-margin-adjustment-desktop">
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/blog-kidney-banner.png'} 
                        className="banner img-fluid w-100" 
                        alt="lotus Pet Foods - Dogs and Kidney"/>
                    </div>
                </MDBAnimation>
                </div>
       
            <div className='container mt-5'>
                <p style={{"color": "grey"}}>Oct 04, 2022</p>
            </div>

            <div className='container mt-5 text-center d-none-mobile'>
                <h1><span className="LemonMilkbold-font blog-hb">Dogs and Kidney</span></h1>
            </div>

            <div className='container mt-5 text-center d-none-desktop'>
                <h1><span className="LemonMilkbold-font blog-h">Dogs and Kidney</span></h1>
            </div>
  
            <div className='container' style={{"marginTop": "60px"}}>
                <div className='mb-5 futura-font blog-p'>
                    <p>What is a kidney? The kidney's (most dogs are born with two) are organs that have many functions, but the primary function is to filter toxins out of 
                        the blood stream, they can regulate essential minerals like phosphorus, potassium and sodium, or electrolytes. The kidneys are also what produces 
                        urine in your dog. When a dog has kidney disease, the kidney's no longer perform these functions efficiently.</p>
                    <br />
                    <p>There are two types of Kidney failure in dogs, acute kidney failure, and chronic kidney failure.</p>
                    <br />

                    <h4>Acute kidney failure</h4>
                    <p>Caused by toxins or infection, acute kidney failure causes decreased function of the kidneys rapidly, typically within hours or days.</p>
                    <br />

                    <h4>Chronic kidney failure</h4>
                    <p>The gradual decline of kidney function, within months or years, usually caused by age. Although kidneys do have a life span, some dog's kidneys will deteriorate quicker than others.</p>
                    <br />

                    <h4>What causes kidney failure?</h4>
                    <p>Kidney failure can be caused by age, genetics, bacterial infections, or toxins.  Any condition that causes the kidneys to stop performing their normal functions is considered kidney disease, 
                        and can vary in severity, true kidney or renal failure is a far more serious condition as the kidneys have stopped working altogether.</p>
                    <br />

                    <h4>What are the symptoms of kidney failure?</h4>
                    <ul>
                        <li>Excessive drinking which causes large amounts of urine</li>
                        <li>Generalized weakness caused by lower levels of electrolytes</li>
                        <li>Lethargy</li>
                    </ul>
                    <h4>More severe, end stage kidney failure symptoms include:</h4>
                    <ul>
                        <li>blood in urine</li>
                        <li>pale gums</li>
                        <li>Weight loss, amongst other symptoms</li>
                    </ul>
                    <br />

                    <h4>What to do if you suspect your dog has kidney issues?</h4>
                    <p>A trip to the vet is necessary. Your vet will draw blood and urine to determine what is going on, if its kidney disease, renal failure, or something else. The testing will also determine which 
                        stage of kidney disease your dog is in. The results of the tests will determine the treatment plan. IV fluids, dialysis or kidney transplant are all possible options.</p>
                    <br />
                    <p>Sadly, chronic kidney disease cannot be cured, however you can lessen the load on the kidneys by feeding a diet lower in phosphorus, calcium, and magnesium along with keeping sodium levels low 
                        and protein moderate to low.</p>
                    <br />

                    <h4>Lotus Pet Foods could help with approval from your vet</h4>
                    <p>When looking for a diet to help your dog with kidney disease, you want to look at a few different values; Phosphorus, Protein, & Sodium. Our senior diet, which is approved for maintenance of adult dogs, 
                        meet these lower levels of key nutrients. Our senior diet is low in phosphorus and sodium, and moderate in protein at 18% to help lessen the workload on the kidneys when they're not functioning at optimal levels. </p>
                    <br />

                    <MDBRow className="d-flex justify-content-center align-items-center mt-5">
                        <MDBCol xs="12" sm="12" md="6" className="d-flex justify-content-center px-0">
                            <div>
                                <a href="https://www.lotuspetfoods.com/product-view/dog/regular-bites/Chicken%20Senior-53" target="_blank">
                                <img
                                    src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/dogandkidney-blog-img.png"
                                    className="img-fluid"
                                    width="80%"
                                />
                                </a>
                            </div>
                        </MDBCol>
                        <MDBCol xs="12" sm="12" md="6" className="px-5 py-4">
                            <div className="futura-bold-font blog-h-color">
                                <h3>Detailed Nutrients</h3>
                            </div>
                        
                            <div className="futura-font blog-p">
                                <ul>
                                    <li>Protein as fed: 18.50%</li>
                                    <li>Sodium as fed: 0.15%</li>
                                    <li>Phosphorus as fed: 0.49%</li>
                                    <li>Phosphorus Dry Matter: 0.54%</li>
                                    <li>Phosphorus per 100kcals: 146.57mg/100kcals</li>
                                </ul>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <p>If you chose to feed Lotus Senior recipe make sure to bring our detailed nutrition found <a href="https://www.lotuspetfoods.com/product-view/dog/regular-bites/Chicken%20Senior-53#Detailed%20Nutrients" target="_blank">here</a> and discuss the levels with your vet. For more information about lotus pet food visit our website at www.lotuspetfoods.com</p>
                     <br />
                </div>
            </div>
        </div>
    );
  }
}

export default DogAndKidney;