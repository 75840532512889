import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody } from 'mdbreact';
import { Link } from 'react-router-dom';


class NavBarTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopUp: false,
      isDesktop: false,
      selectedOption: null,
      cartSize: 0,
      query: '',
      searchResults: [],
      //signInModal: false,
    };
  }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener('resize', this.updatePredicate);
    }

    componentWillUnmount() {
    window.removeEventListener('resize', this.updatePredicate);
    }

    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth < 1200 });
    };

  render() {
    return (
      <div>
        <nav className="navTop navbar navbar-expand-xl fixed-top fixed-top-two" style={{boxShadow:"none"}}>
            {/*<div className="navbar-collapse collapse w-60">*/}
                <ul className="navbar-nav-two mr-auto d-flex align-items-end text-center justify-content-center list-style">
                    <li className="nav-item dropdown">
                        <a
                        className="nav-link nav-bar-link"
                        id="products"
                        href="/products"
                        data-toggle="dropdown">
                        Products
                        </a>
                        <div className="dropdown-menu dropdown-menu-nav product-drop">
                        <MDBContainer>
                            <MDBRow>
                            <MDBCol className="navbar__header-img">
                                <img
                                className="img-fluid"
                                src={
                                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/product-header.png'
                                }
                                />
                            </MDBCol>
                            </MDBRow>
                            <MDBRow>
                            <MDBCol size="6" className="navbar__img-wrapper">
                                <Link
                                className="navbar__img-link"
                                to="/dog-food"
                                //onMouseEnter={this.selectDog}
                                onMouseLeave={this.resetSelect}
                                role="button">
                                <img
                                    className="img-fluid"
                                    src={
                                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/dog-option.png'
                                    }
                                />
                                </Link>
                            </MDBCol>
                            <MDBCol
                                size="6"
                                className="navbar__img-wrapper navbar__img-wrapper--left-adjustment">
                                <Link
                                className="navbar__img-link"
                                to="/cat-food"
                                onMouseEnter={this.selectCat}
                                onMouseLeave={this.resetSelect}
                                role="button">
                                <img
                                    className="img-fluid"
                                    src={
                                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/cat-option.png'
                                    }
                                />
                                </Link>
                            </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a
                        className="nav-link nav-bar-link"
                        id="store-locator"
                        data-toggle="dropdown"
                        href="/store-locator">
                        Store Locator
                        </a>
                        <div className="dropdown-menu dropdown-menu-nav" id="store-drop">
                        <div className="container">
                            <div className="row store-drop-row">
                            <div className="col">
                                <Link to="/store-locator" href="/store-locator">
                                <img
                                    src={
                                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/store-locator-option.png'
                                    }
                                />
                                </Link>
                            </div>
                            </div>
                        </div>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link nav-bar-link" id="about-us" data-toggle="dropdown" href="/about-us">
                        About Us
                        </a>
                        <div className="dropdown-menu dropdown-menu-nav-about-us product-drop">
                        <MDBContainer>
                            <MDBRow>
                            <MDBCol className="navbar__header-img">
                                <img
                                className="img-fluid"
                                src={
                                    'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Nav+Bar/navbar-aboutus-text.png'
                                }
                                width="98%"
                                />
                            </MDBCol>
                            </MDBRow>
                            <MDBRow>
                            <MDBCol size="4" className="navbar__img-wrapper">
                                <Link
                                className="navbar__img-link"
                                to="/about-us"
                                href="/about-us"
                                onMouseEnter={this.selectDog}
                                onMouseLeave={this.resetSelect}>
                                <img
                                    className="img-fluid"
                                    src={
                                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/about-us-option1.png'
                                    }
                                />
                                </Link>
                            </MDBCol>
                            <MDBCol
                                size="4"
                                className="navbar__img-wrapper navbar__img-wrapper--left-adjustment">
                                <Link
                                className="navbar__img-link"
                                to="/oven-baked"
                                href="/oven-baked"
                                onMouseEnter={this.selectCat}
                                onMouseLeave={this.resetSelect}>
                                <img
                                    className="img-fluid navbar--white-bg"
                                    src={
                                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/about-us-option2.png'
                                    }
                                />
                                </Link>
                            </MDBCol>
                            <MDBCol
                                size="4"
                                className="navbar__img-wrapper navbar__img-wrapper--left-adjustment-two">
                                <Link
                                className="navbar__img-link"
                                to="/ingredients"
                                href="/ingredients"
                                onMouseEnter={this.selectCat}
                                onMouseLeave={this.resetSelect}>
                                <img
                                    className="img-fluid"
                                    src={
                                    'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Nav+Bar/narbar-ingredients-image.png'
                                    }
                                />
                                </Link>
                            </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </div>
                    </li>
                    {/*<li className="nav-item dropdown">
                        <a
                        className="nav-link nav-bar-link"
                        id="about-us"
                        data-toggle="dropdown"
                        href="/about-us">
                        About Us
                        </a>
                        <div className="dropdown-menu dropdown-menu-nav product-drop">
                        <MDBContainer>
                            <MDBRow>
                            <MDBCol className="navbar__header-img">
                                <img
                                className="img-fluid"
                                src={
                                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/about-us-bar.png'
                                }
                                />
                            </MDBCol>
                            </MDBRow>
                            <MDBRow>
                            <MDBCol size="6" className="navbar__img-wrapper">
                                <Link
                                className="navbar__img-link"
                                to="/about-us"
                                href="/about-us"
                                onMouseEnter={this.selectDog}
                                onMouseLeave={this.resetSelect}>
                                <img
                                    className="img-fluid"
                                    src={
                                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/about-us-option1.png'
                                    }
                                />
                                </Link>
                            </MDBCol>
                            <MDBCol
                                size="6"
                                className="navbar__img-wrapper navbar__img-wrapper--left-adjustment">
                                <Link
                                className="navbar__img-link"
                                to="/oven-baked"
                                href="/oven-baked"
                                onMouseEnter={this.selectCat}
                                onMouseLeave={this.resetSelect}>
                                <img
                                    className="img-fluid navbar--white-bg"
                                    src={
                                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/about-us-option2.png'
                                    }
                                />
                                </Link>
                            </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </div>
                    </li>*/}
                    <li className="nav-item dropdown">
                        <a className="nav-link nav-bar-link" id="store" data-toggle="dropdown" href="/shop">
                        Shop
                        </a>
                        <div className="dropdown-menu dropdown-menu-nav product-drop">
                        <MDBContainer>
                            <MDBRow>
                            <MDBCol className="navbar__header-img">
                                <img
                                className="img-fluid"
                                src={
                                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/store-bar.png'
                                }
                                />
                            </MDBCol>
                            </MDBRow>
                            <MDBRow>
                            <MDBCol size="6" className="navbar__img-wrapper">
                                <Link
                                className="navbar__img-link"
                                to="/shop/accessories/shirts"
                                href="/shop/shirts"
                                onMouseEnter={this.selectDog}
                                onMouseLeave={this.resetSelect}>
                                <img
                                    className="img-fluid"
                                    src={
                                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/shop-option1.png'
                                    }
                                />
                                </Link>
                            </MDBCol>
                            <MDBCol
                                size="6"
                                className="navbar__img-wrapper navbar__img-wrapper--left-adjustment">
                                <Link
                                className="navbar__img-link"
                                to="/shop/accessories"
                                href="/shop/accessories"
                                onMouseEnter={this.selectCat}
                                onMouseLeave={this.resetSelect}>
                                <img
                                    className="img-fluid"
                                    src={
                                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/shop-option2.png'
                                    }
                                />
                                </Link>
                            </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link nav-bar-link" id="nav-blog" data-toggle="dropdown" href="/blog">
                        Blog
                        </a>
                        <div className="dropdown-menu dropdown-menu-nav product-drop">
                        <MDBContainer>
                            <MDBRow>
                            <MDBCol className="navbar__header-img">
                                <img
                                className="img-fluid"
                                src={
                                    'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Nav+Bar/navbar-blog-text.png'
                                }
                                />
                            </MDBCol>
                            </MDBRow>
                            <MDBRow>
                            <MDBCol size="6" className="navbar__img-wrapper">
                                <Link
                                className="navbar__img-link"
                                to="/blog"
                                href="/blog"
                                onMouseEnter={this.selectDog}
                                onMouseLeave={this.resetSelect}
                                role="button">
                                <img
                                    className="img-fluid"
                                    src={
                                    'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Nav+Bar/navbar-blog-image-two.png'
                                    }
                                />
                                </Link>
                            </MDBCol>
                            <MDBCol
                                size="6"
                                className="navbar__img-wrapper navbar__img-wrapper--left-adjustment">
                                <Link
                                className="navbar__img-link"
                                to="/blog-video"
                                href="/blog-video"
                                onMouseEnter={this.selectCat}
                                onMouseLeave={this.resetSelect}
                                role="button">
                                <img
                                    className="img-fluid"
                                    src={
                                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/navbar-video-blog-image.png'
                                    }
                                />
                                </Link>
                            </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        </div>
                    </li>
                    {/*<li className="nav-item dropdown">
                        <a className="nav-link nav-bar-link" id="nav-ingredients" data-toggle="dropdown" href="/ingredients">
                        Ingredients
                        </a>
                    
                        <div className="dropdown-menu dropdown-menu-nav" id="nav-ingredients-drop">
                        <div className="container">
                            <div className="row nav-ingredients-row">
                            <div className="col">
                                <Link to="/ingredients" href="/ingredients">
                                <img
                                    src={ 
                                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/ingredients.png'
                                    }
                                />
                                </Link>
                            </div>
                            </div>
                        </div>
                        </div>
                    </li>*/}
                    <li className="nav-item dropdown">
                        <a
                        className="nav-link nav-bar-link"
                        id="contact-us"
                        data-toggle="dropdown"
                        href="/contact-us">
                        Contact Us
                        </a>
                        <div className="dropdown-menu dropdown-menu-nav" id="contact-us-drop">
                        <div className="container">
                            <div className="row contact-us-row">
                            <div className="col">
                                <Link to="/contact-us" href="/contact-us">
                                <img
                                    src={
                                    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Nav+Bar/contact-us.png'
                                    }
                                />
                                </Link>
                            </div>
                            </div>
                        </div>
                        </div>
                    </li>
                </ul>
            {/*</div>*/}
        </nav>
      </div>
    );
  }
}

export default NavBarTop;
