import React, { Component, Fragment } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBAnimation,
} from 'mdbreact';

import SignInModal from './SignIn/SignInModal.jsx';
import SignUpForms from './SignUp/SignUpForms.jsx';

export default class UserAccForms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: '0',
    };
  }

  toggleTabs = (tab) => (e) => {
    const { activeItem, retailer } = this.state;
    e.preventDefault();
    if (activeItem !== tab) {
      this.setState({ activeItem: tab });
    }
  };

  render() {
    const navNames = ['Returning Users', 'New Users', 'Retail Users'];

    const { history, toggleSignInModal } = this.props;

    const { activeItem } = this.state;
    return (
      <div className="user-acc-forms user-acc-forms--bg-img">
        <MDBContainer>
          <MDBRow center>
            <MDBCol size="12" lg="12">
              <MDBNav>
                <Fragment>
                  {navNames.map((name, i) => (
                    <MDBCol key={i} size="12" sm="4" className="px-0">
                      <MDBNavItem>
                        <MDBNavLink
                          className={`${
                            activeItem === `${i}` ? 'active-tab' : 'nonactive-tab '
                          } user-acc-forms__links futura-bold-font text-dark text-capitalize`}
                          to="#"
                          active={activeItem === `${i}`}
                          onClick={this.toggleTabs(`${i}`)}
                          role="tab">
                          {name}
                        </MDBNavLink>
                      </MDBNavItem>
                    </MDBCol>
                  ))}
                </Fragment>
              </MDBNav>
              <MDBTabContent activeItem={activeItem}>
                <MDBTabPane tabId="0">
                  <SignInModal toggleSignInModal={toggleSignInModal} history={history} />
                </MDBTabPane>
                <MDBTabPane tabId="1">
                  <MDBAnimation type="fadeIn">
                    <SignUpForms retailer={false} activeItem={activeItem} history={history} />
                  </MDBAnimation>
                </MDBTabPane>
                <MDBTabPane tabId="2">
                  <MDBAnimation type="fadeIn">
                    <SignUpForms retailer={true} activeItem={activeItem} history={history} />
                  </MDBAnimation>
                </MDBTabPane>
              </MDBTabContent>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}
