import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class OvenBaking extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="product-overview__simple-overview mb-5">
            <MDBAnimation type="fadeIn">
                <div className="overview-product__hero-banner">
                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/images-09.png'} className="banner img-fluid w-100" />
                </div>
            </MDBAnimation>
        </div>
        
        <MDBContainer>
            {/*Oven Baking*/}
            <MDBRow className="d-flex justify-content-center align-items-center mt-5">
                <MDBCol xs="12" sm="12" md="6" className="px-5 py-4">
                    <div className="head-style futura-bold-font">
                        <h3>Oven Baking</h3>
                    </div>
                    <div className="para-style futura-font">
                        <p>Baked in Goodness</p>
                    </div>
                    <div className="small-line-two"><hr /></div>
                    <div className="futura-font paratext-style" style={{fontSize:15}}>
                        <p>Oven baking is the process we use to cook our food. Our kibble is cooked from the outside in, keeping most of the nutrients intact. 
                            We set our oven at 500 degrees; but the kibbles internal temperature only reaches 160 degrees. Baking is like grilling a steak, 
                            while extruding (how everyone else cooks their kibble) is like microwaving a steak. Oven baking is a slow, small batch process of 
                            cooking, that takes about 10 minutes to cook each batch of kibble.</p>
                    </div>
                </MDBCol>
                <MDBCol xs="12" sm="12" md="6" className="d-flex justify-content-center px-0">
                    <div>
                        <img
                            src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/images-02.png"
                            className="img-fluid"
                        />
                    </div>
                </MDBCol>
            </MDBRow>

            {/*Benefit of Baking*/}
            <MDBRow className="d-flex justify-content-center align-items-center">
                <MDBCol xs="12" sm="12" md="5" className="d-flex justify-content-center px-0">
                    <div>
                        <img
                            src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/images-03.png"
                            className="img-fluid d-none-mobile"
                        />
                    </div>
                </MDBCol>
                <MDBCol xs="12" sm="12" md="6" className="px-5 py-4">
                        <div className="head-style-two futura-bold-font">
                            <h3>Benefits of Baking</h3>
                        </div>
                        <div className="para-style-two futura-font">
                            <p>Healthy Immune System</p>
                        </div>
                        <div className="small-line-two-left"><hr /></div>
                        <div className="futura-font text-left" style={{fontSize:14}}>
                            <p>More fresh meat. Up to 40% in each bag. Better Amino acid retention. Better Vitamin & antioxidant retention. 
                            50% less sugar delivery. Low cooking temperature makes the sugar less available. Lotus oven baked kibble is made 
                            by a process so uncommonly wholesome it has an “®” after its name. Itʼs called Baked-In Goodness®(see?) Lotus 
                            Oven-Baked kibble is less processed, has less starch, has more fresh meats, fish and poultry than most other kibbles, 
                            and hey, it just plain tastes better.</p>
                        </div>
                </MDBCol>
                <MDBCol xs="12" sm="12" md="5" className="d-flex justify-content-center px-0">
                    <div>
                        <img
                            src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/images-03.png"
                            className="img-fluid d-none-desktop"
                        />
                    </div>
                </MDBCol>
            </MDBRow>

            {/*Small & Large Breed */}
            <MDBRow className="d-flex justify-content-center align-items-center mt-5">
                <MDBCol xs="12" sm="12" md="6" className="px-5 py-4">
                    <div className="head-style futura-bold-font">
                        <h3>Small & Large Breed</h3>
                    </div>
                    <div className="para-style futura-font">
                        <p>Healthy Balance</p>
                    </div>
                    <div className="small-line-two"><hr /></div>
                    <div className="futura-font paratext-style" style={{fontSize:14}}>
                        <p>14% max fat for healthy weight. 1.5% max calcium for healthy urinary tract. 30% max protein for a healthy mix of amino acid & minerals. 
                        1% max phosphorus for healthy urinary tract. Lowers urinary pH for a healthy urinary tract.</p>
                    </div>
                </MDBCol>
                <MDBCol xs="12" sm="12" md="6" className="d-flex justify-content-center px-0">
                    <div>
                        <img
                            src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/images-04.png"
                            className="img-fluid"
                        />
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>

       
        <div className="product-overview__simple-overview mb-5">
            <MDBAnimation type="fadeIn">
              <div className="overview-product__hero-banner">
                <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/images-05.png'} className="banner img-fluid w-100" />
              </div>
            </MDBAnimation>
        </div>

        <MDBContainer>
            {/*Key Benefits*/}
            <MDBRow className="d-flex justify-content-center align-items-center">
                <MDBCol xs="12" sm="12" md="6" className="d-flex justify-content-center px-0">
                <div>
                    <img
                        src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/images-06.png"
                        className="img-fluid d-none-mobile"
                    />
                </div>
                </MDBCol>
                <MDBCol xs="12" sm="12" md="6" className="px-5 py-4">
                <div className="head-style-two futura-bold-font">
                    <h3>Key Benefits</h3>
                </div>
                <div className="para-style-two futura-font">
                    <p>Low Starch</p>
                </div>
                <div className="small-line-two-left"><hr /></div>
                <div className="futura-font text-left" style={{fontSize:14}}>
                    <p>Prevents yeast issues with lower starch delivery. Oven baking <strong>delivers 50% less starch & glucose</strong> to your pet.</p>
                </div>
                </MDBCol>
                <MDBCol xs="12" sm="12" md="5" className="d-flex justify-content-center px-0">
                <div>
                    <img
                        src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/images-06.png"
                        className="img-fluid d-none-desktop"
                    />
                </div>
                </MDBCol>
            </MDBRow>

            {/*Family Owned & Operated*/}
            <MDBRow className="d-flex justify-content-center align-items-center mt-5">
                <MDBCol xs="12" sm="12" md="7" className="px-5 py-4">
                    <div className="head-style futura-bold-font">
                        <h3>Family Owned & Operated</h3>
                    </div>
                    <div className="para-style futura-font">
                        <p>Est. 2003</p>
                    </div>
                    <div className="small-line-two"><hr /></div>
                    <div className="futura-font paratext-style" style={{fontSize:14}}>
                        <p>From the beginning, Lotus has always been family owned and operated. In 2003, we created Lotus because of our love 
                        for pets and because we knew we could make something better. We also wanted to be very hands-on owners by learning 
                        and doing all aspects of making dog and cat food. We decide how our foods are made (small batches of kibble that 
                        are oven-baked and small batches of cans that are made in our own micro-cannery), and what ingredients go into them, 
                        which we feel are the very best. There is no multi-national conglomerate or private equity company telling us how to make our food.</p>
                    </div>
                </MDBCol>
                <MDBCol xs="12" sm="12" md="5" className="d-flex justify-content-center px-0 text-center">
                    <div>
                        <img
                            src="https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/images-07.png"
                            className="img-fluid"
                            width="70%"
                        />
                    </div>
                </MDBCol>
            </MDBRow>

            <div className="product-overview__simple-overview mb-5">
                <MDBAnimation type="fadeIn">
                <div className="overview-product__hero-banner text-center mt-5">
                    <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/images-08.png'} 
                    className="img-fluid" 
                    width="70%"
                    />
                </div>
                </MDBAnimation>
            </div>
        </MDBContainer>
    </div>
    );
  }
}

export default OvenBaking;