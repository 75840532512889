import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation} from 'mdbreact';

class DogTraining extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
      }

  render() {
    return (
        <div>
                <div className="pb-3 blog-margin-adjustment-desktop">
                <MDBAnimation type="fadeIn">
                    <div>
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/blog-training-banner.jpg'} 
                        className="banner img-fluid w-100" 
                        alt="lotus Pet Foods - Training"/>
                    </div>
                </MDBAnimation>
                </div>
       
            <div className='container mt-5'>
                <p style={{"color": "grey"}}>Oct 04, 2022</p>
            </div>

            <div className='container mt-5 text-center d-none-mobile'>
                <h1><span className="LemonMilkbold-font blog-hb">Training</span></h1>
            </div>

            <div className='container mt-5 text-center d-none-desktop'>
                <h1><span className="LemonMilkbold-font blog-h">Training</span></h1>
            </div>
  
            <div className='container' style={{"marginTop": "60px"}}>
                <div className='mb-5 futura-font blog-p'>
                    <p>Training comes in many ways, whether you're crate training your brand-new puppy, loose leash training or basic obedience – we have the perfect products to help you and your dog on your journey to graduating their training class! If you're doing reward based training or positive reinforcement, you'll need some yummy tools in your arsenal!</p>
                    <br />

                    <h4>Crate Training</h4>
                    <p>Crate training is important for several reasons, especially with young puppies. It can help them learn how to hold their bladders to help with potty training, can keep them safe from chewing on things that can harm them when you’re away, it gives your dog a special place just for themselves, where they can go when there overwhelmed or to take a snooze, it also gets them familiar with being in a kennel/crate if an emergency should ever happen!</p>
                    <br />
                    <p>The most important thing you can do when crate training is to make the crate or kennel a happy and calm place, never use it for punishment. Dogs respond well to positive reinforcement training with the use of treats!  Lotus Soft Baked Treats are the perfect training treat for puppies! They peel apart easily so you can give the smaller pups a smaller treat and are the perfect size for medium and large dogs. Just a couple in the kennel and they will be happy as can be running in!</p>
                    <br />
                    <p>Another training tool for long stays in the kennel, is filling up your favorite stuffable toy with some lotus treats mixed in with one of our loaf style cans, or our raw frozen meals. Freeze it and your dog or puppy will have hours of fun and enrichment while you’re away at work or out running errands.</p>
                    <br />

                    <h4>Loose Leash Training</h4>
                    <p>This type of training is important for long walks with your dog. Loose leash training is exactly what it sounds like, you'll train your dog to walk right next to you with a loose leash – no more pulling your arm or running down the street.</p>
                    <br />
                    <p>Using a treat pouch and filling it with Lotus Soft Baked treats will keep your dog at your hip! You can be confident feeding as many as needed to get your training session done as well, with only 2.5 kcals on average per treat, your dog will get the training they need - without the guilt of high calorie treats.</p>
                    <br />

                    <h4>Basic Obedience Training</h4>
                    <p>Old dog new tricks? New dog old tricks? Whatever situation you and your new best friend are in, basic obedience training is essential to enhance the communication between you and your dog. Some basic commands you will learn in obedience training are:</p>
                    <ul>
                        <li>Come</li>
                        <li>Sit</li>
                        <li>Stay</li>
                        <li>Heal</li>
                        <li>Leave it</li>
                        <li>Down</li>
                        <li>Off</li>
                        <li>And of course, No!</li>
                    </ul>
                    <p>Basic obedience training will enforce the bond with you and your dog, keep you both happy and thriving together!  Lotus Soft Baked Treats come in 6 different single protein flavors and are the perfect training treat to bring into obedience training. They’re high in protein, and especially high in liver which make the dogs do just about anything for them!</p>
                        <MDBAnimation type="fadeIn">
                        <div className="text-center">
                            <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/dog-training-treat.png'} 
                            className="img-fluid" 
                            width="80%"
                            alt="Lotus Pet Foods - Cats, you will really need to be sneaky, especially if they are really addicted to their dry crunchies."
                            />
                        </div>
                        </MDBAnimation>
                    <br />
                    <p>Whatever kind of training journey you are on, Lotus Pet Foods offers a wide variety of treats, and canned food to help keep your pup happy. For our full line up of products and nutritional information visit our <a href="https://www.lotuspetfoods.com/overview/dog/treats" target="_blank">products page!</a></p>
                     <br />
                        <MDBAnimation type="fadeIn">
                            <div className="text-center">
                                <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/dog-training-treattwo.png'} 
                                className="img-fluid" 
                                width="80%"
                                alt="Lotus Pet Foods - Cats, you will really need to be sneaky, especially if they are really addicted to their dry crunchies."
                                />
                            </div>
                        </MDBAnimation>
                     <br />
                </div>
            </div>
        </div>
    );
  }
}

export default DogTraining;