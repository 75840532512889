import React from 'react';

function MailchimpSignup() {
  const mailchimpEmbedCode = `
    <div id="mc_embed_shell">
      <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
      <style type="text/css">
        #mc_embed_signup {
          background: #fff;
          clear: left;
          font: 14px Helvetica, Arial, sans-serif;
          width: 100%; /* Make it responsive by setting width to 100% */
          max-width: 600px; /* Set a maximum width to prevent it from becoming too wide */
        }

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh; /* Center vertically within the viewport */
}

@media (max-width: 768px) {
  .center-container {
    flex-direction: column; /* Stack content vertically */
    padding: 20px; /* Add padding for better spacing on smaller screens */
  }
}
        /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
           We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
      </style>
      <div id="mc_embed_signup">
        <form action="https://lotuspetfoods.us4.list-manage.com/subscribe/post?u=fb886a930bf62196abfe09400&amp;id=4a3f4fd0af&amp;f_id=00fac2e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
          <div id="mc_embed_signup_scroll"><h2>Subscribe for your 40% off Lotus and your Choice Pet coupons</h2>
            <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
            <div class="mc-field-group"><label for="mce-FNAME">First Name </label><input type="text" name="FNAME" class=" text" id="mce-FNAME" value=""></div>
            <div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""></div>
            <div id="mce-responses" class="clear">
              <div class="response" id="mce-error-response" style="display: none;"></div>
              <div class="response" id="mce-success-response" style="display: none;"></div>
            </div>
            <div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text" name="b_fb886a930bf62196abfe09400_4a3f4fd0af" tabindex="-1" value=""></div>
            <div class="clear"><input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe"></div>
          </div>
        </form>
      </div>
      <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
      <script type="text/javascript">(function($) {
        window.fnames = new Array();
        window.ftypes = new Array();
        fnames[0]='EMAIL';ftypes[0]='email';
        fnames[21]='DV_VAR_21';ftypes[21]='text';
        // ... (other form fields)
        var $mcj = jQuery.noConflict(true);
      </script>
    </div>
  `;

    const divStyle = {
    width: '100%',     // Set width to 100%
    maxWidth: '900px', // Set max-width to 900px
    // Add any other styles you want here
  };

  return (
<div className="center-container">
    <div style={divStyle}
      dangerouslySetInnerHTML={{ __html: mailchimpEmbedCode }}
    />
    </div>
  );
}

export default MailchimpSignup;
