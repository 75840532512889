import React from 'react';
import { Link } from 'react-router-dom';

const products = [
  {
    name: 'Lotus Dog Guitar Tote',
    price: '3.00',
    img: {
      small: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/dog+320-01.png',
      thumbnail: ['https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/totebag.jpg'],
      main: ['https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/totebag-main.jpg'],
    },
    link: 'totes/dog',
  },
  {
    name: 'Lotus cat Tote',
    price: '3.00',
    img: {
      small:
        'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Shop/cat-totes/bird+watcher+320-01.png',
      thumbnail: ['https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/totebag.jpg'],
      main: ['https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/totebag-main.jpg'],
    },
    link: 'totes/cat',
  },
];

class Accessories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <img
          className="banner img-fluid"
          src={'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Shop/banner.png'}
        />
        <div className="container food-type">
          <div className="row">
            {products.map((item, i) => {
              return (
                <div key={i} className="col-sm-3 col-6 text-center my-sm-5 my-3">
                  <Link
                    className="no-underline"
                    to={`/shop/accessories/${item.link}`}
                    href={`/shop/accessories/${item.link}`}>
                    <div className="shop-item-container">
                      <img className="img-fluid" src={item.img.small} />
                      <div className="shop-item-name mt-4">{item.name}</div>
                      <div className="shop-item-type">{item.type}</div>
                      <div className="shop-item-price">${item.price}</div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Accessories;
