import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBInput } from 'mdbreact';

import SignUpFields from './SignUpFields.jsx';

export default function ConsumerSignUp(props) {
  const { firstName, lastName, email, password, onChange, signUp, onSubscribeChange } = props;
  return (
    <MDBRow className="consumer-sign-up">
      <MDBContainer>
        <MDBCol size="12">
          <p className="futura-font">
            Creating an account is fast, easy, and free. You'll be able to track your orders, write
            reviews, and more!
          </p>
        </MDBCol>
      </MDBContainer>
      <MDBCol size="12">
        <SignUpFields
          firstName={firstName}
          lastName={lastName}
          email={email}
          password={password}
          onChange={onChange}
          signUp={signUp}
          onSubscribeChange={onSubscribeChange}
        />
      </MDBCol>
    </MDBRow>
  );
}
