import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { Link } from 'react-router-dom';
import NewsLetterSubFooter from '@Containers/NewsletterSub/newsLetterSubFooter';


const { randomString } = require('@Comps/_helper/index.jsx');

export default function LoveYourPetCoupon(props) {
  const [tokenstateone, setTokenstateone] = useState(false);
  const tokenNumOne = localStorage.getItem('tokenNumOne');
  const { history } = props;
  const header = 'YOUR COUPON IS WAITING FOR YOU';
  const header2 = 'Welcome!';

  useEffect(() => {
    if (tokenNumOne) setTokenstateone(true);
  }, [tokenstateone]);

  const print = async () => {
    const w = window.open();
    let html = '<!DOCTYPE HTML>';
    html += '<html lang="en-us">';
    html += '<head><style></style></head>';
    html += '<body oncontextmenu="return false;">';
    html +=
      '<img className="coupon" src="https://lotus-coupons.s3.amazonaws.com/loveyourpetday-22.jpg" width="60%"></img>';
    html += '</body>';
    w.document.write(html);
    setTimeout(() => {
      w.print();
      history.push('/');
      localStorage.setItem('tokenNumOne', randomString());
    }, 1000);
  };

  const renderInfo = () => {
    if (tokenstateone) {
      return (
        <MDBContainer center className="free-cans margin-top-adjustment">
          <MDBRow>
            <MDBCol>
              <h1 className="header1 futura-bold-font text-uppercase text-center">{header}</h1>
            </MDBCol>
          </MDBRow>
          <MDBRow className="page-divider mb-3"></MDBRow>
          <MDBRow>
            <MDBCol>
              <p className="futura-font font-weight-bold text-center mb-3">
                You have already redeemed your two free cans or are not a subscriber to our
                newsletter.
              </p>
              <p className="futura-font font-weight-bold text-center mb-3">
                If you have not subscribe to our newsletter, please go to the
                <Link to="/subscribe">following page</Link> to suscribe and receive your two free
                cans!
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      );
    }

    return (
      <MDBContainer center className="free-cans margin-top-adjustment">
        <MDBRow>
          <MDBCol>
            <h4 className="header2 futura-bold-font text-uppercase text-center">{header2}</h4>
            <h1 className="header1 futura-bold-font text-uppercase text-center">{header}</h1>
          </MDBCol>
        </MDBRow>
        <MDBRow className="page-divider mb-3"></MDBRow>
        <MDBRow center className="flex-column align-items-center">
          <MDBCol size="12" className="d-flex flex-column align-items-center">
              <NewsLetterSubFooter acceptAll={true} enableBtn={true} />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  };

  return renderInfo();

}
