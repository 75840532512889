import React from 'react';

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className='container contact-us-body'>
          <div className='row'>
            <div className='col contact-us-text'>Privacy Policy</div>
          </div>
          <div className='row page-divider mb-3' />
          <div className='privacy-policy mb-5'>
            <p>
              This privacy policy describes how The Lotus Pet Food, Inc. (“Lotus”, “we”, “us” or “our”) collect, use and share personal information of consumer users (“Users,”
              “you,” or “your”) of this website, www.lotuspetfoods.com (the “Website”). Our privacy policy describes the types of information we may collect from you or that you
              may provide when you visit our Website and our practices for collecting, using, maintaining, protecting, and disclosing that information.
              <br />
              <br />
              This policy applies to information we collect: <br />
              <br />
              • On this Website; and <br />
              <br />
              • From electronic messages between you and this Website.
              <br />
              <br />
              It does not apply to information collected by: <br />
              <br />
              • Us, offline or through any other means; or <br />
              <br />
              • Any third party (including our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible
              from the Website.
              <br />
              <br />
              Please read this policy carefully. If you do not agree with this policy and our practices, you may choose not to visit this Website. By accessing or using this
              Website, you agree to this policy. This policy may change from time to time (see Changes to Our Privacy Policy below). Your continued use of this Website after we
              make changes will be deemed your acceptance of those changes, so please check the policy periodically for updates.
              <br />
              <br />
              <h2>Information We Collect About You and How We Collect It</h2>
              <br />
              We collect information from and about users of our Website in a range of ways, including directly from you when you provide it to us, e.g., through your completion of
              our “Contact Us” form; and automatically, as you navigate through the Website. <br />
              <br />
              Information You Give Us. We collect your‎ name, email address, residence address and phone number, as well as other information you directly give us on our Website.
              We do not require that you register or otherwise provide us with personal information to visit this Website. You may choose to provide us with personal information
              when you register with us or complete the “Contact Us” form on our Website. We may also retain records and copies of your correspondence (including email addresses),
              if you use the “Contact Us” form on this Website.
              <br />
              <br />
              Information We Get From Others. We may get information about you from other sources. We may add this to information we get from this Website.
              <br />
              <br />
              Information Automatically Collected. We automatically log information about you and your computer. For example, when visiting our Website, we log details of your
              visits to our Website, your computer operating system type, browser type, browser language, the website you visited before browsing to our Website, the date, time and
              duration of your visit to the Website, the specific pages, links and forms you access during your visit, how long you spent on a page, access times and information
              about your use of and actions on our Website, your Internet Protocol address, the domain from which you access the Internet, and whether you are a new or a returning
              visitor to this Website.
              <br />
              <br />
              Cookies. We may log information using "cookies." Cookies are small data files stored on your hard drive by a website. We may use both session Cookies (which expire
              once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive
              experience on our Website. This type of information is collected to make the Website more useful to you and to tailor the experience with us to meet your special
              interests and needs. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be
              unable to access certain parts of our Website. Unless you have adjusted your browser settings so that it will refuse cookies.
              <br />
              <br />
              Google Analytics is a web analysis service provided by Google Inc. (“Google”). We use the automatic data collection technology provided by Google Analytics to gather
              and automatically provide the above listed information to the Google Analytics system. The Google Analytics system immediately aggregates the information. Utilizing
              analytics software we can monitor and analyze web traffic and it can be used to keep track of User behavior. Google utilizes the data collected to track and examine
              the use of this Website, to prepare reports on its activities and share them with other Google services. Google may use the data collected to contextualize and
              personalize the ads of its own advertising network. Place of processing: US – Google's Privacy Policy – You may choose to Opt Out from Google Analytics.
              <br />
              <br />
              <h2>How We Use Your Information</h2>
              <br />
              We use your personal information: <br />
              <br />
              • To present our Website and its contents to Users and to improve User experience with this Website over time;
              <br />
              <br />
              • To communicate about products and services offered by us;
              <br />
              <br />
              • To provide you with the information that you request from us;
              <br />
              <br />
              • To respond to comments and questions and provide customer service;
              <br />
              <br />
              • To contact you about a product rating or review;
              <br />
              <br />
              • To send information including customer communications, technical notices, updates, security alerts, and support and administrative messages;
              <br />
              <br />
              • To link or combine user information with other personal information;
              <br />
              <br />
              • to protect, investigate, and deter against fraudulent, unauthorized, or illegal activity;
              <br />
              <br />
              • In any other way we may describe when you provide us with personal information; and
              <br />
              <br />
              • For any other purpose with your consent.
              <br />
              <br />
              <h2>Sharing Of Personal Information</h2>
              <br />
              We may share personal information as follows:
              <br />
              <br />
              • We may share personal information with our affiliates;
              <br />
              <br />
              • We may share personal information with contractors, service providers, and other third parties we use to support our business;
              <br />
              <br />
              • We may share personal information to fulfill the purpose for which you provide it;
              <br />
              <br />
              • We may share personal information for any other purpose disclosed by us when you provide the information to us; and
              <br />
              <br />
              • We may share personal information when we do a business deal, or negotiate a business deal, involving the sale or transfer of all or a part of our business or
              assets. These deals can include any merger, financing, acquisition, or bankruptcy transaction or proceeding.
              <br />
              <br />
              • We may share personal information for legal, protection, and safety purposes.
              <br />
              <br />
              o We may share information to comply with laws, rules and regulations.
              <br />
              <br />
              o We may share information to respond to lawful requests and legal processes.
              <br />
              <br />
              o We may share information to protect the rights and property of Lotus, our affiliates, our agents, customers, and others. This includes enforcing our agreements,
              policies, and terms of use.
              <br />
              <br />
              o We may share information in an emergency. This includes protecting the safety of our employees and agents, our customers, or any person.
              <br />
              <br />
              • We may share personal information with your consent.
              <br />
              <br />
              We may also share aggregated and/or anonymized data with others for their own uses. <br />
              <br />
              <h2>Retention of Your Information</h2>
              <br />
              We only retain the data from Google Analytics and our other third party service providers only as long as necessary to complete the purpose for which the information
              was collected, or as required by law and our record retention policies.
              <br />
              <br />
              <h3>Children and Minors</h3>
              <br />
              We do not knowingly collect personal information from minors under the age of 18. If you are under the age of 18, please do not submit any personal information
              through the Website. We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce our Privacy Policy by instructing their
              children never to provide personal information on the Website without their permission. If you have reason to believe that a minor under the age of 18 has provided
              personal information to us through the Website, please contact us, and we will endeavor to delete that information from our databases.
              <br />
              <br />
              <h2>Links to Other Web Sites</h2>
              <br />
              This Privacy Policy applies only to this Website. The Website may contain links to other web sites not operated or controlled by us (the "Third Party Sites"). The
              policies and procedures we described here do not apply to the Third Party Sites. The links from this Website do not imply that we endorse or have reviewed the Third
              Party Sites. We suggest contacting those sites directly for information on their privacy policies.
              <br />
              <br />
              <h2>Information Choices And Changes</h2>
              <br />
              We strive to provide you with choices regarding the personal information you provide to us.
              <br />
              <br />
              You may choose not to provide us with personal information. <br />
              <br />
              You may send requests about personal information to our Contact Information below. You can request to change contact choices and update your personal information.
              <br />
              <br />
              In addition, you can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please
              note that some parts of this site may then be inaccessible or not function properly.
              <br />
              <br />
              Our marketing emails tell you how to “opt-out.” If you opt out, we may still send you non-marketing emails. Non-marketing emails include emails about our business
              dealings with you.
              <br />
              <br />
              <h2>Data Security</h2>
              <br />
              We have implemented reasonable administrative, physical and technical safeguards designed to reduce the risk of accidental loss or unauthorized access, use,
              alteration, and disclosure of personal information in our possession or under our control. However, no Internet or email transmission is ever fully secure or error
              free. Email sent to or from the Website may not be secure. Therefore, you should take special care in deciding what information you send to us via email. Please keep
              this in mind when disclosing any personal information to us via the Internet.
              <br />
              <br />
              <h2>Changes to Our Privacy Policy</h2>
              <br />
              We may change this privacy policy at any time and from time to time without prior notice. If we make any changes, we will change the Effective date at the top of this
              privacy policy. You are responsible for periodically visiting our Website and this privacy policy to check for any changes. Please review this privacy policy
              periodically, and especially before you provide any personal information. Your continued use of the Website after any changes or revisions to this privacy policy
              shall indicate your agreement with the terms of such revised privacy policy.
              <br />
              <br />
              <h2>Contact Information</h2>
              <br />
              We welcome your comments or questions about this privacy policy. You may also contact us at our address:
              <br />
              <br />
              The Lotus Pet Food, Inc.
              <br />
              2727 Maricopa Street
              <br />
              Torrance, CA 90503
              <br />
              <br />
              Attention: Daron Matsuura
              <br />
              dmatsuura@lotuspetfoods.com
              <br />
            </p>
          </div>
          <div className='row mt-5'>
            <div className='col contact-us-text'>California Privacy Policy</div>
          </div>
          <div className='row page-divider mb-3' />
          <div className='privacy-policy mb-5'>
            <p>
              If you are a California resident, California Civil Code Section 1798.83 permits you to request information regarding the disclosure of your personal information by
              The Lotus Pet Food, Inc. (“Lotus”) to third parties for such third parties' direct marketing purposes. To make such a request, please contact us at the following
              address:
              <br />
              <br />
              The Lotus Pet Food, Inc.
              <br />
              2727 Maricopa Street
              <br />
              Torrance, CA 90503
              <br />
              <br />
              Attention: Daron Matsuura
              <br />
              dmatsuura@lotuspetfoods.com
              <br />
              <br />
              Pursuant to California Civil Code Section 1798.83(c)(2), Lotus does not share a User’s personal information with third parties for their direct marketing use. If you
              are a California resident under the age of 18, and a registered user of the Lotus Website, California Business and Professions Code Section 22581 permits you to
              request and obtain removal of content or information you have publicly posted. To make such a request, contact us at the following address and provide us with a
              detailed description of the specific content or information you desire removed.
              <br />
              <br />
              The Lotus Pet Food, Inc.
              <br />
              2727 Maricopa Street
              <br />
              Torrance, CA 90503
              <br />
              <br />
              Attention: Daron Matsuura
              <br />
              dmatsuura@lotuspetfoods.com
              <br />
              <br />
              Please be aware that such a request does not ensure complete or comprehensive removal of the content or information you have posted and that there may be
              circumstances in which the law does not require or allow removal even if requested.
              <br />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
