const foodTypeWithCanada = ['regular bites', 'small bites', 'kibble'];
const icons = {
  canada: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Product+Page/made-in-canada.png',
  dog:
    'https://s3-us-west-1.amazonaws.com/lotus-pet-foods/Product+Page/all-life-stage-food-dog.png',
  cat: 'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Product+Page/all-life-cats.png',
  'raw food':
    'https://lotus-pet-foods.s3-us-west-1.amazonaws.com/Cat+Food/Rawfood/icon/made-in-california-icon.png',
};

const getIngredientIcons = (foodType, petType) => {
  const iconObj = {};
  foodTypeWithCanada.forEach((foodItem) => {
    if (foodItem === foodType.toLowerCase()) {
      iconObj.canada = icons.canada;
    }
  });
  if (petType.toLowerCase().includes('dog')) {
    iconObj.petIcon = icons.dog;
  } else {
    iconObj.petIcon = icons.cat;
  }
  if (foodType.toLowerCase() === 'raw food') {
    iconObj.rawIcon = icons['raw food'];
  }

  return iconObj;
};

module.exports = { getIngredientIcons };
