import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBAnimation, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn, MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem} from "mdbreact";
    import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";

export default function Blog() {

   useEffect(()=>{
    window.scrollTo(0, 0);
   },[])

    const [blogState, setblogState] = useState({
        articles: [
            {title: 'Training',
            tag: "All Training Articles",
            url: "/dog-training",
            alt: "Dog Training",
            date: "Oct 04, 2022",
            description: 'Training comes in many ways, whether you are crate training your brand-new puppy, loose leash training...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/blog-training-thumb.jpg'},

            {title: 'Dogs and Kidney',
            tag: "All Health and Wellness Articles",
            url: "/dog-and-kidney",
            alt: "Dogs and Kidney",
            date: "Oct 04, 2022",
            description: 'What is a kidney? The kidneys (most dogs are born with two) are organs that have many functions...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/blog-kidney-thumb.png'},

            {title: 'What should I Do If My Pet wont Eat',
            tag: "All Stories and Ideas Articles",
            url: "/pet-appetite",
            alt: "What should I Do If My Pet wont Eat",
            date: "Sep 01, 2022",
            description: 'If there is a sudden change in your petʼs appetite, a trip to the vet is the first course of action..', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/pet-appetite.jpg'},

            {title: 'Garlic. Why Garlic?',
            tag: "All Nutrition Articles",
            url: "/why-garlic",
            alt: "Why garlic, benefit of garlic to pet",
            date: "Aug, 12, 2022",
            description: 'Can dogs and cat eat garlic? Let’s get into the truths about garlic, why we use it, and how it can benefit your pet...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/garlic-thumubnail.jpg'},

            {title: 'Food Transition to Lotus Foods',
            tag: "All Stories and Ideas Articles",
            url: "/food-transition",
            alt: "Food Transition to Lotus Foods",
            date: "Aug, 01, 2022",
            description: 'Transitioning your pet to another food brand can seem like a lot of work - but with these tips, you will...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/transition-thumbnail.jpg'},

            {title: 'Baking vs Extruded Foods', 
            tag: "All Nutrition Articles",
            url: "/baking-and-extruded",
            alt:'Baking vs Extruded Foods',
            date: "July, 01, 2022",
            description: 'Lotus kibble uses more fresh meat than any premium extruded kibble on the market. Lotus uses 33-40%...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/thumbnail-baked.jpg'},

            {title: 'Flea & Tick', 
            tag: "All Health and Wellness Articles",
            url: "/flea-tick",
            alt:'Flea & Tick',
            date: "June, 01, 2022",
            description: 'When it comes to enjoying time outside with our best friends, the last thing you want to worry about is bugs...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/flea-thumb.png'},

            {title: 'Why is my Cat Fat?',
            tag: "All Health and Wellness Articles",
            url: "/cat-obesity",
            alt: 'Why is my Cat Fat?',
            date: "May, 01, 2022",
            description: 'Do you have a chonk at home? A Garfield? A big boy or girl? Fat cats are very cute, and very common...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/fat-cat-humbnail.png'},
    
            {title: 'Tips for Vacationing with Pet', 
            tag: "All Stories and Ideas Articles",
            url: "/vacation-with-pet",
            alt: "Tips for Vacationing with Pet",
            date: "April, 01, 2022",
            description: 'Make sure you have the proper restraints. If you’re flying, make sure you contact your air carrier and get...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/vacation-thumbnail.jpg'},

            {title: 'Superfood Topper for Dogs & Cats', 
            tag: "All Nutrition Articles",
            url: "/superfood-topper",
            alt: 'Superfood Topper for Dogs & Cats',
            date: "March, 01, 2022",
            description: 'Packed with bio available protein, raw & minimally processed. Promotes healthy digestion & optimal nutrient...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/tripe-thumbnail.jpg'},

            {title: 'Mistakes Pet Owners Make When Buying Pet Food', 
            tag: "All Health and Wellness Articles",
            url:'/mistake-buying-pet-food',
            alt: 'Mistakes Pet Owners Make When Buying Pet Food', 
            date: "Feb, 01, 2022",
            description: 'Not Rearching the company background. Have they ever had any recalls? Not reading the ingredient label...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/food-thumbnail.jpg'},
    
            {title: 'Proper Toppers', 
            tag: "All Nutrition Articles",
            url: "/proper-topper",
            alt: 'Proper Toppers', 
            date: "Dec, 01, 2021",
            description: 'Adding variety to your pets bowl is becoming increasingly popular – because there are so many benefits to it!', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/toppers-thumbnail.jpg'},
    
            {title: 'When You Need to Know About Kidney Disease',
            tag: "All Health and Wellness Articles",
            url: "/pet-kidney-disease",
            alt: 'When You Need to Know About Kidney Disease',
            date: "Nov, 01, 2021",
            description: 'What is Kidney Disease? Any condition that causes the kidneys to stop functioning properly is referred...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/kidney-thumbnail.jpg'}
          ],
        filterArticles:[
            {title: 'Training',
            tag: "All Training Articles",
            url: "/dog-training",
            alt: "Dog Training",
            date: "Oct 04, 2022",
            description: 'Training comes in many ways, whether you are crate training your brand-new puppy, loose leash training...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/blog-training-thumb.jpg'},

            {title: 'Dogs and Kidney',
            tag: "All Health and Wellness Articles",
            url: "/dog-and-kidney",
            alt: "Dogs and Kidney",
            date: "Oct 04, 2022",
            description: 'What is a kidney? The kidneys (most dogs are born with two) are organs that have many functions...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/blog-kidney-thumb.png'},

            {title: 'What should I Do If My Pet wont Eat',
            tag: "All Stories and Ideas Articles",
            url: "/pet-appetite",
            alt: "What should I Do If My Pet wont Eat",
            date: "Sept 01, 2022",
            description: 'If there is a sudden change in your petʼs appetite, a trip to the vet is the first course of action..', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/pet-appetite.jpg'},

            {title: 'Garlic. Why Garlic?',
            tag: "All Nutrition Articles",
            url: "/why-garlic",
            alt: "Why garlic, benefit of garlic to pet",
            date: "Aug, 12, 2022",
            description: 'Can dogs and cat eat garlic? Let’s get into the truths about garlic, why we use it, and how it can benefit your pet...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/garlic-thumubnail.jpg'},

            {title: 'Food Transition to Lotus Foods',
            tag: "All Stories and Ideas Articles",
            url: "/food-transition",
            alt: "Food Transition to Lotus Foods",
            date: "Aug, 01, 2022",
            description: 'Transitioning your pet to another food brand can seem like a lot of work - but with these tips, you will...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/transition-thumbnail.jpg'},

            {title: 'Baking vs Extruded Foods', 
            tag: "All Nutrition Articles",
            url: "/baking-and-extruded",
            alt:'Baking vs Extruded Foods',
            date: "July, 01, 2022",
            description: 'Lotus kibble uses more fresh meat than any premium extruded kibble on the market. Lotus uses 33-40%...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/thumbnail-baked.jpg'},
        ],
        articlesOne: [
            {title: 'Training',
            tag: "All Training Articles",
            url: "/dog-training",
            alt: "Dog Training",
            date: "Oct 04, 2022",
            description: 'Training comes in many ways, whether you are crate training your brand-new puppy, loose leash training...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/blog-training-thumb.jpg'},

            {title: 'Dogs and Kidney',
            tag: "All Health and Wellness Articles",
            url: "/dog-and-kidney",
            alt: "Dogs and Kidney",
            date: "Oct 04, 2022",
            description: 'What is a kidney? The kidneys (most dogs are born with two) are organs that have many functions...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/blog-kidney-thumb.png'},

            {title: 'What should I Do If My Pet wont Eat',
            tag: "All Stories and Ideas Articles",
            url: "/pet-appetite",
            alt: "What should I Do If My Pet wont Eat",
            date: "Sept 01, 2022",
            description: 'If there is a sudden change in your petʼs appetite, a trip to the vet is the first course of action..', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/pet-appetite.jpg'},

            {title: 'Garlic. Why Garlic?',
            tag: "All Nutrition Articles",
            url: "/why-garlic",
            alt: "Why garlic, benefit of garlic to pet",
            date: "Aug, 12, 2022",
            description: 'Can dogs and cat eat garlic? Let’s get into the truths about garlic, why we use it, and how it can benefit your pet...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/garlic-thumubnail.jpg'},

            {title: 'Food Transition to Lotus Foods',
            tag: "All Stories and Ideas Articles",
            url: "/food-transition",
            alt: "Food Transition to Lotus Foods",
            date: "Aug, 01, 2022",
            description: 'Transitioning your pet to another food brand can seem like a lot of work - but with these tips, you will...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/transition-thumbnail.jpg'},

            {title: 'Baking vs Extruded Foods', 
            tag: "All Nutrition Articles",
            url: "/baking-and-extruded",
            alt:'Baking vs Extruded Foods',
            date: "July, 01, 2022",
            description: 'Lotus kibble uses more fresh meat than any premium extruded kibble on the market. Lotus uses 33-40%...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/thumbnail-baked.jpg'},

        ], 
        articlesTwo: [
            
            {title: 'Flea & Tick', 
            tag: "All Health and Wellness Articles",
            url: "/flea-tick",
            alt:'Flea & Tick',
            date: "June, 01, 2022",
            description: 'When it comes to enjoying time outside with our best friends, the last thing you want to worry about is bugs...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/flea-thumb.png'},

            {title: 'Why is my Cat Fat?',
            tag: "All Health and Wellness Articles",
            url: "/cat-obesity",
            alt: 'Why is my Cat Fat?',
            date: "May, 01, 2022",
            description: 'Do you have a chonk at home? A Garfield? A big boy or girl? Fat cats are very cute, and very common...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/fat-cat-humbnail.png'},

            {title: 'Tips for Vacationing with Pet', 
            tag: "All Stories and Ideas Articles",
            url: "/vacation-with-pet",
            alt: "Tips for Vacationing with Pet",
            date: "April, 01, 2022",
            description: 'Make sure you have the proper restraints. If you’re flying, make sure you contact your air carrier and get...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/vacation-thumbnail.jpg'},

            {title: 'Superfood Topper for Dogs & Cats', 
            tag: "All Nutrition Articles",
            url: "/superfood-topper",
            alt: 'Superfood Topper for Dogs & Cats',
            date: "March, 01, 2022",
            description: 'Packed with bio available protein, raw & minimally processed. Promotes healthy digestion & optimal nutrient...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/tripe-thumbnail.jpg'},

            {title: 'Mistakes Pet Owners Make When Buying Pet Food', 
            tag: "All Health and Wellness Articles",
            url:'/mistake-buying-pet-food',
            alt: 'Mistakes Pet Owners Make When Buying Pet Food', 
            date: "Feb, 01, 2022",
            description: 'Not Rearching the company background. Have they ever had any recalls? Not reading the ingredient label...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/food-thumbnail.jpg'},

            {title: 'Proper Toppers', 
            tag: "All Nutrition Articles",
            url: "/proper-topper",
            alt: 'Proper Toppers', 
            date: "Dec, 01, 2021",
            description: 'Adding variety to your pets bowl is becoming increasingly popular – because there are so many benefits to it!', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/toppers-thumbnail.jpg'},

        ],
        articlesThree: [
            {title: 'When You Need to Know About Kidney Disease',
            tag: "All Health and Wellness Articles",
            url: "/pet-kidney-disease",
            alt: 'When You Need to Know About Kidney Disease',
            date: "Nov, 01, 2021",
            description: 'What is Kidney Disease? Any condition that causes the kidneys to stop functioning properly is referred...', 
            img:'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/kidney-thumbnail.jpg'}
          ],
          
          currentCategory: "All Articles",
          activePage: 1,
          totalItem: 13
    });

    
    //Default On page one, render() use current state's filterArticles[] and display on page.
    //If click on page one, getPageData() change state, push articlesOne[] into filterArticles[] and render() display on page,
    //If click on page two, getPageData() change state, push articlesTwo[] into filterArticles[] and render() display on page
    //If click on filter, ex: tag = "All Nutrition Articles", filterSelection() filters articles[] into articlesOne[] and and filterArticles[] render() display on page
    

  const filterSelection = (tag) => {
    let selectionAryOne = [];
    let selectionAryTwo = [];
    let selectionAryThree = [];
    if(tag == "All Articles"){
        for(let i=0; i<=5; i++){
            selectionAryOne.push(blogState.articles[i])
        }
        for(let i=6; i<=11; i++){
            selectionAryTwo.push(blogState.articles[i])
        }
    
            selectionAryThree.push(blogState.articles[12])
        
        setblogState({
            ...blogState,
            filterArticles: selectionAryOne,
            articlesOne: selectionAryOne,
            articlesTwo: selectionAryTwo,
            articlesThree: selectionAryThree,
            currentCategory: "All Articles",
            totalItem: 13
        })
    } else if (tag == "All Nutrition Articles"){
        selectionAryOne = blogState.articles.filter(article => article.tag == "All Nutrition Articles")
        setblogState({
            ...blogState,
            filterArticles: selectionAryOne,
            articlesOne: selectionAryOne,
            articlesTwo: [],
            articlesThree: [],
            currentCategory: "All Nutrition Articles",
            totalItem: 4
        })
    } else if (tag == "All Stories and Ideas Articles"){
        selectionAryOne = blogState.articles.filter(article => article.tag == "All Stories and Ideas Articles")
        setblogState({
            ...blogState,
            filterArticles: selectionAryOne,
            articlesOne: selectionAryOne,
            articlesTwo: [],
            articlesThree: [],
            currentCategory: "All Stories and Ideas Articles",
            totalItem: 3
        })

    } else if (tag == "All Health and Wellness Articles"){
        selectionAryOne = blogState.articles.filter(article => article.tag == "All Health and Wellness Articles")
        setblogState({
            ...blogState,
            filterArticles: selectionAryOne,
            articlesOne: selectionAryOne,
            articlesTwo: [],
            articlesThree: [],
            currentCategory: "All Health and Wellness Articles",
            totalItem: 5
        })

    } else if (tag == "All Training Articles"){
        selectionAryOne = blogState.articles.filter(article => article.tag == "All Training Articles")
        setblogState({
            ...blogState,
            filterArticles: selectionAryOne,
            articlesOne: selectionAryOne,
            articlesTwo: [],
            articlesThree: [],
            currentCategory: "All Training Articles",
            totalItem: 1
        })
    }
}

   /* tag === "All Articles" ? blogState.articles.map(article => selectionAry.push(article)) : selectionAry = blogState.articles.filter(article => article.tag == tag)

    setblogState({
        ...blogState,
        filterArticles: selectionAry,
        currentCategory: tag,
        activePage: 1
    })
    d
  }*/


  const getPageData = (pageNum) => {
    let selectionAry = [];
    if(pageNum == 1){
        blogState.articlesOne.map(article => selectionAry.push(article))
    } else if(pageNum == 2){
        blogState.articlesTwo.map(article => selectionAry.push(article))
    } else if (pageNum == 3){
        blogState.articlesThree.map(article => selectionAry.push(article))
    }
    setblogState({
        ...blogState,
        filterArticles: selectionAry,
        activePage: pageNum
    })
  }
  
  console.log(blogState)
return (
        <div>
            <div className="product-overview__simple-overview mb-2 blog-margin-adjustment-desktop">
                <MDBAnimation type="fadeIn">
                    <div className="overview-product__hero-banner">
                        <img src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/banner.jpg'} 
                        className="banner img-fluid w-100" 
                        alt="Food Transition"/>
                    </div>
                </MDBAnimation>
            </div>

            <div className="container blog-body futura-font"> 
                <MDBRow>
                    <MDBCol xs="12" sm="12" md="3" style={{"textAlign": "center", "marginTop": "150px", "padding": "0px 65px 0px 65px"}} className="display-none-mobile">
                        <div><h2>Year</h2></div>

                            <div id="accordion">
                                <div>

                                    <div id="headingOne" className="text-left">
                                        <h5 className="mb-0">
                                            <h5 data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" className="dropdown-toggle">2021</h5>
                                        </h5>
                                    </div>

                                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div id="accordionInnerOne">
                                            <div>
                                                <div id="headingTwo">
                                                <h5 className="mb-0">
                                                    <p data-toggle="collapse" data-target="#collapseJan" aria-expanded="false" aria-controls="collapseJan" className="dropdown-toggle">
                                                    Nov
                                                    </p>
                                                </h5>
                                                </div>
                                                <div id="collapseJan" className="collapse text-left" aria-labelledby="headingTwo" data-parent="#accordionInnerOne">
                                                    <p style={{"fontSize":"12px"}}><a href="https://www.lotuspetfoods.com/pet-kidney-disease">Pet Kidney Disease</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="accordionInnerTwo">
                                            <div>
                                                <div id="headingTwo">
                                                <h5 className="mb-0">
                                                    <p data-toggle="collapse" data-target="#collapseFeb" aria-expanded="false" aria-controls="collapseFeb" className="dropdown-toggle">
                                                    Dec
                                                    </p>
                                                </h5>
                                                </div>
                                                <div id="collapseFeb" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionInnerTwo">
                                                    <p style={{"fontSize":"12px"}}><a href="https://www.lotuspetfoods.com/proper-topper">Proper Topper</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="accordionTwo">
                                <div>
                                    <div id="headingOne" className="text-left">
                                        <h5 className="mb-0">
                                            <h5 data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" className="dropdown-toggle">2022</h5>
                                        </h5>
                                    </div>

                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingOne" data-parent="#accordionTwo">
                                        <div id="accordionInner_One">
                                            <div>
                                                <div id="headingTwo">
                                                    <h5 className="mb-0">
                                                        <p data-toggle="collapse" data-target="#collapse_Jan" aria-expanded="false" aria-controls="collapse_Jan" className="dropdown-toggle">
                                                        Jan
                                                        </p>
                                                    </h5>
                                                </div>
                                                <div id="collapse_Jan" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionInner_One">
                                                    <p style={{"fontSize":"12px"}}>None</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="accordionInner_Two">
                                            <div>
                                                <div id="headingTwo">
                                                    <h5 className="mb-0">
                                                        <p data-toggle="collapse" data-target="#collapse_Feb" aria-expanded="false" aria-controls="collapse_Feb" className="dropdown-toggle">
                                                        Feb
                                                        </p>
                                                    </h5>
                                                </div>
                                                <div id="collapse_Feb" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionInner_Two">
                                                    <p style={{"fontSize":"12px"}}><a href="https://www.lotuspetfoods.com/mistake-buying-pet-food">Buying Pet Food Mistake</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="accordionInner_Three">
                                            <div>
                                                <div id="headingTwo">
                                                    <h5 className="mb-0">
                                                        <p data-toggle="collapse" data-target="#collapse_March" aria-expanded="false" aria-controls="collapse_March" className="dropdown-toggle">
                                                        Mar
                                                        </p>
                                                    </h5>
                                                </div>
                                                <div id="collapse_March" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionInner_Three">
                                                    <p style={{"fontSize":"12px"}}><a href="https://www.lotuspetfoods.com/superfood-topper">Superfood Topper</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="accordionInner_Four">
                                            <div>
                                                <div id="headingTwo">
                                                    <h5 className="mb-0">
                                                        <p data-toggle="collapse" data-target="#collapse_April" aria-expanded="false" aria-controls="collapse_April" className="dropdown-toggle">
                                                        Apr
                                                        </p>
                                                    </h5>
                                                </div>
                                                <div id="collapse_April" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionInner_Four">
                                                <p style={{"fontSize":"12px"}}><a href="https://www.lotuspetfoods.com/vacation-with-pet">Vacationing with Pet</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="accordionInner_Five">
                                            <div>
                                                <div id="headingTwo">
                                                    <h5 className="mb-0">
                                                        <p data-toggle="collapse" data-target="#collapse_May" aria-expanded="false" aria-controls="collapse_May" className="dropdown-toggle">
                                                        May
                                                        </p>
                                                    </h5>
                                                </div>
                                                <div id="collapse_May" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionInner_Five">
                                                <p style={{"fontSize":"12px"}}><a href="https://www.lotuspetfoods.com/cat-obesity">Why is my Cat Fat</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="accordionInner_Six">
                                            <div>
                                                <div id="headingTwo">
                                                    <h5 className="mb-0">
                                                        <p data-toggle="collapse" data-target="#collapse_June" aria-expanded="false" aria-controls="collapse_June" className="dropdown-toggle">
                                                        Jun
                                                        </p>
                                                    </h5>
                                                </div>
                                                <div id="collapse_June" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionInner_Six">
                                                <p style={{"fontSize":"12px"}}><a href="https://www.lotuspetfoods.com/flea-tick">Flea & Tick</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="accordionInner_Seven">
                                            <div>
                                                <div id="headingTwo">
                                                    <h5 className="mb-0">
                                                        <p data-toggle="collapse" data-target="#collapse_July" aria-expanded="false" aria-controls="collapse_July" className="dropdown-toggle">
                                                        Jul
                                                        </p>
                                                    </h5>
                                                </div>
                                                <div id="collapse_July" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionInner_Seven">
                                                    <p style={{"fontSize":"12px"}}><a href="https://www.lotuspetfoods.com/baking-and-extruded">Baking & Extruded</a></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="accordionInner_Eight">
                                            <div>
                                                <div id="headingTwo">
                                                    <h5 className="mb-0">
                                                        <p data-toggle="collapse" data-target="#collapse_Aug" aria-expanded="false" aria-controls="collapse_Aug" className="dropdown-toggle">
                                                        Aug
                                                        </p>
                                                    </h5>
                                                </div>
                                                <div id="collapse_Aug" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionInner_Eight">
                                                    <p style={{"fontSize":"12px"}}><a href="https://www.lotuspetfoods.com/food-transition">Food Transition</a></p>
                                                    <p style={{"fontSize":"12px"}}><a href="https://www.lotuspetfoods.com/why-garlic">Why Garlic?</a></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="accordionInner_Nine">
                                            <div>
                                                <div id="headingTwo">
                                                    <h5 className="mb-0">
                                                        <p data-toggle="collapse" data-target="#collapse_Sep" aria-expanded="false" aria-controls="collapse_Sep" className="dropdown-toggle">
                                                        Sep
                                                        </p>
                                                    </h5>
                                                </div>
                                                <div id="collapse_Sep" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionInner_Nine">
                                                    <p style={{"fontSize":"12px"}}><a href="https://www.lotuspetfoods.com/pet-appetite">Pet Appetite Tips</a></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="accordionInner_Ten">
                                            <div>
                                                <div id="headingTwo">
                                                    <h5 className="mb-0">
                                                        <p data-toggle="collapse" data-target="#collapse_Oct" aria-expanded="false" aria-controls="collapse_Oct" className="dropdown-toggle">
                                                        Oct
                                                        </p>
                                                    </h5>
                                                </div>
                                                <div id="collapse_Oct" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionInner_Ten">
                                                    <p style={{"fontSize":"12px"}}><a href="https://www.lotuspetfoods.com/dog-training">Training</a></p>
                                                    <p style={{"fontSize":"12px"}}><a href="https://www.lotuspetfoods.com/dog-and-kidney">Dogs and Kidney</a></p>
                                                </div>
                                            </div>
                                        </div>

            


                                    </div>

                                </div>
                            </div>
                            <br/>
                            <hr />
                            <div><h2>Filter</h2></div> 
                            <br />
                            <MDBAnimation type="fadeIn" style={{"cursor": "pointer"}}>
                                <div onClick={() => filterSelection("All Nutrition Articles")}>
                                    <img className="img-fluid" 
                                    width="60%"
                                    src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/blog-iconimg1.png'} 
                                    />                                  
                                <p className="changeColor filter-text-p">Nutrition</p>
                                </div>
                            </MDBAnimation>
                            <br/>
                            <MDBAnimation type="fadeIn" style={{"cursor": "pointer"}}>
                                <div onClick={() => filterSelection("All Stories and Ideas Articles")}>
                                    <img className="img-fluid" 
                                    width="60%"
                                    src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/blog-iconimg2.png'} 
                                    />
                                <p className="changeColor filter-text-p">Stories & Ideas</p>
                                </div>
                            </MDBAnimation >   
                            <br/>
                            <MDBAnimation type="fadeIn" style={{"cursor": "pointer"}}>
                                <div onClick={() => filterSelection("All Health and Wellness Articles")}>
                                    <img className="img-fluid" 
                                    width="60%"
                                    src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/blog-iconimg3.png'} 
                                    />
                                
                                <p className="changeColor filter-text-p">Health & Wellness</p>
                                </div>
                            </MDBAnimation>  
                            <br/>
                            <MDBAnimation type="fadeIn" style={{"cursor": "pointer"}}>
                                <div onClick={() => filterSelection("All Training Articles")}>
                                    <img className="img-fluid" 
                                    width="60%"
                                    src={'https://lotus-pet-foods.s3.us-west-1.amazonaws.com/Landing+Page/blog/blog-iconimg4.png'} 
                                    />
                                <p className="changeColor filter-text-p">Training</p>
                                </div>
                            </MDBAnimation>  
                            <br/>
                            <MDBAnimation type="fadeIn" style={{"cursor": "pointer"}}>
                                <div onClick={() => filterSelection("All Articles")}>
                                    <h6 className="changeColor filter-text-p">View All</h6>
                                </div>
                                
                            </MDBAnimation>                               
                    </MDBCol>

                 
                    <MDBCol xs="12" sm="12" md="9">
                    <h2 className="h1-responsive font-weight-bold text-center my-5">{blogState.currentCategory}</h2>
                            <div className="px-5 pb-5">
                                <div>
                                {blogState.filterArticles.length == 0 ? <div style={{"textAlign": "center"}}><h3>No Result</h3></div> : blogState.filterArticles.map((filterArticle, i) => {
                                    return (
                                        <div key={i} className="ingredients__description">
                                            <p style={{"color": "grey"}}>{filterArticle.date}</p>
                                            <MDBRow className="d-flex align-items-center" style={{"border": "1px solid lightgrey"}}>
                                                <MDBCol lg="5" className='p-0'>
                                                    <MDBView className="z-depth-2 mb-lg-0 mb-4" hover waves>
                                                    <img
                                                        className="img-fluid"
                                                        src={filterArticle.img}
                                                        alt=""
                                                    />
                                                    <a href="#!">
                                                        <MDBMask overlay="white-slight" />
                                                    </a>
                                                    </MDBView>
                                                </MDBCol>
                                                <MDBCol lg="7">
                                                    <h5 className="font-weight-bold mb-3 p-0">
                                                        <strong>{filterArticle.title}</strong>
                                                    </h5>
                                                    <p style={{"fontSize": "12px"}}>{filterArticle.description}</p>
                                                    <MDBBtn style={{"margin": "0px"}} size="sm" className="waves-light mt-3" outline color="warning">
                                                        <Link to={filterArticle.url} style={{"color": "#fb3"}}>
                                                            Read more
                                                        </Link>
                                                    </MDBBtn>
                                                </MDBCol>
                                            </MDBRow>
                                            <br />
                                        </div>
                                    );
                                    })}

                                </div>
                            </div>
                           {blogState.filterArticles.length == 0 ? null : 
                           <Pagination
                            activePage={blogState.activePage}
                            itemsCountPerPage={6}
                            totalItemsCount={blogState.totalItem}
                            onChange={(pageNum) => getPageData(pageNum)}
                            itemClass="page-item"
                            linkClass="page-link"
                            />}
                    </MDBCol>
                </MDBRow>  
            </div>

        </div>
    )

}


              
            