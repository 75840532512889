import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBFileInput } from 'mdbreact';

import IsLoadingChild from '../IsLoading/IsLoadingChild.jsx';

export default class ImgRender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadedImgDataUrl: '',
      uploadedImgLoaded: false,
      imgLoader: false,
    };
  }

  componentDidMount() {
    this.setImgState();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setImgState();
    }
  }

  setImgState() {
    const { uploadedImgDataUrl, uploadedImgLoaded, imgLoader } = this.props;
    this.setState({
      uploadedImgDataUrl,
      uploadedImgLoaded,
      imgLoader,
    });
  }

  render() {
    const { state, props } = this;
    return (
      <MDBContainer className="admin-stores__img-uploader">
        <MDBRow>
          <MDBCol size="12">
            <MDBFileInput
              getValue={props.getUploadImg}
              btnColor="info"
              reset
              textFieldTitle="Upload your image"
            />
            <span className="review__span--img-upload-resize font-weight-bolder ml-2 futura-font">
              * JPG/JPEG file only. Max size 5 MB.
            </span>
          </MDBCol>

          {state.uploadedImgLoaded ? (
            <MDBCol size="12">
              <p className="admin-main__p font-weight-bolder ml-2 futura-font text-danger">
                Please ensure that image has correct orientation prior to uploading.
              </p>

              <img
                className="img-fluid admin-stores__upload-img my-3"
                src={state.uploadedImgDataUrl}
              />
            </MDBCol>
          ) : null}

          {state.imgLoader ? (
            <MDBCol size="12">
              <IsLoadingChild />
            </MDBCol>
          ) : null}
        </MDBRow>
      </MDBContainer>
    );
  }
}
