import React from 'react';
import axios from 'axios';
import swal from 'sweetalert2';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTabContent,
  MDBNav,
  MDBAnimation,
} from 'mdbreact';

const { setToken } = require('../../../../_helper/index.jsx');

const {
  endPoint: { ingredientEndPoint },
} = require('../../../../_helper/stringVars');

export default class AdminIngredients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ingredients: [],
    };
    this.updateIngredient = this.updateIngredient.bind(this);
    this.ingredientRef = React.createRef();
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    axios
      .get(`${ingredientEndPoint.admin}/get-ingredient`, { headers: setToken('admin') })
      .then((res) => {
        const ingredients = res.data;
        this.setState({
          ingredients,
        });
      });
  }

  updateIngredient(e) {
    e.preventDefault();
    const { ingredients } = this.state;
    const ingredientIndex = e.target.attributes['data-ingredient-index'].value;
    const newData = ingredients[ingredientIndex];
    axios
      .put(`${ingredientEndPoint.admin}/update-ingredient`, newData, { headers: setToken('admin') })
      .then((res) => {
        const { status } = res;
        const { message } = res.data;
        if (status === 202) {
          swal.fire({
            title: 'Description Updated.',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
          });
        } else if (status === 200) {
          swal.fire({
            title: 'Description Not Update',
            text: message,
            showConfirmButton: false,
            icon: 'warning',
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        const { status } = err.res;
        console.error(err);

        if (status === 400) {
          returnswal.fire({
            title: 'Error',
            text: 'An error has occurred.',
            icon: 'error',
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
  }

  onChange(e) {
    const { ingredients } = this.state;
    const { value } = e.target;
    const ingredientIndex = e.target.attributes['data-ingredient-index'].value;
    ingredients[ingredientIndex].description = value;
    this.setState({
      ingredients,
    });
  }

  render() {
    return (
      <MDBContainer>
        <MDBAnimation type="fadeIn">
          <MDBNav classicTabs color="primary" className="mt-5">
            <h1 className="admin-main__header1 m-4">Ingredients</h1>
          </MDBNav>

          <MDBTabContent className="admin-main__tab-content px-2">
            {this.state.ingredients.map((ingredient, i) => {
              return (
                <MDBCol size="12" className="mt-3 mb-3" key={i}>
                  <div className="col admin-ingredient-name">
                    {ingredient.name.replace(/^\w/, (c) => c.toUpperCase())}
                  </div>
                  {/* eslint-disable-next-line no-underscore-dangle */}
                  <form
                    id={ingredient._id}
                    data-ingredient-index={i}
                    onSubmit={this.updateIngredient}>
                    <MDBCol>
                      <textarea
                        type="text"
                        data-ingredient-index={i}
                        className="admin-ingredient-description"
                        value={ingredient.description}
                        ref={this.ingredientRef}
                        onChange={this.onChange}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBBtn
                        color="dark"
                        className="product-admin__save-ingredient mx-0"
                        type="submit">
                        Update Ingredient
                      </MDBBtn>
                    </MDBCol>
                  </form>
                </MDBCol>
              );
            })}
          </MDBTabContent>
        </MDBAnimation>
      </MDBContainer>
    );
  }
}
