import React from 'react';
import axios from 'axios';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import Fuse from 'fuse.js';
import $ from 'jquery';
import swal from 'sweetalert2';

import ConsumerSignUp from './ConsumerSignUp.jsx';
import SignUpFieldsRetail from './SignUpFieldsRetail.jsx';

const { success, warning } = require('../../../_helper/SweetAlerts/alerts.jsx');
const { onChange, setNewUserObj } = require('../../../_helper/index.jsx');
const { userLogin } = require('../helper');

const {
  endPoint: { retailStores },
} = require('../../../_helper/stringVars');

const fuseOptions = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['name', 'address'],
};

export default class SignUpForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      queryStore: '',
      selectedStore: '',
      searchResults: [],
      stores: [],
      retailer: false,
      displayList: false,
      showCurrentSelectedStore: false,
      activeItem: '1',
      subscribe: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.fbAsyncInit = function() {
      FB.init({
        appId: '1030022257187734',
        cookie: true,
        xfbml: true,
        version: 'v3.2',
      });
    }.bind(this);

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
    this.getStore();
  }

  getStore = () => {
    axios.get(`${retailStores.client}/get-stores`).then((res) => {
      const storeData = res.data;
      const stores = storeData.map((store) => {
        return { name: store.name, address: store.address };
      });

      this.setState({
        stores,
      });
    });
  };

  onRetailerChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      displayList: !this.state.displayList,
    });
    e.preventDefault();
  };

  onSubscribeChange = () => {
    //console.log('test successs!');
    this.setState({
      subscribe: !this.state.subscribe,
    });
  };

  selectStore = (e) => {
    const { displayList } = this.state;
    const currentDiv = e.target;
    const text = currentDiv.textContent;
    this.setState({
      selectedStore: text,
      displayList: !displayList,
      queryStore: '',
      showCurrentSelectedStore: true,
    });
    /* Need to hide the list */
  };

  signUp = (e) => {
    e.preventDefault();
    const { history, retailer } = this.props;
    const { firstName, lastName, email, password } = this.state;
    let ready = true;
    /*** TODO: Update to handle form validation ***/
    if (firstName === '') {
      $('#firstnamee').css('border-color', 'red');
      swal.fire({
        icon: 'error',
        title: 'Please enter your first name'
      });
      ready = false;
    } else if (lastName === '') {
      $('#lastnamee').css('border-color', 'red');
      swal.fire({
        icon: 'error',
        title: 'Please enter your last name'
      });
      ready = false;
    } else if (email === '') {
      $('#emaill').css('border-color', 'red');
      swal.fire({
        icon: 'error',
        title: 'Please enter a valid email'
      });
      ready = false;
    } else if (password === '' || password.length < 8) {
      $('#passwordd').css('border-color', 'red');
      swal.fire({
        icon: 'error',
        title: 'Password must be at least 8 characters long'
      });
      ready = false;
    }

    if(ready){
    const newUser = setNewUserObj(this.state, retailer);
    axios
      .post('/client/account/new-user', { params: newUser })
      .then((data) => {
        const { message, status } = data.data;

        /*Add new subscriber to mailchimp*/
        if(this.state.subscribe === true){
        const newSub = {
          email_address: this.state.email,
          status: 'subscribed'
        };
        const { data } = axios.post('/client/subscribe/new-subscriber', {
          data: newSub,
        });
      }

        /* Temp */
        success({
          title: 'Thank you!',
          text: 'Your account has been created. You will be automatically Sign In.',
          button: false,
        });
        setTimeout(() => userLogin(e, axios, this.state, this.props), 3000);
      })
      .catch((err) => {
        const { status } = err.response;
        if (status === 409) {
          return this.checkIfAccountExists();
        }
        console.error(err);
      });
    }
  };

  checkEmptyFields = (cb) => {
    const stringFields = Object.keys(this.state).filter((state) => {
      return typeof this.state[state] === 'string';
    });

    /* 
      return cb information to check for the actual field?
      but then what? This is more complicated than it needs to be? 
      ToDo: Check if fields are empty
      Check if account exists 
    */
    const filledFields = stringFields.filter((field) => field !== '');
    cb(filledFields);
    return filledFields >= 7 ? true : false;
  };

  checkIfAccountExists = () => {
    return warning({
      title: 'This email is already taken',
      text: 'Please use another email.',
      button: false,
    });
  };


  renderSignUpComp() {
    const {
      firstName,
      lastName,
      email,
      password,
      selectedStore,
      displayList,
      showCurrentSelectedStore,
      queryStore,
    } = this.state;

    const fuse = new Fuse(this.state.stores, fuseOptions);
    const data = this.state.queryStore
      ? fuse.search(this.state.queryStore)
      : this.state.searchResults;
    const results = data.slice(0, 9);
    const Result = ({ result }) => {
      return (
        <li
          className="list-group-item animate fadeIn"
          style={{ color: 'black', cursor: 'pointer' }}
          onClick={this.selectStore}
          ref={this.storeName}>
          {`${result.name} - ${result.address}`}
        </li>
      );
    };
    const searchResultsJsx = results.map((x, i) => <Result key={i} result={x} />);

    if (this.props.activeItem === '1') {
      return (
        <ConsumerSignUp
          firstName={firstName}
          lastName={lastName}
          email={email}
          password={password}
          onChange={(e) => onChange(this, e)}
          signUp={this.signUp}
          onSubscribeChange={this.onSubscribeChange}
        />
      );
    } 
    return (
      <MDBRow>
        <MDBContainer>
          <MDBCol size="12" className="my-2">
            <p className="futura-font text-danger">
              This sign up is for retail store employees only! If you're a customer, please use
              "New Users" tab.
            </p>
          </MDBCol>
          <MDBCol size="12">
            <div className={`selected-store ${showCurrentSelectedStore ? '' : 'd-none'}`}>
              <p>Current Store Selected:</p>
              <p>{selectedStore}</p>
            </div>
            <input
              type="text"
              id="retail-search"
              className="form-control my-3"
              placeholder="Enter your store..."
              autoComplete="off"
              value={queryStore}
              name="queryStore"
              onChange={this.onRetailerChange}
            />
            <label className="sr-only">Find your store</label>
            <div className={`retailer-list__wrapper ${displayList ? '' : 'd-none'}`}>
              <ul className="retailer-list__list list-group list-group-flush">
                {searchResultsJsx}
              </ul>
            </div>
          </MDBCol>
        </MDBContainer>
        <MDBCol size="12">
        <SignUpFieldsRetail
            firstName={firstName}
            lastName={lastName}
            email={email}
            password={password}
            onChange={(e) => onChange(this, e)}
            signUp={this.signUp}
        />
        </MDBCol>
      </MDBRow>
    );
  }

  render() {
    return this.renderSignUpComp();
  }
}
