import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import StarRating from 'react-star-rating-component';

export default function Rating(props) {
  return (
    <div className="product-main__rating">
      <MDBRow>
        <MDBCol size="12" md="8" lg="5">
          <MDBRow>
            <MDBCol size="7" md="8" className="product-main__stars">
              <StarRating
                name={'product-rating'}
                starCount={5}
                // value={this.state.stars
                editing={false}
                value={props.starsRating !== Infinity ? props.starsRating : null}
                renderStarIcon={(index, value) => {
                  return (
                    <span>
                      <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                    </span>
                  );
                }}
                renderStarIconHalf={() => {
                  return (
                    <span>
                      <span style={{ position: 'absolute' }}>
                        <i className="far fa-star" />
                      </span>
                      <span>
                        <i className="fas fa-star-half" />
                      </span>
                    </span>
                  );
                }}
              />
            </MDBCol>

            <MDBCol size="2">
              <span className="product-main__rating-span futura-font">
                {props.numsOfRating || 0}
              </span>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </div>
  );
}
