import React, { Component, Fragment } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import swal from 'sweetalert2';

import MainImg from './ShopItemComps/MainImg.jsx';
import LightBox from '../../LightBox/LightBox.jsx';
import Purchase from './ShopItemComps/Purchase.jsx';
import ItemHeader from './ShopItemComps/ItemHeader.jsx';
import ShirtOptions from './ShopItemComps/ShirtOptions.jsx';
import Thumbs from './ShopItemComps/Thumbs.jsx';
import Description from './ShopItemComps/Description.jsx';
import Props65 from './ShopItemComps/Prop65.jsx';

import IsLoadingChild from '../../IsLoading/IsLoadingChild.jsx';

import { helper } from './helper.jsx';

const { handleErrors } = require('../../_helper/stringVars');

export default class ShopItemsMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      price: '',
      SKU: {},
      SKUOptions: [],
      description: [],
      images: [],
      shirtImages: {},
      totesImgs: [],
      mainImg: '',
      currentLightbox: '',
      currentShirtColor: '',
      shirtColors: [],
      currentImgThumbs: 'front',
      lightBoxImagesArr: [],
      lightBoxIsOpen: false,
      lightBoxImgIndex: 0,
      shirt: false,
      quantities: 1,
      selectedItemSKU: '',
      selectedShirtSize: '',
      currentThumb: '0',
    };
  }

  componentDidMount() {
    this.setShopItemInfo();
    if (!localStorage.cart) {
      localStorage.cart = JSON.stringify([]);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentShirtColor !== prevState.currentShirtColor) {
      this.setItemImgs();
    }
  }

  /* itemShop init */
  /**
   * @param
   * @return
   */
  setShopItemInfo = async () => {
    try {
      const noContent = undefined;
      const { itemCat, itemType } = noContent || this.props.match.params;
      if (itemCat === 'shirts') {
        this.setState({ shirt: true });
      }

      const shopItemInfo = await helper.setShopitemToRender({
        itemCat,
        itemType,
      });

      Object.keys(shopItemInfo).forEach((key) => {
        this.setState({
          [key]: shopItemInfo[key],
        });
      });

      this.setInitialShirtColor();
      this.setItemImgs();
      this.setSKUOptions();
    } catch (err) {
      console.error(err);
      handleErrors(500);
    }
  };

  setInitialShirtColor() {
    const { shirtColors, shirt } = this.state;
    if (shirt) {
      this.setState({
        currentShirtColor: shirtColors[0].color,
      });
    }
  }

  setItemImgs() {
    const { shirt, currentShirtColor, shirtImages, totesImgs } = this.state;
    if (shirt) {
      this.setState({
        images: shirtImages[currentShirtColor],
        mainImg: helper.setMainImgSrc(shirtImages[currentShirtColor]),
        lightBoxImagesArr: helper.setLightBoxImgArr(shirtImages[currentShirtColor]),
      });
    } else {
      this.setState({
        images: totesImgs,
        mainImg: helper.setMainImgSrc(totesImgs),
        lightBoxImagesArr: helper.setLightBoxImgArr(totesImgs),
      });
    }
  }

  setSKUOptions() {
    const { SKU, shirt } = this.state;
    const { itemCat } = this.props.match.params;

    const SKUOptions = shirt ? helper.setSkuForOptions({ itemCat, SKU }) : SKU;
    const selectedItemSKU = shirt ? '' : SKU;

    this.setState({
      SKUOptions,
      selectedItemSKU,
    });
  }

  /* End of shopItem init */

  /* Img state modification */
  changeCurrentPicture = (e) => {
    e.preventDefault();
    const imgindex = e.currentTarget.attributes['data-imgindex'].value;
    const mainImg = e.currentTarget.attributes['data-mainsrc'].value;

    this.setState({
      mainImg,
      lightBoxImgIndex: imgindex,
      currentThumb: imgindex,
    });
  };

  openLightBox = (e) => {
    e.preventDefault();

    this.setState({ lightBoxIsOpen: !this.state.lightBoxIsOpen });
  };

  changeImg = (prev) => {
    const { lightBoxImgIndex, lightBoxImagesArr } = this.state;
    if (prev !== undefined) {
      return this.setState({
        lightBoxImgIndex:
          (lightBoxImgIndex + lightBoxImagesArr.length - 1) % lightBoxImagesArr.length,
      });
    }
    return this.setState({
      lightBoxImgIndex: (lightBoxImgIndex + 1) % lightBoxImagesArr.length,
    });
  };

  /* End of Img state modification */

  /* Other methods */

  changeShirtColor = (e) => {
    e.preventDefault();
    this.setState({
      currentShirtColor: e.currentTarget.attributes['data-color'].value,
    });
    this.setItemImgs();
  };

  getSKUVal = (value) => {
    this.setState({
      selectedItemSKU: value[0],
    });
  };

  getShirtSize = (value) => {
    this.setState({
      selectedShirtSize: value,
    });
  };

  incrementQuantity = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      quantities: prevState.quantities + 1,
    }));
  };

  decrementQuantity = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      quantities: prevState.quantities === 1 ? 1 : prevState.quantities - 1,
    }));
  };

  addToCart = () => {
    const {
      name,
      price,
      images,
      quantities,
      selectedItemSKU,
      currentShirtColor,
      selectedShirtSize,
    } = this.state;

    const order = {
      name,
      price,
      image: images[0].thumb,
      quantity: quantities,
      selectedColor: currentShirtColor,
      selectedSize: selectedShirtSize,
      selectedSKU: selectedItemSKU,
    };

    const cart = JSON.parse(localStorage.cart);
    console.log({ cart });
    let exists = false;
    let max = false;

    for (let i = 0; i < cart.length; i++) {
      if (
        cart[i].name === order.name &&
        cart[i].selectedSize === order.selectedSize &&
        cart[i].selectedColor === order.selectedColor
      ) {
        cart[i].quantity = parseInt(cart[i].quantity, 10) + parseInt(order.quantity, 10);
        if (cart[i].quantity > 10) {
          swal.fire({
            title: 'Max amount per size is 10!',
            icon: 'error',
            showConfirmButton: false,
            timer: 1000,
          });
          cart[i].quantity = 10;
          max = true;
        }
        exists = true;
      }
    }

    if (!exists) {
      cart.push(order);
    }

    localStorage.cart = JSON.stringify(cart);

    if (!max) {
      swal.fire({
        title: 'Added to Cart!',
        icon: 'success',
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  /* End of other methods */

  render() {
    const { state } = this;
    return (
      <MDBContainer className="shop-item-main margin-top-adjustment">
        <MDBRow>
          <MDBCol size="12" lg="6" className="d-flex flex-column align-items-center">
            <MDBRow>
              <MainImg openLightBox={this.openLightBox} mainImg={state.mainImg} />
            </MDBRow>

            <MDBRow className="ml-5">
              <LightBox
                lightBoxImagesArr={state.lightBoxImagesArr}
                lightBoxIsOpen={state.lightBoxIsOpen}
                changeImg={this.changeImg}
                lightBoxImgIndex={state.lightBoxImgIndex}
                openLightBox={this.openLightBox}
              />
            </MDBRow>

            <MDBRow>
              <Thumbs
                changeCurrentPicture={this.changeCurrentPicture}
                openLightBox={this.openLightBox}
                images={state.images}
                currentThumb={state.currentThumb}
              />
            </MDBRow>
          </MDBCol>
          <MDBCol size="12" lg="6">
            <MDBRow>
              <ItemHeader name={state.name} price={state.price} />
            </MDBRow>

            <MDBRow className="my-2">
              <Purchase
                decrementQuantity={this.decrementQuantity}
                incrementQuantity={this.incrementQuantity}
                quantities={state.quantities}
                addToCart={this.addToCart}
              />
            </MDBRow>

            {state.shirt ? (
              <MDBRow>
                <ShirtOptions
                  changeShirtColor={this.changeShirtColor}
                  shirtColors={state.shirtColors}
                  SKU={state.SKUOptions}
                  getSKUVal={this.getSKUVal}
                  getShirtSize={this.getShirtSize}
                />
              </MDBRow>
            ) : null}

            <MDBRow>
              <Description description={state.description} />
            </MDBRow>

            <MDBRow className="my-3">
              <MDBCol>
                <h2 className="shop-item-main__header2 futura-font">Shipping:</h2>
                <p className="shop-item-main__p futura-font">Allow 1 to 2 weeks for delivery.</p>
              </MDBCol>
            </MDBRow>

            <MDBRow className="my-4">
              <Props65 />
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
